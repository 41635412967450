import React from 'react'
import {
  CloseButton,
  Col,
  Container,
  ContainerBody,
  ContainerFillable,
  ContainerHeader,
  ContainerTitle,
  Row
} from 'components/generic/helpers'
import GenericButton from 'components/generic/GenericButton'
import { InputRead, Label } from 'components/generic/GenericInputs'
import { OperationModel } from 'interfaces/operation'
import FormGenerator from 'components/generic/helpers/FormGenerator'
import OperationDocsList from '../OperationDocsList'
import { consumeByOperationId } from 'services/pdf/PdfExportHelper'
import { usePdfExport } from 'hooks/helpers/usePdfExport'
import moment from 'moment'

interface OperationShowPageProps {
  title: string
  operation: Required<OperationModel>
  readOnly?: boolean
  rightContent?: React.ReactNode
  canExportPdf?: boolean
  canExportAnalyticsPdf?: boolean
  goBack?: () => void
}

const OperationShowPage: React.FC<OperationShowPageProps> = ({
  readOnly = true,
  operation,
  canExportPdf,
  canExportAnalyticsPdf,
  goBack,
  rightContent,
  title
}) => {
  const { createOperationContract, createAnalyticsRelatory } = usePdfExport()

  return (
    <Container>
      <ContainerHeader
        title={title}
        rightContent={
          <Row>
            {canExportPdf && (
              <GenericButton
                style={{ margin: '0 10px' }}
                onClick={() => {
                  createOperationContract(operation)
                }}
              >
                Exportar Contrato
              </GenericButton>
            )}
            {canExportAnalyticsPdf && (
              <GenericButton
                style={{ margin: '0 10px' }}
                onClick={() => {
                  createAnalyticsRelatory(operation, consumeByOperationId)
                }}
              >
                Exportar Relatório
              </GenericButton>
            )}
            {goBack && <CloseButton onClick={goBack} />}
            {rightContent}
          </Row>
        }
      />
      <ContainerBody>
        <Row>
          <Col style={{ flex: 1 }}>
            <FormGenerator
              rows={[
                {
                  items: [
                    {
                      label: 'Nº do Contrato',
                      grid: 1,
                      value: `${operation.bacen_number}`,
                      readOnly
                    }
                  ]
                }
              ]}
            />
            <ContainerFillable title={'Dados para Operação'}>
              <FormGenerator
                rows={[
                  {
                    items: [
                      {
                        label: 'Cod. Operação',
                        grid: 1,
                        value: `${operation.operation_number}`,
                        readOnly
                      },
                      {
                        label: 'Dt. Fechamento',
                        grid: 1,
                        value: `${operation.currency_closure}`,
                        readOnly,
                        type: 'date'
                      },
                      {
                        label: 'Dt. Liquidação',
                        grid: 1,
                        value: `${operation.bacen_closure}`,
                        readOnly,
                        type: 'date'
                      },
                      {
                        label: 'Valor do proceso',
                        grid: 1,
                        value: `${operation.process_value}`,
                        readOnly
                      },
                      {
                        label: 'Vencimento',
                        grid: 1,
                        value: `${moment(operation.process_expiration).format(
                          'DD/MM/yyyy'
                        )}`,
                        readOnly,
                        type: 'date'
                      },
                      {
                        label: 'Moeda',
                        grid: 1,
                        value: `${operation.currency_iso}`,
                        readOnly
                      },
                      {
                        label: 'Valor da Operação',
                        grid: 1,
                        value: `${operation.operation_value}`,
                        readOnly
                      },
                      {
                        label: 'Taxa de Câmbio',
                        grid: 1,
                        value: `${operation.taxa}`,
                        readOnly
                      }
                    ]
                  },
                  {
                    items: [
                      {
                        label: 'Despesas',
                        grid: 1,
                        value: 'Sem valor',
                        readOnly
                      },
                      {
                        label: 'IOF',
                        grid: 1,
                        value: 'Sem valor',
                        readOnly
                      },
                      {
                        label: (
                          <Col>
                            <Label>Natureza da Operação</Label>
                            <Row>
                              <InputRead
                                maxLength={5}
                                maxWidth='50px'
                                value={operation.nature_fact}
                                isReadOnly={readOnly}
                              />
                              <InputRead
                                maxLength={2}
                                maxWidth='30px'
                                value={operation.nature_client_type}
                                isReadOnly={readOnly}
                              />
                              <InputRead
                                maxLength={1}
                                maxWidth='25px'
                                value={operation.nature_aval}
                                isReadOnly={readOnly}
                              />
                              <InputRead
                                maxLength={2}
                                maxWidth='30px'
                                value={operation.nature_beneficiary}
                                isReadOnly={readOnly}
                              />
                              <InputRead
                                maxLength={2}
                                maxWidth='30px'
                                value={operation.nature_group}
                                isReadOnly={readOnly}
                              />
                            </Row>
                          </Col>
                        ),
                        grid: 1,
                        value: `${operation.bacen_closure}`,
                        readOnly
                      },
                      {
                        label: 'Forma de Entrega',
                        grid: 1,
                        value: `${operation.forma_entrada_moeda}`,
                        readOnly
                      },
                      {
                        label: 'Forma de Pagamento',
                        grid: 1,
                        value: `${operation.forma_entrada_reais}`,
                        readOnly
                      },
                      {
                        label: 'Instituição Financeira',
                        grid: 1,
                        value:
                          operation.financial_institution ||
                          operation.instituicao,
                        readOnly
                      }
                    ]
                  }
                ]}
              />
            </ContainerFillable>
            <ContainerFillable title={'Documentos do Pagador/Recebedor'}>
              <FormGenerator
                rows={[
                  {
                    items: [
                      {
                        label: 'Pagador/Recebedor',
                        grid: 1,
                        value: operation.beneficiary_name,
                        readOnly
                      },
                      {
                        label: 'Instituição financeira do Pagador/Recebedor',
                        value: operation.bank_info,
                        readOnly
                      }
                    ]
                  },
                  {
                    items: [
                      {
                        label: 'País',
                        grid: 1,
                        value: operation.country_name,
                        readOnly
                      },
                      {
                        label: 'Endereço',
                        grid: 1,
                        value: operation.beneficiary_address,
                        readOnly
                      },
                      {
                        label: 'Relação de Vínculo',
                        grid: 6,
                        value: operation.vinculo,
                        readOnly
                      }
                    ]
                  },
                  {
                    items: [
                      {
                        label: 'SWIFT',
                        grid: 1,
                        value: operation.bank_swift,
                        readOnly
                      },
                      {
                        label: 'ABA',
                        grid: 1,
                        value: operation.bank_aba,
                        readOnly
                      },
                      {
                        label: 'CHIPS',
                        grid: 1,
                        value: operation.bank_chips,
                        readOnly
                      },
                      {
                        label: 'Conta',
                        grid: 1,
                        value: operation.bank_account,
                        readOnly
                      },
                      {
                        label: 'IBAN',
                        grid: 1,
                        value: operation.bank_iban,
                        readOnly
                      }
                    ]
                  }
                ]}
              />
            </ContainerFillable>
            <ContainerFillable title={'Documentos do Intermediário'}>
              <FormGenerator
                rows={[
                  {
                    items: [
                      {
                        label: 'Instituição Intermediária',
                        grid: 1,
                        value: operation.intermediary_info,
                        readOnly
                      },
                      {
                        label: 'Cod. País',
                        grid: 1,
                        value: operation.intermediary_country_initials,
                        readOnly
                      },
                      {
                        label: 'País',
                        grid: 1,
                        value: operation.intermediary_country,
                        readOnly
                      },
                      {
                        label: 'SWIFT',
                        grid: 1,
                        value: operation.intermediary_swift,
                        readOnly
                      }
                    ]
                  },
                  {
                    items: [
                      {
                        label: 'ABA',
                        grid: 1,
                        value: operation.intermediary_aba,
                        readOnly
                      },
                      {
                        label: 'CHIPS',
                        grid: 1,
                        value: operation.intermediary_chips,
                        readOnly
                      },
                      {
                        label: 'Conta',
                        grid: 1,
                        value: operation.intermediary_account,
                        readOnly
                      },
                      {
                        label: 'IBAN',
                        grid: 1,
                        value: operation.intermediary_iban,
                        readOnly
                      }
                    ]
                  }
                ]}
              />
            </ContainerFillable>
          </Col>
        </Row>
        <Row>
          <Col style={{ flex: 1 }}>
            <ContainerTitle value={'Documentos da Operação'} />
            <Col style={{ padding: '0.5rem' }}>
              <OperationDocsList documents={operation.documents || []} />
            </Col>
          </Col>
          <Col style={{ flex: 1 }}></Col>
        </Row>
      </ContainerBody>
    </Container>
  )
}

export default OperationShowPage
