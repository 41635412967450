import React from "react";

import DascamLogo from "assets/Logo 2.png";
import { Col, Row } from "services/pdf/components/helpers";

const GenericDascamErrorBox: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Col style={{ padding: 20 }}>
      <Row>
        <img style={{ width: "100%" }} src={DascamLogo} alt={"Erro Dascam"} />
      </Row>
      <Row style={{ marginTop: 10, justifyContent: "center" }}>
        <p
          style={{ fontWeight: "bold", letterSpacing: 1, textAlign: "center" }}
        >
          {message}
        </p>
      </Row>
    </Col>
  );
};

export default GenericDascamErrorBox;
