import {
  PermissionModel,
  Permissions,
  PermissionsGroupModel
} from 'interfaces/web/permission'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CheckBoxPermission from './CheckBoxPermission'
import GenericButton from './GenericButton'
import { Col } from 'v2/components/shared/Col'

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  margin: 0.1rem 0;
`

const TextPermission = styled.p`
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 24px;
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.color.gray1};
  margin-right: 1rem;
`

const BlueCard = styled.div`
  width: auto;
  height: auto;
  padding: 0.5rem;
  border-radius: 9px;
  background-color: ${(props) => props.theme.color.primary_main};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-y: auto;
`

const TabsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  max-height: 250px;
  margin-right: 0.5rem;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  border-radius: 9px;
  background-color: ${(props) => props.theme.color.white3};
  width: 195px;
  max-height: 250px;
  overflow-y: auto;
  margin-right: 0.5rem;
`

const ButtonTabs = styled(GenericButton)<{
  isDeny?: boolean
  isYellow?: boolean
  isBlue?: boolean
}>`
  width: 150px;
  height: 25px;
  margin: 0.1rem;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  color: ${(props) => props.theme.color.white1};
  font-size: 0.9rem;
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isYellow
      ? props.theme.color.secondary_main
      : props.isBlue
      ? '#296ba9'
      : props.theme.color.button_normal} !important;

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isYellow
        ? props.theme.color.secondary_light
        : props.isBlue
        ? '#4b87be'
        : props.theme.color.button_normal_hover} !important;
  }
`

interface PermissionPickerProps {
  groups: PermissionsGroupModel[]
  onChange?: (permissions: Permissions[]) => void
  initialValue?: Permissions[]
}

const PermissionPicker: React.FC<PermissionPickerProps> = ({
  groups,
  onChange,
  initialValue
}) => {
  const [group, setGroup] = useState<PermissionsGroupModel>()
  const [permissionsValues, setPermissionsValues] = useState<number[]>([])

  const addPermission = (permissions: number[], emit = true) => {
    const nextPermissions = [...permissionsValues, ...permissions]
    setPermissionsValues(() => nextPermissions)
    emit && onChange?.(nextPermissions)
  }

  const removePermission = (permissions: number[], emit = true) => {
    const nextPermissions = permissionsValues.filter(
      (o) => !permissions.includes(o)
    )
    setPermissionsValues(() => nextPermissions)
    emit && onChange?.(nextPermissions)
  }

  const isPermissionSelected = (permission: number) => {
    return permissionsValues.includes(permission)
  }

  const isSelectedGroup = (currentGroup: PermissionsGroupModel) => {
    return group?.title === currentGroup.title
  }

  const isPermissionsSelecteds = (permissions: number[]) => {
    return permissions.every((o) => isPermissionSelected(o))
  }

  useEffect(() => {
    if (groups.length > 0) {
      setGroup(() => groups[0])
    }

    if (initialValue) {
      setPermissionsValues(() => initialValue)
    }
  }, [])

  return (
    <>
      <Col>
        <TextPermission>Permissões:</TextPermission>
        <Col
          flexDirection={'row'}
          rounded={'lg'}
          padding={'0.5rem'}
          overflow={'hidden'}
          display={'flex'}
          align-items={'flex-start'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
        >
          <TabsList>
            {groups.map((group, keyGroup) => (
              <ButtonTabs
                key={`group${group.title}${keyGroup}`}
                isBlue={!isSelectedGroup(group)}
                isYellow={isSelectedGroup(group)}
                type='button'
                onClick={() => setGroup(() => group)}
              >
                {group.title}
              </ButtonTabs>
            ))}
          </TabsList>
          <List>
            {group?.permissions.map((permission, keyPermission) => (
              <CheckBoxPermission
                key={`permission${keyPermission}`}
                title={`${permission.label}`}
                selected={isPermissionSelected(permission.value)}
                changeSelected={(value) => {
                  if (value) {
                    addPermission([permission.value])
                  } else {
                    removePermission([permission.value])
                  }
                }}
              />
            ))}
            <CheckBoxPermission
              title={`Todos`}
              selected={isPermissionsSelecteds(
                group?.permissions.map((p) => p.value) || []
              )}
              changeSelected={(value) => {
                removePermission(
                  group?.permissions.map((p) => p.value) || [],
                  !value
                )
                if (value) {
                  addPermission(
                    group?.permissions.map((p) => p.value) || [],
                    true
                  )
                }
              }}
            />
          </List>
        </Col>
      </Col>
    </>
  )
}

export default PermissionPicker
