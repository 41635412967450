import {
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input
} from '@chakra-ui/react'
import React, { ChangeEvent, useMemo, useRef, useState } from 'react'
// import ButtonCopy from "v2/components/shared/ButtonCopy";
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import { FieldProps } from '../..'
import { MdAttachFile, MdFileDownload } from 'react-icons/md'
import { toBase64 } from 'v2/services/helpers/toBase64'
import { FileProp } from 'v2/components/forms/documents/contracts'
import moment from 'moment'

interface FilesFieldProps extends FieldProps<'files', FileProp[], FileProp[]> {}

const FilesField: React.FC<FilesFieldProps> = ({
  infos,
  value,
  onChange,
  isReadonly
}) => {
  const [isLoading, setLoading] = useState(false)
  const inputFileRef = useRef<HTMLInputElement>(null)

  const filesCount = useMemo(() => {
    if (!value) return 0

    return value.length
  }, [value])

  const isUndefined = value === undefined || filesCount <= 0
  const isNull = value === null

  const fieldValue = useMemo(() => {
    if (isUndefined && isReadonly) {
      return 'Sem Arquivos'
    }

    if (isUndefined || isNull) {
      return 'Sem Arquivos'
    }

    return `${filesCount} ${filesCount <= 1 ? 'Arquivo' : 'Arquivos'}`
  }, [isUndefined, isNull, isReadonly, filesCount])

  const maxFiles = infos.maxFiles || 1

  return (
    <FormControl
      minH={'100%'}
      display={'flex'}
      flexDir={'column'}
      fontFamily={'heading'}
    >
      <Col mb={1}>
        <FormLabel fontWeight={'bold'} mb={0}>
          {infos.label}
        </FormLabel>
        <FormLabel color={'GrayText'} mb={0} fontSize={'xs'}>
          {infos.description}
        </FormLabel>
      </Col>
      {!isReadonly && (
        <Row pos={'relative'} alignItems={'center'} role='group' mt={'auto'}>
          {!isReadonly && (
            <Input
              ref={inputFileRef}
              type={'file'}
              multiple={maxFiles > 1}
              display={'none'}
              onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                setLoading(() => true)
                const files = Array.from(e.target.files || [])
                const nextValue = await Promise.all(
                  files.map<Promise<FileProp>>(async (file) => ({
                    isBase64: true,
                    src: await toBase64(file),
                    size: file.size,
                    mimeType: file.type,
                    name: file.name
                  }))
                )

                if (inputFileRef.current) {
                  inputFileRef.current.value = ''
                  // inputFileRef.current.files = [];
                }

                onChange?.(nextValue)
                setLoading(() => false)
              }}
            ></Input>
          )}
          <Input
            rounded={'sm'}
            value={`${fieldValue}`}
            placeholder={infos.placeholder}
            readOnly
            variant={'filled'}
            fontStyle={isUndefined ? 'italic' : 'normal'}
            color={isUndefined ? 'gray.500' : 'inherit'}
            _focus={{}}
          />
          <HStack
            h={'100%'}
            pos={'absolute'}
            right={0}
            alignItems={'center'}
            pr={2}
          >
            {filesCount <= 0 && !isReadonly && (
              <IconButton
                aria-label='Pick File'
                size={'sm'}
                variant={'ghost'}
                onClick={() => {
                  inputFileRef.current?.click()
                }}
                isLoading={isLoading}
              >
                <MdAttachFile size={20} />
              </IconButton>
            )}
            {infos.viewMode && filesCount && (
              <IconButton
                aria-label='Download'
                size={'sm'}
                variant={'ghost'}
                onClick={() => {
                  const link = document.createElement('a')
                  const filePath = value![0].src
                  link.href = filePath
                  link.download = `download-${moment().format(
                    'YYYYMMDDHHmmss'
                  )}`
                  link.click()
                }}
                isLoading={isLoading}
              >
                <MdFileDownload size={20} />
              </IconButton>
            )}
            {filesCount > 0 && !isReadonly && (
              <CloseButton
                isDisabled={isLoading}
                onClick={() => {
                  onChange?.([])
                }}
              />
            )}
          </HStack>
        </Row>
      )}
      {isReadonly && (
        <Button
          width={100}
          variant={'outline'}
          disabled={filesCount === 0}
          colorScheme={'primary'}
          onClick={() => {
            const link = document.createElement('a')
            const filePath = value![0].src
            link.href = filePath
            link.target = '_blank'
            link.download = `download-${moment().format('YYYYMMDDHHmmss')}`
            link.click()
          }}
        >
          Baixar
        </Button>
      )}
    </FormControl>
  )
}

export default FilesField
