import { PayerReceiverModel } from "interfaces/payiesReceivies";

export function convertProviderBody(data: PayerReceiverModel) {
    return {
        cod_cli: data.CodCli,
        company_name: '',
        provider_syscambio: data.CodPagReg,
        identification: data.NomeBeneficiario,
        country: data.PaisBeneficiario,
        city: data.CidadeBeneficiario,
        state: data.EstadoBeneficiario,
        status: 'ACTIVE',
        account_data: {
            bank_name: data.NomeBancoBeneficiario,
            bank_country: data.PaisBancoBeneficiario,
            bank_city: data.CidadeBancoBeneficiario,
            bank_address: data.EnderecoBancoBeneficiario,
            swift: data.SwiftBancoBeneficiario,
            aba: data.AbaBancoBeneficiario,
            account: data.ContaBancoBeneficiario,
            iban: data.IbanBancoBeneficiario,
            sort_code: data.SortCodeInglaterra,
            blz: data.BlzAlemanha,
            branch_code: data.BranchCodeAfricadosul,
            bsb: data.BsbAustralia,
            transit: data.TransitCanada,
            cnaps: data.CnapsBeneficiarioChina
        },
        intermediary: {
            bank_name: data.NomeIntermediario,
            bank_country: data.PaisBancoIntermediario,
            bank_city: data.CidadeBancoIntermediario,
            bank_address: data.EndBancoIntermediario,
            swift: data.SwiftIntermediario,
            aba: data.AbaIntermediario,
            chips: data.ChipsIntermediario,
            account: data.ContaIntermediario,
            iban: data.IbanIntermediario
        }
    }
}