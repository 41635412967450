import * as React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, ModalFooter, Tfoot, Box } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    ColumnDef,
    SortingState,
    getSortedRowModel
} from "@tanstack/react-table";

export type DataTableProps<Data extends object> = {
    isOpen: boolean;
    onClose: () => void;
    data: Data[];
    columns: ColumnDef<Data, any>[];
};

export function DataTable<Data extends object>({
    isOpen,
    onClose,
    data,
    columns
}: DataTableProps<Data>) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting
        }
    });


    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={"3xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Selecione um Banco</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box overflowY="auto" maxHeight="500px">
                        <Table variant='simple' colorScheme='primary'>
                            <Thead position="sticky" top={0}>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <Tr backgroundColor={"primary.400"} key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                                            const meta: any = header.column.columnDef.meta;
                                            return (
                                                <Th
                                                    color={"white"}
                                                    key={header.id}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    isNumeric={meta?.isNumeric}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}

                                                    <chakra.span pl="4">
                                                        {header.column.getIsSorted() ? (
                                                            header.column.getIsSorted() === "desc" ? (
                                                                <TriangleDownIcon aria-label="sorted descending" />
                                                            ) : (
                                                                <TriangleUpIcon aria-label="sorted ascending" />
                                                            )
                                                        ) : null}
                                                    </chakra.span>
                                                </Th>
                                            );
                                        })}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows.map((row) => (
                                    <Tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                                            const meta: any = cell.column.columnDef.meta;
                                            return (
                                                <Td key={cell.id} isNumeric={meta?.isNumeric}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </Td>
                                            );
                                        })}
                                    </Tr>
                                ))}
                            </Tbody>
                            <Tfoot position="sticky" bottom={0}>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <Tr backgroundColor={"primary.400"} key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                                            const meta: any = header.column.columnDef.meta;
                                            return (
                                                <Th
                                                    color={"white"}
                                                    key={header.id}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    isNumeric={meta?.isNumeric}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}

                                                    <chakra.span pl="4">
                                                        {header.column.getIsSorted() ? (
                                                            header.column.getIsSorted() === "desc" ? (
                                                                <TriangleDownIcon aria-label="sorted descending" />
                                                            ) : (
                                                                <TriangleUpIcon aria-label="sorted ascending" />
                                                            )
                                                        ) : null}
                                                    </chakra.span>
                                                </Th>
                                            );
                                        })}
                                    </Tr>
                                ))}
                            </Tfoot>
                        </Table>
                    </Box>
                </ModalBody>
                <ModalFooter fontSize={"xs"}>*Dados listados obtidos através do SysCâmbio.</ModalFooter>
            </ModalContent>
        </Modal>
    );
}
