import GenericButton from 'components/generic/GenericButton'
import {
  InputCurrency,
  InputDate,
  InputText
} from 'components/generic/GenericInputs'
import OperationShowPage from 'components/generic/operations/OperationShowPage'
import { axiosWebsite } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { usePdfExport } from 'hooks/helpers/usePdfExport'
import { OperationModel } from 'interfaces/operation'
import { SetModel } from 'interfaces/set_model'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setGlobalMessage, clearLoading, showLoading } from 'redux/actions'
import styled from 'styled-components'

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
`

const TitlePage = styled.h4`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray2};
  align-self: flex-start;
  padding: 1rem 0;
`

const BottomLine = styled.div`
  width: 100%;
  padding-top: 1rem;
  border-bottom: ${(props) => props.theme.color.primary_main} 1px solid;
`

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  padding: 0 2rem;
  margin-top: 2rem;
`

const TieButton = styled.div`
  display: flex;
  flex-direction: row;
`

const ButtonConfirm = styled(GenericButton)<{
  isDeny?: boolean
  isClosedContract?: boolean
}>`
  margin: 1rem 0.2rem;
  min-width: 180px;
  color: ${(props) =>
    props.isDeny
      ? props.theme.color.white1
      : props.isClosedContract
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isClosedContract
      ? props.theme.color.button_normal
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isClosedContract
        ? props.theme.color.button_normal_hover
        : props.theme.color.secondary_light};
  }
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
  padding: 1rem 2rem;
`

const TieInput = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled.h4`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray2};
  align-self: flex-start;
  padding: 1rem 0;
`

const Base = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const BaseRead = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Label = styled.label`
  font-family: Montserrat;
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  color: ${(props) => props.theme.color.gray3};
  margin-left: 0.3rem;
`

const InputRead = styled.input<{
  isBlock?: boolean
  maxWidth?: string
  isReadOnly?: boolean
}>`
  width: 100%;
  height: 40px;
  padding: 0.3rem;
  margin: 0.3rem;
  outline: none;
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.text3};
  background: ${(props) =>
    props.isReadOnly ? props.theme.color.white3 : props.theme.color.white1};
  border: 1px solid
    ${(props) =>
      props.isReadOnly ? props.theme.color.gray3 : props.theme.color.gray4};
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  box-sizing: border-box;
  border-radius: 6px;
  text-align: start;
  max-width: ${(props) => props.maxWidth};
`

const CardGrid = styled.div`
  width: 950px;
  height: 600px;
  overflow: auto;
  margin-bottom: 2rem;
`

const TableGrid = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns:
    minmax(80px, 100px) minmax(150px, 200px) minmax(150px, 200px)
    minmax(150px, 200px) minmax(150px, 200px) auto;
  height: auto;
  width: 100%;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

interface Props {
  typeOperation: string
  pastRender: string
  changeRender: (value: string) => void
  isReadOperation: boolean
  setIsReadOperation: (value: boolean) => void
  operationId: number
  userSyscambio: number
}

const ViewOperation = ({
  typeOperation,
  pastRender,
  changeRender,
  isReadOperation,
  setIsReadOperation,
  operationId,
  userSyscambio
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [data, setData] = useState<OperationModel>({
    operation_value: ''
  } as OperationModel)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gameSetList, setGameSetList] = useState<SetModel[]>([])

  const { createOperationContract } = usePdfExport()

  const getOperationData = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const body = {
        operation_number: operationId,
        id_syscambio: userSyscambio
      }

      const { data } = await axiosWebsite.post(
        `/operation/posfec/search`,
        body,
        config
      )
      setData(data[0])
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  useEffect(() => {
    if (isReadOperation === true) {
      getOperationData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <OperationShowPage
      title={`Visualizar Operação - #${data.operation_number}`}
      operation={data as any}
      canExportPdf
      goBack={() => {
        changeRender('Home')
      }}
    />
  )
}

export default ViewOperation
