import { SquareButton } from "components/generic/helpers";
import { OperationDocumentModel } from "interfaces/operation_document";
import React from "react";
import styled from "styled-components";
import { ReactComponent as DownloadIcon } from "assets/download-icon.svg";
import { toCurrency } from "helpers/moedas";
import { useGlobals } from "hooks/helpers/useGlobals";

const CardGrid = styled.div`
  width: 100%;
  overflow: auto;
  margin-bottom: 2rem;
`;

const TableGrid = styled.div`
  background-color: ${props => props.theme.color.white1};
  border-radius: 4px;
  display: grid;
  grid-template-columns:
    minmax(80px, 100px) minmax(150px, 200px) minmax(150px, 200px)
    minmax(150px, 200px) minmax(150px, 200px) auto;
  height: auto;
  width: 100%;
  overflow: auto;
`;

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${props => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${props => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Text = styled.p<{
  Color?: string;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: boolean;
}>`
  font-size: ${props =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${props => (props.Color ? props.Color : props.theme.color.gray1)};
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;
  text-align: ${props => (props.textAlign ? "center" : "none")};
`;

const ColumnText = styled.div<{ background?: boolean }>`
  border-left: 1px solid ${props => props.theme.color.white5};
  border-right: 1px solid ${props => props.theme.color.white5};
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.white5};
  padding: 0 0.5rem;
  background: ${props =>
    props.background ? props.theme.color.white1 : props.theme.color.white2};
`;

interface OperationDocsListProps {
  documents: OperationDocumentModel[];
}

const OperationDocsList: React.FC<OperationDocsListProps> = ({ documents }) => {
  const { setMessage } = useGlobals();
  return (
    <>
      <CardGrid>
        <TableGrid>
          <GridTitle>
            <Text Color="white" fontWeight="bold">
              Tipo
            </Text>
          </GridTitle>
          <GridTitle>
            <Text Color="white" fontWeight="bold">
              Número
            </Text>
          </GridTitle>
          <GridTitle>
            <Text Color="white" fontWeight="bold">
              Valor
            </Text>
          </GridTitle>
          <GridTitle>
            <Text Color="white" fontWeight="bold">
              Valor aplicado
            </Text>
          </GridTitle>
          <GridTitle>
            <Text Color="white" fontWeight="bold">
              Valor a aplicar
            </Text>
          </GridTitle>
          <GridTitle>
            <Text Color="white" fontWeight="bold">
              Anexo
            </Text>
          </GridTitle>
          {documents.map((document, keyDocument) => (
            <React.Fragment key={`documentItem${keyDocument}`}>
              <ColumnText>
                <Text>{document.document_type}</Text>
              </ColumnText>
              <ColumnText>
                <Text>{document.document_number}</Text>
              </ColumnText>
              <ColumnText>
                <Text>{toCurrency(document.total_value)}</Text>
              </ColumnText>
              <ColumnText>
                <Text>{toCurrency(document.applied_value)}</Text>
              </ColumnText>
              <ColumnText>
                <Text>{toCurrency(document.operation_value)}</Text>
              </ColumnText>
              <ColumnText>
                <SquareButton
                  as={document.attached_file ? "a" : "button"}
                  href={document.attached_file}
                  target={"_blank"}
                  onClick={() => {
                    if (!document.attached_file) {
                      setMessage({
                        type: "WARN",
                        message: "Arquivo inexistente.",
                      });
                    }
                  }}
                >
                  <DownloadIcon />
                </SquareButton>
              </ColumnText>
            </React.Fragment>
          ))}
        </TableGrid>
      </CardGrid>
    </>
  );
};

export default OperationDocsList;
