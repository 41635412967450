export interface SupplierPortalModel {
    id: number;
    company_id: number;
    provider_name: string;
    user_name: string;
    company_name: string;
    provider_email: string;
    login: string;
    password: string;
    language_configuration?: LanguageConfigEnum;
    created_date?: Date;
    updated_date?: Date;
    status?: SupplierStatusEnum;
    access_token?: string;
    recover_token?: string;
    refresh_token?: string;
    accounts?: []
}

export enum LanguageConfigEnum {
    PTBR = 'pt-BR',
    ENUS = 'en-US'
}

export enum SupplierStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export namespace SupplierStatusEnum {
    export function format(value: SupplierStatusEnum): string {
        switch (value) {
            case SupplierStatusEnum.ACTIVE:
                return "Ativo";
            case SupplierStatusEnum.INACTIVE:
                return "Inativo";
            default:
                return "Desconhecido";
        }
    }
}