import { useQuery } from "react-query";
import { apiClient } from "v2/services/clients/apiClient";
import { makeQuerySmallCache } from "v2/services/factories/react-query";

export function useCurrencyQuery() {
  const query = useQuery(
    ["currencies"],
    () => {
      return apiClient.currencies.all();
    },
    makeQuerySmallCache()
  );

  return query;
}
