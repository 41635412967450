import Account from "components/generic/BankAccount";
import GenericButton from "components/generic/GenericButton";
import styled from "styled-components";
import {
  InputAccount,
  InputAgency,
  InputDate,
  InputSelect,
  InputSelectBank,
  InputText,
} from "components/generic/GenericInputs";
import { BankEntity, BankModel } from "interfaces/bank";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading,
} from "redux/actions";
import { axiosAuth } from "helpers/axiosInstances";
import { isUnauthorized } from "helpers/errors";
import { useHistory } from "react-router-dom";
import { UserModel } from "interfaces/user";
import ModalPickBank from "components/generic/logic/ModalPickBank";
import { ReactComponent as LupaIcon } from "assets/lupaIcon.svg";
import { BankAgencyModel } from "interfaces/bankAgency";
import ModalPickAgency from "components/generic/logic/ModalPickAgency";
import StateCityBanksPick from "components/generic/logic/StateCityBanksPick";
import { BiSearchAlt2 } from "react-icons/bi";
import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";

const TableGrid = styled.div<{ noAction?: boolean }>`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.noAction
      ? "200px 100px 150px 150px"
      : "200px 100px 150px 150px auto"};
  grid-auto-rows: 35px;
  height: auto;
  width: auto;
  max-height: 300px;
  overflow: auto;
`;

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Text = styled.p<{
  Color?: string;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: boolean;
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? "center" : "none")};
`;

const ButtonYellow = styled.button<{ marginBottom?: string }>`
  width: 40px;
  height: 40px;
  padding: 0.3rem;
  background-color: ${(props) => props.theme.color.secondary_main};
  border-radius: 6px;
  outline: none;
  border: none;
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  cursor: pointer;
  transform: scale(0.95);
  margin-bottom: ${(props) => props.marginBottom};

  & svg {
    width: auto;
    height: 100%;
    & path {
      fill: ${(props) => props.theme.color.primary_main};
    }
  }

  &:hover {
    transform: scale(1);
  }
`;

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`;

const TitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`;

const SubtitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.color.gray4};
  font-weight: bold;
  line-height: 24px;
`;

const Button = styled(GenericButton) <{
  width?: string;
  isDisabled?: boolean;
  isDeny?: boolean;
}>`
  margin: 1rem 0;
  margin-right: 0.5rem;
  padding: 1rem;
  width: ${(props) => (props.width ? props.width : "260px")};
  color: ${(props) =>
    props.isDisabled
      ? props.theme.color.white1
      : props.isDeny
        ? props.theme.color.white1
        : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.color.gray3
      : props.isDeny
        ? props.theme.color.button_deny
        : props.theme.color.secondary_main};
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${(props) =>
    props.isDisabled
      ? props.theme.color.gray4
      : props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`;

interface Props {
  userId: number | undefined;
  accountBanks: BankModel[];
  getBankData: () => void;
  userData: UserModel;
  setAccountBanks: (value: BankModel[]) => void;
}

const BankAccountPhysical = ({
  userId,
  accountBanks,
  setAccountBanks,
  getBankData,
  userData,
}: Props): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const [isAgencyOpen, setAgencyOpen] = useState(false);
  const [bankEntity, setBankEntity] = useState<BankEntity>();
  const [editUser, setEditUser] = useState<boolean>(false);
  const [bankData, setBankData] = useState<BankModel>({
    bank_code: "",
    bank_name: "",
    bank_agency: "",
    bank_account: "",
    opening_date: "",
    bank_state: "",
    bank_city: "",
    account_type: "C",
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const removeToEdit = (index: number) => {
    let res = [...accountBanks];
    res.splice(index, 1);
    setAccountBanks(res);
  };

  const refreshBanks = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    await axiosAuth
      .get(`/bank/${userData.id}`, config)
      .then((res) => res.data)
      .then(setAccountBanks);
  };

  const handleSubmitBank = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );

      const body = {
        user_id: userId,
        bank_code: bankData.bank_code,
        bank_name: bankData.bank_name,
        bank_agency: bankData.bank_agency,
        bank_account: bankData.bank_account,
        opening_date: bankData.opening_date,
        bank_city: bankData.bank_city,
        bank_state: bankData.bank_state,
        account_type: bankData.account_type,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      console.log({ editUser, userData });

      if (editUser) {
        const res = await axiosAuth.put(`/bank/${bankData.id}`, body, config);
        const resBank = await axiosAuth.get(`/bank/${userData.id}`, config);
        setAccountBanks(resBank.data);

        if (userData.id_syscambio && res) {
          if (resBank) {
            const body = {
              user: userData,
              bank: resBank.data,
              beneficiary: [],
            };
            await axiosAuth.put(`/user/update`, body, config);
          }
        }
      } else {
        const res = await axiosAuth.post(`/bank`, body, config);
        const resBank = await axiosAuth.get(`/bank/${userData.id}`, config);
        setAccountBanks(resBank.data);

        if (userData.id_syscambio && res) {
          if (resBank) {
            const body = {
              user: userData,
              bank: resBank.data,
              beneficiary: [],
            };
            await axiosAuth.put(`/user/update`, body, config);
          }
        }
      }

      dispatch(setSuccessMessage("Conta cadastrada com sucesso!"));
      // clearBank();
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  const clearBank = () => {
    setBankData({
      ...bankData,
      bank_code: "",
      bank_name: "",
      bank_agency: "",
      bank_account: "",
      opening_date: "",
      account_type: "C",
      bank_state: "",
      bank_city: "",
    });
    setEditUser(false);
  };

  const clearBankSubFields = () => {
    setBankData({
      ...bankData,
      bank_agency: "",
      bank_account: "",
      opening_date: "",
      account_type: "C",
      bank_state: "",
      bank_city: "",
    });
    setEditUser(false);
  };

  useEffect(() => {
    getBankData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <DivItems>
        <TitleItems>Dados Bancários</TitleItems>
        {userData.status !== "EVALUATION" ? (
          <SubtitleItems>
            Para adicionar uma conta basta preencher os campos e clicar em
            adicionar conta
          </SubtitleItems>
        ) : null}
      </DivItems>
      {userData.status !== "EVALUATION" ? (
        <DivItems>
          <ItemRow>
            <InputText
              label="Banco"
              maxWidth="500px"
              readOnly
              value={bankData?.bank_name}
            />
            <ButtonYellow
              type="button"
              marginBottom=".3rem"
              onClick={() => setOpen(() => true)}
            >
              <BiSearchAlt2 />
            </ButtonYellow>
            <div style={{ display: "flex", width: 500, marginLeft: 20 }}>
              <StateCityBanksPick
                value={{
                  city: bankData?.bank_city,
                  state: bankData?.bank_state,
                }}
                bankCode={bankData?.bank_code || 0}
                onChange={(v) => {
                  // setFilter(() => v);
                  setBankData((oldBank) => ({
                    ...oldBank,
                    bank_city: v.city,
                    bank_state: v.state,
                  }));
                }}
              />
            </div>
            <ModalPickBank
              onChange={(nextBank) => {
                setBankEntity(() => nextBank);
                setBankData((oldBankData) => ({
                  // ...oldBankData,
                  // ...nextBank,
                  ...oldBankData,
                  bank_name: nextBank.bank_info,
                  bank_code: nextBank.bank_code,
                }));
                // clearBankSubFields()
                setOpen(() => false);
              }}
              value={bankEntity}
              onClose={() => setOpen(() => false)}
              isOpen={isOpen}
            />
          </ItemRow>
          <ItemRow>
            <InputAgency
              readOnly
              label="Agência"
              maxWidth="150px"
              value={`${bankData?.bank_agency}`}
            />
            <ButtonYellow
              type="button"
              disabled={
                bankData?.bank_code &&
                  bankData?.bank_state &&
                  bankData?.bank_city
                  ? false
                  : true
              }
              onClick={() => setAgencyOpen(() => true)}
            >
              <BiSearchAlt2 />
            </ButtonYellow>
            {bankData?.bank_code &&
              bankData?.bank_state &&
              bankData?.bank_city && (
                <ModalPickAgency
                  // {...filter}
                  state={bankData.bank_state}
                  city={bankData.bank_city}
                  onClose={() => setAgencyOpen(() => false)}
                  isOpen={isAgencyOpen}
                  bankCode={bankData.bank_code}
                  onChange={(agency) => {
                    setAgencyOpen(() => false);
                    setBankData((old) => ({
                      ...old,
                      bank_agency: String(agency.CodAgencia),
                    }));
                  }}
                />
              )}
            <div>
              <InputSelect
                label={"Tipo de Conta"}
                value={bankData.account_type}
                changeValue={(v) => {
                  setBankData((oldBank) => ({
                    ...oldBank,
                    account_type: v as any,
                  }));
                }}
              >
                <option value={"C"}>Conta Corrente</option>
                <option value={"P"}>Conta Poupança</option>
              </InputSelect>
            </div>
            <InputAccount
              label={
                bankData.account_type === "C"
                  ? "Conta Corrente"
                  : "Conta Poupança"
              }
              maxWidth="200px"
              value={bankData.bank_account}
              changeValue={(value) =>
                setBankData({
                  ...bankData,
                  bank_account: value,
                })
              }
            />
            <InputDate
              label="Data de Abertura"
              maxWidth="200px"
              max={new Date().toISOString().split("T")[0]}
              value={bankData.opening_date}
              changeValue={(value) =>
                setBankData({
                  ...bankData,
                  opening_date: value,
                })
              }
            />
          </ItemRow>
          <Alert
            backgroundColor={"#f9f1e2"}
            status='warning'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'>
            <AlertIcon />
            <AlertTitle fontSize={"medium"} mt={4} mb={1}>
              Importante! Para cadastro de uma Agência Bancária é necessário selecionar o Banco, Estado e Cidade.
            </AlertTitle>
            <AlertDescription fontSize={"xs"}>
              Caso não sejam encontrados dados para os itens selecionados ou não encontre alguma informação, por favor entre em contato com a equipe DASCAM.
            </AlertDescription>
          </Alert>
          <ItemRow>
            {editUser ? (
              <Button
                isDeny
                type="button"
                width="180px"
                onClick={() => {
                  getBankData();
                  clearBank();
                  setEditUser(!editUser);
                }}
              >
                Cancelar
              </Button>
            ) : null}
            <Button
              isDisabled={
                bankData.bank_code !== "" &&
                  bankData.bank_name !== "" &&
                  bankData.bank_agency !== "" &&
                  bankData.bank_account !== "" &&
                  bankData.opening_date !== ""
                  ? false
                  : true
              }
              type="button"
              width="180px"
              onClick={() =>
                bankData.bank_code !== "" &&
                  bankData.bank_name !== "" &&
                  bankData.bank_agency !== "" &&
                  bankData.bank_account !== "" &&
                  bankData.opening_date !== ""
                  ? handleSubmitBank()
                  : null
              }
            >
              {editUser ? "Alterar" : "Adicionar Conta +"}
            </Button>
          </ItemRow>
        </DivItems>
      ) : null}
      <DivItems>
        <TableGrid noAction={userData.status === "EVALUATION"}>
          <GridTitle>
            <Text fontWeight="bold">Banco</Text>
          </GridTitle>
          <GridTitle>
            <Text fontWeight="bold">Agência</Text>
          </GridTitle>
          <GridTitle>
            <Text fontWeight="bold">Conta Corrente</Text>
          </GridTitle>
          <GridTitle>
            <Text fontWeight="bold">Data de abertura</Text>
          </GridTitle>
          {userData.status !== "EVALUATION" ? (
            <GridTitle>
              <Text fontWeight="bold">Ações</Text>
            </GridTitle>
          ) : null}
          {/* <GridTitle>
            <Text fontWeight="bold">Status</Text>
          </GridTitle> */}
          {accountBanks.map((account, index) => {
            let last = false;
            if (index === accountBanks.length - 1) {
              last = true;
            }
            return (
              <Account
                last={last}
                index={index}
                account={account}
                getBankData={() => getBankData()}
                userData={userData}
                editUser={editUser}
                setEditUser={setEditUser}
                setBankData={setBankData}
                removeToEdit={removeToEdit}
                onDelete={refreshBanks}
              />
            );
          })}
        </TableGrid>
      </DivItems>
    </>
  );
};

export default BankAccountPhysical;
