import { OperationDocumentModel } from "./operation_document";

export interface OperationModel {
  ispb?: number
  inst_id?: number
  operator_id?: string;
  operator?: string;
  iof?: string;
  vet?: string;
  value_base_ir?: string;
  imp?: Array<{
    Tipo: string;
    Valor: number;
    PercImposto: number;
    RecolhimentoPorConta: string;
  }>;
  id?: number;
  user_id?: string;
  operation_number?: string;
  user_syscambio: string | undefined;
  product_type?: ProductTypeEnum;
  operation_type?: OperationTypeEnum;
  finality_type?: FinalityTypeEnum;
  nature_fact?: string;
  nature_client_type?: string;
  nature_aval?: string;
  nature_beneficiary?: string;
  nature_group?: string;
  exchange_trader_code?: string;
  exchange_trader?: string;
  CodFormaEntrMoeda?: number; // alterar
  CodFormaEntrReais?: number; // alterar
  client_code?: string;
  client_name?: string;
  client_document?: string;
  financial_institution_code?: string;
  financial_institution?: string;
  financial_institution_doc?: string;
  currency_code?: string;
  currency_iso?: string;
  currency_name?: string;
  operation_value?: string;
  reais_value?: string;
  currency_closure?: string;
  bacen_days?: string;
  bacen_closure?: string;
  bacen_in?: string;
  bacen_number?: string;
  reais_days?: string;
  reais_closure?: string;
  beneficiary_code?: string;
  beneficiary_name?: string;
  beneficiary_detail_charge?: string;
  beneficiary_observacao?: string;
  beneficiary_identification?: string;
  beneficiary_nif?: string;
  country_code?: string;
  country_name?: string;
  country_initials?: string;
  beneficiary_address?: string;
  vinculo?: string;
  beneficiary_city?: string;
  bank_info?: string;
  bank_country_code?: string;
  bank_country_initials?: string;
  bank_country?: string;
  bank_city?: string;
  bank_address?: string;
  bank_swift?: string;
  bank_aba?: string;
  bank_chips?: string;
  bank_account?: string;
  bank_iban?: string;
  bank_sortcode?: string;
  bank_blz?: string;
  bank_branchcode?: string;
  bank_bsb?: string;
  bank_transit?: string;
  bank_cnaps?: string;
  ir?: {
    Percentual: number;
    Tributo: number;
    Reajustado: string;
    TaxaCalculo: number;
    VlrIncidente: number;
    VlrRecolhido: number;
    CodDarf: number;
    VlrBaseCalculo: number;
  };
  ir_perc?: number;
  taxa?: string;
  instituicao?: string;
  instituicao_doc?: string;
  intermediary_info?: string;
  intermediary_country_initials?: string;
  intermediary_country?: string;
  intermediary_city?: string;
  intermediary_address?: string;
  intermediary_swift?: string;
  intermediary_aba?: string;
  intermediary_chips?: string;
  intermediary_account?: string;
  intermediary_iban?: string;
  forma_entrada_moeda?: string;
  forma_entrada_reais?: string;
  status?: OperationStatusEnum;
  created_date?: string;
  created_by_id?: number;
  updated_date?: string;
  updated_by_id?: number;
  documents?: OperationDocumentModel[];
  process_expiration?: string;
  process_number?: number;
  process_value?: number;
  flag?: string;
}

export enum OperationTypeEnum {
  COMPRA = "COMPRA",
  VENDA = "VENDA",
}

export enum ProductTypeEnum {
  IMPORTACAO = "IMPORTACAO",
  EXPORTACAO = "EXPORTACAO",
  INGRESSO = "INGRESSO",
  REMESSA = "REMESSA",
}

export enum FinalityTypeEnum {
  COMERCIAL = "COMERCIAL",
}

export enum OperationStatusEnum {
  SENT = "SENT", // Cliente = Enviado; Dascam = Em Analise
  PENDING = "PENDING", // Cliente = Em Rascunho
  APPROVAL = "APPROVAL", // Cliente + Dascam = Aguardando Aprovação do Cliente
  CANCELED = "CANCELED", // Cancelado/Desaprovado pelo Cliente
  RELEASE = "RELEASE", // Aprovado/Liberado pelo Cliente
  CORRECTION = "CORRECTION", // Voltou Ao Cliente para Correção
}

export namespace OperationStatusEnum {
  export function format(value: OperationStatusEnum): string {
    switch (value) {
      case OperationStatusEnum.APPROVAL:
        return "Aprovado";
      case OperationStatusEnum.CANCELED:
        return "Cancelado";
      case OperationStatusEnum.CORRECTION:
        return "Em correção";
      case OperationStatusEnum.PENDING:
        return "Rascunho";
      case OperationStatusEnum.RELEASE:
        return "Liberado";
      case OperationStatusEnum.SENT:
        return "Em análise";
      default:
        return "Desconhecido";
    }
  }
}