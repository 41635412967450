import styled from 'styled-components'
import {
  InputCelPhone,
  InputCEP,
  InputCNPJ,
  InputDate,
  InputEmail,
  InputPhone,
  InputText,
  InputUF,
  InputCurrency,
  InputCountry,
  InputActivityMain,
  InputDDDPhone
} from 'components/generic/GenericInputs'
import CheckBox from 'components/generic/CheckBox'
import { UserModel } from 'interfaces/user'
import UserPickEntityLegalNature from 'components/generic/logic/UserPickEntityLegalNature'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { Col } from 'components/generic/helpers'
import { formatCNPJ, formatCelPhone, formatMoney } from 'helpers/formats'
import { useEffect, useMemo, useState } from 'react'
import { LegalEntityModel } from 'interfaces/legalNature'
import { axiosWebsite } from 'helpers/axiosInstances'

const HeaderCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`

const TitleCard = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`

const SubtitleCard = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.color.gray4};
  font-weight: bold;
  line-height: 24px;
`

const DivItems = styled.div`
  width: 100%;
  height: auto
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`

const TitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`

const StatusUser = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  height: auto;
  margin: 0 0.5rem;
  & p {
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 23px;
    margin: 0 0.3rem;
  }
`

const StatusCircle = styled.span<{ color?: string }>`
  margin: 0 0.5rem;
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  margin-right: 0.3rem;
  background-color: ${(props) =>
    props.color === 'DISAPPROVED'
      ? props.theme.color.deny
      : props.color === 'PENDING'
      ? props.theme.color.warn
      : props.color === 'EVALUATION'
      ? props.theme.color.primary_light
      : props.color === 'BLOCKED'
      ? props.theme.color.deny
      : props.theme.color.approval};
`

interface Props {
  userData: UserModel
  changeUserData: (userData: UserModel) => void
  mandatory: any
}

const RegisterLegal = ({
  userData,
  changeUserData,
  mandatory
}: Props): JSX.Element => {
  const [entities, setEntities] = useState<LegalEntityModel[]>([])

  const loadEntity = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    axiosWebsite
      .get('/entidades', config)
      .then((res) => res.data)
      .then(setEntities)
  }

  useEffect(() => {
    //loadEntity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <HeaderCard>
        <div>
          <TitleCard>Meus dados cadastrais</TitleCard>
          <SubtitleCard>
            Para alterar seu cadastro basta clicar, alterar e salvar suas
            alterações
          </SubtitleCard>
        </div>
        <div>
          <StatusUser>
            {userData.responsible_sector !== 'CLIENTE' ? (
              <p>
                Área/Setor:{' '}
                <b>
                  {userData.responsible_sector === 'CADASTRO'
                    ? 'Cadastro'
                    : userData.responsible_sector === 'COMPLIANCE'
                    ? 'Compliance'
                    : 'Diretoria'}
                </b>
              </p>
            ) : null}
            <p>
              Status:{' '}
              <b>
                {userData.status === 'DISAPPROVED'
                  ? 'Reprovado'
                  : userData.status === 'PENDING'
                  ? 'Pendente'
                  : userData.status === 'EVALUATION'
                  ? 'Em análise'
                  : userData.status === 'BLOCKED'
                  ? 'Bloqueado'
                  : 'Aprovado'}
              </b>
            </p>
            <StatusCircle color={userData.status!} />
          </StatusUser>
        </div>
      </HeaderCard>
      {Object.keys(userData).length !== 0 && (
        <Col>
          <ItemRow>
            <CheckBox
              title='Sou Pessoa Politicamente Exposta'
              selected={userData.politically_exposed!}
              changeSelected={(value) =>
                changeUserData({
                  ...userData,
                  politically_exposed: value
                })
              }
              readOnly={userData.status === 'EVALUATION'}
            />
          </ItemRow>
          <SimpleFormGenerator
            rows={[
              {
                columns: 3,
                fields: [
                  {
                    type: 'text',
                    label: 'Razão Social *',
                    name: 'user_name',
                    readOnly: userData.status === 'EVALUATION'
                  },
                  {
                    type: 'text',
                    label: 'Nome Fantasia *',
                    name: 'fantasy_name',
                    readOnly: userData.status === 'EVALUATION'
                  },
                  {
                    type: 'text',
                    label: 'CNPJ *',
                    name: 'document',
                    readOnly: true, //userData.status === 'EVALUATION',
                    mask (data) {
                      return formatCNPJ(String(data))
                    }
                  }
                ]
              }
            ]}
            value={userData}
            onChange={(v) => {
              changeUserData({
                ...userData,
                user_name: v.user_name,
                fantasy_name: v.fantasy_name,
                document: v.document
              })
            }}
          />
          <SimpleFormGenerator
            rows={[
              {
                columns: 3,
                fields: [
                  {
                    type: 'date',
                    label: 'Data da Constituição da PJ *',
                    name: 'opening_date',
                    readOnly: userData.status === 'EVALUATION'
                  },
                  {
                    type: 'text',
                    label: 'Inscrição Municipal / CCM *',
                    name: 'municipalregistration_ccm',
                    readOnly: userData.status === 'EVALUATION'
                  },
                  {
                    type: 'text',
                    label: 'Inscrição Estadual *',
                    name: 'stateregistration',
                    readOnly: userData.status === 'EVALUATION'
                  }
                ]
              }
            ]}
            value={userData}
            onChange={(v) => {
              changeUserData({
                ...userData,
                opening_date: v.opening_date,
                municipalregistration_ccm: v.municipalregistration_ccm,
                stateregistration: v.stateregistration
              })
            }}
          />

          <ItemRow>
            <UserPickEntityLegalNature
              value={{
                entityId: Number(userData.entity),
                legalNatureId: userData.legal_nature
              }}
              onChange={(v) => {
                changeUserData({
                  ...userData,
                  legal_nature: v.legalNatureId,
                  entity: String(v.entityId)
                })
              }}
            />
            <InputCountry
              label='País'
              maxWidth='195px'
              value={userData.country!}
              changeValue={(value) =>
                changeUserData({
                  ...userData,
                  country: value
                })
              }
              readOnly={userData.status === 'EVALUATION'}
            />
            <InputActivityMain
              label='Atividade Principal *'
              maxWidth='300px'
              value={userData.main_activity!}
              changeValue={(value) =>
                changeUserData({
                  ...userData,
                  main_activity: value
                })
              }
              required
              isValid={mandatory.main_activity === false ? true : false}
              readOnly={userData.status === 'EVALUATION'}
            />
          </ItemRow>

          <SimpleFormGenerator
            rows={[
              {
                columns: 2,
                fields: [
                  {
                    type: 'text',
                    label: 'Website *',
                    name: 'website',
                    readOnly: userData.status === 'EVALUATION',
                    validation (value) {
                      return mandatory.website === false ? true : false
                    }
                  }
                ]
              }
            ]}
            value={userData}
            onChange={(v) => {
              changeUserData({
                ...userData,
                website: v.website
              })
            }}
          />

          <SimpleFormGenerator
            rows={[
              {
                columns: 2,
                fields: [
                  {
                    type: 'currency',
                    label: 'Patrimônio Líquido *',
                    name: 'net_worth',
                    readOnly: userData.status === 'EVALUATION',
                    validation (value) {
                      return mandatory.net_worth === false ? true : false
                    }
                  },
                  {
                    type: 'currency',
                    label: 'Faturamento Médio Mensal *',
                    name: 'average_billing',
                    readOnly: userData.status === 'EVALUATION',
                    validation (value) {
                      return mandatory.net_worth === false ? true : false
                    }
                  }
                ]
              }
            ]}
            value={userData}
            onChange={(v) => {
              changeUserData({
                ...userData,
                net_worth: v.net_worth,
                average_billing: v.average_billing
              })
            }}
          />

          <DivItems>
            <TitleItems>Contato / Responsável</TitleItems>
          </DivItems>

          <SimpleFormGenerator
            rows={[
              {
                columns: 3,
                fields: [
                  {
                    type: 'text',
                    label: 'Nome Contato *',
                    name: 'contact_name',
                    readOnly: userData.status === 'EVALUATION',
                    validation (value) {
                      return mandatory.contact_name === false ? true : false
                    }
                  },
                  {
                    type: 'text',
                    label: 'Cargo *',
                    name: 'contact_role',
                    readOnly: userData.status === 'EVALUATION',
                    validation (value) {
                      return mandatory.contact_role === false ? true : false
                    }
                  },
                  {
                    type: 'text',
                    label: 'Departamento *',
                    name: 'contact_branch',
                    readOnly: userData.status === 'EVALUATION',
                    validation (value) {
                      return mandatory.contact_branch === false ? true : false
                    }
                  }
                ]
              },
              {
                columns: 4,
                fields: [
                  {
                    type: 'number',
                    label: 'DDD *',
                    name: 'ddd_cellphone',
                    readOnly: userData.status === 'EVALUATION'
                  },
                  {
                    type: 'text',
                    label: 'Celular *',
                    name: 'contact_cellphone',
                    readOnly: userData.status === 'EVALUATION',
                    mask (data) {
                      return formatCelPhone(data)
                    }
                  },
                  {
                    type: 'number',
                    label: 'DDD *',
                    name: 'ddd_phone',
                    readOnly: userData.status === 'EVALUATION'
                  },
                  {
                    type: 'text',
                    label: 'Telefone *',
                    name: 'contact_phone',
                    readOnly: userData.status === 'EVALUATION',
                    mask (data) {
                      return formatCelPhone(data)
                    }
                  }
                ]
              },
              {
                columns: 2,
                fields: [
                  {
                    type: 'text',
                    label: 'E-mail *',
                    name: 'email',
                    readOnly: userData.status === 'EVALUATION'
                  }
                ]
              }
            ]}
            value={userData}
            onChange={(v) => {
              changeUserData({
                ...userData,
                contact_name: v.contact_name,
                contact_role: v.contact_role,
                contact_branch: v.contact_branch,
                ddd_cellphone: v.ddd_cellphone,
                contact_cellphone: v.contact_cellphone,
                ddd_phone: v.ddd_phone,
                contact_phone: v.contact_phone,
                email: v.email
              })
            }}
          />

          <DivItems>
            <TitleItems>Dados de endereço</TitleItems>
          </DivItems>
          <InputCountry
            label='País *'
            maxWidth='190px'
            value={userData.country!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                country: value
              })
            }
            required
            isValid={mandatory.country === false ? true : false}
            readOnly={userData.status === 'EVALUATION'}
          />
          <SimpleFormGenerator
            rows={[
              {
                columns: 4,
                fields: [
                  {
                    type: 'text',
                    label: 'CEP *',
                    name: 'address_zipcode',
                    readOnly: userData.status === 'EVALUATION',
                    validation (value) {
                      return mandatory.address_zipcode === false ? true : false
                    }
                  },
                  {
                    type: 'text',
                    label: 'Cidade *',
                    name: 'address_city',
                    readOnly: userData.status === 'EVALUATION',
                    validation (value) {
                      return mandatory.address_city === false ? true : false
                    }
                  },
                  {
                    type: 'text',
                    label: 'UF *',
                    name: 'address_state',
                    readOnly: userData.status === 'EVALUATION',
                    validation (value) {
                      return mandatory.address_state === false ? true : false
                    }
                  },
                  {
                    type: 'text',
                    label: 'Bairro *',
                    name: 'address_district',
                    readOnly: userData.status === 'EVALUATION',
                    validation (value) {
                      return mandatory.address_district === false ? true : false
                    }
                  }
                ]
              },
              {
                columns: 2,
                fields: [
                  {
                    type: 'text',
                    label: 'Logradouro  *',
                    name: 'address_place',
                    readOnly: userData.status === 'EVALUATION'
                  },
                  {
                    type: 'number',
                    label: 'Número *',
                    name: 'address_number',
                    readOnly: userData.status === 'EVALUATION'
                  }
                ]
              },
              {
                columns: 1,
                fields: [
                  {
                    type: 'text',
                    label: 'Complemento',
                    name: 'address_complement',
                    readOnly: userData.status === 'EVALUATION'
                  }
                ]
              }
            ]}
            value={userData}
            onChange={(v) => {
              changeUserData({
                ...userData,
                address_zipcode: v.address_zipcode,
                address_city: v.address_city,
                address_state: v.address_state,
                address_district: v.address_district,
                address_place: v.address_place,
                address_number: v.address_number,
                address_complement: v.address_complement
              })
            }}
          />
        </Col>
      )}
    </>
  )
}

export default RegisterLegal
