import { CurrencyModel } from "interfaces/currency";
import { ApiContext } from "../ApiContext";

export class ApiCurrencies extends ApiContext {
  public async all() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http
      .get<CurrencyModel[]>(`/moeda`, config)
      .then((res) => res.data);
  }
}
