import Account from "components/generic/BankAccount";
import styled from "styled-components";
import { BankModel } from "interfaces/bank";
import { UserModel } from "interfaces/user";

const TableGrid = styled.div`
  background-color: ${props => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: 200px 100px 150px 150px;
  grid-auto-rows: 35px;
  height: auto;
  width: auto;
  max-height: 300px;
  overflow: auto;
`;

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${props => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${props => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Text = styled.p<{
  Color?: string;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: boolean;
}>`
  font-size: ${props =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${props => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;
  text-align: ${props => (props.textAlign ? "center" : "none")};
`;

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`;

const TitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${props => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`;

interface Props {
  userId: number | undefined;
  accountBanks: BankModel[];
  getBankData: () => void;
  userData: UserModel;
}

const BankAccountForeign = ({
  userId,
  accountBanks,
  getBankData,
  userData,
}: Props): JSX.Element => {
  return (
    <>
      <DivItems>
        <TitleItems>Dados Bancários</TitleItems>
      </DivItems>
      <DivItems>
        <TableGrid>
          <GridTitle>
            <Text fontWeight="bold">Banco</Text>
          </GridTitle>
          <GridTitle>
            <Text fontWeight="bold">Agência</Text>
          </GridTitle>
          <GridTitle>
            <Text fontWeight="bold">Conta Corrente</Text>
          </GridTitle>
          <GridTitle>
            <Text fontWeight="bold">Data de abertura</Text>
          </GridTitle>
          {accountBanks.map((account, index) => {
            let last = false;
            if (index === accountBanks.length - 1) {
              last = true;
            }
            return (
              <Account
                last={last}
                index={index}
                account={account}
                getBankData={() => getBankData()}
                userData={userData}
                readOnly
              />
            );
          })}
        </TableGrid>
      </DivItems>
    </>
  );
};

export default BankAccountForeign;
