import GenericButton from 'components/generic/GenericButton'
import { InputName } from 'components/generic/GenericInputs'
import Modal from 'components/generic/Modal'
import { axiosWebsite } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { CountryModel } from 'interfaces/country'
import { OperationModel } from 'interfaces/operation'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import styled from 'styled-components'
import { Country } from './LinesModals'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const Button = styled(GenericButton)<{ isDeny?: boolean }>`
  margin: 0.3rem 0;
  width: 150px;
  color: ${(props) => props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
`

const CardGrid = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.1rem;
`
const TableGrid = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: minmax(100px, 150px) minmax(300px, 350px);
  grid-auto-rows: 35px;
  height: 100%;
  max-height: 600px;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

interface Props {
  showModal: boolean
  handleModal: () => void
  data: OperationModel
  changeData: (value: OperationModel) => void
  isPayer: boolean
  isIntermediary: boolean
}

const FindCountry = ({
  showModal,
  handleModal,
  data,
  changeData,
  isPayer,
  isIntermediary
}: Props): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [countries, setCountries] = useState<CountryModel[]>([])
  const [values, setValues] = useState<CountryModel>({
    CodPaisIso3166: '',
    Nome: ''
  })
  const [valuesFilter, setValuesFilter] = useState<CountryModel>({
    CodPaisIso3166: '',
    Nome: ''
  })

  const handleGetCountries = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosWebsite.get(`/paises`, config)

      setCountries(data)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleFilter = () => {
    setValuesFilter({
      CodPaisIso3166: values.CodPaisIso3166,
      Nome: values.Nome
    })
  }

  const close = () => {
    handleModal()
    setValuesFilter({
      CodPaisIso3166: '',
      Nome: ''
    })
    setValues({
      CodPaisIso3166: '',
      Nome: ''
    })
  }

  useEffect(() => {
    if (showModal) {
      handleGetCountries()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])
  return (
    <Modal
      title='Buscar País'
      showModal={showModal}
      close={() => handleModal()}
      backFocus
      width='100%'
      maxWidth='500px'
      height='100%'
      maxHeight='85vh'
    >
      <Container>
        <ItemRowTop>
          <InputName
            label='Sigla'
            maxWidth='120px'
            maxLength={3}
            value={values.CodPaisIso3166}
            changeValue={(value) =>
              setValues({
                ...values,
                CodPaisIso3166: value
              })
            }
            readOnly={String(values.Nome) !== ''}
          />
          <InputName
            label='País'
            maxWidth='190px'
            maxLength={3}
            value={values.Nome}
            changeValue={(value) =>
              setValues({
                ...values,
                Nome: value
              })
            }
            readOnly={String(values.CodPaisIso3166) !== ''}
          />
          <Button type='button' onClick={() => handleFilter()}>
            Buscar
          </Button>
        </ItemRowTop>
        <CardGrid>
          <TableGrid>
            <GridTitle>
              <Text fontWeight='bold'>Sigla</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>País</Text>
            </GridTitle>
            {/*Mudar depois*/}
            {countries
              .filter(
                (e) =>
                  String(e.CodPaisIso3166)
                    ?.toLowerCase()
                    .indexOf(
                      String(valuesFilter.CodPaisIso3166).toLowerCase()
                    ) > -1 &&
                  String(e.Nome)
                    ?.toLowerCase()
                    .indexOf(String(valuesFilter.Nome).toLowerCase()) > -1
              )
              .map((country, index) => {
                let last = false
                if (index === countries.length - 1) {
                  last = true
                }
                return (
                  <Country
                    last={last}
                    index={index}
                    country={country}
                    handleModal={close}
                    data={data}
                    changeData={changeData}
                    isPayer={isPayer}
                    isIntermediary={isIntermediary}
                  />
                )
              })}
          </TableGrid>
        </CardGrid>
      </Container>
    </Modal>
  )
}

export default FindCountry
