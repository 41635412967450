import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { axiosWebsite } from 'helpers/axiosInstances'
import { ExchangeScheduleModel } from 'interfaces/exchange-schedule'
import { BsFillXOctagonFill } from 'react-icons/bs'
import './style.css'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import { createCustomerSearchPicker } from 'v2/services/app/factories/forms/createCustomerSearchPicker'
import { Button } from '@chakra-ui/button'
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Collapse,
  HStack,
  IconButton,
  Progress,
  useBoolean,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { getMoeda } from 'helpers/moedas'
import { formatDate, formatProduct } from 'helpers/formats'

import DataTable, { TableColumn } from 'react-data-table-component'
import { clearLoading, showLoading } from 'redux/actions'
import { useDispatch } from 'react-redux'
import { ExcelExportXlsx } from 'services/ExcelExportXlsx'
import AppContext from 'hooks/helpers/useLastFilter'
import { Permissions } from 'interfaces/web/permission'

const ProcessToCloseInternal: React.FC = () => {
  const [isLoading, loading] = useBoolean()

  const { user, hasPermissions } = useGlobals()

  const date = new Date().toISOString().split('T')[0]
  const Default = AppContext?.apar_review
    ? AppContext?.apar_review
    : {
        start_date: date,
        end_date: date
      }
  useEffect(() => {
    redirect(hashData)
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [process, setProcess] = useState<ExchangeScheduleModel[]>([])
  const [processSelect, setSelectProcess] = useState<ExchangeScheduleModel[]>(
    []
  )
  const [dataInput, setDataInput] = useState<any>({})
  const [dateInitials, setDateInitials] = useState<string>()
  const [dateFinal, setDateFinal] = useState<string>()
  const [showAlert, setShowAlert] = useBoolean()
  const [idSyscambio, setSyscambio] = useState(Number(user!.id_syscambio!))
  const { hashData, redirect } = useHashData(Default)
  const dispatch = useDispatch()
  const [toggledClearRows, setToggleClearRows] = React.useState(false)
  const [idToCancel, setIdToCancel] = useState<Number>()
  const [listType, setListType] = useState<string>()
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows)
  }
  const handleRowSelected = React.useCallback((state) => {
    setSelectProcess(state.selectedRows)
  }, [])

  const rowDisabledCriteria = (row: ExchangeScheduleModel) =>
    row.released === true

  const columns: TableColumn<ExchangeScheduleModel>[] = [
    {
      name: 'PROCESSO',
      selector: (row) => row.process ?? '',
      sortable: true,
      width: '120px',
      conditionalCellStyles: [
        {
          when: (row) => row.released === true,
          style: {
            color: 'green',
            fontWeight: 'bold'
          }
        },
        {
          when: (row) => !row.released,
          style: {
            color: 'firebrick',
            fontWeight: 'bold'
          }
        }
      ]
    },
    {
      name: 'LIBERADO PARA O DIA',
      selector: (row) =>
        row.created_date
          ? new Date(row.created_date)
              ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '---',
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.created_date !== undefined,
          style: {
            color: 'green'
          }
        },
        {
          when: (row) => row.created_date === undefined,
          style: {
            color: 'firebrick'
          }
        }
      ],
      width: '180px'
    },
    {
      name: 'FECHAMENTO',
      selector: (row) =>
        row.closure
          ? new Date(row.closure)
              .toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      width: '130px'
    },
    {
      name: 'PRODUTO',
      selector: (row) => formatProduct(row.product ?? ''),
      width: '110px'
    },
    {
      name: 'CLIENTE',
      selector: (row) => row.name_cli ?? '---',
      wrap: false,
      minWidth: '230px'
    },
    {
      name: 'MOEDA',
      selector: (row) => getMoeda(Number(row.currency)),
      width: '89px',
      right: true
    },
    {
      name: 'VALOR',
      selector: (row) => row.value ?? '',
      format: (row) =>
        Number(row.value).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }),
      sortable: true,
      width: '130px',
      right: true
    },
    {
      name: 'REFERÊNCIA',
      selector: (row) => row.refCli ?? '',
      format: (row) => (row.refCli === '' ? '---' : row.refCli),
      sortable: true,
      width: '190px'
    },
    {
      name: 'FATURA',
      selector: (row) => row.refCli ?? '',
      format: (row) => (row.refCli === '' ? '---' : row.refCli),
      sortable: true,
      width: '190px'
    },
    {
      name: 'AÇÕES',
      width: '80px',
      cell: (row) => (
        <HStack m={1}>
          <IconButton
            disabled={row.released !== true}
            onClick={() => {
              setIdToCancel(Number(row.id))
              onOpenReportModal()
            }}
            aria-label='Estornar'
            title='Estornar'
            size={'sm'}
            colorScheme={'red'}
          >
            <BsFillXOctagonFill size={16} />
          </IconButton>
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ]

  const loadData = useCallback(() => {
    try {
      loading.on()
      setShowAlert.off()
      // handleClearRows()
      setSelectProcess([])
      if (hashData?.start_date && hashData?.end_date) {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
        axiosWebsite
          .post(
            `load-process-to-close`,
            {
              codCli: idSyscambio ?? null,
              initial_date: dateInitials ?? hashData?.start_date,
              end_date: dateFinal ?? hashData?.end_date,
              listType: listType === undefined ? null : listType === 'feitos',
              product_type: dataInput
            },
            config
          )
          .then((res) => {
            setProcess(() => res.data ?? [])
            loading.off()
          })
          .catch((e) => {
            loading.off()
          })
      } else {
        setShowAlert.on()
        loading.off()
      }
    } catch (error) {
      console.log(error)
      loading.off()
    }
  }, [
    dateInitials,
    dateFinal,
    idSyscambio,
    loading,
    setShowAlert,
    dataInput,
    listType
  ])

  async function sendData () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      processSelect.forEach(function (e) {
        e.released = true
      })
      const listToSave = processSelect

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite
        .post(
          `save-process-to-close`,
          {
            processList: listToSave
          },
          config
        )
        .then((res) => {
          loadData()
          handleClearRows()
        })
        .catch((e) => {})
    } catch (error) {
    } finally {
      onClose()
      dispatch(clearLoading())
    }
  }

  async function sendDataCancel () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      processSelect.forEach(function (e) {
        e.released = true
      })
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite
        .delete(`delete-process-to-close?id=${idToCancel}`, config)
        .then((res) => {
          loadData()
          handleClearRows()
        })
        .catch((e) => {})
    } catch (error) {
    } finally {
      onCloseReportModal()
      dispatch(clearLoading())
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  function AlertDialogConfirmation () {
    const cancelRef = useRef<HTMLButtonElement>(null)
    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Marcar como Concluído
              </AlertDialogHeader>

              <AlertDialogBody>
                Você deseja fazer a marcação desses itens como feito (criado o
                processo no SYSCAMBIO)? Essa ação não poderá ser desfeita. Note
                que essa ação não atualiza automaticamento o SYSCAMBIO.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button colorScheme='red' onClick={sendData} ml={3}>
                  Marcar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  const exportExcel = async () => {
    const rows = process.map((o) => {
      return {
        Processo: o.process,
        'Previsão Fechamento': formatDate(o.closure ?? ''),
        Produto: o.product,
        Cliente: o.name_cli,
        'Pagador/Recebedor': o.beneficiary,
        Moeda: getMoeda(Number(o.currency)),
        Valor: Number(o.value),
        Referência: o.refCli
      }
    })

    await ExcelExportXlsx.fromJSON(
      'exportacao',
      rows,
      [
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 20 },
        { wch: 40 },
        { wch: 7 },
        { wch: 15 },
        { wch: 25 }
      ],
      [6]
    )
  }

  const {
    isOpen: isOpenReportModal,
    onOpen: onOpenReportModal,
    onClose: onCloseReportModal
  } = useDisclosure()
  function AlertDialogConfirmationCancel () {
    const cancelRef = useRef<HTMLButtonElement>(null)
    return (
      <>
        <AlertDialog
          isOpen={isOpenReportModal}
          leastDestructiveRef={cancelRef}
          onClose={onCloseReportModal}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Estornar
              </AlertDialogHeader>

              <AlertDialogBody>
                Você deseja o estorno desse(s) iten(s)? Essa ação não poderá ser
                desfeita. Note que essa ação não atualiza automaticamento o
                SYSCAMBIO.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseReportModal}>
                  Cancelar
                </Button>
                <Button colorScheme='red' onClick={sendDataCancel} ml={3}>
                  Estornar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  return (
    <>
      {AlertDialogConfirmation()}
      {AlertDialogConfirmationCancel()}
      <CardPage title='Análise Contas a Pagar/Receber (A Fechar)'>
        <Col px={2} w={'100%'}>
          <SimpleFormGenerator
            rows={[
              {
                columns: 3,
                fields: [
                  createCustomerSearchPicker(0, undefined, {
                    label: 'Cliente',
                    name: 'customer',
                    labelKey: 'corporate_name'
                  }),
                  {
                    type: 'select',
                    label: 'Tipo de Contrato',
                    name: 'contract_type',
                    options: [
                      {
                        label: () => 'A Pagar (Importação/Remessa)',
                        value: 'pagar'
                      },
                      {
                        label: () => 'A Receber (Exportação/Ingresso)',
                        value: 'receber'
                      },
                      {
                        label: () => 'Ingresso',
                        value: 'recebe_in'
                      },
                      {
                        label: () => 'Exportação',
                        value: 'recebe_ex'
                      },
                      {
                        label: () => 'Importação',
                        value: 'pagar_im'
                      },
                      {
                        label: () => 'Remessa',
                        value: 'pagar_re'
                      }
                    ]
                  },
                  {
                    type: 'select',
                    label: 'Status',
                    name: 'list_type',
                    options: [
                      {
                        label: () => 'Pendentes',
                        value: 'pendentes'
                      },
                      {
                        label: () => 'Feitos',
                        value: 'feitos'
                      }
                    ]
                  }
                ]
              },
              {
                columns: 5,
                fields: [
                  {
                    type: 'date',
                    label: 'Período',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'até',
                    name: 'end_date'
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              const productTypeBySelect = {
                pagar: {
                  one: 'IMPORTACAO',
                  two: 'REMESSA'
                },
                receber: {
                  one: 'EXPORTACAO',
                  two: 'INGRESSO'
                },
                recebe_in: 'INGRESSO',
                recebe_ex: 'EXPORTACAO',
                pagar_im: 'IMPORTACAO',
                pagar_re: 'REMESSA'
              }
              const { corporate_name, document, id_syscambio } =
                v.customer || {}
              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  corporate_name,
                  document,
                  id_syscambio
                }
              }

              if (v.contract_type) {
                const type: string = v.contract_type
                nextParams.contract_type = v.contract_type
                // @ts-ignore
                setDataInput(productTypeBySelect[type])
              } else {
                setDataInput({})
              }

              setListType(v.list_type)

              setSyscambio(id_syscambio)
              setDateInitials(v.start_date)
              setDateFinal(v.end_date)

              redirect(nextParams)
            }}
          />
          {showAlert && (
            <Alert status='warning'>
              <AlertIcon />
              Preencha todos os campos de filtro
            </Alert>
          )}
          <Collapse in={isLoading}>
            <Progress isIndeterminate colorScheme={'secondary'} h={1} />
          </Collapse>
          <VStack spacing={2} align='end'>
            <Box h='40px' mt={1}>
              <Button
                disabled={isLoading || process.length === 0}
                variant={'ghost'}
                color={'green.400'}
                colorScheme={'green'}
                mr='20px'
                onClick={() => {
                  exportExcel()
                }}
              >
                Exportação Excel
              </Button>
              <Button
                disabled={
                  hasPermissions([Permissions.APAR_CREATE]) &&
                  (isLoading || processSelect.length === 0)
                }
                variant={'outline'}
                color={'red.400'}
                colorScheme={'red'}
                mr='10px'
                onClick={onOpen}
              >
                Marcar como Feito
              </Button>
              <Button
                disabled={isLoading}
                variant={'outline'}
                color={'primary.400'}
                mr='10px'
                colorScheme={'primary'}
                onClick={() => {
                  loadData()
                  handleClearRows()
                  AppContext.apar_review = hashData
                }}
              >
                Buscar
              </Button>
            </Box>
          </VStack>
        </Col>
        <Col px={4}>
          <Col
            mt={3}
            rounded={'lg'}
            overflow={'auto'}
            border={'1px solid transparent'}
            borderColor={'primary.600'}
          >
            <DataTable
              noDataComponent=''
              columns={columns}
              data={process}
              dense={true}
              fixedHeader={true}
              fixedHeaderScrollHeight={'350px'}
              striped={true}
              selectableRows={hasPermissions([Permissions.APAR_CREATE])}
              onSelectedRowsChange={handleRowSelected}
              selectableRowsHighlight={true}
              selectableRowDisabled={rowDisabledCriteria}
              clearSelectedRows={toggledClearRows}
            />
          </Col>
        </Col>
      </CardPage>
    </>
  )
}

export default ProcessToCloseInternal
