import React from 'react'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { FormDocumentProps } from '../contracts'
import { formatCNPJ } from 'helpers/formats'

const FormDocumentDI: React.FC<FormDocumentProps> = ({
  onChange,
  value,
  operation,
  onRequestDocumentNumberBalance
}) => {
  return (
    <>
      <SimpleFormGenerator
        onChange={onChange}
        value={value}
        rows={[
          {
            columns: 4,
            fields: [
              {
                name: 'files',
                label: 'Arquivo',
                type: 'files',
                extensions: ['pdf']
              },
              {
                name: 'document_number',
                label: 'Número',
                type: 'text',
                onBlur: onRequestDocumentNumberBalance,
                mask: (value) => {
                  return value
                    .replace(/\D/g, '')
                    .replace(/(\d{2})(\d)/, '$1/$2')
                    .replace(/(\d{7})(\d)/, '$1-$2')
                    .replace(/(-\d{1})\d+?$/, '$1')
                }
              },
              { name: 'protocol', label: 'Protocolo', type: 'number' },
              {
                name: 'clearence_document',
                label: 'CNPJ Desembaraço',
                type: 'text',
                maxLength: 18,
                mask: (value) => {
                  return formatCNPJ(value)
                }
              },
              { name: 'reference', label: 'Referência', type: 'text' }
              //{ name: 'additionals', label: 'Adições', type: 'text' }
            ]
          },
          {
            columns: 3,
            fields: value.document_number
              ? [
                  {
                    name: 'value_applied',
                    label: 'Valor Aplicado',
                    type: 'currency'
                  },
                  {
                    name: 'value_total',
                    label: value?.last_balance
                      ? 'Valor Disponível para Uso'
                      : 'Valor Total',
                    type: 'currency',
                    readOnly: value?.last_balance
                  },
                  {
                    name: 'balance',
                    label: 'Saldo',
                    type: 'currency',
                    readOnly: true
                  }
                ]
              : []
          },
          {
            columns: 1,
            fields:
              value?.last_total && value.document_number
                ? [
                    {
                      type: 'heading',
                      as: 'h6',
                      label: `Valor Total Original: ${value?.last_total}`,
                      name: '',
                      size: 'xs'
                    }
                  ]
                : []
          }
        ]}
      />
    </>
  )
}

export default FormDocumentDI
