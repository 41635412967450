export interface PermissionsGroupModel {
  title: string;
  permissions: PermissionModel[];
}

export type PermissionValue = number;

export interface PermissionModel {
  label: string;
  value: PermissionValue;
}

export enum Permissions {
  CADASTRO_CREATE = 1,
  CADASTRO_VIEW = 2,
  CADASTRO_EDIT = 3,
  CADASTRO_STATUS = 4,

  COMPLIANCE_CREATE = 5,
  COMPLIANCE_VIEW = 6,
  COMPLIANCE_EDIT = 7,
  COMPLIANCE_STATUS = 8,

  DIRETORIA_CREATE = 9,
  DIRETORIA_VIEW = 10,
  DIRETORIA_EDIT = 11,
  DIRETORIA_STATUS = 12,

  USERS_CREATE = 13,
  USERS_VIEW = 14,
  USERS_EDIT = 15,
  USERS_STATUS = 16,

  DOCUMENT_CREATE = 17,
  DOCUMENT_VIEW = 18,
  DOCUMENT_EDIT = 19,
  DOCUMENT_STATUS = 20,

  OPERATION_VIEW = 21,
  OPERATION_EDIT = 22,

  MONITOR = 23,
  USER_ACCESS = 24,

  PROCESS_ACCESS = 25,
  PROCESS_VIEW = 26,
  PROCESS_CREATE = 27,
  PROCESS_EDIT = 28,

  APAR_ACCESS = 29,
  APAR_VIEW = 30,
  APAR_CREATE = 31,

  SCHEDULE_ACCESS = 32,
  SCHEDULE_BILLING = 33,
  SCHEDULE_CANCEL = 34,

  BILLING_ACCESS = 35,
  BILLING_EDIT = 36,
  BILLING_CANCEL = 37,

  POSCLOSE_ACCESS = 38,
  POSCLOSE_VIEW = 39,

  REPORTS_ACCESS = 40,
  REPORT_PAGREG = 41,
  REPORT_NF = 42,
  REPORT_IR = 43,
  REPORT_IMPEXP = 44,
  REPORT_DIRF = 45,
  REPORT_VOLCONTRACT = 46,
  REPORT_ECF = 47,
  REPORT_TRF = 48,
  REPORT_VOTORANTIM = 49,

  // Portal do fornecedor 200-299
  PROVIDER_ACCESS = 200,
  PROVIDER_DASCAM_ACCESS = 201,
}
