export interface BeneficiaryModel {
  id?: number;
  uuid?: string;
  user_id?: number;
  syscambio_beneficiary?: string;
  beneficiary_name?: string;
  document?: string;
  participation_percentage?: string;
  final_beneficiary?: boolean;
  attorney?: boolean;
  legal_representative?: boolean;
  person?: BeneficiaryPersonEnum;
  type?: BeneficiaryTypeEnum;
  status?: BeneficiaryStatusEnum;
  created_date?: string;
  created_by_id?: number;
  updated_date?: string;
  updated_by_id?: number;
}

export enum BeneficiaryStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  BLOCKED = "BLOCKED",
}

export enum BeneficiaryTypeEnum {
  CONTATO = "CONTATO",
  SOCIO = "SOCIO / DIRETOR",
  DIRETOR = "DIRETOR",
  ADMINISTRADOR = "ADMINISTRADOR",
}

export enum BeneficiaryPersonEnum {
  FISICO = "FISICO",
  JURIDICO = "JURIDICO",
}
