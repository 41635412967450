import {
  ComponentStyleConfig,
  extendTheme,
  theme as themeChakra,
  useColorMode,
  withDefaultColorScheme,
  withDefaultProps,
} from "@chakra-ui/react";

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
  pink: {
    200: "#00b358",
    300: "#00803e",
    400: "#00803e",
    500: "#00803e",
  },
  gray: {
    50: "#f0f1f8",
    100: "#ebedf6",
    200: "#e9ebf5",
    300: "#d2d4dd",
    400: "#babcc4",
    500: "#a3a5ac",
    600: "#30363d",
    700: "#21262d",
    750: "#1b2028",
    800: "#161b22",
    900: "#0d1117",
    1000: "#06080c",
    1100: "#020305",
  },
  primary: {
    50: "#b7c9db",
    100: "#9fb7cf",
    200: "#5782aa",
    300: "#3f709e",
    400: "#275e92",
    500: "#0f4c86",
    600: "#0e4479",
    700: "#0c3d6b",
    800: "#0b355e",
    900: "#092e50",
    1000: "#082643",
  },
  secondary: {
    50: "#ffe699",
    100: "#ffd966",
    200: "#ffd34d",
    300: "#ffcd33",
    400: "#ffc61a",
    500: "#ffc000",
    600: "#e6ad00",
    700: "#b38600",
    800: "#806000",
    900: "#4c3a00",
  },
  terciary: {
    300: "#aa6443",
    400: "#aa6443",
    500: "#aa6443",
  },
  terciary_hover: {
    50: "#f0ddc9",
    100: "#c9b29b",
    200: "#c9b29b",
    300: "#c9b29b",
    400: "#c9b29b",
    500: "#c9b29b",
  },
  darken: {
    50: "rgba(0,0,0,.1)",
    100: "rgba(0,0,0,.2)",
    200: "rgba(0,0,0,.3)",
    300: "rgba(0,0,0,.4)",
    400: "rgba(0,0,0,.5)",
    500: "rgba(0,0,0,.6)",
    600: "rgba(0,0,0,.7)",
    700: "rgba(0,0,0,.8)",
    800: "rgba(0,0,0,.9)",
    900: "rgba(0,0,0,1)",
  },
  lighten: {
    50: "rgba(255,255,255,.05)",
    100: "rgba(255,255,255,.1)",
    200: "rgba(255,255,255,.3)",
    300: "rgba(255,255,255,.4)",
    400: "rgba(255,255,255,.5)",
    500: "rgba(255,255,255,.6)",
    600: "rgba(255,255,255,.7)",
    700: "rgba(255,255,255,.8)",
    800: "rgba(255,255,255,.9)",
    900: "rgba(255,255,255,1)",
  },
  linkedin: {
    50: "#e1f3ff",
    100: "#bdd7f6",
    200: "#95bceb",
    300: "#6ba1df",
    400: "#4486d5",
    500: "#2a6cbb",
    600: "#1e5493",
    700: "#123c6a",
    800: "#052442",
    900: "#000d1c",
  },
  facebook: {
    50: "#e7f0ff",
    100: "#c4d3ef",
    200: "#a0b5e0",
    300: "#7c98d0",
    400: "#587ac1",
    500: "#3e61a7",
    600: "#2f4b83",
    700: "#20365f",
    800: "#11203c",
    900: "#020b1b",
  },
  google: {
    50: "#ffe8e4",
    100: "#f8c0bc",
    200: "#ee9992",
    300: "#e57167",
    400: "#dc493d",
    500: "#c23023",
    600: "#98241a",
    700: "#6d1812",
    800: "#440d09",
    900: "#1e0100",
  },
  green: {
    50: "#d4e5d7",
    100: "#b7d3bc",
    200: "#a9caae",
    300: "#9ac1a1",
    400: "#7db086",
    500: "#6fa778",
    600: "#598660",
    700: "#4e7554",
    800: "#436448",
    900: "#38543c",
  },
  blue: {
    50: "#b4aeff",
    100: "#938bff",
    200: "#786efc",
    300: "#6c62f0",
    400: "#6057df",
    500: "#5349d1",
    600: "#4a40c1",
    700: "#3a31ac",
    800: "#2f2798",
    900: "#1e177a",
  },
  yellow: {
    50: "#ffffdd",
    100: "#f9f7b4",
    200: "#f3ed89",
    300: "#eee05c",
    400: "#e9d130",
    500: "#cfc416",
    600: "#9fa10d",
    700: "#6a7306",
    800: "#3b4500",
    900: "#151900",
  },
  red: {
    50: "#ffd4d4",
    100: "#ffb7b7",
    200: "#ffa8a8",
    300: "#ff9a9a",
    400: "#ff7d7d",
    500: "#ff6e6e",
    600: "#e66363",
    700: "#cc5858",
    800: "#b34d4d",
    900: "#662c2c",
  },
  instagram: {
    100: "#f9d6e2",
    200: "#f3acc4",
    300: "#ea6e98",
    400: "#e4457b",
    500: "#e1306c",
    600: "#b42656",
    700: "#871d41",
    800: "#430e20",
    900: "#16050b",
  },
};

const theme = extendTheme({
  fonts: {
    body: `-apple-system,BlinkMacSystemFont,'Roboto','Segoe UI','Oxygen', 'Ubuntu','Montserrat','Cantarell','Fira Sans','Droid Sans','Helvetica Neue', sans-serif`,
    // heading: '"Outfit"',
  },
  fontSizes: {
    ...themeChakra.fontSizes,
    md: "14px",
  },
  colors,
  components: {
    ...themeChakra.components,
    Input: {
      ...themeChakra.components.Input,
      defaultProps: {
        ...themeChakra.components.Input.defaultProps,
        rounded: "sm",
      },
    },
  },
});

console.log({ themeChakra, theme });

export { theme };
