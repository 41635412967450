import AttachFile from "components/generic/AttachFileToBase64";
import GenericButton from "components/generic/GenericButton";
import {
  InputCNPJCPF,
  InputName,
  InputNumber,
  InputTextArea,
} from "components/generic/GenericInputs";
import { devices } from "helpers/devices";
import {
  ExcelStatusEnum,
  OperationExcelModel,
} from "interfaces/operation_excel";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import FindCustomer from "./FindCustomer";
import { ReactComponent as LupaIcon } from "assets/lupaIcon.svg";
import { useSelector } from "react-redux";
import { GlobalState } from "redux/types";
import { UserTypeEnum } from "interfaces/user";
import { BiSearchAlt2 } from "react-icons/bi";

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 100;
  background-color: ${(props) => props.theme.color.white1};
  height: auto;
  width: 100%;
  position: sticky;
  top: -1px;
  left: 0;

  @media ${devices.tabletPortrait} {
    align-items: flex-start;
  }

  @media ${devices.bigDesktop} {
    align-items: center;
  }
`;

const TabsBlue = styled.div`
  width: 100%;
  height: auto;
  padding: 0.2rem;
  background: ${(props) => props.theme.color.primary_main};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & div {
    padding: 0.3rem 1rem;
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }
`;

const ButtonYellow = styled.button<{ marginBottom?: string }>`
  width: 40px;
  height: 40px;
  padding: 0.3rem;
  background-color: ${(props) => props.theme.color.secondary_main};
  border-radius: 6px;
  outline: none;
  border: none;
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  cursor: pointer;
  transform: scale(0.95);
  margin-bottom: ${(props) => props.marginBottom};

  & svg {
    width: auto;
    height: 100%;
    & path {
      fill: ${(props) => props.theme.color.primary_main};
    }
  }

  &:hover {
    transform: scale(1);
  }
`;

const ButtonTabs = styled(GenericButton)<{
  isDeny?: boolean;
  isYellow?: boolean;
  isBlue?: boolean;
}>`
  width: auto;
  min-width: 120px;
  height: 25px;
  margin: 0.1rem 0.3rem;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  color: ${(props) => props.theme.color.white1};
  font-size: 0.9rem;
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isYellow
      ? props.theme.color.secondary_main
      : props.isBlue
      ? "#296ba9"
      : props.theme.color.button_normal} !important;

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isYellow
        ? props.theme.color.secondary_light
        : props.isBlue
        ? "#4b87be"
        : props.theme.color.button_normal_hover} !important;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
`;

const TextTitle = styled.h4`
  text-transform: uppercase;
  display: block;
`;

const TextLabel = styled.span`
  color: ${(props) => props.theme.color.gray3};
  font-weight: bold;
`;

interface OperationFormFiles {
  attachment?: {
    file?: string;
    type?: string;
  };
  dascamExcel?: {
    file?: string;
    type?: string;
  };
  clientExcel?: {
    file?: string;
    type?: string;
  };
}

export interface OperationForm extends OperationFormFiles {
  codClient?: string;
  clientName?: string;
  cnpjCpf?: string;
  clientObservation?: string;
  dascamObservation?: string;
}

export interface ButtonItem {
  onClick?: (props: OperationForm) => void;
  text?: string;
  isDeny?: boolean;
  isYellow?: boolean;
}

interface ExcelOperationFormProps {
  buttons?: ButtonItem[];
  initialData?: Partial<OperationExcelModel>;
  isDisabled?: boolean;
}

export const ExcelOperationForm: React.FC<ExcelOperationFormProps> = ({
  buttons,
  initialData,
  isDisabled,
}) => {
  const user = useSelector((state: GlobalState) => state.user);
  const [form, setForm] = useState<OperationForm>({});
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const isDascamUser = user?.cad_type === UserTypeEnum.DASCAM;

  const isUserAndApproved = useMemo(() => {
    return !isDascamUser && initialData?.status === ExcelStatusEnum.APPROVAL;
  }, [user, isDascamUser, initialData]);

  useEffect(() => {
    if (initialData) {
      setForm(() => ({
        attachment: {
          file: initialData?.attachment,
          type: "",
        },
        clientExcel: {
          file: initialData?.client_excel,
          type: "",
        },
        dascamExcel: {
          file: initialData?.dascam_excel,
          type: "",
        },
        clientName: initialData?.client_name,
        cnpjCpf: initialData?.client_doc,
        codClient: `${initialData?.user_syscambio}`,
        clientObservation: initialData?.client_observation,
        dascamObservation: initialData?.dascam_observation,
      }));
    }
  }, [initialData]);

  const registerText = <T extends keyof OperationForm>(key: T) => {
    return {
      value: form[key],
      changeValue: (value: string) => {
        setForm((oldValues) => ({
          ...oldValues,
          [key]: value,
        }));
      },
    };
  };

  const registerFile = <T extends keyof OperationFormFiles>(key: T) => {
    function changeValue(value: string, _?: string, type?: string) {
      setForm((oldValues) => ({
        ...oldValues,
        [key]: {
          file: value,
          type,
        },
      }));
    }

    return {
      value: form[key]?.file || "",
      changeValue,
      limitSize: 104857600,
      newId: key,
    };
  };

  return (
    <>
      <TabsContainer>
        <TabsBlue>
          <div></div>
          <div>
            {buttons?.map((button, keyButton) => (
              <ButtonTabs
                key={`button${keyButton}`}
                isDeny={button.isDeny}
                isYellow={button.isYellow}
                onClick={() => {
                  button.onClick?.(form);
                }}
              >
                {button.text}
              </ButtonTabs>
            ))}
          </div>
        </TabsBlue>
      </TabsContainer>
      <Col style={{ padding: 40 }}>
        <Row style={{ marginBottom: 20 }}>
          <TextTitle>Dados para operação</TextTitle>
        </Row>
        <ItemRow>
          <InputNumber
            label="Cód. do cliente"
            maxWidth="130px"
            maxLength={6}
            readOnly={isDascamUser || isDisabled || isUserAndApproved}
            {...registerText("codClient")}
          />
          <InputName
            label="Cliente"
            maxWidth="300px"
            readOnly={isDascamUser || isDisabled || isUserAndApproved}
            {...registerText("clientName")}
          />
          <InputCNPJCPF
            label="CNPJ/CPF"
            maxWidth="200px"
            readOnly={isDascamUser || isDisabled || isUserAndApproved}
            {...registerText("cnpjCpf")}
          />
          <ButtonYellow
            type="button"
            marginBottom=".3rem"
            onClick={() => setShowCustomerModal(!showCustomerModal)}
          >
            <BiSearchAlt2 />
          </ButtonYellow>
        </ItemRow>
        <Col style={{ marginBottom: 20, marginTop: 20 }}>
          <TextTitle>Planilhas/Documentos</TextTitle>
          <Row>
            <Col style={{ flex: 1 }}>
              <TextLabel>Planilha para importação *</TextLabel>
              <AttachFile
                {...registerFile("clientExcel")}
                readOnly={isDascamUser || isDisabled || isUserAndApproved}
              />
            </Col>
            <Col style={{ flex: 1 }}>
              <TextLabel>
                Anexo de Documentos (enviar documentos em formato .zip)
              </TextLabel>
              <AttachFile
                {...registerFile("attachment")}
                readOnly={isDascamUser || isDisabled || isUserAndApproved}
              />
            </Col>
          </Row>
          <Col>
            <InputTextArea
              label={"Observações:"}
              readOnly={isDascamUser || isDisabled || isUserAndApproved}
              {...registerText("clientObservation")}
            />
          </Col>
        </Col>
        <Col style={{ marginBottom: 20, marginTop: 20 }}>
          <TextTitle>Planilhas importação dascam</TextTitle>
          <Row>
            <Col style={{ flex: 1 }}>
              <TextLabel>Planilha importada pela Dascam</TextLabel>
              <AttachFile
                {...registerFile("dascamExcel")}
                readOnly={!isDascamUser}
              />
            </Col>
          </Row>
          <Col>
            <InputTextArea
              label={"Observações:"}
              readOnly={!isDascamUser || isDisabled}
              {...registerText("dascamObservation")}
            />
          </Col>
        </Col>
      </Col>
      <FindCustomer
        showModal={showCustomerModal}
        handleModal={() => setShowCustomerModal(!showCustomerModal)}
        data={{
          client_document: form.cnpjCpf,
          user_syscambio: form.codClient,
          client_name: form.clientName,
        }}
        changeData={(nextOperation) => {
          setForm((oldForm) => {
            return {
              ...oldForm,
              cnpjCpf: nextOperation.client_document,
              codClient: nextOperation.user_syscambio,
              clientName: nextOperation.client_name,
            };
          });
        }}
      />
    </>
  );
};
