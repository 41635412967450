import HomePostClosing from 'components/restricted-area/registration-data/data/post-closing/HomePostClosing'
import ViewOperation from 'components/restricted-area/registration-data/data/post-closing/ViewOperation'
import { devices } from 'helpers/devices'
import { useState } from 'react'
import styled from 'styled-components'
import CardPage from 'v2/components/pages/CardPage'

const Container = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */

  @media ${devices.phoneOnly} {
    background-position: center left;
  }
`

type ElementRender = 'Home' | 'View'

const PostClosing = (): JSX.Element => {
  const [render, setRender] = useState<ElementRender>('Home')
  const [pastRender, setPastRender] = useState<ElementRender>('Home')
  const [isReadOperation, setIsReadOperation] = useState<boolean>(false)
  const [operationId, setOperationId] = useState<number>(0)
  const [userSyscambio, setUserSyscambio] = useState<number>(0)

  return (
    <CardPage title='Pós Fechamento de operações de câmbio'>
      <Container>
        {render === 'Home' ? (
          <HomePostClosing
            typeOperation={render as ElementRender}
            changeRender={(value) => setRender(value as ElementRender)}
            isReadOperation={isReadOperation}
            setIsReadOperation={setIsReadOperation}
            setOperationId={setOperationId}
            setUserSyscambio={setUserSyscambio}
            changePastRender={(value) => setPastRender(value as ElementRender)}
          />
        ) : null}
        {render === 'View' ? (
          <ViewOperation
            typeOperation={render as ElementRender}
            pastRender={pastRender as ElementRender}
            changeRender={(value) => setRender(value as ElementRender)}
            isReadOperation={isReadOperation}
            setIsReadOperation={setIsReadOperation}
            operationId={operationId}
            userSyscambio={userSyscambio}
          />
        ) : null}
      </Container>
    </CardPage>
  )
}

export default PostClosing
