import styled from "styled-components";
//imports components
import GenericButton from "components/generic/GenericButton";
import { useHistory } from "react-router-dom";
//import imgs
// import BgAreas from "assets/fundoCadastro.png";
import LogoDascam from "assets/Logo 1.png";
import { useDispatch } from "react-redux";
import { clearGlobalMessage, clearUser } from "redux/actions";

const Container = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.color.gray2};
`;

const Logo = styled.img`
  height: auto;
  width: 400px;
  margin: 1rem;
`;

const Button = styled(GenericButton)`
  font-size: 1rem;
  font-weight: 300;
  height: 40px;
  width: 200px;
  margin: 0.5rem;
  border-radius: 10px;
  background: ${props => props.theme.color.primary_dark};
  color: ${props => props.theme.color.white1};

  &:hover {
    background: ${props => props.theme.color.primary_main};
  }
`;

const TextError = styled.p`
  margin: 1rem 0;
  text-align: center;
  color: ${props => props.theme.color.deny};
`;

const Effect = styled.div<{ delay: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Unauthorized = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const cleanLog = () => {
    dispatch(clearGlobalMessage());
    dispatch(clearUser());
    localStorage.clear();
    history.push("/");
  };

  return (
    <Container>
      <Effect delay={"0ms"}>
        <Logo src={LogoDascam} alt="Logo da Tânia Bulhões" />
      </Effect>
      <TextError>
        Seu acesso expirou ou seu usuário não possui acesso a esta área. Por
        favor, tente fazer um novo login.
      </TextError>
      <Button onClick={() => cleanLog()}>Voltar</Button>
    </Container>
  );
};

export default Unauthorized;
