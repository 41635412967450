import axios, { AxiosInstance } from "axios";
import { axiosAuth, axiosWebsite } from "helpers/axiosInstances";
import { ApiBanks } from "./lib/api.banks";
import { ApiCurrencies } from "./lib/api.currencies";
import { ApiCustomers } from "./lib/api.customers";
import { ApiOperations } from "./lib/api.operations";
import { ApiPayer } from "./lib/api.payer";
import { ApiFiscalNotes } from "./lib/api.ficalNotes"
import { ApiProcess } from "./lib/api.process";

export class ApiClient {
  public customers: ApiCustomers;
  public banks: ApiBanks;
  public currencies: ApiCurrencies;
  public payer: ApiPayer;
  public operations: ApiOperations;
  public fiscalNotes: ApiFiscalNotes;
  public http: AxiosInstance;
  public httpAuth: AxiosInstance;
  public process: ApiProcess;

  constructor(
    public url: string = process.env.REACT_APP_WEBSITE_API
      ? process.env.REACT_APP_WEBSITE_API
      : "http://localhost:8080/api"
  ) {

    this.http = axiosWebsite;//axios.create(config);
    this.httpAuth = axiosAuth;


    this.customers = new ApiCustomers(this);
    this.banks = new ApiBanks(this);
    this.currencies = new ApiCurrencies(this);
    this.payer = new ApiPayer(this);
    this.operations = new ApiOperations(this);
    this.fiscalNotes = new ApiFiscalNotes(this);
    this.process = new ApiProcess(this);
  }
}
