import styled from 'styled-components'
import {
  InputCelPhone,
  InputCEP,
  InputCNPJ,
  InputDate,
  InputEmail,
  InputPhone,
  InputText,
  InputUF,
  InputCurrency
} from 'components/generic/GenericInputs'
import CheckBox from 'components/generic/CheckBox'
import { UserModel } from 'interfaces/user'
import UserPickEntityLegalNature from 'components/generic/logic/UserPickEntityLegalNature'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { Col } from 'v2/components/shared/Col'

const HeaderCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`

const TitleCard = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`

const TitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`

const StatusUser = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  height: auto;
  margin: 0 0.5rem;
  & p {
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 23px;
    margin: 0 0.3rem;
  }
`

const StatusCircle = styled.span<{ color?: string }>`
  margin: 0 0.5rem;
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  margin-right: 0.3rem;
  background-color: ${(props) =>
    props.color === 'DISAPPROVED'
      ? props.theme.color.deny
      : props.color === 'PENDING'
      ? props.theme.color.warn
      : props.color === 'EVALUATION'
      ? props.theme.color.primary_light
      : props.color === 'BLOCKED'
      ? props.theme.color.deny
      : props.theme.color.approval};
`

interface Props {
  userData: UserModel
  changeUserData: (userData: UserModel) => void
}

const RegisterLegal = ({ userData, changeUserData }: Props): JSX.Element => {
  return (
    <>
      <HeaderCard>
        <div>
          <TitleCard>Meus dados cadastrais</TitleCard>
        </div>
        <div>
          <StatusUser>
            {userData.responsible_sector !== 'CLIENTE' ? (
              <p>
                Área/Setor:{' '}
                <b>
                  {userData.responsible_sector === 'CADASTRO'
                    ? 'Cadastro'
                    : userData.responsible_sector === 'COMPLIANCE'
                    ? 'Compliance'
                    : 'Diretoria'}
                </b>
              </p>
            ) : null}
            <p>
              Status:{' '}
              <b>
                {userData.status === 'DISAPPROVED'
                  ? 'Reprovado'
                  : userData.status === 'PENDING'
                  ? 'Pendente'
                  : userData.status === 'EVALUATION'
                  ? 'Em análise'
                  : userData.status === 'BLOCKED'
                  ? 'Bloqueado'
                  : 'Aprovado'}
              </b>
            </p>
            <StatusCircle color={userData.status!} />
          </StatusUser>
        </div>
      </HeaderCard>
      <DivItems>
        <ItemRow>
          <CheckBox
            readOnly
            title='Sou Pessoa Politicamente Exposta'
            selected={userData.politically_exposed!}
            changeSelected={(value) =>
              changeUserData({
                ...userData,
                politically_exposed: value
              })
            }
          />
        </ItemRow>
        <ItemRow>
          <InputText
            label='Razão Social *'
            readOnly
            maxWidth='300px'
            value={userData.user_name!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                user_name: value
              })
            }
            required
          />
          <InputText
            label='Nome Fantasia *'
            readOnly
            maxWidth='300px'
            value={userData.fantasy_name!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                fantasy_name: value
              })
            }
            required
          />
          <InputCNPJ
            label='CNPJ *'
            placeholder='__.___.___/____-__'
            readOnly
            maxWidth='200px'
            value={userData.document!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                document: value
              })
            }
            required
          />
          <InputDate
            label='Data da Constituição da PJ *'
            readOnly
            maxWidth='250px'
            max={new Date().toISOString().split('T')[0]}
            value={userData.opening_date!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                opening_date: value
              })
            }
            required
          />
        </ItemRow>
        <ItemRow>
          <InputText
            label='Inscrição Municipal  / CCM *'
            readOnly
            maxWidth='300px'
            value={userData.municipalregistration_ccm!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                municipalregistration_ccm: value
              })
            }
            required
          />
          <InputText
            label='Inscrição Estadual *'
            readOnly
            maxWidth='300px'
            value={userData.stateregistration!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                stateregistration: value
              })
            }
            required
          />
          <InputText
            label='Cod. BACEN'
            readOnly
            maxWidth='250px'
            value={userData.bacen_code!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                bacen_code: value
              })
            }
          />
          {/* <InputSelect label="Praça *" maxWidth="200px"></InputSelect> */}
        </ItemRow>
        <ItemRow>
          <UserPickEntityLegalNature
            value={{
              entityId: Number(userData.entity),
              legalNatureId: userData.legal_nature
            }}
            onChange={(v) => {
              changeUserData({
                ...userData,
                legal_nature: v.legalNatureId,
                entity: String(v.entityId)
              })
            }}
          />
          <InputText
            label='País'
            readOnly
            maxWidth='150px'
            value={userData.country!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                country: value
              })
            }
          />
        </ItemRow>
        <ItemRow>
          <InputText
            label='Website *'
            readOnly
            maxWidth='300px'
            value={userData.website!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                website: value
              })
            }
            required
          />
          <InputText
            label='Atividade Principal *'
            readOnly
            maxWidth='300px'
            value={userData.main_activity!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                main_activity: value
              })
            }
            required
          />
        </ItemRow>
        <ItemRow>
          <Col ml={1}>
            <SimpleFormGenerator
              rows={[
                {
                  columns: 2,
                  fields: [
                    {
                      type: 'currency',
                      label: 'Patrimônio Líquido *',
                      name: 'net_worth',
                      readOnly: true
                    },
                    {
                      type: 'currency',
                      label: 'Faturamento Médio Mensal *',
                      name: 'average_billing',
                      readOnly: true
                    }
                  ]
                }
              ]}
              value={userData}
              onChange={(v) => {
                changeUserData({
                  ...userData,
                  net_worth: v.net_worth,
                  average_billing: v.average_billing
                })
              }}
            />
          </Col>
        </ItemRow>
      </DivItems>
      <DivItems>
        <TitleItems>Contato / Responsável</TitleItems>
      </DivItems>
      <DivItems>
        <ItemRow>
          <InputText
            label='Nome Contato *'
            readOnly
            maxWidth='300px'
            value={userData.contact_name!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                contact_name: value
              })
            }
            required
          />
          <InputText
            label='Cargo *'
            readOnly
            maxWidth='300px'
            value={userData.contact_role!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                contact_role: value
              })
            }
            required
          />
          <InputText
            label='Departamento *'
            readOnly
            maxWidth='300px'
            value={userData.contact_branch!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                contact_branch: value
              })
            }
            required
          />
        </ItemRow>
        <ItemRow>
          <InputCelPhone
            label='Celular *'
            placeholder='00000-0000'
            readOnly
            maxWidth='200px'
            value={userData.contact_cellphone!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                contact_cellphone: value
              })
            }
            required
          />
          <InputPhone
            label='Telefone *'
            placeholder='0000-0000'
            readOnly
            maxWidth='200px'
            value={userData.contact_phone!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                contact_phone: value
              })
            }
            required
          />
          <InputEmail
            label='E-mail *'
            placeholder='exemplo@email.com'
            readOnly
            maxWidth='300px'
            value={userData.email!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                email: value
              })
            }
            required
          />
        </ItemRow>
      </DivItems>
      <DivItems>
        <TitleItems>Dados de endereço</TitleItems>
      </DivItems>
      <DivItems>
        <ItemRow>
          <InputText
            label='País *'
            readOnly
            maxWidth='150px'
            value={userData.country!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                country: value
              })
            }
            required
          />
          <InputCEP
            label='CEP *'
            readOnly
            maxWidth='175px'
            value={userData.address_zipcode!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                address_zipcode: value
              })
            }
            changeOtherValue={(value) =>
              changeUserData({
                ...userData,
                address_city: value.localidade,
                address_state: value.uf,
                address_place: value.logradouro,
                address_district: value.bairro
              })
            }
            required
          />
          <InputText
            label='Cidade *'
            readOnly
            maxWidth='200px'
            value={userData.address_city!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                address_city: value
              })
            }
            required
          />
          <InputUF
            readOnly
            label='UF *'
            value={userData.address_state!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                address_state: value
              })
            }
            required
          />
          {/* <InputSelect
                    label="Tipo de Bairro *"
                readOnly
                    maxWidth="200px"
                    value={userData.address_type!}
                    changeValue={value =>
                      changeUserData({
                        ...userData,
                        address_type: value,
                      })
                    }
                    required
                  ></InputSelect> */}
          <InputText
            label='Bairro *'
            readOnly
            maxWidth='250px'
            value={userData.address_district!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                address_district: value
              })
            }
            required
          />
        </ItemRow>
        <ItemRow>
          {/* <InputSelect
                    label="Tipo de Logradouro *"
                readOnly
                    maxWidth="200px"
                    value={userData.address_type!}
                    changeValue={value =>
                      changeUserData({
                        ...userData,
                        address_type: value,
                      })
                    }
                    required
                  ></InputSelect> */}
          <InputText
            label='Logradouro *'
            readOnly
            maxWidth='420px'
            value={userData.address_place!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                address_place: value
              })
            }
            required
          />
          <InputText
            label='Número *'
            readOnly
            maxWidth='100px'
            value={userData.address_number!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                address_number: value
              })
            }
            required
          />
          <InputText
            label='Complemento'
            readOnly
            maxWidth='340px'
            value={userData.address_complement!}
            changeValue={(value) =>
              changeUserData({
                ...userData,
                address_complement: value
              })
            }
          />
        </ItemRow>
      </DivItems>
    </>
  )
}

export default RegisterLegal
