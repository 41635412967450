export const filterString = (
  items: any[],
  value: string | undefined,
  fields: string[]
) => {
  if (!value) return items;

  return items.filter((item) => {
    let isValid = false;
    for (const field of fields) {
      const includes = String(item[field])
        .toLowerCase()
        .includes(value.toLowerCase());
      const startsWith = String(item[field])
        .toLowerCase()
        .startsWith(value.toLowerCase());
      if (includes || startsWith) {
        isValid = true;
      }
    }

    return isValid;
  });
};
