import {
  Button,
  HStack,
  Modal,
  Progress,
  Text,
  useBoolean,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea
} from '@chakra-ui/react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { OperationStatusEnum } from 'interfaces/operation'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { clearLoading, showLoading } from 'redux/actions'
import { DocumentItemProps } from 'v2/components/forms/documents/contracts'
import OperationDocuments from 'v2/components/modules/operation/OperationDocuments'
import OperationInfos, {
  OperationInfosValue
} from 'v2/components/modules/operation/OperationInfos'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import { useCurrencyQuery } from 'v2/hooks/api/currency/useCurrencyQuery'
import { useCustomersByAuth } from 'v2/hooks/api/customer/useCustomersByAuth'
import { ApiOperations } from 'v2/services/api/lib/api.operations'
import { createOperationRequestData } from 'v2/services/app/factories/request/createOperationRequestData'
import { apiClient } from 'v2/services/clients/apiClient'

const ShowOperationPage: React.FC = ({ children }) => {
  const { id } = useParams<any>()
  const { user, isDascam } = useGlobals()
  const { data: customers } = useCustomersByAuth()
  const { data: currencies } = useCurrencyQuery()
  const [operationInfos, setOperationInfos] = useState<OperationInfosValue>(
    {} as OperationInfosValue
  )
  const [operationData, setOperation] = useState<ApiOperations.OperationProps>()
  const [documents, setDocuments] = useState<DocumentItemProps[]>([])
  const [isLoadingRefresh, loadRefresh] = useBoolean(true)
  const [isLoadingCorrection, loadCorrection] = useBoolean()
  const [isLoadingSave, loadSave] = useBoolean()
  const [observation, setObservation] = useState<string>('')
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onRefresh = async () => {
    loadRefresh.on()
    apiClient.operations
      .show(id)
      .then((operation) => {
        setOperation(() => operation)

        // @ts-ignore
        setOperationInfos(() => ({
          process: {
            number:
              Number(operation.process_number) === 0
                ? undefined
                : operation.process_number,
            type: operation.product_type,
            refCli: operation.refCli,
            value: operation.process_value,
            expiration: operation.process_expiration
          },
          customer: {
            id_syscambio: Number(operation.id_syscambio),
            fantasy_name: operation.client_name,
            document: operation.client_document
          },
          currency: {
            Codigo: Number(operation.currency_code),
            Nome: operation.currency_name,
            CodigoISO: operation.currency_iso
          },
          payerReceiver: {
            CodPagReg: Number(operation.beneficiary_code),
            NomeBeneficiario: operation.beneficiary_name,
            CodCli: operation.id_syscambio
          },
          beneficiatyBank: operation.beneficiary_info?.beneficiary,
          intermediaryBank: operation.beneficiary_info?.intermediary
        }))

        setDocuments(() => {
          const nextData = operation.documents?.map<DocumentItemProps>(
            (document) => ({
              balance: Number(document.balance_avaliable),
              document_number: document.document_number,
              document_type: document.document_type,
              value_applied: Number(document.applied_value),
              value_total: Number(document.total_value),
              files: [
                {
                  isBase64: false,
                  src: document.attached_file,
                  mimeType: document.attached,
                  name: document.attached_name
                }
              ],
              group_number: document.set_number,
              reference: document.document_reference,
              document_key: document.document_key,
              protocol: document.protocol,
              clearence_document: document.clearence_document
            })
          )

          return nextData || []
        })
      })
      .finally(() => {
        loadRefresh.off()
      })
  }

  // Método para enviar o Processo com o status de Correção (Dascam > Cliente)
  const onSubmitCorrection = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          subMessage: 'Salvando, aguarde!',
          isLoading: true
        })
      )

      loadCorrection.on()

      const date = new Date()

      await apiClient.operations
        .send(
          createOperationRequestData({
            id: Number(id),
            operationInfos,
            documents,
            status: OperationStatusEnum.CORRECTION,
            updated_by_id: user.id,
            updated_date: date.toISOString(),
            //userId: user.id,
            observation: {
              process_id: Number(id),
              text_content:
                observation !== '' &&
                observation !== undefined &&
                observation !== null
                  ? observation
                  : 'Solicitada revisão e correção das informações. Após a validação, envie novamente para análise',
              created_by_id: Number(user.id),
              created_date: moment().format('yyyy-MM-DD'),
              creator_name: `${user.user_name!}-Dascam`,
              creator_sector: user.contact_branch,
              status: OperationStatusEnum.CORRECTION
            }
          })
        )
        .then(() => {
          onRefresh()
        })
        .finally(() => {
          loadCorrection.off()
        })
    } catch (error) {
    } finally {
      dispatch(clearLoading())
    }
  }

  // Método para enviar o orocesso com o status de Aprovado (Dascam > SysCâmbio)
  const onSubmitDascam = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          subMessage: 'Salvando, aguarde!',
          isLoading: true
        })
      )
      loadSave.on()

      const date = new Date()

      await apiClient.operations
        .sendDascam(
          createOperationRequestData({
            id: Number(id),
            operationInfos,
            documents,
            status: OperationStatusEnum.APPROVAL,
            updated_by_id: user.id,
            updated_date: date.toISOString(),
            //userId: user.id,
            observation: {
              process_id: Number(id),
              text_content: 'Processo criado no SysCâmbio',
              created_by_id: Number(user.id),
              created_date: moment().format('yyyy-MM-DD'),
              creator_name: `${user.user_name!}-Dascam`,
              creator_sector: user.contact_branch,
              status: OperationStatusEnum.APPROVAL
            }
          })
        )
        .then(() => {
          onRefresh()
        })
        .finally(() => {
          loadSave.off()
        })
    } catch (error) {
    } finally {
      dispatch(clearLoading())
    }
  }

  useEffect(() => {
    onRefresh()
  }, [id])

  if (!customers || !currencies || isLoadingRefresh)
    return (
      <CardPage
        title={'Carregando...'}
        _headerRight={
          <HStack my={2} fontSize={'xs'}>
            <Link to={`/operations`}>
              <Button
                variant={'ghost'}
                color={'terciary.400'}
                colorScheme={'terciary_hover'}
              >
                Voltar
              </Button>
            </Link>
          </HStack>
        }
      >
        <Progress isIndeterminate colorScheme={'secondary'} h={1} />
      </CardPage>
    )

  return (
    <CardPage
      title={'Exibir Processo'}
      _headerBottom={
        <Text m={2} fontSize={'sm'}>
          {operationData?.status
            ? OperationStatusEnum.format(
                operationData?.status as OperationStatusEnum
              )
            : ''}
        </Text>
      }
      _headerRight={
        <HStack my={3} fontSize={'xs'}>
          <Link to={`/operations`}>
            <Button
              variant={'ghost'}
              color={'terciary.400'}
              colorScheme={'terciary_hover'}
            >
              Voltar
            </Button>
          </Link>
          {isDascam && operationData?.status === OperationStatusEnum.SENT && (
            <>
              <Button
                onClick={onOpen}
                variant={'outline'}
                colorScheme={'red'}
                isLoading={isLoadingCorrection}
              >
                Enviar para Correção
              </Button>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Devolver para Correção</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <FormControl>
                      <FormLabel>Observação</FormLabel>
                      <Textarea
                        placeholder='Informe ao cliente o motivo da devolução deste processo.'
                        value={observation}
                        onChange={(ev) => setObservation(ev.target.value)}
                      />
                    </FormControl>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      variant={'ghost'}
                      colorScheme={'red'}
                      mr={3}
                      onClick={() => {
                        setObservation('')
                        onClose()
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant={'outline'}
                      color={'primary.400'}
                      colorScheme='primary'
                      onClick={onSubmitCorrection}
                    >
                      Confirmar
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
          )}
          {isDascam && operationData?.status === OperationStatusEnum.SENT && (
            <Button
              onClick={onSubmitDascam}
              colorScheme={'primary'}
              isLoading={isLoadingSave}
            >
              Enviar para Syscambio
            </Button>
          )}
        </HStack>
      }
    >
      <Col px={4}>
        <OperationInfos
          currencies={currencies}
          customers={customers}
          value={operationInfos}
          onChange={setOperationInfos}
          readOnly
        />
      </Col>
      <Col px={4} my={2}>
        <OperationDocuments
          documents={documents}
          onChange={setDocuments}
          formsEnabled={[]}
          idSyscambio={''}
          readOnly
          operation={operationInfos}
        />
      </Col>
    </CardPage>
  )
}

export default ShowOperationPage
