import GenericButton from "components/generic/GenericButton";
import Modal from "components/generic/Modal";
import { axiosAuth } from "helpers/axiosInstances";
import { isUnauthorized } from "helpers/errors";
import { UserModel } from "interfaces/user";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading,
} from "redux/actions";
import styled from "styled-components";

const ModalStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const P = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  color: ${props => props.theme.color.gray1};
  font-size: 0.1.2rem;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;

  & span {
    color: ${props => props.theme.color.primary_light};
  }
`;

const ButtonDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const Button = styled(GenericButton)<{ width?: string }>`
  margin: 1rem 0.2rem;
  width: ${props => (props.width ? props.width : "150px")};
  padding: 0.5rem;
`;

interface Props {
  showModal: boolean;
  getUserData: () => void;
  handleModal: () => void;
  id: string;
}

const DisableUser = ({
  showModal,
  getUserData,
  handleModal,
  id,
}: Props): JSX.Element => {
  const [showModalData, setShowModalData] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState<UserModel>({});

  const getUser = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/users/${id}`, config);

      setUserData(data);
      setShowModalData(true);
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const body = {
        status: "INACTIVE",
      };

      await axiosAuth.put(`/users/${userData.id}`, body, config);

      dispatch(setSuccessMessage("Usuário desativado com sucesso!"));

      close();
      getUserData();
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  const close = () => {
    setUserData({});
    handleModal();
    setShowModalData(false);
  };

  useEffect(() => {
    if (showModal === true) {
      getUser();
    }
    // setUserData({ ...userData, company_id: user?.id! });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <Modal
      title="Desativar usuário"
      showModal={showModalData}
      maxWidth="350px"
      backFocus
    >
      <ModalStyled>
        <P>
          Deseja realmente desativar o usuário:{" "}
          <span>{userData.user_name}</span>
        </P>
      </ModalStyled>
      <ButtonDiv>
        <Button isDisabled type="button" onClick={() => close()}>
          Cancelar
        </Button>
        <Button type="button" onClick={() => handleSubmit()}>
          Confirmar
        </Button>
      </ButtonDiv>
    </Modal>
  );
};

export default DisableUser;
