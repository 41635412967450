import GenericButton from 'components/generic/GenericButton'
import { valueBothConvert, valueConvert, valueReconvert } from 'helpers/moedas'
import { OperationModel } from 'interfaces/operation'
import { OperationDocumentModel } from 'interfaces/operation_document'
import { SetModel } from 'interfaces/set_model'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Document from './LineDocuments'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { clearGlobalMessage, setGlobalMessage } from 'redux/actions'
import { isUnauthorized } from 'helpers/errors'
import { useHistory } from 'react-router-dom'
import { axiosWebsite } from 'helpers/axiosInstances'

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
`

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`

const Title = styled.h4`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray2};
  align-self: flex-start;
  padding: 1rem;
`

const TransactionData = styled.div`
  width: 620px;
  height: 65px;
  background: ${(props) => props.theme.color.white1};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.gray4};
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  padding: 0.5rem;
  margin-bottom: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const TieDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
`

const Space = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const End = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

const TextBlue = styled.p`
  color: ${(props) => props.theme.color.primary_main};
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`

const TextGray = styled.p`
  color: ${(props) => props.theme.color.gray2};
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`

const ButtonConfirm = styled(GenericButton)<{ isDeny?: boolean }>`
  margin: 1rem 0;
  width: 200px;
  color: ${(props) => props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`

const CardGrid = styled.div`
  width: 100%;
  height: 100%;
  max-height: 500px;
  overflow: auto;
`

const TableGrid = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns:
    minmax(100px, 150px) minmax(180px, 230px) minmax(250px, 300px)
    minmax(180px, 230px) minmax(150px, 200px) minmax(150px, 200px) auto auto;
  grid-auto-rows: 35px;
  height: 100%;
  width: auto;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

// const Pagination = styled.ul`
//   display: flex;
//   list-style: none;
//   margin: 0.5rem 2rem;
// `;

// const PageButton = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-weight: bold;
//   background: ${props => props.theme.color.button_normal};
//   color: ${props => props.theme.color.white1};
//   border-radius: 50%;
//   height: 1.4rem;
//   width: 1.4rem;
//   padding: 0.2rem;
//   border: none;
//   outline: none;
//   cursor: pointer;
// `;

// const PageNum = styled.p<{ isActive: boolean }>`
//   margin: 0 0.2rem;
//   color: ${props =>
//     props.isActive ? props.theme.color.gray1 : props.theme.color.gray2};
//   font-weight: ${props => (props.isActive ? "bold" : null)};
//   cursor: pointer;

//   @media ${devices.desktop} {
//     font-size: 0.9rem;
//   }

//   @media ${devices.bigDesktop} {
//     font-size: 1rem;
//   }
// `;

const ButtonToModal = styled(GenericButton)<{
  isDeny?: boolean
  isDisabled?: boolean
}>`
  margin: 0.2rem 0;
  width: auto;
  // min-width: 200px;
  color: ${(props) =>
    props.isDeny
      ? props.theme.color.white1
      : props.isDisabled
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isDisabled
      ? props.theme.color.gray3
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isDisabled
        ? props.theme.color.gray4
        : props.theme.color.secondary_light};
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }
`

type Tabs = 'Game' | 'Money-Order' | 'Invoice' | 'Knowledge'
interface Props {
  typeOperation: string
  changeRender: (value: string) => void
  data: OperationModel
  changeData: (value: OperationModel) => void
  operationList: OperationDocumentModel[]
  setOperationList: (value: OperationDocumentModel[]) => void
  gameSetList: SetModel[]
  setGameSetList: (value: SetModel[]) => void
  isReadOperation: boolean
  setIsReadOperation: (value: boolean) => void
  getUserData: () => void
}

const Documents = ({
  typeOperation,
  changeRender,
  data,
  changeData,
  operationList,
  setOperationList,
  gameSetList,
  setGameSetList,
  isReadOperation,
  setIsReadOperation,
  getUserData
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState<Tabs>('Game')
  const [showOperationModal, setShowOperationModal] = useState<boolean>(false)
  const [totalValue, setTotalValue] = useState<number>(-1)
  const [totalApplied, setTotalApplied] = useState<number>(0)
  const [lastGame, setLastGame] = useState<number>(0)

  useEffect(() => {
    setTotalValue(
      Number(valueReconvert(String(data.operation_value), 'ANY')) - totalApplied
    )
  }, [totalApplied, data.operation_value])

  const calculationDoc = (data: OperationDocumentModel[]) => {
    const newPrice = data
      .filter(
        (e) =>
          e.applied_value !== undefined &&
          e.document_type !== 'CONHECIMENTO' &&
          e.document_type !== 'FATURA' &&
          e.document_type !== 'ORDEM DE PAGAMENTO'
      )
      .map((i) => Number(valueReconvert(String(i.applied_value), 'ANY')))

    // console.log(newPrice);
    let priceAll = 0
    for (let x = 0; x < newPrice.length; x++) {
      priceAll += newPrice[x]
    }
    // console.log(priceAll);
    setTotalApplied(priceAll)
  }

  const observerLastGame = (data: SetModel[]) => {
    // let temp = 0;
    // data.forEach(i => {
    //   if (temp < Number(i.set_number)) {
    //     temp = Number(i.set_number);
    //   }
    // });
    setLastGame(data.length)
  }

  const createList = (data: SetModel[]) => {
    const list: OperationDocumentModel[] = []
    data.forEach((e) => {
      const obj1 = e
      obj1.operation_documents?.forEach((e2) => {
        e2.uu_id = uuidv4()
        list.push(
          Object.assign(e2, {
            set_number: obj1.set_number,
            set_value: obj1.set_value,
            set_reference: obj1.set_reference
          })
        )
      })
    })
    return list
  }

  const handleUnItem = (
    uuid: string,
    set_number: number,
    set_id: number,
    id?: number,
    fn?: () => void
  ) => {
    dispatch(
      setGlobalMessage({
        message:
          id !== undefined && id !== 0
            ? 'Deseja realmente excluir este item? \n Este item será excluído diretamente da base de dados!'
            : 'Deseja realmente excluir este item?',
        type: 'MESSAGE',
        buttons: (
          <>
            <ButtonToModal
              onClick={() => {
                handleDeleteDocument(uuid, set_number, set_id, id, fn)
                dispatch(clearGlobalMessage())
              }}
            >
              Ok
            </ButtonToModal>
            <ButtonToModal
              isDeny
              onClick={() => {
                dispatch(clearGlobalMessage())
              }}
            >
              Cancelar
            </ButtonToModal>
          </>
        )
      })
    )
    // let res = [...operationList];
    // res.splice(index, 1);
    // setOperationList(res);
  }

  // const handleDeleteItem = (id: number, set_number: number, set_id: number) => {
  //   dispatch(
  //     setGlobalMessage({
  //       message:
  //         "Deseja realmente excluir este item? \n O item será excluido direto da base de dados",
  //       type: "MESSAGE",
  //       buttons: (
  //         <>
  //           <ButtonToModal
  //             onClick={() => {
  //               handleDeleteDocument(id, set_number, set_id);
  //               dispatch(clearGlobalMessage());
  //             }}
  //           >
  //             Ok
  //           </ButtonToModal>
  //           <ButtonToModal
  //             isDeny
  //             onClick={() => {
  //               dispatch(clearGlobalMessage());
  //             }}
  //           >
  //             Cancelar
  //           </ButtonToModal>
  //         </>
  //       ),
  //     })
  //   );
  // };

  const handleDeleteDocument = async (
    uuid: string,
    set_number: number,
    set_id: number,
    id?: number,
    fn?: () => void
  ) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      // console.log(id);
      if (id !== undefined && id !== 0) {
        await axiosWebsite.delete(`/operationdoc?id=${id}`, config)
      }

      let newGameList = [...gameSetList]

      let newDocList = newGameList.filter(
        (e) => Number(e.set_number) === set_number
      )[0].operation_documents

      const index = newDocList
        ?.map((e) => {
          return e.uu_id
        })
        .indexOf(uuid)

      newDocList?.splice(Number(index), 1)

      newGameList.filter(
        (e) => Number(e.set_number) === set_number
      )[0].operation_documents = newDocList

      if (newDocList?.length === 0) {
        const indexGame = newGameList
          .map((e) => Number(e.set_number))
          .indexOf(set_number)
        newGameList.splice(Number(indexGame), 1)
        for (let i = 0; i < newGameList.length; i++) {
          newGameList[i].set_number = i + 1
        }
        if (id !== undefined && id !== 0) {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
          }
          await axiosWebsite.delete(`/set?set_id=${set_id}`, config)
        }
      }
      setGameSetList(newGameList)
      // console.log(newGameList, "Lista do Final total master");
      if (fn) {
        fn()
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    }
  }

  useEffect(() => {
    setOperationList(createList(gameSetList))
    // console.log(gameSetList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameSetList])

  useEffect(() => {
    if (operationList.length !== 0) {
      calculationDoc(operationList)
    } else {
      setTotalApplied(0)
    }
    observerLastGame(gameSetList)
    // console.log(gameSetList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationList])

  return (
    <>
      <Container>
        <ItemRowTop>
          <Title>documentos da operação</Title>
          <TransactionData>
            <TieDiv>
              <TextBlue>Valor de Moeda Estrangeira:</TextBlue>
              <Space>
                <TextGray>{data.currency_iso}</TextGray>
                <TextGray>
                  {valueBothConvert(String(data.operation_value), 'ANY')}
                </TextGray>
              </Space>
            </TieDiv>
            <TieDiv>
              <TextBlue>Total Aplicações jogos:</TextBlue>
              <End>
                <TextGray>{valueConvert(totalApplied, 'ANY')}</TextGray>
              </End>
            </TieDiv>
            <TieDiv>
              <TextBlue>Total a Aplicar:</TextBlue>
              <End>
                <TextGray>{valueConvert(totalValue, 'ANY')}</TextGray>
              </End>
            </TieDiv>
          </TransactionData>
          {/* <Pagination>
            {currentPage > 1 ? (
              <PageButton title="Página anterior" onClick={() => prevPage()}>
                {"<"}
              </PageButton>
            ) : null}
            {pages.map(page => (
              <PageNum
                title={`Página ${page}`}
                isActive={currentPage === Number(page)}
                onClick={() => goToPage(page)}
              >
                {page}
              </PageNum>
            ))}
            {currentPage < totalPages() ? (
              <PageButton title="Próxima página" onClick={() => nextPage()}>
                {">"}
              </PageButton>
            ) : null}
          </Pagination> */}
        </ItemRowTop>
        <ItemRowTop>
          {isReadOperation !== true ? (
            <ButtonConfirm
              type='button'
              onClick={() => setShowOperationModal(!showOperationModal)}
            >
              Novo Jogo
            </ButtonConfirm>
          ) : null}
        </ItemRowTop>
        <CardGrid>
          <TableGrid>
            <GridTitle>
              <Text fontWeight='bold'>Jogo</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Referência</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Valor da Aplicação do jogo</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Doc.</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Número</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Valor Aplicado</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Anexo</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Ações</Text>
            </GridTitle>
            {/*Mudar depois*/}
            {operationList
              .sort((a, b) => Number(a.set_number) - Number(b.set_number))
              .map((document, index) => {
                let last = false
                if (index === operationList.length - 1) {
                  last = true
                }
                return (
                  <Document
                    last={last}
                    index={index}
                    document={document}
                    handleUnItem={handleUnItem}
                    gameSetList={gameSetList}
                    setGameSetList={(value) => setGameSetList(value)}
                    typeOperation={typeOperation}
                    lastGame={lastGame}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    operationList={operationList}
                    isReadOperation={isReadOperation}
                    getUserData={getUserData}
                    totalValue={totalValue}
                  />
                )
              })}
          </TableGrid>
        </CardGrid>
      </Container>
    </>
  )
}

export default Documents
