import { useSelector } from "react-redux";
import { GlobalState } from "redux/types";
import styled, { keyframes } from "styled-components";
import { ReactComponent as ConfirmHand } from "assets/confirmHand.svg";
import { Spinner } from "@chakra-ui/react";
import { Col } from "v2/components/shared/Col";

const BarJump = keyframes`
  0% {
    transform: translate(0);
    filter: hue-rotate(0);
  }
  30% {
    transform: translate(0);
  }
  50% {
    transform: translateY(-120%);
    filter: hue-rotate(100deg);

  }
  70% {
    transform: translate(0);
  }
  100% {
    transform: translate(0);
    filter: hue-rotate(0);
  }
`;

const Background = styled.div<{ backFocus?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.backFocus ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.8)"};
  z-index: 70000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  padding: 1rem 1.5rem;
  width: auto;
  /* max-width: 350px; */
  height: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px ${(props) => props.theme.color.gray4};
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.8);
  /* background: ${(props) => props.theme.color.white1}; */
`;

// const EnterpriseName = styled.h3`
//   font-size: 25px;
//   font-weight: bold;
//   color: ${props => props.theme.color.primary_main};
//   margin-right: 10px;
// `;

const LineDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.color.primary_light};
  margin-right: 10px;
`;

const DivBars = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LoadingBar = styled.div<{ delay: number }>`
  margin: 0 0.2rem;
  width: 5px;
  height: 10px;
  background: ${(props) => props.theme.color.primary_light};
  border-radius: 10px;
  animation: ${BarJump} 800ms infinite ease-in-out
    ${(props) => `${props.delay}ms`};
`;

const Hand = styled(ConfirmHand)`
  margin: 0 0.2rem;
  width: 20px;
  height: 20px;

  & path {
    fill: ${(props) => props.theme.color.primary_light};
  }
`;

const Message = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.color.gray3};
  margin-top: 1rem;
`;

const NewLoading = (): JSX.Element | null => {
  const loading = useSelector((state: GlobalState) => state.loading);

  if (loading?.isLoading) {
    return (
      <Background backFocus={loading.backFocus}>
        {/* <LineDiv>
            <EnterpriseName>Dascam Direct</EnterpriseName>
          </LineDiv> */}
        <Col>
          <LineDiv>
            {loading.isLoaded ? (
              <Hand />
            ) : (
              <DivBars>
                <Spinner margin={2} color={"primary.400"} emptyColor={"gray.200"} />
              </DivBars>
            )}
            <Title>{loading.message}</Title>
          </LineDiv>
          <LineDiv>
            <Message>
              {loading.subMessage
                ? loading.subMessage
                : "Isso pode levar algum tempo."}
            </Message>
          </LineDiv>
        </Col>
      </Background>
    );
  } else {
    return null;
  }
};
export default NewLoading;
