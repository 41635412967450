import { formatCNPJ, formatCPF } from "helpers/formats";
import { CustomerModel } from "interfaces/customer";
import { ColumnFieldByName } from "v2/components/shared/SimpleFormGenerator";
import { apiClient } from "v2/services/clients/apiClient";

export function createCustomerSearchPicker(
  userId: string | number,
  filterList?: number[],
  options?: Partial<ColumnFieldByName["search-picker"]>,
  initialItems?: CustomerModel[]
): ColumnFieldByName["search-picker"] {
  return {
    type: "search-picker",
    label: "CNPJ/CPF",
    name: "customer",
    labelKey: "document",
    onSearch: async () => {

      let items =
        initialItems || (await apiClient.customers.getCustomersByUser(userId));
      let filter: CustomerModel[] = []
      if (filterList) {
        if (filterList.length === 0) {
          items = []
        } else {
          filterList.forEach(a => {
            const item = items.find(e => Number(e.id_syscambio) === a)
            if (item) {
              filter.push(item)
            }

          })
        }

      }


      return Promise.resolve({
        title: "Selecione um cliente",
        items: filter.length !== 0 ? filter : items,
        uuidField: "id_syscambio",
        _modal: { size: "3xl" },
        columns: [
          { title: "Código", key: "id_syscambio" },
          {
            title: "Razão Social",
            key: "corporate_name",
            length: 30,
          },
          {
            title: "Nome Fantasia",
            key: "fantasy_name",
            length: 30,
          },
          {
            title: "CPF/CNPJ", key: "document", render(value, item, index) {
              return String(value).length === 11
                ? formatCPF(String(value))
                : formatCNPJ(String(value))
            },
          },
        ],
        search: {
          filters: {
            code: ["id_syscambio"],
            corporateName: ["corporate_name"],
            fantasyName: ["fantasy_name"],
          },
          initialValue: {
            code: "",
            fantasyName: "",
            corporateName: "",
          },
          rows: [
            {
              columns: 3,
              fields: [
                {
                  type: "text",
                  key: "code",
                  label: "Código",
                  placeholder: "Pesquisar por código",
                },
                {
                  type: "text",
                  key: "fantasyName",
                  label: "Nome Fantasia",
                  placeholder: "Pesquisar por nome",
                },
                {
                  type: "text",
                  key: "corporateName",
                  label: "Razão Social",
                  placeholder: "Pesquisar por nome",
                },
              ],
            },
          ],
        },
      });
    },
    ...options,
  };
}
