import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BgAreas from "assets/fundoCadastro.png";
import LogoDascam from "assets/Logo 2.png";
import { useDispatch } from "react-redux";
import { clearGlobalMessage, clearLoading, clearUser, setGlobalMessage, setSuccessMessage, showLoading } from "redux/actions";
import { axiosAuth, axiosEmail, axiosLogin } from "helpers/axiosInstances";
import { devices } from "helpers/devices";
import { Button, useToast, Flex, Spacer, Box, Image, InputGroup, Stack, InputLeftElement, Input, InputRightElement, IconButton, Alert, AlertDescription, AlertIcon, AlertTitle, Text, Center, Highlight, Heading } from "@chakra-ui/react";
import { AxiosError, AxiosResponse } from "axios";
import { PhoneIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { isNoContent, isUnauthorized } from "helpers/errors";
import jsrsasign from "jsrsasign";

const FirstAccessPassword: React.FC = () => {
    const [login, setLogin] = useState<string>("");
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [verifyPassword, setVerifyPassword] = useState<string>("");
    const [passwordEquals, setPasswordEquals] = useState<boolean>(false);
    const [auth, setAuth] = useState<boolean>(false);
    const [buttonLoad, setButtonLoad] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const [show, setShow] = React.useState(false)
    const [show1, setShow1] = React.useState(false)
    const [show2, setShow2] = React.useState(false)
    const handleClick = () => setShow(!show)
    const handleClick1 = () => setShow1(!show1)
    const handleClick2 = () => setShow2(!show2)

    const toast = useToast()

    const logout = () => {
        dispatch(clearGlobalMessage());
        dispatch(clearUser());
        localStorage.clear();
        history.push("/login");
    };

    const clearInput = () => {
        setLogin("");
        setPassword("");
        setVerifyPassword("");
        setPasswordEquals(false);
    };

    const handleSubmit = async () => {
        try {
            // dispatch(
            //     showLoading({
            //         message: "Autenticando",
            //         backFocus: true,
            //         isLoading: true,
            //     })
            // );
            setButtonLoad(true);

            const body = {
                username: login,
                password: currentPassword,
                grant_type: "password",
            };

            const { data } = await axiosLogin.post("/login", body);

            if (data.firstAccess) {
                // localStorage.setItem("fullName", data.fullName);
                // localStorage.setItem("username", email);
                // localStorage.setItem("currentPassword", password);
                // history.push("/first-login");
            } else {
                // Pegando id de usuário
                const {
                    KJUR: {
                        jws: { JWS },
                    },
                } = jsrsasign;
                const login = JWS.readSafeJSONString(
                    jsrsasign.b64utoutf8(data.access_token.split(".")[1])
                );

                if (login) {
                    // const { data: user } = await fetchOneUser({
                    //   id: (login as any).id,
                    // });
                    // dispatch(setUser(user));

                    localStorage.setItem("logged_user", (login as any).id);
                }

                localStorage.setItem("access_token", data.access_token);
                localStorage.setItem("refresh_token", data.refresh_token);
                localStorage.setItem("type", data.cad_type);

            }
            setAuth(true)
        } catch (error: any) {
            if (isUnauthorized(error)) {
                // dispatch(
                //     setGlobalMessage({
                //         message: `Usuário ou senha incorreto!`,
                //         type: "ERROR",
                //     })
                // );
                toast({
                    title: 'Usuário ou Senha inválidos!',
                    position: 'bottom',
                    description: "Por favor, verifique os dados e tente novamente.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            } else if (isNoContent(error)) {
                // dispatch(
                //     setGlobalMessage({
                //         message: `Usuário não encontrado!`,
                //         type: "ERROR",
                //     })
                // );
                toast({
                    title: 'Usuário não localizado!',
                    position: 'bottom',
                    description: "Por favor, verifique os dados e tente novamente ou entre em contato com a DASCAM.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            } else {
                // dispatch(
                //     setGlobalMessage({
                //         message: `Error: ${error}`,
                //         type: "ERROR",
                //     })
                // );
                toast({
                    title: 'Erro!',
                    position: 'bottom',
                    description: `${error}`,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } finally {
            // dispatch(clearLoading());
            setButtonLoad(false);
        }
    };
    const handleChangePassword = () => {
        if (passwordEquals === true) {
            dispatch(
                showLoading({
                    message: "Carregando",
                    isLoading: true,
                })
            );
            const body = {
                username: login,
                currentPassword: currentPassword,
                newPassword: password,
                confirmationPassword: verifyPassword,
            };

            axiosAuth
                .put(`/passwords`, body)
                .then((response: AxiosResponse) => {
                    // dispatch('');
                    toast({
                        title: 'Senha alterada!',
                        position: 'bottom',
                        description: "Realize seu login com a nova senha.",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                    setLogin("");
                    setCurrentPassword("");
                    setPassword("");
                    setVerifyPassword("");
                    logout();
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 403) {
                        dispatch(
                            setGlobalMessage({
                                message: `Error: ${error.response?.status}`,
                                type: "ERROR",
                                buttons: (
                                    <Button
                                        variant={"outline"}
                                        color={"primary.400"}
                                        colorScheme={"primary"}
                                        onClick={() => {
                                            history.push("/");
                                            dispatch(clearGlobalMessage());
                                        }}
                                    >
                                        Ok
                                    </Button>
                                ),
                            })
                        );
                    } else {
                        dispatch(
                            setGlobalMessage({
                                message: `Error: ${error.response?.status}`,
                                type: "ERROR",
                            })
                        );
                    }
                })
                .finally(() => {
                    dispatch(clearLoading());
                });
        } else {
            dispatch(
                setGlobalMessage({
                    message: `Preencha os campos corretamente!`,
                    type: "ERROR",
                })
            );
        }
    };

    useEffect(() => {
        if (password !== "" && verifyPassword !== "") {
            if (password === verifyPassword) {
                setPasswordEquals(true);
            } else {
                setPasswordEquals(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password && verifyPassword]);

    return (
        <Flex backgroundImage={BgAreas} backgroundRepeat={"no-repeat"} backgroundSize={"cover"} backgroundPosition={"center"} direction={"column"} align={"flex-end"} justify={"center"} h={"100vh"} w={"100vw"}>
            <Flex direction={"column"} justify={"space-around"} align={"center"} bg={"rgba(255, 255, 255, 0.85)"} h={"100%"} w={"100%"} maxW={"700px"} mr={40}>
                <Box maxW={"sm"}><Image src={LogoDascam} alt="Logo" /></Box>
                <Alert
                    background={"#d1e9ff"}
                    status='info'
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height='150px'
                >
                    <AlertIcon boxSize='30px' mr={0} color={"primary.500"} />
                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                        Olá! Bem-vindo ao DASCAM Direct!
                    </AlertTitle>
                    <AlertDescription maxWidth='sm'>
                        Antes de começar a usar o sistema, por favor valide seu usuário e redefine sua senha.
                    </AlertDescription>
                </Alert>
                <Stack spacing={5}>
                    <InputGroup hidden={auth ? true : false}>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<FaUser color='gray.300' />}
                        />
                        <Input
                            variant='filled'
                            value={login}
                            type='text'
                            placeholder='Login'
                            onChange={(ev) => setLogin(ev.target.value)} />
                    </InputGroup>
                    <InputGroup size='md' hidden={auth ? true : false}>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<RiLockPasswordFill color='gray.300' />}
                        />
                        <Input
                            variant='filled'
                            pr='4.5rem'
                            type={show ? 'text' : 'password'}
                            placeholder='Senha Atual'
                            value={currentPassword}
                            onChange={(ev) => setCurrentPassword(ev.target.value)}
                        />
                        <InputRightElement width='4.5rem'>
                            <IconButton
                                h={"80%"}
                                variant={"ghost"}
                                colorScheme={"primary"}
                                aria-label="show"
                                icon={show ? <ViewOffIcon /> : <ViewIcon />}
                                onClick={handleClick} />
                        </InputRightElement>
                    </InputGroup>
                    <Button
                        hidden={auth ? true : false}
                        isLoading={buttonLoad}
                        loadingText={"Validando"}
                        variant={"outline"}
                        color={"primary.400"}
                        colorScheme={"primary"}
                        spinnerPlacement='start'
                        onClick={() => handleSubmit()}>
                        Validar Usuário
                    </Button>
                    <Stack hidden={auth ? false : true}>
                        <Heading size={"md"} color={"primary.500"}>{`${login},`}</Heading>
                        <Heading size={"sm"}>Insira sua nova senha nos campos abaixo e confirme a troca.</Heading>
                    </Stack>
                    <InputGroup size='md' hidden={auth ? false : true}>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<RiLockPasswordFill color='gray.300' />}
                        />
                        <Input
                            variant='filled'
                            pr='4.5rem'
                            type={show1 ? 'text' : 'password'}
                            placeholder='Nova Senha'
                            value={password}
                            onChange={(ev) => setPassword(ev.target.value)}
                        />
                        <InputRightElement width='4.5rem'>
                            <IconButton
                                h={"80%"}
                                variant={"ghost"}
                                colorScheme={"primary"}
                                aria-label="show"
                                icon={show1 ? <ViewOffIcon /> : <ViewIcon />}
                                onClick={handleClick1} />
                        </InputRightElement>
                    </InputGroup>
                    <InputGroup size='md' hidden={auth ? false : true}>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<RiLockPasswordFill color='gray.300' />}
                        />
                        <Input
                            variant='filled'
                            pr='4.5rem'
                            type={show2 ? 'text' : 'password'}
                            placeholder='Confirmar Senha'
                            value={verifyPassword}
                            onChange={(ev) => setVerifyPassword(ev.target.value)}
                        />
                        <InputRightElement width='4.5rem'>
                            <IconButton
                                h={"80%"}
                                variant={"ghost"}
                                colorScheme={"primary"}
                                aria-label="show"
                                icon={show2 ? <ViewOffIcon /> : <ViewIcon />}
                                onClick={handleClick2} />
                        </InputRightElement>
                    </InputGroup>
                </Stack>
                <Stack w={"40%"} spacing={4} h={"30%"}>
                    <Button
                        hidden={auth ? false : true}
                        variant={"outline"}
                        color={"primary.400"}
                        colorScheme={"primary"}
                        onClick={() => handleChangePassword()}>
                        Redefinir Senha
                    </Button>
                    {/* <Button
                        variant={"outline"}
                        color={"red.400"}
                        colorScheme={"red"}
                        onClick={() => history.push("/")}>
                        Cancelar
                    </Button> */}
                </Stack>
            </Flex>
        </Flex>
    );
}

export default FirstAccessPassword