export const validBody = (currentUserData: any, newUserData: any) => {
  let diff = (
    Object.keys(newUserData) as Array<keyof typeof newUserData>
  ).reduce((diff, key) => {
    if (currentUserData[key] === newUserData[key]) return diff;
    if (newUserData[key] !== "") {
      // console.log("Caiu Diferente de vazio:");
      return {
        ...diff,
        [key]: newUserData[key],
      };
    } else if (newUserData[key] === "" && currentUserData[key] !== null) {
      // console.log("Caiu igual vazio e diferente null:");
      return {
        ...diff,
        [key]: null,
      };
    } else {
      console.log("Caiu como null:");
      const deleteList: any = diff;
      delete deleteList[key];
      diff = deleteList;
      return diff;
    }
  }, {});
  // console.log(diff);
  return diff;
};

export const enableButtonUser = (userData: any) => {
  let value = (Object.keys(userData) as Array<keyof typeof userData>).length;
  if (value !== 0) {
    return true;
  } else {
    return false;
  }
};
