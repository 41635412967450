import {
  Grid,
  GridItem,
  Select,
  Button,
  InputGroup,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Alert,
  AlertDescription,
  AlertIcon,
  InputRightElement,
  IconButton,
  Text,
  FormControl,
  FormLabel,
  SimpleGrid,
  Center,
  useDisclosure
} from '@chakra-ui/react'
import CardPage from 'v2/components/pages/CardPage'
import React, { useMemo, useState, useEffect } from 'react'
import { BankEntity } from 'interfaces/bank'
import { AddIcon, SearchIcon } from '@chakra-ui/icons'
import { apiClient } from 'v2/services/clients/apiClient'
import { axiosAuth, axiosWebsite } from 'helpers/axiosInstances'
import { createColumnHelper } from '@tanstack/react-table'
import { BankModel } from 'interfaces/bank'
import { BankAgencyModel } from 'interfaces/bankAgency'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { DataTable } from 'v2/components/forms/DataTable/DataTable'

const FormBankInfo: React.FC = () => {
  const { user } = useGlobals()
  const [isOpen, setOpen] = useState(false)
  const [banksList, setBankList] = useState<BankModel[]>([])
  const columnHelper = createColumnHelper<BankModel>()
  const columns = [
    columnHelper.accessor('bank_code', {
      cell: (info) => info.getValue(),
      header: 'Cód. Banco'
    }),
    columnHelper.accessor('bank_info', {
      cell: (info) => info.getValue(),
      header: 'Nome Banco'
    })
  ]

  const getBanksList = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    const res = await axiosWebsite.get(`/bancos`, config)
    setBankList(res.data)
  }

  return (
    <CardPage title='Dados Bancários'>
      <Center>
        <Alert
          background={'#d1e9ff'}
          status='info'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          w={'65%'}
        >
          <AlertIcon color={'primary.500'} />
          <AlertDescription fontSize={'xs'}>
            <Text fontWeight={'bold'}>
              Todos os campos são de preenchimento obrigatório!
            </Text>
            Caso não sejam encontrados dados para os itens selecionados ou não
            encontre alguma informação, por favor entre em contato com a equipe
            DASCAM.
          </AlertDescription>
        </Alert>
      </Center>
      <Grid
        templateAreas={`"header header"`}
        gridTemplateRows={'50%'}
        gridTemplateColumns={'150px 1fr'}
        h='80%'
        gap='1'
        color='blackAlpha.700'
        fontWeight='bold'
      >
        <GridItem p='5' ml={5} mr={5} mb={2} area={'header'}>
          <SimpleGrid spacing={1} minChildWidth='180px'>
            <FormControl>
              <FormLabel fontWeight={'bold'} fontSize={'md'}>
                Banco
              </FormLabel>
              <InputGroup size='md'>
                <Input type={'text'} placeholder='Selecione um Banco' />
                <InputRightElement width='3.5rem'>
                  <IconButton
                    h={'80%'}
                    variant={'ghost'}
                    colorScheme='primary'
                    aria-label='Search database'
                    icon={<SearchIcon />}
                    onClick={() => {
                      getBanksList()
                      setOpen(true)
                    }}
                  />
                </InputRightElement>
              </InputGroup>
              <DataTable
                isOpen={isOpen}
                onClose={() => setOpen(() => false)}
                columns={columns}
                data={banksList}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight={'bold'} fontSize={'md'}>
                Estado
              </FormLabel>
              <Select variant='outline' placeholder='Selecione um Estado' />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight={'bold'} fontSize={'md'}>
                Cidade
              </FormLabel>
              <Select variant='outline' placeholder='Selecione uma Cidade' />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight={'bold'} fontSize={'md'}>
                Agência
              </FormLabel>
              <InputGroup size='md'>
                <Input type={'text'} placeholder='Selecione uma Agência' />
                <InputRightElement width='3.5rem'>
                  <IconButton
                    h={'80%'}
                    variant={'ghost'}
                    colorScheme='primary'
                    aria-label='Search database'
                    icon={<SearchIcon />}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </SimpleGrid>
          <SimpleGrid
            spacing={1}
            minChildWidth='180px'
            mt={15}
            mb={20}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <FormControl>
              <FormLabel fontWeight={'bold'} fontSize={'md'}>
                Tipo de Conta
              </FormLabel>
              <Select
                variant='outline'
                placeholder='Selecione um Tipo de Conta'
              >
                <option value='C'>Conta Corrente</option>
                <option value='P'>Conta Poupança</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel fontWeight={'bold'} fontSize={'md'}>
                Número da Conta
              </FormLabel>
              <Input type={'number'} placeholder='Número da Conta' />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight={'bold'} fontSize={'md'}>
                Data de Abertura
              </FormLabel>
              <Input placeholder='Data de Abertura' size='md' type='date' />
            </FormControl>
            <FormControl>
              <FormLabel visibility={'hidden'}>Adicionar Conta</FormLabel>
              <Center>
                <Button
                  iconSpacing={3}
                  leftIcon={<AddIcon />}
                  colorScheme={'primary'}
                >
                  Adicionar Conta
                </Button>
              </Center>
            </FormControl>
          </SimpleGrid>
          <TableContainer>
            <Table variant='simple' colorScheme='primary'>
              <TableCaption>Imperial to metric conversion factors</TableCaption>
              <Thead>
                <Tr backgroundColor={'primary.400'}>
                  <Th color={'white'}>To convert</Th>
                  <Th color={'white'}>into</Th>
                  <Th color={'white'} isNumeric>
                    multiply by
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>inches</Td>
                  <Td>millimetres (mm)</Td>
                  <Td isNumeric>25.4</Td>
                </Tr>
                <Tr>
                  <Td>feet</Td>
                  <Td>centimetres (cm)</Td>
                  <Td isNumeric>30.48</Td>
                </Tr>
                <Tr>
                  <Td>yards</Td>
                  <Td>metres (m)</Td>
                  <Td isNumeric>0.91444</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr backgroundColor={'primary.400'}>
                  <Th color={'white'}>To convert</Th>
                  <Th color={'white'}>into</Th>
                  <Th color={'white'} isNumeric>
                    multiply by
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </GridItem>
      </Grid>
    </CardPage>
  )
}

export default FormBankInfo
