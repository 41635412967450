import { OperationModel } from "interfaces/operation";
import jsPDF from "jspdf";
import { OperationQuery, PdfExportHelper } from "services/pdf/PdfExportHelper";
import { useGlobals } from "./useGlobals";

export function usePdfExport() {
  const { setMessage } = useGlobals();

  const createAnalyticsRelatory = async (
    operations: OperationModel,
    consumer?: OperationQuery
  ) => {
    return new PdfExportHelper(new jsPDF()).createAnalyticsRelatory(
      operations,
      consumer
    );
  };

  const createOperationContract = async (operation: OperationModel) => {
    return new PdfExportHelper(new jsPDF())
      .createOperationContract(operation)
      .catch(err => {
        setMessage({
          message: err.message,
          type: "ERROR",
        });
      });
  };

  const createOperationContractMulti = async (operation: OperationModel[]) => {
    return new PdfExportHelper(new jsPDF())
      .createOperationContractMulti(operation)
      .catch(err => {
        setMessage({
          message: err.message,
          type: "ERROR",
        });
      });
  };

  return { createAnalyticsRelatory, createOperationContract, createOperationContractMulti };
}
