import styled from "styled-components";
import { devices } from "helpers/devices";
import GenericButton from "components/generic/GenericButton";
import { InputImgBase64 } from "components/generic/GenericInputs";
import { useEffect, useMemo, useState } from "react";
import { BankModel } from "interfaces/bank";
import { UserModel, UserTypeEnum } from "interfaces/user";
import { useDispatch, useSelector } from "react-redux";
import { axiosAuth } from "helpers/axiosInstances";
import { Redirect, useHistory, useParams } from "react-router-dom";
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading,
} from "redux/actions";
import { isUnauthorized } from "helpers/errors";
import BankAccountPhysical from "./subComponents/BankAccountPhysical";
import DocumentationPhysical from "./subComponents/DocumentationPhysical";
import RegisterPhysical from "./subComponents/RegisterPhysical";
import { ReactComponent as CheckSvg } from "assets/checkSvg.svg";
import { ReactComponent as RevokeSvg } from "assets/revokeSvg.svg";
import ReprovalAndObservation from "components/generic/ReprovalAndObservation";
import ApprovalUser from "components/generic/ApprovalUser";
import { GlobalState } from "redux/types";
import { enableButtonUser, validBody } from "helpers/validBody";
import { ArchiveModel } from "interfaces/user-archives";
import { useGlobals } from "hooks/helpers/useGlobals";
import { Permissions } from "interfaces/web/permission";

const PhysicalStyled = styled.section`
  background: transparent;
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  /* overflow-y: auto; */
`;

const Container = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */

  @media ${devices.phoneOnly} {
    background-position: center left;
  }
`;

const TemplateFlex = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem ${props => props.theme.margins.from_sides};

  @media ${devices.phoneOnly} {
    padding: 1rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 2rem ${props => props.theme.margins.from_sides};
  }
`;

const CardForm = styled.form`
  padding: 1rem;
  box-shadow: 1px 2px 4px gray;
  background-color: ${props => props.theme.color.white1};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
`;

const CardMenu = styled.div`
  width: 300px;
  min-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const Button = styled(GenericButton)<{ width?: string }>`
  margin: 1rem 0;
  width: ${props => (props.width ? props.width : "260px")};
`;

const CardItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.5rem;
  overflow-y: auto;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 100;
  background-color: ${props => props.theme.color.white1};
  height: auto;
  width: 100%;
  position: sticky;
  top: -1px;
  left: 0;
  padding: 0.3rem;

  @media ${devices.tabletPortrait} {
    align-items: flex-start;
  }

  @media ${devices.bigDesktop} {
    align-items: center;
  }
`;

const TabsBlue = styled.div`
  width: 100%;
  height: auto;
  padding: 0.2rem;
  background: ${props => props.theme.color.primary_main};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & div {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }
`;

const ButtonTabs = styled(GenericButton)<{
  isDeny?: boolean;
  isYellow?: boolean;
  isBlue?: boolean;
}>`
  width: auto;
  min-width: 120px;
  height: 25px;
  margin: 0.1rem;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  color: ${props => props.theme.color.white1};
  font-size: 0.9rem;
  background-color: ${props =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isYellow
      ? props.theme.color.secondary_main
      : props.isBlue
      ? "#296ba9"
      : props.theme.color.button_normal} !important;

  &:hover {
    background-color: ${props =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isYellow
        ? props.theme.color.secondary_light
        : props.isBlue
        ? "#4b87be"
        : props.theme.color.button_normal_hover} !important;
  }
`;

const AprovedRevoke = styled.div`
  display: flex;
  flex-direction: collumn;
  align-items: center;
  margin: 0.5rem 0;

  & p {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  }

  & div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
  }
`;

const ButtonAproveRevoke = styled.button<{ isDeny?: boolean }>`
  outline: none;
  border: none;
  width: 70px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 0 0.5rem;
  cursor: pointer;
  background-color: ${props =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.button_normal};

  &:hover {
    background-color: ${props =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.button_normal_hover};
  }

  & svg {
    width: 18px;
    height: 18px;
  }
`;

type Tabs = "registration" | "bank" | "documentation";

interface PathNav {
  id: string;
  email: string;
}

const PhysicalManagement = (): JSX.Element => {
  // const theme = useContext(ThemeContext);
  // const user = useSelector((state: GlobalState) => state.user);
  const { user, hasPermissions } = useGlobals();
  const [currentTab, setCurrentTab] = useState<Tabs>("registration");
  const history = useHistory();
  const dispatch = useDispatch();
  const [showReproval, setShowReproval] = useState<boolean>(false);
  const [showApproval, setShowApproval] = useState<boolean>(false);
  //User State
  const [userData, setUserData] = useState<UserModel>({
    cad_type: "FISICO" as UserTypeEnum,
  });
  const [userFixedData, setUserFixedData] = useState<UserModel>({});
  const [body, setBody] = useState<UserModel>({});
  const [bodyDoc, setBodyDoc] = useState<ArchiveModel>({});
  //Conta Bancaria State
  const [accountBanks, setAccountBanks] = useState<BankModel[]>([]);
  //Documentation
  const [userDocumentation, setUserDocumentation] = useState<ArchiveModel>({});
  const [userFixedDocumentation, setUserFixedDocumentation] =
    useState<ArchiveModel>({});

  const pathNav = useParams<PathNav>();

  const getUserData = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
          subMessage: "Carregando dados do usuário",
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/users/${pathNav.id}`, config);

      setUserFixedData(data);
      setUserData(data);
      getDocumentData();
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    }
  };

  const getDocumentData = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
          subMessage: "Carregando documentos",
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/archives/${pathNav.id}`, config);

      setUserFixedDocumentation(data);
      setUserDocumentation(data);
      getBankData();
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    }
  };

  const getBankData = async () => {
    try {
      dispatch(
        showLoading({
          message: "Finalizando",
          isLoading: true,
          subMessage: "Aplicando dados do usuário",
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/bank/${pathNav.id}`, config);

      setAccountBanks(data);
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      await axiosAuth.put(`/users/${pathNav.id}`, body, config);

      handleSubmitDocuments();
      dispatch(setSuccessMessage("Dados Salvos com Sucesso!"));
      getUserData();
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  const handleSubmitDocuments = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      bodyDoc.user_id = Number(pathNav.id);
      bodyDoc.id = userDocumentation.id;

      console.log("PhysicalManagement.tsx", { bodyDoc });

      await axiosAuth.post(`/archives`, bodyDoc, config);
      getDocumentData();
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBodyDoc(validBody(userFixedDocumentation, userDocumentation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDocumentation]);

  useEffect(() => {
    setBody(validBody(userFixedData, userData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const needsRedirect = useMemo(() => {
    const hasResponsible = userData.responsible_sector !== undefined;
    const isCadastro =
      userData.responsible_sector === "CADASTRO" &&
      hasPermissions([Permissions.CADASTRO_EDIT]);
    const isCompliance =
      userData.responsible_sector === "COMPLIANCE" &&
      hasPermissions([Permissions.COMPLIANCE_EDIT]);
    const isDiretoria =
      userData.responsible_sector === "DIRETORIA" &&
      hasPermissions([Permissions.DIRETORIA_EDIT]);

    return !hasResponsible && !isCadastro && !isCompliance && !isDiretoria;
  }, [userData, hasPermissions]);

  return (
    <>
      {/* {needsRedirect && <Redirect to="/unauthorized" />} */}
      <PhysicalStyled>
        <Container>
          <TemplateFlex>
            <CardForm
              onSubmit={event => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <CardMenu>
                <div>
                  <InputImgBase64
                    avatar={userData.photo!}
                    changeValue={value =>
                      setUserData({
                        ...userData,
                        photo: value,
                      })
                    }
                  />
                  <Button
                    type="button"
                    onClick={() =>
                      document.getElementById("selectedFileImg")!.click()
                    }
                  >
                    Adicionar Foto
                  </Button>
                  {userData.responsible_sector !== undefined ? (
                    (userData.responsible_sector === "CADASTRO" &&
                      hasPermissions([Permissions.CADASTRO_STATUS])) ||
                    (userData.responsible_sector === "COMPLIANCE" &&
                      hasPermissions([Permissions.COMPLIANCE_STATUS])) ||
                    (userData.responsible_sector === "DIRETORIA" &&
                      hasPermissions([Permissions.DIRETORIA_STATUS])) ? (
                      <AprovedRevoke>
                        {userData.responsible_sector === "CADASTRO" &&
                        userData.old_sector !== "DIRETORIA" ? (
                          <>
                            <p>Enviar Cadastro para Compliance</p>
                            <div>
                              <ButtonAproveRevoke
                                type="button"
                                isDeny
                                onClick={() => setShowReproval(!showReproval)}
                              >
                                <RevokeSvg />
                              </ButtonAproveRevoke>
                              <ButtonAproveRevoke
                                type="button"
                                onClick={() => setShowApproval(!showApproval)}
                              >
                                <CheckSvg />
                              </ButtonAproveRevoke>
                            </div>
                          </>
                        ) : null}
                        {userData.responsible_sector === "COMPLIANCE" &&
                        userData.old_sector !== "DIRETORIA" ? (
                          <>
                            <p>Enviar Cadastro para Diretoria</p>
                            <div>
                              <ButtonAproveRevoke
                                type="button"
                                isDeny
                                onClick={() => setShowReproval(!showReproval)}
                              >
                                <RevokeSvg />
                              </ButtonAproveRevoke>
                              <ButtonAproveRevoke
                                type="button"
                                onClick={() => setShowApproval(!showApproval)}
                              >
                                <CheckSvg />
                              </ButtonAproveRevoke>
                            </div>
                          </>
                        ) : null}
                        {userData.responsible_sector === "DIRETORIA" &&
                        userData.old_sector !== "DIRETORIA" ? (
                          <>
                            <p>Aprovar Cliente</p>
                            <div>
                              <ButtonAproveRevoke
                                type="button"
                                isDeny
                                onClick={() => setShowReproval(!showReproval)}
                              >
                                <RevokeSvg />
                              </ButtonAproveRevoke>
                              <ButtonAproveRevoke
                                type="button"
                                onClick={() => setShowApproval(!showApproval)}
                              >
                                <CheckSvg />
                              </ButtonAproveRevoke>
                            </div>
                          </>
                        ) : null}
                        {userData.responsible_sector === "CADASTRO" &&
                        userData.old_sector === "DIRETORIA" ? (
                          <>
                            <p>Aprovar Cliente</p>
                            <div>
                              <ButtonAproveRevoke
                                type="button"
                                isDeny
                                onClick={() => setShowReproval(!showReproval)}
                              >
                                <RevokeSvg />
                              </ButtonAproveRevoke>
                              <ButtonAproveRevoke
                                type="button"
                                onClick={() => setShowApproval(!showApproval)}
                              >
                                <CheckSvg />
                              </ButtonAproveRevoke>
                            </div>
                          </>
                        ) : null}
                      </AprovedRevoke>
                    ) : null
                  ) : null}
                </div>
              </CardMenu>
              <CardItems>
                <TabsContainer>
                  <TabsBlue>
                    <div>
                      <ButtonTabs
                        isBlue={currentTab !== "registration"}
                        isYellow={currentTab === "registration"}
                        type="button"
                        onClick={() => setCurrentTab("registration")}
                      >
                        Pré - cadastro *
                      </ButtonTabs>
                      <ButtonTabs
                        isBlue={currentTab !== "documentation"}
                        isYellow={currentTab === "documentation"}
                        type="button"
                        onClick={() => setCurrentTab("documentation")}
                      >
                        Documentação
                      </ButtonTabs>
                      <ButtonTabs
                        isBlue={currentTab !== "bank"}
                        isYellow={currentTab === "bank"}
                        type="button"
                        onClick={() => setCurrentTab("bank")}
                      >
                        Dados Bancários
                      </ButtonTabs>
                    </div>
                    <div>
                      <>
                        {enableButtonUser(body) || enableButtonUser(bodyDoc) ? (
                          <ButtonTabs type="submit">
                            Salvar Alterações
                          </ButtonTabs>
                        ) : null}
                        <ButtonTabs
                          isDeny
                          type="button"
                          onClick={() =>
                            history.push("/dascam/registration-management")
                          }
                        >
                          Voltar
                        </ButtonTabs>
                      </>
                    </div>
                  </TabsBlue>
                </TabsContainer>
                {currentTab === "registration" ? (
                  <RegisterPhysical
                    userData={userData}
                    changeUserData={value => setUserData(value)}
                  />
                ) : null}
                {currentTab === "documentation" ? (
                  <DocumentationPhysical
                    userDocumentation={userDocumentation}
                    changeDocumentation={value => setUserDocumentation(value)}
                    userData={userData}
                  />
                ) : null}
                {currentTab === "bank" ? (
                  <BankAccountPhysical
                    userId={userData?.id}
                    accountBanks={accountBanks}
                    setAccountBanks={setAccountBanks}
                    getBankData={() => getBankData()}
                    userData={userData}
                  />
                ) : null}
              </CardItems>
            </CardForm>
          </TemplateFlex>
        </Container>
      </PhysicalStyled>
      <ReprovalAndObservation
        showModal={showReproval}
        userData={userData}
        handleModal={() => setShowReproval(!showReproval)}
      />
      <ApprovalUser
        showModal={showApproval}
        userData={userData}
        accountBanks={accountBanks}
        handleModal={() => setShowApproval(!showApproval)}
      />
    </>
  );
};

export default PhysicalManagement;
