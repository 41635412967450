import styled from "styled-components";
import { ReactComponent as DualArrow } from "assets/dual-arrow.svg";
import { ReactComponent as LupaIcon } from "assets/lupaIcon.svg";
import { ReactComponent as Shipping } from "assets/shipping.svg";
import { ReactComponent as Ticket } from "assets/ticket.svg";
import { ReactComponent as Import } from "assets/import.svg";
import { ReactComponent as Export } from "assets/export.svg";
import {
  InputCNPJ,
  InputCNPJCPF,
  InputDate,
  InputDaysSettlement,
  InputNumber,
  InputText,
  InputCurrency,
} from "components/generic/GenericInputs";
import { useEffect, useState } from "react";
import FindFinancialInstitution from "./FindFinancialInstitution";
import FindCustomer from "./FindCustomer";
import FindCurrency from "./FindCurrency";
import { OperationModel } from "interfaces/operation";
import ModalPickBank from "components/generic/logic/ModalPickBank";
import { addDays } from "helpers/date";
import { BiSearchAlt2 } from "react-icons/bi";
// import { useDispatch } from "react-redux";
// import { setGlobalMessage } from "redux/actions";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
  padding-top: 0.5rem;
`;

const BottomLine = styled.div`
  width: 100%;
  padding-top: 1rem;
  border-bottom: ${(props) => props.theme.color.primary_main} 1px solid;
`;

const Base = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.color.primary_main};
  margin: 0 1rem;
`;

const SimuInput = styled.div`
  width: auto;
  height: 40px;
  background: ${(props) => props.theme.color.white1};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.gray4};
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 1rem;

  & p {
    font-size: 1rem;
    color: ${(props) => props.theme.color.text3};
    margin: 0 0.3rem;
  }

  & svg {
    width: 30px;
    height: 30px;
    margin: 0 0.3rem;
  }
`;

const ButtonYellow = styled.button<{ marginBottom?: string }>`
  width: 40px;
  height: 40px;
  padding: 0.3rem;
  background-color: ${(props) => props.theme.color.secondary_main};
  border-radius: 6px;
  outline: none;
  border: none;
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  cursor: pointer;
  transform: scale(0.95);
  margin-bottom: ${(props) => props.marginBottom};

  & svg {
    width: auto;
    height: 100%;
    & path {
      fill: ${(props) => props.theme.color.primary_main};
    }
  }

  &:hover {
    transform: scale(1);
  }
`;

const InputLine = styled.input`
  width: 80px;
  padding: 0.3rem;
  outline: none;
  border: none;
  border-bottom: ${(props) => props.theme.color.gray3} 1px solid;
  color: ${(props) => props.theme.color.gray2};
  text-align: center;
`;

const InputRead = styled.input<{
  isBlock?: boolean;
  maxWidth?: string;
  isReadOnly?: boolean;
}>`
  width: 100%;
  height: 40px;
  padding: 0.3rem;
  margin: 0.3rem;
  outline: none;
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.text3};
  background: ${(props) =>
    props.isReadOnly ? props.theme.color.white3 : props.theme.color.white1};
  border: 1px solid
    ${(props) =>
      props.isReadOnly ? props.theme.color.gray3 : props.theme.color.gray4};
  box-sizing: border-box;
  border-radius: 6px;
  text-align: start;
  max-width: ${(props) => props.maxWidth};
`;

const Title = styled.h4`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray2};
  align-self: flex-start;
  padding: 1rem;
`;

const SubTitle = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.color.gray2};
  padding: 0 1rem;
  align-self: center;
`;

interface Props {
  typeOperation: string;
  changeRender: (value: string) => void;
  data: OperationModel;
  changeData: (value: OperationModel) => void;
  isReadOperation: boolean;
  setIsReadOperation: (value: boolean) => void;
  isEditOperation: boolean;
}

const Main = ({
  typeOperation,
  changeRender,
  data,
  changeData,
  isReadOperation,
  setIsReadOperation,
  isEditOperation,
}: Props): JSX.Element => {
  // const dispatch = useDispatch();
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const [showFinancialInstitutionModal, setShowFinancialInstitutionModal] =
    useState<boolean>(false);
  const [showCurrencyModal, setShowCurrencyModal] = useState<boolean>(false);

  // const getNextWork = (date: string) => {
  //   let newDate = new Date(date);
  //   newDate.setDate(newDate.getDate() + 1); // tomorrow
  //   if (newDate.getDay() === 0) newDate.setDate(newDate.getDate() + 1);
  //   else if (newDate.getDay() === 6) newDate.setDate(newDate.getDate() + 2);
  //   return String(newDate.toLocaleDateString().split("/").reverse().join("-"));
  // };
  // check reais

  // const chooseDateReais = (value: string, sum: string) => {
  //   let date = new Date(value);
  //   date.setDate(date.getDate() + Number(sum) + 1);
  //   changeData({
  //     ...data,
  //     reais_closure: String(
  //       date.toLocaleDateString().split("/").reverse().join("-")
  //     ),
  //   });
  // };

  // useEffect(() => {
  //   if (data.currency_closure && data.reais_days) {
  //     if (data.reais_days !== "") {
  //       chooseDateReais(data.currency_closure, data.reais_days);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.currency_closure, data.reais_days]);

  // useEffect(() => {
  //   if (data.currency_closure && data.reais_days && data.reais_closure) {
  //     // console.log(getNextWork(data.reais_closure));
  //     changeData({
  //       ...data,
  //       reais_closure: getNextWork(data.reais_closure),
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.reais_closure]);

  // // check Bacen

  // const chooseDateBacen = (value: string, sum: string) => {
  //   let date = new Date(value);
  //   date.setDate(date.getDate() + Number(sum) + 1);
  //   changeData({
  //     ...data,
  //     bacen_closure: String(
  //       date.toLocaleDateString().split("/").reverse().join("-")
  //     ),
  //   });
  // };

  // useEffect(() => {
  //   if (data.currency_closure && data.bacen_days) {
  //     if (data.bacen_days !== "") {
  //       chooseDateBacen(data.currency_closure, data.bacen_days);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.currency_closure, data.bacen_days]);

  // useEffect(() => {
  //   if (data.currency_closure && data.bacen_days && data.bacen_closure) {
  //     // console.log(getNextWork(data.bacen_closure));
  //     changeData({
  //       ...data,
  //       bacen_closure: getNextWork(data.bacen_closure),
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.bacen_closure]);

  // useEffect(() => {
  //   if (data.currency_closure && data.bacen_days) {
  //     if (data.bacen_days !== "") {
  //       chooseDateBacen(data.currency_closure, data.bacen_days);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.currency_closure, data.bacen_days]);

  // useEffect(() => {
  //   if (data.bacen_days !== "" && data.bacen_days !== "0") {
  //     let day = new Date(data.bacen_closure!).getUTCDay();
  //     if ([6, 0].includes(day)) {
  //       changeData({
  //         ...data,
  //         currency_closure: "",
  //         bacen_closure: "",
  //         reais_closure: "",
  //         bacen_days: "",
  //         reais_days: "",
  //       });
  //       dispatch(
  //         setGlobalMessage({
  //           message: "Finais de semana não podem ser selecionados",
  //           type: "WARN",
  //         })
  //       );
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.bacen_closure]);

  // useEffect(() => {
  //   if (data.bacen_days !== "" && data.bacen_days !== "0") {
  //     let holidays = [
  //       "1-1",
  //       "2-28",
  //       "3-1",
  //       "3-2",
  //       "4-15",
  //       "4-21",
  //       "5-1",
  //       "6-16",
  //       "7-7",
  //       "10-12",
  //       "10-28",
  //       "11-2",
  //       "11-15",
  //       "12-25",
  //     ];
  //     let date = new Date(data.bacen_closure!);
  //     date.setDate(date.getDate() + 1);
  //     if (
  //       holidays.indexOf(String(date.getMonth() + 1 + "-" + date.getDate())) !==
  //       -1
  //     ) {
  //       changeData({
  //         ...data,
  //         currency_closure: "",
  //         bacen_closure: "",
  //         reais_closure: "",
  //         bacen_days: "",
  //         reais_days: "",
  //       });
  //       dispatch(
  //         setGlobalMessage({
  //           message: "Feriados não podem ser selecionados",
  //           type: "WARN",
  //         })
  //       );
  //     }
  //     // console.log(String(date.getMonth() + 1 + "-" + date.getDate()));
  //     // console.log(
  //     //   holidays.indexOf(String(date.getMonth() + 1 + "-" + date.getDate()))
  //     // );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.bacen_closure]);

  // useEffect(() => {
  //   if (data.currency_closure && data.reais_days) {
  //     if (data.reais_days !== "") {
  //       chooseDateReais(data.currency_closure, data.reais_days);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.currency_closure, data.reais_days]);

  // useEffect(() => {
  //   if (data.reais_days !== "" && data.reais_days !== "0") {
  //     let day = new Date(data.reais_closure!).getUTCDay();
  //     if ([6, 0].includes(day)) {
  //       changeData({
  //         ...data,
  //         currency_closure: "",
  //         bacen_closure: "",
  //         reais_closure: "",
  //         bacen_days: "",
  //         reais_days: "",
  //       });
  //       dispatch(
  //         setGlobalMessage({
  //           message: "Finais de semana não podem ser selecionados",
  //           type: "WARN",
  //         })
  //       );
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.reais_closure]);

  // useEffect(() => {
  //   if (data.reais_days !== "" && data.reais_days !== "0") {
  //     let holidays = [
  //       "1-1",
  //       "2-28",
  //       "3-1",
  //       "3-2",
  //       "4-15",
  //       "4-21",
  //       "5-1",
  //       "6-16",
  //       "7-7",
  //       "10-12",
  //       "10-28",
  //       "11-2",
  //       "11-15",
  //       "12-25",
  //     ];
  //     let date = new Date(data.reais_closure!);
  //     date.setDate(date.getDate() + 1);
  //     if (
  //       holidays.indexOf(String(date.getMonth() + 1 + "-" + date.getDate())) !==
  //       -1
  //     ) {
  //       changeData({
  //         ...data,
  //         currency_closure: "",
  //         bacen_closure: "",
  //         reais_closure: "",
  //         bacen_days: "",
  //         reais_days: "",
  //       });
  //       dispatch(
  //         setGlobalMessage({
  //           message: "Feriados não podem ser selecionados",
  //           type: "WARN",
  //         })
  //       );
  //     }
  //     // console.log(String(date.getMonth() + 1 + "-" + date.getDate()));
  //     // console.log(
  //     //   holidays.indexOf(String(date.getMonth() + 1 + "-" + date.getDate()))
  //     // );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.reais_closure]);

  // useEffect(() => {
  //   let holidays = [
  //     "1-1",
  //     "2-28",
  //     "3-1",
  //     "3-2",
  //     "4-15",
  //     "4-21",
  //     "5-1",
  //     "6-16",
  //     "7-7",
  //     "10-12",
  //     "10-28",
  //     "11-2",
  //     "11-15",
  //     "12-25",
  //   ];
  //   let date = new Date(data.currency_closure!);
  //   date.setDate(date.getDate() + 1);
  //   if (
  //     holidays.indexOf(String(date.getMonth() + 1 + "-" + date.getDate())) !==
  //     -1
  //   ) {
  //     changeData({
  //       ...data,
  //       currency_closure: "",
  //       bacen_closure: "",
  //       reais_closure: "",
  //       bacen_days: "",
  //       reais_days: "",
  //     });
  //     dispatch(
  //       setGlobalMessage({
  //         message: "Feriados não podem ser selecionados",
  //         type: "WARN",
  //       })
  //     );
  //   }
  //   // console.log(String(date.getMonth() + 1 + "-" + date.getDate()));
  //   // console.log(
  //   //   holidays.indexOf(String(date.getMonth() + 1 + "-" + date.getDate()))
  //   // );

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.currency_closure]);

  //substituir
  // const randomNumber = (length: number) => {
  //   let randomNumber;
  //   let number = "";

  //   for (let count = 0; count < length; count++) {
  //     randomNumber = Math.floor(Math.random() * 10);
  //     number += randomNumber.toString();
  //   }
  //   return number;
  // };

  // const randomLetter = (length: number) => {
  //   let result = "";
  //   let characters = "NS";
  //   let charactersLength = characters.length;
  //   for (let count = 0; count < length; count++) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   return result;
  // };

  const checkBacenDate = () => {};

  return (
    <>
      <Container>
        <ItemRowTop>
          <Base>
            <Label>Produto</Label>
            {typeOperation === "Shipping" ? (
              <SimuInput>
                <Shipping />
                <p>Remessa</p>
              </SimuInput>
            ) : null}
            {typeOperation === "Ticket" ? (
              <SimuInput>
                <Ticket />
                <p>Ingresso</p>
              </SimuInput>
            ) : null}
            {typeOperation === "Import" ? (
              <SimuInput>
                <Import />
                <p>Importação</p>
              </SimuInput>
            ) : null}
            {typeOperation === "Export" ? (
              <SimuInput>
                <Export />
                <p>Exportação</p>
              </SimuInput>
            ) : null}
            {isReadOperation !== true && isEditOperation !== true ? (
              <ButtonYellow type="button" onClick={() => changeRender("Types")}>
                <DualArrow />
              </ButtonYellow>
            ) : null}
          </Base>
          <Base>
            <Label>Operação</Label>
            <InputLine
              value={
                typeOperation === "Shipping" || typeOperation === "Import"
                  ? "Venda"
                  : "Compra"
              }
            />
          </Base>
          <Base>
            <Label>Finalidade</Label>
            <InputLine value={"Comercial"} />
          </Base>
          <Base>
            <Label>Natureza da Operação</Label>
            <InputRead
              maxLength={5}
              maxWidth="50px"
              value={data.nature_fact}
              onChange={(event) => {
                changeData({
                  ...data,
                  nature_fact: event.target.value,
                });
              }}
              readOnly={isReadOperation}
              isReadOnly={isReadOperation}
            />
            <InputRead
              maxLength={2}
              maxWidth="30px"
              value={data.nature_client_type}
              onChange={(event) => {
                changeData({
                  ...data,
                  nature_client_type: event.target.value,
                });
              }}
              readOnly={isReadOperation}
              isReadOnly={isReadOperation}
            />
            <InputRead
              maxLength={1}
              maxWidth="25px"
              value={data.nature_aval}
              onChange={(event) => {
                changeData({
                  ...data,
                  nature_aval: event.target.value,
                });
              }}
              readOnly={isReadOperation}
              isReadOnly={isReadOperation}
            />
            <InputRead
              maxLength={2}
              maxWidth="30px"
              value={data.nature_beneficiary}
              onChange={(event) => {
                changeData({
                  ...data,
                  nature_beneficiary: event.target.value,
                });
              }}
              readOnly={isReadOperation}
              isReadOnly={isReadOperation}
            />
            <InputRead
              maxLength={2}
              maxWidth="30px"
              value={data.nature_group}
              onChange={(event) => {
                changeData({
                  ...data,
                  nature_group: event.target.value,
                });
              }}
              readOnly={isReadOperation}
              isReadOnly={isReadOperation}
            />
          </Base>
          <Base>
            <Label>Analista de Câmbio</Label>
            <InputRead maxWidth="50px" maxLength={3} readOnly isReadOnly />
            <InputRead maxWidth="200px" maxLength={120} readOnly isReadOnly />
          </Base>
        </ItemRowTop>
        <BottomLine />
        <ItemRow>
          <Title>Dados para operação</Title>
        </ItemRow>
        <ItemRow>
          <InputNumber
            label="Cód. do cliente"
            maxWidth="130px"
            maxLength={6}
            value={data.user_syscambio}
            changeValue={(value) =>
              changeData({
                ...data,
                user_syscambio: value,
              })
            }
            required
            readOnly
          />
          <InputText
            label="Cliente"
            maxWidth="300px"
            maxLength={120}
            value={data.client_name}
            changeValue={(value) =>
              changeData({
                ...data,
                client_name: value,
              })
            }
            required
            readOnly
          />
          <InputCNPJCPF
            label="CNPJ/CPF"
            maxWidth="200px"
            value={data.client_document}
            changeValue={(value) =>
              changeData({
                ...data,
                client_document: value,
              })
            }
            required
            readOnly
          />
          {isReadOperation !== true ? (
            <ButtonYellow
              type="button"
              marginBottom=".3rem"
              onClick={() => setShowCustomerModal(!showCustomerModal)}
            >
              <BiSearchAlt2 />
            </ButtonYellow>
          ) : null}
        </ItemRow>
        <ItemRow>
          <ModalPickBank
            onChange={(nextBank) => {
              console.log("nextBank", nextBank);
              changeData({
                ...data,
                financial_institution_doc: nextBank.bank_document,
                financial_institution_code: nextBank.bank_code,
                financial_institution: nextBank.bank_info,
              });

              setShowFinancialInstitutionModal(() => false);
            }}
            isOpen={showFinancialInstitutionModal}
            onClose={() => {
              setShowFinancialInstitutionModal(() => false);
            }}
          />
          <InputNumber
            label="Cód. Inst. ficanceira"
            maxWidth="180px"
            maxLength={7}
            value={data.financial_institution_code}
            changeValue={(value) =>
              changeData({
                ...data,
                financial_institution_code: value,
              })
            }
            required
            readOnly
          />
          <InputText
            label="Inst. ficanceira"
            maxWidth="300px"
            maxLength={120}
            value={data.financial_institution}
            changeValue={(value) =>
              changeData({
                ...data,
                financial_institution: value,
              })
            }
            required
            readOnly
          />
          <InputCNPJ
            label="CNPJ"
            maxWidth="300px"
            value={data.financial_institution_doc}
            changeValue={(value) =>
              changeData({
                ...data,
                financial_institution_doc: value,
              })
            }
            required
            readOnly
          />
          {isReadOperation !== true ? (
            <ButtonYellow
              type="button"
              marginBottom=".3rem"
              onClick={() =>
                setShowFinancialInstitutionModal(!showFinancialInstitutionModal)
              }
            >
              <BiSearchAlt2 />
            </ButtonYellow>
          ) : null}
        </ItemRow>
        <ItemRow>
          <SubTitle>Moeda</SubTitle>
          <InputNumber
            label="Cód. Moeda"
            maxWidth="130px"
            maxLength={3}
            value={data.currency_code}
            changeValue={(value) =>
              changeData({
                ...data,
                currency_code: value,
              })
            }
            required
            readOnly
          />
          <InputText
            label="Cód. ISO"
            maxWidth="110px"
            maxLength={3}
            value={data.currency_iso}
            changeValue={(value) =>
              changeData({
                ...data,
                currency_iso: value,
              })
            }
            required
            readOnly
          />
          <InputText
            label="Nome da Moeda"
            maxWidth="200px"
            maxLength={52}
            value={data.currency_name}
            changeValue={(value) =>
              changeData({
                ...data,
                currency_name: value,
              })
            }
            required
            readOnly
          />
          {isReadOperation !== true ? (
            <ButtonYellow
              type="button"
              marginBottom=".3rem"
              onClick={() => setShowCurrencyModal(!showCurrencyModal)}
            >
              <BiSearchAlt2 />
            </ButtonYellow>
          ) : null}
          <InputCurrency
            label="Valor da operação"
            maxWidth="150px"
            maxLength={12}
            value={data.operation_value}
            changeValue={(value) =>
              changeData({
                ...data,
                operation_value: value,
              })
            }
            required
            readOnly={isReadOperation}
          />
        </ItemRow>
        <ItemRow>
          <SubTitle>Moeda Estrangeira</SubTitle>
          <InputDate
            id="date1"
            label="Fechamento"
            maxWidth="180px"
            min={new Date().toISOString().split("T")[0]}
            value={data.currency_closure}
            changeValue={(value) => {
              if (data.bacen_days) {
                data.bacen_closure = addDays(value, Number(data.bacen_days));
              }

              if (data.reais_days) {
                data.reais_closure = addDays(value, Number(data.reais_days));
              }

              changeData({
                ...data,
                currency_closure: value,
              });
            }}
            required
            readOnly={isReadOperation}
          />
          <InputDaysSettlement
            label="Dias para liquidação BACEN"
            maxWidth="250px"
            value={data.bacen_days}
            changeValue={(value) => {
              if (data.currency_closure) {
                data.bacen_closure = addDays(
                  data.currency_closure,
                  Number(value)
                );
              }

              changeData({
                ...data,
                bacen_days: value,
              });
            }}
            required
            readOnly={isReadOperation}
          />
          <InputDate
            label="Data"
            maxWidth="180px"
            min={new Date().toISOString().split("T")[0]}
            value={data.bacen_closure}
            readOnly
            required
          />
          <SubTitle>Reais</SubTitle>
          <InputDaysSettlement
            label="Dias para liquidação dos R$"
            maxWidth="250px"
            value={data.reais_days}
            changeValue={(value) => {
              if (data.currency_closure) {
                data.reais_closure = addDays(
                  data.currency_closure,
                  Number(value)
                );
              }

              changeData({
                ...data,
                reais_days: value,
              });
            }}
            required
            readOnly={isReadOperation}
          />
          <InputDate
            label="Data"
            maxWidth="180px"
            min={new Date().toISOString().split("T")[0]}
            value={data.reais_closure}
            readOnly
            required
          />
        </ItemRow>
      </Container>
      <FindCustomer
        showModal={showCustomerModal}
        handleModal={() => setShowCustomerModal(!showCustomerModal)}
        data={data}
        changeData={changeData}
      />
      {/* <FindFinancialInstitution
        showModal={showFinancialInstitutionModal}
        handleModal={() =>
          setShowFinancialInstitutionModal(!showFinancialInstitutionModal)
        }
        data={data}
        changeData={changeData}
      /> */}
      <FindCurrency
        showModal={showCurrencyModal}
        handleModal={() => setShowCurrencyModal(!showCurrencyModal)}
        data={data}
        changeData={changeData}
      />
    </>
  );
};

export default Main;
