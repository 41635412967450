import { Heading, Tabs, Square } from '@chakra-ui/react'
import React from 'react'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'

interface CardPageProps {
  title: string
  _headerBottom?: React.ReactNode
  _headerRight?: React.ReactNode
  _tabs?: typeof Tabs
}

const CardPage: React.FC<CardPageProps> = ({
  title,
  children,
  _headerBottom,
  _headerRight
}) => {
  return (
    <Col
      h={'100vh'}
      w={'100vw'}
      bg={'transparent'}
      maxW={'100%'}
      maxH={'100%'}
      alignItems={'center'}
      p={0}
    >
      <Col w={'full'} minH={'100%'} maxW={'100%'} py={4} px={4} pt={0}>
        <Col
          w={'100%'}
          minH={'100%'}
          bg={'white'}
          rounded={'lg'}
          shadow={'xl'}
          pos={'relative'}
          border={'1px solid transparent'}
          borderColor={'gray.300'}
        >
          <Col minH={16} roundedTop={'lg'}>
            <Row alignItems={'center'} flex={1} px={4} minH={10}>
              <Square rounded={'lg'} size={5} bg={'primary.500'} mr={2} />
              <Heading size={'md'} flex={1}>
                {title}
              </Heading>
              {_headerRight}
            </Row>
            {_headerBottom && <Row px={2}>{_headerBottom}</Row>}
          </Col>
          <Col flex={1} pos={'relative'} w={'100%'}>
            <div
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: '100%',
                height: '98%',
                overflow: 'auto'
              }}
            >
              {children}
            </div>
          </Col>
        </Col>
      </Col>
    </Col>
  )
}
/*


*/
export default CardPage
