import { devices } from "helpers/devices";
import styled from "styled-components";
import LogoDigitaly from "assets/LogoDigitaly.png";
import GenericButton from "components/generic/GenericButton";
import Logo1 from "assets/Logo 1.png";
import { ReactComponent as LocationIcon } from "assets/locationIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/phoneIcon.svg";
import { ReactComponent as WhatsAppIcon } from "assets/whatsAppIcon.svg";
import { Button, Link } from "@chakra-ui/react";

const FooterStyled = styled.footer`
  background-color: ${props => props.theme.color.primary_dark};
  height: 100vh;
  min-height: 250px;
  max-height: 250px;
  width: 100vw;
  max-width: 100%;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media ${devices.phoneOnly} {
    padding: 0.3rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${props => props.theme.margins.from_sides};
  }
`;

const FooterDigitaly = styled.div`
  padding: 0.5rem ${props => props.theme.margins.from_sides};
  background: ${props => props.theme.color.gray2};
  display: flex;
  justify-content: center;

  & a {
    text-decoration: none;
  }

  & p {
    text-align: center;
    color: ${props => props.theme.color.white1};
    font-size: ${props => props.theme.font.small};
  }

  & img {
    height: 30px;
    width: auto;
    margin-left: 0.5rem;
  }

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
    & p {
      font-size: 0.6rem;
    }

    & img {
      height: 20px;
    }
  }
`;

const DivButtons = styled.div`
  width: 400px;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${props => props.theme.color.white1};
`;

const Label = styled.label`
  margin: 1rem 0;
  font-weight: bold;
  font-size: 1rem;
  line-height: 23px;
  color: ${props => props.theme.color.white1};
`;

// const Button = styled(GenericButton)`
//   width: 300px;
// `;

const DivAll = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
`;

const DivUpper = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${props => props.theme.color.white1};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ImgContainer = styled.div`
  height: 60px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 100%;
  width: auto;
`;

const DivBottom = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  & p {
    text-decoration: underline;
    color: ${props => props.theme.color.white1};
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 19px;
  }
`;

const ContactContainer = styled.div`
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Contact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0;

  & svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin: 0 0.5rem;
  }

  & p {
    margin: 0 0.5rem;
    color: ${props => props.theme.color.white1};
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 19px;
  }
`;

const Footer = (): JSX.Element => {
  return (
    <>
      <FooterStyled id="6">
        <Container>
          <DivAll>
            <DivUpper>
              <ImgContainer>
                <Logo src={Logo1} alt="Logotipo da Dascam" />
              </ImgContainer>
              <ContactContainer>
                <Contact>
                  <LocationIcon />
                  <p>Rua Líbero Badaró, 425 - 29º Andar - São Paulo/SP</p>
                </Contact>
                <Contact>
                  <PhoneIcon />
                  <p>11 3112 - 9292</p>
                </Contact>
                <Contact>
                  <WhatsAppIcon />
                  <p>11 99277 - 4756</p>
                </Contact>
              </ContactContainer>
            </DivUpper>
            <DivBottom>
              <Link href="https://dascam.s3.amazonaws.com/seguranca_cibernetica.pdf">
              <p>Política de Segurança Cibernética</p>
              </Link>
              <Link href="https://www.dascam.com.br/servi%C3%A7os#demonstrativos_financeiros">
              <p>Demonstrativos Finaceiros</p>
              </Link>
            </DivBottom>
          </DivAll>
          <DivButtons>
            <Label>Gostaria de trabalhar com a gente</Label>
            <Link href="https://www.dascam.com.br/formul%C3%A1rio">
            <Button
              w={150}
              variant={"outline"}
              color={"white"}
              colorScheme={"lighten"}
            >Enviar Curriculum</Button>
            </Link>
            <Label>Está com problemas?</Label>
            <Link href="https://www.dascam.com.br/">
            <Button
              w={150}
              variant={"outline"}
              color={"white"}
              colorScheme={"lighten"}
            >Ouvidoria
            </Button>
            </Link>
          </DivButtons>
        </Container>
      </FooterStyled>
      <FooterDigitaly>
        <p>© 2023 | Dascam - Corretora de Câmbio Ltda | Desenvolvido por </p>
        <a
          href="https://digitaly.tech"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LogoDigitaly} alt="Logo Tipo da Digitaly" />
        </a>
      </FooterDigitaly>
    </>
  );
};

export default Footer;
