export const userRequired = [
  "user_name",
  "document",
  "birthdate",
  "email",
  "general_registry",
  "issuing_agency",
  "agency_state",
  "rg_issue",
  "father_name",
  "mother_name",
  "contact_cellphone",
  "contact_phone",
  "nationality",
  "nationality_country",
  "marital_status",
  "country",
  "address_zipcode",
  "address_city",
  "address_state",
  "address_district",
  "address_place",
  "address_number",
];

export const foreignRequired = [
  "user_name",
  "document",
  "birthdate",
  "email",
  "father_name",
  "mother_name",
  "contact_cellphone",
  "contact_phone",
  "nationality",
  "nationality_country",
  "marital_status",
  "country",
  "address_zipcode",
  "address_city",
  "address_state",
  "address_district",
  "address_place",
  "address_number",
];

export const enterpriseRequired = [
  "user_name",
  "fantasy_name",
  "document",
  "opening_date",
  "municipalregistration_ccm",
  "stateregistration",
  "legal_nature",
  "entity",
  "website",
  "main_activity",
  "net_worth",
  "average_billing",
  "contact_name",
  "contact_role",
  "contact_branch",
  "contact_cellphone",
  "contact_phone",
  "email",
  "country",
  "address_zipcode",
  "address_city",
  "address_state",
  "address_district",
  "address_place",
  "address_number",
];

export const userDocRequired = ["income_tax", "rg_cpf_doc", "residence_proof"];

export const enterpriseDocRequired = [
  "rg_cpf_doc",
  "residence_proof",
  "constitutive_act",
  "business_residence",
  "monthly_billing",
  "identification_beneficiaries",
];

export const userThatReturn = ["attorney_letter"];
export const enterpriseThatReturn = [
  "rg_cpf_doc",
  "identification_beneficiaries",
];
