import { devices } from "helpers/devices";
import styled, { keyframes } from "styled-components";

const OptionItem = styled.div<{ readOnly: boolean }>`
  padding: 0.1rem;
  margin: 0.3rem 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: ${props => (props.readOnly ? "default" : "pointer")};
`;

const DivText = styled.div`
  margin: 0 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const TextItem = styled.p<{ isBold?: boolean; readOnly: boolean }>`
  color: ${props =>
    props.readOnly ? props.theme.color.gray3 : props.theme.color.gray1};
  font-size: 0.8rem;
  font-weight: ${props => (props.isBold ? "bold" : "none")};
  white-space: nowrap;

  @media ${devices.desktop} {
    font-size: 0.7rem;
  }

  @media ${devices.bigDesktop} {
    font-size: 0.8rem;
  }
`;

const Fade = keyframes`
    0% {
        transform: scaleX(.3);
        opacity: 0;
    }
    70% {
        transform: scaleX(1.1);
        opacity: 1;
    }
    100% {
        transform: scaleX(1);
    }
`;

const Box = styled.div<{ readOnly: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${props =>
    props.readOnly ? props.theme.color.gray3 : props.theme.color.white3};
  box-shadow: 0px 0px 2px ${props => props.theme.color.gray4};
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;

  @media ${devices.desktop} {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }

  @media ${devices.bigDesktop} {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }
`;

const Checked = styled.div`
  animation: ${Fade} 1 200ms ease-out;
  background: ${props => props.theme.color.primary_light};
  width: 12px;
  height: 12px;
  border-radius: 3px;

  @media ${devices.desktop} {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
  }

  @media ${devices.bigDesktop} {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
  }
`;

interface Props {
  title: string;
  activity?: string;
  selected: boolean;
  changeSelected: (value: boolean) => void;
  readOnly?: boolean;
}

const CheckBoxPermission = ({
  title,
  activity,
  selected,
  changeSelected,
  readOnly,
}: Props): JSX.Element => {
  return (
    <OptionItem
      readOnly={readOnly ? true : false}
      onClick={() => {
        if (!readOnly) {
          changeSelected(!selected);
        }
      }}
    >
      <Box readOnly={readOnly ? true : false}>
        {selected === true ? <Checked /> : null}
      </Box>
      <DivText>
        <TextItem readOnly={readOnly ? true : false} isBold>
          {title}
        </TextItem>
        {/* <TextItem readOnly={readOnly ? true : false}>
                    {activity}
                </TextItem> */}
      </DivText>
    </OptionItem>
  );
};

export default CheckBoxPermission;
