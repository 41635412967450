import { GlobalMessageTypes } from "interfaces/GlobalMessage";
import { useDispatch, useSelector } from "react-redux";
import { clearGlobalMessage } from "redux/actions";
import { GlobalState } from "redux/types";
import styled from "styled-components";
import GenericButton from "./GenericButton";

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.color.transparent_opaque};
  z-index: 80000;
`;

const Card = styled.div`
  padding: 1rem;
  width: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px ${props => props.theme.color.gray4};
  border-radius: 30px;
  background: ${props => props.theme.color.white1};
`;

const Container = styled.div<{ type: GlobalMessageTypes }>`
  display: flex;
  flex-direction: column;

  & button {
    background: ${props =>
      props.type === "ERROR"
        ? "#ff6e6e"
        : props.type === "WARN"
        ? "#eecb1d"
        : null};
  }

  & button:hover {
    background: ${props =>
      props.type === "ERROR"
        ? "#f88484"
        : props.type === "WARN"
        ? "#ffd500"
        : null};
  }
`;

const MessageContainer = styled.div`
  padding: 1.2rem;
  margin-bottom: 1rem;
  max-width: 300px;
  text-align: center;
`;

const Button = styled(GenericButton)`
  color: ${props => props.theme.color.white1};
`;

const GlobalMessage = (): JSX.Element | null => {
  const globalMessage = useSelector(
    (state: GlobalState) => state.globalMessage
  );

  const dispatch = useDispatch();

  if (globalMessage !== undefined) {
    return (
      <Background>
        <Card>
          <Container
            type={globalMessage.disabledType ? "MESSAGE" : globalMessage.type}
          >
            <MessageContainer>{globalMessage.message}</MessageContainer>
            {globalMessage.buttons ? (
              globalMessage.buttons
            ) : (
              <Button onClick={() => dispatch(clearGlobalMessage())}>Ok</Button>
            )}
          </Container>
        </Card>
      </Background>
    );
  } else {
    return null;
  }
};

export default GlobalMessage;
