import {
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuGroup,
  MenuItem,
  MenuItemProps,
  MenuList
} from '@chakra-ui/react'
import React from 'react'
import { Col } from '../Col'
import { OptionalLink } from '../OptionalLink'

interface SimpleMenuProps extends MenuButtonProps {
  groups: Array<{
    title: string
    items: Array<SimpleMenuItem>
  }>
}

export interface SimpleMenuItem extends MenuItemProps {
  label: (props: { groupIndex: number }) => React.ReactNode
  to?: string
  isHidden?: boolean
}

export const SimpleMenu: React.NextFC<SimpleMenuProps> = ({
  groups,
  ...rest
}) => {
  return (
    <>
      <Menu matchWidth>
        <MenuButton {...rest} />
        <MenuList
          zIndex={9999999999}
          border={1}
          borderStyle={'solid'}
          rounded={'md'}
          shadow={'xl'}
          borderColor={'gray.200'}
          _dark={{ borderColor: 'gray.600' }}
        >
          {groups.map((g, groupIndex) => (
            <MenuGroup key={`group${groupIndex}`} title={g.title}>
              {g.items
                .filter((i) => !i.isHidden)
                .map(({ label, to, ...restItem }, itemIndex) => (
                  <OptionalLink key={`item${itemIndex}${groupIndex}`} to={to}>
                    <Col px={4}>
                      <MenuItem rounded={'sm'} {...restItem}>
                        {label({ groupIndex })}
                      </MenuItem>
                    </Col>
                  </OptionalLink>
                ))}
            </MenuGroup>
          ))}
        </MenuList>
      </Menu>
    </>
  )
}
