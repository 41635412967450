export interface OperationExcelModel {
  id: number;
  user_id: number;
  client_name: string;
  user_syscambio?: number;
  product_type?: ExcelTypeEnum;
  client_excel_name?: string;
  client_excel_type?: string;
  client_doc?: string;
  client_excel?: string;
  attachment_name?: string;
  attachment_type?: string;
  attachment?: string;
  client_observation?: string;
  dascam_excel_name?: string;
  dascam_excel?: string;
  dascam_excel_type?: string;
  dascam_observation?: string;
  status?: ExcelStatusEnum;
  created_date?: Date;
  created_by_id?: number;
  updated_date?: Date;
  updated_by_id?: number;
}

export enum ExcelTypeEnum {
  IMPORTACAO = "IMPORTACAO",
  EXPORTACAO = "EXPORTACAO",
  INGRESSO = "INGRESSO",
  REMESSA = "REMESSA",
}

export enum ExcelStatusEnum {
  SENT = "SENT", // Cliente = Enviado; Dascam = Em Analise
  PENDING = "PENDING", // Cliente = Em Rascunho
  APPROVAL = "APPROVAL", // Cliente + Dascam = Aguardando Aprovação do Cliente
  CANCELED = "CANCELED", // Cancelado/Desaprovado pelo Cliente
  RELEASE = "RELEASE", // Aprovado/Liberado pelo Cliente
  CORRECTION = "CORRECTION", // Voltou Ao Cliente para Correção

}

// 1 - PENDING
// 2 - approved ou correction
// 3 - release

export namespace ExcelStatusEnum {
  export function format(value: ExcelStatusEnum): string {
    switch (value) {
      case ExcelStatusEnum.APPROVAL:
        return "Aprovado";
      case ExcelStatusEnum.CANCELED:
        return "Cancelado";
      case ExcelStatusEnum.CORRECTION:
        return "Em correção";
      case ExcelStatusEnum.PENDING:
        return "Rascunho";
      case ExcelStatusEnum.RELEASE:
        return "Liberado";
      case ExcelStatusEnum.SENT:
        return "Em análise";
      default:
        return "Desconhecido";
    }
  }
}