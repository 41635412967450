export class ExcelExport {
  public static async fromJSON(fileName: string, items: string[][]) {
    let csv = "";
    items.forEach((row, rowIndex) => {
      row.forEach(col => {
        if (rowIndex <= 0) {
          const nextColValue = col
            .split(" ")
            .map(v => v.normalize("NFKD").replace(/[^\w]/g, ""))
            .join(" ");

          csv += `${nextColValue};`;
        } else {
          csv += `${col};`;
        }
      });
      csv += "\r\n";
    });

    const blob = new Blob([csv], { type: "application/csv" });
    const url = URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${fileName}.csv`;
    anchor.click();
    anchor.remove();
  }
}
