import chunk from "lodash.chunk";
import { ExcelExport } from "./ExcelExport";

export class GridExport {
  public async export(element: HTMLElement, fileName: string = "filename") {
    const values: string[] = Array.from(
      element.querySelectorAll("div") || []
    ).map(el => el.innerText);
    const computedStyle = window.getComputedStyle(element);
    const columnsCount = computedStyle
      .getPropertyValue("grid-template-columns")
      .split(" ").length;
    const items = chunk(values, columnsCount);

    await ExcelExport.fromJSON(fileName, items);
  }
}
