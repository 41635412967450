import styled from "styled-components";
import GenericButton from "components/generic/GenericButton";
import { Fragment, useState } from "react";
import { ReactComponent as IconOpenEye } from "assets/IconOpenEye.svg";
// import { ReactComponent as EditDataIcon } from "assets/editDataIcon.svg";
// import { ReactComponent as TrashIcon } from "assets/trashIcon.svg";
import { ReactComponent as AcceptIcon } from "assets/acceptIcon.svg";
import { ReactComponent as HistoryIcon } from "assets/historyIcon.svg";
import { valueConvert, valueReconvert } from "helpers/moedas";
import { formatDate } from "helpers/formats";
import {
  OperationModel,
  OperationStatusEnum,
  OperationTypeEnum,
  ProductTypeEnum,
} from "interfaces/operation";
import Historic from "components/generic/Historic";
import ApprovalOperation from "./ApprovalOperation";

const ColumnText = styled.div<{ background?: boolean }>`
  border-right: 1px solid ${props => props.theme.color.white5};
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.white5};
  padding: 0 0.5rem;
  background: ${props =>
    props.background ? props.theme.color.white1 : props.theme.color.white2};
`;

const Text = styled.p<{ Color?: string; fontWeight?: string }>`
  font-size: ${props => props.theme.font.small};
  color: ${props => (props.Color ? props.Color : props.theme.color.text3)};
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;
`;

const DivButton = styled.div<{ borderRadius?: boolean; background?: boolean }>`
  border-right: 1px solid ${props => props.theme.color.white5};
  background: ${props =>
    props.background
      ? props.theme.color.primary_main
      : props.theme.color.primary_light};
  border-bottom: 1px solid ${props => props.theme.color.white5};
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: ${props => (props.borderRadius ? "0 0 10px 0" : "")};
`;

const ButtonEdit = styled(GenericButton)`
  width: 30px;
  height: 30px;
  padding: 0.3rem;
  background: ${props => props.theme.color.secondary_main};
  justify-content: center;
  align-items: center;
  transform: scale(0.95);
  margin: 0 0.1rem;

  &:hover {
    background: ${props => props.theme.color.secondary_light};
    transform: scale(1);
  }

  & svg {
    width: 100%;
    height: 100%;
    /* fill: ${props => props.theme.color.primary_main}; */
  }
`;

const ButtonAccept = styled(GenericButton)`
  width: 30px;
  height: 30px;
  padding: 0.3rem;
  background: ${props => props.theme.color.button_normal};
  justify-content: center;
  align-items: center;
  transform: scale(0.95);
  margin: 0 0.1rem;

  &:hover {
    background: ${props => props.theme.color.button_normal_hover};
    transform: scale(1);
  }

  & svg {
    width: 100%;
    height: 100%;
    & path {
      fill: ${props => props.theme.color.white1};
    }
  }
`;

interface Props {
  last: boolean;
  index: number;
  operation: OperationModel;
  changeRender: (value: string) => void;
  isReadOperation: boolean;
  setIsReadOperation: (value: boolean) => void;
  setOperationId: (value: number) => void;
  handleDeleteItem: (id: number) => void;
  getOperationData: () => void;
}

const Operation = ({
  last,
  index,
  operation,
  changeRender,
  isReadOperation,
  setIsReadOperation,
  setOperationId,
  handleDeleteItem,
  getOperationData,
}: Props): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showApproval, setShowApproval] = useState<boolean>(false);

  return (
    <Fragment key={1}>
      <ColumnText background={isEven(index)}>
        <Text
          title={
            operation.product_type === ("REMESSA" as ProductTypeEnum)
              ? "Remessa"
              : operation.product_type === ("INGRESSO" as ProductTypeEnum)
              ? "Ingresso"
              : operation.product_type === ("EXPORTACAO" as ProductTypeEnum)
              ? "Exportação"
              : operation.product_type === ("IMPORTACAO" as ProductTypeEnum)
              ? "Importação"
              : ""
          }
        >
          {operation.product_type === ("REMESSA" as ProductTypeEnum)
            ? "Remessa"
            : operation.product_type === ("INGRESSO" as ProductTypeEnum)
            ? "Ingresso"
            : operation.product_type === ("EXPORTACAO" as ProductTypeEnum)
            ? "Exportação"
            : operation.product_type === ("IMPORTACAO" as ProductTypeEnum)
            ? "Importação"
            : ""}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text
          title={
            operation.operation_type === ("VENDA" as OperationTypeEnum)
              ? "Venda"
              : "Compra"
          }
        >
          {operation.operation_type === ("VENDA" as OperationTypeEnum)
            ? "Venda"
            : "Compra"}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={operation.client_name}>{operation.client_name}</Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={"9999999"}>{"9999999"}</Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text
          title={
            operation.currency_closure !== "" &&
            operation.currency_closure !== undefined &&
            operation.currency_closure !== null
              ? formatDate(operation.currency_closure)
              : ""
          }
        >
          {operation.currency_closure !== "" &&
          operation.currency_closure !== undefined &&
          operation.currency_closure !== null
            ? formatDate(operation.currency_closure)
            : ""}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text
          title={
            operation.bacen_closure !== "" &&
            operation.bacen_closure !== undefined &&
            operation.bacen_closure !== null
              ? formatDate(operation.bacen_closure)
              : ""
          }
        >
          {operation.bacen_closure !== "" &&
          operation.bacen_closure !== undefined &&
          operation.bacen_closure !== null
            ? formatDate(operation.bacen_closure)
            : ""}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={operation.currency_name}>
          {operation.currency_code + " - " + operation.currency_name}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text
          title={valueConvert(
            Number(valueReconvert(String(operation.operation_value), "ANY")),
            "ANY"
          )}
        >
          {valueConvert(
            Number(valueReconvert(String(operation.operation_value), "ANY")),
            "ANY"
          )}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={operation.beneficiary_name}>
          {operation.beneficiary_name}
        </Text>
      </ColumnText>
      <DivButton borderRadius={last} background={isEven(index)}>
        <ButtonEdit
          type="button"
          onClick={() => {
            setIsReadOperation(true);
            setOperationId(Number(operation.id));
            switch (operation.product_type) {
              case "REMESSA":
                changeRender("Shipping");
                break;
              case "INGRESSO":
                changeRender("Ticket");
                break;
              case "EXPORTACAO":
                changeRender("Export");
                break;
              case "IMPORTACAO":
                changeRender("Import");
                break;
              default:
                break;
            }
          }}
        >
          <IconOpenEye />
        </ButtonEdit>
        <ButtonEdit type="button" onClick={() => setShowModal(!showModal)}>
          <HistoryIcon />
        </ButtonEdit>
        {operation.status === ("APPROVAL" as OperationStatusEnum) ? (
          <ButtonAccept
            type="button"
            onClick={() => setShowApproval(!showApproval)}
          >
            <AcceptIcon />
          </ButtonAccept>
        ) : null}
      </DivButton>
      <Historic
        showModal={showModal}
        handleModal={() => setShowModal(!showModal)}
        operation={operation}
      />
      <ApprovalOperation
        showModal={showApproval}
        handleModal={() => setShowApproval(!showApproval)}
        operation={operation}
        changeRender={changeRender}
        getOperationData={getOperationData}
      />
    </Fragment>
  );
};

export default Operation;
