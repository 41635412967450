import styled from "styled-components";
import Modal from "components/generic/Modal";
import { useEffect, useState } from "react";
import {
  InputCurrency,
  InputDate,
  InputNumber,
  InputNumberDI,
  InputSelect,
  InputText,
} from "components/generic/GenericInputs";
import { OperationDocumentModel } from "interfaces/operation_document";
import AttachFile from "components/generic/AttachFileToBase64";
import { valueConvert, valueReconvert } from "helpers/moedas";
import { SetModel } from "interfaces/set_model";
import { formatGame } from "helpers/formats";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MinHeader = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
`;

const MinContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
`;

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: auto;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`;

type Tabs = "Game" | "Money-Order" | "Invoice" | "Knowledge";

interface Props {
  showModal: boolean;
  handleModal: () => void;
  gameSetList: SetModel[];
  typeOperation: string;
  lastGame: number;
  setCurrentTab: (value: Tabs) => void;
  readValue: OperationDocumentModel;
  operationList?: OperationDocumentModel[];
}
const ReadOperation = ({
  showModal,
  handleModal,
  gameSetList,
  typeOperation,
  lastGame,
  setCurrentTab,
  readValue,
  operationList,
}: Props): JSX.Element => {
  const [valueList, setValueList] = useState<OperationDocumentModel[]>([]);
  const [invoiceList, setInvoiceList] = useState<OperationDocumentModel[]>([]);
  const [orderList, setOrderList] = useState<OperationDocumentModel[]>([]);
  const [knowList, setKnowList] = useState<OperationDocumentModel[]>([]);
  const [gameData, setGameData] = useState<SetModel>({});
  const [gameValue, setGameValue] = useState<OperationDocumentModel>({});
  const [newApplied, setNewApplied] = useState<number>(0);

  const calculationTotalApplied = (data: OperationDocumentModel[]) => {
    const newPrice = data
      .filter(
        e =>
          e.applied_value !== undefined &&
          e.document_type !== "CONHECIMENTO" &&
          e.document_type !== "FATURA" &&
          e.document_type !== "ORDEM DE PAGAMENTO"
      )
      .map(i => Number(valueReconvert(String(i.applied_value), "ANY")));

    // console.log(newPrice);
    let priceAll = 0;
    for (let x = 0; x < newPrice.length; x++) {
      priceAll += newPrice[x];
    }
    // console.log(priceAll);
    setNewApplied(priceAll);
  };

  const closeModal = () => {
    setGameData({});
    setValueList([]);
    setOrderList([]);
    setKnowList([]);
    setInvoiceList([]);
    setNewApplied(0);
    setCurrentTab("Game");
    handleModal();
    // console.log(gameData, gameSetList);
  };

  const calculationDoc = () => {
    let regex =
      /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)(\.[0-9]{1,2})?$/;
    let priceAll = 0;
    if (regex.test(String(gameValue.total_value))) {
      // console.log("TIPO: Numerico? " + gameValue.total_value);
      priceAll += Number(gameValue.total_value);
    } else {
      // console.log("TIPO: String? " + gameValue.total_value);
      priceAll += Number(valueReconvert(String(gameValue.total_value), "ANY"));
    }
    if (regex.test(String(gameValue.applied_value))) {
      // console.log("TIPO: Numerico? " + gameValue.applied_value);
      priceAll -= Number(gameValue.applied_value);
    } else {
      // console.log("TIPO: String? " + gameValue.applied_value);
      priceAll -= Number(
        valueReconvert(String(gameValue.applied_value), "ANY")
      );
    }
    // console.log(priceAll);
    setGameValue({
      ...gameValue,
      balance_avaliable: valueConvert(priceAll, "ANY"),
    });
  };

  useEffect(() => {
    calculationDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameValue.applied_value, gameValue.total_value]);

  useEffect(() => {
    if (showModal === true) {
      // console.log(lastGame);
      setGameData({
        ...gameData,
        set_number: lastGame + 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    setGameData({
      ...gameData,
      set_value: valueConvert(newApplied, "ANY"),
      operation_documents: [
        ...valueList,
        ...orderList,
        ...knowList,
        ...invoiceList,
      ],
    });
    // console.log(gameData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueList, orderList, knowList, invoiceList]);

  useEffect(() => {
    setGameData({
      ...gameData,
      set_value: valueConvert(newApplied, "ANY"),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newApplied]);

  useEffect(() => {
    calculationTotalApplied(valueList);
    // console.log(operationList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueList]);

  useEffect(() => {
    if (showModal) {
      if (readValue) {
        setGameValue(readValue);
        setGameData(
          gameSetList.filter(e => e.set_number === readValue.set_number)[0]
        );
        if (operationList) {
          setValueList(
            operationList.filter(
              e =>
                e.document_type !== "ORDEM DE PAGAMENTO" &&
                e.document_type !== "CONHECIMENTO" &&
                e.document_type !== "FATURA" &&
                e.set_number === readValue.set_number
            )
          );
          setOrderList(
            operationList.filter(
              e =>
                e.document_type === "ORDEM DE PAGAMENTO" &&
                e.set_number === readValue.set_number
            )
          );
          setKnowList(
            operationList.filter(
              e =>
                e.document_type === "CONHECIMENTO" &&
                e.set_number === readValue.set_number
            )
          );
          setInvoiceList(
            operationList.filter(
              e =>
                e.document_type === "FATURA" &&
                e.set_number === readValue.set_number
            )
          );
        }
      }
    }
    // console.log(gameValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <Modal
      title={
        typeOperation === "Shipping"
          ? "Visualizar documento - Remessa"
          : typeOperation === "Ticket"
          ? "Visualizar documento - Ingresso"
          : typeOperation === "Import"
          ? "Visualizar documento - Importação"
          : typeOperation === "Export"
          ? "Visualizar documento - Exportação"
          : ""
      }
      showModal={showModal}
      close={() => closeModal()}
      backFocus
      width="100%"
      maxWidth="950px"
      height="100%"
      maxHeight="85vh"
    >
      <Container>
        <MinHeader>
          <ItemRowTop>
            <InputText
              label="Jogo"
              maxWidth="170px"
              value={formatGame(Number(gameData.set_number))}
              changeValue={value =>
                setGameData({
                  ...gameData,
                  set_number: Number(value),
                })
              }
              readOnly
            />
            <InputText
              label="Referência"
              maxWidth="350px"
              value={gameData.set_reference}
              changeValue={value =>
                setGameData({
                  ...gameData,
                  set_reference: value,
                })
              }
              readOnly
            />
            <InputCurrency
              label="Valor da Aplicação do jogo"
              maxWidth="400px"
              value={gameData.set_value}
              changeValue={value =>
                setGameData({
                  ...gameData,
                  set_value: String(newApplied),
                })
              }
              readOnly
            />
          </ItemRowTop>
        </MinHeader>
        <MinContainer>
          {gameValue.document_type !== "ORDEM DE PAGAMENTO" &&
          gameValue.document_type !== "CONHECIMENTO" &&
          gameValue.document_type !== "FATURA" ? (
            <>
              <ItemRow>
                <InputSelect
                  label="Tipo de Documento"
                  maxWidth="250px"
                  value={gameValue.document_type}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      document_type: value,
                    })
                  }
                  required
                  readOnly
                >
                  <option value="" disabled selected>
                    {" "}
                  </option>
                  {typeOperation === "Shipping" ||
                  typeOperation === "Export" ? (
                    <>
                      <option value="DUE">DUE</option>
                      <option value="APÓLICE">APÓLICE</option>
                      <option value="NOTA DE DÉBITO">NOTA DE DÉBITO</option>
                      <option value="NOTA DE CRÉDITO">NOTA DE CRÉDITO</option>
                    </>
                  ) : (
                    <>
                      <option value="DI">DI</option>
                      <option value="DUIMP">DUIMP</option>
                      <option value="DSI">DSI</option>
                      <option value="LI">LI</option>
                      {/* <option value="DRI">DRI</option> */}
                    </>
                  )}
                </InputSelect>

                {gameValue.document_type === "DUE" ||
                gameValue.document_type === "APÓLICE" ||
                gameValue.document_type === "NOTA DE DÉBITO" ||
                gameValue.document_type === "NOTA DE CRÉDITO" ||
                gameValue.document_type === "DUIMP" ||
                gameValue.document_type === "DSI" ? (
                  <InputNumber
                    label="Número"
                    maxWidth="250px"
                    value={gameValue.document_number}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        document_number: value,
                      })
                    }
                    required
                    readOnly
                  />
                ) : null}
                {gameValue.document_type === "DI" ||
                gameValue.document_type === "LI" ? (
                  <InputNumberDI
                    label="Número"
                    maxWidth="250px"
                    value={gameValue.document_number}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        document_number: value,
                      })
                    }
                    required
                    readOnly
                  />
                ) : null}
              </ItemRow>
              <ItemRow>
                {gameValue.document_type === "DUE" ||
                gameValue.document_type === "DUIMP" ? (
                  <InputText
                    label="Chave"
                    maxWidth="250px"
                    value={gameValue.document_key}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        document_key: value,
                      })
                    }
                    required
                    readOnly
                  />
                ) : null}
                {gameValue.document_type === "DUE" ||
                gameValue.document_type === "APÓLICE" ||
                gameValue.document_type === "NOTA DE DÉBITO" ||
                gameValue.document_type === "NOTA DE CRÉDITO" ||
                gameValue.document_type === "DI" ||
                gameValue.document_type === "DUIMP" ||
                gameValue.document_type === "DSI" ||
                gameValue.document_type === "LI" ? (
                  <InputText
                    label="Referência"
                    maxWidth="250px"
                    value={gameValue.document_reference}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        document_reference: value,
                      })
                    }
                    required
                    readOnly
                  />
                ) : null}
                {gameValue.document_type === "APÓLICE" ? (
                  <InputDate
                    label="Emissão"
                    maxWidth="250px"
                    value={gameValue.document_issue}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        document_issue: value,
                      })
                    }
                    required
                    readOnly
                  />
                ) : null}
                {gameValue.document_type === "DI" ? (
                  <InputText
                    label="Adições"
                    maxWidth="250px"
                    value={gameValue.document_additions}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        document_additions: value,
                      })
                    }
                    required
                    readOnly
                  />
                ) : null}
              </ItemRow>
              <ItemRow>
                {gameValue.document_type === "DUE" ||
                gameValue.document_type === "DI" ||
                gameValue.document_type === "DUIMP" ||
                gameValue.document_type === "DSI" ||
                gameValue.document_type === "LI" ? (
                  <InputCurrency
                    label="Valor Aplicado"
                    maxWidth="200px"
                    value={gameValue.applied_value}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        applied_value: value,
                      })
                    }
                    required
                    readOnly
                  />
                ) : null}
                {gameValue.document_type === "DUE" ||
                gameValue.document_type === "DI" ||
                gameValue.document_type === "DUIMP" ||
                gameValue.document_type === "DSI" ||
                gameValue.document_type === "LI" ? (
                  <InputCurrency
                    label="Valor Total"
                    maxWidth="200px"
                    value={gameValue.total_value}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        total_value: value,
                      })
                    }
                    required
                    readOnly
                  />
                ) : null}
                {gameValue.document_type === "DUE" ||
                gameValue.document_type === "DI" ||
                gameValue.document_type === "DUIMP" ||
                gameValue.document_type === "DSI" ||
                gameValue.document_type === "LI" ? (
                  <InputCurrency
                    label="Saldo"
                    maxWidth="200px"
                    value={gameValue.balance_avaliable}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        balance_avaliable: value,
                      })
                    }
                    readOnly
                  />
                ) : null}
              </ItemRow>
              <ItemRow>
                {gameValue.document_type === "APÓLICE" ? (
                  <InputText
                    label="Companhia"
                    maxWidth="200px"
                    value={gameValue.document_company}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        document_company: value,
                      })
                    }
                    required
                    readOnly
                  />
                ) : null}
                {gameValue.document_type === "APÓLICE" ? (
                  <InputText
                    label="Endosso"
                    maxWidth="450px"
                    value={gameValue.document_endorsement}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        document_endorsement: value,
                      })
                    }
                    required
                    readOnly
                  />
                ) : null}
              </ItemRow>
              <ItemRow>
                {gameValue.document_type === "DUE" ||
                gameValue.document_type === "APÓLICE" ||
                gameValue.document_type === "NOTA DE DÉBITO" ||
                gameValue.document_type === "NOTA DE CRÉDITO" ||
                gameValue.document_type === "DI" ||
                gameValue.document_type === "DUIMP" ||
                gameValue.document_type === "DSI" ||
                gameValue.document_type === "LI" ? (
                  <AttachFile
                    newId="game"
                    title=""
                    value={gameValue.attached_file!}
                    changeValue={value =>
                      setGameValue({
                        ...gameValue,
                        attached_file: value,
                      })
                    }
                    readOnly
                  />
                ) : null}
              </ItemRow>
            </>
          ) : null}
          {gameValue.document_type === "ORDEM DE PAGAMENTO" ? (
            <>
              <ItemRow>
                <InputText
                  label="Ordem"
                  maxWidth="200px"
                  value={gameValue.document_number}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      document_number: value,
                    })
                  }
                  required
                  readOnly
                />
                <InputNumber
                  label="Despesa"
                  maxWidth="200px"
                  value={gameValue.document_value}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      document_value: value,
                    })
                  }
                  required
                  readOnly
                />
              </ItemRow>
              <ItemRow>
                <InputCurrency
                  label="Valor Aplicado"
                  maxWidth="200px"
                  value={gameValue.applied_value}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      applied_value: value,
                    })
                  }
                  required
                  readOnly
                />
                <InputCurrency
                  label="Valor Total"
                  maxWidth="200px"
                  value={gameValue.total_value}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      total_value: value,
                    })
                  }
                  required
                  readOnly
                />
                <InputCurrency
                  label="Saldo"
                  maxWidth="200px"
                  value={gameValue.balance_avaliable}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      balance_avaliable: value,
                    })
                  }
                  required
                  readOnly
                />
              </ItemRow>
              <ItemRow>
                <AttachFile
                  newId="order"
                  title=""
                  value={gameValue.attached_file!}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      attached_file: value,
                    })
                  }
                  readOnly
                />
              </ItemRow>
            </>
          ) : null}
          {gameValue.document_type === "FATURA" ? (
            <>
              <ItemRow>
                <InputText
                  label="Fatura"
                  maxWidth="200px"
                  value={gameValue.document_number}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      document_number: value,
                    })
                  }
                  required
                  readOnly
                />
                <InputDate
                  label="Data de Vencimento"
                  maxWidth="200px"
                  value={gameValue.expiration_date}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      expiration_date: value,
                    })
                  }
                  required
                  readOnly
                />
              </ItemRow>
              <ItemRow>
                <InputCurrency
                  label="Valor da Aplicação"
                  maxWidth="200px"
                  value={gameValue.applied_value}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      applied_value: value,
                    })
                  }
                  required
                  readOnly
                />
                <InputCurrency
                  label="Valor Total"
                  maxWidth="200px"
                  value={gameValue.total_value}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      total_value: value,
                    })
                  }
                  required
                  readOnly
                />
                <InputCurrency
                  label="Saldo"
                  maxWidth="200px"
                  value={gameValue.balance_avaliable}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      balance_avaliable: value,
                    })
                  }
                  required
                  readOnly
                />
              </ItemRow>
              <ItemRow>
                <AttachFile
                  newId="invoice"
                  title=""
                  value={gameValue.attached_file!}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      attached_file: value,
                    })
                  }
                  readOnly
                />
              </ItemRow>
            </>
          ) : null}
          {gameValue.document_type === "CONHECIMENTO" ? (
            <>
              <ItemRow>
                <InputText
                  label="Conhecimento"
                  maxWidth="350px"
                  value={gameValue.document_number}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      document_number: value,
                    })
                  }
                  required
                  readOnly
                />
              </ItemRow>
              <ItemRow>
                <InputDate
                  label="Data de Embarque"
                  maxWidth="200px"
                  value={gameValue.departure_date}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      departure_date: value,
                    })
                  }
                  required
                  readOnly
                />
              </ItemRow>
              <ItemRow>
                <AttachFile
                  newId="conhecimento"
                  title=""
                  value={gameValue.attached_file!}
                  changeValue={value =>
                    setGameValue({
                      ...gameValue,
                      attached_file: value,
                    })
                  }
                  readOnly
                />
              </ItemRow>
            </>
          ) : null}
        </MinContainer>
      </Container>
    </Modal>
  );
};

export default ReadOperation;
