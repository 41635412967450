import { DocumentItemProps } from "v2/components/forms/documents/contracts";

export interface GroupAmmount {
  number: string;
  ammount: number;
  max: number;
}

export function getNextDocumentsGroupAmmount(
  documents: DocumentItemProps[],
  processValue: number
): GroupAmmount {
  const nextNumber =
    documents.length <= 0
      ? 1
      : Math.max(
        ...documents.map(({ group_number }) =>
          group_number ? parseInt(group_number) : 0
        )
      ) + 1;

  const nextGroup = {
    number: `${nextNumber}`,
    ammount: 0,
    max: processValue,
  };

  return nextGroup;
}

export function getDocumentsGroupAmmount(
  documents: DocumentItemProps[],
  groupNumber: string,
  processValue: number,
  processType: string
): GroupAmmount {
  const groupDocuments = documents.filter(
    ({ group_number }) => group_number === groupNumber
  );

  const nextGroup = {
    number: groupNumber,
    ammount: processValue/*groupDocuments.filter(e => {
      if (processType === 'IMPORTACAO') {
        return e.document_type !== 'FATURA'
      } else {
        return e.document_type !== 'ORDEM PAGAMENTO'
      }
    }).reduce((acc, document) => {
      if (!document.value_applied) return acc;
      return acc + document.value_applied;
    }, 0)*/,
    max: processValue,
  };

  return nextGroup;
}
