import {
  Button,
  HStack,
  IconButton,
  ModalBody,
  useToast
} from '@chakra-ui/react'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { DocumentItemProps } from 'v2/components/forms/documents/contracts'
import FormDocumentAPOLICE from 'v2/components/forms/documents/FormDocumentAPOLICE'
import FormDocumentCREDIT from 'v2/components/forms/documents/FormDocumentCREDIT'
import FormDocumentDEBIT from 'v2/components/forms/documents/FormDocumentDEBIT'
import FormDocumentDI from 'v2/components/forms/documents/FormDocumentDI'
import FormDocumentDRE from 'v2/components/forms/documents/FormDocumentDRE'
import FormDocumentDSI from 'v2/components/forms/documents/FormDocumentDSI'
import FormDocumentDUE from 'v2/components/forms/documents/FormDocumentDUE'
import FormDocumentDUIMP from 'v2/components/forms/documents/FormDocumentDUIMP'
import FormDocumentFatura from 'v2/components/forms/documents/FormDocumentFatura'
import FormDocumentConhecimento from 'v2/components/forms/documents/FormDocumentConhecimento'
import FormDocumentOrdemPagamento from 'v2/components/forms/documents/FormDocumentOrdemPagamento'
import FormDocumentLI from 'v2/components/forms/documents/FormDocumentLI'
import AppModal from 'v2/components/shared/AppModal'
import ButtonConfirm from 'v2/components/shared/ButtonConfirm'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import TableRender from 'v2/components/shared/TableRender'
import { apiClient } from 'v2/services/clients/apiClient'
import { formatCurrency } from 'v2/services/helpers/formatCurrency'
import FileItem from '../../files/FileItem'
import { OperationInfosValue } from '../OperationInfos'
import { useDispatch } from 'react-redux'
import { clearLoading, showLoading } from 'redux/actions'

const forms = {
  apolice: FormDocumentAPOLICE,
  due: FormDocumentDUE,
  dsi: FormDocumentDSI,
  di: FormDocumentDI,
  dre: FormDocumentDRE,
  duimp: FormDocumentDUIMP,
  li: FormDocumentLI,
  fatura: FormDocumentFatura,
  conhecimento: FormDocumentConhecimento,
  op: FormDocumentOrdemPagamento
}

export type Forms = keyof typeof forms | undefined

type Tabs = 'jogo'

interface DocumentSetModalValue {
  documents: DocumentItemProps[]
  indexDel?: number
  document_number?: string
}

interface DocumentSetModalProps {
  infos: {
    number: string
    max: number
    ammount: number
  }
  isOpen: boolean
  onClose: () => void
  value?: DocumentSetModalValue
  onSave?: (value: DocumentSetModalValue) => void
  onChange?: (value: DocumentSetModalValue) => void
  formsEnabled: Forms[]
  idSyscambio: string
  operation: OperationInfosValue
}

const DocumentSetModal: React.FC<DocumentSetModalProps> = ({
  onSave,
  onClose,
  isOpen,
  onChange,
  value,
  infos,
  formsEnabled,
  idSyscambio,
  operation
}) => {
  const [documentForm, setDocumentForm] = useState<any>({})
  const [documents, setDocuments] = useState<DocumentItemProps[]>(
    value?.documents || []
  )
  const [currentTab, setTab] = useState<Tabs>('jogo')
  const [currentForm, setForm] = useState<Forms>()
  const [processBalance, setProcessBalance] = useState(0)
  const [totalValue, setTotalValue] = useState(0)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [oldDocument, setOldDocument] = useState<any>({})
  const { toString } = formatCurrency()
  const toast = useToast()
  const dispatch = useDispatch()
  const showError = (title: string, description: string) => {
    toast({
      position: 'bottom-right',
      title,
      description,
      status: 'error',
      variant: 'left-accent'
    })
  }

  const showInfo = (title: string, description: string) => {
    toast({
      position: 'bottom-right',
      title,
      description,
      status: 'info',
      variant: 'left-accent'
    })
  }

  const ButtonFormTab = ({
    children,
    name
  }: {
    children: ReactNode
    name: Forms
  }) => {
    if (!formsEnabled.includes(name)) return <></>

    return (
      <>
        <Button
          size={'sm'}
          // color={"gray.100"}
          variant={'ghost'}
          rounded={'sm'}
          _hover={{ color: 'gray.50', bg: 'primary.400' }}
          _active={{ color: 'white', bg: 'primary.300' }}
          isActive={currentForm === name}
          onClick={() => {
            var props = Object.getOwnPropertyNames(formProps.value)
            for (var i = 0; i < props.length; i++) {
              formProps.value[props[i]] = null
            }
            setForm(() => name)
          }}
        >
          {children}
        </Button>
      </>
    )
  }

  const ButtonTab = ({
    children,
    name
  }: {
    children: ReactNode
    name: Tabs
  }) => {
    return (
      <>
        <Button
          size={'sm'}
          // color={"gray.100"}
          variant={'ghost'}
          rounded={'sm'}
          _hover={{ color: 'gray.50', bg: 'primary.400' }}
          _active={{ color: 'white', bg: 'primary.300' }}
          isActive={currentTab === name}
          onClick={() => {
            setTab(() => name)
          }}
        >
          {children}
        </Button>
      </>
    )
  }

  const nextDocumentForm = useMemo(() => {
    const formData = { ...documentForm }

    if (formData.value_total && formData.value_applied) {
      const nextBalance =
        processBalance + (formData.value_total - formData.value_applied)
      formData.balance = nextBalance
    } else {
      formData.balance = processBalance || formData.value_total
    }

    formData.value_total = totalValue || documentForm.value_total

    return formData
  }, [documentForm, totalValue])

  const formProps = useMemo(() => {
    return {
      value: nextDocumentForm,
      operation,
      onChange: (v: any) => {
        if (totalValue) {
          setDocumentForm(() => ({
            ...v,
            value_total: totalValue ?? 0
          }))
        } else {
          setDocumentForm(() => v)
        }
      },
      onRequestDocumentNumberBalance: async () => {
        try {
          dispatch(
            showLoading({
              message: 'Carregando',
              isLoading: true
            })
          )
          if (nextDocumentForm.document_number) {
            nextDocumentForm.last_total = null
            nextDocumentForm.last_balance = null
            const { flag, total, saldo, referencia } =
              await apiClient.operations.checkBalance({
                id_syscambio: idSyscambio,
                document_number: nextDocumentForm.document_number,
                document_type: currentForm?.toUpperCase() as any
              })
            if (flag === 'insufficientFunds') {
              setDocumentForm(() => ({}))
              return showError('Saldo', 'Documento sem Saldo')
            }
            if (flag === 'dascamFunds' || flag === 'directFunds') {
              showInfo('Existente', 'Documento já existente')
              nextDocumentForm.reference = referencia
              setDocumentForm(() => ({
                ...nextDocumentForm,
                ...{
                  value_total: saldo ?? 0,
                  last_balance: saldo,
                  last_total: Number(total).toLocaleString('pt-br', {
                    minimumFractionDigits: 2
                  })
                }
              }))
            }
            if (flag === 'newDoc') {
              setDocumentForm(() => ({ ...nextDocumentForm }))
              showInfo('Novo', 'Documento Novo')
            }

            if (flag === 'unavailable') {
              setDocumentForm(() => ({}))
              showError('Erro', 'Documento não disponível sua empresa.')
            }
          } else {
            setDocumentForm(() => ({}))
            showError('Erro', 'Digite um Documento')
          }
        } catch (error) {
          showError('Erro', String(error))
        } finally {
          dispatch(clearLoading())
        }
      }
    }
  }, [setDocumentForm, nextDocumentForm])

  const getForm = () => {
    if (!currentForm) return <></>
    const FormComponent = forms[currentForm]
    return <FormComponent {...formProps} />
  }

  useEffect(() => {
    if (value) {
      setDocuments(() => value.documents || [])
    }
  }, [value])

  const addDocument = (documentData: DocumentItemProps) => {
    const nextDocuments = [...documents, documentData]

    setDocuments(() => [...nextDocuments])
    //onChange?.({ documents: nextDocuments })
  }

  const editDocument = (index: number) => {
    setIsEdit(() => true)
    console.log(index)
    const editDocument = documents.filter((_, i) => i === index)
    editDocument[0].old_doc = editDocument[0].document_number
    switch (editDocument[0].document_type) {
      case 'DUIMP':
        setForm('duimp')
        break
      case 'DRE':
        setForm('dre')
        break
      case 'DSI':
        setForm('dsi')
        break
      case 'DI':
        setForm('di')
        break
      case 'DUE':
        setForm('due')
        break
      case 'LI':
        setForm('li')
        break
      case 'FATURA':
        setForm('fatura')
        break
      case 'CONHECIMENTO':
        setForm('conhecimento')
        break
      case 'ORDEM PAGAMENTO':
        setForm('op')
        break
      default:
        setForm('duimp')
        break
    }
    setDocumentForm(() => editDocument[0])
    removeEditDocument(index)
  }

  const removeEditDocument = (index: number) => {
    documents.splice(Number(index), 1)
    const nextDocuments = documents

    setDocuments(() => [...nextDocuments])
  }

  const removeDocument = (index: number, document_number: string) => {
    documents.splice(Number(index), 1)
    const nextDocuments = documents

    setDocuments(() => [...nextDocuments])
    onChange?.({
      documents: nextDocuments,
      indexDel: index,
      document_number: document_number
    })
  }

  const validateDocument = (document: any) => {
    console.log('form data', document)

    if (
      document.document_type === 'FATURA' ||
      document.document_type === 'OP' ||
      document.document_type === 'CONHECIMENTO'
    ) {
      return true
    }

    const isValidNumber =
      document.document_number && document.document_number.length >= 9

    const isValidBalance =
      document.balance === undefined ? true : document.balance >= 0

    if (!isValidNumber) {
      showError(
        'Número do documento inválido',
        'O número do documento deve conter no mínimo 9 dígitos'
      )
    }

    if (!isValidBalance) {
      showError('Saldo Inválido', 'O saldo deve ser positivo')
    }

    return isValidNumber && isValidBalance
  }

  return (
    <>
      <AppModal
        _modal={{ size: '4xl', scrollBehavior: 'inside' }}
        title='Novo jogo de Documentos'
        isOpen={isOpen}
        onClose={() => {
          setDocumentForm(() => ({}))
          setForm(() => undefined)
          onClose?.()
        }}
        _bottomHeader={
          <Col>
            <Row px={2}>
              <SimpleFormGenerator
                rows={[
                  {
                    columns: 3,
                    fields: [
                      {
                        label: 'Jogo',
                        type: 'text',
                        readOnly: true,
                        name: 'number'
                      },
                      {
                        label: 'Referência',
                        type: 'text',
                        name: 'reference'
                      },
                      {
                        label: 'Valor da Aplicação do Jogo',
                        type: 'currency',
                        readOnly: true,
                        name: 'max'
                      }
                    ]
                  }
                ]}
                value={infos}
              />
            </Row>
            <Row color={'white'} bg={'primary.600'} h={10} px={2}>
              <HStack spacing={2}>
                <ButtonTab name='jogo'>Jogo</ButtonTab>
              </HStack>
              <Row flex={1} />
              <HStack spacing={2}>
                {documents.length > 0 && (
                  <Button
                    size={'sm'}
                    colorScheme={'green'}
                    _hover={{ bg: 'green.400' }}
                    _active={{ bg: 'green.300' }}
                    onClick={() => {
                      onSave?.({
                        documents
                      })

                      // setDocuments(() => []);
                      setDocumentForm(() => ({}))
                    }}
                  >
                    Enviar Jogo
                  </Button>
                )}
              </HStack>
            </Row>
            {currentTab === 'jogo' && (
              <Row
                p={2}
                bg={'gray.50'}
                borderBottom={'1px solid transparent'}
                borderColor={'gray.300'}
              >
                <HStack spacing={2}>
                  <ButtonFormTab name={'dre'}>DRE</ButtonFormTab>
                  <ButtonFormTab name={'di'}>DI</ButtonFormTab>
                  <ButtonFormTab name={'li'}>LI</ButtonFormTab>
                  <ButtonFormTab name={'due'}>DUE</ButtonFormTab>
                  <ButtonFormTab name={'dsi'}>DSI</ButtonFormTab>
                  <ButtonFormTab name={'fatura'}>FATURA</ButtonFormTab>
                  <ButtonFormTab name={'apolice'}>APÓLICE</ButtonFormTab>
                  <ButtonFormTab name={'conhecimento'}>
                    CONHECIMENTO
                  </ButtonFormTab>
                  <ButtonFormTab name={'op'}>ORDEM PGTO</ButtonFormTab>
                  <ButtonFormTab name={'duimp'}>DUIMP</ButtonFormTab>
                </HStack>
              </Row>
            )}
          </Col>
        }
      >
        <ModalBody p={0}>
          {currentTab === 'jogo' && (
            <Col
              borderBottom={'1px solid transparent'}
              borderColor={'gray.300'}
            >
              <Col p={4}>
                {getForm()}
                <Row>
                  <Row flex={1} />
                  <Button
                    size={'xs'}
                    colorScheme={'primary'}
                    onClick={() => {
                      const nextDocument = {
                        ...nextDocumentForm,
                        document_type: currentForm?.toUpperCase(),
                        group_number: infos.number
                      }

                      const alreadyExist = documents.find(
                        (e) =>
                          e.document_number === nextDocument.document_number &&
                          e.document_type === nextDocument.document_type
                      )

                      if (alreadyExist !== null && alreadyExist !== undefined)
                        return showError(
                          'Documento já utilizado!',
                          'Este documento já foi lançado neste processo, por favor verifique os dados.'
                        )

                      if (nextDocument.value_applied === 0)
                        return showError(
                          'Valor Inválido!',
                          'O valor aplicado do documento não pode ser igual a zero.'
                        )

                      const isValid = validateDocument(nextDocument)
                      let tempList = [...documents]
                      if (isValid) {
                        tempList = [...documents, nextDocument]
                      } else {
                        return showError(
                          'Documento Inválido!',
                          'Verifique as informações e tente novamente.'
                        )
                      }

                      const actualValue = Array.from(
                        tempList.reduce(
                          (m, { document_type, value_applied }) =>
                            m.set(
                              document_type,
                              (m.get(document_type) || 0) + value_applied
                            ),
                          new Map()
                        ),
                        ([document_type, value_applied]) => ({
                          document_type,
                          value_applied
                        })
                      )
                      console.log(actualValue)

                      let validValue: boolean = true

                      actualValue.forEach((element) => {
                        if (element.value_applied > operation.process.value) {
                          validValue = false
                          return showError(
                            `Atenção! A soma dos valores das ${element.document_type} não pode ser maior que o valor do processo.`,
                            'Por favor verifique as informações antes de continuar.'
                          )
                        }
                      })
                      if (validValue) {
                        addDocument(nextDocument)
                        setDocumentForm(() => ({}))
                        setProcessBalance(() => 0)
                        setIsEdit(() => false)
                        setOldDocument('')
                      }
                    }}
                  >
                    Salvar Documento
                  </Button>
                </Row>
              </Col>
            </Col>
          )}
          <Col bg={'primary.600'} roundedBottom={'lg'}>
            <Col
              roundedBottom={'lg'}
              overflow={'hidden'}
              border={'2px solid transparent'}
              borderColor={'primary.600'}
              bg={'white'}
            >
              <TableRender
                _thead={{
                  _tr: { bg: 'primary.600' },
                  _th: {
                    color: 'white',
                    border: 0,
                    fontSize: '10px',
                    px: 1,
                    py: 2
                  }
                }}
                config={{
                  columns: [
                    {
                      key: 'actions',
                      title: 'Editar',
                      render: (_, __, index) => {
                        return (
                          <IconButton
                            aria-label='Editar'
                            size={'sm'}
                            colorScheme={'secondary'}
                            color={'primary.400'}
                            onClick={() => {
                              editDocument(index)
                            }}
                          >
                            <AiOutlineEdit size={16} />
                          </IconButton>
                        )
                      }
                    },
                    {
                      key: 'document_type',
                      title: 'Documento',
                      defaultLabel: 'Não definido'
                    },
                    {
                      key: 'document_number',
                      title: 'Número',
                      defaultLabel: 'Não definido',
                      render: (value: any, item: any) => {
                        if (item.document_type === 'di') {
                          return value
                            .replace(/\D/g, '')
                            .replace(/(\d{2})(\d)/, '$1/$2')
                            .replace(/(\d{7})(\d)/, '$1-$2')
                            .replace(/(-\d{1})\d+?$/, '$1')
                        }
                        return value
                      }
                    },
                    {
                      key: 'value_applied',
                      title: 'Aplicação',
                      defaultLabel: 'Não definido',
                      render: (v: any) => `${toString(Number(v ?? 0))}`
                    },
                    {
                      key: 'value_total',
                      title: 'Valor Total',
                      defaultLabel: 'Não definido',
                      render: (v: any) => `${toString(Number(v ?? 0))}`
                    },
                    {
                      key: 'balance',
                      title: 'Saldo Disponível',
                      defaultLabel: 'Não definido',
                      render: (v: any) => `${toString(Number(v ?? 0))}`
                    },
                    {
                      key: 'files',
                      title: 'Anexo',
                      defaultLabel: 'Não definido',
                      render: (value: any) => {
                        if (
                          !value ||
                          value.length <= 0 ||
                          !value.some((elem: any) => elem.src?.length)
                        )
                          return <>Não definido</>

                        return (
                          <Col>
                            {value.map((file: any, keyFile: number) => (
                              <FileItem {...file} key={`fileItem${keyFile}`} />
                            ))}
                          </Col>
                        )
                      }
                    },
                    {
                      key: 'actions',
                      title: 'Ações',
                      render: (_, item, index) => {
                        return (
                          <ButtonConfirm
                            title={'Continuar?'}
                            description={
                              'Deseja realmente excluir este arquivo?'
                            }
                            iconShow={<AiOutlineDelete size={22} />}
                            iconHide={<AiOutlineDelete size={22} />}
                            _default={{
                              colorScheme: 'orange',
                              variant: 'ghost'
                            }}
                            _confirm={{
                              colorScheme: 'red',
                              variant: 'solid'
                            }}
                            onConfirm={() => {
                              removeDocument(index, item.document_number)
                            }}
                          />
                        )
                      }
                    }
                  ]
                }}
                data={documents}
              />
            </Col>
          </Col>
        </ModalBody>
      </AppModal>
    </>
  )
}

export default DocumentSetModal
