
import { ApiContext } from "../ApiContext";

export class ApiFiscalNotes extends ApiContext {
  public async all(params: Partial<ApiFiscalNotes.FiscalNotesProps>) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http
      .post(`/consulta/notas`, params, config)
      .then((res) => res.data);
  }

}
export namespace ApiFiscalNotes {
  export interface FiscalNotesProps {
    id_syscambio: number,
    data_type: string,
    note_type: string,
    start_date: string,
    end_date: string
  }
}
