import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { clearUser, setUser } from "redux/actions";
import useUserAPI from "./useUserAPI";

export default function useLocalTokens(redirectTo: string | undefined) {
  const [fetchOneUser] = useUserAPI();
  const dispatch = useDispatch();
  const history = useHistory();

  const tokensInLocalStorage = () => {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    const loggedUser = localStorage.getItem("logged_user");

    if (accessToken !== null && refreshToken !== null && loggedUser !== null) {
      return true;
    } else {
      return false;
    }
  };

  const fetchUser = async () => {
    try {
      const { data } = await fetchOneUser({
        id: String(localStorage.getItem("logged_user")!),
      });

      dispatch(setUser(data));
    } catch (error: any) {
      history.push("/unauthorized");
      dispatch(clearUser());
      localStorage.clear();
    }
  };

  const handleSetting = async () => {
    await fetchUser();
    // console.log("Caiu");

    if (redirectTo) {
      history.push(redirectTo);
    }
  };

  useEffect(() => {
    if (tokensInLocalStorage()) {
      handleSetting();
    } else {
      localStorage.clear();
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
