// import { devices } from "helpers/devices";
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setGlobalMessage } from 'redux/actions'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as PreviewIcon } from 'assets/IconOpenEye.svg'
import { ReactComponent as AttachIcon } from 'assets/attachIcon.svg'
import { ReactComponent as CrossIcon } from 'assets/crossIcon.svg'
import { useToast } from '@chakra-ui/react'

const Fade = keyframes`
    0% {
        transform: scaleX(.3);
        opacity: 0;
    }
    70% {
        transform: scaleX(1.1);
        opacity: 1;
    }
    100% {
        transform: scaleX(1);
    }
`

const AttachStyle = styled.div`
  width: 100%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0;
`

const Title = styled.p<{ isBlock: boolean }>`
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.gray3};
`

const ButtonAttach = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const P = styled.p`
  font-size: 0.9rem;
  line-height: 20px;
  color: ${(props) => props.theme.color.gray4};
  margin-right: 0.3rem;
`

const SinalStatus = styled.p<{ attached?: boolean }>`
  font-weight: bold;
  font-size: 1rem;
  line-height: 20px;
  color: ${(props) =>
    props.attached
      ? props.theme.color.button_normal
      : props.theme.color.primary_main};
  margin-left: 0.3rem;
`

const IconAttach = styled.div<{ readOnly?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  width: 20px;
  height: 20px;
  margin: 0 0.3rem;
  background-color: ${(props) => props.theme.color.gray4};
  border-radius: 5px;
  cursor: ${(props) => (props.readOnly ? 'default' : 'pointer')};

  & svg {
    width: 18px;
    height: 18px;
  }
`

const IconPreview = styled.div<{ readOnly?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  width: 20px;
  height: 20px;
  margin: 0 0.3rem;
  background-color: ${(props) => props.theme.color.secondary_main};
  border-radius: 5px;
  cursor: ${(props) => (props.readOnly ? 'default' : 'pointer')};
  animation: ${Fade} 200ms 1 ease-out both;

  & svg {
    width: 18px;
    height: 18px;
    fill: ${(props) => props.theme.color.primary_main};
  }
`

const IconRemove = styled.div<{ readOnly?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  width: 20px;
  height: 20px;
  margin: 0 0.3rem;
  background-color: ${(props) => props.theme.color.deny};
  border-radius: 5px;
  cursor: ${(props) => (props.readOnly ? 'default' : 'pointer')};

  & svg {
    width: 18px;
    height: 18px;
  }
`

const InputFile = styled.input`
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
  z-index: 98;
`

interface Props {
  newId?: string
  title?: string
  value: string
  typeFile?: string
  changeValue: (value: string, name?: string, type?: string) => void
  readOnly?: boolean
  required?: boolean
  isValid?: boolean
  limitSize?: number
}

const AttachFile = ({
  newId,
  title,
  value,
  typeFile,
  changeValue,
  required,
  isValid,
  readOnly,
  limitSize = Infinity
}: Props): JSX.Element => {
  const [fileBase64String, setFileBase64String] = useState<
    String | ArrayBuffer | null
  >('')
  const [fileBase64Type, setFileBase64Type] = useState<string>('')
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()
  const toast = useToast()
  const encodeFileBase64 = (file: File | null) => {
    let reader = new FileReader()
    if (file) {
      reader.readAsDataURL(file)
      reader.onload = () => {
        let Base64 = reader.result
        setFileBase64String(
          Base64 as string
          // .replace("data:image/jpeg;base64,", "")
          // .replace("data:image/png;base64,", "")
        )
        setFileBase64Type(file.type)
        changeValue!(
          (Base64 as string).replace(`data:${file.type};base64,`, ''),
          // .replace("data:image/jpeg;base64,", "")
          // .replace("data:image/png;base64,", "")
          file.name,
          file.type
        )
      }
      reader.onerror = (error) => {
        console.log('Teste de erro: ', error, fileBase64String)
      }
    }
  }

  const checkFile = (file: File | null | undefined) => {
    if (file) {
      // console.log(file.type);
      let size = file.size
      if (limitSize) {
        let limit = limitSize ? limitSize : 104857600
        if (size < limit) {
          let reg = /application\/(pdf)/
          let reg2 = /image\/(png|jpeg)/
          let reg3 = /application\/(x-zip-compressed)/
          let reg4 =
            /application\/(vnd.openxmlformats-officedocument.wordprocessingml.document)/
          let reg5 = /application\/(vnd.ms-excel)/
          let reg6 =
            /application\/(vnd.openxmlformats-officedocument.spreadsheetml.sheet)/
          let reg7 = /application\/(zip)/
          console.log(file.type)
          if (
            reg.test(file.type) ||
            reg2.test(file.type) ||
            reg3.test(file.type) ||
            reg4.test(file.type) ||
            reg5.test(file.type) ||
            reg6.test(file.type) ||
            reg7.test(file.type)
          ) {
            encodeFileBase64(file)
          } else {
            toast({
              title: 'Tipo do Arquivo',
              position: 'bottom',
              description: 'O tipo do arquivo enviado não é permitido',
              status: 'info',
              duration: 3000,
              isClosable: true
            })
            setFileBase64String('')
            changeValue!('')
          }
        } else {
          setFileBase64String('')
          changeValue!('')
          toast({
            title: 'Tamanho do Arquivo',
            position: 'bottom',
            description: 'Arquivo excede o tamanho sugerido!',
            status: 'info',
            duration: 3000,
            isClosable: true
          })
        }
      }
    } else {
      setFileBase64String('')
      changeValue!('')
    }
  }

  const base64toBlob = (base64Data: string, type: string) => {
    const sliceSize = 1024
    const byteCharacters = atob(base64Data.replace(`data:${type};base64,`, ''))
    const bytesLength = byteCharacters.length
    const slicesCount = Math.ceil(bytesLength / sliceSize)
    const byteArrays = new Array(slicesCount)

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize
      const end = Math.min(begin + sliceSize, bytesLength)

      const bytes = new Array(end - begin)
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0)
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes)
    }
    return new Blob(byteArrays, {
      type: type
    })
  }

  const openFile = (data: string, type: string) => {
    let blob: Blob
    if (typeFile) {
      blob = base64toBlob(value, typeFile)
      // console.log(blob);
    } else {
      blob = base64toBlob(data, type)
    }
    if (
      (window.navigator as any) &&
      (window.navigator as any).msSaveOrOpenBlob
    ) {
      ;(window.navigator as any).msSaveOrOpenBlob(blob, `${Date.now()}`)
    } else {
      const blobUrl = URL.createObjectURL(blob)
      window.open(blobUrl)
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <AttachStyle>
      <Title isBlock={isInputBlock}>{title}</Title>
      <ButtonAttach>
        <P>Anexar arquivo:</P>
        {readOnly !== true ? (
          <>
            <IconAttach
              readOnly={readOnly}
              onClick={() => {
                document.getElementById(newId ? newId : 'selectedFile')!.click()
                setIsInputBlock(false)
              }}
            >
              <AttachIcon />
            </IconAttach>
            {value !== '' &&
            value !== null &&
            value !== undefined &&
            !required ? (
              <IconRemove
                readOnly={readOnly}
                onClick={() => {
                  checkFile(undefined)
                }}
              >
                <CrossIcon />
              </IconRemove>
            ) : null}
          </>
        ) : null}
        {value !== '' && value !== null && value !== undefined ? (
          <IconPreview
            onClick={() => {
              if (
                value.includes('https://') &&
                value !== undefined &&
                value !== null &&
                value !== ''
              ) {
                window.open(String(value), '_blank')!.focus()
              } else if (
                value !== undefined &&
                value !== null &&
                value !== ''
              ) {
                openFile(String(fileBase64String), fileBase64Type)
              } else {
                dispatch(
                  setGlobalMessage({
                    message: 'Não há anexo!',
                    type: 'WARN'
                  })
                )
              }
            }}
          >
            <PreviewIcon />
          </IconPreview>
        ) : null}
        <SinalStatus
          attached={
            value !== '' && value !== null && value !== undefined ? true : false
          }
        >
          {value !== '' && value !== null && value !== undefined
            ? 'Anexado'
            : 'Pendente'}
        </SinalStatus>
      </ButtonAttach>
      <InputFile
        type='file'
        id={newId ? newId : 'selectedFile'}
        // accept="image/png, image/jpeg, application/pdf"
        accept='
          image/png,
          image/jpeg,
          application/pdf,
          application/vnd.oasis.opendocument.text,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
          application/x-vnd.oasis.opendocument.spreadsheet,
          text/csv,
          application/zip,
          application/x-rar-compressed,
          application/vnd.rar'
        onChange={(event) => {
          checkFile(event.target.files![0])
        }}
        readOnly={readOnly}
      />
    </AttachStyle>
  )
}

export default AttachFile
