import AttachFile from 'components/generic/AttachFileToBase64'
import GenericButton from 'components/generic/GenericButton'
import { axiosAuth } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { UserModel } from 'interfaces/user'
import { ArchiveModel } from 'interfaces/user-archives'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import styled from 'styled-components'

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`

const RowDivideItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  & div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
`

const TitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`

const SubtitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.color.gray4};
  font-weight: bold;
  line-height: 24px;
`

const Button = styled(GenericButton)<{ width?: string }>`
  margin: 1rem 0;
  width: ${(props) => (props.width ? props.width : '260px')};
`

interface Props {
  userDocumentation: ArchiveModel
  changeDocumentation: (userDocumentation: ArchiveModel) => void
  mandatory: any
  userData: UserModel
}

const DocumentationForeign = ({
  userDocumentation,
  changeDocumentation,
  mandatory,
  userData
}: Props): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()

  const getForm = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Finalizando',
          isLoading: true,
          subMessage: 'Aplicando dados do usuário'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(`/forms/3`, config)
      // console.log(data);
      if (
        data.attached_file.includes('https://') &&
        data.attached_file !== undefined &&
        data.attached_file !== null &&
        data.attached_file !== ''
      ) {
        window.open(String(data.attached_file), '_blank')!.focus()
      } else {
        dispatch(
          setGlobalMessage({
            message: 'Não há anexo!',
            type: 'WARN'
          })
        )
      }
    } catch (error: any) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  return (
    <>
      <DivItems>
        <RowDivideItems>
          <div>
            <TitleItems>Documentação necessária para cadastro</TitleItems>
            <SubtitleItems>
              Os arquivos anexados não poderão passar de 1MB e só é possível
              anexar 1 arquivo, se adicionar outro em um local já anexado o
              arquivo será sobreposto.
            </SubtitleItems>
          </div>
          {/* {userData.status !== "ACTIVE" && userData.status !== "EVALUATION" ? (
            <div>
              <SubtitleItems>
                Baixe aqui, os formulários a serem preenchidos ou assinados
              </SubtitleItems>
              <Button type="button" onClick={() => getForm()}>
                Download de formulários
              </Button>
            </div>
          ) : null} */}
        </RowDivideItems>
      </DivItems>
      <DivItems>
        <AttachFile
          newId='attorney_letter'
          title='Procuração (via original ou cópia autenticada)'
          value={userDocumentation.attorney_letter!}
          typeFile={userDocumentation.attorney_letter_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              attorney_letter: value,
              attorney_letter_type: type
            })
          }
          readOnly={userData.status === 'EVALUATION'}
          limitSize={1000000}
        />
        <AttachFile
          newId='income_tax'
          title='Cópia do imposto de Renda *'
          value={userDocumentation.income_tax!}
          typeFile={userDocumentation.income_tax_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              income_tax: value,
              income_tax_type: type
            })
          }
          required
          isValid={mandatory.income_tax === false ? true : false}
          readOnly={userData.status === 'EVALUATION'}
          limitSize={1000000}
        />
        <AttachFile
          newId='rg_cpf_doc'
          title='Cópia do RG e CPF/RNE *'
          value={userDocumentation.rg_cpf_doc!}
          typeFile={userDocumentation.rg_cpf_doc_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              rg_cpf_doc: value,
              rg_cpf_doc_type: type
            })
          }
          required
          isValid={mandatory.rg_cpf_doc === false ? true : false}
          readOnly={userData.status === 'EVALUATION'}
          limitSize={1000000}
        />
        <AttachFile
          newId='service_contract'
          title='Contrato de Prestação de Serviços (Entre cliente e corretora)'
          value={userDocumentation.service_contract!}
          typeFile={userDocumentation.service_contract_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              service_contract: value,
              service_contract_type: type
            })
          }
          readOnly={userData.status === 'EVALUATION'}
          limitSize={1000000}
        />
        <AttachFile
          newId='residence_proof'
          title='Copia de comprovante de endereço (conta emitida por concessionária de serviço público) *'
          value={userDocumentation.residence_proof!}
          typeFile={userDocumentation.residence_proof_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              residence_proof: value,
              residence_proof_type: type
            })
          }
          required
          isValid={mandatory.residence_proof === false ? true : false}
          readOnly={userData.status === 'EVALUATION'}
          limitSize={1000000}
        />
        {/* <AttachFile
          newId="identification_beneficiaries"
          title="Cópia Documentos Identificação do(s) Beneficiário(s) Final(is)"
          value={userDocumentation.identification_beneficiaries!}
          typeFile={userDocumentation.identification_beneficiaries_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              identification_beneficiaries: value,
              identification_beneficiaries_type: type,
            })
          }
          readOnly={
             userData.status === "EVALUATION"
          }
        /> */}
        <AttachFile
          newId='additional_documents'
          title='Documentos Adicionais'
          value={userDocumentation.additional_documents!}
          typeFile={userDocumentation.additional_documents_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              additional_documents: value,
              additional_documents_type: type
            })
          }
          readOnly={userData.status === 'EVALUATION'}
          limitSize={1000000}
        />
      </DivItems>
    </>
  )
}

export default DocumentationForeign
