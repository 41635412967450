import { devices } from "helpers/devices";
import { useEffect, useState } from "react";
import Logo1 from "assets/Logo 1.png";
import Logo2 from "assets/Logo 2.png";
import styled from "styled-components";
import GenericButton from "components/generic/GenericButton";
import { useHistory } from "react-router-dom";
import Scroll from "react-scroll";
import { Button } from "@chakra-ui/react";

const scroller = Scroll.scroller;

const HeaderStyle = styled.header<{ scrolling: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 999;
  -webkit-box-shadow: ${props =>
    props.scrolling ? "0 1px 5px rgba(0, 0, 0, 0.25)" : "none"};
  -moz-box-shadow: ${props =>
    props.scrolling ? "0 1px 5px rgba(0, 0, 0, 0.25)" : "none"};
  box-shadow: ${props =>
    props.scrolling ? "0 1px 5px rgba(0, 0, 0, 0.25)" : "none"};
  transition: all 0.2s ease-in-out;
  height: 120px;
  background-color: ${props =>
    props.scrolling ? "rgba(255, 255, 255, 0.95)" : "transparent"};
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${devices.phoneOnly} {
    padding: 0 ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 0 ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 0 ${props => props.theme.margins.from_sides};
  }
`;

const ImgContainer = styled.div`
  height: 80px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Logo = styled.img`
  height: auto;
  width: auto;
`;

const Nav = styled.nav`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: flex;
  }
`;

const Ul = styled.ul<{ scrolling: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;

  & a {
    text-decoration: none;
  }

  & li {
    cursor: pointer;
    padding: 13px 20px;
    transform: scale(0.95);

    &:hover {
      transform: scale(1);
      ${props =>
    props.scrolling ? props.theme.color.gray3 : props.theme.color.white1};
    }
  }
`;

const A = styled.a<{ scrolling: boolean }>`
  white-space: nowrap;
  color: ${props =>
    props.scrolling ? props.theme.color.gray1 : props.theme.color.white1};
`;

const ButtonContainer = styled.div`
  height: 100%;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

// const Button = styled(GenericButton)``;

const Header = (): JSX.Element => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const scrollTo = localStorage.getItem("srolling");

  const history = useHistory();

  const onScroll = (event: any) => {
    setScrollTop(event.target.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (scrollTop > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  }, [scrollTop]);

  useEffect(() => {
    if (scrollTo) {
      scroller.scrollTo(scrollTo, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -80,
      });
      localStorage.removeItem("srolling");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HeaderStyle scrolling={scrolling} id="1">
      <Container>
        <ImgContainer>
          <Logo
            src={scrolling ? Logo2 : Logo1}
            alt="Logotipo da Dascam"
            onClick={() => {
              scroller.scrollTo("2", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -80,
              });
            }}
          />
        </ImgContainer>
        <Nav>
          <Ul scrolling={scrolling}>
            <li
              onClick={() => {
                scroller.scrollTo("3", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                  offset: -80,
                });
              }}
            >
              <A scrolling={scrolling}>Para quem é o Dascam Direct?</A>
            </li>
          </Ul>
          <Ul scrolling={scrolling}>
            <li
              onClick={() => {
                scroller.scrollTo("4", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                  offset: -80,
                });
              }}
            >
              <A scrolling={scrolling}>Quem somos</A>
            </li>
          </Ul>
          <Ul scrolling={scrolling}>
            <li
              onClick={() => {
                scroller.scrollTo("5", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                  offset: -80,
                });
              }}
            >
              <A scrolling={scrolling}>Selo do Banco Central</A>
            </li>
          </Ul>
        </Nav>
        <ButtonContainer>
          <Button
            variant={"solid"}
            color={"white"}
            colorScheme={"primary"}
            onClick={() => history.push("/login")}>
            Acessar Dascam Direct
          </Button>
        </ButtonContainer>
      </Container>
    </HeaderStyle>
  );
};

export default Header;
