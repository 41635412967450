import React from 'react'
import { ColumnFieldProps } from '../..'
import CurrencyField from '../CurrencyField'
import DateField from '../DateField'
import FilesField from '../FilesField'
import HeadingField from '../HeadingField'
import NumberField from '../NumberField'
import SearchPickerField from '../SearchPickerField'
import SelectField from '../SelectField'
import TextFieldArea from '../TextAreaField'
import TextField from '../TextField'
import RateField from '../RateField'

interface SimpleFieldProps {
  field: ColumnFieldProps
  value?: any
  onChange?: (value: any) => void
  isReadonly?: boolean
  maxLength?: number
}

const SimpleField: React.FC<SimpleFieldProps> = ({
  field,
  isReadonly,
  onChange,
  value,
  maxLength
}) => {
  return (
    <>
      {field.type === 'text' && (
        <TextField
          infos={field}
          value={value}
          isReadonly={isReadonly}
          onChange={onChange}
          maxLength={field.maxLength}
        />
      )}
      {field.type === 'select' && (
        <SelectField
          infos={field}
          value={value}
          isReadonly={isReadonly}
          onChange={onChange}
        />
      )}
      {field.type === 'search-picker' && (
        <SearchPickerField
          infos={field}
          value={value}
          isReadonly={isReadonly}
          onChange={onChange}
        />
      )}
      {field.type === 'number' && (
        <NumberField
          infos={field}
          value={value}
          isReadonly={isReadonly}
          onChange={onChange}
        />
      )}
      {field.type === 'currency' && (
        <CurrencyField
          infos={field}
          value={value}
          isReadonly={isReadonly}
          onChange={onChange}
        />
      )}
      {field.type === 'rate' && (
        <RateField
          infos={field}
          value={value}
          isReadonly={isReadonly}
          onChange={onChange}
        />
      )}
      {field.type === 'files' && (
        <FilesField
          infos={field}
          value={value}
          isReadonly={isReadonly}
          onChange={onChange}
        />
      )}
      {field.type === 'date' && (
        <DateField
          infos={field}
          value={value}
          isReadonly={isReadonly}
          onChange={onChange}
        />
      )}
      {field.type === 'textarea' && (
        <TextFieldArea
          infos={field}
          value={value}
          isReadonly={isReadonly}
          onChange={onChange}
        />
      )}
      {field.type === 'heading' && <HeadingField infos={field} value={value} />}
    </>
  )
}

export default SimpleField
