import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { consumeQs, parseQs } from "v2/services/helpers/url";

export function useHashData<T = any>(initialData?: T) {
  const [hashData, setData] = useState<T>((initialData || {}) as any);
  const location = useLocation();
  const history = useHistory();

  const exists = useMemo(() => {
    return Object.entries(hashData || {}).length > 0;
  }, [hashData]);

  const parse = () => {
    return parseQs(hashData);
  };

  useEffect(() => {
    const nextFilter = consumeQs(location.hash);
    setData(() => ({ ...nextFilter }));
  }, [location]);

  const redirect = (value: any) => {
    history.push(`#${parseQs(value)}`);
  };

  return { redirect, hashData, parse, exists };
}
