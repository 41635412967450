import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  useDisclosure
} from '@chakra-ui/react'
import { axiosWebsite } from 'helpers/axiosInstances'
import { useGlobals } from 'hooks/helpers/useGlobals'
import React, { useEffect, useMemo, useState } from 'react'
import { InputSelect } from '../GenericInputs'

interface FilterData {
  city: string
  state: string
}

interface StateCityBanksPickProps {
  bankCode?: string | number
  onChange?: (v: FilterData) => void
  value?: Partial<FilterData>
}

const StateCityBanksPick: React.FC<StateCityBanksPickProps> = ({
  bankCode,
  onChange,
  value
}) => {
  const [states, setStates] = useState<string[]>([])
  const [cities, setCities] = useState<string[]>([])

  const [state, setState] = useState<string>()
  const [city, setCity] = useState<string>()

  const { startLoading, stopLoading } = useGlobals()

  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })

  const filterData = useMemo(() => {
    return {
      state,
      city
    }
  }, [state, city])

  const onChangeEmitter = (filter?: Partial<FilterData>) => {
    const nextFilter: any = {
      ...filterData,
      ...filter
    }
    onChange?.(nextFilter)
    // if (!!nextFilter && nextFilter.city && nextFilter.state) {
    // }
  }

  useEffect(() => {
    if (value) {
      console.log('value', value)
      value.state && setState(() => value.state)
      value.city && setCity(() => value.city)
    }
  }, [value])

  useEffect(() => {
    // Refresh States
    startLoading('Carregando Estados')
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    axiosWebsite
      .post(`/bancos/estados`, { bank_code: bankCode }, config)
      .then((res) => res.data)
      .then(setStates)
      .finally(stopLoading)
  }, [bankCode])

  useEffect(() => {
    if (state && state !== '--') {
      startLoading('Carregando Cidades')
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      axiosWebsite
        .post(
          `/bancos/municipios`,
          {
            bank_code: bankCode,
            state
          },
          config
        )
        .then((res) => res.data)
        .then(setCities)
        .finally(stopLoading)
    } else {
      setCities([])
    }
  }, [bankCode, state])

  return bankCode && states.length === 0 ? (
    <Alert status='warning' variant={'left-accent'}>
      <AlertIcon />
      Não foram encontrados estados ou municipios para o banco selecionado.
    </Alert>
  ) : (
    <>
      <InputSelect
        label={'Estado'}
        value={value?.state}
        readOnly={!bankCode || states.length <= 0}
        changeValue={(v) => {
          setState(() => v)
          onChangeEmitter({
            state: v
          })
        }}
      >
        <option>--</option>
        {states.map((state) => (
          <option value={state} key={`state${state}`}>
            {state}
          </option>
        ))}
      </InputSelect>
      <InputSelect
        label={'Cidade'}
        value={value?.city}
        readOnly={!bankCode || cities.length <= 0}
        changeValue={(v) => {
          setCity(() => v)
          onChangeEmitter({
            city: v
          })
        }}
      >
        <option>--</option>
        {cities.map((city) => (
          <option key={`city${city}`} value={city}>
            {city}
          </option>
        ))}
      </InputSelect>
    </>
  )
}

export default StateCityBanksPick
