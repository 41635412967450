import { useDispatch, useSelector } from "react-redux";
import { clearLoading, setGlobalMessage, showLoading } from "redux/actions";
import { GlobalState } from "redux/types";
import { GlobalMessage } from "../../interfaces/GlobalMessage";

export function useGlobals() {
  const dispatch = useDispatch();
  const user = useSelector((state: GlobalState) => state.user);

  const hasPermissions = (permissions: number[]) => {
    const userPermissions = user?.permission_list || [];
    return permissions.every((permission) =>
      userPermissions.includes(permission)
    );
  };

  const hasSomePermissions = (permissions: number[]) => {
    const userPermissions = user?.permission_list || [];
    return permissions.some((permission) =>
      userPermissions.includes(permission)
    );
  };

  const isDascam = user?.cad_type === "DASCAM";
  const isAutorizado = user?.cad_type === "AUTORIZADO";

  const withPermissions = (
    permissions: number[],
    cb?: (isSuccess: boolean) => any
  ) => {
    if (hasPermissions(permissions)) {
      cb?.(true);
    } else {
      cb?.(false);
    }
  };

  const withSomePermissions = (
    permissions: number[],
    cb?: (isSuccess: boolean) => any
  ) => {
    if (hasPermissions(permissions)) {
      cb?.(true);
    } else {
      cb?.(false);
    }
  };

  const startLoading = (message = "Carregando") => {
    dispatch(
      showLoading({
        message,
        isLoading: true,
      })
    );
  };

  const stopLoading = () => {
    dispatch(clearLoading());
  };

  const setMessage = (message: GlobalMessage) => {
    dispatch(setGlobalMessage(message));
  };

  return {
    startLoading,
    stopLoading,
    setMessage,
    user: user!,
    hasPermissions,
    withPermissions,
    hasSomePermissions,
    withSomePermissions,
    isDascam,
    isAutorizado
  };
}
