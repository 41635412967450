import { PayerReceiverModel } from "interfaces/payiesReceivies";
import { ApiContext } from "../ApiContext";

export class ApiPayer extends ApiContext {
  public async all(syscambioId: number | string) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.httpAuth
      .get<PayerReceiverModel[]>(`/pagreceb/${syscambioId}`, config)
      .then((res) => res.data);
  }
}
