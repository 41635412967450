import styled, { keyframes } from "styled-components";
import { ReactComponent as CheckIcon } from "assets/checkSvg.svg";
import { ReactComponent as CrossIcon } from "assets/crossIcon.svg";
import { UserModel } from "interfaces/user";
import { ReactComponent as ExitIcon } from "assets/leftArrowIcon.svg";
import { useEffect, useState } from "react";
import { axiosAuth } from "helpers/axiosInstances";
import { ObservationModel } from "interfaces/observation";
import { isUnauthorized } from "helpers/errors";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setGlobalMessage, clearLoading, showLoading } from "redux/actions";
import { formatDate } from "helpers/formats";

const FadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    `;

const AppearFromCenter = keyframes`
    from {
        opacity: 0;
        transform: scale(.3);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
    `;

const Background = styled.div<{ backFocus?: boolean; animate: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 15000;
  background: ${props =>
    props.backFocus ? props.theme.color.transparent_opaque : "none"};
  animation: ${FadeIn} 200ms 1 ease-out both
    ${props => (props.animate ? "running" : "paused")};
`;

const ContainerOne = styled.div<{
  animate: boolean;
}>`
  padding: 1rem;
  margin: 0.5rem;
  box-shadow: 1px 2px 4px gray;
  background-color: ${props => props.theme.color.white1};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 600px;
  width: 300px;
  animation: ${AppearFromCenter} 200ms 1 ease-out both
    ${props => (props.animate ? "running" : "paused")};
`;

const ContainerTwo = styled.div<{
  animate: boolean;
}>`
  padding: 1rem;
  margin: 0.5rem;
  box-shadow: 1px 2px 4px gray;
  background-color: ${props => props.theme.color.white1};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 600px;
  width: 700px;
  animation: ${AppearFromCenter} 200ms 1 ease-out both
    ${props => (props.animate ? "running" : "paused")};
`;

const Title = styled.p`
  margin: 0.3rem 0;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 24px;
  text-transform: uppercase;
  color: ${props => props.theme.color.third_main};
`;

const StatusText = styled.p`
  margin: 0.3rem 0;
  font-weight: bold;
  font-size: 1rem;
  line-height: 21px;
  text-transform: uppercase;
  color: ${props => props.theme.color.gray1};
`;

const SpanBrown = styled.span`
  color: ${props => props.theme.color.third_main};
`;

const StatusFlowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const FlowSteps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const CheckStatus = styled.div<{ isChecked?: boolean; isDeny?: boolean }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 0.3rem;
  margin: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.isChecked
      ? props.theme.color.primary_main
      : props.isDeny
      ? props.theme.color.deny
      : props.theme.color.gray4};

  & svg {
    width: 100%;
    height: 100%;
  }
`;

const BarStatus = styled.div<{ isChecked?: boolean }>`
  width: 10px;
  height: 90px;
  background-color: ${props =>
    props.isChecked ? props.theme.color.primary_main : props.theme.color.gray4};
`;

const CloseButton = styled.button`
  background: ${props => props.theme.color.secondary_main};
  border: none !important;
  width: 30px;
  height: 30px;
  outline: none;
  cursor: pointer;
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &:hover {
    background: ${props => props.theme.color.secondary_light};
  }

  & svg {
    height: 100%;
    width: auto;
  }
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;

const DivObservation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const NewObservation = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${props => props.theme.color.white1};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  margin: 0.5rem 0;

  & h4 {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 23px;
    color: ${props => props.theme.color.gray2};
    margin: 0.5rem;
  }

  & p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: ${props => props.theme.color.gray2};
    margin: 0.5rem;
    white-space: pre-line;
  }

  & div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
`;

const TieStatusText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

const TieBarIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
`;

const TitleStatus = styled.p`
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 26px;
  margin-bottom: 0.3rem;
  color: ${props => props.theme.color.gray1};
`;

const DateStatus = styled.p`
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 19px;
  margin: 0.3rem 0;
  color: ${props => props.theme.color.gray3};
`;

const DivStatus = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PStatus = styled.p`
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: ${props => props.theme.color.gray2};
  margin: 0.3rem 0;
  white-space: pre-line;
`;

interface Props {
  showModal: boolean;
  close: () => void;
  userData: UserModel;
}

const ObservationHistory = ({
  showModal,
  close,
  userData,
}: Props): JSX.Element | null => {
  const [observationsData, setObservationsData] = useState<ObservationModel[]>(
    []
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const getObservations = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(
        `/observation/${userData?.id}`,
        config
      );

      setObservationsData(data);
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar as observações do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  useEffect(() => {
    if (showModal) {
      getObservations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);
  if (showModal) {
    return (
      <Background animate={true} backFocus={true}>
        <ContainerOne animate={true}>
          <Title>Processo de aprovação</Title>
          <StatusText>
            <SpanBrown>Fase</SpanBrown> : Análise de PRé-Cadastro
          </StatusText>
          <StatusFlowDiv>
            <FlowSteps>
              <TieStatusText>
                <TieBarIcon>
                  <CheckStatus
                    isChecked={
                      (userData.status === "DISAPPROVED" &&
                        userData.responsible_sector === "CLIENTE") ||
                      (userData.status === "ACTIVE" &&
                        userData.responsible_sector === "CLIENTE") ||
                      userData.responsible_sector === "CLIENTE" ||
                      userData.responsible_sector === "CADASTRO" ||
                      userData.responsible_sector === "COMPLIANCE" ||
                      userData.responsible_sector === "DIRETORIA"
                    }
                  >
                    {(userData.status === "DISAPPROVED" &&
                      userData.responsible_sector === "CLIENTE") ||
                    (userData.status === "ACTIVE" &&
                      userData.responsible_sector === "CLIENTE") ||
                    userData.responsible_sector === "CADASTRO" ||
                    userData.responsible_sector === "COMPLIANCE" ||
                    userData.responsible_sector === "DIRETORIA" ? (
                      <CheckIcon />
                    ) : null}
                  </CheckStatus>
                  <BarStatus
                    isChecked={
                      (userData.status === "DISAPPROVED" &&
                        userData.responsible_sector === "CLIENTE") ||
                      (userData.status === "ACTIVE" &&
                        userData.responsible_sector === "CLIENTE") ||
                      userData.responsible_sector === "CADASTRO" ||
                      userData.responsible_sector === "COMPLIANCE" ||
                      userData.responsible_sector === "DIRETORIA"
                    }
                  />
                </TieBarIcon>
                <DivStatus>
                  <TitleStatus>Abertura de Pré- cadastro</TitleStatus>
                  {/* <DateStatus>01/10/1995</DateStatus> */}
                </DivStatus>
              </TieStatusText>
              <TieStatusText>
                <TieBarIcon>
                  <CheckStatus
                    isChecked={
                      (userData.status === "DISAPPROVED" &&
                        userData.responsible_sector === "CLIENTE") ||
                      (userData.status === "ACTIVE" &&
                        userData.responsible_sector === "CLIENTE") ||
                      userData.responsible_sector === "CADASTRO" ||
                      userData.responsible_sector === "COMPLIANCE" ||
                      userData.responsible_sector === "DIRETORIA"
                    }
                  >
                    {(userData.status === "DISAPPROVED" &&
                      userData.responsible_sector === "CLIENTE") ||
                    (userData.status === "ACTIVE" &&
                      userData.responsible_sector === "CLIENTE") ||
                    userData.responsible_sector === "COMPLIANCE" ||
                    userData.responsible_sector === "DIRETORIA" ? (
                      <CheckIcon />
                    ) : null}
                  </CheckStatus>
                  <BarStatus
                    isChecked={
                      (userData.status === "DISAPPROVED" &&
                        userData.responsible_sector === "CLIENTE") ||
                      (userData.status === "ACTIVE" &&
                        userData.responsible_sector === "CLIENTE") ||
                      userData.responsible_sector === "COMPLIANCE" ||
                      userData.responsible_sector === "DIRETORIA"
                    }
                  />
                </TieBarIcon>
                <DivStatus>
                  <TitleStatus>Análise de Pré- Cadastro</TitleStatus>
                  {/* <DateStatus>01/10/1995</DateStatus> */}
                </DivStatus>
              </TieStatusText>
              <TieStatusText>
                <TieBarIcon>
                  <CheckStatus
                    isChecked={
                      (userData.status === "DISAPPROVED" &&
                        userData.responsible_sector === "CLIENTE") ||
                      (userData.status === "ACTIVE" &&
                        userData.responsible_sector === "CLIENTE") ||
                      userData.responsible_sector === "COMPLIANCE" ||
                      userData.responsible_sector === "DIRETORIA"
                    }
                  >
                    {(userData.status === "DISAPPROVED" &&
                      userData.responsible_sector === "CLIENTE") ||
                    (userData.status === "ACTIVE" &&
                      userData.responsible_sector === "CLIENTE") ||
                    userData.responsible_sector === "DIRETORIA" ? (
                      <CheckIcon />
                    ) : null}
                  </CheckStatus>
                  <BarStatus
                    isChecked={
                      (userData.status === "DISAPPROVED" &&
                        userData.responsible_sector === "CLIENTE") ||
                      (userData.status === "ACTIVE" &&
                        userData.responsible_sector === "CLIENTE") ||
                      userData.responsible_sector === "DIRETORIA"
                    }
                  />
                </TieBarIcon>
                <DivStatus>
                  <TitleStatus>Análise de Compliance</TitleStatus>
                  {/* <DateStatus>01/10/1995</DateStatus> */}
                </DivStatus>
              </TieStatusText>
              <TieStatusText>
                <TieBarIcon>
                  <CheckStatus
                    isChecked={
                      (userData.status === "DISAPPROVED" &&
                        userData.responsible_sector === "CLIENTE") ||
                      (userData.status === "ACTIVE" &&
                        userData.responsible_sector === "CLIENTE") ||
                      userData.responsible_sector === "DIRETORIA"
                    }
                  >
                    {(userData.status === "DISAPPROVED" &&
                      userData.responsible_sector === "CLIENTE") ||
                    (userData.responsible_sector === "CLIENTE" &&
                      userData.status === "ACTIVE") ? (
                      <CheckIcon />
                    ) : null}
                  </CheckStatus>
                  <BarStatus
                    isChecked={
                      (userData.status === "DISAPPROVED" &&
                        userData.responsible_sector === "CLIENTE") ||
                      (userData.responsible_sector === "CLIENTE" &&
                        userData.status === "ACTIVE")
                    }
                  />
                </TieBarIcon>
                <DivStatus>
                  <TitleStatus>Análise da Diretoria</TitleStatus>
                  {/* <DateStatus>01/10/1995</DateStatus> */}
                </DivStatus>
              </TieStatusText>
              <TieStatusText>
                <TieBarIcon>
                  <CheckStatus
                    isChecked={
                      userData.responsible_sector === "CLIENTE" &&
                      userData.status === "ACTIVE"
                    }
                    isDeny={
                      userData.responsible_sector === "CLIENTE" &&
                      userData.status === "DISAPPROVED"
                    }
                  >
                    {userData.responsible_sector === "CLIENTE" &&
                    userData.status === "ACTIVE" ? (
                      <CheckIcon />
                    ) : null}
                    {userData.responsible_sector === "CLIENTE" &&
                    userData.status === "DISAPPROVED" ? (
                      <CrossIcon />
                    ) : null}
                  </CheckStatus>
                </TieBarIcon>
                <DivStatus>
                  {userData.responsible_sector === "CLIENTE" &&
                  userData.status === "ACTIVE" ? (
                    <TitleStatus>Aprovação</TitleStatus>
                  ) : null}
                  {userData.responsible_sector === "CLIENTE" &&
                  userData.status === "DISAPPROVED" ? (
                    <TitleStatus>Reprovado</TitleStatus>
                  ) : null}
                </DivStatus>
              </TieStatusText>
            </FlowSteps>
          </StatusFlowDiv>
        </ContainerOne>
        <ContainerTwo animate={true}>
          <TitleBar>
            <Title>histórico</Title>
            <CloseButton onClick={() => close()}>
              <ExitIcon />
            </CloseButton>
          </TitleBar>
          <DivObservation>
            {observationsData.map((observation, index) => {
              return (
                <NewObservation>
                  <h4>
                    {observation.creator_name +
                      " - " +
                      observation.creator_sector}
                  </h4>
                  <div>
                    {observation.status === "DISAPPROVED" ? (
                      <PStatus>Reprovou o usuário</PStatus>
                    ) : observation.status === "APPROVED" ? (
                      <PStatus>Aprovou o usuário</PStatus>
                    ) : null}
                    {observation.text_content ? (
                      <PStatus>Motivo: {observation.text_content}</PStatus>
                    ) : null}
                    <DateStatus>
                      {formatDate(String(observation.created_date))}
                    </DateStatus>
                  </div>
                </NewObservation>
              );
            })}
          </DivObservation>
        </ContainerTwo>
      </Background>
    );
  } else {
    return null;
  }
};

export default ObservationHistory;
