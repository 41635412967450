import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import React, { ChangeEvent, useMemo } from 'react'
import ButtonCopy from 'v2/components/shared/ButtonCopy'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import { FieldProps } from '../..'

interface DateFieldProps extends FieldProps<'date', string, string> {}

const DateField: React.FC<DateFieldProps> = ({
  infos,
  value,
  onChange,
  isReadonly
}) => {
  const isUndefined = value === undefined
  const isNull = value === null
  const fieldValue = useMemo(() => {
    if (isUndefined && isReadonly) {
      return 'Não definido'
    }

    if (isUndefined || isNull) {
      return ''
    }

    return value
  }, [value, isUndefined, isNull, isReadonly])

  return (
    <FormControl
      minH={'100%'}
      display={'flex'}
      flexDir={'column'}
      fontFamily={'heading'}
    >
      <Col mb={1}>
        <FormLabel fontWeight={'bold'} mb={0}>
          {infos.label}
        </FormLabel>
        <FormLabel color={'GrayText'} mb={0} fontSize={'xs'}>
          {infos.description}
        </FormLabel>
      </Col>
      <Row pos={'relative'} alignItems={'center'} role='group' mt={'auto'}>
        <Input
          rounded={'sm'}
          value={`${fieldValue}`}
          placeholder={infos.placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChange?.(e.target.value)
          }}
          type={'date'}
          readOnly={isReadonly}
          variant={'filled'}
          fontStyle={isUndefined ? 'italic' : 'normal'}
          color={isUndefined ? 'gray.500' : 'inherit'}
          _focus={
            isReadonly
              ? {}
              : {
                  bg: 'white'
                }
          }
        />
        {/* <ButtonCopy value={value || ""} /> */}
      </Row>
    </FormControl>
  )
}

export default DateField
