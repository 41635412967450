import styled from 'styled-components'
import Modal from 'components/generic/Modal'
import GenericButton from 'components/generic/GenericButton'
import { devices } from 'helpers/devices'
import { useEffect, useState } from 'react'
import {
  InputCurrency,
  InputDate,
  InputNumber,
  InputNumberDI,
  InputNumberDocument,
  InputSelect,
  InputText
} from 'components/generic/GenericInputs'
import { OperationDocumentModel } from 'interfaces/operation_document'
import { Game, Invoice, Knowledge, MoneyOrder } from './LineOperationModal'
import { useDispatch } from 'react-redux'
import { clearGlobalMessage, setGlobalMessage } from 'redux/actions'
import AttachFile from 'components/generic/AttachFileToBase64'
import { valueConvert, valueReconvert } from 'helpers/moedas'
import { SetModel } from 'interfaces/set_model'
import { formatGame } from 'helpers/formats'
import { v4 as uuidv4 } from 'uuid'
import { useHistory } from 'react-router-dom'
import { isUnauthorized } from 'helpers/errors'
import { axiosWebsite } from 'helpers/axiosInstances'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const MinHeader = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
`

const MinContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
`

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: auto;
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 100;
  background-color: ${(props) => props.theme.color.white1};
  height: auto;
  width: 100%;

  @media ${devices.tabletPortrait} {
    align-items: flex-start;
  }

  @media ${devices.bigDesktop} {
    align-items: center;
  }
`

const TabsBlue = styled.div`
  width: 100%;
  height: auto;
  padding: 0.2rem;
  background: ${(props) => props.theme.color.primary_main};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & div {
    padding: 0.3rem 1rem;
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }
`

const ButtonTabs = styled(GenericButton)<{
  isDeny?: boolean
  isYellow?: boolean
  isBlue?: boolean
}>`
  width: auto;
  min-width: 120px;
  height: 25px;
  margin: 0.1rem 0.3rem;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  color: ${(props) => props.theme.color.white1};
  font-size: 0.9rem;
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isYellow
      ? props.theme.color.secondary_main
      : props.isBlue
      ? '#296ba9'
      : props.theme.color.button_normal} !important;

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isYellow
        ? props.theme.color.secondary_light
        : props.isBlue
        ? '#4b87be'
        : props.theme.color.button_normal_hover} !important;
  }
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`

const CardGrid = styled.div`
  width: 100%;
  height: 100%;
  max-height: 280px;
  overflow: auto;
`

const TableGridGame = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns:
    minmax(130px, 1fr) minmax(130px, 150px) minmax(130px, 150px)
    minmax(130px, 150px) minmax(130px, 150px) auto auto;
  grid-auto-rows: 35px;
  height: 100%;
  width: auto;
  overflow: auto;
`

const TableGridOrder = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns:
    minmax(170px, 1fr) minmax(140px, 170px) minmax(130px, 150px)
    minmax(140px, 170px) minmax(130px, 150px) auto auto;
  grid-auto-rows: 35px;
  height: 100%;
  width: auto;
  overflow: auto;
`

const TableGridInvoice = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns:
    minmax(170px, 1fr) minmax(140px, 170px) minmax(130px, 150px)
    minmax(140px, 170px) auto auto;
  grid-auto-rows: 35px;
  height: 100%;
  width: auto;
  overflow: auto;
`

const TableGridKnow = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: minmax(370px, 1fr) minmax(270px, 200px) auto auto;
  grid-auto-rows: 35px;
  height: 100%;
  width: auto;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

const ButtonConfirm = styled(GenericButton)<{
  isDeny?: boolean
  isDisabled?: boolean
}>`
  margin: 1rem 0.2rem;
  width: auto;
  min-width: 200px;
  color: ${(props) =>
    props.isDeny
      ? props.theme.color.white1
      : props.isDisabled
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isDisabled
      ? props.theme.color.gray3
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isDisabled
        ? props.theme.color.gray4
        : props.theme.color.secondary_light};
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }
`

const ButtonToModal = styled(GenericButton)<{
  isDeny?: boolean
  isDisabled?: boolean
}>`
  margin: 0.2rem 0;
  width: auto;
  // min-width: 200px;
  color: ${(props) =>
    props.isDeny
      ? props.theme.color.white1
      : props.isDisabled
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isDisabled
      ? props.theme.color.gray3
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isDisabled
        ? props.theme.color.gray4
        : props.theme.color.secondary_light};
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }
`

const AppliedTotalInvoice = styled.p<{ isLower?: boolean }>`
  margin: 1rem 0.2rem;
  font-size: 1rem;
  color: ${(props) => props.theme.color.primary_main};
  font-weight: bold;

  & span {
    color: ${(props) =>
      props.isLower
        ? props.theme.color.button_deny
        : props.theme.color.button_normal};
  }
`

const TieButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

type Tabs = 'Game' | 'Money-Order' | 'Invoice' | 'Knowledge'

interface Props {
  showModal: boolean
  handleModal: () => void
  gameSetList: SetModel[]
  setGameSetList: (value: SetModel[]) => void
  typeOperation: string
  lastGame: number
  currentTab: Tabs
  setCurrentTab: (value: Tabs) => void
  editValue?: OperationDocumentModel
  operationList?: OperationDocumentModel[]
  isEditOperation: boolean
  user_syscambio: string
  totalValue: number
  getUserData: () => void
  handleUnItem: (
    uuid: string,
    set_number: number,
    set_id: number,
    id?: number,
    fn?: () => void
  ) => void
}
const CreateOperation = ({
  showModal,
  handleModal,
  gameSetList,
  setGameSetList,
  typeOperation,
  lastGame,
  currentTab,
  setCurrentTab,
  editValue,
  operationList,
  isEditOperation,
  getUserData,
  handleUnItem,
  user_syscambio,
  totalValue
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [blockRef, setBlockRef] = useState<boolean>(false)
  const [valueList, setValueList] = useState<OperationDocumentModel[]>([])
  const [invoiceList, setInvoiceList] = useState<OperationDocumentModel[]>([])
  const [orderList, setOrderList] = useState<OperationDocumentModel[]>([])
  const [knowList, setKnowList] = useState<OperationDocumentModel[]>([])
  const [gameData, setGameData] = useState<SetModel>({})
  const [gameValue, setGameValue] = useState<OperationDocumentModel>({})
  const [newApplied, setNewApplied] = useState<number>(0)
  // const [nextTotalValue, setNextTotalValue] = useState<string>("");
  const [invoiceApplied, setInvoiceApplied] = useState<number>(0)
  const [totalValueRead, setTotalValueRead] = useState<boolean>(true)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  // Guardar valores temporarios/pode ser necessario tirar
  // const [temporaryTotal, setTemporaryTotal] = useState<string>("");
  const [temporaryBalance, setTemporaryBalance] = useState<string>('')

  const handleAddGame = () => {
    gameValue.uu_id = uuidv4()
    gameValue.id = undefined
    if (gameValue.document_type !== '' && gameValue.document_number !== '') {
      const findByTypeAndNumber = getDuplicatedDocuments({
        type: gameValue.document_type!,
        documentNumber: gameValue.document_number!
      })

      if (!!findByTypeAndNumber) {
        dispatch(
          setGlobalMessage({
            message: `Já existe um documento com o tipo ${findByTypeAndNumber.type} e número ${findByTypeAndNumber.documentNumber}`,
            type: 'ERROR'
          })
        )

        return
      }

      if (
        Number(valueReconvert(String(gameValue.total_value), 'ANY')) >=
        Number(valueReconvert(String(gameValue.applied_value), 'ANY'))
      ) {
        setValueList([...valueList, gameValue])
        gameDefault()
      } else {
        dispatch(
          setGlobalMessage({
            message: 'Valor total não pode ser menor do que o valor aplicado!',
            type: 'WARN'
          })
        )
      }
    } else {
      dispatch(
        setGlobalMessage({
          message: 'Preencha todos os campos!',
          type: 'WARN'
        })
      )
    }
  }

  const calculationTotalApplied = (data: OperationDocumentModel[]) => {
    const newPrice = data
      .filter(
        (e) =>
          e.applied_value !== undefined &&
          e.document_type !== 'CONHECIMENTO' &&
          e.document_type !== 'FATURA' &&
          e.document_type !== 'ORDEM DE PAGAMENTO'
      )
      .map((i) => Number(valueReconvert(String(i.applied_value), 'ANY')))

    // console.log(newPrice);
    let priceAll = 0
    for (let x = 0; x < newPrice.length; x++) {
      priceAll += newPrice[x]
    }
    // console.log(priceAll);
    setNewApplied(priceAll)
  }

  const handleAddOrder = () => {
    gameValue.uu_id = uuidv4()
    gameValue.id = undefined
    if (
      gameValue.document_number !== '' &&
      gameValue.document_value !== '' &&
      gameValue.applied_value !== '' &&
      gameValue.total_value !== '' &&
      gameValue.balance_avaliable !== ''
    ) {
      setOrderList([...orderList, gameValue])
      orderDefault()
    } else {
      dispatch(
        setGlobalMessage({
          message: 'Preencha todos os campos!',
          type: 'WARN'
        })
      )
    }
  }

  const handleAddInvoice = () => {
    gameValue.uu_id = uuidv4()
    gameValue.id = undefined
    if (
      gameValue.document_number !== '' &&
      gameValue.expiration_date !== '' &&
      gameValue.applied_value !== '' &&
      gameValue.total_value !== '' &&
      gameValue.balance_avaliable !== ''
    ) {
      setInvoiceList([...invoiceList, gameValue])
      invoiceDefault()
    } else {
      dispatch(
        setGlobalMessage({
          message: 'Preencha todos os campos!',
          type: 'WARN'
        })
      )
    }
  }

  const handleAddKnow = () => {
    gameValue.uu_id = uuidv4()
    gameValue.id = undefined
    if (
      gameValue.document_type !== '' &&
      gameValue.document_number !== '' &&
      gameValue.departure_date !== ''
    ) {
      setKnowList([...knowList, gameValue])
      knowDefault()
    } else {
      dispatch(
        setGlobalMessage({
          message: 'Preencha todos os campos!',
          type: 'WARN'
        })
      )
    }
  }

  const calculationInvoiceTotal = (data: OperationDocumentModel[]) => {
    const newPrice = data.map((i) =>
      Number(valueReconvert(String(i.applied_value), 'ANY'))
    )

    // console.log(newPrice);
    let priceAll = 0
    for (let x = 0; x < newPrice.length; x++) {
      priceAll += newPrice[x]
    }
    // console.log(priceAll);
    setInvoiceApplied(priceAll)
  }

  const gameDefault = () => {
    setGameValue({
      ...gameValue,
      document_type: '',
      document_number: undefined,
      document_value: undefined,
      applied_value: undefined,
      total_value: undefined,
      document_key: undefined,
      document_reference: undefined,
      document_issue: undefined,
      document_company: undefined,
      document_endorsement: undefined,
      document_additions: undefined,
      expiration_date: undefined,
      departure_date: undefined,
      balance_avaliable: undefined,
      attached_file: undefined,
      attached_name: undefined,
      attached: undefined
    })
    setTemporaryBalance('')
  }

  const invoiceDefault = () => {
    setGameValue({
      ...gameValue,
      document_type: 'FATURA',
      document_number: undefined,
      document_value: undefined,
      applied_value: undefined,
      total_value: undefined,
      document_key: undefined,
      document_reference: undefined,
      document_issue: undefined,
      document_company: undefined,
      document_endorsement: undefined,
      document_additions: undefined,
      expiration_date: undefined,
      departure_date: undefined,
      balance_avaliable: undefined,
      attached_file: undefined,
      attached_name: undefined,
      attached: undefined
    })
  }

  const orderDefault = () => {
    setGameValue({
      ...gameValue,
      document_type: 'ORDEM DE PAGAMENTO',
      document_number: undefined,
      document_value: undefined,
      applied_value: undefined,
      total_value: undefined,
      document_key: undefined,
      document_reference: undefined,
      document_issue: undefined,
      document_company: undefined,
      document_endorsement: undefined,
      document_additions: undefined,
      expiration_date: undefined,
      departure_date: undefined,
      balance_avaliable: undefined,
      attached_file: undefined,
      attached_name: undefined,
      attached: undefined
    })
    setTemporaryBalance('')
  }

  const knowDefault = () => {
    setGameValue({
      ...gameValue,
      document_type: 'CONHECIMENTO',
      document_number: undefined,
      document_value: undefined,
      applied_value: undefined,
      total_value: undefined,
      document_key: undefined,
      document_reference: undefined,
      document_issue: undefined,
      document_company: undefined,
      document_endorsement: undefined,
      document_additions: undefined,
      expiration_date: undefined,
      departure_date: undefined,
      balance_avaliable: undefined,
      attached_file: undefined,
      attached_name: undefined,
      attached: undefined
    })
    setTemporaryBalance('')
  }

  const handleSubmit = () => {
    setGameSetList([...gameSetList, gameData])
    closeModal()
  }

  const closeModal = () => {
    setBlockRef(false)
    setIsEdit(false)
    setGameData({})
    gameDefault()
    setValueList([])
    setOrderList([])
    setKnowList([])
    setInvoiceList([])
    setNewApplied(0)
    setInvoiceApplied(0)
    setCurrentTab('Game')
    setTemporaryBalance('')
    handleModal()
    // console.log(gameData, gameSetList);
  }

  const handleUnItemHere = (index: number, whats: string) => {
    dispatch(
      setGlobalMessage({
        message: 'Deseja realmente excluir este item?',
        type: 'MESSAGE',
        buttons: (
          <>
            <ButtonToModal
              onClick={() => {
                switch (whats) {
                  case 'Game':
                    let res = [...valueList]
                    res.splice(index, 1)
                    setValueList(res)
                    break

                  case 'Know':
                    let res2 = [...knowList]
                    res2.splice(index, 1)
                    setKnowList(res2)
                    break

                  case 'Order':
                    let res3 = [...orderList]
                    res3.splice(index, 1)
                    // console.log(orderList, res);
                    setOrderList(res3)
                    break

                  case 'Invoice':
                    let res4 = [...invoiceList]
                    res4.splice(index, 1)
                    setInvoiceList(res4)
                    break

                  default:
                    break
                }
                dispatch(clearGlobalMessage())
              }}
            >
              Ok
            </ButtonToModal>
            <ButtonToModal
              isDeny
              onClick={() => {
                dispatch(clearGlobalMessage())
              }}
            >
              Cancelar
            </ButtonToModal>
          </>
        )
      })
    )
  }

  const unItemHere = (index: number, whats: string) => {
    switch (whats) {
      case 'Game':
        let res = [...valueList]
        res.splice(index, 1)
        setValueList(res)
        break

      case 'Know':
        let res2 = [...knowList]
        res2.splice(index, 1)
        setKnowList(res2)
        break

      case 'Order':
        let res3 = [...orderList]
        res3.splice(index, 1)
        // console.log(orderList, res);
        setOrderList(res3)
        break

      case 'Invoice':
        let res4 = [...invoiceList]
        res4.splice(index, 1)
        setInvoiceList(res4)
        break

      default:
        break
    }
  }

  const calculationDoc = () => {
    let regex =
      /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)(\.[0-9]{1,2})?$/
    let priceAll = 0
    if (regex.test(String(gameValue.total_value))) {
      console.log('TIPO: Numerico? ' + gameValue.total_value)
      priceAll += Number(gameValue.total_value)
    } else {
      // console.log("TIPO: String? " + gameValue.total_value);
      priceAll += Number(valueReconvert(String(gameValue.total_value), 'ANY'))
    }
    if (regex.test(String(gameValue.applied_value))) {
      // console.log("TIPO: Numerico? " + gameValue.applied_value);
      priceAll -= Number(gameValue.applied_value)
    } else {
      // console.log("TIPO: String? " + gameValue.applied_value);
      priceAll -= Number(valueReconvert(String(gameValue.applied_value), 'ANY'))
    }
    // console.log(priceAll);
    setGameValue({
      ...gameValue,
      balance_avaliable: String(priceAll)
    })
  }

  const calculationUsedDoc = () => {
    let regex =
      /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)(\.[0-9]{1,2})?$/
    let priceAll = 0
    if (regex.test(String(temporaryBalance))) {
      // console.log("TIPO: Numerico? " + temporaryBalance);
      priceAll += Number(temporaryBalance)
    } else {
      // console.log("TIPO: String? " + temporaryBalance);
      priceAll += Number(valueReconvert(String(temporaryBalance), 'ANY'))
    }
    if (regex.test(String(gameValue.applied_value))) {
      // console.log("TIPO: Numerico? " + gameValue.applied_value);
      priceAll -= Number(gameValue.applied_value)
    } else {
      // console.log("TIPO: String? " + gameValue.applied_value);
      priceAll -= Number(valueReconvert(String(gameValue.applied_value), 'ANY'))
    }
    // console.log(priceAll);
    setGameValue({
      ...gameValue,
      balance_avaliable: String(priceAll)
    })
  }

  const handleEditItem = (whats: string) => {
    switch (whats) {
      case 'Game':
        const res = valueList.map(
          (e) => [gameValue].find((o) => o.uu_id === e.uu_id) || e
        )
        setValueList(res)
        gameDefault()
        setIsEdit(false)
        break
      case 'Know':
        const res2 = knowList.map(
          (e) => [gameValue].find((o) => o.uu_id === e.uu_id) || e
        )
        setKnowList(res2)
        knowDefault()
        setIsEdit(false)
        break
      case 'Order':
        const res3 = orderList.map(
          (e) => [gameValue].find((o) => o.uu_id === e.uu_id) || e
        )
        setOrderList(res3)
        orderDefault()
        setIsEdit(false)
        break
      case 'Invoice':
        const res4 = invoiceList.map(
          (e) => [gameValue].find((o) => o.uu_id === e.uu_id) || e
        )
        setInvoiceList(res4)
        invoiceDefault()
        setIsEdit(false)
        break
      default:
        break
    }
  }

  const cancelEdit = (whats: string) => {
    switch (whats) {
      case 'Game':
        gameDefault()
        setIsEdit(false)
        break
      case 'Know':
        knowDefault()
        setIsEdit(false)
        break
      case 'Order':
        orderDefault()
        setIsEdit(false)
        break
      case 'Invoice':
        invoiceDefault()
        setIsEdit(false)
        break
      default:
        break
    }
  }

  const getDuplicatedDocuments = (props: {
    type: string
    documentNumber: string
  }) => {
    const documentsNumbersByType: Array<{
      type: string
      documentNumber: string
    }> = []

    for (const gameSet of gameSetList) {
      for (const operation of gameSet.operation_documents || []) {
        documentsNumbersByType.push({
          type: operation.document_type!,
          documentNumber: operation.document_number!
        })
      }
    }

    for (const operation of valueList) {
      documentsNumbersByType.push({
        documentNumber: operation.document_number!,
        type: operation.document_type!
      })
    }

    return documentsNumbersByType.find(({ documentNumber, type }) => {
      return type === props.type && documentNumber === props.documentNumber
    })
  }

  const validBalance = async () => {
    console.log('valida balance')

    try {
      const body = {
        document: {
          user_syscambio: Number(user_syscambio),
          document_number: gameValue.document_number,
          document_type: gameValue.document_type
        }
      }

      const findByTypeAndNumber = getDuplicatedDocuments({
        type: gameValue.document_type!,
        documentNumber: gameValue.document_number!
      })

      if (!!findByTypeAndNumber) {
        dispatch(
          setGlobalMessage({
            message: `Já existe um documento com o tipo ${findByTypeAndNumber.type} e número ${findByTypeAndNumber.documentNumber}`,
            type: 'ERROR'
          })
        )

        return
      }
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const res = await axiosWebsite.post(`/validasaldo`, body, config)

      switch (res.data.flag) {
        case 'newDoc':
          setTotalValueRead(false)
          break
        case 'insufficientFunds':
          setTotalValueRead(true)
          break
        case 'alreadyUsed':
          setTotalValueRead(true)
          break
        case 'otherClient':
          dispatch(
            setGlobalMessage({
              message: `${res.data.message}`,
              type: 'WARN'
            })
          )
          gameDefault()
          setTotalValueRead(true)
          break
        default:
          setTotalValueRead(true)
          // setTemporaryTotal(
          //   valueConvert(Number(res.data.total_document), "ANY")
          // );
          setTemporaryBalance(
            valueConvert(Number(res.data.saldo_document), 'ANY')
          )
          setGameValue({
            ...gameValue,
            applied_value: '0',
            total_value: res.data.total_document
            // balance_avaliable: res.data.saldo_document,
          })

          break
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao consultar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    }
  }

  const handleEdit = () => {
    const res = gameSetList.map(
      (e) => [gameData].find((o) => o.set_number === e.set_number) || e
    )

    // console.log(res);
    setGameSetList(res)
    closeModal()
  }

  const handleCreateLits = () => {
    if (editValue) {
      if (operationList) {
        setValueList(
          operationList.filter(
            (e) =>
              e.document_type !== 'ORDEM DE PAGAMENTO' &&
              e.document_type !== 'CONHECIMENTO' &&
              e.document_type !== 'FATURA' &&
              e.set_number === editValue.set_number
          )
        )
        setOrderList(
          operationList.filter(
            (e) =>
              e.document_type === 'ORDEM DE PAGAMENTO' &&
              e.set_number === editValue.set_number
          )
        )
        setKnowList(
          operationList.filter(
            (e) =>
              e.document_type === 'CONHECIMENTO' &&
              e.set_number === editValue.set_number
          )
        )
        setInvoiceList(
          operationList.filter(
            (e) =>
              e.document_type === 'FATURA' &&
              e.set_number === editValue.set_number
          )
        )
      }
    }
  }

  // const handleEditDocument = async () => {
  //   // const body = {
  //   //   operation_document: gameData.operation_documents,
  //   // };
  //   // console.log(body);
  //   try {
  //     dispatch(
  //   showLoading({
  //     message: "Carregando",
  //     isLoading: true,
  //   })
  // );

  //     // const config = {
  //     //   headers: {
  //     //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //     //   },
  //     // };

  //     const body = {
  //       operation_document: gameData.operation_documents,
  //     };

  //     await axiosWebsite.post(`/operationdoc`, body);

  //     getUserData();
  //   } catch (error: any) {
  //     if (isUnauthorized(error)) {
  //       history.push("/unauthorized");
  //     } else {
  //       dispatch(
  //         setGlobalMessage({
  //           message: `Ocorreu um erro ao enviar os dados: ${error}`,
  //           type: "ERROR",
  //         })
  //       );
  //     }
  //   } finally {
  //     dispatch(
  //   showLoading({
  //     message: "Carregando",
  //     isLoading: true,
  //   })
  // );
  //   }
  // };

  useEffect(() => {
    if (!totalValueRead) {
      calculationDoc()
    } else {
      calculationUsedDoc()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameValue.applied_value, gameValue.total_value])

  useEffect(() => {
    if (showModal === true) {
      // console.log(lastGame);
      setGameData({
        ...gameData,
        set_number: lastGame + 1
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  useEffect(() => {
    if (invoiceList.length !== 0) {
      calculationInvoiceTotal(invoiceList)
    }
    if (orderList.length !== 0) {
      calculationInvoiceTotal(orderList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderList, invoiceList])

  useEffect(() => {
    setGameData({
      ...gameData,
      set_value: valueConvert(newApplied, 'ANY'),
      operation_documents: [
        ...valueList,
        ...orderList,
        ...knowList,
        ...invoiceList
      ]
    })
    // console.log(gameData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueList, orderList, knowList, invoiceList])

  useEffect(() => {
    setGameData({
      ...gameData,
      set_value: valueConvert(newApplied, 'ANY')
    })
    // setNextTotalValue(valueConvert(totalValue - newApplied, "ANY"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newApplied])

  useEffect(() => {
    calculationTotalApplied(valueList)
    // console.log(operationList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueList])

  useEffect(() => {
    if (showModal) {
      if (editValue) {
        setGameValue(editValue)
        setGameData(
          gameSetList.filter((e) => e.set_number === editValue.set_number)[0]
        )
        setIsEdit(true)
        handleCreateLits()
      }
    }
    // console.log(gameValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  return (
    <Modal
      title={
        editValue
          ? typeOperation === 'Shipping'
            ? 'Alterar jogo de documentos - Remessa'
            : typeOperation === 'Ticket'
            ? 'Alterar jogo de documentos - Ingresso'
            : typeOperation === 'Import'
            ? 'Alterar jogo de documentos - Importação'
            : typeOperation === 'Export'
            ? 'Alterar jogo de documentos - Exportação'
            : ''
          : typeOperation === 'Shipping'
          ? 'Novo jogo de documentos - Remessa'
          : typeOperation === 'Ticket'
          ? 'Novo jogo de documentos - Ingresso'
          : typeOperation === 'Import'
          ? 'Novo jogo de documentos - Importação'
          : typeOperation === 'Export'
          ? 'Novo jogo de documentos - Exportação'
          : ''
      }
      showModal={showModal}
      close={() => closeModal()}
      backFocus
      width='100%'
      maxWidth='950px'
      height='100%'
      maxHeight='85vh'
    >
      <Container>
        <MinHeader>
          <ItemRowTop>
            <InputText
              label='Jogo'
              maxWidth='170px'
              value={formatGame(Number(gameData.set_number))}
              changeValue={(value) =>
                setGameData({
                  ...gameData,
                  set_number: Number(value)
                })
              }
              readOnly
            />
            <InputText
              label='Referência'
              maxWidth='200px'
              value={gameData.set_reference}
              changeValue={(value) =>
                setGameData({
                  ...gameData,
                  set_reference: value
                })
              }
              // readOnly={blockRef}
            />
            <InputCurrency
              label='Valor da Aplicação do jogo'
              maxWidth='400px'
              value={gameData.set_value}
              changeValue={(value) =>
                setGameData({
                  ...gameData,
                  set_value: String(newApplied)
                })
              }
              readOnly
            />
            <InputCurrency
              label='Total a Aplicar'
              maxWidth='400px'
              value={valueConvert(totalValue, 'ANY')}
              readOnly
            />
          </ItemRowTop>
        </MinHeader>
        <TabsContainer>
          <TabsBlue>
            <div>
              <ButtonTabs
                isBlue={currentTab !== 'Game'}
                isYellow={currentTab === 'Game'}
                type='button'
                onClick={() => {
                  gameDefault()
                  setIsEdit(false)
                  setCurrentTab('Game')
                }}
              >
                Jogo
              </ButtonTabs>
              <ButtonTabs
                isBlue={currentTab !== 'Invoice'}
                isYellow={currentTab === 'Invoice'}
                type='button'
                onClick={() => {
                  invoiceDefault()
                  setIsEdit(false)
                  setCurrentTab('Invoice')
                }}
              >
                Fatura
              </ButtonTabs>
              {typeOperation === 'Ticket' || typeOperation === 'Export' ? (
                <ButtonTabs
                  isBlue={currentTab !== 'Money-Order'}
                  isYellow={currentTab === 'Money-Order'}
                  type='button'
                  onClick={() => {
                    orderDefault()
                    setIsEdit(false)
                    setCurrentTab('Money-Order')
                  }}
                >
                  Ordem de pagamento
                </ButtonTabs>
              ) : null}
              <ButtonTabs
                isBlue={currentTab !== 'Knowledge'}
                isYellow={currentTab === 'Knowledge'}
                type='button'
                onClick={() => {
                  knowDefault()
                  setIsEdit(false)
                  setCurrentTab('Knowledge')
                }}
              >
                Conhecimento
              </ButtonTabs>
            </div>
            <div>
              {editValue ? (
                <ButtonTabs type='button' onClick={() => handleEdit()}>
                  Salvar Alterações
                </ButtonTabs>
              ) : (
                <ButtonTabs type='button' onClick={() => handleSubmit()}>
                  Enviar Documento
                </ButtonTabs>
              )}
            </div>
          </TabsBlue>
        </TabsContainer>
        <MinContainer>
          {currentTab === 'Game' ? (
            <>
              <ItemRow>
                <InputSelect
                  label='Tipo de Documento'
                  maxWidth='250px'
                  value={gameValue.document_type}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      document_type: value
                    })
                  }
                  required
                  readOnly={isEdit}
                >
                  <option value='' disabled selected>
                    {' '}
                  </option>
                  {typeOperation === 'Shipping' ? (
                    <>
                      <option value='DUE'>DUE</option>
                      <option value='APÓLICE'>APÓLICE</option>
                      <option value='NOTA DE DÉBITO'>NOTA DE DÉBITO</option>
                      <option value='NOTA DE CRÉDITO'>NOTA DE CRÉDITO</option>
                    </>
                  ) : null}
                  {typeOperation === 'Ticket' ? (
                    <>
                      <option value='DUE'>DUE</option>
                      <option value='APÓLICE'>APÓLICE</option>
                      <option value='NOTA DE DÉBITO'>NOTA DE DÉBITO</option>
                      <option value='NOTA DE CRÉDITO'>NOTA DE CRÉDITO</option>
                    </>
                  ) : null}
                  {typeOperation === 'Import' ? (
                    <>
                      <option value='DUIMP'>DUIMP</option>
                      <option value='DI'>DI</option>
                      <option value='DSI'>DSI</option>
                      <option value='LI'>LI</option>
                    </>
                  ) : null}
                  {typeOperation === 'Export' ? (
                    <>
                      <option value='DUE'>DUE</option>
                      <option value='DRE'>DRE</option>
                    </>
                  ) : null}
                </InputSelect>
                {gameValue.document_type === 'DUE' ||
                gameValue.document_type === 'APÓLICE' ||
                gameValue.document_type === 'NOTA DE DÉBITO' ||
                gameValue.document_type === 'NOTA DE CRÉDITO' ||
                gameValue.document_type === 'DUIMP' ||
                gameValue.document_type === 'DSI' ||
                gameValue.document_type === 'DRE' ? (
                  <InputNumberDocument
                    label='Número'
                    maxWidth='250px'
                    value={gameValue.document_number}
                    validBalance={validBalance}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        document_number: value
                      })
                    }
                    required
                  />
                ) : null}
                {gameValue.document_type === 'DI' ||
                gameValue.document_type === 'LI' ? (
                  <InputNumberDI
                    label='Número'
                    maxWidth='250px'
                    value={gameValue.document_number}
                    validBalance={validBalance}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        document_number: value
                      })
                    }
                    required
                  />
                ) : null}
              </ItemRow>
              <ItemRow>
                {gameValue.document_type === 'DUE' ||
                gameValue.document_type === 'DUIMP' ? (
                  <InputText
                    label='Chave'
                    maxWidth='250px'
                    value={gameValue.document_key}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        document_key: value
                      })
                    }
                    required
                  />
                ) : null}
                {gameValue.document_type === 'DUE' ||
                gameValue.document_type === 'APÓLICE' ||
                gameValue.document_type === 'NOTA DE DÉBITO' ||
                gameValue.document_type === 'NOTA DE CRÉDITO' ||
                gameValue.document_type === 'DI' ||
                gameValue.document_type === 'DUIMP' ||
                gameValue.document_type === 'DSI' ||
                gameValue.document_type === 'LI' ||
                gameValue.document_type === 'DRE' ? (
                  <InputText
                    label='Referência'
                    maxWidth='250px'
                    value={gameValue.document_reference}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        document_reference: value
                      })
                    }
                    required
                  />
                ) : null}
                {gameValue.document_type === 'APÓLICE' ? (
                  <InputDate
                    label='Emissão'
                    maxWidth='250px'
                    value={gameValue.document_issue}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        document_issue: value
                      })
                    }
                    required
                  />
                ) : null}
                {gameValue.document_type === 'DI' ? (
                  <InputText
                    label='Adições'
                    maxWidth='250px'
                    value={gameValue.document_additions}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        document_additions: value
                      })
                    }
                    required
                  />
                ) : null}
              </ItemRow>
              <ItemRow>
                {gameValue.document_type === 'DUE' ||
                gameValue.document_type === 'DI' ||
                gameValue.document_type === 'DUIMP' ||
                gameValue.document_type === 'DSI' ||
                gameValue.document_type === 'LI' ||
                gameValue.document_type === 'DRE' ? (
                  <InputCurrency
                    label='Valor Aplicado'
                    maxWidth='200px'
                    value={gameValue.applied_value}
                    maxValue={gameValue.total_value}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        applied_value: value
                      })
                    }
                    required
                  />
                ) : null}
                {gameValue.document_type === 'DUE' ||
                gameValue.document_type === 'DI' ||
                gameValue.document_type === 'DUIMP' ||
                gameValue.document_type === 'DSI' ||
                gameValue.document_type === 'LI' ||
                gameValue.document_type === 'DRE' ? (
                  <InputCurrency
                    label='Valor Total'
                    maxWidth='200px'
                    value={gameValue.total_value}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        total_value: value
                      })
                    }
                    required
                    readOnly={totalValueRead}
                  />
                ) : null}
                {gameValue.document_type === 'DUE' ||
                gameValue.document_type === 'DI' ||
                gameValue.document_type === 'DUIMP' ||
                gameValue.document_type === 'DSI' ||
                gameValue.document_type === 'LI' ||
                gameValue.document_type === 'DRE' ? (
                  <InputCurrency
                    label='Saldo'
                    maxWidth='200px'
                    value={gameValue.balance_avaliable}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        balance_avaliable: value
                      })
                    }
                    readOnly
                  />
                ) : null}
              </ItemRow>
              <ItemRow>
                {gameValue.document_type === 'APÓLICE' ? (
                  <InputText
                    label='Companhia'
                    maxWidth='200px'
                    value={gameValue.document_company}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        document_company: value
                      })
                    }
                    required
                  />
                ) : null}
                {gameValue.document_type === 'APÓLICE' ? (
                  <InputText
                    label='Endosso'
                    maxWidth='450px'
                    value={gameValue.document_endorsement}
                    changeValue={(value) =>
                      setGameValue({
                        ...gameValue,
                        document_endorsement: value
                      })
                    }
                    required
                  />
                ) : null}
              </ItemRow>
              <ItemRow>
                {gameValue.document_type === 'DUE' ||
                gameValue.document_type === 'APÓLICE' ||
                gameValue.document_type === 'NOTA DE DÉBITO' ||
                gameValue.document_type === 'NOTA DE CRÉDITO' ||
                gameValue.document_type === 'DI' ||
                gameValue.document_type === 'DUIMP' ||
                gameValue.document_type === 'DSI' ||
                gameValue.document_type === 'LI' ||
                gameValue.document_type === 'DRE' ? (
                  <AttachFile
                    newId='game'
                    title=''
                    value={gameValue.attached_file!}
                    changeValue={(value, name, type) =>
                      setGameValue({
                        ...gameValue,
                        attached_file: value,
                        attached_name: name,
                        attached: type
                      })
                    }
                  />
                ) : null}
              </ItemRow>
              <TieButton>
                {isEdit === true ? (
                  <>
                    <ButtonConfirm
                      type='button'
                      isDeny
                      onClick={() =>
                        blockRef !== true ? cancelEdit('Game') : null
                      }
                    >
                      Cancelar
                    </ButtonConfirm>
                    <ButtonConfirm
                      type='button'
                      isDisabled={blockRef}
                      onClick={() =>
                        blockRef !== true ? handleEditItem('Game') : null
                      }
                    >
                      Atualizar jogo
                    </ButtonConfirm>
                  </>
                ) : (
                  <ButtonConfirm
                    type='button'
                    isDisabled={blockRef}
                    // onClick={() => console.log(gameValue)}
                    onClick={() => (blockRef !== true ? handleAddGame() : null)}
                  >
                    Adicionar jogo
                  </ButtonConfirm>
                )}
              </TieButton>
              <CardGrid>
                <TableGridGame>
                  <GridTitle>
                    <Text fontWeight='bold'>Documento</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Número</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Aplicação</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Valor Total</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Saldo Disp.</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Anexo</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Ações</Text>
                  </GridTitle>
                  {/*Mudar depois*/}
                  {valueList
                    .filter(
                      (value) =>
                        value.document_type !== 'ORDEM DE PAGAMENTO' &&
                        value.document_type !== 'CONHECIMENTO' &&
                        value.document_type !== 'FATURA'
                    )
                    .map((value, index) => {
                      let last = false
                      if (index === valueList.length - 1) {
                        last = true
                      }
                      return (
                        <Game
                          last={last}
                          index={index}
                          value={value}
                          handleUnItem={handleUnItem}
                          handleUnItemHere={handleUnItemHere}
                          unItemHere={unItemHere}
                          setGameValue={setGameValue}
                          setIsEdit={setIsEdit}
                          isEditOperation={isEditOperation}
                        />
                      )
                    })}
                </TableGridGame>
              </CardGrid>
            </>
          ) : null}
          {currentTab === 'Money-Order' ? (
            <>
              <ItemRow>
                <InputNumberDocument
                  label='Ordem'
                  maxWidth='200px'
                  value={gameValue.document_number}
                  validBalance={validBalance}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      document_number: value
                    })
                  }
                  required
                />
                <InputNumber
                  label='Despesa'
                  maxWidth='200px'
                  value={gameValue.document_value}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      document_value: value
                    })
                  }
                  required
                />
              </ItemRow>
              <ItemRow>
                <InputCurrency
                  label='Valor Aplicado'
                  maxWidth='200px'
                  value={gameValue.applied_value}
                  maxValue={gameValue.total_value}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      applied_value: value
                    })
                  }
                  required
                />
                <InputCurrency
                  label='Valor Total'
                  maxWidth='200px'
                  value={gameValue.total_value}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      total_value: value
                    })
                  }
                  required
                />
                <InputCurrency
                  label='Saldo'
                  maxWidth='200px'
                  value={gameValue.balance_avaliable}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      balance_avaliable: value
                    })
                  }
                  required
                  readOnly
                />
              </ItemRow>
              <ItemRow>
                <AttachFile
                  newId='order'
                  title=''
                  value={gameValue.attached_file!}
                  changeValue={(value, name, type) =>
                    setGameValue({
                      ...gameValue,
                      attached_file: value,
                      attached_name: name,
                      attached: type
                    })
                  }
                />
              </ItemRow>
              <ItemRowTop>
                <AppliedTotalInvoice
                  isLower={
                    invoiceApplied <
                    Number(valueReconvert(String(gameData.set_value), 'ANY'))
                  }
                >
                  Total Aplicações:{' '}
                  <span>{valueConvert(invoiceApplied, 'ANY')}</span>
                </AppliedTotalInvoice>
              </ItemRowTop>
              <TieButton>
                {isEdit === true ? (
                  <>
                    <ButtonConfirm
                      type='button'
                      isDeny
                      onClick={() =>
                        blockRef !== true ? cancelEdit('Order') : null
                      }
                    >
                      Cancelar
                    </ButtonConfirm>
                    <ButtonConfirm
                      type='button'
                      isDisabled={blockRef}
                      onClick={() =>
                        blockRef !== true ? handleEditItem('Order') : null
                      }
                    >
                      Atualizar Pagamento
                    </ButtonConfirm>
                  </>
                ) : (
                  <ButtonConfirm type='button' onClick={() => handleAddOrder()}>
                    Adicionar Pagamento
                  </ButtonConfirm>
                )}
              </TieButton>
              <CardGrid>
                <TableGridOrder>
                  <GridTitle>
                    <Text fontWeight='bold'>Ordem</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Despesa</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Aplicação</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Valor Total</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Saldo Disp.</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Anexo</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Ações</Text>
                  </GridTitle>
                  {/*Mudar depois*/}
                  {orderList
                    .filter(
                      (value) => value.document_type === 'ORDEM DE PAGAMENTO'
                    )
                    .map((value, index) => {
                      let last = false
                      if (index === orderList.length - 1) {
                        last = true
                      }
                      return (
                        <MoneyOrder
                          last={last}
                          index={index}
                          value={value}
                          handleUnItem={handleUnItem}
                          handleUnItemHere={handleUnItemHere}
                          unItemHere={unItemHere}
                          setGameValue={setGameValue}
                          setIsEdit={setIsEdit}
                          isEditOperation={isEditOperation}
                        />
                      )
                    })}
                </TableGridOrder>
              </CardGrid>
            </>
          ) : null}
          {currentTab === 'Invoice' ? (
            <>
              <ItemRow>
                <InputNumberDocument
                  label='Fatura'
                  maxWidth='200px'
                  value={gameValue.document_number}
                  validBalance={validBalance}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      document_number: value
                    })
                  }
                  required
                />
                <InputDate
                  label='Data de Vencimento'
                  maxWidth='200px'
                  value={gameValue.expiration_date}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      expiration_date: value
                    })
                  }
                  required
                />
              </ItemRow>
              <ItemRow>
                <InputCurrency
                  label='Valor da Aplicação'
                  maxWidth='200px'
                  value={gameValue.applied_value}
                  maxValue={gameValue.total_value}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      applied_value: value
                    })
                  }
                  required
                />
                <InputCurrency
                  label='Valor Total'
                  maxWidth='200px'
                  value={gameValue.total_value}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      total_value: value
                    })
                  }
                  required
                />
                <InputCurrency
                  label='Saldo'
                  maxWidth='200px'
                  value={gameValue.balance_avaliable}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      balance_avaliable: value
                    })
                  }
                  required
                  readOnly
                />
              </ItemRow>
              <ItemRow>
                <AttachFile
                  newId='invoice'
                  title=''
                  value={gameValue.attached_file!}
                  changeValue={(value, name, type) =>
                    setGameValue({
                      ...gameValue,
                      attached_file: value,
                      attached_name: name,
                      attached: type
                    })
                  }
                />
              </ItemRow>
              <ItemRowTop>
                <AppliedTotalInvoice
                  isLower={
                    invoiceApplied <
                    Number(valueReconvert(String(gameData.set_value), 'ANY'))
                  }
                >
                  Total Aplicações:{' '}
                  <span>{valueConvert(invoiceApplied, 'ANY')}</span>
                </AppliedTotalInvoice>
              </ItemRowTop>
              <TieButton>
                {isEdit === true ? (
                  <>
                    <ButtonConfirm
                      type='button'
                      isDeny
                      onClick={() =>
                        blockRef !== true ? cancelEdit('Invoice') : null
                      }
                    >
                      Cancelar
                    </ButtonConfirm>
                    <ButtonConfirm
                      type='button'
                      isDisabled={blockRef}
                      onClick={() =>
                        blockRef !== true ? handleEditItem('Invoice') : null
                      }
                    >
                      Atualizar Fatura
                    </ButtonConfirm>
                  </>
                ) : (
                  <ButtonConfirm
                    type='button'
                    onClick={() => handleAddInvoice()}
                  >
                    Adicionar Fatura
                  </ButtonConfirm>
                )}
              </TieButton>

              <CardGrid>
                <TableGridInvoice>
                  <GridTitle>
                    <Text fontWeight='bold'>Fatura</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Valor da Aplicação</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Saldo Disp.</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Vencimento</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Anexo</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Ações</Text>
                  </GridTitle>
                  {/*Mudar depois*/}
                  {invoiceList
                    .filter((value) => value.document_type === 'FATURA')
                    .map((value, index) => {
                      let last = false
                      if (index === invoiceList.length - 1) {
                        last = true
                      }
                      return (
                        <Invoice
                          last={last}
                          index={index}
                          value={value}
                          handleUnItem={handleUnItem}
                          handleUnItemHere={handleUnItemHere}
                          unItemHere={unItemHere}
                          setGameValue={setGameValue}
                          setIsEdit={setIsEdit}
                          isEditOperation={isEditOperation}
                        />
                      )
                    })}
                </TableGridInvoice>
              </CardGrid>
            </>
          ) : null}
          {currentTab === 'Knowledge' ? (
            <>
              <ItemRow>
                <InputText
                  label='Conhecimento'
                  maxWidth='350px'
                  value={gameValue.document_number}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      document_number: value
                    })
                  }
                  required
                />
              </ItemRow>
              <ItemRow>
                <InputDate
                  label='Data de Embarque'
                  maxWidth='200px'
                  value={gameValue.departure_date}
                  changeValue={(value) =>
                    setGameValue({
                      ...gameValue,
                      departure_date: value
                    })
                  }
                  required
                />
              </ItemRow>
              <ItemRow>
                <AttachFile
                  newId='conhecimento'
                  title=''
                  value={gameValue.attached_file!}
                  changeValue={(value, name, type) =>
                    setGameValue({
                      ...gameValue,
                      attached_file: value,
                      attached_name: name,
                      attached: type
                    })
                  }
                />
              </ItemRow>
              <TieButton>
                {isEdit === true ? (
                  <>
                    <ButtonConfirm
                      type='button'
                      isDeny
                      onClick={() =>
                        blockRef !== true ? cancelEdit('Know') : null
                      }
                    >
                      Cancelar
                    </ButtonConfirm>
                    <ButtonConfirm
                      type='button'
                      isDisabled={blockRef}
                      onClick={() =>
                        blockRef !== true ? handleEditItem('Know') : null
                      }
                    >
                      Atualizar Conhecimento
                    </ButtonConfirm>
                  </>
                ) : (
                  <ButtonConfirm type='button' onClick={() => handleAddKnow()}>
                    Adicionar Conhecimento
                  </ButtonConfirm>
                )}
              </TieButton>
              <CardGrid>
                <TableGridKnow>
                  <GridTitle>
                    <Text fontWeight='bold'>Conhecimento</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Data de Embarque</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Anexo</Text>
                  </GridTitle>
                  <GridTitle>
                    <Text fontWeight='bold'>Ações</Text>
                  </GridTitle>
                  {/*Mudar depois*/}
                  {knowList
                    .filter((value) => value.document_type === 'CONHECIMENTO')
                    .map((value, index) => {
                      let last = false
                      if (index === knowList.length - 1) {
                        last = true
                      }
                      return (
                        <Knowledge
                          last={last}
                          index={index}
                          value={value}
                          handleUnItem={handleUnItem}
                          handleUnItemHere={handleUnItemHere}
                          unItemHere={unItemHere}
                          setGameValue={setGameValue}
                          setIsEdit={setIsEdit}
                          isEditOperation={isEditOperation}
                        />
                      )
                    })}
                </TableGridKnow>
              </CardGrid>
            </>
          ) : null}
        </MinContainer>
      </Container>
    </Modal>
  )
}

export default CreateOperation
