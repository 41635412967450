import styled from 'styled-components'
import { devices } from 'helpers/devices'
import GenericButton from 'components/generic/GenericButton'
import { InputImgBase64 } from 'components/generic/GenericInputs'
import { useEffect, useMemo, useState } from 'react'
import { UserModel } from 'interfaces/user'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading
} from 'redux/actions'
import { isUnauthorized } from 'helpers/errors'
import { axiosAuth } from 'helpers/axiosInstances'
import { BankModel } from 'interfaces/bank'
import { BeneficiaryModel } from 'interfaces/beneficiary'
import RegisterLegal from './subComponents/RegisterLegal'
import DocumentationLegal from './subComponents/DocumentationLegal'
import BankAccountLegal from './subComponents/BankAccountLegal'
import PartnerBeneficiaryLegal from './subComponents/PartnerBeneficiaryLegal'
import { GlobalState } from 'redux/types'
import { ArchiveModel } from 'interfaces/user-archives'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { Permissions } from 'interfaces/web/permission'

const LegalStyled = styled.section`
  background: transparent;
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  /* overflow-y: auto; */
`

const Container = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */

  @media ${devices.phoneOnly} {
    background-position: center left;
  }
`

const TemplateFlex = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem ${(props) => props.theme.margins.from_sides};

  @media ${devices.phoneOnly} {
    padding: 1rem ${(props) => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${(props) => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 2rem ${(props) => props.theme.margins.from_sides};
  }
`

const CardForm = styled.form`
  padding: 1rem;
  box-shadow: 1px 2px 4px gray;
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
`

const CardMenu = styled.div`
  width: 300px;
  min-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

const CardItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.5rem;
  overflow-y: auto;
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 100;
  background-color: ${(props) => props.theme.color.white1};
  height: auto;
  width: 100%;
  position: sticky;
  top: -1px;
  left: 0;
  padding: 0.3rem;

  @media ${devices.tabletPortrait} {
    align-items: flex-start;
  }

  @media ${devices.bigDesktop} {
    align-items: center;
  }
`

const TabsBlue = styled.div`
  width: 100%;
  height: auto;
  padding: 0.2rem;
  background: ${(props) => props.theme.color.primary_main};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & div {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }
`

const ButtonTabs = styled(GenericButton)<{
  isDeny?: boolean
  isYellow?: boolean
  isBlue?: boolean
}>`
  width: auto;
  min-width: 120px;
  height: 25px;
  margin: 0.1rem;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  color: ${(props) => props.theme.color.white1};
  font-size: 0.9rem;
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isYellow
      ? props.theme.color.secondary_main
      : props.isBlue
      ? '#296ba9'
      : props.theme.color.button_normal} !important;

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isYellow
        ? props.theme.color.secondary_light
        : props.isBlue
        ? '#4b87be'
        : props.theme.color.button_normal_hover} !important;
  }
`

type Tabs = 'registration' | 'bank' | 'documentation' | 'partnerBeneficiary'

interface PathNav {
  id: string
  email: string
}

const LegalReading = (): JSX.Element => {
  // const theme = useContext(ThemeContext);
  // const user = useSelector((state: GlobalState) => state.user);
  const { user, hasPermissions } = useGlobals()
  const [currentTab, setCurrentTab] = useState<Tabs>('registration')
  const history = useHistory()
  const dispatch = useDispatch()
  //User State
  const [userData, setUserData] = useState<UserModel>({})
  const [accountBanks, setAccountBanks] = useState<BankModel[]>([])
  const [beneficiarys, setBeneficiarys] = useState<BeneficiaryModel[]>([])
  //Documentation
  const [userDocumentation, setUserDocumentation] = useState<ArchiveModel>({})
  const pathNav = useParams<PathNav>()

  const getUserData = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando dados do usuário'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(`/users/${pathNav.id}`, config)

      setUserData(data)
      getDocumentData()
    } catch (error: any) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    }
  }

  const getDocumentData = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando documentos'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(`/archives/${pathNav.id}`, config)

      setUserDocumentation(data)
      getBankData()
    } catch (error: any) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    }
  }

  const getBankData = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando dados bancários'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(`/bank/${pathNav.id}`, config)

      setAccountBanks(data)
      getBeneficiaryData()
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const getBeneficiaryData = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Finalizando',
          isLoading: true,
          subMessage: 'Aplicando dados do usuário'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(`/beneficiary/${pathNav.id}`, config)

      setBeneficiarys(data)
    } catch (error: any) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados de societários do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleSubmit = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      await axiosAuth.put(`/users/${pathNav.id}`, userData, config)

      dispatch(setSuccessMessage('Dados Salvos com Sucesso!'))
      getUserData()
    } catch (error: any) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const needsRedirect = useMemo(() => {
    const hasResponsible = userData.responsible_sector !== undefined
    const isCadastro =
      userData.responsible_sector === 'CADASTRO' &&
      hasPermissions([Permissions.CADASTRO_EDIT])
    const isCompliance =
      userData.responsible_sector === 'COMPLIANCE' &&
      hasPermissions([Permissions.COMPLIANCE_EDIT])
    const isDiretoria =
      userData.responsible_sector === 'DIRETORIA' &&
      hasPermissions([Permissions.DIRETORIA_EDIT])

    return !hasResponsible && !isCadastro && !isCompliance && !isDiretoria
  }, [userData, hasPermissions])

  useEffect(() => {
    getUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* {needsRedirect && <Redirect to="/unauthorized" />} */}
      <LegalStyled>
        <Container>
          <TemplateFlex>
            <CardForm
              onSubmit={(event) => {
                event.preventDefault()
                handleSubmit()
              }}
            >
              <CardMenu>
                <div>
                  <InputImgBase64
                    avatar={userData.photo!}
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        photo: value
                      })
                    }
                    readOnly
                  />
                </div>
              </CardMenu>
              <CardItems>
                <TabsContainer>
                  <TabsBlue>
                    <div>
                      <ButtonTabs
                        isBlue={currentTab !== 'registration'}
                        isYellow={currentTab === 'registration'}
                        type='button'
                        onClick={() => setCurrentTab('registration')}
                      >
                        Pré - cadastro *
                      </ButtonTabs>
                      <ButtonTabs
                        isBlue={currentTab !== 'documentation'}
                        isYellow={currentTab === 'documentation'}
                        type='button'
                        onClick={() => setCurrentTab('documentation')}
                      >
                        Documentação
                      </ButtonTabs>
                      <ButtonTabs
                        isBlue={currentTab !== 'bank'}
                        isYellow={currentTab === 'bank'}
                        type='button'
                        onClick={() => setCurrentTab('bank')}
                      >
                        Dados Bancários
                      </ButtonTabs>
                      <ButtonTabs
                        isBlue={currentTab !== 'partnerBeneficiary'}
                        isYellow={currentTab === 'partnerBeneficiary'}
                        type='button'
                        onClick={() => setCurrentTab('partnerBeneficiary')}
                      >
                        Representante(s)
                      </ButtonTabs>
                    </div>
                    <div>
                      <>
                        <ButtonTabs
                          isDeny
                          type='button'
                          onClick={() =>
                            history.push('/dascam/registration-management')
                          }
                        >
                          Voltar
                        </ButtonTabs>
                      </>
                    </div>
                  </TabsBlue>
                </TabsContainer>
                {currentTab === 'registration' ? (
                  <RegisterLegal
                    userData={userData}
                    changeUserData={(value) => setUserData(value)}
                  />
                ) : null}
                {currentTab === 'documentation' ? (
                  <DocumentationLegal
                    userDocumentation={userDocumentation}
                    changeDocumentation={(value) => setUserDocumentation(value)}
                  />
                ) : null}
                {currentTab === 'bank' ? (
                  <BankAccountLegal
                    userId={userData.id}
                    accountBanks={accountBanks}
                    getBankData={() => getBankData()}
                    userData={userData}
                  />
                ) : null}
                {currentTab === 'partnerBeneficiary' ? (
                  <PartnerBeneficiaryLegal
                    userId={userData.id}
                    beneficiarys={beneficiarys}
                    setBeneficiarys={setBeneficiarys}
                    getBeneficiaryData={() => getBeneficiaryData()}
                    userData={userData}
                  />
                ) : null}
              </CardItems>
            </CardForm>
          </TemplateFlex>
        </Container>
      </LegalStyled>
    </>
  )
}

export default LegalReading
