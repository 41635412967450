import styled, { keyframes } from 'styled-components'
import { useState, useEffect } from 'react'
import { axiosAuth } from 'helpers/axiosInstances'
import { AxiosError, AxiosResponse } from 'axios'
import GenericButton from 'components/generic/GenericButton'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  clearLoading,
  showLoading,
  setGlobalMessage,
  clearGlobalMessage,
  setSuccessMessage
} from 'redux/actions'

import Modal from './Modal'
import { InputImgBase64, InputPassword } from './GenericInputs'
import { formatCelPhone, formatPhone } from 'helpers/formats'
import { UserModel } from 'interfaces/user'
import { isUnauthorized } from 'helpers/errors'
import { Avatar, Button } from '@chakra-ui/react'

const slide = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

const Container = styled.div`
  padding: 0.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
`

const ItemGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 1rem;
`

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  min-width: 400px;
  padding: 1rem;
`

const UserData = styled.p`
  color: ${(props) => props.theme.color.primary_main};
  margin: 1rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 40ch;
`

const Span = styled.span`
  color: ${(props) => props.theme.color.primary_light};
  font-weight: 0;
`

const ButtonRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

// const Button = styled(GenericButton)<{ isDeny?: boolean }>`
//   width: 180px;
//   margin: 0.5rem;
//   padding: 0.6rem;
//   border-radius: 6px;
//   font-size: ${props => props.theme.font.normal};
//   background: ${props =>
//     props.isDeny
//       ? props.theme.color.button_deny
//       : props.theme.color.primary_main};
//   color: ${props => props.theme.color.white1};

//   &:hover {
//     background: ${props =>
//       props.isDeny
//         ? props.theme.color.button_deny_hover
//         : props.theme.color.primary_light};
//   }
// `;

const Effect = styled.div<{ delay: string }>`
  animation: ${slide} 1s 1 both ${(props) => props.delay};
  padding: 0.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
`

interface Props {
  id: string
  showModal: boolean
  handleModal: () => void
  logout: () => void
}

type ExtendedState = 'stage 01' | 'stage 02'

const ConfigUser = ({
  id,
  showModal,
  handleModal,
  logout
}: Props): JSX.Element => {
  const [showModalData, setShowModalData] = useState<boolean>(false)
  const [isExtended, setIsExtended] = useState<ExtendedState>('stage 01')
  const [photo, setPhoto] = useState<string>('')
  const [oldPhoto, setOldPhoto] = useState<string>('')
  const [fullName, setFullName] = useState<string>('')
  const [login, setLogin] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [cellPhone, setCellPhone] = useState<string>('')
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [verifyPassword, setVerifyPassword] = useState<string>('')
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showVerifyPassword, setShowVerifyPassword] = useState<boolean>(false)
  const [passwordEquals, setPasswordEquals] = useState<boolean>(false)
  const history = useHistory()

  const dispatch = useDispatch()

  const handleChangePhoto = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const body = {
        photo: photo
      }

      await axiosAuth.put(`/users/${id}`, body, config)
      dispatch(setSuccessMessage('Foto Salva com Sucesso!'))
      setOldPhoto(photo)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleChangePassword = () => {
    if (passwordEquals === true) {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const body = {
        username: login,
        currentPassword: currentPassword,
        newPassword: password,
        confirmationPassword: verifyPassword
      }

      axiosAuth
        .put(`/passwords`, body, config)
        .then((response: AxiosResponse) => {
          dispatch(setSuccessMessage('Senha alterada com sucesso!'))
          setIsExtended('stage 01')
          setCurrentPassword('')
          setPassword('')
          setVerifyPassword('')
          logout()
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 403) {
            dispatch(
              setGlobalMessage({
                message: `Error: ${error.response?.status}`,
                type: 'ERROR',
                buttons: (
                  <GenericButton
                    onClick={() => {
                      history.push('/')
                      dispatch(clearGlobalMessage())
                    }}
                  >
                    Ok
                  </GenericButton>
                )
              })
            )
          } else {
            dispatch(
              setGlobalMessage({
                message: `Error: ${error.response?.status}`,
                type: 'ERROR'
              })
            )
          }
        })
        .finally(() => {
          dispatch(clearLoading())
        })
    } else {
      dispatch(
        setGlobalMessage({
          message: `Preencha os campos corretamente!`,
          type: 'ERROR'
        })
      )
    }
  }

  const fetchUser = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      if (showModal === true) {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }

        const { data } = await axiosAuth.get<UserModel>(
          `/users-macro/${id}`,
          config
        )
        setFullName(data.user_name!)
        setLogin(data.login!)
        setEmail(data.email!)
        setPhone(
          data.contact_phone
            ? formatPhone(data.contact_phone!)
            : String(data.contact_phone)
        )
        setCellPhone(
          data.contact_cellphone
            ? formatCelPhone(data.contact_cellphone!)
            : String(data.contact_cellphone)
        )
        setPhoto(data.photo!)
        setOldPhoto(data.photo!)
        setShowModalData(true)
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  useEffect(() => {
    if (showModalData) {
      if (photo !== oldPhoto) {
        handleChangePhoto()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo])

  useEffect(() => {
    if (password !== '' && verifyPassword !== '') {
      if (password === verifyPassword) {
        setPasswordEquals(true)
      } else {
        setPasswordEquals(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password && verifyPassword])

  useEffect(() => {
    fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const cancel = () => {
    setIsExtended('stage 01')
    setCurrentPassword('')
    setPassword('')
    setVerifyPassword('')
  }

  const closeModal = () => {
    handleModal()
    setShowModalData(false)
    setIsExtended('stage 01')
    setPhoto('')
    setOldPhoto('')
    setFullName('')
    setLogin('')
    setEmail('')
    setPhone('')
    setCellPhone('')
    setCurrentPassword('')
    setPassword('')
    setVerifyPassword('')
  }

  return (
    <>
      {isExtended === 'stage 01' ? (
        <Modal
          title='Dados do Usuário'
          showModal={showModalData}
          close={closeModal}
          width='700px'
          backFocus={true}
        >
          <Container>
            <ItemGroup>
              <LeftSide>
                {/* <InputImgBase64
                  label=""
                  changeValue={value => setPhoto(value)}
                  avatar={photo}
                /> */}
                <Avatar
                  bg={'primary.200'}
                  name={fullName}
                  src={photo}
                  size='2xl'
                />
              </LeftSide>
              <RightSide>
                <UserData>
                  Nome: <Span>{fullName}</Span>
                </UserData>
                <UserData>
                  E-mail: <Span>{email}</Span>
                </UserData>
                <UserData>
                  Telefone:{' '}
                  <Span>{phone !== 'null' ? phone : 'Não informado'}</Span>
                </UserData>
                <UserData>
                  Celular:{' '}
                  <Span>
                    {cellPhone !== 'null' ? cellPhone : 'Não informado'}
                  </Span>
                </UserData>
              </RightSide>
            </ItemGroup>
            <ButtonRow>
              <Button
                variant={'outline'}
                color={'primary.400'}
                colorScheme={'primary'}
                m={6}
                onClick={() => setIsExtended('stage 02')}
              >
                Redefinir Senha
              </Button>
            </ButtonRow>
          </Container>
        </Modal>
      ) : (
        <Modal
          title='Redefinir Senha'
          showModal={showModalData}
          close={closeModal}
          width='700px'
          backFocus={true}
        >
          <Effect delay='50ms'>
            <ItemGroup>
              <LeftSide>
                <InputImgBase64
                  label=''
                  changeValue={(value) => setPhoto(value)}
                  avatar={photo}
                  readOnly
                />
              </LeftSide>
              <RightSide>
                <InputPassword
                  placeholder='Senha Atual'
                  value={currentPassword}
                  changeValue={(value) => setCurrentPassword(value)}
                  plainPassword={showCurrentPassword}
                  handlePlainPassword={() =>
                    setShowCurrentPassword(!showCurrentPassword)
                  }
                />
                <InputPassword
                  placeholder='Nova Senha'
                  value={password}
                  changeValue={(value) => setPassword(value)}
                  plainPassword={showPassword}
                  handlePlainPassword={() => setShowPassword(!showPassword)}
                />
                <InputPassword
                  placeholder='Confirmar Nova Senha'
                  value={verifyPassword}
                  changeValue={(value) => setVerifyPassword(value)}
                  plainPassword={showVerifyPassword}
                  handlePlainPassword={() =>
                    setShowVerifyPassword(!showVerifyPassword)
                  }
                />
              </RightSide>
            </ItemGroup>
            <ButtonRow>
              <Button
                m={3}
                variant={'outline'}
                color={'red.400'}
                colorScheme={'red'}
                onClick={() => cancel()}
              >
                Cancelar
              </Button>
              <Button
                m={3}
                variant={'ghost'}
                color={'green.400'}
                colorScheme={'green'}
                onClick={() => handleChangePassword()}
              >
                Confirmar
              </Button>
            </ButtonRow>
          </Effect>
        </Modal>
      )}
    </>
  )
}

export default ConfigUser
