import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { GlobalState } from "redux/types";
import styled from "styled-components";

const StyledHome = styled.section`
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
`;

const Home = (): JSX.Element => {
  const user = useSelector((state: GlobalState) => state.user);
  return (
    <>
      {user?.cad_type === "JURIDICO" && user.status !== "ACTIVE" ? (
        <Redirect to="/data/company" />
      ) : null}
      {user?.cad_type === "FISICO" && user.status !== "ACTIVE" ? (
        <Redirect to="/data/individual" />
      ) : null}
      {user?.cad_type === "ESTRANGEIRO" && user.status !== "ACTIVE" ? (
        <Redirect to="/data/foreign" />
      ) : null}
      {user === undefined ? <Redirect to="/unauthorized" /> : null}
      <StyledHome>
        <iframe title="a" src="https://dashboardcotacao.dascam.com.br/#/" width={'100%'} height={'100%'}></iframe>
      </StyledHome>
    </>
  );
};

export default Home;
