import Header from "components/landing/header/Header";
import Hero from "components/landing/hero/Hero";
import ForWhom from "components/landing/for-whom/For-whom";
import WhoWeAre from "components/landing/who-we-are/Who-we-are";
import Autorization from "components/landing/autorization/Autorization";
import Footer from "components/landing/footer/Footer";

const Landing = (): JSX.Element => {
  return (
    <>
      <Header />
      <Hero />
      <ForWhom />
      <WhoWeAre />
      <Autorization />
      <Footer />
    </>
  );
};

export default Landing;
