import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  IconButton,
  ModalProps,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { Col } from "../Col";
import { Row } from "../Row";

export interface AppModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  _modal?: Partial<ModalProps>;
  _rightHeader?: React.ReactNode;
  _bottomHeader?: React.ReactNode;
}

const AppModal: React.FC<AppModalProps> = ({
  children,
  onClose,
  title,
  isOpen,
  _modal,
  _rightHeader,
  _bottomHeader,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} {..._modal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display={"flex"}
            flexDir={"row"}
            alignItems={"center"}
            p={0}
          >
            <Col w={"100%"}>
              <Row p={4}>
                <Row flex={1} textTransform={"uppercase"} fontWeight={"bold"}>
                  {title}
                </Row>
                <IconButton
                  size={"sm"}
                  aria-label="Fechar"
                  variant={"ghost"}
                  color={"terciary.400"}
                  colorScheme={"terciary_hover"}
                  onClick={onClose}
                  mr={_rightHeader ? 2 : 0}
                >
                  <AiOutlineLeft size={18} />
                </IconButton>
                {_rightHeader}
              </Row>
              {_bottomHeader}
            </Col>
          </ModalHeader>
          {children}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AppModal;
