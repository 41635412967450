import Account from 'components/generic/BankAccount'
import GenericButton from 'components/generic/GenericButton'
import styled from 'styled-components'
import {
  InputAccount,
  InputAgency,
  InputDate,
  InputSelect,
  InputSelectBank,
  InputText
} from 'components/generic/GenericInputs'
import { BankEntity, BankModel } from 'interfaces/bank'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading
} from 'redux/actions'
import { axiosAuth, axiosWebsite } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { useHistory } from 'react-router-dom'
import { UserModel, UserStatusEnum } from 'interfaces/user'
import ModalPickBank from 'components/generic/logic/ModalPickBank'
import { ReactComponent as LupaIcon } from 'assets/lupaIcon.svg'
import { BankAgencyModel } from 'interfaces/bankAgency'
import ModalPickAgency from 'components/generic/logic/ModalPickAgency'
import StateCityBanksPick from 'components/generic/logic/StateCityBanksPick'
import { BiSearchAlt2 } from 'react-icons/bi'
import DataTable, { TableColumn } from 'react-data-table-component'
import { HStack, IconButton } from '@chakra-ui/react'
import { formatAccountBank, formatAgency } from 'helpers/formats'
import { Col } from 'v2/components/shared/Col'
import { ReactComponent as EditDataIcon } from 'assets/editDataIcon.svg'
import { ReactComponent as ActiveIcon } from 'assets/activeIcon.svg'
import { ReactComponent as InactiveIcon } from 'assets/inactiveIcon.svg'
import { ReactComponent as DeleteIcon } from 'assets/trashIcon.svg'
import { v4 as uuidv4 } from 'uuid'

const TableGrid = styled.div<{ noAction?: boolean }>`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.noAction
      ? '300px 100px 150px 150px'
      : '300px 100px 150px 150px auto'};
  grid-auto-rows: 35px;
  height: auto;
  width: auto;
  max-height: 300px;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

const ButtonYellow = styled.button<{ marginBottom?: string }>`
  width: 40px;
  height: 40px;
  padding: 0.3rem;
  background-color: ${(props) => props.theme.color.secondary_main};
  border-radius: 6px;
  outline: none;
  border: none;
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  cursor: pointer;
  transform: scale(0.95);
  margin-bottom: ${(props) => props.marginBottom};

  & svg {
    width: auto;
    height: 100%;
    & path {
      fill: ${(props) => props.theme.color.primary_main};
    }
  }

  &:hover {
    transform: scale(1);
  }
`

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: end;
  flex-wrap: wrap;
  height: auto;
`

const TitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`

const SubtitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.color.gray4};
  font-weight: bold;
  line-height: 24px;
`

const Button = styled(GenericButton)<{
  width?: string
  isDisabled?: boolean
  isDeny?: boolean
}>`
  margin: 1rem 0;
  margin-right: 0.5rem;
  padding: 1rem;
  width: ${(props) => (props.width ? props.width : '260px')};
  color: ${(props) =>
    props.isDisabled
      ? props.theme.color.white1
      : props.isDeny
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.color.gray3
      : props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${(props) =>
      props.isDisabled
        ? props.theme.color.gray4
        : props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`
const ButtonStatus = styled(GenericButton)<{ isActive?: boolean }>`
  width: 60px;
  height: 25px;
  padding: 0.1rem;
  border: 1px solid ${(props) => props.theme.color.white1};
  border-radius: 10px;
  background: ${(props) => props.theme.color.primary_dark};
  align-items: center;
  justify-content: ${(props) => (props.isActive ? 'flex-start' : 'flex-end')};
  margin-left: 0.3rem;

  &:hover {
    background: ${(props) => props.theme.color.primary_main};
  }

  & svg {
    width: 30px;
    height: auto;
  }
`
interface Props {
  userId: number | undefined
  accountBanks: BankModel[]
  getBankData: () => void
  userData: UserModel
}

const BankAccountLegal = ({
  userId,
  accountBanks,
  getBankData,
  userData
}: Props): JSX.Element => {
  const [bankData, setBankData] = useState<BankModel>({
    bank_code: '',
    bank_name: '',
    bank_agency: '',
    bank_account: '',
    opening_date: '',
    bank_state: '',
    bank_city: '',
    account_type: 'C'
  })

  const columns: TableColumn<BankModel>[] = [
    {
      name: 'Banco',
      selector: (row) => row.bank_name ?? '',
      sortable: true,
      width: '380px'
    },
    {
      name: 'Agência',
      selector: (row) => row.bank_agency ?? '',
      format: (row) => formatAgency(row.bank_agency ?? ''),
      width: '100px'
    },
    {
      name: 'Conta Corrente',
      selector: (row) => row.bank_account ?? '',
      format: (row) => formatAccountBank(row.bank_account ?? '')
    },
    {
      name: 'Data de Abertura',
      selector: (row) =>
        row.opening_date
          ? new Date(row.opening_date)
              ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      width: '150px'
    }
  ]

  useEffect(() => {
    getBankData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <DivItems>
        <Col
          rounded={'lg'}
          overflow={'auto'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
        >
          <DataTable
            noDataComponent=''
            columns={columns}
            data={accountBanks}
            dense={true}
            fixedHeader={true}
            fixedHeaderScrollHeight={'350px'}
            striped={true}
          />
        </Col>
      </DivItems>
    </>
  )
}

export default BankAccountLegal
