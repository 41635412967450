import Account from 'components/generic/BankAccount'
import GenericButton from 'components/generic/GenericButton'
import styled from 'styled-components'
import {
  InputAccount,
  InputAgency,
  InputDate,
  InputSelect,
  InputSelectBank,
  InputText
} from 'components/generic/GenericInputs'
import { BankEntity, BankModel } from 'interfaces/bank'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading
} from 'redux/actions'
import { axiosAuth, axiosWebsite } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { useHistory } from 'react-router-dom'
import { UserModel, UserStatusEnum } from 'interfaces/user'
import ModalPickBank from 'components/generic/logic/ModalPickBank'
import { ReactComponent as LupaIcon } from 'assets/lupaIcon.svg'
import { BankAgencyModel } from 'interfaces/bankAgency'
import ModalPickAgency from 'components/generic/logic/ModalPickAgency'
import StateCityBanksPick from 'components/generic/logic/StateCityBanksPick'
import { BiSearchAlt2 } from 'react-icons/bi'
import DataTable, { TableColumn } from 'react-data-table-component'
import { HStack, IconButton } from '@chakra-ui/react'
import { formatAccountBank, formatAgency } from 'helpers/formats'
import { Col } from 'v2/components/shared/Col'
import { ReactComponent as EditDataIcon } from 'assets/editDataIcon.svg'
import { ReactComponent as ActiveIcon } from 'assets/activeIcon.svg'
import { ReactComponent as InactiveIcon } from 'assets/inactiveIcon.svg'
import { ReactComponent as DeleteIcon } from 'assets/trashIcon.svg'
import { v4 as uuidv4 } from 'uuid'

const TableGrid = styled.div<{ noAction?: boolean }>`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.noAction
      ? '300px 100px 150px 150px'
      : '300px 100px 150px 150px auto'};
  grid-auto-rows: 35px;
  height: auto;
  width: auto;
  max-height: 300px;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

const ButtonYellow = styled.button<{ marginBottom?: string }>`
  width: 40px;
  height: 40px;
  padding: 0.3rem;
  background-color: ${(props) => props.theme.color.secondary_main};
  border-radius: 6px;
  outline: none;
  border: none;
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  cursor: pointer;
  transform: scale(0.95);
  margin-bottom: ${(props) => props.marginBottom};

  & svg {
    width: auto;
    height: 100%;
    & path {
      fill: ${(props) => props.theme.color.primary_main};
    }
  }

  &:hover {
    transform: scale(1);
  }
`

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: end;
  flex-wrap: wrap;
  height: auto;
`

const TitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`

const SubtitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.color.gray4};
  font-weight: bold;
  line-height: 24px;
`

const Button = styled(GenericButton)<{
  width?: string
  isDisabled?: boolean
  isDeny?: boolean
}>`
  margin: 1rem 0;
  margin-right: 0.5rem;
  padding: 1rem;
  width: ${(props) => (props.width ? props.width : '260px')};
  color: ${(props) =>
    props.isDisabled
      ? props.theme.color.white1
      : props.isDeny
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.color.gray3
      : props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${(props) =>
      props.isDisabled
        ? props.theme.color.gray4
        : props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`
const ButtonStatus = styled(GenericButton)<{ isActive?: boolean }>`
  width: 60px;
  height: 25px;
  padding: 0.1rem;
  border: 1px solid ${(props) => props.theme.color.white1};
  border-radius: 10px;
  background: ${(props) => props.theme.color.primary_dark};
  align-items: center;
  justify-content: ${(props) => (props.isActive ? 'flex-start' : 'flex-end')};
  margin-left: 0.3rem;

  &:hover {
    background: ${(props) => props.theme.color.primary_main};
  }

  & svg {
    width: 30px;
    height: auto;
  }
`
interface Props {
  userId: number | undefined
  accountBanks: BankModel[]
  getBankData: () => void
  userData: UserModel
  setAccountBanks: (value: BankModel[]) => void
}

const BankAccountLegal = ({
  userId,
  accountBanks,
  setAccountBanks,
  getBankData,
  userData
}: Props): JSX.Element => {
  const [disableBank, setDisableBank] = useState<boolean>(false)
  const [activeBank, setActiveBank] = useState<boolean>(false)
  const [isOpen, setOpen] = useState(false)
  const [isAgencyOpen, setAgencyOpen] = useState(false)
  const [bankEntity, setBankEntity] = useState<BankEntity>()
  const [editUser, setEditUser] = useState<boolean>(false)
  const [bankData, setBankData] = useState<BankModel>({
    bank_code: '',
    bank_name: '',
    bank_agency: '',
    bank_account: '',
    opening_date: '',
    bank_state: '',
    bank_city: '',
    account_type: 'C'
  })
  const history = useHistory()
  const dispatch = useDispatch()

  const columns: TableColumn<BankModel>[] = [
    {
      name: 'Banco',
      selector: (row) => row.bank_name ?? '',
      sortable: true,
      width: '380px'
    },
    {
      name: 'Agência',
      selector: (row) => row.bank_agency ?? '',
      format: (row) => row.bank_agency ?? '',
      width: '100px'
    },
    {
      name: 'Conta Corrente',
      selector: (row) => row.bank_account ?? '',
      format: (row) => row.bank_account ?? ''
    },
    {
      name: 'Data de Abertura',
      selector: (row) =>
        row.opening_date
          ? new Date(row.opening_date)
              ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      width: '150px'
    },
    {
      name: 'AÇÕES',
      cell: (row) => action(row),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      width: '120px'
    }
  ]

  function action (data: any) {
    return userData.status !== 'EVALUATION' ? (
      <HStack m={1}>
        <IconButton
          onClick={() => {
            if (!editUser && removeToEdit && setEditUser && setBankData) {
              data.uuid = uuidv4()
              removeToEdit(data.id, data.uuid)
              setEditUser(!editUser)
              setBankData(data)
            } else {
              dispatch(
                setGlobalMessage({
                  message: 'Finalize a edição do registro atual!',
                  type: 'WARN'
                })
              )
            }
          }}
          aria-label='Editar'
          title='Editar'
          size={'sm'}
          colorScheme={'secondary_main'}
        >
          <EditDataIcon />
        </IconButton>
        {userData.status !== UserStatusEnum.PENDING && (
          <ButtonStatus
            isActive={data.status === 'ACTIVE' ? true : false}
            type='button'
            onClick={() =>
              data.status === 'ACTIVE'
                ? setDisableBank(!disableBank)
                : setActiveBank(!activeBank)
            }
          >
            {data.status === 'ACTIVE' ? <ActiveIcon /> : <InactiveIcon />}
          </ButtonStatus>
        )}
      </HStack>
    ) : null
  }

  const refreshBanks = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }

    await axiosAuth
      .get(`/bank/${userData.id}`, config)
      .then((res) => res.data)
      .then(setAccountBanks)
  }

  const removeToEdit = (index?: number, uuid?: string) => {
    let res = [...accountBanks]
    index
      ? res.splice(
          res.findIndex((e) => e.id === index),
          1
        )
      : res.splice(
          res.findIndex((e) => e.uuid === uuid),
          1
        )
    setAccountBanks(res)
  }

  const handleSubmitBank = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const body = {
        user_id: userId,
        bank_code: bankData.bank_code,
        bank_name: bankData.bank_name,
        bank_agency: bankData.bank_agency,
        bank_account: bankData.bank_account,
        opening_date:
          bankData.opening_date === '' ? null : bankData.opening_date,
        bank_city: bankData.bank_city,
        bank_state: bankData.bank_state,
        account_type: bankData.account_type
      }

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      if (editUser) {
        const res = await axiosAuth.put(`/bank/${bankData.id}`, body, config)
        if (userData.id_syscambio && res) {
          const resBank = await axiosAuth.get(`/bank/${userData.id}`, config)
          setAccountBanks(resBank.data)
          if (resBank) {
            const body = {
              user: userData,
              bank: resBank.data,
              beneficiary: []
            }
            await axiosAuth.put(`/user/update`, body, config)
          }
        }
      } else {
        const res = await axiosAuth.post(`/bank`, body, config)
        if (userData.id_syscambio && res) {
          const resBank = await axiosAuth.get(`/bank/${userData.id}`, config)
          setAccountBanks(resBank.data)
          if (resBank) {
            const body = {
              user: userData,
              bank: resBank.data,
              beneficiary: []
            }
            await axiosAuth.put(`/user/update`, body, config)
          }
        }
      }

      dispatch(setSuccessMessage('Conta cadastrada com sucesso!'))
      clearBank()
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
      getBankData()
    }
  }

  const clearBank = () => {
    setBankData({
      ...bankData,
      bank_code: '',
      bank_name: '',
      bank_agency: '',
      bank_account: '',
      opening_date: '',
      account_type: 'C',
      bank_state: '',
      bank_city: ''
    })
    setEditUser(false)
  }

  const clearBankSubFields = () => {
    setBankData({
      ...bankData,
      bank_agency: '',
      bank_account: '',
      opening_date: '',
      account_type: 'C',
      bank_state: '',
      bank_city: ''
    })
    setEditUser(false)
  }

  useEffect(() => {
    getBankData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <DivItems>
        <TitleItems>Dados Bancários</TitleItems>
        {userData.status !== 'EVALUATION' ? (
          <SubtitleItems>
            Para adicionar uma conta basta preencher os campos e clicar em
            adicionar conta. Importante! Para cadastro de uma Agência Bancária é
            necessário selecionar o Banco, Estado e Cidade. Caso não sejam
            encontrados dados para os itens selecionados ou não encontre alguma
            informação, por favor entre em contato com a equipe DASCAM.
          </SubtitleItems>
        ) : null}
      </DivItems>
      {userData.status !== 'EVALUATION' ? (
        <DivItems>
          <ItemRow>
            <InputText
              label='Banco'
              maxWidth='500px'
              readOnly
              value={bankData?.bank_name}
            />
            <ButtonYellow
              type='button'
              marginBottom='.3rem'
              onClick={() => setOpen(() => true)}
            >
              <BiSearchAlt2 />
            </ButtonYellow>
            {/*<div style={{ display: 'flex', width: 500, marginLeft: 5 }}>
              <StateCityBanksPick
                value={{
                  city: bankData?.bank_city,
                  state: bankData?.bank_state
                }}
                bankCode={bankData?.bank_code || 0}
                onChange={(v) => {
                  setBankData((oldBank) => ({
                    ...oldBank,
                    bank_city: v.city,
                    bank_state: v.state
                  }))
                }}
              />
            </div>*/}
            <ModalPickBank
              onChange={(nextBank) => {
                setBankEntity(() => nextBank)
                setBankData((oldBankData) => ({
                  // ...oldBankData,
                  // ...nextBank,
                  ...oldBankData,
                  bank_name: nextBank.bank_info,
                  bank_code: nextBank.bank_code
                }))
                // clearBankSubFields()
                setOpen(() => false)
              }}
              value={bankEntity}
              onClose={() => setOpen(() => false)}
              isOpen={isOpen}
            />
          </ItemRow>
          <ItemRow>
            <InputAgency
              changeValue={(value) =>
                setBankData({
                  ...bankData,
                  bank_agency: value
                })
              }
              label='Agência'
              maxWidth='150px'
              value={`${bankData?.bank_agency}`}
            />
            {/*<InputAgency
              readOnly
              label='Agência'
              maxWidth='150px'
              value={`${bankData?.bank_agency}`}
            />
            <ButtonYellow
              type='button'
              marginBottom='.3rem'
              disabled={
                bankData?.bank_code &&
                bankData?.bank_state &&
                bankData?.bank_city
                  ? false
                  : true
              }
              onClick={() => setAgencyOpen(() => true)}
            >
              <BiSearchAlt2 />
            </ButtonYellow>*/}
            {bankData?.bank_code &&
              bankData?.bank_state &&
              bankData?.bank_city && (
                <ModalPickAgency
                  // {...filter}
                  state={bankData.bank_state}
                  city={bankData.bank_city}
                  onClose={() => setAgencyOpen(() => false)}
                  isOpen={isAgencyOpen}
                  bankCode={bankData.bank_code}
                  onChange={(agency) => {
                    setAgencyOpen(() => false)
                    setBankData((old) => ({
                      ...old,
                      bank_agency: String(agency.CodAgencia)
                    }))
                  }}
                />
              )}
            <div style={{ display: 'flex', marginLeft: 5 }}>
              <InputSelect
                label={'Tipo de Conta'}
                value={bankData.account_type}
                changeValue={(v) => {
                  setBankData((oldBank) => ({
                    ...oldBank,
                    account_type: v as any
                  }))
                }}
              >
                <option value={'C'}>Conta Corrente</option>
                <option value={'P'}>Conta Poupança</option>
              </InputSelect>
            </div>
            <InputAccount
              label={
                bankData.account_type === 'C'
                  ? 'Conta Corrente'
                  : 'Conta Poupança'
              }
              maxWidth='200px'
              value={bankData.bank_account}
              changeValue={(value) =>
                setBankData({
                  ...bankData,
                  bank_account: value
                })
              }
            />
            <InputDate
              label='Data de Abertura'
              maxWidth='200px'
              max={new Date().toISOString().split('T')[0]}
              value={bankData.opening_date}
              changeValue={(value) =>
                setBankData({
                  ...bankData,
                  opening_date: value
                })
              }
            />
          </ItemRow>
          <ItemRow>
            {editUser ? (
              <Button
                isDeny
                type='button'
                width='180px'
                onClick={() => {
                  getBankData()
                  clearBank()
                  setEditUser(!editUser)
                }}
              >
                Cancelar
              </Button>
            ) : null}
            <Button
              isDisabled={
                bankData.bank_code !== '' &&
                bankData.bank_name !== '' &&
                bankData.bank_agency !== '' &&
                bankData.bank_account !== '' //&& bankData.opening_date !== ''
                  ? false
                  : true
              }
              type='button'
              width='180px'
              onClick={() =>
                bankData.bank_code !== '' &&
                bankData.bank_name !== '' &&
                bankData.bank_agency !== '' &&
                bankData.bank_account !== '' //&& bankData.opening_date !== ''
                  ? handleSubmitBank()
                  : null
              }
            >
              {editUser ? 'Alterar' : 'Adicionar Conta +'}
            </Button>
          </ItemRow>
        </DivItems>
      ) : null}
      <DivItems>
        <Col
          rounded={'lg'}
          overflow={'auto'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
        >
          <DataTable
            noDataComponent=''
            columns={columns}
            data={accountBanks}
            dense={true}
            fixedHeader={true}
            fixedHeaderScrollHeight={'350px'}
            striped={true}
          />
        </Col>
      </DivItems>
    </>
  )
}

export default BankAccountLegal
