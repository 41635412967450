import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { axiosWebsite } from 'helpers/axiosInstances'
import { ExchangeScheduleModel } from 'interfaces/exchange-schedule'

import './style.css'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import { createCustomerSearchPicker } from 'v2/services/app/factories/forms/createCustomerSearchPicker'
import { Button } from '@chakra-ui/button'
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Collapse,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  useBoolean,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import { getMoeda } from 'helpers/moedas'
import {
  formatCNPJ,
  formatCPF,
  formatDate,
  formatProduct
} from 'helpers/formats'

import DataTable, { TableColumn } from 'react-data-table-component'

import { ExcelExportXlsx } from 'services/ExcelExportXlsx'
import { BiShow } from 'react-icons/bi'
import AppContext from 'hooks/helpers/useLastFilter'
import { BsTrash } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { clearLoading, showLoading } from 'redux/actions'
import { FiEdit } from 'react-icons/fi'
import { validateFild } from 'helpers/validFilds'

const BillingInternal: React.FC = () => {
  const [isLoading, loading] = useBoolean()

  const { user } = useGlobals()
  const date = new Date().toISOString().split('T')[0]
  const Default = AppContext?.billing_review
    ? AppContext?.billing_review
    : {
        start_date: date,
        end_date: date
      }
  useEffect(() => {
    redirect(hashData)
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [canelBillingSelect, setCancelBillingSelect] =
    useState<ExchangeScheduleModel>({})
  const [process, setProcess] = useState<ExchangeScheduleModel[]>([])
  const [billingSelect, setBillingSelect] = useState<ExchangeScheduleModel>({})
  const [dataInput, setDataInput] = useState<any>()
  const [dateInitials, setDateInitials] = useState<string>()
  const [dateFinal, setDateFinal] = useState<string>()
  const [showAlert, setShowAlert] = useBoolean()
  const [idSyscambio, setSyscambio] = useState(Number(user!.id_syscambio!))
  const { hashData, redirect } = useHashData(Default)
  const dispatch = useDispatch()
  const userId = user!.id!
  const toast = useToast()

  const [toggledClearRows, setToggleClearRows] = React.useState(false)

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows)
  }

  const columns: TableColumn<ExchangeScheduleModel>[] = [
    {
      name: 'Ações',
      cell: (row) => (
        <HStack m={1}>
          <IconButton
            onClick={() => {
              setBillingSelect(row)
              onOpenBilling()
            }}
            aria-label='Informar'
            title='Informar'
            size={'sm'}
            colorScheme={'blue'}
          >
            <FiEdit size={16} />
          </IconButton>
          <IconButton
            onClick={() => {
              setCancelBillingSelect(row)
              onOpen()
            }}
            aria-label='Reverter'
            title='Reverter'
            size={'sm'}
            colorScheme={'red'}
          >
            <BsTrash size={16} />
          </IconButton>
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    },
    {
      name: 'id',
      selector: (row) => row.id ?? '',
      sortable: true,
      width: '60px'
    },
    {
      name: 'CLIENTE',
      selector: (row) => row.name_cli ?? '',
      format: (row) => row.cod_cli + ' - ' + row.name_cli,
      sortable: true,
      width: '300px'
    },
    {
      name: 'FECHAMENTO',
      selector: (row) =>
        row.closure
          ? new Date(row.closure)
              .toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      width: '130px'
    },
    {
      name: 'PRODUTO',
      selector: (row) => formatProduct(row.product ?? ''),
      width: '120px'
    },
    {
      name: 'MOEDA',
      selector: (row) => getMoeda(Number(row.currency)),
      width: '90px',
      right: true
    },
    {
      name: 'VALOR',
      selector: (row) => row.value ?? '',
      format: (row) =>
        row.value?.toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }),
      sortable: true,
      width: '130px',
      right: true
    },
    {
      name: 'TAXA',
      selector: (row) => row.rate ?? '',
      format: (row) =>
        row.rate?.toLocaleString('pt-br', {
          minimumFractionDigits: 4
        }),
      width: '100px',
      right: true
    },
    {
      name: 'BANCO',
      selector: (row) => row.bank ?? ''
      //width: '200px'
    },
    {
      name: 'DATA ME',
      selector: (row) =>
        row.date_me
          ? new Date(row.date_me)
              .toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      width: '130px'
    },
    {
      name: 'DATA REAIS',
      selector: (row) =>
        row.date_reais
          ? new Date(row.date_reais)
              .toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      width: '130px'
    }
  ]

  const { isOpen, onOpen, onClose } = useDisclosure()
  function AlertDialogConfirmation () {
    const cancelRef = useRef<HTMLButtonElement>(null)

    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Reverte
              </AlertDialogHeader>

              <AlertDialogBody>
                Você deseja fazer a reversão do item selecionado? Ele será
                enviado de volta para a tela de Agenda de Câmbio
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button colorScheme='red' onClick={sendDataCancel} ml={3}>
                  Reverter
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  const {
    isOpen: isOpenBilling,
    onOpen: onOpenBilling,
    onClose: onCloseBilling
  } = useDisclosure()
  function BillingForm () {
    const listR: string[] = billingSelect?.referecies ?? []
    billingSelect!.refereciesJoin = listR.join(', ')

    const listP: string[] = billingSelect?.processes ?? []

    return (
      <>
        <Modal size={'6xl'} isOpen={isOpenBilling} onClose={onCloseBilling}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Visualização/Edição</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Col m={4}>
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 3,
                      fields: [
                        {
                          type: 'text',
                          label: 'Produto',
                          name: 'product',
                          readOnly: true,
                          mask (data) {
                            return formatProduct(data ?? '')
                          }
                        },
                        {
                          type: 'text',
                          label: 'Moeda',
                          name: 'currency',
                          readOnly: true,
                          mask (data) {
                            return getMoeda(Number(data) ?? '')
                          }
                        },
                        {
                          type: 'currency',
                          label: 'Valor Total ME',
                          name: 'value',
                          readOnly: true
                        }
                      ]
                    }
                  ]}
                  value={billingSelect}
                  onChange={(_) => {}}
                />
                <p>Para editar, preencha todos os campos necessarios abaixo:</p>
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 2,
                      fields: [
                        {
                          type: 'text',
                          label: 'Banco*',
                          name: 'bank'
                        },
                        {
                          type: 'rate',
                          label: 'Taxa*',
                          name: 'rate'
                        }
                      ]
                    },
                    {
                      columns: 2,
                      fields: [
                        {
                          type: 'date',
                          label: 'Data ME*',
                          name: 'date_me'
                        },
                        {
                          type: 'date',
                          label: 'Data Reais*',
                          name: 'date_reais'
                        }
                      ]
                    },
                    {
                      columns: 2,
                      fields: [
                        {
                          type: 'textarea',
                          label: 'Observação',
                          name: 'obs'
                        },
                        {
                          type: 'textarea',
                          label: 'Referências',
                          name: 'refereciesJoin'
                        }
                      ]
                    },
                    {
                      columns: 2,
                      fields: [
                        {
                          type: 'textarea',
                          label: 'Processos',
                          name: 'processes'
                        }
                      ]
                    }
                  ]}
                  value={billingSelect}
                  onChange={(v) => {
                    setBillingSelect(v)
                  }}
                />
              </Col>
            </ModalBody>

            <ModalFooter>
              <Button
                variant={'ghost'}
                color={'red.400'}
                colorScheme={'red'}
                mr='20px'
                onClick={onCloseBilling}
              >
                Cancelar
              </Button>

              <Button
                variant={'outline'}
                color={'green.400'}
                colorScheme={'green'}
                mr='20px'
                onClick={save}
              >
                Atualizar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
  async function save () {
    try {
      if (
        validateFild(billingSelect, ['bank', 'rate', 'date_me', 'date_reais'])
      ) {
        dispatch(
          showLoading({
            message: 'Carregando',
            isLoading: true,
            subMessage: ''
          })
        )
        const toSave = billingSelect
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
        await axiosWebsite
          .post(
            `save-billing`,
            {
              data: toSave
            },
            config
          )
          .then((_) => {
            onCloseBilling()
            loadData()
          })
          .catch((e) => {
            return toast({
              title: 'Erro!',
              position: 'bottom',
              description: 'Erro ao Salvar',
              status: 'error',
              duration: 3000,
              isClosable: true
            })
          })
      } else {
        return toast({
          title: 'Existem campos sem preenchimento!',
          position: 'bottom',
          description: 'Por favor, verifique os dados e tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(clearLoading())
    }
  }
  async function sendDataCancel () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite
        .post(
          'destroy-billing',
          {
            data: canelBillingSelect
          },
          config
        )
        .then((res) => {
          loadData()
        })
        .catch((e) => {})
    } catch (error) {
    } finally {
      onClose()
      dispatch(clearLoading())
    }
  }
  const loadData = useCallback(() => {
    try {
      loading.on()
      setShowAlert.off()
      if (userId && hashData?.start_date && hashData?.end_date) {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
        axiosWebsite
          .post(
            `load-billing`,
            {
              codCli: idSyscambio ?? null,
              product_type: dataInput,
              initial_date: dateInitials ?? hashData?.start_date,
              end_date: dateFinal ?? hashData?.end_date
            },
            config
          )
          .then((res) => {
            setProcess(() => res.data)
            loading.off()
          })
          .catch((e) => {
            loading.off()
          })
        console.log(process)
      } else {
        setShowAlert.on()
        loading.off()
      }
    } catch (error) {
      console.log(error)
      loading.off()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataInput,
    dateFinal,
    dateInitials,
    idSyscambio,
    loading,
    setShowAlert,
    user?.id_syscambio,
    userId
  ])
  const exportExcel = async () => {
    const rows = process.map((o) => {
      return {
        Cliente: o.name_cli ?? '',
        Fechamento: formatDate(o.closure ?? ''),
        Produto: formatProduct(o.product ?? ''),
        Moeda: getMoeda(Number(o.currency)),
        Valor: o.value,
        Taxa: o.rate,
        Banco: o.bank,
        'Data ME': formatDate(o.date_me ?? ''),
        'Data Reais': formatDate(o.date_reais ?? ''),
        Processos: o.processes?.join(', ')
      }
    })

    await ExcelExportXlsx.fromJSON(
      'exportacao',
      rows,
      [
        { wch: 30 },
        { wch: 20 },
        { wch: 15 },
        { wch: 7 },
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 60 }
      ],
      [4],
      [5]
    )
  }

  return (
    <>
      {BillingForm()}
      <CardPage title='Análise Boletagem'>
        <Col px={2} minH={'fill'} w={'100%'}>
          <SimpleFormGenerator
            rows={[
              {
                columns: 4,
                fields: [
                  {
                    type: 'select',
                    label: 'Tipo de Contrato',
                    name: 'contract_type',
                    options: [
                      {
                        label: () => 'A Pagar (Importação/Remessa)',
                        value: 'pagar'
                      },
                      {
                        label: () => 'A Receber (Exportação/Ingresso)',
                        value: 'receber'
                      },
                      {
                        label: () => 'Ingresso',
                        value: 'recebe_in'
                      },
                      {
                        label: () => 'Exportação',
                        value: 'recebe_ex'
                      },
                      {
                        label: () => 'Importação',
                        value: 'pagar_im'
                      },
                      {
                        label: () => 'Remessa',
                        value: 'pagar_re'
                      }
                    ]
                  },
                  createCustomerSearchPicker(0, undefined, {
                    label: 'Cliente',
                    name: 'customer',
                    labelKey: 'fantasy_name'
                  }),
                  {
                    type: 'text',
                    label: 'Cod. Cliente',
                    name: 'customer.id_syscambio',
                    readOnly: true
                  },
                  {
                    type: 'text',
                    label: 'CNPJ/CPF Cliente',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  },
                  {
                    type: 'date',
                    label: 'Período',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'até',
                    name: 'end_date'
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              const productTypeBySelect = {
                pagar: ['IMPORTACAO', 'REMESSA'],
                receber: ['EXPORTACAO', 'INGRESSO'],
                recebe_ex: ['EXPORTACAO'],
                recebe_in: ['INGRESSO'],
                pagar_im: ['IMPORTACAO'],
                pagar_re: ['REMESSA']
              }
              const { fantasy_name, document, id_syscambio } = v.customer || {}
              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  fantasy_name,
                  document,
                  id_syscambio
                }
              }

              if (v.contract_type) {
                const type: string = v.contract_type
                nextParams.contract_type = v.contract_type
                // @ts-ignore
                setDataInput(productTypeBySelect[type || 'pagar'])
              } else {
                setDataInput(null)
              }
              setSyscambio(id_syscambio)
              setDateInitials(v.start_date)
              setDateFinal(v.end_date)

              redirect(nextParams)
            }}
          />
          {showAlert && (
            <Alert status='warning'>
              <AlertIcon />
              Preencha todos os campos de filtro
            </Alert>
          )}
          <Collapse in={isLoading}>
            <Progress isIndeterminate colorScheme={'secondary'} h={1} />
          </Collapse>
          <VStack spacing={2} align='end'>
            <Box h='40px' mt={1}>
              <Button
                disabled={isLoading || process.length === 0}
                variant={'ghost'}
                color={'green.400'}
                colorScheme={'green'}
                mr='20px'
                onClick={() => {
                  exportExcel()
                }}
              >
                Exportação Excel
              </Button>
              <Button
                disabled={isLoading}
                variant={'outline'}
                color={'primary.400'}
                colorScheme={'primary'}
                onClick={() => {
                  loadData()
                  handleClearRows()
                  AppContext.billing_review = hashData
                }}
              >
                Buscar
              </Button>
            </Box>
          </VStack>
        </Col>
        <Col id='cont' minH={'60%'} px={2}>
          <Col
            mt={1}
            rounded={'lg'}
            overflow={'auto'}
            border={'1px solid transparent'}
            borderColor={'primary.600'}
          >
            <DataTable
              keyField='id'
              noDataComponent=''
              columns={columns}
              data={process}
              dense={true}
              fixedHeader={true}
              fixedHeaderScrollHeight={`${
                (document.getElementById('cont')?.clientHeight ?? 0) - 25
              }px`}
              striped={true}
            />
          </Col>
        </Col>
      </CardPage>
    </>
  )
}

export default BillingInternal
