import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  TableRowProps,
  TableColumnHeaderProps,
} from "@chakra-ui/react";
import React from "react";
import truncate from "lodash.truncate";

export type TableColumn = {
  title: string;
  key: string;
  colorCustom?: string;
  defaultLabel?: string;
  length?: number;
  renderColumn?: () => React.ReactNode;
  render?: (
    value: string | number | undefined,
    item: any,
    index: number
  ) => React.ReactNode;
};

interface TableRenderProps {
  config: {
    columns: TableColumn[];
  };
  data: any[];
  _thead?: {
    _tr?: TableRowProps;
    _th?: TableColumnHeaderProps;
  };
}

const TableRender: React.FC<TableRenderProps> = ({ config, data, _thead }) => {
  const RowColumns: React.FC = () => {
    return (
      <Tr {..._thead?._tr}>
        {config.columns.map((column, keyColumn) => (
          <Th key={`column${keyColumn}`} {..._thead?._th}>
            {column.renderColumn?.() || column.title}
          </Th>
        ))}
      </Tr>
    );
  };

  const getRowColumnValue = (
    rowData: any,
    column: TableColumn,
    index: number
  ) => {
    const nextValue = rowData[column.key];

    if (column.render) {
      const renderData = column.render(rowData[column.key], rowData, index);

      if (!renderData && column.defaultLabel) {
        return column.defaultLabel;
      }

      return renderData;
    }

    if (column.length) {
      return truncate(rowData[column.key], { length: column.length });
    }

    if (!nextValue && column.defaultLabel) {
      return column.defaultLabel;
    }

    return nextValue;
  };

  return (
    <>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <RowColumns />
          </Thead>
          <Tbody>
            {data.map((row, keyRow) => (
              <Tr key={`row${keyRow}`}>
                {config.columns.map((column, keyColumn) => {
                  const columnValue = getRowColumnValue(row, column, keyRow);
                  const isUndefined = column.defaultLabel === columnValue;

                  return (
                    <Td
                      p={2}
                      fontSize={"xs"}
                      key={`column${keyColumn}`}
                      title={
                        typeof columnValue !== "string"
                          ? row?.[column.key] || "Não definido"
                          : "Não definido"
                      }
                      fontStyle={isUndefined ? "italic" : "normal"}
                      color={isUndefined ? "gray.500" : "black"}
                    >
                      {columnValue}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <RowColumns />
          </Tfoot>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableRender;
