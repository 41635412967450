import { CurrencyModel } from "interfaces/currency";
import { ColumnFieldByName } from "v2/components/shared/SimpleFormGenerator";
import { apiClient } from "v2/services/clients/apiClient";

export function createCurrencySearchPicker(
  options?: Partial<ColumnFieldByName["search-picker"]>,
  initialItems?: CurrencyModel[]
): ColumnFieldByName["search-picker"] {
  return {
    type: "search-picker",
    label: "Moeda",
    name: "currency_code",
    labelKey: "Nome",
    onSearch: async () => {
      const items = initialItems || (await apiClient.currencies.all());

      return Promise.resolve({
        title: "Selecione uma Moeda",
        items: items,
        _modal: { size: "3xl", scrollBehavior: "inside" },
        uuidField: "Codigo",
        columns: [
          { title: "Cod. Moeda", key: "Codigo" },
          { title: "ISO", key: "CodigoISO" },
          { title: "Nome", key: "Nome" },
        ],
        search: {
          filters: {
            code: ["Codigo"],
            iso: ["CodigoISO"],
            name: ["Nome"],
          },
          initialValue: {
            code: "",
            iso: "",
            name: "",
          },
          rows: [
            {
              columns: 3,
              fields: [
                {
                  type: "text",
                  key: "code",
                  label: "Cod. Moeda",
                  placeholder: "Pesquisar por código",
                },
                {
                  type: "text",
                  key: "iso",
                  label: "Cod. ISO",
                  placeholder: "Pesquisar por ISO",
                },
                {
                  type: "text",
                  key: "name",
                  label: "Nome Moeda",
                  placeholder: "Pesquisar por nome",
                },
              ],
            },
          ],
        },
      });
    },
    ...options,
  };
}
