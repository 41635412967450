import useLocalTokens from "hooks/useLocalTokens";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import "./style.css";
import { Title } from "services/pdf/components/helpers";
import { Row } from "v2/components/shared/Row";

interface LocationState {
  from: {
    hash: string;
    pathname: string | undefined;
    search: string;
    state: undefined;
  };
}

const Loading = (): JSX.Element => {
  const location = useLocation<LocationState>();

  const nextUrl = useMemo(() => {
    let nextUrl: string | undefined = "";
    if (location.state) {
      nextUrl += `${location.state.from.pathname}${location.state.from.hash}`;
    } else {
      return undefined;
    }

    return nextUrl?.trim();
  }, [location]);

  useLocalTokens(nextUrl);

  return (
    <div className="containerLoad">
      <Row m={4}>
        <Spinner
          thickness="4px"
          speed="0.85s"
          emptyColor="gray.200"
          color="primary.400"
          size="xl"
        />
      </Row>
      <Row>
        <Title>{"Estamos sincronizando suas informações com a DASCAM, por favor aguarde"}</Title>
      </Row>

    </div>
  );
};

export default Loading;
