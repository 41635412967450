import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Progress,
  useBoolean
} from '@chakra-ui/react'
import { formatProduct } from 'helpers/formats'

import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DocumentItemProps } from 'v2/components/forms/documents/contracts'
import { OperationInfosValue } from 'v2/components/modules/operation/OperationInfos'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import DataTable, { TableColumn } from 'react-data-table-component'
import { ApiOperations } from 'v2/services/api/lib/api.operations'
import { apiClient } from 'v2/services/clients/apiClient'
import { getMoeda } from 'helpers/moedas'
import { isUnauthorized } from 'helpers/errors'
import { setGlobalMessage } from 'redux/actions'
import { useDispatch } from 'react-redux'

const ExchangeScheduleDetails: React.FC = ({ children }) => {
  const { id_process, id_syscambio } = useParams<any>()
  const [operationInfos, setOperationInfos] = useState<OperationInfosValue>(
    {} as OperationInfosValue
  )
  const dispatch = useDispatch()
  const [operationData, setOperation] = useState<ApiOperations.OperationProps>()
  const [documents, setDocuments] = useState<DocumentItemProps[]>([])
  const [isLoadingRefresh, loadRefresh] = useBoolean()
  let history = useHistory()
  const columns: TableColumn<any>[] = [
    {
      name: 'REFERÊNCIA',
      selector: (row) =>
        row.reference ?? row.reference !== '' ? row.reference : '---',
      sortable: true
    },
    {
      name: 'DOCUMENTO',
      selector: (row) => row.document_type,
      sortable: true
    },
    {
      name: 'NÚMERO',
      selector: (row) =>
        row.document_type === 'di'
          ? String(row.document_number)
              .replace(/\D/g, '')
              .replace(/(\d{2})(\d)/, '$1/$2')
              .replace(/(\d{7})(\d)/, '$1-$2')
              .replace(/(-\d{1})\d+?$/, '$1')
          : row.document_number,
      sortable: true
    },
    {
      name: 'VALOR DA APLICAÇÃO',
      selector: (row) =>
        Number(row.value_applied).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }),
      sortable: true,
      right: true
    },
    {
      name: 'VALOR DO DOCUMENTO',
      selector: (row) =>
        Number(row.value_total).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }),
      sortable: true,
      right: true
    }
  ]

  const onRefresh = useCallback(() => {
    try {
      loadRefresh.on()
      apiClient.process
        .show(id_process, id_syscambio)
        .then((operation) => {
          operation.product_type = formatProduct(operation.product_type)
          setOperation(() => operation)

          // @ts-ignore
          setOperationInfos(() => ({
            process: {
              number:
                Number(operation.process_number) === 0
                  ? undefined
                  : operation.process_number,
              type: formatProduct(operation.product_type),
              value: operation.process_value,
              expiration: operation.process_expiration
            }
          }))

          setDocuments(() => {
            const nextData = operation.documents?.map<DocumentItemProps>(
              (document) => ({
                balance: Number(document.balance_avaliable),
                document_number: document.document_number,
                document_type: document.document_type,
                value_applied: Number(document.applied_value),
                value_total: Number(document.total_value),
                files: [
                  {
                    isBase64: false,
                    src: document.attached_file,
                    mimeType: document.attached,
                    name: document.attached_name
                  }
                ],
                group_number: document.set_number,
                reference: document.document_reference,
                document_key: document.document_key,
                clearence_document: document.clearence_document,
                protocol: document.protocol
              })
            )

            return nextData || []
          })
        })
        .finally(() => {
          loadRefresh.off()
        })
    } catch (error) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    }
  }, [id_process, id_syscambio, loadRefresh])

  useEffect(() => {
    onRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_process, id_syscambio])

  if (!operationInfos.process)
    return (
      <CardPage
        title={'Dados do Processo'}
        _headerRight={
          <HStack>
            <Button
              onClick={() => history.goBack()}
              variant={'ghost'}
              color={'terciary.400'}
              colorScheme={'terciary_hover'}
              mr='20px'
            >
              Voltar
            </Button>
          </HStack>
        }
      >
        <Progress isIndeterminate colorScheme={'secondary'} h={1} />
      </CardPage>
    )

  return (
    <CardPage
      title={'Dados do Processo'}
      _headerRight={
        <HStack>
          <Button
            onClick={() => history.goBack()}
            variant={'ghost'}
            color={'terciary.400'}
            colorScheme={'terciary_hover'}
            mr='20px'
          >
            Voltar
          </Button>
        </HStack>
      }
    >
      <Col px={4}>
        <SimpleFormGenerator
          rows={[
            {
              columns: 4,
              fields: [
                {
                  type: 'text',
                  label: 'Referência',
                  name: 'refCli',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Tipo do Produto',
                  name: 'product_type',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Moeda',
                  name: 'currency_code',
                  mask (data) {
                    return getMoeda(Number(data))
                  },
                  readOnly: true
                },
                {
                  type: 'currency',
                  label: 'Valor do Processo',
                  name: 'process_value',
                  readOnly: true
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
        <SimpleFormGenerator
          rows={[
            {
              columns: 3,
              fields: [
                {
                  type: 'text',
                  label: 'Nome do Cliente',
                  name: 'client_name',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Cód. Beneficiário',
                  name: 'beneficiary_code',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Nome do Beneficiário',
                  name: 'beneficiary_name',
                  readOnly: true
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
        <SimpleFormGenerator
          rows={[
            {
              columns: 3,
              fields: [
                {
                  type: 'text',
                  label: 'Ident. Beneficiário',
                  name: 'pagRec.beneficiary_identification',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'País. Beneficiário',
                  name: 'pagRec.country_name',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Banco do Beneficiário',
                  name: 'pagRec.bank_info',
                  readOnly: true
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
        <SimpleFormGenerator
          rows={[
            {
              columns: 3,
              fields: [
                {
                  type: 'text',
                  label: 'Conta Beneficiário',
                  name: 'pagRec.bank_account',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'SWIFT Beneficiário',
                  name: 'pagRec.bank_swift',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'IBAN Beneficiário',
                  name: 'pagRec.bank_iban',
                  readOnly: true
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='b' flex='1' textAlign='left'>
                  Outros dados do Beneficiário/Intermediário
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <SimpleFormGenerator
                rows={[
                  {
                    columns: 3,
                    fields: [
                      {
                        type: 'text',
                        label: 'ABA Beneficiário',
                        name: 'pagRec.bank_aba',
                        readOnly: true
                      },
                      {
                        type: 'text',
                        label: 'SortCode Beneficiário',
                        name: 'pagRec.bank_sortcode',
                        readOnly: true
                      },
                      {
                        type: 'text',
                        label: 'BLZ Beneficiário',
                        name: 'pagRec.bank_blz',
                        readOnly: true
                      }
                    ]
                  }
                ]}
                value={operationData}
                onChange={(v) => {}}
              />
              <SimpleFormGenerator
                rows={[
                  {
                    columns: 3,
                    fields: [
                      {
                        type: 'text',
                        label: 'BranchCode Beneficiário',
                        name: 'pagRec.bank_branchcode',
                        readOnly: true
                      },
                      {
                        type: 'text',
                        label: 'BSB Beneficiário',
                        name: 'pagRec.bank_bsb',
                        readOnly: true
                      },
                      {
                        type: 'text',
                        label: 'TRAMSIT Beneficiário',
                        name: 'pagRec.bank_transit',
                        readOnly: true
                      }
                    ]
                  }
                ]}
                value={operationData}
                onChange={(v) => {}}
              />
              <SimpleFormGenerator
                rows={[
                  {
                    columns: 3,
                    fields: [
                      {
                        type: 'text',
                        label: 'Banco Intermediário',
                        name: 'pagRec.intermediary_info',
                        readOnly: true
                      },
                      {
                        type: 'text',
                        label: 'País Intermediário',
                        name: 'pagRec.intermediary_country_initials',
                        readOnly: true
                      },
                      {
                        type: 'text',
                        label: 'Conta Intermediário',
                        name: 'pagRec.intermediary_account',
                        readOnly: true
                      }
                    ]
                  }
                ]}
                value={operationData}
                onChange={(v) => {}}
              />
              <SimpleFormGenerator
                rows={[
                  {
                    columns: 3,
                    fields: [
                      {
                        type: 'text',
                        label: 'SWIFT Intermediário',
                        name: 'pagRec.intermediary_swift',
                        readOnly: true
                      },
                      {
                        type: 'text',
                        label: 'IBAN Intermediário',
                        name: 'pagRec.intermediary_iban',
                        readOnly: true
                      },
                      {
                        type: 'text',
                        label: 'ABA Intermediário',
                        name: 'pagRec.intermediary_aba',
                        readOnly: true
                      }
                    ]
                  }
                ]}
                value={operationData}
                onChange={(v) => {}}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Col>
      <Col px={4} my={2}>
        <Col>
          Documentos do Processo
          <Col
            rounded={'lg'}
            overflow={'hidden'}
            border={'2px solid transparent'}
            borderColor={'primary.500'}
          >
            <DataTable
              noDataComponent='Sem Documentos'
              columns={columns}
              data={documents ?? []}
              dense={true}
              fixedHeader={true}
              fixedHeaderScrollHeight={'350px'}
              striped={true}
            />
          </Col>
        </Col>
      </Col>
    </CardPage>
  )
}

export default ExchangeScheduleDetails
