import {
  Button,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { BiScreenshot } from "react-icons/bi";
import { Col } from "../Col";
import { Row } from "../Row";

interface ButtonConfirmProps {
  iconShow: React.ReactNode;
  iconHide: React.ReactNode;
  title: string;
  description?: string;
  onConfirm?: () => void;
  _default?: Partial<IconButtonProps>;
  _confirm?: Partial<IconButtonProps>;
  size?: number;
}

const ButtonConfirm: React.FC<ButtonConfirmProps> = ({
  iconHide,
  iconShow,
  title,
  description,
  onConfirm,
  _confirm,
  _default,
  size = 8,
}) => {
  const confirm = useDisclosure();

  const iconProps = useMemo(() => {
    if (confirm.isOpen) {
      return { ..._default, ..._confirm };
    }

    return _default;
  }, [confirm.isOpen, _confirm, _default]);

  return (
    <Col>
      <Popover {...confirm}>
        <PopoverTrigger>
          <IconButton
            minW={0}
            minH={0}
            p={0}
            w={size}
            h={size}
            aria-label="Change"
            onClick={confirm.onOpen}
            {...iconProps}
          >
            {/* <BiScreenshot /> */}
            {confirm.isOpen ? iconHide : iconShow}
          </IconButton>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontWeight={"bold"}>{title}</PopoverHeader>
          <PopoverBody shadow={"2xl"} p={0}>
            {description && (
              <Col px={4} pt={2}>
                {description}
              </Col>
            )}
            <Row px={4} py={2}>
              <Row flex={1} />
              <Button
                size={"xs"}
                onClick={() => {
                  confirm.onClose();
                  onConfirm?.();
                }}
              >
                Confirmar
              </Button>
            </Row>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Col>
  );
};

export default ButtonConfirm;
