import GenericButton from 'components/generic/GenericButton'
import { InputText } from 'components/generic/GenericInputs'
import Modal from 'components/generic/Modal'
import { axiosAuth } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { OperationModel } from 'interfaces/operation'
import { PayerReceiverModel } from 'interfaces/payiesReceivies'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import { GlobalState } from 'redux/types'
import styled from 'styled-components'
import { PayerReceiver } from './LinesModals'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const Button = styled(GenericButton)<{ isDeny?: boolean }>`
  margin: 0.3rem 0;
  width: 150px;
  color: ${(props) => props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
`

const CardGrid = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.1rem;
`

const TableGrid = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns:
    minmax(150px, 200px) minmax(400px, 1fr) minmax(80px, 130px)
    minmax(150px, 250px);
  grid-auto-rows: 35px;
  height: 100%;
  max-height: 600px;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

interface Props {
  showModal: boolean
  handleModal: () => void
  data: OperationModel
  changeData: (value: OperationModel) => void
}

const FindPayerReceiver = ({
  showModal,
  handleModal,
  data,
  changeData
}: Props): JSX.Element => {
  const user = useSelector((state: GlobalState) => state.user)
  const history = useHistory()
  const dispatch = useDispatch()
  const [payiesReceivies, setPayiesReceivies] = useState<PayerReceiverModel[]>(
    []
  )
  const [values, setValues] = useState<PayerReceiverModel>({
    CodCli: '',
    NomeBeneficiario: '',
    PaisBeneficiario: '',
    CodPaisBeneficiario: '',
    ContaBancoBeneficiario: ''
  })
  const [valuesFilter, setValuesFilter] = useState<PayerReceiverModel>({
    CodCli: '',
    NomeBeneficiario: '',
    PaisBeneficiario: '',
    CodPaisBeneficiario: '',
    ContaBancoBeneficiario: ''
  })

  const handleGetCurrency = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const res = await axiosAuth.get(
        `/pagreceb/${
          data.user_syscambio ? data.user_syscambio : user?.id_syscambio
        }`,
        config
      )

      setPayiesReceivies(res.data)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleFilter = () => {
    setValuesFilter({
      CodCli: values.CodCli,
      NomeBeneficiario: values.NomeBeneficiario,
      PaisBeneficiario: values.PaisBeneficiario,
      CodPaisBeneficiario: values.CodPaisBeneficiario,
      ContaBancoBeneficiario: values.ContaBancoBeneficiario
    })
  }

  const close = () => {
    handleModal()
    setValuesFilter({
      CodCli: '',
      NomeBeneficiario: '',
      PaisBeneficiario: '',
      CodPaisBeneficiario: '',
      ContaBancoBeneficiario: ''
    })
    setValues({
      CodCli: '',
      NomeBeneficiario: '',
      PaisBeneficiario: '',
      CodPaisBeneficiario: '',
      ContaBancoBeneficiario: ''
    })
  }

  useEffect(() => {
    if (showModal) {
      handleGetCurrency()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])
  return (
    <Modal
      title='Buscar Pagador/Recebedor'
      showModal={showModal}
      close={() => close()}
      backFocus
      width='100%'
      maxWidth='1100px'
      height='100%'
      maxHeight='85vh'
    >
      <Container>
        <ItemRowTop>
          <InputText
            label='Identificação'
            maxWidth='150px'
            value={values.CodCli}
            changeValue={(value) =>
              setValues({
                ...values,
                CodCli: value
              })
            }
            readOnly={
              String(values.ContaBancoBeneficiario) !== '' ||
              String(values.CodPaisBeneficiario) !== '' ||
              String(values.PaisBeneficiario) !== '' ||
              String(values.NomeBeneficiario) !== ''
            }
          />
          <InputText
            label='Pagador/Recebedor no Estrangeiro'
            maxWidth='350px'
            value={values.NomeBeneficiario}
            changeValue={(value) =>
              setValues({
                ...values,
                NomeBeneficiario: value
              })
            }
            readOnly={
              String(values.ContaBancoBeneficiario) !== '' ||
              String(values.CodPaisBeneficiario) !== '' ||
              String(values.PaisBeneficiario) !== '' ||
              String(values.CodCli) !== ''
            }
          />
          <InputText
            label='País'
            maxWidth='100px'
            value={values.PaisBeneficiario}
            changeValue={(value) =>
              setValues({
                ...values,
                PaisBeneficiario: value
              })
            }
            readOnly={
              String(values.ContaBancoBeneficiario) !== '' ||
              String(values.CodPaisBeneficiario) !== '' ||
              String(values.NomeBeneficiario) !== '' ||
              String(values.CodCli) !== ''
            }
          />
          {/* <InputText
            label="Código"
            maxWidth="150px"
            value={values.CodPaisBeneficiario}
            changeValue={value =>
              setValues({
                ...values,
                CodPaisBeneficiario: value,
              })
            }
            readOnly={
              String(values.ContaBancoBeneficiario) !== "" ||
              String(values.PaisBeneficiario) !== "" ||
              String(values.NomeBeneficiario) !== "" ||
              String(values.CodCli) !== ""
            }
          /> */}
          <InputText
            label='Conta'
            maxWidth='200px'
            value={values.ContaBancoBeneficiario}
            changeValue={(value) =>
              setValues({
                ...values,
                ContaBancoBeneficiario: value
              })
            }
            readOnly={
              String(values.CodPaisBeneficiario) !== '' ||
              String(values.PaisBeneficiario) !== '' ||
              String(values.NomeBeneficiario) !== '' ||
              String(values.CodCli) !== ''
            }
          />
          <Button type='button' onClick={() => handleFilter()}>
            Buscar
          </Button>
        </ItemRowTop>
        <CardGrid>
          <TableGrid>
            <GridTitle>
              <Text fontWeight='bold'>Identificação</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Pagador/Recebedor no Estrangeiro</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>País</Text>
            </GridTitle>
            {/* <GridTitle>
              <Text fontWeight="bold">Código</Text>
            </GridTitle> */}
            <GridTitle>
              <Text fontWeight='bold'>Conta</Text>
            </GridTitle>
            {/*Mudar depois*/}
            {payiesReceivies
              .filter(
                (e) =>
                  String(e.CodPaisBeneficiario)
                    ?.toLowerCase()
                    .indexOf(
                      String(valuesFilter.CodPaisBeneficiario).toLowerCase()
                    ) > -1 &&
                  String(e.PaisBeneficiario)
                    ?.toLowerCase()
                    .indexOf(
                      String(valuesFilter.PaisBeneficiario).toLowerCase()
                    ) > -1 &&
                  String(e.NomeBeneficiario)
                    ?.toLowerCase()
                    .indexOf(
                      String(valuesFilter.NomeBeneficiario).toLowerCase()
                    ) > -1 &&
                  String(e.CodCli)
                    ?.toLowerCase()
                    .indexOf(String(valuesFilter.CodCli).toLowerCase()) > -1 &&
                  String(e.ContaBancoBeneficiario)
                    ?.toLowerCase()
                    .indexOf(
                      String(valuesFilter.ContaBancoBeneficiario).toLowerCase()
                    ) > -1
              )
              .map((payerReceiver, index) => {
                let last = false
                if (index === payiesReceivies.length - 1) {
                  last = true
                }
                return (
                  <PayerReceiver
                    last={last}
                    index={index}
                    payerReceiver={payerReceiver}
                    handleModal={close}
                    data={data}
                    changeData={changeData}
                  />
                )
              })}
          </TableGrid>
        </CardGrid>
      </Container>
    </Modal>
  )
}

export default FindPayerReceiver
