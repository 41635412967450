import styled from "styled-components";
import { devices } from "helpers/devices";
import GenericButton from "components/generic/GenericButton";
import { InputImgBase64 } from "components/generic/GenericInputs";
import { useEffect, useState } from "react";
import { UserModel } from "interfaces/user";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "redux/types";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { setGlobalMessage, clearLoading, showLoading } from "redux/actions";
import { isUnauthorized } from "helpers/errors";
import { axiosAuth } from "helpers/axiosInstances";
import { BankModel } from "interfaces/bank";
import BankAccountForeign from "./subComponents/BankAccountForeign";
import DocumentationForeign from "./subComponents/DocumentationForeign";
import RegisterForeign from "./subComponents/RegisterForeign";
import { ArchiveModel } from "interfaces/user-archives";
import { useGlobals } from "hooks/helpers/useGlobals";
import { Permissions } from "interfaces/web/permission";

const ForeignStyled = styled.section`
  background: transparent;
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  /* overflow-y: auto; */
`;

const Container = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */

  @media ${devices.phoneOnly} {
    background-position: center left;
  }
`;

const TemplateFlex = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem ${props => props.theme.margins.from_sides};

  @media ${devices.phoneOnly} {
    padding: 1rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 2rem ${props => props.theme.margins.from_sides};
  }
`;

const CardForm = styled.form`
  padding: 1rem;
  box-shadow: 1px 2px 4px gray;
  background-color: ${props => props.theme.color.white1};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
`;

const CardMenu = styled.div`
  width: 300px;
  min-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const CardItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.5rem;
  overflow-y: auto;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 100;
  background-color: ${props => props.theme.color.white1};
  height: auto;
  width: 100%;
  position: sticky;
  top: -1px;
  left: 0;
  padding: 0.3rem;

  @media ${devices.tabletPortrait} {
    align-items: flex-start;
  }

  @media ${devices.bigDesktop} {
    align-items: center;
  }
`;

const TabsBlue = styled.div`
  width: 100%;
  height: auto;
  padding: 0.2rem;
  background: ${props => props.theme.color.primary_main};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & div {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }
`;

const ButtonTabs = styled(GenericButton)<{
  isDeny?: boolean;
  isYellow?: boolean;
  isBlue?: boolean;
}>`
  width: auto;
  min-width: 120px;
  height: 25px;
  margin: 0.1rem;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  color: ${props => props.theme.color.white1};
  font-size: 0.9rem;
  background-color: ${props =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isYellow
      ? props.theme.color.secondary_main
      : props.isBlue
      ? "#296ba9"
      : props.theme.color.button_normal} !important;

  &:hover {
    background-color: ${props =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isYellow
        ? props.theme.color.secondary_light
        : props.isBlue
        ? "#4b87be"
        : props.theme.color.button_normal_hover} !important;
  }
`;

type Tabs = "registration" | "bank" | "documentation";
interface PathNav {
  id: string;
  email: string;
}

const ForeignReading = (): JSX.Element => {
  // const theme = useContext(ThemeContext);
  const [currentTab, setCurrentTab] = useState<Tabs>("registration");
  // const user = useSelector((state: GlobalState) => state.user);
  const { user, hasSomePermissions } = useGlobals();
  const history = useHistory();
  const dispatch = useDispatch();
  //User State
  const [userData, setUserData] = useState<UserModel>({});
  //Conta Bancaria State
  const [accountBanks, setAccountBanks] = useState<BankModel[]>([]);
  //Documentation
  const [userDocumentation, setUserDocumentation] = useState<ArchiveModel>({});
  const pathNav = useParams<PathNav>();

  const getUserData = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
          subMessage: "Carregando dados do usuário",
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/users/${pathNav.id}`, config);

      setUserData(data);
      getDocumentData();
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    }
  };

  const getDocumentData = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
          subMessage: "Carregando documentos",
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/archives/${pathNav.id}`, config);

      setUserDocumentation(data);
      getBankData();
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    }
  };

  const getBankData = async () => {
    try {
      dispatch(
        showLoading({
          message: "Finalizando",
          isLoading: true,
          subMessage: "Aplicando dados do usuário",
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/bank/${pathNav.id}`, config);

      setAccountBanks(data);
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* {!hasSomePermissions([
        Permissions.CADASTRO_VIEW,
        Permissions.COMPLIANCE_VIEW,
        Permissions.DIRETORIA_VIEW,
      ]) && <Redirect to="/unauthorized" />} */}
      <ForeignStyled>
        <Container>
          <TemplateFlex>
            <CardForm
              onSubmit={event => {
                event.preventDefault();
              }}
            >
              <CardMenu>
                <div>
                  <InputImgBase64
                    avatar={userData.photo!}
                    changeValue={value =>
                      setUserData({
                        ...userData,
                        photo: value,
                      })
                    }
                    readOnly
                  />
                </div>
              </CardMenu>
              <CardItems>
                <TabsContainer>
                  <TabsBlue>
                    <div>
                      <ButtonTabs
                        isBlue={currentTab !== "registration"}
                        isYellow={currentTab === "registration"}
                        type="button"
                        onClick={() => setCurrentTab("registration")}
                      >
                        Pré - cadastro *
                      </ButtonTabs>
                      <ButtonTabs
                        isBlue={currentTab !== "documentation"}
                        isYellow={currentTab === "documentation"}
                        type="button"
                        onClick={() => setCurrentTab("documentation")}
                      >
                        Documentação
                      </ButtonTabs>
                      <ButtonTabs
                        isBlue={currentTab !== "bank"}
                        isYellow={currentTab === "bank"}
                        type="button"
                        onClick={() => setCurrentTab("bank")}
                      >
                        Dados Bancários
                      </ButtonTabs>
                    </div>
                    <div>
                      <>
                        <ButtonTabs
                          isDeny
                          type="button"
                          onClick={() =>
                            history.push("/dascam/registration-management")
                          }
                        >
                          Voltar
                        </ButtonTabs>
                      </>
                    </div>
                  </TabsBlue>
                </TabsContainer>
                {currentTab === "registration" ? (
                  <RegisterForeign
                    userData={userData}
                    changeUserData={value => setUserData(value)}
                  />
                ) : null}
                {currentTab === "documentation" ? (
                  <DocumentationForeign
                    userDocumentation={userDocumentation}
                    changeDocumentation={value => setUserDocumentation(value)}
                  />
                ) : null}
                {currentTab === "bank" ? (
                  <BankAccountForeign
                    userId={user?.id}
                    accountBanks={accountBanks}
                    getBankData={() => getBankData()}
                    userData={userData}
                  />
                ) : null}
              </CardItems>
            </CardForm>
          </TemplateFlex>
        </Container>
      </ForeignStyled>
    </>
  );
};

export default ForeignReading;
