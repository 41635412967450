import ButtonsTab from "components/generic/ButtonsTab";
import GenericButton from "components/generic/GenericButton";
import Export from "components/restricted-area/registration-data/data/pre-closing/Export";
import HomePreClosing from "components/restricted-area/registration-data/data/pre-closing/HomePreClosing";
import { HomeSpreadsheetsPreClosing } from "components/restricted-area/registration-data/data/pre-closing/HomeSpreadsheetsPreClosing";
import Import from "components/restricted-area/registration-data/data/pre-closing/Import";
import Shipping from "components/restricted-area/registration-data/data/pre-closing/Shipping";
import Ticket from "components/restricted-area/registration-data/data/pre-closing/Ticket";
import TypePreClosing from "components/restricted-area/registration-data/data/pre-closing/TypePreClosing";
import { devices } from "helpers/devices";
import { ExcelStatusEnum } from "interfaces/operation_excel";
import { useState } from "react";
import styled from "styled-components";

const PreClosingStyled = styled.section`
  background: transparent;
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  /* overflow-y: auto; */
`;

const Container = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */

  @media ${devices.phoneOnly} {
    background-position: center left;
  }
`;

const TemplateFlex = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem ${props => props.theme.margins.from_sides};

  @media ${devices.phoneOnly} {
    padding: 1rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 2rem ${props => props.theme.margins.from_sides};
  }
`;

const Card = styled.div`
  box-shadow: 1px 2px 4px gray;
  background-color: ${props => props.theme.color.white1};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
`;

type ElementRender =
  | "Home"
  | "Types"
  | "Shipping"
  | "Ticket"
  | "Import"
  | "HomeSpreadSheets"
  | "Export";

const PreCLosing = (): JSX.Element => {
  const [render, setRender] = useState<ElementRender>("Home");
  const [isReadOperation, setIsReadOperation] = useState<boolean>(false);
  const [isEditOperation, setIsEditOperation] = useState<boolean>(false);
  const [operationId, setOperationId] = useState<number>(0);
  const [isForm, setForm] = useState(false);

  return (
    <PreClosingStyled>
      <Container>
        <TemplateFlex>
          <Card>
            {["Home", "HomeSpreadSheets"].includes(render) && !isForm && (
              <ButtonsTab
                align="right"
                value={render}
                buttons={[
                  { label: "Preenchimento Manual", value: "Home" },
                  { label: "Envio de Planilha", value: "HomeSpreadSheets" },
                ]}
                onChange={setRender as any}
              />
            )}
            {render === "Home" ? (
              <>
                <HomePreClosing
                  typeOperation={render as ElementRender}
                  changeRender={value => setRender(value as ElementRender)}
                  isReadOperation={isReadOperation}
                  setIsReadOperation={setIsReadOperation}
                  isEditOperation={isEditOperation}
                  setIsEditOperation={setIsEditOperation}
                  setOperationId={setOperationId}
                />
              </>
            ) : null}
            {render === "HomeSpreadSheets" ? (
              <>
                <HomeSpreadsheetsPreClosing
                  title={"NOVO CONTRATO DE OPERAÇÃO - Excel"}
                  canAdd
                  statusList={[
                    { label: "Pendente", value: ExcelStatusEnum.PENDING },
                    { label: "Enviados", value: ExcelStatusEnum.SENT },
                  ]}
                  onSave={() => setRender("HomeSpreadSheets")}
                  onOpenForm={() => setForm(() => true)}
                  onCloseForm={() => setForm(() => false)}
                />
              </>
            ) : null}
            {render === "Types" ? (
              <TypePreClosing
                typeOperation={render as ElementRender}
                changeRender={value => setRender(value as ElementRender)}
              />
            ) : null}
            {render === "Shipping" ? (
              <Shipping
                typeOperation={render as ElementRender}
                changeRender={value => setRender(value as ElementRender)}
                isReadOperation={isReadOperation}
                setIsReadOperation={setIsReadOperation}
                isEditOperation={isEditOperation}
                setIsEditOperation={setIsEditOperation}
                operationId={operationId}
              />
            ) : null}
            {render === "Ticket" ? (
              <Ticket
                typeOperation={render as ElementRender}
                changeRender={value => setRender(value as ElementRender)}
                isReadOperation={isReadOperation}
                setIsReadOperation={setIsReadOperation}
                isEditOperation={isEditOperation}
                setIsEditOperation={setIsEditOperation}
                operationId={operationId}
              />
            ) : null}
            {render === "Import" ? (
              <Import
                typeOperation={render as ElementRender}
                changeRender={value => setRender(value as ElementRender)}
                isReadOperation={isReadOperation}
                setIsReadOperation={setIsReadOperation}
                isEditOperation={isEditOperation}
                setIsEditOperation={setIsEditOperation}
                operationId={operationId}
              />
            ) : null}
            {render === "Export" ? (
              <Export
                typeOperation={render as ElementRender}
                changeRender={value => setRender(value as ElementRender)}
                isReadOperation={isReadOperation}
                setIsReadOperation={setIsReadOperation}
                isEditOperation={isEditOperation}
                setIsEditOperation={setIsEditOperation}
                operationId={operationId}
              />
            ) : null}
          </Card>
        </TemplateFlex>
      </Container>
    </PreClosingStyled>
  );
};

export default PreCLosing;
