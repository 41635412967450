import { axiosAuth } from "helpers/axiosInstances";
import { UserModel } from "interfaces/user";

export default function useUserAPI() {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  type FetchOneUser = {
    id: string;
  };

  const fetchOneUser = async ({ id }: FetchOneUser) => {
    try {
      return await axiosAuth.get<UserModel>(`/users-macro/${id}`, config);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return [fetchOneUser] as const;
}
