import { useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "redux/types";

export default function useAuth() {
  const user = useSelector((state: GlobalState) => state.user);

  const saveAuthToStorage = () => {
    localStorage.setItem("logged_user", String(user!.id));
  };

  const hasTokens = () =>
    localStorage.getItem("access_token") != null &&
    localStorage.getItem("refresh_token") != null;

  const hasUserId = () => user !== undefined;

  const isAuth = () => hasTokens() && hasUserId();

  useEffect(() => {
    if (isAuth()) {
      saveAuthToStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuth();
}
