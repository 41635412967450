import GenericButton from 'components/generic/GenericButton'
import {
  InputDate,
  InputName,
  InputSelect,
  InputText
} from 'components/generic/GenericInputs'
import styled from 'styled-components'
import { ReactComponent as LupaIcon } from 'assets/lupaIcon.svg'
import usePagination from 'hooks/usePagination'
import { devices } from 'helpers/devices'
import Operation from './subComponents/LineOperations'
import FindCustomer from './subComponents/FindCustomer'
import { useEffect, useState } from 'react'
import FindCurrency from './subComponents/FindCurrency'
import FindPayerReceiver from './subComponents/FindPayerReceiver'
import {
  OperationModel,
  OperationStatusEnum,
  OperationTypeEnum,
  ProductTypeEnum
} from 'interfaces/operation'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearGlobalMessage,
  setGlobalMessage,
  clearLoading,
  showLoading
} from 'redux/actions'
import { axiosWebsite } from 'helpers/axiosInstances'
import { useHistory } from 'react-router-dom'
import { isUnauthorized } from 'helpers/errors'
import { GlobalState } from 'redux/types'
import { BiSearchAlt2 } from 'react-icons/bi'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
`

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
`

const TitlePage = styled.h4`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray2};
  align-self: flex-start;
  padding: 1rem;
`

const ButtonConfirm = styled(GenericButton)<{ isDeny?: boolean }>`
  margin: 1rem 0.2rem;
  color: ${(props) => props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`

const ButtonYellow = styled.button<{ marginBottom?: string }>`
  width: 40px;
  height: 40px;
  padding: 0.3rem;
  background-color: ${(props) => props.theme.color.secondary_main};
  border-radius: 6px;
  outline: none;
  border: none;
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  cursor: pointer;
  transform: scale(0.95);
  margin-bottom: ${(props) => props.marginBottom};

  & svg {
    width: auto;
    height: 100%;
    & path {
      fill: ${(props) => props.theme.color.primary_main};
    }
  }

  &:hover {
    transform: scale(1);
  }
`

const Pagination = styled.ul`
  display: flex;
  list-style: none;
  margin: 0.5rem 2rem;
`

const PageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: ${(props) => props.theme.color.button_normal};
  color: ${(props) => props.theme.color.white1};
  border-radius: 50%;
  height: 1.4rem;
  width: 1.4rem;
  padding: 0.2rem;
  border: none;
  outline: none;
  cursor: pointer;
`

const PageNum = styled.p<{ isActive: boolean }>`
  margin: 0 0.2rem;
  color: ${(props) =>
    props.isActive ? props.theme.color.gray1 : props.theme.color.gray2};
  font-weight: ${(props) => (props.isActive ? 'bold' : null)};
  cursor: pointer;

  @media ${devices.desktop} {
    font-size: 0.9rem;
  }

  @media ${devices.bigDesktop} {
    font-size: 1rem;
  }
`

const CardGrid = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

const TableGrid = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns:
    minmax(120px, 150px) minmax(120px, 150px) minmax(250px, 300px) minmax(
      80px,
      150px
    )
    minmax(80px, 150px) minmax(80px, 150px) minmax(80px, 150px) minmax(
      100px,
      150px
    )
    minmax(250px, 300px) auto;
  grid-auto-rows: 35px;
  height: 100%;
  width: auto;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

const TieButton = styled.div`
  display: flex;
  flex-direction: row;
`

const ButtonToModal = styled(GenericButton)<{
  isDeny?: boolean
  isDisabled?: boolean
}>`
  margin: 0.2rem 0;
  width: auto;
  // min-width: 200px;
  color: ${(props) =>
    props.isDeny
      ? props.theme.color.white1
      : props.isDisabled
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isDisabled
      ? props.theme.color.gray3
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isDisabled
        ? props.theme.color.gray4
        : props.theme.color.secondary_light};
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }
`

interface Props {
  typeOperation: string
  changeRender: (value: string) => void
  isReadOperation: boolean
  setIsReadOperation: (value: boolean) => void
  setOperationId: (value: number) => void
}

const ContractReleased = ({
  typeOperation,
  changeRender,
  isReadOperation,
  setIsReadOperation,
  setOperationId
}: Props): JSX.Element => {
  const user = useSelector((state: GlobalState) => state.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const pageLimit = 20
  const [currentPage, pages, nextPage, prevPage, , goToPage, totalPages] =
    usePagination(pageLimit)
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
  const [showCurrencyModal, setShowCurrencyModal] = useState<boolean>(false)
  const [showPayerReceiverModal, setShowPayerReceiverModal] =
    useState<boolean>(false)
  const [dataInput, setDataInput] = useState<OperationModel>(
    {} as OperationModel
  )
  const [dateInitials, setDateInitials] = useState<string>('')
  const [dateFinal, setDateFinal] = useState<string>('')
  const [operationData, setOperationData] = useState<OperationModel[]>([])

  const handleFilter = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosWebsite.get(
        `/operation-filter?limit=${pageLimit}&offset=${
          (currentPage - 1) * pageLimit
        }&status=APPROVAL,RELEASE${
          user?.id !== undefined ? '&user_id=' + user.id : ''
        }${
          dataInput.product_type !== ('' as ProductTypeEnum) &&
          dataInput.product_type !== undefined
            ? '&product_type=' + dataInput.product_type
            : ''
        }${
          dataInput.client_code !== '' && dataInput.client_code !== undefined
            ? '&client_code=' + dataInput.client_code
            : ''
        }${
          dataInput.client_name !== '' && dataInput.client_name !== undefined
            ? '&client_name=' + dataInput.client_name
            : ''
        }${
          dataInput.client_document !== '' &&
          dataInput.client_document !== undefined
            ? '&client_document=' + dataInput.client_document
            : ''
        }${
          dateInitials !== '' && dateFinal !== ''
            ? '&date=' + [dateInitials, dateFinal]
            : ''
        }${
          dataInput.operation_type !== ('' as OperationTypeEnum) &&
          dataInput.operation_type !== undefined
            ? '&operation_type=' + dataInput.operation_type
            : ''
        }${
          dataInput.currency_name !== '' &&
          dataInput.currency_name !== undefined
            ? '&currency_name=' + dataInput.currency_name
            : ''
        }${
          dataInput.currency_iso !== '' && dataInput.currency_iso !== undefined
            ? '&currency_iso=' + dataInput.currency_iso
            : ''
        }${
          dataInput.beneficiary_name !== '' &&
          dataInput.beneficiary_name !== undefined
            ? '&beneficiary_name=' + dataInput.beneficiary_name
            : ''
        }`,
        config
      )
      setOperationData(data.operation)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const getOperationData = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosWebsite.get(
        `/operation-filter?limit=${pageLimit}&offset=${
          (currentPage - 1) * pageLimit
        }&status=APPROVAL,RELEASE${
          user?.id !== undefined ? '&user_id=' + user.id : ''
        }`,
        config
      )

      setOperationData(data.operation)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleDeleteItem = (id: number) => {
    dispatch(
      setGlobalMessage({
        message:
          'Deseja realmente excluir este item? \n O item será excluido direto da base de dados',
        type: 'MESSAGE',
        buttons: (
          <>
            <ButtonToModal
              onClick={() => {
                handleDeleteOperation(id)
                dispatch(clearGlobalMessage())
              }}
            >
              Ok
            </ButtonToModal>
            <ButtonToModal
              isDeny
              onClick={() => {
                dispatch(clearGlobalMessage())
              }}
            >
              Cancelar
            </ButtonToModal>
          </>
        )
      })
    )
  }

  const handleDeleteOperation = async (id: number) => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite.delete(`/operation?id=${id}`, config)
      getOperationData()
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const clearInputs: OperationModel = {
    product_type: '' as ProductTypeEnum,
    client_code: '',
    client_name: '',
    client_document: '',
    status: '' as OperationStatusEnum,
    currency_name: '',
    beneficiary_name: ''
  } as OperationModel

  useEffect(() => {
    getOperationData()
    setIsReadOperation(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container>
        <ItemRowTop>
          <TitlePage>Meus contratos a Liberar</TitlePage>
        </ItemRowTop>
        <ItemRow>
          <InputSelect
            label='Tipo de contrato'
            maxWidth='200px'
            value={dataInput.product_type}
            changeValue={(value) =>
              setDataInput({
                ...dataInput,
                product_type: value as ProductTypeEnum
              })
            }
          >
            <option value='' selected>
              Todos
            </option>
            <option value='REMESSA'>Remessa</option>
            <option value='INGRESSO'>Ingresso</option>
            <option value='IMPORTACAO'>Importação</option>
            <option value='EXPORTACAO'>Exportação</option>
          </InputSelect>
          <InputName
            label='Empresa'
            maxWidth='300px'
            value={dataInput.client_name}
            changeValue={(value) =>
              setDataInput({
                ...dataInput,
                client_name: value
              })
            }
          />
          <ButtonYellow
            type='button'
            marginBottom='.3rem'
            onClick={() => setShowCustomerModal(!showCustomerModal)}
          >
            <BiSearchAlt2 />
          </ButtonYellow>
          {/* <InputText
            label="Sequência SYSCâmbio"
            maxWidth="300px"
            value={dataInput.beneficiary_name}
            changeValue={value =>
              setDataInput({
                ...dataInput,
                beneficiary_name: value,
              })
            }
          /> */}
          <InputText
            label='Pagador/Recebedor'
            maxWidth='300px'
            value={dataInput.beneficiary_name}
            changeValue={(value) =>
              setDataInput({
                ...dataInput,
                beneficiary_name: value
              })
            }
          />
          <ButtonYellow
            type='button'
            marginBottom='.3rem'
            onClick={() => setShowPayerReceiverModal(!showPayerReceiverModal)}
          >
            <BiSearchAlt2 />
          </ButtonYellow>
          <InputDate
            label='Período'
            maxWidth='200px'
            value={dateInitials}
            changeValue={(value) => setDateInitials(value)}
          />
          <InputDate
            label='até'
            maxWidth='200px'
            value={dateFinal}
            changeValue={(value) => setDateFinal(value)}
          />
        </ItemRow>
        <ItemRow>
          <InputText
            label='Moeda'
            maxWidth='300px'
            value={dataInput.currency_name}
            changeValue={(value) =>
              setDataInput({
                ...dataInput,
                currency_name: value
              })
            }
          />
          <ButtonYellow
            type='button'
            marginBottom='.3rem'
            onClick={() => setShowCurrencyModal(!showCurrencyModal)}
          >
            <BiSearchAlt2 />
          </ButtonYellow>
        </ItemRow>
        <ItemRowTop>
          <Pagination>
            {currentPage > 1 ? (
              <PageButton title='Página anterior' onClick={() => prevPage()}>
                {'<'}
              </PageButton>
            ) : null}
            {pages.map((page) => (
              <PageNum
                title={`Página ${page}`}
                isActive={currentPage === Number(page)}
                onClick={() => goToPage(page)}
              >
                {page}
              </PageNum>
            ))}
            {currentPage < totalPages() ? (
              <PageButton title='Próxima página' onClick={() => nextPage()}>
                {'>'}
              </PageButton>
            ) : null}
          </Pagination>
          <TieButton>
            {dataInput !== clearInputs ? (
              <ButtonConfirm
                type='button'
                onClick={() => {
                  setDataInput(clearInputs)
                  setDateInitials('')
                  setDateFinal('')
                  getOperationData()
                }}
              >
                Limpar Filtro
              </ButtonConfirm>
            ) : null}
            <ButtonConfirm type='button' onClick={() => handleFilter()}>
              Buscar operação
            </ButtonConfirm>
          </TieButton>
        </ItemRowTop>
        <CardGrid>
          <TableGrid>
            <GridTitle>
              <Text fontWeight='bold'>Tipo de Contrato</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Tipo de operação</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Divisão</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Sequência</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Fechamento</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Liquidação</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Moeda</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Valor Total</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Pagador/Recebedor</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Ações</Text>
            </GridTitle>
            {/*Mudar depois*/}
            {operationData.map((operation, index) => {
              let last = false
              if (index === operationData.length - 1) {
                last = true
              }
              return (
                <Operation
                  last={last}
                  index={index}
                  operation={operation}
                  changeRender={changeRender}
                  isReadOperation={isReadOperation}
                  setIsReadOperation={setIsReadOperation}
                  setOperationId={setOperationId}
                  handleDeleteItem={handleDeleteItem}
                  getOperationData={getOperationData}
                />
              )
            })}
          </TableGrid>
        </CardGrid>
      </Container>
      <FindCustomer
        showModal={showCustomerModal}
        handleModal={() => setShowCustomerModal(!showCustomerModal)}
        data={dataInput}
        changeData={setDataInput}
      />
      <FindCurrency
        showModal={showCurrencyModal}
        handleModal={() => setShowCurrencyModal(!showCurrencyModal)}
        data={dataInput}
        changeData={setDataInput}
      />
      <FindPayerReceiver
        showModal={showPayerReceiverModal}
        handleModal={() => setShowPayerReceiverModal(!showPayerReceiverModal)}
        data={dataInput}
        changeData={setDataInput}
      />
    </>
  )
}

export default ContractReleased
