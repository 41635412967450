import { ApiContext } from "../ApiContext";

export class ApiOperations extends ApiContext {
  public async send(data: Partial<ApiOperations.OperationProps>) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http.post(`/process`, data, config).then((res) => res.data);
  }

  public async delete(id: string | number) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http.delete(`/process?id=${id}`, config).then((res) => res.data);
  }

  public async sendDascam(data: Partial<ApiOperations.OperationProps>) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http
      .post(`/process/create`, data, config)
      .then((res) => res.data);
  }

  public async processFilter(
    params: Partial<ApiOperations.ProcessFilterProps>
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http
      .post(`/process-filter`, params, config)
      .then((res) => res.data);
  }

  public async processLoadSpreadSheets(
    params: Partial<ApiOperations.ProcessFilterProps>
    , isDascamUser: boolean
  ) {
    /*let payload: any = {};
    if (params.id) {
      payload.id = params.id as number;
    } else {
      payload = {
        id_syscambio: isDascamUser ? undefined : params.id_syscambio,
        limit: 20000,
        offset: 0,
        initial_date: params.date ? params.date[0] : undefined,
        end_date: params.date ? params.date[1] : undefined,
        status: params.status ? params.status : [],
      };
    }*/
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http
      .post("/operation/excel/list", params, config);
  }

  public async processSaveSpreadsheet(
    params: any
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http
      .post("/operation/excel", params, config);
  }

  public async show(id: string | number) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http
      .get<ApiOperations.OperationProps>(`/process/${id}`, config)
      .then((res) => res.data);
  }

  public async checkBalance(
    props: ApiOperations.CheckBalanceRequest
  ): Promise<ApiOperations.CheckBalanceResponse> {
    // return { balance: 50 };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http
      .post<ApiOperations.CheckBalanceResponse>(`/process/validasaldo`, {
        docs: props,
      }, config)
      .then((res) => res.data)
      .catch(() => ({
        balance: 0,
        flag: "error",
      }));
  }
}

export namespace ApiOperations {
  export interface CheckBalanceRequest {
    id_syscambio: string;
    document_number: string;
    document_type: string;
  }

  export interface CheckBalanceResponse {
    balance: number;
    total?: number;
    saldo?: number;
    referencia?: string;
    flag: "newDoc" | string;
  }

  interface BeneficiaryInfo {
    beneficiary_identification: string;
    country_code: number;
    country_name: string;
    country_initials: string;
    beneficiary_address: string;
    beneficiary_city: string;
    bank_info: string;
    bank_country_code: number;
    bank_country: string;
    bank_city: string;
    bank_address: string;
    bank_swift: string;
    bank_aba: string;
    bank_chips: string;
    bank_account: string;
    bank_iban: string;
    bank_sortcode: string;
    bank_blz: string;
    bank_branchcode: string;
    bank_bsb: string;
    bank_transit: string;
    bank_cnaps: string;
  }

  export interface OperationProps {
    id: number;
    refCli?: string;
    user_id: number;
    product_type: string;
    process_number: number;
    id_syscambio: number | string;
    client_name: string;
    client_document: string;
    beneficiary_code: number;
    beneficiary_name: string;
    beneficiary_info: {
      beneficiary: Partial<BeneficiaryInfo>;
      intermediary: Partial<BeneficiaryInfo>;
    };
    nature_fact: string;
    nature_client_type: string;
    nature_aval: string;
    nature_beneficiary: string;
    nature_group: string;
    currency_code: string;
    currency_iso: string;
    currency_name: string;
    process_value: string;
    process_expiration: string;
    documents: Array<{
      clearence_document: string;
      protocol: string;
      document_type: string;
      document_number: string;
      document_value: string;
      document_reference: string;
      document_key: string;
      document_issue: string;
      document_company: string;
      document_endorsement: string;
      document_additions: string;
      applied_value: string;
      balance_avaliable: string;
      total_value: string;
      departure_date: string;
      expiration_date: string;
      attached_file: string;
      attached_name: string;
      attached: string;
      set_number: string;
    }>;
    status: string;
    created_date: string;
    created_by_id: number;
    updated_date: string;
    updated_by_id: number;
    observation: ObservationProps;
  }

  export interface ProcessFilterProps {
    id: number;
    user_id: number;
    product_type: string;
    id_syscambio: number;
    client_name: string;
    currency_iso: string;
    currency_name: string;
    beneficiary_name: string;
    process_value: string;
    process_number: number;
    status: string[];
    date: [string, string];
    nature_fact: string;
    nature_client_type: string;
    nature_aval: string;
    nature_beneficiary: string;
    nature_group: string;
    limit: number;
    offset: number;
  }

  export interface OperationItem {
    id: string;
    user_id: string;
    product_type: string;
    id_syscambio: string;
    client_name: string;
    beneficiary_name: string;
    nature_fact: string;
    currency_code: string;
    currency_name: string;
    process_value: string;
    process_expiration: string;
    status: string;
    created_date: string;
  }

  export interface ObservationProps {
    process_id: number;
    text_content: string;
    created_date: string;
    created_by_id: number;
    creator_name: string;
    creator_sector?: string;
    status: string;
  }
}
