import { Avatar, Button } from '@chakra-ui/react'
import GenericButton from 'components/generic/GenericButton'
import {
  InputCelPhone,
  InputCPF,
  InputEmail,
  InputImgBase64,
  InputLogin,
  InputPhone,
  InputSelect,
  InputText
} from 'components/generic/GenericInputs'
import Modal from 'components/generic/Modal'
import PermissionList from 'components/generic/PermissionList'
import PermissionPicker from 'components/generic/PermissionPicker'
import { axiosAuth } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { makeDascamUsersPermissionsGroups } from 'helpers/factories/permissions'
import { validBody } from 'helpers/validBody'
import { UserModel } from 'interfaces/user'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading
} from 'redux/actions'
import styled from 'styled-components'

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ModalStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
`

const LeftPane = styled.div`
  width: auto;
  min-width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  & div {
    align-items: center;
  }
`

const RightPane = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`

const ButtonDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
`

interface Props {
  showModal: boolean
  getUserData: () => void
  handleModal: () => void
  id: string
}

const EditUser = ({
  showModal,
  getUserData,
  handleModal,
  id
}: Props): JSX.Element => {
  const [showModalData, setShowModalData] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const [userData, setUserData] = useState<UserModel>({})
  const [userFixedData, setUserFixedData] = useState<UserModel>({})
  const [body, setBody] = useState<UserModel>({})

  const getUser = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(`/users/${id}`, config)

      setUserFixedData(data)
      setUserData(data)
      setShowModalData(true)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleSubmit = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      await axiosAuth.put(`/users/${userData.id}`, body, config)

      dispatch(setSuccessMessage('Usuário alterado com sucesso!'))

      close()
      getUserData()
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const close = () => {
    setUserData({})
    handleModal()
    setShowModalData(false)
  }

  useEffect(() => {
    setBody(validBody(userFixedData, userData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  useEffect(() => {
    if (showModal === true) {
      getUser()
    }
    // setUserData({ ...userData, company_id: user?.id! });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  return (
    <Modal title='Alterar usuário' showModal={showModalData} backFocus>
      <Form>
        <ModalStyled>
          <LeftPane>
            <Avatar
              bg={'primary.200'}
              name={userData?.user_name}
              src={userData?.photo}
              size='2xl'
            />
            {/* <Button
              m={2}
              variant='outline'
              color={"primary.400"}
              colorScheme={"primary"}
              onClick={() =>
                document.getElementById("selectedFileImg")!.click()
              }
            >
              Adicionar Foto
            </Button> */}
          </LeftPane>
          <RightPane>
            <ItemRow>
              <InputText
                maxWidth='400px'
                label='Nome *'
                required
                value={userData.user_name!}
                changeValue={(value) =>
                  setUserData({ ...userData, user_name: value })
                }
              />
            </ItemRow>
            <ItemRow>
              <InputCPF
                maxWidth='200px'
                label='CPF *'
                placeholder='___.___.___-__'
                required
                value={userData.document!}
                changeValue={(value) =>
                  setUserData({ ...userData, document: value })
                }
              />
              <InputEmail
                maxWidth='200px'
                label='E-mail *'
                placeholder='exemplo@mail.com'
                required
                value={userData.email!}
                changeValue={(value) =>
                  setUserData({ ...userData, email: value })
                }
              />
            </ItemRow>
            <ItemRow>
              <InputSelect
                maxWidth='200px'
                label='Departamento *'
                required
                value={userData.dascam_type!}
                changeValue={(value) =>
                  setUserData({ ...userData, dascam_type: value })
                }
              >
                <option selected disabled value=''>
                  Selecione o departamento
                </option>
                <option value='NAO INFORMADO'>NÃO INFORMADO</option>
                <option value='ADMINISTRACAO'>ADMINISTRAÇÃO</option>
                <option value='BACKOFFICE'>BACKOFFICE</option>
                <option value='CADASTRO'>CADASTRO</option>
                <option value='COMPLIANCE'>COMPLIANCE</option>
                <option value='DIRETORIA'>DIRETORIA</option>
                <option value='FINANCEIRO'>FINANCEIRO</option>
                <option value='OPERACOES'>OPERAÇÕES</option>
              </InputSelect>
              <InputLogin
                value={userData.login}
                label='Login de acesso *'
                maxWidth='200px'
                readOnly
                changeValue={(value) =>
                  setUserData({
                    ...userData,
                    login: value
                  })
                }
              />
            </ItemRow>
            {/* <ItemRow>
            <DivAddClient>
              <p>Alterar Clientes Dascam:</p>
              <Button type="button">Selecionar clientes</Button>
            </DivAddClient>
          </ItemRow> */}
            <PermissionPicker
              initialValue={userData.permission_list}
              onChange={(permissions) => {
                setUserData((oldUser) => {
                  return {
                    ...oldUser,
                    permission_list: permissions
                  }
                })
              }}
              groups={makeDascamUsersPermissionsGroups()}
            />
          </RightPane>
        </ModalStyled>
        <ButtonDiv>
          <Button
            m={2}
            variant='outline'
            color={'red.400'}
            colorScheme={'red'}
            onClick={() => close()}
          >
            Cancelar
          </Button>
          <Button
            m={2}
            variant='ghost'
            color={'green.400'}
            colorScheme={'green'}
            onClick={() => handleSubmit()}
          >
            Confirmar Alterações
          </Button>
        </ButtonDiv>
      </Form>
    </Modal>
  )
}

export default EditUser
