import React from "react";
import styled from "styled-components";
import Modal from "./Modal";
import { AiOutlineBell } from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import GenericDascamErrorBox from "./GenericDascamErrorBox";
import { Col, Row } from "services/pdf/components/helpers";
import GenericButton from "./GenericButton";

const ItemRow = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Square = styled.div`
  width: 60px;
  height: 60px;
  background: white;
  /* box-shadow: 0 20px 10px -10px rgba(0, 0, 0, 0.4); */
  border: 1px solid ${props => props.theme.color.gray4};
  color: ${props => props.theme.color.primary_dark};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ItemRowContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const ItemRowTitle = styled.h2`
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.color.primary_dark};
`;

const ItemRowDescription = styled.span`
  margin-top: 4px;
  font-size: 14px;
  color: ${props => props.theme.color.gray2};
`;

const Tag = styled.span`
  background: ${props => props.theme.color.primary_dark};
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 4px;
  font-size: 12px;
  font-weight: bold;
`;

export interface DropdownItemProps {
  icon?: React.ReactNode;
  title: string;
  description?: string;
  tags?: string[];
  link?: string;
  buttons?: Array<{
    label: string;
    onClick: () => void;
  }>;
}

interface GenericDropdownItemsProps {
  onClose: () => void;
  isOpen: boolean;
  messageEmpty?: string;
  items: DropdownItemProps[];
}

const GenericDropdownItems: React.FC<GenericDropdownItemsProps> = ({
  isOpen,
  onClose,
  items,
  messageEmpty,
}) => {
  return (
    <>
      <Modal
        title="Notificações"
        showModal={isOpen}
        backFocus
        width={"35vw"}
        maxHeight={"80vh"}
        close={onClose}
      >
        {items.length <= 0 && messageEmpty && (
          <GenericDascamErrorBox message={messageEmpty} />
        )}
        {items.map((item, keyItem) => (
          <ItemRow key={`item${keyItem}`}>
            {item.icon && <Square>{item.icon}</Square>}
            <ItemRowContent>
              <ItemRowTitle>{item.title}</ItemRowTitle>
              {item.description && (
                <ItemRowDescription>{item.description}</ItemRowDescription>
              )}
            </ItemRowContent>
            <Col>
              {item.tags && item.tags.length > 0 && (
                <div style={{ marginTop: 2, marginBottom: 4 }}>
                  {item.tags.map((tag, keyTag) => (
                    <Tag key={`tag${keyItem}${keyTag}`}>{tag}</Tag>
                  ))}
                </div>
              )}
              {item.buttons && (
                <Row style={{ alignItems: "center" }}>
                  {item.buttons.map((b, keyB) => (
                    <GenericButton
                      key={`button${keyItem}${keyB}`}
                      onClick={b.onClick}
                      padding={"2px 4px"}
                      style={{ margin: 0, borderRadius: 2, marginRight: 4 }}
                    >
                      {b.label}
                    </GenericButton>
                  ))}
                </Row>
              )}
            </Col>
            {item.link && (
              <Square
                style={{
                  background: "transparent",
                  color: "black",
                  boxShadow: "none",
                  border: "none",
                }}
                as={"a"}
                href={item.link}
                target={"_blank"}
              >
                <BiLinkExternal size={24} />
                <span style={{ fontSize: "10px" }}>Abrir LINK</span>
              </Square>
            )}
          </ItemRow>
        ))}
      </Modal>
    </>
  );
};

export default GenericDropdownItems;
