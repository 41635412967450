import AttachFile from "components/generic/AttachFileToBase64";
import { ArchiveModel } from "interfaces/user-archives";
import styled from "styled-components";

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`;

interface Props {
  userDocumentation: ArchiveModel;
  changeDocumentation: (userDocumentation: ArchiveModel) => void;
}

const DocumentationForeign = ({
  userDocumentation,
  changeDocumentation,
}: Props): JSX.Element => {
  return (
    <>
      <DivItems>
        <AttachFile
          newId="attorney_letter"
          title="Procuração (via original ou cópia autenticada)"
          value={userDocumentation.attorney_letter!}
          typeFile={userDocumentation.attorney_letter_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              attorney_letter: value,
              attorney_letter_type: type,
            })
          }
          readOnly
        />
        <AttachFile
          newId="income_tax"
          title="Cópia do imposto de Renda *"
          value={userDocumentation.income_tax!}
          typeFile={userDocumentation.income_tax_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              income_tax: value,
              income_tax_type: type,
            })
          }
          readOnly
        />
        <AttachFile
          newId="rg_cpf_doc"
          title="Cópia do RG e CPF/RNE *"
          value={userDocumentation.rg_cpf_doc!}
          typeFile={userDocumentation.rg_cpf_doc_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              rg_cpf_doc: value,
              rg_cpf_doc_type: type,
            })
          }
          readOnly
        />
        <AttachFile
          newId="service_contract"
          title="Contrato de Prestação de Serviços (Entre cliente e corretora)"
          value={userDocumentation.service_contract!}
          typeFile={userDocumentation.service_contract_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              service_contract: value,
              service_contract_type: type,
            })
          }
          readOnly
        />
        <AttachFile
          newId="residence_proof"
          title="Copia de comprovante de endereço (conta emitida por concessionária de serviço público) *"
          value={userDocumentation.residence_proof!}
          typeFile={userDocumentation.residence_proof_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              residence_proof: value,
              residence_proof_type: type,
            })
          }
          readOnly
        />
        {/* <AttachFile
          newId="identification_beneficiaries"
          title="Cópia Documentos Identificação do(s) Beneficiário(s) Final(is)"
          value={userDocumentation.identification_beneficiaries!}
          typeFile={userDocumentation.identification_beneficiaries_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              identification_beneficiaries: value,
              identification_beneficiaries_type: type,
            })
          }
          readOnly
        /> */}
        <AttachFile
          newId="additional_documents"
          title="Documentos Adicionais"
          value={userDocumentation.additional_documents!}
          typeFile={userDocumentation.additional_documents_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              additional_documents: value,
              additional_documents_type: type,
            })
          }
          readOnly
        />
      </DivItems>
    </>
  );
};

export default DocumentationForeign;
