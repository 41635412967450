import { useLayoutEffect, useState } from "react";

/**
 * Hook que calcula paginação, expõe página atual, expõe funções para troca de página e também retorna função que calcula número de páginas.
 * @param limit Quantos items são retornados pela API por página.
 * @returns {[number, (string | number)[], Function, Function, Function, Function]} Página atual (index 1), Array representando as páginas para exibição, Funções para mudar de página, Função para calcular numero de páginas.
 */
export default function usePagination(limit: number) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<Array<number | string>>([1]);
  const [total, setTotal] = useState<number>(limit);

  const DOTS = "...";

  const totalPages = () => Math.ceil(total / limit);

  const flipToNextPage = () => {
    if (currentPage + 1 <= totalPages()) {
      setCurrentPage(value => value + 1);
    }
  };

  const flipToPrevPage = () => {
    if (currentPage - 1 > 0) {
      setCurrentPage(value => value - 1);
    }
  };

  const goToPage = (pageIndex: string | number) => {
    if (!isNaN(Number(pageIndex))) {
      setCurrentPage(Number(pageIndex));
    }
  };

  const handleNewTotal = (newTotal: number) => {
    setTotal(newTotal);
  };

  const range = (start: number, end: number) => {
    const length = end - start + 1;

    return Array.from({ length }, (_, index) => index + start);
  };

  const calculatePages = () => {
    const siblingCount = 3;
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPages()) {
      return range(1, totalPages());
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPages()
    );
    const showLeftDots = leftSiblingIndex > 2;
    const showRightDots = rightSiblingIndex < totalPages() - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages();

    if (!showLeftDots && showRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPages()];
    } else if (showLeftDots && !showRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPages() - rightItemCount + 1, totalPages());

      return [firstPageIndex, DOTS, ...rightRange];
    } else {
      // Mostra DOTS dos dois lados
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  };

  // Calcula array de páginas
  useLayoutEffect(() => {
    setPages(calculatePages());
  }, [total, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    currentPage,
    pages,
    flipToNextPage,
    flipToPrevPage,
    handleNewTotal,
    goToPage,
    totalPages,
  ] as const;
}
