import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { axiosWebsite } from 'helpers/axiosInstances'
import { ExchangeScheduleModel } from 'interfaces/exchange-schedule'
import { BiShow } from 'react-icons/bi'
import './style.css'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import { createCustomerSearchPicker } from 'v2/services/app/factories/forms/createCustomerSearchPicker'
import { Button } from '@chakra-ui/button'
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Badge,
  Box,
  Collapse,
  HStack,
  IconButton,
  Progress,
  Select,
  Tooltip,
  useBoolean,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { getMoeda } from 'helpers/moedas'
import {
  capitalizeFirstLetter,
  formatCNPJ,
  formatCPF,
  formatDate,
  formatProduct
} from 'helpers/formats'

import DataTable, { TableColumn } from 'react-data-table-component'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import { useDispatch } from 'react-redux'
import { ExcelExportXlsx } from 'services/ExcelExportXlsx'
import AppContext from 'hooks/helpers/useLastFilter'
import { Permissions } from 'interfaces/web/permission'
import { apiClient } from 'v2/services/clients/apiClient'
import { createCurrencySearchPicker } from 'v2/services/app/factories/forms/createCurrencySearchPicker'
import { useCurrencyQuery } from 'v2/hooks/api/currency/useCurrencyQuery'
import { Row } from 'v2/components/shared/Row'
import styled from 'styled-components'
import { isUnauthorized } from 'helpers/errors'

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`
const FilterComponent = ({ filterText, onFilter, onType, type }: any) => (
  <>
    <Select mr={3} onChange={onType} size='sm' w={'180px'}>
      <option value={1}>Referência</option>
      <option value={2}>Fatura</option>
    </Select>
    <TextField
      id='search'
      type='text'
      placeholder='Buscar'
      aria-label='Search Input'
      value={filterText}
      onChange={onFilter}
    />
  </>
)

const AFechar: React.FC = () => {
  const { user, hasPermissions, isAutorizado } = useGlobals()
  const permCreation = isAutorizado
    ? hasPermissions([Permissions.APAR_CREATE, Permissions.APAR_VIEW])
    : true
  const permView = isAutorizado ? hasPermissions([Permissions.APAR_VIEW]) : true
  const date = new Date().toISOString().split('T')[0]
  const Default = AppContext?.apar
    ? AppContext?.apar
    : {
        start_date: date,
        end_date: date
      }
  useEffect(() => {
    redirect(hashData)
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [filterTextExternal, setFilterTextExternal] = React.useState<string>('')
  const [filterTextType, setFilterTextType] = React.useState<number>(1)
  const [isLoading, loading] = useBoolean()
  const { data: currencies } = useCurrencyQuery()
  const [process, setProcess] = useState<ExchangeScheduleModel[]>([])
  const [processSelect, setSelectProcess] = useState<ExchangeScheduleModel[]>(
    []
  )
  const [groupList, setGroupList] = useState<any[]>([])
  const [dataInput, setDataInput] = useState<any>()
  const iso = useRef<string>()
  const [dateInitials, setDateInitials] = useState<string>()
  const [dateFinal, setDateFinal] = useState<string>()
  const [showAlert, setShowAlert] = useBoolean()
  const { hashData, redirect } = useHashData(Default)
  const [idSyscambio, setSyscambio] = useState(hashData?.customer?.id_syscambio)
  const [dateSelect, setDateSelect] = useState<any>()
  const [dateMESelect, setDateMESelect] = useState<any>()
  const [dateReaisSelect, setDateReaisSelect] = useState<any>()
  const userId = user!.id!
  const dispatch = useDispatch()
  const toast = useToast()
  const [toggledClearRows, setToggleClearRows] = React.useState(false)
  let history = useHistory()
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows)
  }

  const rowDisabledCriteria = (row: ExchangeScheduleModel) =>
    row.created_date !== undefined

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        filterText={filterTextExternal}
        onFilter={(e: any) => {
          setFilterTextExternal(e.target.value)
        }}
        onType={(e: any) => {
          setFilterTextType(Number(e.target.value))
        }}
        type={filterTextType}
      />
    )
  }, [filterTextExternal])

  const conditionalRowStyles = [
    {
      when: (row: ExchangeScheduleModel) =>
        capitalizeFirstLetter(row.status ?? '') !== 'Sem Pendências',
      style: {
        backgroundColor: 'rgba(255, 64, 64, 0.8)',
        color: 'white'
      }
    }
  ]

  const columns: TableColumn<ExchangeScheduleModel>[] = [
    {
      name: 'PROCESSO',
      selector: (row) => row.process ?? '',
      sortable: true,
      width: '120px',
      omit: true
    },
    {
      name: '',
      cell: (row) => (
        <HStack m={1}>
          {permView && (
            <IconButton
              onClick={() => {
                history.push(
                  `/schedules/show/${row.process}/${
                    idSyscambio || user?.id_syscambio
                  }`
                )
              }}
              aria-label='Visualizar'
              size={'sm'}
              colorScheme={'blue'}
            >
              <BiShow size={16} />
            </IconButton>
          )}
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      width: '50px',
      button: true
    },
    {
      name: 'REFERÊNCIA',
      selector: (row) => row.refCli ?? '',
      sortable: true,
      width: '230px'
    },
    {
      name: 'FATURA',
      selector: (row) => row.invoices ?? '',
      cell: (row) => (
        <Tooltip label={row.invoices ?? ''}>{row.invoices ?? ''}</Tooltip>
      ),
      sortable: true,
      width: '200px'
    },
    {
      name: 'EMPRESA',
      selector: (row) =>
        groupList.find((e) => Number(e.id_syscambio) === Number(row.cod_cli))
          ?.fantasy_name ?? '',
      wrap: false,
      minWidth: '260px',
      sortable: true,
      reorder: true
    },
    {
      name: 'LIBERADO PARA O DIA',
      selector: (row) =>
        row.created_date
          ? new Date(row.created_date)
              ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '---',
      sortable: true,
      cell: (row) => (
        <Badge
          variant='subtle'
          colorScheme={row.created_date ? 'green' : 'red'}
        >
          {row.created_date
            ? new Date(row.created_date)
                ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
                .split(',')[0]
            : '---'}
        </Badge>
      ),
      conditionalCellStyles: [
        {
          when: (row) => row.created_date !== undefined,
          style: {
            color: 'green'
          }
        },
        {
          when: (row) => row.created_date === undefined,
          style: {
            color: 'firebrick'
          }
        }
      ],
      width: '180px'
    },
    {
      name: 'PREVISÃO DE FECHAMENTO',
      selector: (row) =>
        row.closure
          ? new Date(row.closure)
              .toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      width: '215px'
    },
    {
      name: 'PRODUTO',
      selector: (row) => formatProduct(row.product ?? ''),
      width: '120px'
    },
    {
      name: 'PAGADOR/RECEBEDOR',
      selector: (row) => row.beneficiary ?? '',
      wrap: false,
      sortable: true,
      minWidth: '230px'
    },
    {
      name: 'PAG/REC Identificação',
      selector: (row) => row.beneficiary_identification ?? '',
      wrap: false,
      sortable: true,
      minWidth: '260px'
    },
    {
      name: 'MOEDA',
      selector: (row) => getMoeda(Number(row.currency)),
      width: '90px',
      right: true
    },
    {
      name: 'VALOR',
      selector: (row) => row.value ?? '',
      format: (row) =>
        row.value?.toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }),
      sortable: true,
      width: '130px',
      right: true
    },
    {
      name: 'IR %',
      selector: (row) => row.ir ?? '---',
      format: (row) =>
        row.ir
          ? row.ir?.toLocaleString('pt-br', {
              minimumFractionDigits: 2
            })
          : '---',
      width: '73px',
      right: true
    },
    {
      name: 'IR Valor',
      selector: (row) =>
        row.ir ? (row.value_base_ir ?? 0) * (row.ir / 100) : '---',
      format: (row) =>
        row.ir
          ? ((row.value_base_ir ?? 0) * (row.ir / 100)).toLocaleString(
              'pt-br',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            )
          : '---',
      width: '130px',
      right: true
    },
    /*{
      name: 'ORIGEM',
      selector: (row) => (row.origin === 'SYSCAMBIO' ? 'Interno' : 'Direct'),
      width: '100px'
    },*/
    {
      name: 'STATUS',
      selector: (row) => capitalizeFirstLetter(row.status ?? ''),
      cell: (row) => (
        <Badge>
          <Tooltip label={row.status ?? ''}>{row.status ?? ''}</Tooltip>
        </Badge>
      )
    }
  ]

  const loadData = useCallback(async () => {
    try {
      loading.on()
      setShowAlert.off()
      setSelectProcess([])

      /*
                  id_syscambio:  Number(
              idSyscambio ||
                user?.id_syscambio ||
                hashData?.customer?.id_syscambio
            ),
      */

      if (
        (dateInitials || hashData?.start_date) &&
        (dateFinal || hashData?.end_date)
      ) {
        let itemsToFind = undefined

        if (groupList.length === 0) {
          const id = user.company_id ?? user.id!
          itemsToFind =
            groupList.length !== 0
              ? groupList
              : await apiClient.customers.getCustomersByUser(id)

          if (user.permission_group_company) {
            itemsToFind = itemsToFind.filter((e) =>
              user.permission_group_company?.includes(e.id_syscambio)
            )
          }
          setGroupList(itemsToFind)
        } else {
          itemsToFind = groupList
        }

        const byId = idSyscambio
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
        axiosWebsite
          .post(
            `afechar`,
            {
              id_syscambio: byId,
              list_id_syscambio: byId
                ? undefined
                : itemsToFind?.map((e) => e.id_syscambio),
              product_type: dataInput,
              document_type: 'FATURA',
              initial_date: dateInitials ?? hashData?.start_date,
              end_date: dateFinal ?? hashData?.end_date,
              isFull: false,
              crossData: true,
              currency: iso.current
            },
            config
          )
          .then((res) => {
            setProcess(() => res.data)

            loading.off()
          })
          .catch((error) => {
            loading.off()
          })
      } else {
        setShowAlert.on()
        loading.off()
      }
    } catch (error) {
      loading.off()
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataInput,
    dateFinal,
    dateInitials,
    idSyscambio,
    loading,
    setShowAlert,
    user?.id_syscambio,
    userId
  ])

  const handleRowSelected = React.useCallback((state) => {
    setSelectProcess(state.selectedRows)
  }, [])

  function alert () {
    return toast({
      title: 'Campos Obrigatórios!!',
      position: 'bottom',
      description:
        'Por favor, verifique o campo "Liberado para o Dia" e tente novamente.',
      status: 'info',
      duration: 3000,
      isClosable: true
    })
  }

  async function sendData () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      processSelect.forEach(function (e) {
        // e.cod_cli = Number(idSyscambio || user?.id_syscambio)
        e.closure = dateSelect.date
        e.created_date = dateSelect.date
        e.date_me = dateSelect.date_me ?? null
        e.date_reais = dateSelect.date_reais ?? null
      })
      const listToSave = processSelect

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite
        .post(
          `save-process-to-close`,
          {
            processList: listToSave
          },
          config
        )
        .then((res) => {
          loadData()
          handleClearRows()
          setDateSelect(null)
        })
        .catch((e) => {})
    } catch (error) {
    } finally {
      onClose()
      dispatch(clearLoading())
    }
  }

  const exportExcel = async () => {
    const rows = process.map((o) => {
      return {
        Referêcia: o.refCli,
        Fatura: o.invoices ?? '---',
        Solicitação: o.created_date ? formatDate(o.created_date) : '---',
        'Previsão Fechamento': formatDate(o.closure ?? ''),
        Produto: o.product,
        'Pagador/Recebedor': o.beneficiary,
        'Pagador/Recebedor Ident.': o.beneficiary_identification,
        Moeda: getMoeda(Number(o.currency)),
        Valor: o.value,
        IR: `${o.ir ? `${o.ir}%` : '---'}`,
        'Valor IR': o.ir ? (o.value_base_ir ?? 0) * (o.ir / 100) : '---',
        Status: capitalizeFirstLetter(o.status ?? '')
      }
    })

    let objectMaxLength: any[] = []
    for (let i = 0; i < rows.length; i++) {
      let value: any = Object.values(rows[i])
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == 'number') {
          objectMaxLength[j] = 15
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j]?.length
              ? objectMaxLength[j]
              : value[j]?.length >= 15
              ? value[j]?.length + 1
              : 15
        }
      }
    }

    await ExcelExportXlsx.fromJSON(
      'exportacao',
      rows,
      [
        { width: Number(objectMaxLength[0]) },
        { width: Number(objectMaxLength[1]) },
        { width: Number(objectMaxLength[2]) },
        { width: Number(objectMaxLength[3]) },
        { width: Number(objectMaxLength[4]) },
        { width: Number(objectMaxLength[5]) },
        { width: Number(objectMaxLength[6]) },
        { width: Number(objectMaxLength[7]) },
        { width: Number(objectMaxLength[8]) },
        { width: Number(objectMaxLength[9]) },
        { width: Number(objectMaxLength[10]) },
        { width: Number(objectMaxLength[11]) }
      ],
      [8, 10]
    )
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  function AlertDialogConfirmation () {
    const cancelRef = useRef<HTMLButtonElement>(null)

    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Solicitar Criação de Contrato
              </AlertDialogHeader>

              <AlertDialogBody>
                Você deseja fazer a solicitar criação de contrato para os itens
                selecionados?
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 2,
                      fields: [
                        {
                          type: 'date',
                          label: 'Liberado para o Dia*',
                          name: 'date'
                        }
                      ]
                    },
                    {
                      columns: 2,
                      fields: [
                        {
                          type: 'date',
                          label: 'Data ME',
                          name: 'date_me'
                        }
                      ]
                    },
                    {
                      columns: 2,
                      fields: [
                        {
                          type: 'date',
                          label: 'Data Reais',
                          name: 'date_reais'
                        }
                      ]
                    }
                  ]}
                  value={dateSelect}
                  onChange={(v) => {
                    setDateSelect(v)
                  }}
                />
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  colorScheme='red'
                  onClick={() => (!dateSelect?.date ? alert() : sendData())}
                  ml={3}
                >
                  Solicitar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  return (
    <>
      {AlertDialogConfirmation()}
      <CardPage title='Contas a Pagar/Receber'>
        <Col px={2} minH={'fill'} w={'100%'}>
          <SimpleFormGenerator
            rows={[
              {
                columns: 3,
                fields: [
                  {
                    type: 'select',
                    label: 'Tipo de Contrato',
                    name: 'contract_type',
                    options: [
                      {
                        label: () => 'A Pagar (Importação/Remessa)',
                        value: 'pagar'
                      },
                      {
                        label: () => 'A Receber (Exportação/Ingresso)',
                        value: 'receber'
                      },
                      {
                        label: () => 'Importação',
                        value: 'pagar_im'
                      },
                      {
                        label: () => 'Remessa',
                        value: 'pagar_re'
                      },
                      {
                        label: () => 'Exportação',
                        value: 'recebe_ex'
                      },
                      {
                        label: () => 'Ingresso',
                        value: 'recebe_in'
                      }
                    ]
                  },
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'fantasy_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              const productTypeBySelect = {
                pagar: {
                  one: 'IMPORTACAO',
                  two: 'REMESSA'
                },
                receber: {
                  one: 'EXPORTACAO',
                  two: 'INGRESSO'
                },
                recebe_in: 'INGRESSO',
                recebe_ex: 'EXPORTACAO',
                pagar_im: 'IMPORTACAO',
                pagar_re: 'REMESSA'
              }
              const { fantasy_name, document, id_syscambio } = v.customer || {}
              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  fantasy_name,
                  document,
                  id_syscambio
                }
              } else {
                nextParams.customer = null
              }

              if (v.contract_type) {
                const type: string = v.contract_type
                nextParams.contract_type = v.contract_type
                // @ts-ignore
                setDataInput(productTypeBySelect[type || 'pagar'])
              } else {
                setDataInput(null)
              }
              setSyscambio(id_syscambio ?? undefined)

              redirect(nextParams)
            }}
          />
          <VStack spacing={4} display={'flex'} align='start'>
            <Row justifyContent={'space-between'} alignItems={'end'}>
              <Box>
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 3,
                      fields: [
                        {
                          type: 'date',
                          label: 'Período',
                          name: 'start_date'
                        },
                        {
                          type: 'date',
                          label: 'até',
                          name: 'end_date'
                        },
                        createCurrencySearchPicker(
                          {
                            label: 'Moeda',
                            name: 'currency_iso',
                            labelKey: 'Nome'
                          },
                          currencies
                        )
                      ]
                    }
                  ]}
                  value={hashData}
                  onChange={(v) => {
                    const nextParams: any = { ...v }
                    const { Codigo } = v?.currency_iso ?? {}
                    if (v?.currency_iso) {
                      nextParams.currency_iso = { ...v.currency_iso }
                    }

                    setDateInitials(v.start_date)
                    setDateFinal(v.end_date)
                    iso.current = Codigo
                    redirect(nextParams)
                  }}
                />
              </Box>
              <Box>
                <Button
                  marginBottom={5}
                  marginEnd={3}
                  marginLeft={2}
                  disabled={isLoading || process.length === 0}
                  variant={'ghost'}
                  color={'green.400'}
                  colorScheme={'green'}
                  mr='20px'
                  onClick={() => {
                    exportExcel()
                  }}
                >
                  Exportação Excel
                </Button>
                {permCreation && (
                  <Button
                    marginBottom={5}
                    marginEnd={3}
                    marginLeft={2}
                    disabled={isLoading || processSelect.length === 0}
                    variant={'outline'}
                    color={'green.400'}
                    colorScheme={'green'}
                    mr='10px'
                    onClick={onOpen}
                  >
                    Liberar Selecionados
                  </Button>
                )}
                <Button
                  marginBottom={5}
                  marginEnd={3}
                  marginLeft={2}
                  disabled={isLoading}
                  variant={'outline'}
                  color={'primary.400'}
                  mr='10px'
                  colorScheme={'primary'}
                  onClick={() => {
                    loadData()
                    handleClearRows()
                    AppContext.apar = hashData
                  }}
                >
                  Buscar
                </Button>
              </Box>
            </Row>
          </VStack>
          {showAlert && (
            <Alert status='warning'>
              <AlertIcon />
              Preencha todos os campos de filtro
            </Alert>
          )}
          <Collapse in={isLoading}>
            <Progress isIndeterminate colorScheme={'secondary'} h={1} />
          </Collapse>
          <VStack spacing={1} align='end'></VStack>
        </Col>
        <Col id='cont' minH={'60%'} px={2}>
          <Col
            mt={3}
            p={0.5}
            rounded={'lg'}
            overflow={'hide'}
            border={'1px solid transparent'}
            borderColor={'primary.600'}
          >
            <DataTable
              keyField='PROCESSO'
              noDataComponent=''
              columns={columns}
              data={
                filterTextExternal
                  ? process.filter((item) =>
                      filterTextType === 1
                        ? item.refCli &&
                          item.refCli
                            .toLowerCase()
                            .includes(filterTextExternal.toLowerCase())
                        : item.invoices &&
                          item.invoices
                            .toLowerCase()
                            .includes(filterTextExternal.toLowerCase())
                    )
                  : process
              }
              dense={true}
              fixedHeader={true}
              fixedHeaderScrollHeight={`${
                (document.getElementById('cont')?.clientHeight ?? 0) - 25
              }px`}
              striped={true}
              selectableRows={permCreation}
              onSelectedRowsChange={handleRowSelected}
              selectableRowsHighlight={true}
              clearSelectedRows={toggledClearRows}
              selectableRowDisabled={rowDisabledCriteria}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              conditionalRowStyles={conditionalRowStyles}
            />
          </Col>
        </Col>
      </CardPage>
    </>
  )
}

export default AFechar
