import styled from "styled-components";
import {
  InputCelPhone,
  InputCEP,
  InputCountry,
  InputCPF,
  InputDate,
  InputDDDPhone,
  InputEmail,
  InputPhone,
  InputRG,
  InputSelect,
  InputText,
  InputUF,
  InputUFSelect,
} from "components/generic/GenericInputs";
import CheckBox from "components/generic/CheckBox";
import { UserMaritalEnum, UserModel } from "interfaces/user";

const HeaderCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const TitleCard = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${props => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`;

const SubtitleCard = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1rem;
  color: ${props => props.theme.color.gray4};
  font-weight: bold;
  line-height: 24px;
`;

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`;

const TitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${props => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`;

const StatusUser = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  height: auto;
  margin: 0 0.5rem;
  & p {
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 23px;
    margin: 0 0.3rem;
  }
`;

const StatusCircle = styled.span<{ color?: string }>`
  margin: 0 0.5rem;
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  margin-right: 0.3rem;
  background-color: ${props =>
    props.color === "DISAPPROVED"
      ? props.theme.color.deny
      : props.color === "PENDING"
        ? props.theme.color.warn
        : props.color === "EVALUATION"
          ? props.theme.color.primary_light
          : props.color === "BLOCKED"
            ? props.theme.color.deny
            : props.theme.color.approval};
`;

interface Props {
  userData: UserModel;
  changeUserData: (userData: UserModel) => void;
  mandatory: any;
}

const RegisterForeign = ({
  userData,
  changeUserData,
  mandatory,
}: Props): JSX.Element => {
  return (
    <>
      <HeaderCard>
        <div>
          <TitleCard>Meus dados cadastrais</TitleCard>
          <SubtitleCard>
            Para alterar seu cadastro basta clicar, alterar e salvar suas
            alterações
          </SubtitleCard>
        </div>
        <div>
          <StatusUser>
            {userData.responsible_sector !== "CLIENTE" ? (
              <p>
                Área/Setor:{" "}
                <b>
                  {userData.responsible_sector === "CADASTRO"
                    ? "Cadastro"
                    : userData.responsible_sector === "COMPLIANCE"
                      ? "Compliance"
                      : "Diretoria"}
                </b>
              </p>
            ) : null}
            <p>
              Status:{" "}
              <b>
                {userData.status === "DISAPPROVED"
                  ? "Reprovado"
                  : userData.status === "PENDING"
                    ? "Pendente"
                    : userData.status === "EVALUATION"
                      ? "Em análise"
                      : userData.status === "BLOCKED"
                        ? "Bloqueado"
                        : "Aprovado"}
              </b>
            </p>
            <StatusCircle color={userData.status!} />
          </StatusUser>
        </div>
      </HeaderCard>
      <DivItems>
        <ItemRow>
          <CheckBox
            title="Sou Pessoa Politicamente Exposta"
            selected={userData.politically_exposed!}
            changeSelected={value =>
              changeUserData({
                ...userData,
                politically_exposed: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
        </ItemRow>
        <ItemRow>
          <InputText
            label="Nome Completo *"
            maxWidth="350px"
            value={userData.user_name!}
            changeValue={value =>
              changeUserData({
                ...userData,
                user_name: value,
              })
            }
            required
            isValid={mandatory.user_name === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputText
            label="CPF/RNE *"
            placeholder=""
            maxWidth="200px"
            value={userData.document!}
            changeValue={value =>
              changeUserData({
                ...userData,
                document: value,
              })
            }
            required
            isValid={mandatory.document === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" ||
                userData.status === "EVALUATION" ||
                userData.id_syscambio
                ? true
                : false
            }
          />
          <InputDate
            label="Data de Nascimento *"
            maxWidth="200px"
            max={new Date().toISOString().split("T")[0]}
            value={userData.birthdate!}
            changeValue={value =>
              changeUserData({
                ...userData,
                birthdate: value,
              })
            }
            required
            isValid={mandatory.birthdate === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputEmail
            label="E-mail *"
            placeholder="exemplo@email.com"
            maxWidth="300px"
            value={userData.email!}
            changeValue={value =>
              changeUserData({
                ...userData,
                email: value,
              })
            }
            required
            isValid={mandatory.email === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
        </ItemRow>
        <ItemRow>
          <InputRG
            label="RG"
            maxWidth="240px"
            value={userData.general_registry!}
            changeValue={value =>
              changeUserData({
                ...userData,
                general_registry: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputText
            label="Orgão Expeditor - RG"
            maxWidth="200px"
            value={userData.issuing_agency!}
            changeValue={value =>
              changeUserData({
                ...userData,
                issuing_agency: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputUFSelect
            label="UF"
            value={userData.agency_state!}
            changeValue={value =>
              changeUserData({
                ...userData,
                agency_state: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputDate
            label="Data Emissão - RG"
            maxWidth="200px"
            value={userData.rg_issue!}
            changeValue={value =>
              changeUserData({
                ...userData,
                rg_issue: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
        </ItemRow>
        <ItemRow>
          <InputText
            label="Nome do Pai *"
            maxWidth="300px"
            value={userData.father_name!}
            changeValue={value =>
              changeUserData({
                ...userData,
                father_name: value,
              })
            }
            required
            isValid={mandatory.father_name === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputText
            label="Nome da Mãe *"
            maxWidth="300px"
            value={userData.mother_name!}
            changeValue={value =>
              changeUserData({
                ...userData,
                mother_name: value,
              })
            }
            required
            isValid={mandatory.mother_name === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
        </ItemRow>
        <ItemRow>
          <ItemRow>
            <InputDDDPhone
              label="DDD *"
              placeholder="00"
              maxWidth="100px"
              value={userData.ddd_cellphone!}
              changeValue={value =>
                changeUserData({
                  ...userData,
                  ddd_cellphone: value,
                })
              }
              readOnly={userData.status === "EVALUATION"}
            />
            <InputCelPhone
              label="Celular *"
              placeholder="00000-0000"
              maxWidth="200px"
              value={userData.contact_cellphone!}
              changeValue={value =>
                changeUserData({
                  ...userData,
                  contact_cellphone: value,
                })
              }
              required
              isValid={mandatory.contact_cellphone === false ? true : false}
              readOnly={userData.status === "EVALUATION"}
            />
            <InputDDDPhone
              label="DDD *"
              placeholder="00"
              maxWidth="100px"
              value={userData.ddd_phone!}
              changeValue={value =>
                changeUserData({
                  ...userData,
                  ddd_phone: value,
                })
              }
              readOnly={userData.status === "EVALUATION"}
            />
            <InputPhone
              label="Telefone *"
              placeholder="0000-0000"
              maxWidth="200px"
              value={userData.contact_phone!}
              changeValue={value =>
                changeUserData({
                  ...userData,
                  contact_phone: value,
                })
              }
              required
              isValid={mandatory.contact_phone === false ? true : false}
              readOnly={userData.status === "EVALUATION"}
            />
          </ItemRow>
        </ItemRow>
      </DivItems>
      <DivItems>
        <ItemRow>
          <InputText
            label="CNH"
            maxWidth="240px"
            value={userData.cnh_number!}
            changeValue={value =>
              changeUserData({
                ...userData,
                cnh_number: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputDate
            label="Data de Emissão - CNH"
            maxWidth="200px"
            value={userData.cnh_issue!}
            changeValue={value =>
              changeUserData({
                ...userData,
                cnh_issue: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputDate
            label="Validade - CNH"
            maxWidth="200px"
            value={userData.cnh_validity!}
            changeValue={value =>
              changeUserData({
                ...userData,
                cnh_validity: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
        </ItemRow>
        <ItemRow>
          <InputText
            label="Passaporte"
            maxWidth="240px"
            value={userData.passaport!}
            changeValue={value =>
              changeUserData({
                ...userData,
                passaport: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputDate
            label="Emissão"
            maxWidth="200px"
            value={userData.passaport_issue!}
            changeValue={value =>
              changeUserData({
                ...userData,
                passaport_issue: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputDate
            label="Validade"
            maxWidth="200px"
            value={userData.passaport_validity!}
            changeValue={value =>
              changeUserData({
                ...userData,
                passaport_validity: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputText
            label="Naturalidade *"
            maxWidth="200px"
            value={userData.nationality!}
            changeValue={value =>
              changeUserData({
                ...userData,
                nationality: value,
              })
            }
            required
            isValid={mandatory.nationality === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputCountry
            label="País de Nacionalidade *"
            maxWidth="220px"
            value={userData.nationality_country!}
            changeValue={value =>
              changeUserData({
                ...userData,
                nationality_country: value,
              })
            }
            required
            isValid={mandatory.nationality_country === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
        </ItemRow>
        <ItemRow>
          <InputSelect
            label="Estado Cívil *"
            maxWidth="200px"
            value={userData.marital_status!}
            changeValue={value =>
              changeUserData({
                ...userData,
                marital_status: value as UserMaritalEnum,
              })
            }
            required
            isValid={mandatory.marital_status === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          >
            <option value="SOLTEIRO">Solteiro(a)</option>
            <option value="CASADO">Casado(a)</option>
            <option value="SEPARADO">Separado(a)</option>
            <option value="DIVORCIADO">Divorciado(a)</option>
            <option value="VIUVO">Viúvo(a)</option>
          </InputSelect>
          {userData.marital_status! !== "SOLTEIRO" &&
            userData.marital_status! !== null ? (
            <>
              <InputText
                label="Nome do Cônjulgue"
                maxWidth="300px"
                value={userData.spouse_name!}
                changeValue={value =>
                  changeUserData({
                    ...userData,
                    spouse_name: value,
                  })
                }
                readOnly={
                  userData.status === "ACTIVE" ||
                  userData.status === "EVALUATION"
                }
              />
              <InputCPF
                label="CPF do Cônjulgue"
                placeholder="___.___.___-__"
                maxWidth="200px"
                value={userData.spouse_cpf!}
                changeValue={value =>
                  changeUserData({
                    ...userData,
                    spouse_cpf: value,
                  })
                }
                readOnly={
                  userData.status === "ACTIVE" ||
                  userData.status === "EVALUATION"
                }
              />
            </>
          ) : null}
        </ItemRow>
      </DivItems>
      <DivItems>
        <TitleItems>Dados de endereço</TitleItems>
      </DivItems>
      <DivItems>
        <ItemRow>
          <InputCountry
            label="País *"
            maxWidth="150px"
            value={userData.country!}
            changeValue={value =>
              changeUserData({
                ...userData,
                country: value,
              })
            }
            required
            isValid={mandatory.country === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputCEP
            label="CEP *"
            maxWidth="175px"
            value={userData.address_zipcode!}
            changeValue={value =>
              changeUserData({
                ...userData,
                address_zipcode: value,
              })
            }
            changeOtherValue={value =>
              changeUserData({
                ...userData,
                address_city: value.localidade,
                address_state: value.uf,
                address_place: value.logradouro,
                address_district: value.bairro,
              })
            }
            required
            isValid={mandatory.address_zipcode === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputText
            label="Cidade *"
            maxWidth="200px"
            value={userData.address_city!}
            changeValue={value =>
              changeUserData({
                ...userData,
                address_city: value,
              })
            }
            required
            isValid={mandatory.address_city === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputUF
            label="UF *"
            value={userData.address_state!}
            changeValue={value =>
              changeUserData({
                ...userData,
                address_state: value,
              })
            }
            required
            isValid={mandatory.address_state === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          {/* <InputSelect
                    label="Tipo de Bairro *"
                    maxWidth="200px"
                    value={userData.address_type!}
                    changeValue={value =>
                      changeUserData({
                        ...userData,
                        address_type: value,
                      })
                    }
                    required
            isValid={mandatory.user_name === false ? true : false}
                  ></InputSelect> */}
          <InputText
            label="Bairro *"
            maxWidth="250px"
            value={userData.address_district!}
            changeValue={value =>
              changeUserData({
                ...userData,
                address_district: value,
              })
            }
            required
            isValid={mandatory.address_district === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
        </ItemRow>
        <ItemRow>
          {/* <InputSelect
                    label="Tipo de Logradouro *"
                    maxWidth="200px"
                    value={userData.address_type!}
                    changeValue={value =>
                      changeUserData({
                        ...userData,
                        address_type: value,
                      })
                    }
                    required
            isValid={mandatory.user_name === false ? true : false}
                  ></InputSelect> */}
          <InputText
            label="Logradouro *"
            maxWidth="420px"
            value={userData.address_place!}
            changeValue={value =>
              changeUserData({
                ...userData,
                address_place: value,
              })
            }
            required
            isValid={mandatory.address_place === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputText
            label="Número *"
            maxWidth="100px"
            value={userData.address_number!}
            changeValue={value =>
              changeUserData({
                ...userData,
                address_number: value,
              })
            }
            required
            isValid={mandatory.address_number === false ? true : false}
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
          <InputText
            label="Complemento"
            maxWidth="340px"
            value={userData.address_complement!}
            changeValue={value =>
              changeUserData({
                ...userData,
                address_complement: value,
              })
            }
            readOnly={
              userData.status === "ACTIVE" || userData.status === "EVALUATION"
            }
          />
        </ItemRow>
      </DivItems>
    </>
  );
};

export default RegisterForeign;
