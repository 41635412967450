import styled from "styled-components";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GenericButton from "components/generic/GenericButton";
//import imgs
import BgAreas from "assets/fundoCadastro.png";
import LogoDascam from "assets/Logo 2.png";
import { useDispatch } from "react-redux";
import { clearLoading, setGlobalMessage, showLoading } from "redux/actions";
import { axiosLogin } from "helpers/axiosInstances";
import { devices } from "helpers/devices";
import { InputPassword } from "components/generic/GenericInputs";
import { isUnauthorized } from "helpers/errors";
import { Button } from "@chakra-ui/react";

const Section = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledLogin = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 ${props => props.theme.margins.from_sides};
  background-color: ${props => props.theme.color.white1};
  background: url("${BgAreas}");
  background-position: center left;
  background-size: cover;
  background-repeat: no-repeat;
`;

const AlignRight = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: ${props => props.theme.color.white1};
  padding: 1rem ${props => props.theme.margins.from_sides};
  padding-top: 3rem;
`;

const CenterItems = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: auto;
  width: 400px;
  margin: 1rem;
  cursor: pointer;

  @media ${devices.desktop} {
    width: 350px;
  }

  @media ${devices.bigDesktop} {
    width: 400px;
  }
`;

const Container = styled.div<{ fill?: boolean; Bfill?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// const Button = styled(GenericButton) <{ isDeny?: boolean }>`
//   margin: 0.2rem;
//   padding: 0.8rem;
//   width: 250px;
//   background: ${props =>
//     props.isDeny
//       ? props.theme.color.button_deny
//       : props.theme.color.secondary_main};
//   color: ${props =>
//     props.isDeny ? props.theme.color.white1 : props.theme.color.primary_main};

//   &:hover {
//     background: ${props =>
//     props.isDeny
//       ? props.theme.color.button_deny_hover
//       : props.theme.color.secondary_light};
//   }
// `;

const P = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: ${props => props.theme.color.gray3};
  margin-bottom: 1rem;
  text-align: center;
`;

export interface PathContentNav {
  token: string;
}

const RecoverPage = (): JSX.Element => {
  const pathNav = useParams<PathContentNav>();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const dispatch = useDispatch();
  const [plainPassword, setPlainPassword] = useState<boolean>(false);
  const [plainConfirmPassword, setPlainConfirmPassword] =
    useState<boolean>(false);
  const history = useHistory();

  const clearInput = () => {
    setConfirmPassword("");
    setPassword("");
    setPlainPassword(false);
    setPlainConfirmPassword(false);
  };

  const validateRecover = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          backFocus: true,
          isLoading: true,
        })
      );

      const config = {
        headers: {
          token: `${pathNav.token}`,
        },
      };

      const { data } = await axiosLogin.get("/validaterecover", config);
      setName(data.user_name);
      setLogin(data.login);
    } catch (error: any) {
      if (isUnauthorized(error)) {
        dispatch(
          setGlobalMessage({
            message: `Não Autorizado!`,
            type: "ERROR",
          })
        );
      }
      history.push("/");
    } finally {
      clearInput();
      dispatch(clearLoading());
    }
  };

  const handleSubmit = async () => {
    if (
      password === confirmPassword &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      try {
        dispatch(
          showLoading({
            message: "Carregando",
            isLoading: true,
          })
        );

        const body = {
          username: login,
          newPassword: password,
          confirmationPassword: confirmPassword,
        };

        await axiosLogin.put("/recoverpass", body);

        history.push("/login");
      } catch (error: any) {
        if (isUnauthorized(error)) {
          dispatch(
            setGlobalMessage({
              message: `Não Autorizado!`,
              type: "ERROR",
            })
          );
        }
      } finally {
        clearInput();
        dispatch(clearLoading());
      }
    } else {
      dispatch(
        setGlobalMessage({
          message: `Senhas não coincidem!`,
          type: "WARN",
        })
      );
    }
  };

  useEffect(() => {
    validateRecover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section>
      <StyledLogin>
        <AlignRight>
          <CenterItems>
            <Logo
              src={LogoDascam}
              alt={"Logo da Dascam"}
              onClick={() => history.push("/")}
            />
            <Container>
              <P>Olá {name}, insira sua nova senha</P>
              <InputGroup>
                <InputPassword
                  label="Senha: "
                  maxWidth="300px"
                  value={password}
                  required
                  changeValue={value => setPassword(value)}
                  plainPassword={plainPassword}
                  handlePlainPassword={() => setPlainPassword(!plainPassword)}
                  handleSubmit={
                    confirmPassword !== "" && password !== ""
                      ? handleSubmit
                      : undefined
                  }
                />
              </InputGroup>
              <InputGroup>
                <InputPassword
                  label="Confirmar Senha: "
                  maxWidth="300px"
                  value={confirmPassword}
                  required
                  changeValue={value => setConfirmPassword(value)}
                  plainPassword={plainConfirmPassword}
                  handlePlainPassword={() =>
                    setPlainConfirmPassword(!plainConfirmPassword)
                  }
                  handleSubmit={
                    confirmPassword !== "" && password !== ""
                      ? handleSubmit
                      : undefined
                  }
                />
              </InputGroup>
            </Container>
            <Button
              variant={"outline"}
              color={"primary.400"}
              colorScheme={"primary"}
              w={'70%'}
              onClick={() =>
                confirmPassword !== "" && password !== ""
                  ? handleSubmit()
                  : undefined
              }
            >
              Confirmar
            </Button>
          </CenterItems>
        </AlignRight>
      </StyledLogin>
    </Section>
  );
};

export default RecoverPage;
