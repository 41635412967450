export function formatRate() {
  return {
    toNumber: (value: string) => {
      const floatValue = Number(value.replace(/[.,\s]/gm, "")) / 10000;
      return floatValue;
    },
    toString: (value: number) => {
      return value.toLocaleString('pt-br', {
        minimumFractionDigits: 4
      })
      //return nextValue;
    },
  };
}
