export function consumeQs(url: string) {
  if (!url) return {};
  if (!url.includes("#")) return {};

  try {
    return JSON.parse(decodeURI(url.replace("#", "")));
  } catch (err) {
    return {};
  }
}
