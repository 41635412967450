import { axiosAuth, axiosEmail } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { UserModel } from 'interfaces/user'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading
} from 'redux/actions'
import { GlobalState } from 'redux/types'
import styled from 'styled-components'
import GenericButton from './GenericButton'
import { InputTextArea } from './GenericInputs'
import Modal from './Modal'

const StyledObservation = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
`

const Text = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray1};
`

const DivButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0.3rem 0;
`

const SpanColor = styled.span`
  color: ${(props) => props.theme.color.third_main};
`

const Button = styled(GenericButton)<{ isDeny?: boolean }>`
  width: 150px;
  padding: 0.5rem;
  margin: 0 0.3rem;
  color: ${(props) => props.theme.color.white1};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.button_normal};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.button_normal_hover};
  }
`

interface Props {
  showModal: boolean
  userData: UserModel
  handleModal: () => void
}

interface PathNav {
  id: string
  email: string
}

const ReprovalAndObservation = ({
  showModal,
  userData,
  handleModal
}: Props): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const pathNav = useParams<PathNav>()
  const [observationValue, setObservationValue] = useState<string>('')
  const user = useSelector((state: GlobalState) => state.user)

  const handleReprovalStep = async (
    status: string,
    sector: string,
    old_sector?: string
  ) => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const body = {
        status: status,
        responsible_sector: sector,
        old_sector: old_sector ? old_sector : ''
      }

      await axiosAuth.put(`/users/${pathNav.id}`, body, config)

      const bodyObserv = {
        user_id: userData?.id,
        text_content: observationValue,
        creator_name: user?.user_name,
        creator_sector: user?.dascam_type,
        created_by_id: user?.id,
        status: 'DISAPPROVED'
      }

      await axiosAuth.post(`/observation`, bodyObserv, config)

      if (sector === 'CLIENTE') {
        const { data } = await axiosAuth.get(`/users/${pathNav.id}`, config)

        const bodyMail = {
          emails: [data.email],
          name: data.user_name,
          status: 'Pendente',
          sector: 'Cliente',
          text: `Cadastro Inválido: ${observationValue}`
        }
        await axiosEmail.post(`/update-status`, bodyMail)
      }

      dispatch(setSuccessMessage('Envio feito com sucesso!'))
      history.push('/dascam/registration-management')
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const close = () => {
    handleModal()
    setObservationValue('')
  }

  return (
    <Modal showModal={showModal} title='' backFocus>
      <StyledObservation>
        <Text>
          <SpanColor>Você está reprovando este cadastro</SpanColor>
          <br />
          Escreva para o usuário o motivo da recusa:
        </Text>
        <InputTextArea
          value={observationValue}
          changeValue={(value) => setObservationValue(value)}
        />
        <DivButtons>
          <Button type='button' isDeny onClick={() => close()}>
            Voltar
          </Button>
          {userData.responsible_sector === 'CADASTRO' &&
          userData.old_sector !== 'DIRETORIA' &&
          !userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() => handleReprovalStep('PENDING', 'CLIENTE')}
              >
                Enviar Resposta
              </Button>
            </>
          ) : null}
          {userData.responsible_sector === 'COMPLIANCE' &&
          userData.old_sector !== 'DIRETORIA' &&
          !userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() => handleReprovalStep('EVALUATION', 'CADASTRO')}
              >
                Enviar Resposta
              </Button>
            </>
          ) : null}
          {userData.responsible_sector === 'DIRETORIA' &&
          userData.old_sector !== 'DIRETORIA' &&
          !userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() =>
                  handleReprovalStep('EVALUATION', 'CADASTRO', 'DIRETORIA')
                }
              >
                Enviar Resposta
              </Button>
            </>
          ) : null}
          {userData.responsible_sector === 'CADASTRO' &&
          userData.old_sector === 'DIRETORIA' &&
          !userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() => handleReprovalStep('DISAPPROVED', 'CLIENTE')}
              >
                Enviar Resposta
              </Button>
            </>
          ) : null}
          {userData.responsible_sector === 'COMPLIANCE' &&
          userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() => handleReprovalStep('PENDING', 'CLIENTE')}
              >
                Enviar Resposta
              </Button>
            </>
          ) : null}
          {userData.responsible_sector === 'DIRETORIA' &&
          userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() => handleReprovalStep('EVALUATION', 'COMPLIANCE')}
              >
                Enviar Resposta
              </Button>
            </>
          ) : null}
        </DivButtons>
      </StyledObservation>
    </Modal>
  )
}

export default ReprovalAndObservation
