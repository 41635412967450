export const size = {
  phoneOnly: 599,
  tabletPortrait: 600,
  tabletLandscape: 900,
  desktop: 1200,
  bigDesktop: 1800,
};

export const devices = {
  phoneOnly: `(max-width: ${size.phoneOnly}px)`,
  tabletPortrait: `(min-width: ${size.tabletPortrait}px)`,
  tabletLandscape: `(min-width: ${size.tabletLandscape}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  bigDesktop: `(min-width: ${size.bigDesktop}px)`,
};

export enum OS {
  win = "Windows",
  mac = "MacOS",
  linux = "Linux",
  android = "Android",
  ios = "iOS",
}

export function getOS() {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = [
      "Macintosh",
      "MacIntel",
      "MacPPC",
      "Mac68K",
      "MacOS",
      "Mac OS",
    ],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os: OS;

  // console.log({
  //   nav: window.navigator,
  //   platform: window.navigator.platform,
  // });

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS.mac;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS.ios;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS.win;
  } else if (/Android/.test(userAgent)) {
    os = OS.android;
  } else if (/Linux/.test(platform)) {
    os = OS.linux;
  }

  return os!;
}
