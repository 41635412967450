import styled from "styled-components";
import GenericButton from "components/generic/GenericButton";
import { Fragment, useState } from "react";
import { BankModel } from "interfaces/bank";
import { formatAccountBank, formatAgency, formatDate } from "helpers/formats";
// import { ReactComponent as TrashDataIcon } from "assets/trashIcon.svg";
import { ReactComponent as EditDataIcon } from "assets/editDataIcon.svg";
import { ReactComponent as ActiveIcon } from "assets/activeIcon.svg";
import { ReactComponent as InactiveIcon } from "assets/inactiveIcon.svg";
import { ReactComponent as DeleteIcon } from "assets/trashIcon.svg";
import BankAccountDelete from "./BankAccountDelete";
import { UserModel, UserStatusEnum } from "interfaces/user";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalMessage } from "redux/actions";
import { GlobalState } from "redux/types";
import { BankActive, BankInactive } from "./BankAccountStatus";
import { axiosAuth, axiosWebsite } from "helpers/axiosInstances";
import { useGlobals } from "hooks/helpers/useGlobals";

const ColumnText = styled.div<{ background?: boolean }>`
  border-right: 1px solid ${props => props.theme.color.white5};
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.white5};
  padding: 0 0.5rem;
  background: ${props =>
    props.background ? props.theme.color.white1 : props.theme.color.white2};
`;

const Text = styled.p<{ Color?: string; fontWeight?: string }>`
  font-size: ${props => props.theme.font.small};
  color: ${props => (props.Color ? props.Color : props.theme.color.text3)};
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;
`;

const DivButton = styled.div<{ borderRadius?: boolean; background?: boolean }>`
  border-right: 1px solid ${props => props.theme.color.white5};
  background: ${props =>
    props.background
      ? props.theme.color.primary_main
      : props.theme.color.primary_light};
  border-bottom: 1px solid ${props => props.theme.color.white5};
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: ${props => (props.borderRadius ? "0 0 10px 0" : "")};
`;

const ButtonEdit = styled(GenericButton)`
  width: 30px;
  height: 30px;
  padding: 0.3rem;
  background: ${props => props.theme.color.secondary_main};
  justify-content: center;
  align-items: center;
  transform: scale(0.95);
  margin: 0 0.1rem;

  &:hover {
    background: ${props => props.theme.color.secondary_light};
    transform: scale(1);
  }

  & svg {
    width: 100%;
    height: 100%;
    /* fill: ${props => props.theme.color.primary_main}; */
  }
`;

const ButtonStatus = styled(GenericButton)<{ isActive?: boolean }>`
  width: 60px;
  height: 25px;
  padding: 0.1rem;
  border: 1px solid ${props => props.theme.color.white1};
  border-radius: 10px;
  background: ${props => props.theme.color.primary_dark};
  align-items: center;
  justify-content: ${props => (props.isActive ? "flex-start" : "flex-end")};
  margin-left: 0.3rem;

  &:hover {
    background: ${props => props.theme.color.primary_main};
  }

  & svg {
    width: 30px;
    height: auto;
  }
`;
interface Props {
  last: boolean;
  index: number;
  account: BankModel;
  readOnly?: boolean;
  getBankData: () => void;
  editUser?: boolean;
  setEditUser?: (value: boolean) => void;
  setBankData?: (value: BankModel) => void;
  removeToEdit?: (value: number) => void;
  userData: UserModel;
  onDelete?: () => void;
}

const Account = ({
  last,
  index,
  account,
  getBankData,
  editUser,
  setEditUser,
  setBankData,
  removeToEdit,
  readOnly,
  userData,
  onDelete,
}: Props): JSX.Element => {
  // const { pathname } = useLocation();
  const user = useSelector((state: GlobalState) => state.user);
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const dispatch = useDispatch();

  // const [beneficiaryDelete, setBeneficiaryDelete] = useState<boolean>(false);
  // const [editUser, setEditUser] = useState<boolean>(false);
  const [disableBank, setDisableBank] = useState<boolean>(false);
  const [activeBank, setActiveBank] = useState<boolean>(false);
  const { startLoading, stopLoading } = useGlobals();

  // const [deleteBank, setDeleteBank] = useState<boolean>(false);

  return (
    <Fragment key={account.id}>
      <ColumnText background={isEven(index)}>
        <Text title={account.bank_code}>
          {/*account.bank_code + " - " + */account.bank_name}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={formatAgency(String(account.bank_agency))}>
          {formatAgency(String(account.bank_agency))}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={formatAccountBank(String(account.bank_account))}>
          {formatAccountBank(String(account.bank_account))}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text
          title={
            account.opening_date
              ? formatDate(account.opening_date!)
              : account.opening_date
          }
        >
          {account.opening_date
            ? formatDate(account.opening_date!)
            : account.opening_date}
        </Text>
      </ColumnText>
      {!readOnly ? (
        user?.cad_type === "DASCAM" || userData.status !== "EVALUATION" ? (
          <DivButton background={isEven(index)}>
            <ButtonEdit
              type="button"
              onClick={() => {
                if (!editUser && removeToEdit && setEditUser && setBankData) {
                  removeToEdit(index);
                  setEditUser(!editUser);
                  setBankData(account);
                } else {
                  dispatch(
                    setGlobalMessage({
                      message: "Finalize a edição do registro atual!",
                      type: "WARN",
                    })
                  );
                }
              }}
            >
              <EditDataIcon />
            </ButtonEdit>
            {userData.status === UserStatusEnum.PENDING && (
              <ButtonEdit
                type="button"
                style={{ background: "#ff6e6e" }}
                onClick={async () => {
                  const config = {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                      )}`,
                    },
                  };

                  startLoading("Excluindo banco");

                  await axiosAuth
                    .delete(`/bank-delete/${account.id}`, config)
                    .then(onDelete)
                    .finally(stopLoading);
                }}
              >
                <DeleteIcon />
              </ButtonEdit>
            )}
            {userData.status !== UserStatusEnum.PENDING && (
              <ButtonStatus
                isActive={account.status === "ACTIVE" ? true : false}
                type="button"
                onClick={() =>
                  account.status === "ACTIVE"
                    ? setDisableBank(!disableBank)
                    : setActiveBank(!activeBank)
                }
              >
                {account.status === "ACTIVE" ? (
                  <ActiveIcon />
                ) : (
                  <InactiveIcon />
                )}
              </ButtonStatus>
            )}
            {/* {userData.status === UserStatusEnum.PENDING && (

            )} */}
          </DivButton>
        ) : null
      ) : null}

      <BankActive
        showModal={activeBank}
        handleModal={() => setActiveBank(!activeBank)}
        getBankData={() => getBankData()}
        id={account.id!}
        bankName={account.bank_name!}
      />
      <BankInactive
        showModal={disableBank}
        handleModal={() => setDisableBank(!disableBank)}
        getBankData={() => getBankData()}
        id={account.id!}
        bankName={account.bank_name!}
      />
      {/* <BankAccountDelete
        showModal={deleteBank}
        handleModal={() => setDeleteBank(!deleteBank)}
        getBankData={() => getBankData()}
        id={account.id!}
        bankName={account.bank_name!}
        bankAgency={account.bank_agency!}
        bankAccount={account.bank_account!}
      /> */}
    </Fragment>
  );
};

export default Account;
