import { InputText } from "components/generic/GenericInputs";
import React from "react";
import { Col, Row } from "..";

interface FormGeneratorProps {
  rows: Array<{
    items: Array<{
      label: React.ReactNode;
      value?: string;
      readOnly?: boolean;
      grid?: number;
      type?: HTMLInputElement["type"];
    }>;
  }>;
}

const FormGenerator: React.FC<FormGeneratorProps> = ({ rows }) => {
  return (
    <Col style={{ width: "100%" }}>
      {rows.map((row, keyRow) => (
        <Row key={`row${keyRow}`}>
          {row.items.map((item, keyItem) => (
            <Col key={`item${keyItem}`} grid={item.grid}>
              {typeof item.label === "string" ? (
                <InputText
                  label={`${item.label}`}
                  value={`${item.value || "Não definido"}`}
                  readOnly={item.readOnly}
                  type={item.type || "text"}
                />
              ) : (
                item.label
              )}
            </Col>
          ))}
        </Row>
      ))}
    </Col>
  );
};

export default FormGenerator;
