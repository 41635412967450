import React from "react";
import { GridExport } from "services/GridExport";

export function useGridExport(
  ref: React.RefObject<HTMLElement>,
  fileName?: string
) {
  const [isExporting, setIsExporting] = React.useState(false);

  const execute = async () => {
    if (!ref.current) return;
    setIsExporting(true);
    new GridExport()
      .export(ref.current, fileName)
      .finally(() => setIsExporting(false));
  };

  return { isExporting, execute };
}
