import styled, { keyframes } from "styled-components";
import { useState, useEffect } from "react";
import { axiosAuth } from "helpers/axiosInstances";
import { AxiosError, AxiosResponse } from "axios";
import GenericButton from "components/generic/GenericButton";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  clearLoading,
  showLoading,
  setGlobalMessage,
  clearGlobalMessage,
  setSuccessMessage,
  clearUser,
} from "redux/actions";

import Modal from "./Modal";
import { InputPassword } from "./GenericInputs";
import { UserModel } from "interfaces/user";
import { isUnauthorized } from "helpers/errors";
import { Button } from "@chakra-ui/react";

const slide = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const ItemGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  min-width: 400px;
  padding: 1rem;
`;

const ButtonRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

// const Button = styled(GenericButton) <{ isDeny?: boolean }>`
//   width: 180px;
//   margin: 0.5rem;
//   padding: 0.6rem;
//   border-radius: 6px;
//   font-size: ${props => props.theme.font.normal};
//   background: ${props =>
//     props.isDeny
//       ? props.theme.color.button_deny
//       : props.theme.color.primary_main};
//   color: ${props => props.theme.color.white1};

//   &:hover {
//     background: ${props =>
//     props.isDeny
//       ? props.theme.color.button_deny_hover
//       : props.theme.color.primary_light};
//   }
// `;

const Effect = styled.div<{ delay: string }>`
  animation: ${slide} 1s 1 both ${props => props.delay};
  padding: 0.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
`;

interface Props {
  id: string;
  showModal: boolean;
  handleModal: () => void;
}

const PasswordChange = ({ id, showModal, handleModal }: Props): JSX.Element => {
  const [showModalData, setShowModalData] = useState<boolean>(false);
  const [login, setLogin] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [verifyPassword, setVerifyPassword] = useState<string>("");
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState<boolean>(false);
  const [passwordEquals, setPasswordEquals] = useState<boolean>(false);
  const history = useHistory();

  const dispatch = useDispatch();

  const logout = () => {
    handleModal();
    dispatch(clearGlobalMessage());
    dispatch(clearUser());
    localStorage.clear();
    history.push("/");
  };

  const handleChangePassword = () => {
    if (passwordEquals === true) {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const body = {
        username: login,
        currentPassword: currentPassword,
        newPassword: password,
        confirmationPassword: verifyPassword,
      };

      axiosAuth
        .put(`/passwords`, body, config)
        .then((response: AxiosResponse) => {
          dispatch(setSuccessMessage("Senha alterada com sucesso!"));
          setCurrentPassword("");
          setPassword("");
          setVerifyPassword("");
          logout();
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 403) {
            dispatch(
              setGlobalMessage({
                message: `Error: ${error.response?.status}`,
                type: "ERROR",
                buttons: (
                  <Button
                    variant={"outline"}
                    color={"primary.400"}
                    colorScheme={"primary"}
                    onClick={() => {
                      history.push("/");
                      dispatch(clearGlobalMessage());
                    }}
                  >
                    Ok
                  </Button>
                ),
              })
            );
          } else {
            dispatch(
              setGlobalMessage({
                message: `Error: ${error.response?.status}`,
                type: "ERROR",
              })
            );
          }
        })
        .finally(() => {
          dispatch(clearLoading());
        });
    } else {
      dispatch(
        setGlobalMessage({
          message: `Preencha os campos corretamente!`,
          type: "ERROR",
        })
      );
    }
  };

  const fetchUser = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );
      if (showModal === true) {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        };

        const { data } = await axiosAuth.get<UserModel>(`/users/${id}`, config);
        setLogin(data.login!);
        setShowModalData(true);
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  useEffect(() => {
    if (password !== "" && verifyPassword !== "") {
      if (password === verifyPassword) {
        setPasswordEquals(true);
      } else {
        setPasswordEquals(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password && verifyPassword]);

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const closeModal = () => {
    handleModal();
    setShowModalData(false);
    setLogin("");
    setCurrentPassword("");
    setPassword("");
    setVerifyPassword("");
  };

  return (
    <>
      <Modal
        title="Alterar Senha"
        showModal={showModalData}
        close={closeModal}
        width="450px"
        backFocus={true}
      >
        <Effect delay="50ms">
          <ItemGroup>
            <RightSide>
              <InputPassword
                placeholder="Senha Atual"
                value={currentPassword}
                changeValue={value => setCurrentPassword(value)}
                plainPassword={showCurrentPassword}
                handlePlainPassword={() =>
                  setShowCurrentPassword(!showCurrentPassword)
                }
              />
              <InputPassword
                placeholder="Nova Senha"
                value={password}
                changeValue={value => setPassword(value)}
                plainPassword={showPassword}
                handlePlainPassword={() => setShowPassword(!showPassword)}
              />
              <InputPassword
                placeholder="Confirmar Nova Senha"
                value={verifyPassword}
                changeValue={value => setVerifyPassword(value)}
                plainPassword={showVerifyPassword}
                handlePlainPassword={() =>
                  setShowVerifyPassword(!showVerifyPassword)
                }
              />
            </RightSide>
          </ItemGroup>
          <ButtonRow>
            <Button
              variant={"outline"}
              color={"red.400"}
              colorScheme={"red"}
              onClick={() => closeModal()}>
              Cancelar
            </Button>
            <Button
              ml={6}
              variant={"outline"}
              color={"primary.400"}
              colorScheme={"primary"}
              onClick={() => handleChangePassword()}>
              Confirmar
            </Button>
          </ButtonRow>
        </Effect>
      </Modal>
    </>
  );
};

export default PasswordChange;
