import GenericButton from 'components/generic/GenericButton'
import { axiosWebsite } from 'helpers/axiosInstances'
import { devices } from 'helpers/devices'
import { isUnauthorized } from 'helpers/errors'
import { ObservationOperationStatusEnum } from 'interfaces/observation-operation'
import {
  OperationModel,
  OperationStatusEnum,
  OperationTypeEnum,
  ProductTypeEnum
} from 'interfaces/operation'
import { OperationDocumentModel } from 'interfaces/operation_document'
import { SetModel } from 'interfaces/set_model'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  setGlobalMessage,
  clearLoading,
  showLoading,
  clearGlobalMessage
} from 'redux/actions'
import { GlobalState } from 'redux/types'
import styled from 'styled-components'
import Documents from './subComponents/Documents'
import Main from './subComponents/Main'
import PayerReceiver from './subComponents/PayerReceiver'

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
`

const TitlePage = styled.h4`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray2};
  align-self: flex-start;
  padding: 1rem;
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 100;
  background-color: ${(props) => props.theme.color.white1};
  height: auto;
  width: 100%;
  position: sticky;
  top: -1px;
  left: 0;

  @media ${devices.tabletPortrait} {
    align-items: flex-start;
  }

  @media ${devices.bigDesktop} {
    align-items: center;
  }
`

const TabsBlue = styled.div`
  width: 100%;
  height: auto;
  padding: 0.2rem;
  background: ${(props) => props.theme.color.primary_main};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & div {
    padding: 0.3rem 1rem;
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }
`

const ButtonTabs = styled(GenericButton)<{
  isDeny?: boolean
  isYellow?: boolean
  isBlue?: boolean
}>`
  width: auto;
  min-width: 120px;
  height: 25px;
  margin: 0.1rem 0.3rem;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  color: ${(props) => props.theme.color.white1};
  font-size: 0.9rem;
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isYellow
      ? props.theme.color.secondary_main
      : props.isBlue
      ? '#296ba9'
      : props.theme.color.button_normal} !important;

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isYellow
        ? props.theme.color.secondary_light
        : props.isBlue
        ? '#4b87be'
        : props.theme.color.button_normal_hover} !important;
  }
`

const Button = styled(GenericButton)<{ isDeny?: boolean }>`
  margin: 0.2rem 0;
  width: 100%;
  color: ${(props) => props.theme.color.white1};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.button_normal};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.button_normal_hover};
  }
`

interface Props {
  typeOperation: string
  changeRender: (value: string) => void
  isReadOperation: boolean
  setIsReadOperation: (value: boolean) => void
  isEditOperation: boolean
  setIsEditOperation: (value: boolean) => void
  operationId: number
}

type Tabs = 'Main' | 'Payer-Receiver' | 'Documents'

const Import = ({
  typeOperation,
  changeRender,
  isReadOperation,
  setIsReadOperation,
  isEditOperation,
  setIsEditOperation,
  operationId
}: Props): JSX.Element => {
  const user = useSelector((state: GlobalState) => state.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState<Tabs>('Main')
  const [data, setData] = useState<OperationModel>({} as OperationModel)
  const [operationList, setOperationList] = useState<OperationDocumentModel[]>(
    []
  )
  const [gameSetList, setGameSetList] = useState<SetModel[]>([])

  const handleSubmit = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      data.product_type =
        typeOperation === 'Shipping'
          ? ('REMESSA' as ProductTypeEnum)
          : typeOperation === 'Import'
          ? ('IMPORTACAO' as ProductTypeEnum)
          : typeOperation === 'Ticket'
          ? ('INGRESSO' as ProductTypeEnum)
          : ('EXPORTACAO' as ProductTypeEnum)
      data.operation_type =
        typeOperation === 'Shipping' || typeOperation === 'Import'
          ? ('VENDA' as OperationTypeEnum)
          : ('COMPRA' as OperationTypeEnum)
      data.user_id = String(user?.id)
      data.status = 'SENT' as OperationStatusEnum

      const body = {
        operation: data,
        sets: gameSetList
      }

      const response = await axiosWebsite.post(`/fulloperation`, body, config)

      const bodyOperation = {
        creator_name: user?.user_name,
        creator_sector: user?.contact_branch,
        created_by_id: user?.id,
        operation_id: response.data.id,
        status: 'SENT' as ObservationOperationStatusEnum
      }

      await axiosWebsite.post(`/obsoperation`, bodyOperation, config)

      changeRender('Home')
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleDraft = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      data.product_type =
        typeOperation === 'Shipping'
          ? ('REMESSA' as ProductTypeEnum)
          : typeOperation === 'Import'
          ? ('IMPORTACAO' as ProductTypeEnum)
          : typeOperation === 'Ticket'
          ? ('INGRESSO' as ProductTypeEnum)
          : ('EXPORTACAO' as ProductTypeEnum)
      data.operation_type =
        typeOperation === 'Shipping' || typeOperation === 'Import'
          ? ('VENDA' as OperationTypeEnum)
          : ('COMPRA' as OperationTypeEnum)
      data.user_id = String(user?.id)
      data.status = 'PENDING' as OperationStatusEnum

      const body = {
        operation: data,
        sets: gameSetList
      }

      await axiosWebsite.post(`/fulloperation`, body, config)

      changeRender('Home')
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const getUserData = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const operation = await axiosWebsite.get(
        `/operation/${operationId}`,
        config
      )
      setData(operation.data)
      const set = await axiosWebsite.get(
        `/set?operation_id=${operation.data.id}`,
        config
      )
      setGameSetList(set.data.sets)
      // console.log(data);
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao carregar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const cancelEvent = () => {
    changeRender('Home')
  }

  useEffect(() => {
    if (isReadOperation === true || isEditOperation === true) {
      getUserData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault()
        dispatch(
          setGlobalMessage({
            message: `Deseja realmente enviar a operação para análise?`,
            type: 'MESSAGE',
            buttons: (
              <>
                <Button
                  onClick={() => {
                    handleSubmit()
                    dispatch(clearGlobalMessage())
                  }}
                >
                  Ok
                </Button>
                <Button isDeny onClick={() => dispatch(clearGlobalMessage())}>
                  Cancelar
                </Button>
              </>
            )
          })
        )
      }}
    >
      {isReadOperation === true ? (
        <TitlePage>visualizar contrato de operação</TitlePage>
      ) : isEditOperation === true ? (
        <TitlePage>Editar contrato de operação</TitlePage>
      ) : (
        <TitlePage>Novo contrato de operação</TitlePage>
      )}
      <TabsContainer>
        <TabsBlue>
          <div>
            <ButtonTabs
              isBlue={currentTab !== 'Main'}
              isYellow={currentTab === 'Main'}
              type='button'
              onClick={() => setCurrentTab('Main')}
            >
              Principal
            </ButtonTabs>
            <ButtonTabs
              isBlue={currentTab !== 'Payer-Receiver'}
              isYellow={currentTab === 'Payer-Receiver'}
              type='button'
              onClick={() => {
                if (
                  data.operation_value !== '0,00' &&
                  data.operation_value !== '' &&
                  data.operation_value !== undefined &&
                  data.currency_name !== '' &&
                  data.currency_name !== undefined &&
                  data.currency_iso !== '' &&
                  data.currency_iso !== undefined &&
                  data.currency_code !== '' &&
                  data.currency_code !== undefined
                ) {
                  setCurrentTab('Payer-Receiver')
                } else {
                  dispatch(
                    setGlobalMessage({
                      message:
                        'Preencha o valor da operação e selecione uma moeda !',
                      type: 'WARN'
                    })
                  )
                }
              }}
            >
              Pagador / Recebedor
            </ButtonTabs>
            <ButtonTabs
              isBlue={currentTab !== 'Documents'}
              isYellow={currentTab === 'Documents'}
              type='button'
              onClick={() => {
                if (
                  data.operation_value !== '0,00' &&
                  data.operation_value !== '' &&
                  data.operation_value !== undefined &&
                  data.beneficiary_name !== '' &&
                  data.beneficiary_identification !== '' &&
                  data.beneficiary_name !== undefined &&
                  data.beneficiary_identification !== undefined &&
                  data.beneficiary_identification !== undefined
                ) {
                  setCurrentTab('Documents')
                } else {
                  dispatch(
                    setGlobalMessage({
                      message: 'Preencha um pagador/recebedor valido !',
                      type: 'WARN'
                    })
                  )
                }
              }}
            >
              Documentos
            </ButtonTabs>
          </div>
          <div>
            <ButtonTabs
              isDeny={isReadOperation !== true}
              isYellow={isReadOperation === true}
              type='button'
              onClick={() => cancelEvent()}
            >
              {/* {isReadOperation !== true ? "Cancelar Operação" : "Voltar"} */}
              Voltar
            </ButtonTabs>
            {isReadOperation !== true ? (
              <>
                <ButtonTabs
                  isYellow
                  type='button'
                  onClick={() => handleDraft()}
                >
                  Salvar como rascunho
                </ButtonTabs>
                <ButtonTabs type='submit'>Enviar contrato</ButtonTabs>
              </>
            ) : null}
          </div>
        </TabsBlue>
      </TabsContainer>
      {currentTab === 'Main' ? (
        <Main
          typeOperation={typeOperation}
          changeRender={(value) => changeRender(value)}
          data={data}
          changeData={(value) => setData(value)}
          isReadOperation={isReadOperation}
          setIsReadOperation={setIsReadOperation}
          isEditOperation={isEditOperation}
        />
      ) : null}
      {currentTab === 'Payer-Receiver' ? (
        <PayerReceiver
          typeOperation={typeOperation}
          changeRender={(value) => changeRender(value)}
          data={data}
          changeData={(value) => setData(value)}
          isReadOperation={isReadOperation}
          setIsReadOperation={setIsReadOperation}
        />
      ) : null}
      {currentTab === 'Documents' ? (
        <Documents
          typeOperation={typeOperation}
          changeRender={(value) => changeRender(value)}
          data={data}
          changeData={(value) => setData(value)}
          operationList={operationList}
          setOperationList={setOperationList}
          gameSetList={gameSetList}
          setGameSetList={setGameSetList}
          isReadOperation={isReadOperation}
          setIsReadOperation={setIsReadOperation}
          isEditOperation={isEditOperation}
          setIsEditOperation={setIsEditOperation}
          getUserData={getUserData}
        />
      ) : null}
    </Form>
  )
}

export default Import
