import { useSelector } from "react-redux";
import { GlobalState } from "redux/types";
import styled from "styled-components";
import Logo from "assets/Animacao-Carregamento.gif";

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  z-index: 70000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Effect = styled.img`
  height: 150px;
  width: auto;
`;

const GlobalLoading = (): JSX.Element | null => {
  const isLoading = useSelector((state: GlobalState) => state.globalLoading);

  if (isLoading) {
    return (
      <Background>
        <Effect src={Logo} />
      </Background>
    );
  } else {
    return null;
  }
};
export default GlobalLoading;
