import { devices } from "helpers/devices";
import styled from "styled-components";
import centerBank from "assets/centerBank.png";
import bgCenterBank from "assets/bgCenterBank.png";
import GenericButton from "components/generic/GenericButton";
import { Button } from "@chakra-ui/react";

const AutorizationStyled = styled.section`
  background-color: ${props => props.theme.color.primary_main};
  height: 100vh;
  min-height: 450px;
  max-height: 450px;
  width: 100vw;
  max-width: 100%;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media ${devices.phoneOnly} {
    padding: 0 ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 0 ${props => props.theme.margins.from_sides};
  }
`;

const DivImg = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: auto;
  height: 100%;
  object-fit: fill;
`;

const DivText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  background-image: url("${bgCenterBank}");
  background-position: center right;
  background-size: fill;
  background-repeat: no-repeat;
`;

const Title = styled.p`
  width: 550px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 44px;
  text-transform: uppercase;
  color: ${props => props.theme.color.white1};
`;

const Text = styled.p`
  width: 550px;
  font-size: 0.8rem;
  line-height: 2;
  letter-spacing: -0.02em;
  color: ${props => props.theme.color.white1};
`;

// const Button = styled(GenericButton)`
//   width: 300px;
// `;

const Autorization = (): JSX.Element => {
  return (
    <AutorizationStyled id="5">
      <Container>
        <DivImg>
          <Img src={centerBank} alt="Imagem Banco central" />
        </DivImg>
        <DivText>
          <Title>
            A DASCAM É UMA INSTITUIÇÃO QUE TEM SEU FUNCIONAMENTO AUTORIZADO PELO BANCO CENTRAL DO BRASIL.
          </Title>
          <Text>
            Por uma opção estratégica que visa manter o foco na prestação de serviços aos nossos clientes,
            a DASCAM não mantém posição própria de câmbio.
            <br />
            A DASCAM é uma empresa que atua no Mercado de Câmbio há mais de 30 anos, agindo sempre com total transparência e
            alinhada às diretrizes emanadas pelo Conselho Monetário Nacional (CMN) e Banco Central do Brasil (BCB).
          </Text>
          {/* <Button
            size={"lg"}
            variant={"outline"}
            color={"white"}
            colorScheme={"darken"}
          >Ver Certificado</Button> */}
        </DivText>
      </Container>
    </AutorizationStyled>
  );
};

export default Autorization;
