import { Permissions, PermissionsGroupModel } from "interfaces/web/permission";

export function makeBaseUsersPermissionsGroups(): PermissionsGroupModel[] {
  return [
    {
      title: "Monitor",
      permissions: [
        { label: "Acessar", value: Permissions.MONITOR },
      ],
    },
    {
      title: "Processos",
      permissions: [
        { label: "Acessar", value: Permissions.PROCESS_ACCESS },
        { label: "Criar", value: Permissions.PROCESS_CREATE },
        { label: "Visualizar", value: Permissions.PROCESS_VIEW },
        { label: "Alterar", value: Permissions.PROCESS_EDIT },
      ],
    },
    {
      title: "A Pagar/Receber",
      permissions: [
        { label: "Acessar", value: Permissions.APAR_ACCESS },
        { label: "Liberação", value: Permissions.APAR_CREATE },
        { label: "Visualizar", value: Permissions.APAR_VIEW }
      ],
    },
    {
      title: "Agenda de Câmbio",
      permissions: [
        { label: "Acessar", value: Permissions.SCHEDULE_ACCESS },
        { label: "Liberação", value: Permissions.SCHEDULE_BILLING },
        { label: "Cancelar", value: Permissions.SCHEDULE_CANCEL }
      ],
    },
    {
      title: "Boletagem",
      permissions: [
        { label: "Acessar", value: Permissions.BILLING_ACCESS },
        { label: "Editar", value: Permissions.BILLING_EDIT },
        { label: "Cancelar", value: Permissions.BILLING_CANCEL }
      ],
    },
    {
      title: "Pós Fechamento",
      permissions: [
        { label: "Acessar", value: Permissions.POSCLOSE_ACCESS },
        { label: "Visualizar", value: Permissions.POSCLOSE_VIEW },
      ],
    },
    {
      title: "Relatórios",
      permissions: [
        { label: "Acessar", value: Permissions.REPORTS_ACCESS },
        { label: "IR", value: Permissions.REPORT_IR },
        { label: "DIRF", value: Permissions.REPORT_DIRF },
        { label: "Importação/Exportação", value: Permissions.REPORT_IMPEXP },
        { label: "Nota Fiscal", value: Permissions.REPORT_NF },
        { label: "Pagador/Recebedor", value: Permissions.REPORT_PAGREG },
        { label: "Volumetria de contratos", value: Permissions.REPORT_VOLCONTRACT },
        { label: "ECF Y520", value: Permissions.REPORT_ECF },
        { label: "Operações", value: Permissions.REPORT_VOTORANTIM },
        { label: "Trib. de Remessas Fin.", value: Permissions.REPORT_TRF },
      ],
    },
    {
      title: "Usuários",
      permissions: [
        { label: "Acessar", value: Permissions.USER_ACCESS },
        { label: "Criar", value: Permissions.USERS_CREATE },
        { label: "Visualizar", value: Permissions.USERS_VIEW },
        { label: "Alterar", value: Permissions.USERS_EDIT },
        { label: "Alterar Status", value: Permissions.USERS_STATUS },
      ],
    },
  ];
}

export function makeDascamUsersPermissionsGroups(): PermissionsGroupModel[] {
  return [
    {
      title: "Setor de Cadastro",
      permissions: [
        { label: "Visualizar", value: Permissions.CADASTRO_VIEW },
        { label: "Alterar", value: Permissions.CADASTRO_EDIT },
        {
          label: "Alterar Status",
          value: Permissions.CADASTRO_STATUS,
        },
      ],
    },
    {
      title: "Setor Compliance",
      permissions: [
        { label: "Visualizar", value: Permissions.COMPLIANCE_VIEW },
        { label: "Alterar", value: Permissions.COMPLIANCE_EDIT },
        {
          label: "Alterar Status",
          value: Permissions.COMPLIANCE_STATUS,
        },
      ],
    },
    {
      title: "Setor Diretoria",
      permissions: [
        { label: "Visualizar", value: Permissions.DIRETORIA_VIEW },
        { label: "Alterar", value: Permissions.DIRETORIA_EDIT },
        {
          label: "Alterar Status",
          value: Permissions.DIRETORIA_STATUS,
        },
      ],
    },
    {
      title: "Usuários Dascam",
      permissions: [
        { label: "Criar", value: Permissions.USERS_CREATE },
        { label: "Visualizar", value: Permissions.USERS_VIEW },
        { label: "Alterar", value: Permissions.USERS_EDIT },
        {
          label: "Ativar/Desativar",
          value: Permissions.USERS_STATUS,
        },
      ],
    },
    {
      title: "Fornecedor",
      permissions: [
        { label: "Ações", value: Permissions.PROVIDER_DASCAM_ACCESS }
      ],
    },
    {
      title: "Documentação",
      permissions: [
        { label: "Visualizar", value: Permissions.DOCUMENT_VIEW },
        {
          label: "Efetuar Upload",
          value: Permissions.DOCUMENT_CREATE,
        },
      ],
    },
    {
      title: "Processos",
      permissions: [
        { label: "Acessar", value: Permissions.PROCESS_ACCESS },
        {
          label: "Aprovar/Reprovar",
          value: Permissions.OPERATION_EDIT,
        },
      ],
    },
    {
      title: "A Pagar/Receber",
      permissions: [
        { label: "Acessar", value: Permissions.APAR_ACCESS },
        { label: "Editar", value: Permissions.APAR_CREATE }
      ],
    },
    {
      title: "Boletagem",
      permissions: [
        { label: "Acessar", value: Permissions.BILLING_ACCESS },
      ],
    },
  ];
}
