/* eslint-disable react-hooks/exhaustive-deps */
import { Box, VStack } from '@chakra-ui/layout'
import {
  Alert,
  AlertIcon,
  Button,
  Collapse,
  HStack,
  IconButton,
  Progress,
  useBoolean
} from '@chakra-ui/react'
import { axiosWebsite } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import {
  capitalizeFirstLetter,
  formatDate,
  formatProduct
} from 'helpers/formats'
import { getMoeda, valueConvert, valueReconvert } from 'helpers/moedas'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { OperationModel } from 'interfaces/operation'
import { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { BiShow } from 'react-icons/bi'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { ExcelExportXlsx } from 'services/ExcelExportXlsx'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import TableRender from 'v2/components/shared/TableRender'
import { useCurrencyQuery } from 'v2/hooks/api/currency/useCurrencyQuery'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import { createCurrencySearchPicker } from 'v2/services/app/factories/forms/createCurrencySearchPicker'
import { createCustomerSearchPicker } from 'v2/services/app/factories/forms/createCustomerSearchPicker'
import { createPayerReceiverSearchPicker } from 'v2/services/app/factories/forms/createPayerReceiverSearchPicker'

import DataTable, { TableColumn } from 'react-data-table-component'

const ProcessesClose = (): JSX.Element => {
  const { user } = useGlobals()
  const { data: currencies } = useCurrencyQuery()
  const [isLoading, loading] = useBoolean()
  const [showAlert, setShowAlert] = useBoolean()
  const [showAlertText, setShowAlertText] = useState<string>('')
  const [lastContractsType, setLastContractsType] = useState<string>()
  const [operationData, setOperationData] = useState<OperationModel[]>([])
  const { hashData, redirect } = useHashData()
  const [idSyscambio, setSyscambio] = useState(Number(user!.id_syscambio!))

  useEffect(() => {
    checkALterFilter()
  }, [hashData.contracts_type])

  const exportExcel = async () => {
    const rows = operationData
      .map((o) => {
        if (hashData.contracts_type === 'ProcessesClose') {
          return {
            NumeroOperacao: o.operation_number,
            TipoProduto: o.product_type,
            Moeda: o.currency_iso,
            Valor: valueConvert(
              Number(valueReconvert(String(o.operation_value), 'ANY')),
              'ANY'
            ),
            NomeBeneficiario: o.beneficiary_name,
            PrevisaoFechamento: formatDate(String(o.currency_closure)),
            IR: `${
              o.ir && o.ir.Percentual !== undefined
                ? `${o.ir.Percentual}%`
                : 'Não definido'
            }`
          }
        } else {
          return {
            NumeroOperacao: o.operation_number,
            TipoProduto: o.product_type,
            Moeda: o.currency_iso,
            Valor: valueConvert(
              Number(valueReconvert(String(o.operation_value), 'ANY')),
              'ANY'
            ),
            NomeBeneficiario: o.beneficiary_name,
            PrevisaoFechamento: formatDate(String(o.currency_closure)),
            IR: `${
              o.ir && o.ir.Percentual !== undefined
                ? `${o.ir.Percentual}%`
                : 'Não definido'
            }`
          }
        }
      })
      .filter((v) => !!v)

    await ExcelExportXlsx.fromJSON('exportacao', rows, [
      { wch: 16 },
      { wch: 15 },
      { wch: 7 },
      { wch: 15 },
      { wch: 30 },
      { wch: 20 },
      { wch: 15 }
    ])
  }

  function checkALterFilter () {
    if (lastContractsType !== undefined) {
      if (!hashData.contracts_type) {
        setLastContractsType(undefined)
        clear()
      } else if (hashData.contracts_type !== lastContractsType) {
        setOperationData([])
      }
    } else {
      setLastContractsType(hashData.contracts_type)
    }
  }

  const loadData = useCallback(async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      loading.on()
      setShowAlert.off()
      setShowAlertText('')
      if (!hashData.contracts_type) {
        loading.off()
        setShowAlert.on()
        return setShowAlertText('Selecione um Tipo de Contrato')
      }
      if (!hashData.initial_date && !hashData.operation_number) {
        loading.off()
        setShowAlert.on()
        return setShowAlertText('Selecione uma Data Inicial')
      }
      if (!hashData.end_date && !hashData.operation_number) {
        loading.off()
        setShowAlert.on()
        return setShowAlertText('Selecione uma Data Final')
      }

      const body = {
        id_syscambio: hashData.customer
          ? Number(hashData.customer.id_syscambio)
          : Number(user?.id_syscambio),
        document: hashData.customer
          ? hashData.customer.document
          : user?.document,
        initial_date: hashData.initial_date,
        end_date: hashData.end_date
      }

      if (hashData.operation_number) {
        Object.assign(body, { operation_number: hashData.operation_number })
      }
      if (hashData.currency_iso) {
        Object.assign(body, { currency_iso: hashData.currency_iso.CodigoISO })
      }
      if (hashData.payer_receiver) {
        Object.assign(body, {
          beneficiary_name: hashData.payer_receiver.NomeBeneficiario
        })
      }

      if (hashData.contracts_type === 'ProcessesClose') {
        const { data } = await axiosWebsite.post(
          `/operation/search`,
          body,
          config
        )
        loading.off()
        setOperationData(data)
      } else {
        const { data } = await axiosWebsite.post(
          `/operation/closed/search`,
          body,
          config
        )
        loading.off()
        setOperationData(data)
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        // history.push("/unauthorized");
      } else {
      }
    }
  }, [user?.document, user?.id_syscambio, hashData])

  function clear () {
    redirect({})
    setOperationData([])
  }

  const columns: TableColumn<OperationModel>[] = [
    {
      name: 'OPERAÇÃO',
      selector: (row) => row.operation_number ?? '',
      sortable: true,
      width: '115px'
    },
    {
      name: 'PRODUTO',
      selector: (row) => formatProduct(row.product_type ?? ''),
      width: '120px'
    },
    {
      name: 'PAGADOR/RECEBEDOR',
      selector: (row) => row.beneficiary_name ?? '',
      wrap: false,
      minWidth: '180px',
      reorder: true
    },
    {
      name: 'MOEDA',
      selector: (row) => row.currency_iso ?? '',
      width: '100px'
    },
    {
      name: 'VALOR',
      selector: (row) => Number(row.operation_value ?? 0),
      format: (row) =>
        Number(row.operation_value ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }) ?? '',
      sortable: true,
      width: '130px'
    },
    {
      name: 'IR',
      selector: (row) =>
        row.ir?.Percentual ? `${row.ir?.Percentual}%` : '---',
      width: '80px'
    },
    {
      name: 'PREV. FECHAMENTO',
      selector: (row) =>
        row.bacen_closure
          ? new Date(row.bacen_closure)
              ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      width: '180px'
    },
    {
      name: 'AÇÕES',
      cell: (row) => action(row.operation_number),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ]

  function action (operation_number: any) {
    return (
      <HStack m={1}>
        <Link
          to={`/operation/show/${operation_number}/${
            idSyscambio || user?.id_syscambio
          }${hashData?.contracts_type === 'ClosedContracts' ? '/1' : '/0'}`}
          title='Visualizar'
        >
          <IconButton aria-label='Visualizar' size={'sm'} colorScheme={'blue'}>
            <BiShow size={16} />
          </IconButton>
        </Link>
      </HStack>
    )
  }

  return (
    <CardPage title='Meus Contratos'>
      <Col px={2} w={'100%'}>
        <SimpleFormGenerator
          rows={[
            {
              columns: 4,
              fields: [
                {
                  type: 'select',
                  label: 'Contratos',
                  name: 'contracts_type',
                  options: [
                    {
                      label: () => 'Em Fechamento',
                      value: 'ProcessesClose'
                    },
                    {
                      label: () => 'Fechado (Aguardando Bacen)',
                      value: 'ClosedContracts'
                    }
                  ]
                },
                {
                  type: 'text',
                  label: 'N. Operação',
                  name: 'operation_number'
                },
                createCustomerSearchPicker(
                  user.company_id ?? user.id!,
                  user.cad_type === 'AUTORIZADO'
                    ? user.permission_group_company ?? []
                    : undefined,
                  {
                    label: 'CNPJ/CPF da Empresa',
                    name: 'customer',
                    labelKey: 'corporate_name'
                  }
                ),
                createCurrencySearchPicker(
                  {
                    label: 'Moeda',
                    name: 'currency_iso',
                    labelKey: 'Nome'
                  },
                  currencies
                )
              ]
            },
            {
              columns: 4,
              fields: [
                createPayerReceiverSearchPicker(
                  hashData?.customer?.id_syscambio,
                  {
                    readOnly: !hashData?.customer?.id_syscambio,
                    label: 'Pagador/Recebedor',
                    name: 'payer_receiver',
                    labelKey: 'NomeBeneficiario'
                  }
                ),
                {
                  type: 'date',
                  label: 'Período',
                  name: 'initial_date'
                },
                {
                  type: 'date',
                  label: 'até',
                  name: 'end_date'
                }
              ]
            }
          ]}
          value={hashData}
          onChange={(v) => {
            const { corporate_name, document, id_syscambio } = v.customer || {}
            const { CodPagReg, NomeBeneficiario } = v.payer_receiver || {}

            const nextParams: any = { ...v }
            setSyscambio(id_syscambio)
            if (v.customer) {
              nextParams.customer = {
                corporate_name,
                document,
                id_syscambio
              }
            }

            if (v.payer_receiver) {
              nextParams.payer_receiver = { CodPagReg, NomeBeneficiario }
            }

            if (v.currency_code) {
              nextParams.currency_code = { ...v.currency_code }
            }

            redirect(nextParams)
          }}
        />
        {showAlert && (
          <Alert status='warning'>
            <AlertIcon />
            {showAlertText}
          </Alert>
        )}
        <Collapse in={isLoading}>
          <Progress isIndeterminate colorScheme={'secondary'} h={1} />
        </Collapse>
        <VStack spacing={2} align='end'>
          <Box h='40px' mt={1}>
            <Button
              disabled={isLoading || operationData.length === 0}
              variant={'ghost'}
              color={'green.400'}
              colorScheme={'green'}
              mr='20px'
              onClick={() => {
                exportExcel()
              }}
            >
              Exportação Excel
            </Button>
            <Button
              disabled={isLoading}
              variant={'ghost'}
              color={'primary.400'}
              colorScheme={'primary'}
              mr='10px'
              onClick={() => {
                clear()
              }}
            >
              Limpar Filtros
            </Button>
            <Button
              disabled={isLoading}
              variant={'outline'}
              color={'primary.400'}
              colorScheme={'primary'}
              mr='10px'
              onClick={() => {
                loadData()
              }}
            >
              Buscar
            </Button>
          </Box>
        </VStack>
      </Col>
      <Col px={4}>
        <Col
          mt={3}
          rounded={'lg'}
          overflow={'auto'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
        >
          <DataTable
            noDataComponent=''
            columns={columns}
            data={operationData}
            dense={true}
            fixedHeader={true}
            fixedHeaderScrollHeight={'350px'}
            striped={true}
          />
        </Col>
      </Col>
    </CardPage>
  )
}

export default ProcessesClose
