import GenericButton from 'components/generic/GenericButton'
import usePagination from 'hooks/usePagination'
import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { UserModel } from 'interfaces/user'
import { axiosAuth, axiosWebsite } from 'helpers/axiosInstances'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/types'
import { isUnauthorized } from 'helpers/errors'
import { useHistory } from 'react-router-dom'
import { setGlobalMessage, clearLoading, showLoading } from 'redux/actions'
import RegisterUser from 'components/restricted-area/registration-data/data/user/subComponents/RegisterUser'
import { BiShow } from 'react-icons/bi'
import { BsCardChecklist } from 'react-icons/bs'
import CardPage from 'v2/components/pages/CardPage'
import { Button, IconButton } from '@chakra-ui/button'
import { Col } from 'v2/components/shared/Col'
import EditUser from 'components/restricted-area/registration-data/data/user/subComponents/EditUser'
import VisualUser from 'components/restricted-area/registration-data/data/user/subComponents/VisualUser'
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { AiOutlineEdit } from 'react-icons/ai'
import ActiveUser from 'components/restricted-area/registration-data/data/user/subComponents/ActiveUser'
import DisableUser from 'components/restricted-area/registration-data/data/user/subComponents/DisableUser'
import { ReactComponent as ActiveIcon } from 'assets/activeIcon.svg'
import { ReactComponent as InactiveIcon } from 'assets/inactiveIcon.svg'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { Permissions } from 'interfaces/web/permission'
import DataTable, { TableColumn } from 'react-data-table-component'
import { apiClient } from 'v2/services/clients/apiClient'
import { CustomerModel } from 'interfaces/customer'

const Users: React.FC = () => {
  const pageLimit = 20
  const { hasPermissions, isAutorizado } = useGlobals()
  const user = useSelector((state: GlobalState) => state.user)
  const permCreation = isAutorizado
    ? hasPermissions([Permissions.USERS_CREATE])
    : true
  const permEdit = isAutorizado
    ? !hasPermissions([Permissions.USERS_EDIT])
    : false
  const permView = isAutorizado
    ? !hasPermissions([Permissions.USERS_VIEW])
    : false
  const permStatus = isAutorizado
    ? !hasPermissions([Permissions.USERS_STATUS])
    : false

  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPage] = usePagination(pageLimit)
  const [registerUser, setRegisterUser] = useState<boolean>(false)
  const [editUser, setEditUser] = useState<boolean>(false)
  const [showUser, setShowUser] = useState<boolean>(false)
  const [activeUser, setActiveUser] = useState<boolean>(false)
  const [disableUser, setDisableUser] = useState<boolean>(false)
  const [idUserToAction, setIdUserToAction] = useState<string>()
  const [users, setUsers] = useState<UserModel[]>([])
  const [companys, setCompanys] = useState<CustomerModel[]>([])
  let [companysSelected, setCompanysSelected] = React.useState<number[]>([])
  const [userSelect, setUserSelect] = useState<UserModel>({})

  const columns: TableColumn<UserModel>[] = [
    {
      name: 'id',
      selector: (row) => row.id ?? '',
      sortable: true,
      width: '120px',
      omit: true
    },
    {
      name: 'Nome',
      selector: (row) => row.user_name ?? '',
      sortable: true,
      width: '240px'
    },
    {
      name: 'Login',
      selector: (row) => row.login ?? '',
      sortable: true,
      width: '150px'
    },
    {
      name: 'Empresa',
      selector: (row) => row.contact_role ?? '',
      sortable: true,
      width: '280px'
    },
    {
      name: 'Departamento',
      selector: (row) => row.contact_branch ?? '',
      sortable: true,
      width: '150px'
    },
    {
      name: 'E-mail',
      selector: (row) => row.email ?? '',
      sortable: true,
      width: '250px'
    },
    {
      name: 'Ações',
      width: '280px',
      cell: (row) => (
        <HStack m={1}>
          <IconButton
            disabled={permView}
            aria-label='Visualizar'
            size={'sm'}
            colorScheme={'blue'}
            onClick={() => {
              setIdUserToAction(row!.id?.toString())
              setShowUser(true)
            }}
          >
            <BiShow size={16} />
          </IconButton>
          <IconButton
            disabled={permEdit}
            aria-label='Editar'
            size={'sm'}
            colorScheme={'blue'}
            onClick={() => {
              setIdUserToAction(row!.id?.toString())
              setEditUser(true)
            }}
          >
            <AiOutlineEdit size={16} />
          </IconButton>
          <IconButton
            disabled={permEdit}
            aria-label='Grupo'
            size={'sm'}
            colorScheme={'blue'}
            onClick={() => {
              setUserSelect(row)
              onOpenGroup()
            }}
          >
            <BsCardChecklist size={16} />
          </IconButton>
          <Col width={'0'}>
            <ButtonStatus
              disabled={permStatus}
              isActive={row.status === 'ACTIVE' ? true : false}
              type='button'
              onClick={() => {
                setIdUserToAction(row!.id?.toString())
                row.status === 'ACTIVE'
                  ? setDisableUser(!disableUser)
                  : setActiveUser(!activeUser)
              }}
            >
              {row.status === 'ACTIVE' ? <ActiveIcon /> : <InactiveIcon />}
            </ButtonStatus>
          </Col>
        </HStack>
      ),
      ignoreRowClick: true,
      button: true
    }
  ]
  const {
    isOpen: isOpenGroup,
    onOpen: onOpenGroup,
    onClose: onCloseGroup
  } = useDisclosure()

  const handleChange = ({ selectedRows }: any) => {
    companysSelected = selectedRows.map((e: any) => e.id_syscambio)
  }

  const rowSelectCritera = (row: CustomerModel) =>
    userSelect.permission_group_company
      ? userSelect.permission_group_company.includes(Number(row.id_syscambio))
      : false

  async function getCompanys () {
    setCompanys(await apiClient.customers.getCustomersByUser(user!.id!))
  }

  function PermGroupForm () {
    const columnsB: TableColumn<CustomerModel>[] = [
      {
        name: 'id',
        selector: (row) => row.id_syscambio ?? '',
        sortable: true,
        omit: true
      },
      {
        name: 'Nome',
        selector: (row) => row.corporate_name ?? '',
        sortable: true
      },
      {
        name: 'Documento',
        selector: (row) => row.document ?? '',
        sortable: true
      }
    ]

    return (
      <>
        <Modal
          size={'6xl'}
          isOpen={isOpenGroup}
          onClose={() => {
            setCompanysSelected([])
            onCloseGroup()
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Visualização/Edição</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Col m={4}>
                <p>Selecione abaixo as empresas do grupo para esse usuário:</p>
                <DataTable
                  keyField='id_syscambio'
                  noDataComponent=''
                  columns={columnsB}
                  data={companys}
                  dense={true}
                  fixedHeader={true}
                  fixedHeaderScrollHeight={'550px'}
                  selectableRows={true}
                  selectableRowSelected={rowSelectCritera}
                  onSelectedRowsChange={handleChange}
                  striped={true}
                />
              </Col>
            </ModalBody>

            <ModalFooter>
              <Button
                variant={'ghost'}
                color={'red.400'}
                colorScheme={'red'}
                mr='20px'
                onClick={() => {
                  setCompanysSelected([])
                  onCloseGroup()
                }}
              >
                Cancelar
              </Button>

              <Button
                variant={'outline'}
                color={'green.400'}
                colorScheme={'green'}
                mr='20px'
                onClick={() => save(userSelect)}
              >
                Atualizar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
  async function save (data: UserModel) {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      data.permission_group_company =
        companysSelected.map((e) => Number(e)) ?? []

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      await axiosAuth
        .put(`/users/${data.id}`, data, config)
        .then((_) => {
          onCloseGroup()
        })
        .catch((e) => {
          console.log(e)
        })
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(clearLoading())
    }
  }
  const getUserData = useCallback(async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const company_id =
        user?.cad_type === 'AUTORIZADO' ? user.company_id : user?.id
      const { data } = await axiosAuth.get(
        `/filter-users?limit=${pageLimit}&offset=${
          (currentPage - 1) * pageLimit
        }&cad_type=AUTORIZADO&company_id=${company_id}`,
        config
      )

      setUsers(data.users)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, dispatch, history, idUserToAction])

  useEffect(() => {
    getUserData()
    getCompanys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {PermGroupForm()}
      <CardPage
        title='Gestão de Usuários'
        _headerRight={
          permCreation && (
            <Button
              variant='outline'
              color={'primary.400'}
              colorScheme={'primary'}
              onClick={() => setRegisterUser(!registerUser)}
            >
              Adicionar usuário
            </Button>
          )
        }
      >
        <Col px={4}>
          <Col
            mt={2}
            rounded={'lg'}
            overflow={'hidden'}
            border={'1px solid transparent'}
            borderColor={'primary.600'}
          >
            {/*<TableRender
              _thead={{
                _tr: { bg: 'primary.600' },
                _th: {
                  color: 'white',
                  border: 0,
                  fontSize: '10px',
                  px: 1,
                  py: 2
                }
              }}
              config={{
                columns: [
                  {
                    key: 'user_name',
                    title: 'Nome',
                    defaultLabel: 'Não definido'
                  },
                  {
                    key: 'contact_role',
                    title: 'Empresa',
                    defaultLabel: 'Não definido'
                  },
                  {
                    key: 'contact_branch',
                    title: 'Departamento',
                    defaultLabel: 'Não definido'
                  },
                  {
                    key: 'email',
                    title: 'E-mail',
                    defaultLabel: 'Não definido'
                  },
                  {
                    key: 'contact_cellphone',
                    title: 'Celular',
                    defaultLabel: 'Não definido'
                  },
                  {
                    key: 'contact_phone',
                    title: 'Telefone',
                    defaultLabel: 'Não definido'
                  },
                  {
                    key: 'action',
                    title: 'Ações',
                    defaultLabel: 'Ações',
                    render: (value: any, item: any) => {
                      return (
                        <HStack>
                          <IconButton
                            aria-label='Visualizar'
                            size={'sm'}
                            colorScheme={'blue'}
                            onClick={() => {
                              setIdUserToAction(item.id)
                              setShowUser(true)
                            }}
                          >
                            <BiShow size={16} />
                          </IconButton>
                          <IconButton
                            aria-label='Editar'
                            size={'sm'}
                            colorScheme={'blue'}
                            onClick={() => {
                              setIdUserToAction(item.id)
                              setEditUser(true)
                            }}
                          >
                            <AiOutlineEdit size={16} />
                          </IconButton>
                          <Col width={'0'}>
                            <ButtonStatus
                              isActive={item.status === 'ACTIVE' ? true : false}
                              type='button'
                              onClick={() => {
                                setIdUserToAction(item.id)
                                item.status === 'ACTIVE'
                                  ? setDisableUser(!disableUser)
                                  : setActiveUser(!activeUser)
                              }}
                            >
                              {item.status === 'ACTIVE' ? (
                                <ActiveIcon />
                              ) : (
                                <InactiveIcon />
                              )}
                            </ButtonStatus>
                          </Col>
                        </HStack>
                      )
                    }
                  }
                ]
              }}
              data={users}
            />*/}
            <DataTable
              keyField='id'
              noDataComponent=''
              columns={columns}
              data={users}
              dense={true}
              fixedHeader={true}
              fixedHeaderScrollHeight={'550px'}
              striped={true}
            />
          </Col>
        </Col>
      </CardPage>
      <RegisterUser
        showModal={registerUser}
        handleModal={() => setRegisterUser(!registerUser)}
        getUserData={() => getUserData()}
      />
      <EditUser
        id={String(idUserToAction)}
        showModal={editUser}
        handleModal={() => setEditUser(!editUser)}
        getUserData={() => getUserData()}
      />
      <VisualUser
        id={String(idUserToAction)}
        showModal={showUser}
        handleModal={() => setShowUser(!showUser)}
      />
      <ActiveUser
        id={String(idUserToAction)}
        showModal={activeUser}
        handleModal={() => setActiveUser(!activeUser)}
        getUserData={() => getUserData()}
      />
      <DisableUser
        id={String(idUserToAction)}
        showModal={disableUser}
        handleModal={() => setDisableUser(!disableUser)}
        getUserData={() => getUserData()}
      />
    </>
  )
}

const ButtonStatus = styled(GenericButton)<{ isActive?: boolean }>`
  width: 60px;
  height: 25px;
  padding: 0.1rem;
  border: 1px solid ${(props) => props.theme.color.white1};
  border-radius: 10px;
  background: ${(props) => props.theme.color.primary_dark};
  align-items: center;
  justify-content: ${(props) => (props.isActive ? 'flex-start' : 'flex-end')};

  &:hover {
    background: ${(props) => props.theme.color.primary_main};
  }

  & svg {
    width: 30px;
    height: auto;
  }
`

export default Users
