import styled from "styled-components";
import { devices } from "helpers/devices";
import GenericButton from "components/generic/GenericButton";
import { InputImgBase64 } from "components/generic/GenericInputs";
import { useEffect, useState } from "react";
import {
  UserModel,
  UserResponsibleEnum,
  UserStatusEnum,
} from "interfaces/user";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "redux/types";
import { Redirect, useHistory } from "react-router-dom";
import {
  clearGlobalMessage,
  clearUser,
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading,
} from "redux/actions";
import { isUnauthorized } from "helpers/errors";
import { axiosAuth, axiosEmail } from "helpers/axiosInstances";
import { BankModel } from "interfaces/bank";
import BankAccountForeign from "./subComponents/BankAccountForeign";
import DocumentationForeign from "./subComponents/DocumentationForeign";
import RegisterForeign from "./subComponents/RegisterForeign";
import { enableButtonUser, validBody } from "helpers/validBody";
import { ArchiveModel } from "interfaces/user-archives";
import PasswordChange from "components/generic/PasswordChange";
import { validateFild, validateThatReturn } from "helpers/validFilds";
import {
  foreignRequired,
  userDocRequired,
  userThatReturn,
} from "helpers/userRequired";
import { capitalizeFirstLetter } from "helpers/formats";
import { sendNotification } from "services/notificationService";
import { Avatar } from "@chakra-ui/react";

const ForeignStyled = styled.section`
  background: transparent;
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  /* overflow-y: auto; */
`;

const Container = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */

  @media ${devices.phoneOnly} {
    background-position: center left;
  }
`;

const TemplateFlex = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem ${props => props.theme.margins.from_sides};

  @media ${devices.phoneOnly} {
    padding: 1rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 2rem ${props => props.theme.margins.from_sides};
  }
`;

const CardForm = styled.form`
  padding: 1rem;
  box-shadow: 1px 2px 4px gray;
  background-color: ${props => props.theme.color.white1};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
`;

const CardMenu = styled.div`
  width: 300px;
  min-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const Button = styled(GenericButton) <{ width?: string }>`
  margin: 1rem 0;
  width: ${props => (props.width ? props.width : "260px")};
`;

const ButtonConfirm = styled(GenericButton) <{ isDeny?: boolean }>`
  margin: 1rem 0;
  width: 260px;
  color: ${props => props.theme.color.white1};
  background-color: ${props =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.button_normal};

  &:hover {
    background-color: ${props =>
    props.isDeny
      ? props.theme.color.button_deny_hover
      : props.theme.color.button_normal_hover};
  }
`;

const CardItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.5rem;
  overflow-y: auto;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 100;
  background-color: ${props => props.theme.color.white1};
  height: auto;
  width: 100%;
  position: sticky;
  top: -1px;
  left: 0;
  padding: 0.3rem;

  @media ${devices.tabletPortrait} {
    align-items: flex-start;
  }

  @media ${devices.bigDesktop} {
    align-items: center;
  }
`;

const TabsBlue = styled.div`
  width: 100%;
  height: auto;
  padding: 0.2rem;
  background: ${props => props.theme.color.primary_main};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & div {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }
`;

const ButtonDelete = styled(GenericButton) <{ isDeny?: boolean }>`
  margin: 0.2rem;
  color: ${props => props.theme.color.white1};
  background-color: ${props =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.button_normal} !important;

  &:hover {
    background-color: ${props =>
    props.isDeny
      ? props.theme.color.button_deny_hover
      : props.theme.color.button_normal_hover} !important;
  }
`;

const ButtonTabs = styled(GenericButton) <{
  isDeny?: boolean;
  isYellow?: boolean;
  isBlue?: boolean;
}>`
  width: auto;
  height: 25px;
  margin: 0.1rem;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  color: ${props => props.theme.color.white1};
  font-size: 0.9rem;
  background-color: ${props =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isYellow
        ? props.theme.color.secondary_main
        : props.isBlue
          ? "#296ba9"
          : props.theme.color.button_normal} !important;

  &:hover {
    background-color: ${props =>
    props.isDeny
      ? props.theme.color.button_deny_hover
      : props.isYellow
        ? props.theme.color.secondary_light
        : props.isBlue
          ? "#4b87be"
          : props.theme.color.button_normal_hover} !important;
  }
`;

const ButtonModal = styled(GenericButton) <{
  width?: string;
  isDisabled?: boolean;
  isDeny?: boolean;
}>`
  margin: 0.3rem 0;
  padding: 1rem;
  width: 100%;
  color: ${props =>
    props.isDisabled
      ? props.theme.color.white1
      : props.isDeny
        ? props.theme.color.white1
        : props.theme.color.primary_main};
  background-color: ${props =>
    props.isDisabled
      ? props.theme.color.gray3
      : props.isDeny
        ? props.theme.color.button_deny
        : props.theme.color.secondary_main};
  cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${props =>
    props.isDisabled
      ? props.theme.color.gray4
      : props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`;

type Tabs = "registration" | "bank" | "documentation";

const Foreign = (): JSX.Element => {
  // const theme = useContext(ThemeContext);
  const [currentTab, setCurrentTab] = useState<Tabs>("registration");
  const user = useSelector((state: GlobalState) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  //User State
  const [passwordModal, setPasswordModal] = useState<boolean>(false);
  const [mandatory, setMandatory] = useState({});
  const [userData, setUserData] = useState<UserModel>({});
  const [userFixedData, setUserFixedData] = useState<UserModel>({});
  const [body, setBody] = useState<UserModel>({});
  const [bodyDoc, setBodyDoc] = useState<ArchiveModel>({});
  //Conta Bancaria State
  const [accountBanks, setAccountBanks] = useState<BankModel[]>([]);
  const [userDocumentation, setUserDocumentation] = useState<ArchiveModel>({});
  const [userFixedDocumentation, setUserFixedDocumentation] =
    useState<ArchiveModel>({});

  const getUserData = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
          subMessage: "Carregando dados do usuário",
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/users/${user?.id}`, config);

      setUserFixedData(data);
      setUserData(data);
      getDocumentData();
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    }
  };

  const getDocumentData = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
          subMessage: "Carregando documentos",
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/archives/${user?.id}`, config);

      setUserFixedDocumentation(data);
      setUserDocumentation(data);
      getBankData();
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    }
  };

  const getBankData = async () => {
    try {
      dispatch(
        showLoading({
          message: "Finalizando",
          isLoading: true,
          subMessage: "Aplicando dados do usuário",
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/bank/${user?.id}`, config);

      setAccountBanks(data);
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      if (
        validateThatReturn(bodyDoc, userThatReturn) &&
        userData.status === "ACTIVE"
      ) {
        body.status = "PENDING" as UserStatusEnum;
        body.responsible_sector = "CLIENTE" as UserResponsibleEnum;
        body.old_sector = "CLIENTE";
      }

      // @ts-ignore
      body.user_id = user.id;

      const res = await axiosAuth.put(`/users/${user?.id}`, body, config);

      if (userData.id_syscambio && res) {
        const resUser = await axiosAuth.get(`/users/${userData.id}`, config);
        if (resUser) {
          const bodyUpdate = {
            user: resUser.data,
            bank: accountBanks,
            beneficiary: [],
          };
          await axiosAuth.put(`/user/update`, bodyUpdate, config);
        }
      }
      handleSubmitDocuments();

      dispatch(setSuccessMessage("Dados Salvos com Sucesso!"));
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    }
  };

  const fields = ["rg_cpf_doc", "nda_doc"];

  const handleSubmitDocuments = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      bodyDoc.user_id = user?.id;
      bodyDoc.id = userDocumentation.id;

      // userDocumentation.nda_doc

      console.log("Foreign.tsx", { bodyDoc });

      await axiosAuth.post(`/archives`, bodyDoc, config);
      getUserData();
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
      if (
        validateThatReturn(bodyDoc, userThatReturn) &&
        userData.status === "ACTIVE"
      ) {
        history.go(0);
      }
    }
  };

  const handleDelete = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      await axiosAuth.delete(`/users-delete/${user?.id}`, config);

      dispatch(setSuccessMessage("Cadastro apagado com sucesso!"));
      dispatch(clearGlobalMessage());
      dispatch(clearUser());
      localStorage.clear();
      history.push("/");
    } catch (error: any) {
      dispatch(clearLoading());
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  const deleteRegistration = () => {
    dispatch(
      setGlobalMessage({
        message: `Atenção: ao Excluir seu Pré-Cadastro não será possível cancelar esta operação e todos os dados serão removidos do sistema.
          Havendo necessidade de um novo Cadastro, será necessário refazer todo o processo.`,
        type: "WARN",
        buttons: (
          <>
            <ButtonDelete onClick={() => handleDelete()}>
              Sim! Quero apagar o cadastro
            </ButtonDelete>
            <ButtonDelete isDeny onClick={() => dispatch(clearGlobalMessage())}>
              Cancelar
            </ButtonDelete>
          </>
        ),
      })
    );
  };

  const handleApprovalStep = async () => {
    if (
      validateFild(userData, foreignRequired, setMandatory) &&
      validateFild(userDocumentation, userDocRequired, setMandatory)
    ) {
      try {
        dispatch(
          showLoading({
            message: "Enviando dados do usuário",
            isLoading: true,
          })
        );
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        };
        const bodyApproval = {
          status: "EVALUATION",
          responsible_sector: userData.id_syscambio ? "COMPLIANCE" : "CADASTRO",
          company_id: user?.id,
        };
        const res = await axiosAuth.put(
          `/users/${user?.id}`,
          bodyApproval,
          config
        );
        dispatch(
          showLoading({
            message: "Finalizando",
            isLoading: true,
          })
        );
        if (res) {
          const bodyMail = {
            emails: [userData.email],
            name: userData.user_name,
            status: "Análise",
            sector: capitalizeFirstLetter(bodyApproval.responsible_sector),
            text: "Aqui Jaz um texto de Exemplo.",
          };
          await axiosEmail.post(`/update-status`, bodyMail, config);

          await sendNotification({
            title: "Novo Usuário Cadastrado",
            message: `Usuário ${user?.user_name} aguardando liberação.`,
            areas: [UserResponsibleEnum.CADASTRO],
          });
        }
        dispatch(setSuccessMessage("Envio feito com sucesso!"));
        getUserData();
      } catch (error: any) {
        dispatch(clearLoading());
        if (isUnauthorized(error)) {
          history.push("/unauthorized");
        } else {
          dispatch(
            setGlobalMessage({
              message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
              type: "ERROR",
            })
          );
        }
      }
    } else {
      dispatch(
        setGlobalMessage({
          message: `Preencha todos os campos obrigatórios, que possuem "*"`,
          type: "WARN",
        })
      );
    }
  };

  const handleConfirmSubmit = () => {
    dispatch(
      setGlobalMessage({
        message: `Ao alterar o documento referente a procuração, 
        será necessário enviar o cadastro para uma nova avaliação. 
        Verifique se todas as informações estão corretas e confirme o envio.`,
        type: "MESSAGE",
        buttons: (
          <>
            <ButtonModal
              onClick={() => {
                dispatch(clearGlobalMessage());
                handleSubmit();
              }}
            >
              Confirmar
            </ButtonModal>
            <ButtonModal isDeny onClick={() => dispatch(clearGlobalMessage())}>
              Cancelar
            </ButtonModal>
          </>
        ),
      })
    );
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nextBodyDoc = validBody(userFixedDocumentation, userDocumentation);
    // console.log("set body doc", { bodyDoc, nextBodyDoc });

    setBodyDoc(nextBodyDoc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDocumentation]);

  useEffect(() => {
    setBody(validBody(userFixedData, userData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      {user!.cad_type !== "ESTRANGEIRO" ? <Redirect to="/home" /> : null}
      {user === undefined ? <Redirect to="/unauthorized" /> : null}
      <ForeignStyled>
        <Container>
          <TemplateFlex>
            <CardForm
              onSubmit={event => {
                event.preventDefault();
                handleApprovalStep();
              }}
            >
              <CardMenu>
                <div>
                  {/* <InputImgBase64
                    id="selectedNewImg"
                    avatar={userData.photo!}
                    changeValue={value =>
                      setUserData({
                        ...userData,
                        photo: value,
                      })
                    }
                    readOnly={
                      userData.status === "ACTIVE" ||
                      userData.status === "EVALUATION"
                    }
                  /> */}
                  <Avatar
                    bg={"primary.200"}
                    name={user?.user_name}
                    src={user?.photo}
                    size="2xl"
                  />
                  {userData.responsible_sector === "CLIENTE" &&
                    userData.status === "PENDING" ? (
                    <>
                      <Button
                        type="button"
                        onClick={() =>
                          document.getElementById("selectedNewImg")!.click()
                        }
                      >
                        Adicionar Foto
                      </Button>
                      <Button
                        type="button"
                        onClick={() => setPasswordModal(!passwordModal)}
                      >
                        Alterar Senha
                      </Button>
                    </>
                  ) : null}
                </div>
                <div>
                  {userData.responsible_sector === "CLIENTE" &&
                    userData.status === "PENDING" ? (
                    <>
                      {!(
                        enableButtonUser(body) || enableButtonUser(bodyDoc)
                      ) ? (
                        <ButtonConfirm type="submit">
                          Enviar para Análise
                        </ButtonConfirm>
                      ) : null}
                    </>
                  ) : null}
                  {userData.responsible_sector === "CLIENTE" &&
                    userData.status === "PENDING" &&
                    !userData.id_syscambio ? (
                    <ButtonConfirm
                      type="button"
                      isDeny
                      onClick={() => deleteRegistration()}
                    >
                      Excluir Pré-Cadastro
                    </ButtonConfirm>
                  ) : null}
                </div>
              </CardMenu>
              <CardItems>
                <TabsContainer>
                  <TabsBlue>
                    <div>
                      <ButtonTabs
                        isBlue={currentTab !== "registration"}
                        isYellow={currentTab === "registration"}
                        type="button"
                        onClick={() => setCurrentTab("registration")}
                      >
                        Pré - cadastro *
                      </ButtonTabs>
                      <ButtonTabs
                        isBlue={currentTab !== "documentation"}
                        isYellow={currentTab === "documentation"}
                        type="button"
                        onClick={() => setCurrentTab("documentation")}
                      >
                        Documentação
                      </ButtonTabs>
                      <ButtonTabs
                        isBlue={currentTab !== "bank"}
                        isYellow={currentTab === "bank"}
                        type="button"
                        onClick={() => setCurrentTab("bank")}
                      >
                        Dados Bancários
                      </ButtonTabs>
                    </div>
                    <div>
                      {userData.status !== "EVALUATION" ? (
                        <>
                          {enableButtonUser(body) ||
                            enableButtonUser(bodyDoc) ? (
                            <ButtonTabs
                              type="button"
                              onClick={() =>
                                validateThatReturn(bodyDoc, userThatReturn) &&
                                  userData.status === "ACTIVE"
                                  ? handleConfirmSubmit()
                                  : handleSubmit()
                              }
                            >
                              Salvar Alterações
                            </ButtonTabs>
                          ) : null}
                          <ButtonTabs
                            isDeny
                            type="button"
                            onClick={() => getUserData()}
                          >
                            Cancelar Alterações
                          </ButtonTabs>
                        </>
                      ) : null}
                    </div>
                  </TabsBlue>
                </TabsContainer>
                {currentTab === "registration" ? (
                  <RegisterForeign
                    userData={userData}
                    changeUserData={value => setUserData(value)}
                    mandatory={mandatory}
                  />
                ) : null}
                {currentTab === "documentation" ? (
                  <DocumentationForeign
                    userDocumentation={userDocumentation}
                    changeDocumentation={value => setUserDocumentation(value)}
                    mandatory={mandatory}
                    userData={userData}
                  />
                ) : null}
                {currentTab === "bank" ? (
                  <BankAccountForeign
                    userId={user?.id}
                    accountBanks={accountBanks}
                    setAccountBanks={setAccountBanks}
                    getBankData={() => getBankData()}
                    userData={userData}
                  />
                ) : null}
              </CardItems>
            </CardForm>
          </TemplateFlex>
        </Container>
      </ForeignStyled>
      <PasswordChange
        showModal={passwordModal}
        handleModal={() => setPasswordModal(!passwordModal)}
        id={String(user?.id)}
      />
    </>
  );
};

export default Foreign;
