import {
  CloseButton,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  useBoolean,
  useDisclosure
} from '@chakra-ui/react'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import AppModalPicker, {
  AppModalPickerProps,
  AppModelPickerManagerProps
} from 'v2/components/shared/AppModalPicker'
import ButtonCopy from 'v2/components/shared/ButtonCopy'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import { FieldProps } from '../..'

interface SearchPickerFieldProps
  extends FieldProps<'search-picker', any, any> {}

const SearchPickerField: React.FC<SearchPickerFieldProps> = ({
  infos,
  value,
  onChange,
  isReadonly
}) => {
  const showModal = useDisclosure()
  const [isLoading, loading] = useBoolean()
  const isUndefined = value === undefined
  const isNull = value === null

  const [searchData, setSearchData] = useState<Partial<AppModalPickerProps>>()
  // const [searchValue, setSearchValue] = useState<any>();

  const fieldValue = useMemo(() => {
    const defaultValue = 'Não Definido'

    if (isUndefined && isReadonly) {
      return infos.placeholder || defaultValue
    }

    if (isUndefined || isNull) {
      return infos.placeholder || defaultValue
    }

    const nextValue = value?.[infos.labelKey]

    if (!nextValue) {
      return 'Error'
    }

    return nextValue
  }, [
    value,
    isUndefined,
    isNull,
    isReadonly,
    infos.placeholder,
    infos.labelKey
  ])

  const fetchSearch = async () => {
    loading.on()
    infos.onSearch().then(setSearchData).finally(loading.off)
  }

  const onSearchStart = () => {
    setSearchData(() => undefined)
    fetchSearch().then(() => {
      setTimeout(() => {
        showModal.onOpen()
      }, 50)
    })
  }

  const modalValue = value?.[searchData?.uuidField || 'id'] || undefined

  return (
    <>
      {searchData && (
        <AppModalPicker
          {...showModal}
          {...(searchData as any)}
          onSelect={(item) => {
            // setSearchValue(() => item);
            // infos.onSelect(item);
            onChange?.(item)
            setTimeout(() => {
              showModal.onClose()
            }, 50)
          }}
          value={modalValue}
        />
      )}
      <FormControl
        minH={'100%'}
        display={'flex'}
        flexDir={'column'}
        fontFamily={'heading'}
      >
        <Col mb={1}>
          <FormLabel fontWeight={'bold'} mb={0}>
            {infos.label}
          </FormLabel>
          <FormLabel color={'GrayText'} mb={0} fontSize={'xs'}>
            {infos.description}
          </FormLabel>
        </Col>
        <Row pos={'relative'} alignItems={'center'} role='group' mt={'auto'}>
          <Input
            rounded={'sm'}
            value={`${fieldValue}`}
            placeholder={infos.placeholder}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChange?.(e.target.value)
            }}
            readOnly
            variant={'filled'}
            fontStyle={isUndefined ? 'italic' : 'normal'}
            color={isUndefined ? 'gray.500' : 'inherit'}
            _focus={isReadonly ? {} : { bg: 'white' }}
          />
          <HStack pos={'absolute'} right={1}>
            {!isUndefined && !isNull && !isReadonly && (
              <CloseButton
                size={'sm'}
                onClick={() => {
                  onChange?.(undefined)
                }}
              />
            )}
            <IconButton
              aria-label='Pesquisar'
              variant={'ghost'}
              color={'primary.500'}
              colorScheme={'primary'}
              size={'sm'}
              onClick={onSearchStart}
              isLoading={isLoading}
              isDisabled={isReadonly}
            >
              <BiSearch />
            </IconButton>
          </HStack>
        </Row>
      </FormControl>
    </>
  )
}

export default SearchPickerField
