import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSuccessMessage } from "redux/actions";
import { GlobalState } from "redux/types";
import styled, { keyframes } from "styled-components";

const PopUpThenDown = keyframes`
    0% {
        transform: translateY(100%);
    }
    15% {
        transform: translate(0);
    }
    85% {
        transform: translate(0);
    }
    100% {
        transform: translateY(100%);
    }
    `;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  z-index: 90000;
  pointer-events: none;
`;

const Container = styled.div`
  padding: 2rem 3rem;
  background: ${props => props.theme.color.button_normal};
  border-radius: 30px 30px 0 0;
  animation: ${PopUpThenDown} 2500ms 1 both ease-in-out;
  max-width: 50vw;
  font-weight: bold;

  & p {
    color: ${props => props.theme.color.white1};
  }
`;

const SuccessMessage = (): JSX.Element | null => {
  const successMessage = useSelector(
    (state: GlobalState) => state.successMessage
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (successMessage !== undefined) {
      setTimeout(() => {
        dispatch(clearSuccessMessage());
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMessage]);

  if (successMessage !== undefined) {
    return (
      <Background>
        <Container>
          <p>{successMessage}</p>
        </Container>
      </Background>
    );
  } else {
    return null;
  }
};

export default SuccessMessage;
