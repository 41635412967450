import { CustomerModel } from "interfaces/customer";
import { ApiContext } from "../ApiContext";

export class ApiCustomers extends ApiContext {


  public async getCustomersByUser(userId: number | string) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }

    return this.client.httpAuth
      .get<CustomerModel[]>(`/gpempresa/${userId}`, config)
      .then((res) => res.data);
  }
}
