import { IconButton, Square, Text } from '@chakra-ui/react'
import truncate from 'lodash.truncate'
import React, { useMemo } from 'react'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { MdAttachment } from 'react-icons/md'
import { FileProp } from 'v2/components/forms/documents/contracts'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import { niceBytes } from 'v2/services/helpers/niceBytes'

interface FileItemProps extends FileProp {
  length?: number
}

const FileItem: React.FC<FileItemProps> = ({
  isBase64,
  src,
  mimeType,
  name,
  size,
  length = 30
}) => {
  const title = useMemo(() => {
    let title = size ? `${niceBytes(size)}` : 'Arquivo'
    if (name) {
      title += `, ${name}`
    }

    return title
  }, [size, name])

  const link = useMemo(() => {
    if (src && src.length > 0) {
      return src
    }

    return undefined
  }, [src])

  return (
    <Row alignItems={'center'} userSelect={'none'} title={title}>
      <Square size={8} color={'green.700'} rounded={'md'}>
        <MdAttachment size={26} />
      </Square>
      <Col flex={1} pl={2} mr={4}>
        <Text fontWeight={'bold'} fontSize={'xs'}>
          {truncate(title, { length })}
        </Text>
        <Text fontSize={'xs'}>Arquivo</Text>
      </Col>
      {link && (
        <a href={link} target={'_blank'} rel='noreferrer'>
          <IconButton
            aria-label='Download File'
            size={'sm'}
            colorScheme={'orange'}
          >
            <AiOutlineCloudDownload size={18} />
          </IconButton>
        </a>
      )}
    </Row>
  )
}

export default FileItem
