import { axiosWebsite } from 'helpers/axiosInstances'
import { BankEntity } from 'interfaces/bank'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import GenericButton from '../GenericButton'
import { InputText } from '../GenericInputs'
import Modal from '../Modal'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const Button = styled(GenericButton)<{ isDeny?: boolean }>`
  margin: 0.3rem 0;
  width: 150px;
  color: ${(props) => props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
`

const CardGrid = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.1rem;
`

const TableGrid = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: minmax(100px, 150px) minmax(300px, 1fr);
  grid-auto-rows: 35px;
  height: 100%;
  max-height: 200px;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

const ColumnText = styled.div<{ isActive?: boolean }>`
  border-right: 1px solid ${(props) => props.theme.color.white5};
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid ${(props) => props.theme.color.white5};
  padding: 0 0.5rem;
  background: ${(props) =>
    props.isActive ? props.theme.color.white4 : props.theme.color.white1};

  &:hover {
    cursor: pointer;
  }
`

const TextColumn = styled(Text)`
  color: black;
  user-select: none;
`

interface ModalPickBankProps {
  isOpen?: boolean
  onClose?: () => void
  onChange?: (bank: BankEntity) => void
  value?: BankEntity
}

const ModalPickBank: React.FC<ModalPickBankProps> = ({
  onChange,
  value,
  isOpen = true,
  onClose
}) => {
  const [banks, setBanks] = useState<BankEntity[]>([])
  const [bankSelected, setBankSelected] = useState<string>()
  const [bankActive, setBankActive] = useState<string>()
  const [filter, setFilter] = useState({
    bankCode: '',
    bankName: ''
  })
  const currentBanks = useMemo(() => {
    let filteredBanks = banks.filter((b) => !!b.bank_code)
    if (filter.bankCode || filter.bankName) {
      /*filteredBanks = filteredBanks.reduce((prev, current, index) => {
        const findByBankCode = prev.find(
          (i) => Number(i.bank_code) === Number(current.bank_code)
        )

        if (!findByBankCode) {
          return [...prev, current]
        }
        console.log(prev)
        return prev
      }, [] as BankEntity[])*/
      if (filter.bankCode) {
        filteredBanks = filteredBanks.filter((e) =>
          String(e.bank_code).includes(String(filter.bankCode))
        )
      }
      if (filter.bankName) {
        filteredBanks = filteredBanks.filter((e) =>
          String(e.bank_info).includes(
            String(filter.bankName).toLocaleUpperCase()
          )
        )
      }
    }

    filteredBanks.sort((a, b) => Number(a.bank_code) - Number(b.bank_code))

    return filteredBanks
  }, [filter, banks])

  const onFilter = async () => {
    console.log('filter', filter)
  }

  const load = async () => {
    setBanks(() => [])
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    axiosWebsite
      .get('/bancos', config)
      .then((res) => res.data)
      .then(setBanks)
  }

  const columnEvents = (bank: BankEntity) => {
    const key = `${bank.bank_code}${bank.bank_document}${bank.bank_info}`
    const isActive =
      [bankSelected, bankActive].includes(key) ||
      value?.bank_code === bank.bank_code

    return {
      onClick: () => {
        setBankSelected(() => key)
      },
      onDoubleClick: () => {
        setBankActive(() => key)
        onChange?.(bank)
      },
      isActive
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Modal
      showModal={isOpen}
      title='Selecione um Banco'
      fontSize='0.5rem'
      backFocus
      width='50%'
      close={onClose}
    >
      <Container>
        <ItemRowTop>
          <InputText
            label='Cód. Inst. Financeira'
            maxWidth='200px'
            maxLength={7}
            value={filter.bankCode}
            changeValue={(value) => {
              setFilter((old) => ({ ...old, bankCode: value }))
            }}
          />
          <InputText
            label='Nome Instituição financeira'
            maxWidth='300px'
            value={filter.bankName}
            changeValue={(value) => {
              setFilter((old) => ({ ...old, bankName: value }))
            }}
          />
        </ItemRowTop>
        <CardGrid>
          <TableGrid>
            <GridTitle>
              <Text fontWeight='bold'>Cód. Inst. Financeira</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Instituição Financeira</Text>
            </GridTitle>
            {currentBanks.map((bank) => (
              <>
                <ColumnText {...columnEvents(bank)}>
                  <TextColumn Color={'black'}>{bank.bank_code}</TextColumn>
                </ColumnText>
                <ColumnText {...columnEvents(bank)}>
                  <TextColumn Color={'black'}>{bank.bank_info}</TextColumn>
                </ColumnText>
              </>
            ))}
            {/* Table content */}
          </TableGrid>
        </CardGrid>
      </Container>
    </Modal>
  )
}

export default ModalPickBank
