import { UseQueryOptions } from "react-query";

function makeQueryOptions(options?: UseQueryOptions) {
  return options as any;
}

export function makeQuerySmallCache() {
  return makeQueryOptions({
    staleTime: 1000 * 60 * 2, // 2 minutes
  });
}
