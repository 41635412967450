import * as FileSaver from 'file-saver';
import { formatColumn } from 'helpers/formats';
import * as XLSX from 'xlsx';

export class ExcelExportXlsx {
  public static async fromJSON(fileName: string, items: any[], colConfig: any[] = [], formatMoney?: number[], formatTaxa?: number[]) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(items);
    if (formatMoney) {
      formatMoney.forEach(element => {
        formatColumn(
          ws,
          element,
          '#,##0.00'
        )
      });
    }
    if (formatTaxa) {
      formatTaxa.forEach(element => {
        formatColumn(
          ws,
          element,
          '#,##0.0000'
        )
      });
    }
    ws["!cols"] = colConfig ?? []
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }


}


