import {
  Button,
  HStack,
  Progress,
  Text,
  useBoolean,
  useToast
} from '@chakra-ui/react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { DocumentItemProps } from 'v2/components/forms/documents/contracts'
import OperationDocuments from 'v2/components/modules/operation/OperationDocuments'
import OperationInfos, {
  OperationInfosValue
} from 'v2/components/modules/operation/OperationInfos'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import { useCurrencyQuery } from 'v2/hooks/api/currency/useCurrencyQuery'
import { useCustomersByAuth } from 'v2/hooks/api/customer/useCustomersByAuth'
import { apiClient } from 'v2/services/clients/apiClient'
import { OperationStatusEnum } from 'interfaces/operation'
import { ApiOperations } from 'v2/services/api/lib/api.operations'
import { createOperationRequestData } from 'v2/services/app/factories/request/createOperationRequestData'
import { Forms } from 'v2/components/modules/operation/DocumentSetModal'
import moment from 'moment'

const EditOperationPage: React.FC = ({ children }) => {
  const toast = useToast()
  const { id } = useParams<any>()
  const { user, isDascam } = useGlobals()
  const { data: customers } = useCustomersByAuth()
  const { data: currencies } = useCurrencyQuery()
  const [operationInfos, setOperationInfos] = useState<OperationInfosValue>(
    {} as OperationInfosValue
  )
  const [operationData, setOperation] = useState<ApiOperations.OperationProps>()
  const [documents, setDocuments] = useState<DocumentItemProps[]>([])
  const [isLoadingRefresh, loadRefresh] = useBoolean(true)
  const [isLoadingSave, loadSave] = useBoolean()
  const [isLoadingSend, loadSend] = useBoolean()
  const history = useHistory()

  const formsEnabled = useMemo<Forms[]>(() => {
    if (operationInfos.process?.type === 'IMPORTACAO') {
      //return ['duimp', 'di', 'dsi', 'li', 'fatura', 'conhecimento']
      return ['di', 'fatura', 'conhecimento', 'duimp']
    }

    if (operationInfos.process?.type === 'REMESSA') {
      return ['fatura', 'due']
    }

    if (operationInfos.process?.type === 'INGRESSO') {
      return ['fatura', 'op']
    }

    if (operationInfos.process?.type === 'EXPORTACAO') {
      return ['due', 'fatura', 'op']
    }

    return []
  }, [operationInfos.process])

  const onRefresh = async () => {
    loadRefresh.on()
    apiClient.operations
      .show(id)
      .then((operation) => {
        setOperation(() => operation)

        // @ts-ignore
        setOperationInfos(() => ({
          process: {
            number:
              Number(operation.process_number) === 0
                ? undefined
                : operation.process_number,
            value: operation.process_value,
            refCli: operation.refCli,
            expiration: operation.process_expiration,
            type: operation.product_type
          },
          customer: {
            id_syscambio: Number(operation.id_syscambio),
            fantasy_name: operation.client_name,
            document: operation.client_document
          },
          currency: {
            Codigo: Number(operation.currency_code),
            Nome: operation.currency_name,
            CodigoISO: operation.currency_iso
          },
          payerReceiver: {
            CodPagReg: Number(operation.beneficiary_code),
            NomeBeneficiario: operation.beneficiary_name,
            CodCli: operation.id_syscambio
          },
          beneficiatyBank: operation.beneficiary_info?.beneficiary,
          intermediaryBank: operation.beneficiary_info?.intermediary
        }))

        setDocuments(() => {
          const nextData = operation.documents?.map<DocumentItemProps>(
            (document) => ({
              balance: Number(document.balance_avaliable),
              document_number: document.document_number,
              document_type: document.document_type,
              value_applied: Number(document.applied_value),
              value_total: Number(document.total_value),
              files: [
                {
                  isBase64: false,
                  src: document.attached_file,
                  mimeType: document.attached,
                  name: document.attached_name
                }
              ],
              group_number: document.set_number,
              reference: document.document_reference,
              document_key: document.document_key,
              clearence_document: document.clearence_document,
              protocol: document.protocol
            })
          )

          return nextData || []
        })
      })
      .finally(() => {
        loadRefresh.off()
      })
  }

  // Método para enviar o processo com o status de  Rascunho (Cliente > Cliente)
  const onSubmit = async () => {
    loadSave.on()
    const date = new Date()

    await apiClient.operations
      .send(
        createOperationRequestData({
          id: Number(id),
          operationInfos,
          documents,
          status: OperationStatusEnum.PENDING,
          userId: id === undefined && id === null ? user.id : undefined,
          created_by_id: id === undefined && id === null ? user.id : undefined,
          updated_by_id: id !== undefined && id !== null ? user.id : undefined,
          updated_date:
            id !== undefined && id !== null ? date.toISOString() : undefined
        })
      )
      .then(() => {
        onRefresh()
      })
      .finally(() => {
        loadSave.off()
      })
  }

  // Método para enviar o processo com o status de Analise (Cliente > Dascam)
  const onSubmitAnalytics = async () => {
    try {
      if (!documents || documents.length === 0) {
        toast({
          title: 'Documentos',
          description: 'É necessario ter documentos no processo!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        return null
      }

      if (
        !operationInfos.payerReceiver?.CodPagReg ||
        !operationInfos.currency ||
        !operationInfos.customer ||
        !operationInfos.process.expiration ||
        !operationInfos.process.type ||
        !operationInfos.process.value
      ) {
        toast({
          title: 'Campos Obrigatórios',
          description: 'É necessario preencher todos os campos com *!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        loadSave.off()
        return null
      }

      loadSend.on()

      const valueProcess = operationInfos.process.value
      let valueInvoice = 0

      documents.forEach((e) => {
        if (e.document_type === 'FATURA') {
          valueInvoice = Number((valueInvoice + e.value_applied).toFixed(2))
        }
      })

      if (Number(valueProcess) !== Number(valueInvoice)) {
        toast({
          title: 'Documentos',
          description:
            'É necessario que o valor de faturas bata com o valor do processo!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        return null
      }

      await apiClient.operations
        .send(
          createOperationRequestData({
            id: Number(id),
            operationInfos,
            documents,
            status: OperationStatusEnum.SENT,
            userId: id === undefined && id === null ? user.id : undefined,
            created_by_id:
              id === undefined && id === null ? user.id : undefined,
            updated_by_id:
              id !== undefined && id !== null ? user.id : undefined,
            updated_date:
              id !== undefined && id !== null
                ? new Date().toISOString()
                : undefined,
            observation: {
              process_id: Number(id),
              text_content: `${operationInfos.customer.corporate_name} enviou o processo para análise`,
              created_by_id: Number(user.id),
              created_date: moment().format('yyyy-MM-DD'),
              creator_name: user.user_name!,
              status: OperationStatusEnum.SENT
            }
          })
        )
        .then(() => {
          onRefresh()
        })
    } catch (error) {
    } finally {
      loadSend.off()
    }
  }

  useEffect(() => {
    onRefresh()
  }, [id])

  useEffect(() => {
    if (operationData?.status) {
      if (
        [OperationStatusEnum.APPROVAL, OperationStatusEnum.SENT].includes(
          operationData.status as any
        )
      ) {
        history.push(`/operations/show/${id}`)
      }
    }
  }, [operationData?.status])

  if (!customers || !currencies || isLoadingRefresh)
    return (
      <CardPage
        title={'Carregando...'}
        _headerRight={
          <HStack>
            <Link to={`/operations`}>
              <Button
                variant={'ghost'}
                color={'terciary.400'}
                colorScheme={'terciary_hover'}
              >
                Voltar
              </Button>
            </Link>
          </HStack>
        }
      >
        <Progress isIndeterminate colorScheme={'secondary'} h={1} />
      </CardPage>
    )

  return (
    <CardPage
      title={'Editar Processo'}
      _headerBottom={
        <Text m={2} fontSize={'sm'}>
          {OperationStatusEnum.format(
            operationData?.status as OperationStatusEnum
          )}
        </Text>
      }
      _headerRight={
        <HStack my={2} fontSize={'xs'}>
          <Link to={`/operations`}>
            <Button
              variant={'ghost'}
              color={'terciary.400'}
              colorScheme={'terciary_hover'}
            >
              Voltar
            </Button>
          </Link>
          <Button
            onClick={onSubmit}
            variant={'outline'}
            colorScheme={'green'}
            isLoading={isLoadingSave}
          >
            Salvar Alterações
          </Button>
          {!isDascam && (
            <Button
              onClick={onSubmitAnalytics}
              colorScheme={'primary'}
              isLoading={isLoadingSend}
            >
              Salvar e Enviar para Análise
            </Button>
          )}
        </HStack>
      }
    >
      <Col px={4}>
        <OperationInfos
          currencies={currencies}
          customers={customers}
          value={operationInfos}
          onChange={setOperationInfos}
        />
      </Col>
      <Col px={4} my={2}>
        {formsEnabled.length > 0 && operationInfos.customer?.id_syscambio && (
          <OperationDocuments
            documents={documents}
            onChange={setDocuments}
            formsEnabled={formsEnabled}
            idSyscambio={`${operationInfos.customer.id_syscambio}`}
            operation={operationInfos}
          />
        )}
      </Col>
    </CardPage>
  )
}

export default EditOperationPage
