import styled from "styled-components";
import GenericButton from "components/generic/GenericButton";
import { Fragment } from "react";
import { ReactComponent as FileIcon } from "assets/fileIcon.svg";
import { ReactComponent as EditDataIcon } from "assets/editDataIcon.svg";
import { ReactComponent as TrashIcon } from "assets/trashIcon.svg";
import { valueBothConvert } from "helpers/moedas";
import { OperationDocumentModel } from "interfaces/operation_document";
import { formatDate } from "helpers/formats";
import { setGlobalMessage } from "redux/actions";
import { useDispatch } from "react-redux";

const ColumnText = styled.div<{ background?: boolean }>`
  border-right: 1px solid ${props => props.theme.color.white5};
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.white5};
  padding: 0 0.5rem;
  background: ${props =>
    props.background ? props.theme.color.white1 : props.theme.color.white2};
`;

const Text = styled.p<{ Color?: string; fontWeight?: string }>`
  font-size: ${props => props.theme.font.small};
  color: ${props => (props.Color ? props.Color : props.theme.color.text3)};
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;
`;

const DivButton = styled.div<{ borderRadius?: boolean; background?: boolean }>`
  border-right: 1px solid ${props => props.theme.color.white5};
  background: ${props =>
    props.background
      ? props.theme.color.primary_main
      : props.theme.color.primary_light};
  border-bottom: 1px solid ${props => props.theme.color.white5};
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: ${props => (props.borderRadius ? "0 0 10px 0" : "")};
`;

const ButtonEdit = styled(GenericButton)`
  width: 30px;
  height: 30px;
  padding: 0.3rem;
  background: ${props => props.theme.color.secondary_main};
  justify-content: center;
  align-items: center;
  transform: scale(0.95);
  margin: 0 0.1rem;

  &:hover {
    background: ${props => props.theme.color.secondary_light};
    transform: scale(1);
  }

  & svg {
    width: 100%;
    height: 100%;
    /* fill: ${props => props.theme.color.primary_main}; */
  }
`;

interface Props {
  last: boolean;
  index: number;
  value: OperationDocumentModel;
  handleUnItem: (
    uuid: string,
    set_number: number,
    set_id: number,
    id?: number,
    fn?: () => void
  ) => void;
  handleUnItemHere: (index: number, whats: string) => void;
  unItemHere: (index: number, whats: string) => void;
  setGameValue: (value: OperationDocumentModel) => void;
  setIsEdit: (value: boolean) => void;
  isEditOperation: boolean;
}

const Game = ({
  last,
  index,
  value,
  handleUnItem,
  setGameValue,
  setIsEdit,
  isEditOperation,
  handleUnItemHere,
  unItemHere,
}: Props): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const dispatch = useDispatch();

  const base64toBlob = (base64Data: string, type: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data.replace(`data:${type};base64,`, ""));
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {
      type: type,
    });
  };

  const openFile = (data: string, type: string) => {
    let blob = base64toBlob(data, type);
    if (
      (window.navigator as any) &&
      (window.navigator as any).msSaveOrOpenBlob
    ) {
      (window.navigator as any).msSaveOrOpenBlob(blob, `${Date.now()}`);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  };

  return (
    <Fragment key={value.id}>
      <ColumnText background={isEven(index)}>
        <Text title={value.document_type}>{value.document_type}</Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={value.document_number}>{value.document_number}</Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={valueBothConvert(String(value.applied_value), "ANY")}>
          {valueBothConvert(String(value.applied_value), "ANY")}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={valueBothConvert(String(value.total_value), "ANY")}>
          {valueBothConvert(String(value.total_value), "ANY")}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={valueBothConvert(String(value.balance_avaliable), "ANY")}>
          {valueBothConvert(String(value.balance_avaliable), "ANY")}
        </Text>
      </ColumnText>
      <DivButton background={isEven(index)}>
        <ButtonEdit
          type="button"
          onClick={() => {
            if (
              value.attached_file?.includes(`https`) &&
              value.attached_file !== undefined &&
              value.attached_file !== null &&
              value.attached_file !== ""
            ) {
              window.open(String(value.attached_file), "_blank")!.focus();
            } else if (
              value.attached_file !== undefined &&
              value.attached_file !== null &&
              value.attached_file !== ""
            ) {
              openFile(String(value.attached_file), String(value.attached));
            } else {
              dispatch(
                setGlobalMessage({
                  message: "Não há anexo!",
                  type: "WARN",
                })
              );
            }
          }}
        >
          <FileIcon />
        </ButtonEdit>
      </DivButton>
      <DivButton borderRadius={last} background={isEven(index)}>
        <ButtonEdit
          type="button"
          onClick={() => {
            setGameValue(value);
            setIsEdit(true);
          }}
        >
          <EditDataIcon />
        </ButtonEdit>
        <ButtonEdit
          type="button"
          onClick={() => {
            if (value.id !== undefined) {
              if (isEditOperation === true) {
                handleUnItem(
                  String(value.uu_id),
                  Number(value.set_number),
                  Number(value.set_id),
                  Number(value.id),
                  () => unItemHere(Number(index), "Game")
                );
              }
            } else {
              handleUnItemHere(Number(index), "Game");
            }
          }}
        >
          <TrashIcon />
        </ButtonEdit>
      </DivButton>
    </Fragment>
  );
};

const MoneyOrder = ({
  last,
  index,
  value,
  handleUnItem,
  setGameValue,
  setIsEdit,
  isEditOperation,
  handleUnItemHere,
  unItemHere,
}: Props): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const dispatch = useDispatch();

  const base64toBlob = (base64Data: string, type: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data.replace(`data:${type};base64,`, ""));
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {
      type: type,
    });
  };

  const openFile = (data: string, type: string) => {
    let blob = base64toBlob(data, type);
    if (
      (window.navigator as any) &&
      (window.navigator as any).msSaveOrOpenBlob
    ) {
      (window.navigator as any).msSaveOrOpenBlob(blob, `${Date.now()}`);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  };

  return (
    <Fragment key={value.id}>
      <ColumnText background={isEven(index)}>
        <Text title={value.document_number}>{value.document_number}</Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={valueBothConvert(String(value.document_value), "ANY")}>
          {valueBothConvert(String(value.document_value), "ANY")}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={valueBothConvert(String(value.applied_value), "ANY")}>
          {valueBothConvert(String(value.applied_value), "ANY")}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={valueBothConvert(String(value.total_value), "ANY")}>
          {valueBothConvert(String(value.total_value), "ANY")}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={valueBothConvert(String(value.balance_avaliable), "ANY")}>
          {valueBothConvert(String(value.balance_avaliable), "ANY")}
        </Text>
      </ColumnText>
      <DivButton background={isEven(index)}>
        <ButtonEdit
          type="button"
          onClick={() => {
            if (
              value.attached_file?.includes(`https`) &&
              value.attached_file !== undefined &&
              value.attached_file !== null &&
              value.attached_file !== ""
            ) {
              window.open(String(value.attached_file), "_blank")!.focus();
            } else if (
              value.attached_file !== undefined &&
              value.attached_file !== null &&
              value.attached_file !== ""
            ) {
              openFile(String(value.attached_file), String(value.attached));
            } else {
              dispatch(
                setGlobalMessage({
                  message: "Não há anexo!",
                  type: "WARN",
                })
              );
            }
          }}
        >
          <FileIcon />
        </ButtonEdit>
      </DivButton>
      <DivButton borderRadius={last} background={isEven(index)}>
        <ButtonEdit
          type="button"
          onClick={() => {
            setGameValue(value);
            setIsEdit(true);
          }}
        >
          <EditDataIcon />
        </ButtonEdit>
        <ButtonEdit
          type="button"
          onClick={() => {
            if (value.id !== undefined) {
              if (isEditOperation === true) {
                handleUnItem(
                  String(value.uu_id),
                  Number(value.set_number),
                  Number(value.set_id),
                  Number(value.id),
                  () => unItemHere(Number(index), "Order")
                );
              }
            } else {
              handleUnItemHere(Number(index), "Order");
            }
          }}
        >
          <TrashIcon />
        </ButtonEdit>
      </DivButton>
    </Fragment>
  );
};

const Invoice = ({
  last,
  index,
  value,
  handleUnItem,
  setGameValue,
  setIsEdit,
  isEditOperation,
  handleUnItemHere,
  unItemHere,
}: Props): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const dispatch = useDispatch();

  const base64toBlob = (base64Data: string, type: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data.replace(`data:${type};base64,`, ""));
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {
      type: type,
    });
  };

  const openFile = (data: string, type: string) => {
    let blob = base64toBlob(data, type);
    if (
      (window.navigator as any) &&
      (window.navigator as any).msSaveOrOpenBlob
    ) {
      (window.navigator as any).msSaveOrOpenBlob(blob, `${Date.now()}`);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  };

  return (
    <Fragment key={value.id}>
      <ColumnText background={isEven(index)}>
        <Text title={value.document_number}>{value.document_number}</Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={valueBothConvert(String(value.applied_value), "ANY")}>
          {valueBothConvert(String(value.applied_value), "ANY")}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={valueBothConvert(String(value.balance_avaliable), "ANY")}>
          {valueBothConvert(String(value.balance_avaliable), "ANY")}
        </Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text title={formatDate(value.expiration_date!)}>
          {formatDate(value.expiration_date!)}
        </Text>
      </ColumnText>
      <DivButton background={isEven(index)}>
        <ButtonEdit
          type="button"
          onClick={() => {
            if (
              value.attached_file?.includes(`https`) &&
              value.attached_file !== undefined &&
              value.attached_file !== null &&
              value.attached_file !== ""
            ) {
              window.open(String(value.attached_file), "_blank")!.focus();
            } else if (
              value.attached_file !== undefined &&
              value.attached_file !== null &&
              value.attached_file !== ""
            ) {
              openFile(String(value.attached_file), String(value.attached));
            } else {
              dispatch(
                setGlobalMessage({
                  message: "Não há anexo!",
                  type: "WARN",
                })
              );
            }
          }}
        >
          <FileIcon />
        </ButtonEdit>
      </DivButton>
      <DivButton borderRadius={last} background={isEven(index)}>
        <ButtonEdit
          type="button"
          onClick={() => {
            setGameValue(value);
            setIsEdit(true);
          }}
        >
          <EditDataIcon />
        </ButtonEdit>
        <ButtonEdit
          type="button"
          onClick={() => {
            if (value.id !== undefined) {
              if (isEditOperation === true) {
                handleUnItem(
                  String(value.uu_id),
                  Number(value.set_number),
                  Number(value.set_id),
                  Number(value.id),
                  () => unItemHere(Number(index), "Invoice")
                );
              }
            } else {
              handleUnItemHere(Number(index), "Invoice");
            }
          }}
        >
          <TrashIcon />
        </ButtonEdit>
      </DivButton>
    </Fragment>
  );
};

const Knowledge = ({
  last,
  index,
  value,
  handleUnItem,
  setGameValue,
  setIsEdit,
  isEditOperation,
  handleUnItemHere,
  unItemHere,
}: Props): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const dispatch = useDispatch();

  const base64toBlob = (base64Data: string, type: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data.replace(`data:${type};base64,`, ""));
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {
      type: type,
    });
  };

  const openFile = (data: string, type: string) => {
    let blob = base64toBlob(data, type);
    if (
      (window.navigator as any) &&
      (window.navigator as any).msSaveOrOpenBlob
    ) {
      (window.navigator as any).msSaveOrOpenBlob(blob, `${Date.now()}`);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  };

  return (
    <Fragment key={value.id}>
      <ColumnText background={isEven(index)}>
        <Text title={value.document_number}>{value.document_number}</Text>
      </ColumnText>
      <ColumnText background={isEven(index)}>
        <Text
          title={
            value.departure_date !== "" && value.departure_date !== undefined
              ? formatDate(value.departure_date!)
              : value.departure_date
          }
        >
          {value.departure_date !== "" && value.departure_date !== undefined
            ? formatDate(value.departure_date!)
            : value.departure_date}
        </Text>
      </ColumnText>
      <DivButton background={isEven(index)}>
        <ButtonEdit
          type="button"
          onClick={() => {
            if (
              value.attached_file?.includes(`https`) &&
              value.attached_file !== undefined &&
              value.attached_file !== null &&
              value.attached_file !== ""
            ) {
              window.open(String(value.attached_file), "_blank")!.focus();
            } else if (
              value.attached_file !== undefined &&
              value.attached_file !== null &&
              value.attached_file !== ""
            ) {
              openFile(String(value.attached_file), String(value.attached));
            } else {
              dispatch(
                setGlobalMessage({
                  message: "Não há anexo!",
                  type: "WARN",
                })
              );
            }
          }}
        >
          <FileIcon />
        </ButtonEdit>
      </DivButton>
      <DivButton borderRadius={last} background={isEven(index)}>
        <ButtonEdit
          type="button"
          onClick={() => {
            setGameValue(value);
            setIsEdit(true);
          }}
        >
          <EditDataIcon />
        </ButtonEdit>
        <ButtonEdit
          type="button"
          onClick={() => {
            if (value.id !== undefined) {
              if (isEditOperation === true) {
                handleUnItem(
                  String(value.uu_id),
                  Number(value.set_number),
                  Number(value.set_id),
                  Number(value.id),
                  () => unItemHere(Number(index), "Know")
                );
              }
            } else {
              handleUnItemHere(Number(index), "Know");
            }
          }}
        >
          <TrashIcon />
        </ButtonEdit>
      </DivButton>
    </Fragment>
  );
};

export { Game, MoneyOrder, Invoice, Knowledge };
