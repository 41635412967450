import styled from "styled-components";
import { ReactComponent as LupaIcon } from "assets/lupaIcon.svg";
import {
  InputBSB,
  InputName,
  InputSORTCODE,
  InputText,
} from "components/generic/GenericInputs";
import FindPayerReceiver from "./FindPayerReceiver";
import { useState } from "react";
import { OperationModel } from "interfaces/operation";
import FindCountry from "./FindCountry";
import { BiSearchAlt2 } from "react-icons/bi";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  padding-bottom: 5rem;
  overflow-y: auto;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
  padding-top: 0.5rem;
`;

const BottomLine = styled.div`
  width: 100%;
  padding-top: 1rem;
  border-bottom: ${(props) => props.theme.color.primary_main} 1px solid;
`;

const ButtonYellow = styled.button<{ marginBottom?: string }>`
  width: 40px;
  height: 40px;
  padding: 0.3rem;
  background-color: ${(props) => props.theme.color.secondary_main};
  border-radius: 6px;
  outline: none;
  border: none;
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  cursor: pointer;
  transform: scale(0.95);
  margin-bottom: ${(props) => props.marginBottom};

  & svg {
    width: auto;
    height: 100%;
    & path {
      fill: ${(props) => props.theme.color.primary_main};
    }
  }

  &:hover {
    transform: scale(1);
  }
`;

const Title = styled.h4`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray2};
  align-self: flex-start;
  padding: 1rem;
`;

interface Props {
  typeOperation: string;
  changeRender: (value: string) => void;
  data: OperationModel;
  changeData: (value: OperationModel) => void;
  isReadOperation: boolean;
  setIsReadOperation: (value: boolean) => void;
}

const PayerReceiver = ({
  typeOperation,
  changeRender,
  data,
  changeData,
  isReadOperation,
  setIsReadOperation,
}: Props): JSX.Element => {
  const [showPayerReceiverModal, setShowPayerReceiverModal] =
    useState<boolean>(false);
  const [showCountryModal, setShowCountryModal] = useState<boolean>(false);
  const [isPayer, setIsPayer] = useState<boolean>(false);
  const [isIntermediary, setIsIntermediary] = useState<boolean>(false);
  return (
    <>
      <Container>
        <ItemRow>
          <InputText
            label="Código"
            maxWidth="150px"
            maxLength={8}
            value={data.beneficiary_code}
            changeValue={(value) =>
              changeData({
                ...data,
                beneficiary_code: value,
              })
            }
            required
            readOnly
          />

          <InputText
            label="Nome"
            maxWidth="450px"
            maxLength={120}
            value={data.beneficiary_name}
            changeValue={(value) =>
              changeData({
                ...data,
                beneficiary_name: value,
              })
            }
            required
            readOnly
          />
          <InputText
            label="Indentificação"
            maxWidth="200px"
            maxLength={120}
            value={data.beneficiary_identification}
            changeValue={(value) =>
              changeData({
                ...data,
                beneficiary_identification: value,
              })
            }
            required
            readOnly
          />
          {isReadOperation !== true ? (
            <ButtonYellow
              type="button"
              marginBottom=".3rem"
              onClick={() => setShowPayerReceiverModal(!showPayerReceiverModal)}
            >
              <BiSearchAlt2 />
            </ButtonYellow>
          ) : null}
        </ItemRow>
        <ItemRow>
          {/* <InputText
            label="Cód. do País"
            maxWidth="150px"
            value={data.country_code}
            changeValue={value =>
              changeData({
                ...data,
                country_code: value,
              })
            }
            required
            readOnly
          /> */}
          <InputName
            label="Sigla"
            maxWidth="80px"
            maxLength={3}
            value={data.country_initials}
            changeValue={(value) =>
              changeData({
                ...data,
                country_initials: value,
              })
            }
            required
            readOnly
          />
          <InputText
            label="País"
            maxWidth="250px"
            maxLength={120}
            value={data.country_name}
            changeValue={(value) =>
              changeData({
                ...data,
                country_name: value,
              })
            }
            required
            readOnly
          />
          {isReadOperation !== true ? (
            <ButtonYellow
              type="button"
              marginBottom=".3rem"
              onClick={() => {
                setIsPayer(true);
                setIsIntermediary(false);
                setShowCountryModal(!showCountryModal);
              }}
            >
              <BiSearchAlt2 />
            </ButtonYellow>
          ) : null}
          <InputText
            label="Cidade"
            maxWidth="225px"
            maxLength={120}
            value={data.beneficiary_city}
            changeValue={(value) =>
              changeData({
                ...data,
                beneficiary_city: value,
              })
            }
            required
            readOnly={isReadOperation}
          />
          <InputText
            label="Endereço"
            maxWidth="250px"
            maxLength={120}
            value={data.beneficiary_address}
            changeValue={(value) =>
              changeData({
                ...data,
                beneficiary_address: value,
              })
            }
            required
            readOnly={isReadOperation}
          />
        </ItemRow>
        <ItemRow>
          <InputText
            label="NIF"
            maxWidth="200px"
            maxLength={120}
            value={data.beneficiary_nif}
            changeValue={(value) =>
              changeData({
                ...data,
                beneficiary_nif: value,
              })
            }
            required
            readOnly={isReadOperation}
          />
        </ItemRow>
        <BottomLine />
        <ItemRow>
          <Title>Dados Bancários</Title>
        </ItemRow>
        <ItemRow>
          <InputText
            label="Banco"
            maxWidth="560px"
            maxLength={130}
            value={data.bank_info}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_info: value,
              })
            }
            required
            readOnly={isReadOperation}
          />
          {/* <ButtonYellow
            type="button"
            marginBottom=".3rem"
            onClick={() => setShowPayerReceiverModal(!showPayerReceiverModal)}
          >
            <LupaIcon />
          </ButtonYellow> */}
          <InputName
            label="Sigla"
            maxWidth="100px"
            maxLength={3}
            value={data.bank_country_initials}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_country_initials: value,
              })
            }
            required
            readOnly
          />
          <InputText
            label="País"
            maxWidth="250px"
            maxLength={120}
            value={data.bank_country}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_country: value,
              })
            }
            required
            readOnly
          />
          {isReadOperation !== true ? (
            <ButtonYellow
              type="button"
              marginBottom=".3rem"
              onClick={() => {
                setIsPayer(false);
                setIsIntermediary(false);
                setShowCountryModal(!showCountryModal);
              }}
            >
              <BiSearchAlt2 />
            </ButtonYellow>
          ) : null}
          <InputText
            label="Cidade"
            maxWidth="200px"
            maxLength={120}
            value={data.bank_city}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_city: value,
              })
            }
            required
            readOnly={isReadOperation}
          />
          <InputText
            label="Endereço"
            maxWidth="250px"
            maxLength={120}
            value={data.bank_address}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_address: value,
              })
            }
            required
            readOnly={isReadOperation}
          />
        </ItemRow>
        <ItemRow>
          <InputText
            label="SWIFT"
            maxWidth="280px"
            maxLength={11}
            value={data.bank_swift}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_swift: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="ABA"
            maxWidth="280px"
            maxLength={9}
            value={data.bank_aba}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_aba: value.replace(/[A-z]/gm, ""),
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="CHIPS"
            maxWidth="280px"
            maxLength={16}
            value={data.bank_chips}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_chips: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="Conta"
            maxWidth="280px"
            maxLength={40}
            value={data.bank_account}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_account: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="IBAN"
            maxWidth="280px"
            maxLength={29}
            value={data.bank_iban}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_iban: value,
              })
            }
            readOnly={isReadOperation}
          />
        </ItemRow>
        <ItemRow>
          <InputSORTCODE
            label="SORT CODE (Inglaterra) :"
            maxWidth="280px"
            maxLength={6}
            value={data.bank_sortcode}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_sortcode: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="BLZ (Alemanha) :"
            maxWidth="280px"
            maxLength={8}
            value={data.bank_blz}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_blz: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="BRANCH CODE (Africa do Sul) :"
            maxWidth="280px"
            maxLength={8}
            value={data.bank_branchcode}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_branchcode: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputBSB
            label="BSB (Austrália) :"
            maxWidth="280px"
            maxLength={6}
            value={data.bank_bsb}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_bsb: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="TRANSIT (Canadá):"
            maxWidth="280px"
            maxLength={5}
            value={data.bank_transit}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_transit: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="CNAPS (China) :"
            maxWidth="280px"
            maxLength={12}
            value={data.bank_cnaps}
            changeValue={(value) =>
              changeData({
                ...data,
                bank_cnaps: value,
              })
            }
            readOnly={isReadOperation}
          />
        </ItemRow>{" "}
        <ItemRow>
          <Title>Intermediário</Title>
        </ItemRow>
        <ItemRow>
          <InputText
            label="Nome"
            maxWidth="560px"
            maxLength={120}
            value={data.intermediary_info}
            changeValue={(value) =>
              changeData({
                ...data,
                intermediary_info: value,
              })
            }
            required
            readOnly={isReadOperation}
          />
          {/* <ButtonYellow
            type="button"
            marginBottom=".3rem"
            onClick={() => setShowPayerReceiverModal(!showPayerReceiverModal)}
          >
            <LupaIcon />
          </ButtonYellow> */}
          <InputName
            label="Sigla"
            maxWidth="100px"
            maxLength={3}
            value={data.intermediary_country_initials}
            changeValue={(value) =>
              changeData({
                ...data,
                intermediary_country_initials: value,
              })
            }
            required
            readOnly
          />
          <InputText
            label="País"
            maxWidth="250px"
            maxLength={120}
            value={data.intermediary_country}
            changeValue={(value) =>
              changeData({
                ...data,
                intermediary_country: value,
              })
            }
            required
            readOnly
          />
          {isReadOperation !== true ? (
            <ButtonYellow
              type="button"
              marginBottom=".3rem"
              onClick={() => {
                setIsPayer(false);
                setIsIntermediary(true);
                setShowCountryModal(!showCountryModal);
              }}
            >
              <BiSearchAlt2 />
            </ButtonYellow>
          ) : null}
          <InputText
            label="Cidade"
            maxWidth="200px"
            maxLength={120}
            value={data.intermediary_city}
            changeValue={(value) =>
              changeData({
                ...data,
                intermediary_city: value,
              })
            }
            required
            readOnly={isReadOperation}
          />
          <InputText
            label="Endereço"
            maxWidth="250px"
            maxLength={120}
            value={data.intermediary_address}
            changeValue={(value) =>
              changeData({
                ...data,
                intermediary_address: value,
              })
            }
            required
            readOnly={isReadOperation}
          />
        </ItemRow>
        <ItemRow>
          <InputText
            label="SWIFT"
            maxWidth="280px"
            maxLength={11}
            value={data.intermediary_swift}
            changeValue={(value) =>
              changeData({
                ...data,
                intermediary_swift: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="ABA"
            maxWidth="280px"
            maxLength={9}
            value={data.intermediary_aba}
            changeValue={(value) =>
              changeData({
                ...data,
                intermediary_aba: value.replace(/[A-z]/gm, ""),
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="CHIPS"
            maxWidth="280px"
            maxLength={16}
            value={data.intermediary_chips}
            changeValue={(value) =>
              changeData({
                ...data,
                intermediary_chips: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="Conta"
            maxWidth="280px"
            maxLength={40}
            value={data.intermediary_account}
            changeValue={(value) =>
              changeData({
                ...data,
                intermediary_account: value,
              })
            }
            readOnly={isReadOperation}
          />
          <InputText
            label="IBAN"
            maxWidth="280px"
            maxLength={29}
            value={data.intermediary_iban}
            changeValue={(value) =>
              changeData({
                ...data,
                intermediary_iban: value,
              })
            }
            readOnly={isReadOperation}
          />
        </ItemRow>
      </Container>
      <FindPayerReceiver
        showModal={showPayerReceiverModal}
        handleModal={() => setShowPayerReceiverModal(!showPayerReceiverModal)}
        data={data}
        changeData={changeData}
      />
      <FindCountry
        showModal={showCountryModal}
        handleModal={() => setShowCountryModal(!showCountryModal)}
        data={data}
        changeData={changeData}
        isPayer={isPayer}
        isIntermediary={isIntermediary}
      />
    </>
  );
};

export default PayerReceiver;
