import { useState } from "react";

export function useDisclosure() {
  const [isOpen, setOpen] = useState(false);
  const onOpen = () => {
    setOpen(() => true);
  };

  const onClose = () => {
    setOpen(() => false);
  };

  const onToggle = () => {
    setOpen(old => !old);
  };

  return {
    isOpen,
    onClose,
    onOpen,
    onToggle,
  };
}
