import { axiosWebsite } from 'helpers/axiosInstances'
import { LegalEntityModel } from 'interfaces/legalNature'
import React, { useEffect, useMemo, useState } from 'react'
import { InputSelect } from '../GenericInputs'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

interface Value {
  entityId: number
  legalNatureId: string
}

interface UserPickEntityLegalNatureProps {
  onChange?: (data: Value) => void
  value: Partial<Value>
}

const UserPickEntityLegalNature: React.FC<UserPickEntityLegalNatureProps> = ({
  value,
  onChange: onChangeParam
}) => {
  const [valueState, setValue] = useState(value)
  const [entities, setEntities] = useState<LegalEntityModel[]>([])

  const natures = useMemo(() => {
    if (!value.entityId) return []

    const entity = entities.find((e) => e.Codigo === value.entityId)

    if (!entity) return []

    return entity.Naturezas
  }, [value.entityId])

  const onChange = (nextValue: Partial<Value>) => {
    setValue((oldValue) => {
      return { ...oldValue, ...nextValue }
    })

    onChangeParam?.({ ...valueState, ...nextValue } as any)
  }

  const load = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    axiosWebsite
      .get('/entidades', config)
      .then((res) => res.data)
      .then(setEntities)
  }

  useEffect(() => {
    load()
  }, [])

  // useEffect(() => {
  //   setValue(value);
  // }, [value]);

  return (
    <>
      {
        <div>
          <InputSelect
            label={'Entidade *'}
            changeValue={(v) => {
              onChange({
                entityId: Number(v)
              })
            }}
            maxWidth={'150px'}
            value={value.entityId! as any}
          >
            <option>Selecione...</option>
            {entities.map((entity) => (
              <option key={`option${entity.Codigo}`} value={entity.Codigo}>
                {entity.Descricao}
              </option>
            ))}
          </InputSelect>
        </div>
      }
      {
        <div>
          <InputSelect
            label={'Natureza Jurídica *'}
            changeValue={(v) => {
              onChange({
                legalNatureId: v
              })
            }}
            maxWidth={'150px'}
            value={value.legalNatureId!}
            readOnly={natures.length <= 0}
          >
            <option>Selecione...</option>
            {natures.map((nature) => (
              <option key={`option${nature.Codigo}`} value={nature.Codigo}>
                {nature.Descricao}
              </option>
            ))}
          </InputSelect>
        </div>
      }
    </>
  )
}

export default UserPickEntityLegalNature
