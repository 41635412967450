import { useGlobals } from "hooks/helpers/useGlobals";
import { useQuery } from "react-query";
import { apiClient } from "v2/services/clients/apiClient";
import { makeQuerySmallCache } from "v2/services/factories/react-query";

export function useCustomersByAuth() {
  const { user } = useGlobals();
  const query = useQuery(
    ["consumers", user.id],
    () => {
      return apiClient.customers.getCustomersByUser(user.id!);
    },
    makeQuerySmallCache()
  );

  return query;
}
