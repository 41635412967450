import styled from "styled-components";
import { ReactComponent as Shipping } from "assets/shipping.svg";
import { ReactComponent as Ticket } from "assets/ticket.svg";
import { ReactComponent as Import } from "assets/import.svg";
import { ReactComponent as Export } from "assets/export.svg";

const Container = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h4`
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  text-transform: uppercase;
  color: ${props => props.theme.color.gray2};
  margin-bottom: 30px;
`;

const DivButtons = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: auto;
`;

const Button = styled.div`
  width: 280px;
  height: 280px;
  background-color: rgba(255, 255, 255, 0.97);
  box-shadow: 0px 4px 16px rgba(49, 49, 49, 0.39);
  border-radius: 19px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scale(0.97);
  cursor: pointer;

  &:hover {
    transform: scale(1);
    box-shadow: 0px 4px 16px rgba(6, 71, 127, 0.97);
  }

  & svg {
    width: 130px;
    height: 130px;
  }

  & p {
    margin-top: 20px;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: ${props => props.theme.color.gray2};
  }
`;

interface Props {
  typeOperation: string;
  changeRender: (value: string) => void;
}

const TypePreClosing = ({
  typeOperation,
  changeRender,
}: Props): JSX.Element => {
  return (
    <Container>
      <Title>Novo contrato de operação</Title>
      <DivButtons>
        <Button onClick={() => changeRender("Shipping")}>
          <Shipping />
          <p>Remessa</p>
        </Button>
        <Button onClick={() => changeRender("Ticket")}>
          <Ticket />
          <p>Ingresso</p>
        </Button>
        <Button onClick={() => changeRender("Import")}>
          <Import />
          <p>Importação</p>
        </Button>
        <Button onClick={() => changeRender("Export")}>
          <Export />
          <p>Exportação</p>
        </Button>
      </DivButtons>
    </Container>
  );
};

export default TypePreClosing;
