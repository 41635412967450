import AttachFile from "components/generic/AttachFileToBase64";
import { ArchiveModel } from "interfaces/user-archives";
import styled from "styled-components";

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`;
interface Props {
  userDocumentation: ArchiveModel;
  changeDocumentation: (userDocumentation: ArchiveModel) => void;
}

const DocumentationLegal = ({
  userDocumentation,
  changeDocumentation,
}: Props): JSX.Element => {
  return (
    <>
      <DivItems>
        <AttachFile
          newId="attorney_letter"
          title="Procuração (via original ou cópia autenticada)"
          value={userDocumentation.attorney_letter!}
          typeFile={userDocumentation.attorney_letter_type!}
          changeValue={(value, name, type) => {
            // console.log("change", value, name, type);
            changeDocumentation({
              ...userDocumentation,
              attorney_letter: value,
              attorney_letter_type: type,
            });
          }}
        />
        <AttachFile
          newId="rg_cpf_doc"
          title="Cópia do RG e CPF dos representantes legais (autenticados) *"
          value={userDocumentation.rg_cpf_doc!}
          typeFile={userDocumentation.rg_cpf_doc_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              rg_cpf_doc: value,
              rg_cpf_doc_type: type,
            })
          }
        />
        <AttachFile
          newId="residence_proof"
          title="Cópia do comprovante de endereço dos representantes *"
          value={userDocumentation.residence_proof!}
          typeFile={userDocumentation.residence_proof_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              residence_proof: value,
              residence_proof_type: type,
            })
          }
        />
        <AttachFile
          newId="service_contract"
          title="Contrato de Prestação de Serviços (Entre cliente e corretora) "
          value={userDocumentation.service_contract!}
          typeFile={userDocumentation.service_contract_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              service_contract: value,
              service_contract_type: type,
            })
          }
        />
        <AttachFile
          newId="constitutive_act"
          title="Cópia (Autenticada) do ato constitutivo - Contrato Social, Estatuto e sua última alteração  *"
          value={userDocumentation.constitutive_act!}
          typeFile={userDocumentation.constitutive_act_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              constitutive_act: value,
              constitutive_act_type: type,
            })
          }
        />
        <AttachFile
          newId="board_election"
          title="Cópia da ata de eleição da atual diretoria e/ou gerência (autenticado)"
          value={userDocumentation.board_election!}
          typeFile={userDocumentation.board_election_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              board_election: value,
              board_election_type: type,
            })
          }
        />
        <AttachFile
          newId="business_residence"
          title="Cópia do comprovante de endereço da empresa (conta emitida por concessionária de serviço público) *"
          value={userDocumentation.business_residence!}
          typeFile={userDocumentation.business_residence_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              business_residence: value,
              business_residence_type: type,
            })
          }
        />
        <AttachFile
          newId="monthly_billing"
          title="Cópia do último balanço e faturamento médio mensal (autenticado e assinado pelo representante legal da empresa e contador) *"
          value={userDocumentation.monthly_billing!}
          typeFile={userDocumentation.monthly_billing_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              monthly_billing: value,
              monthly_billing_type: type,
            })
          }
        />
        <AttachFile
          newId="novoex_doc"
          title="Declaração novoex (Termo de autorização para consulta de registros  de exportação- se aplicável) (Firma Reconhecida)"
          value={userDocumentation.novoex_doc!}
          typeFile={userDocumentation.novoex_doc_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              novoex_doc: value,
              novoex_doc_type: type,
            })
          }
        />
        <AttachFile
          newId="identification_beneficiaries"
          title="Cópia Documentos Identificação do(s) Beneficiário(s) Final(is)"
          value={userDocumentation.identification_beneficiaries!}
          typeFile={userDocumentation.identification_beneficiaries_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              identification_beneficiaries: value,
              identification_beneficiaries_type: type,
            })
          }
        />
        <AttachFile
          newId="additional_documents"
          title="Documentos Adicionais"
          value={userDocumentation.additional_documents!}
          typeFile={userDocumentation.additional_documents_type!}
          changeValue={(value, name, type) =>
            changeDocumentation({
              ...userDocumentation,
              additional_documents: value,
              additional_documents_type: type,
            })
          }
        />
      </DivItems>
    </>
  );
};

export default DocumentationLegal;
