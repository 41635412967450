import Account from "components/generic/BankAccount";
import GenericButton from "components/generic/GenericButton";
import styled from "styled-components";
import {
  InputAccount,
  InputAgency,
  InputDate,
  InputSelectBank,
} from "components/generic/GenericInputs";
import { BankModel } from "interfaces/bank";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading,
} from "redux/actions";
import { axiosAuth } from "helpers/axiosInstances";
import { isUnauthorized } from "helpers/errors";
import { useHistory } from "react-router-dom";
import { UserModel } from "interfaces/user";

const TableGrid = styled.div`
  background-color: ${props => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: 200px 100px 150px 150px auto;
  grid-auto-rows: 35px;
  height: auto;
  width: auto;
  max-height: 300px;
  overflow: auto;
`;

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${props => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${props => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Text = styled.p<{
  Color?: string;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: boolean;
}>`
  font-size: ${props =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${props => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;
  text-align: ${props => (props.textAlign ? "center" : "none")};
`;

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`;

const TitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${props => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`;

const Button = styled(GenericButton)<{
  width?: string;
  isDisabled?: boolean;
  isDeny?: boolean;
}>`
  margin: 1rem 0;
  margin-right: 0.5rem;
  padding: 1rem;
  width: ${props => (props.width ? props.width : "260px")};
  color: ${props =>
    props.isDisabled
      ? props.theme.color.white1
      : props.isDeny
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${props =>
    props.isDisabled
      ? props.theme.color.gray3
      : props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};
  cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${props =>
      props.isDisabled
        ? props.theme.color.gray4
        : props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`;

interface Props {
  userId: number | undefined;
  accountBanks: BankModel[];
  getBankData: () => void;
  userData: UserModel;
  setAccountBanks: (value: BankModel[]) => void;
}

const BankAccountPhysical = ({
  userId,
  accountBanks,
  getBankData,
  userData,
  setAccountBanks,
}: Props): JSX.Element => {
  const [editUser, setEditUser] = useState<boolean>(false);
  const [bankData, setBankData] = useState<BankModel>({
    bank_code: "",
    bank_name: "",
    bank_agency: "",
    bank_account: "",
    opening_date: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const removeToEdit = (index: number) => {
    let res = [...accountBanks];
    res.splice(index, 1);
    setAccountBanks(res);
  };

  const handleSubmitBank = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );

      const body = {
        user_id: userId,
        bank_code: bankData.bank_code,
        bank_name: bankData.bank_name,
        bank_agency: bankData.bank_agency,
        bank_account: bankData.bank_account,
        opening_date: bankData.opening_date,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      if (editUser) {
        await axiosAuth.put(`/bank/${bankData.id}`, body, config);
      } else {
        await axiosAuth.post(`/bank`, body, config);
      }

      dispatch(setSuccessMessage("Conta cadastrada com sucesso!"));
      getBankData();
      clearBank();
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  const clearBank = () => {
    setBankData({
      ...bankData,
      bank_code: "",
      bank_name: "",
      bank_agency: "",
      bank_account: "",
      opening_date: "",
    });
    setEditUser(false);
  };

  useEffect(() => {
    getBankData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <DivItems>
        <TitleItems>Dados Bancários</TitleItems>
      </DivItems>
      <DivItems>
        <ItemRow>
          <InputSelectBank
            label="Banco"
            maxWidth="250px"
            value={bankData.bank_code}
            changeValue={value =>
              setBankData({
                ...bankData,
                bank_code: value,
              })
            }
            valueName={value =>
              setBankData({
                ...bankData,
                bank_name: value,
              })
            }
          />
          <InputAgency
            label="Agência"
            maxWidth="150px"
            value={bankData.bank_agency}
            changeValue={value =>
              setBankData({
                ...bankData,
                bank_agency: value,
              })
            }
          />
          <InputAccount
            label="Conta Corrente"
            maxWidth="200px"
            value={bankData.bank_account}
            changeValue={value =>
              setBankData({
                ...bankData,
                bank_account: value,
              })
            }
          />
          <InputDate
            label="Data de Abertura"
            maxWidth="200px"
            max={new Date().toISOString().split("T")[0]}
            value={bankData.opening_date}
            changeValue={value =>
              setBankData({
                ...bankData,
                opening_date: value,
              })
            }
          />
        </ItemRow>
        <ItemRow>
          {editUser ? (
            <Button
              isDeny
              type="button"
              width="180px"
              onClick={() => {
                getBankData();
                clearBank();
                setEditUser(!editUser);
              }}
            >
              Cancelar
            </Button>
          ) : null}
          <Button
            isDisabled={
              bankData.bank_code !== "" &&
              bankData.bank_name !== "" &&
              bankData.bank_agency !== "" &&
              bankData.bank_account !== "" &&
              bankData.opening_date !== ""
                ? false
                : true
            }
            type="button"
            width="180px"
            onClick={() =>
              bankData.bank_code !== "" &&
              bankData.bank_name !== "" &&
              bankData.bank_agency !== "" &&
              bankData.bank_account !== "" &&
              bankData.opening_date !== ""
                ? handleSubmitBank()
                : null
            }
          >
            {editUser ? "Alterar" : "Adicionar Conta +"}
          </Button>
        </ItemRow>
      </DivItems>
      <DivItems>
        <TableGrid>
          <GridTitle>
            <Text fontWeight="bold">Banco</Text>
          </GridTitle>
          <GridTitle>
            <Text fontWeight="bold">Agência</Text>
          </GridTitle>
          <GridTitle>
            <Text fontWeight="bold">Conta Corrente</Text>
          </GridTitle>
          <GridTitle>
            <Text fontWeight="bold">Data de abertura</Text>
          </GridTitle>
          <GridTitle>
            <Text fontWeight="bold">Ações</Text>
          </GridTitle>
          {/* <GridTitle>
            <Text fontWeight="bold">Status</Text>
          </GridTitle> */}
          {accountBanks.map((account, index) => {
            let last = false;
            if (index === accountBanks.length - 1) {
              last = true;
            }
            return (
              <Account
                last={last}
                index={index}
                account={account}
                getBankData={() => getBankData()}
                userData={userData}
                editUser={editUser}
                setEditUser={setEditUser}
                setBankData={setBankData}
                removeToEdit={removeToEdit}
              />
            );
          })}
        </TableGrid>
      </DivItems>
    </>
  );
};

export default BankAccountPhysical;
