import styled from "styled-components";
import Background from "assets/Imagem Principal.png";
import { devices } from "helpers/devices";
import GenericButton from "components/generic/GenericButton";
// import { ReactComponent as DownArrow } from "assets/DownArrow.svg";
import Scroll from "react-scroll";
import { Button } from "@chakra-ui/react";

const scroller = Scroll.scroller;

const HeroStyle = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: calc(100vh - 50px);
  min-height: 800px;
  width: 100vw;
  max-width: 100%;
`;

const Container = styled.div`
  background-color: transparent;
  background-image: url("${Background}");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;

  @media ${devices.phoneOnly} {
    background-position: center left;
  }
`;

const TemplateGrid = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 120px;
  padding: 0 ${props => props.theme.margins.from_sides};

  @media ${devices.phoneOnly} {
    padding: 0 ${props => props.theme.margins.from_sides_mobile};
    display: flex;
  }

  @media ${devices.tabletPortrait} {
    padding: 0 ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 1rem ${props => props.theme.margins.from_sides};
  }
`;

const GridTopPanel = styled.div`
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const GridBottomPanel = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const GreatCardPanel = styled.div`
  position: relative;
  width: 700px;
  height: 350px;
  border: solid 5px ${props => props.theme.color.primary_dark};
  border-radius: 35px;
  background-color: ${props => props.theme.color.white1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
`;

const MidCardPanel = styled.div`
  position: absolute;
  bottom: -60px;
  right: -60px;
  width: 280px;
  height: 140px;
  border: solid 5px ${props => props.theme.color.primary_dark};
  border-radius: 35px;
  background-color: ${props => props.theme.color.white1};
`;

const SmallCardPanel = styled.div`
  position: absolute;
  bottom: -80px;
  left: -60px;
  width: 170px;
  height: 120px;
  border: solid 5px ${props => props.theme.color.primary_dark};
  border-radius: 35px;
  background-color: ${props => props.theme.color.white1};
`;

const DivTitle = styled.div`
  width: 100%;
  height: auto;
  padding: 0.5rem 1rem;
`;

const Title = styled.p`
  font-size: 2rem;
  color: ${props => props.theme.color.gray2};
  font-weight: bold;
  text-transform: uppercase;
`;

const SpanBrown = styled.span`
  color: #aa6443;
`;

const DivSubTitle = styled.div`
  width: 100%;
  height: auto;
  padding: 0.5rem 1rem;
`;

const SubTitle = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.color.gray2};
  font-weight: bold;
`;

const DivText = styled.div`
  width: 100%;
  height: auto;
`;

const Text = styled.p`
  font-size: 1.2rem;
  color: ${props => props.theme.color.gray2};
  font-weight: bold;
  padding: 0.5rem 1rem;
`;

const DivButton = styled.div`
  width: 100%;
  height: auto;
  padding: 0.5rem 1rem;
  align-items: flex-end;
`;

// const Button = styled(GenericButton)`
//   cursor: pointer;
// `;

// const ButtonScroll = styled(GenericButton)`
//   background: transparent;
//   cursor: pointer;
//   color: ${props => props.theme.color.white1};
//   transform: scale(0.95);

//   & svg {
//     margin: 0 1rem;
//   }

//   &:hover {
//     background: transparent;
//     transform: scale(1);
//   }
// `;

const Hero = (): JSX.Element => {
  return (
    <HeroStyle id="2">
      <Container>
        <TemplateGrid>
          <GridTopPanel>
            <GreatCardPanel>
              <DivTitle>
                <Title>
                  Conheça o <SpanBrown>Dascam Direct</SpanBrown>
                </Title>
              </DivTitle>
              <DivSubTitle>
                <SubTitle>
                  Assessoria extremamente qualificada <br />
                  <SpanBrown>Venha descobrir mais sobre nós!</SpanBrown>
                </SubTitle>
              </DivSubTitle>
              <DivText>
                <Text>
                  O <SpanBrown>Dascam Direct</SpanBrown> é uma área criada para
                  você que é ou quer se tornar cliente Dascam, com essa
                  plataforma facilitaremos a forma de você fazer o câmbio do seu
                  dinheiro.
                </Text>
              </DivText>
              <DivButton>
                <Button
                  variant={"outline"}
                  color={"terciary.400"}
                  colorScheme={"terciary_hover"}
                  onClick={() => {
                    scroller.scrollTo("3", {
                      duration: 1500,
                      delay: 100,
                      smooth: true,
                      offset: -80,
                    });
                  }}
                >
                  Saiba mais sobre o DASCAM Direct
                </Button>
              </DivButton>
            </GreatCardPanel>
          </GridTopPanel>
          <GridBottomPanel></GridBottomPanel>
        </TemplateGrid>
      </Container>
    </HeroStyle>
  );
};

export default Hero;
