import { axiosEmail } from "helpers/axiosInstances";

interface SendNotificationProps {
  process: string;
  action: string;
  client: string;
  emails: string[];
}

const sendNotificationMail = async ({
  emails,
  client,
  action,
  process,
}: SendNotificationProps) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  const res = await axiosEmail
    .post(`/notification`, {
      emails,
      client,
      action,
      process,
    }, config);
  const message = res.data;
  return console.log(message);
};

export { sendNotificationMail };
