import styled from 'styled-components'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import GenericButton from 'components/generic/GenericButton'
//import imgs
import BgAreas from 'assets/fundoCadastro.png'
import LogoDascam from 'assets/Logo 2.png'
import { useDispatch } from 'react-redux'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import { axiosEmail, axiosLogin } from 'helpers/axiosInstances'
import jsrsasign from 'jsrsasign'
import { devices } from 'helpers/devices'
import {
  InputEmail,
  InputLogin,
  InputPassword,
  InputText
} from 'components/generic/GenericInputs'
import { isNoContent, isUnauthorized } from 'helpers/errors'
import { Button } from '@chakra-ui/react'
import AppContext from 'hooks/helpers/useLastFilter'

const Section = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledLogin = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 ${(props) => props.theme.margins.from_sides};
  background-color: ${(props) => props.theme.color.white1};
  background: url('${BgAreas}');
  background-position: center left;
  background-size: cover;
  background-repeat: no-repeat;
`

const AlignRight = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 1rem ${(props) => props.theme.margins.from_sides};
  padding-top: 3rem;
`

const CenterItems = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  height: auto;
  width: 400px;
  margin: 1rem;
  cursor: pointer;

  @media ${devices.desktop} {
    width: 350px;
  }

  @media ${devices.bigDesktop} {
    width: 400px;
  }
`

const Container = styled.div<{ fill?: boolean; Bfill?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
`

const InputGroup = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

// const Button = styled(GenericButton)<{ isDeny?: boolean }>`
//   margin: 0.2rem;
//   padding: 0.8rem;
//   width: 250px;
//   background: ${props =>
//     props.isDeny
//       ? props.theme.color.button_deny
//       : props.theme.color.secondary_main};
//   color: ${props =>
//     props.isDeny ? props.theme.color.white1 : props.theme.color.primary_main};

//   &:hover {
//     background: ${props =>
//       props.isDeny
//         ? props.theme.color.button_deny_hover
//         : props.theme.color.secondary_light};
//   }
// `;

// const ButtonUnderline = styled.button`
//   padding: 0.3rem;
//   font-weight: 500;
//   font-size: 1rem;
//   line-height: 20px;
//   text-decoration-line: underline;
//   color: ${props => props.theme.color.button_deny};
//   background-color: transparent;
//   outline: none;
//   border: none;
//   cursor: pointer;
// `;

const LoginPage = (): JSX.Element => {
  const [email, setEmail] = useState<string>('')
  const [login, setLogin] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [plainPassword, setPlainPassword] = useState<boolean>(false)
  const [forgotPassword, setForgotPassword] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const clearInput = () => {
    setEmail('')
    setLogin('')
    setPassword('')
    setPlainPassword(false)
  }

  const handleSubmit = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Autenticando',
          backFocus: true,
          isLoading: true
        })
      )

      localStorage.clear()
      AppContext.data_post_closing = null
      AppContext.billing_review = null
      AppContext.apar_review = null
      AppContext.data_billing = null
      AppContext.apar = null

      const body = {
        username: login,
        password: password,
        grant_type: 'password'
      }

      const { data } = await axiosLogin.post('/login', body)

      if (data.firstAccess) {
        // localStorage.setItem("fullName", data.fullName);
        // localStorage.setItem("username", email);
        // localStorage.setItem("currentPassword", password);
        // history.push("/first-login");
      } else {
        // Pegando id de usuário
        const {
          KJUR: {
            jws: { JWS }
          }
        } = jsrsasign
        const login = JWS.readSafeJSONString(
          jsrsasign.b64utoutf8(data.access_token.split('.')[1])
        )

        if (login) {
          // const { data: user } = await fetchOneUser({
          //   id: (login as any).id,
          // });
          // dispatch(setUser(user));

          localStorage.setItem('logged_user', (login as any).id)
        }

        localStorage.setItem('access_token', data.access_token)
        localStorage.setItem('refresh_token', data.refresh_token)
        localStorage.setItem('type', data.cad_type)

        history.push('/home')
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        dispatch(
          setGlobalMessage({
            message: `Usuário ou senha incorreto!`,
            type: 'ERROR'
          })
        )
      } else if (isNoContent(error)) {
        dispatch(
          setGlobalMessage({
            message: `Usuário não encontrado!`,
            type: 'ERROR'
          })
        )
      } else {
        dispatch(
          setGlobalMessage({
            message: `Error: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      clearInput()
      dispatch(clearLoading())
    }
  }

  const handleForgotPassword = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      const { data } = await axiosLogin.get(
        `/recover?login=${login}&email=${email}`
      )

      const body = {
        emails: [email],
        link: data.recover_token
      }

      await axiosEmail.post(`/forgot`, body)

      setForgotPassword(!forgotPassword)
    } catch (error) {
      if (isUnauthorized(error)) {
        dispatch(
          setGlobalMessage({
            message: `Usuário ou senha incorreto!`,
            type: 'ERROR'
          })
        )
      } else if (isNoContent(error)) {
        dispatch(
          setGlobalMessage({
            message: `Usuário não encontrado!`,
            type: 'ERROR'
          })
        )
      } else {
        dispatch(
          setGlobalMessage({
            message: `Error: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      clearInput()
      dispatch(clearLoading())
    }
  }

  return (
    <Section>
      {forgotPassword !== true ? (
        <StyledLogin>
          <AlignRight>
            <CenterItems>
              <Logo
                src={LogoDascam}
                alt={'Logo da Dascam'}
                onClick={() => history.push('/')}
              />
              <Container>
                <InputGroup>
                  <InputLogin
                    label='Login: '
                    maxWidth='300px'
                    value={login}
                    changeValue={(value) => setLogin(value)}
                  />
                </InputGroup>
                <InputGroup>
                  <InputPassword
                    label='Senha: '
                    maxWidth='300px'
                    value={password}
                    changeValue={(value) => setPassword(value)}
                    plainPassword={plainPassword}
                    handlePlainPassword={() => setPlainPassword(!plainPassword)}
                    handleSubmit={
                      login !== '' && password !== '' ? handleSubmit : undefined
                    }
                  />
                  <Button
                    variant={'link'}
                    color={'red.400'}
                    colorScheme={'red'}
                    mt={2}
                    onClick={() => {
                      setForgotPassword(!forgotPassword)
                    }}
                  >
                    Esqueceu sua senha?
                  </Button>
                </InputGroup>
              </Container>
              <Button
                variant={'outline'}
                color={'primary.400'}
                colorScheme={'primary'}
                w={'70%'}
                mb={4}
                onClick={() =>
                  login !== '' && password !== '' ? handleSubmit() : undefined
                }
              >
                Login
              </Button>
              <Button
                variant={'outline'}
                color={'primary.400'}
                colorScheme={'primary'}
                w={'70%'}
                onClick={() => history.push('/register')}
              >
                Fazer um novo Pré Cadastro
              </Button>
            </CenterItems>
            <Button
              variant={'link'}
              color={'terciary.400'}
              colorScheme={'terciary_hover'}
              onClick={() => {
                dispatch(
                  setGlobalMessage({
                    message: `Em Desenvolvimento!`,
                    type: 'WARN'
                  })
                )
              }}
            >
              Termos de Uso de Cybersegurança - Políticas de Privacidade -
              Contato
            </Button>
          </AlignRight>
        </StyledLogin>
      ) : (
        <StyledLogin>
          <AlignRight>
            <CenterItems>
              <Logo
                src={LogoDascam}
                alt={'Logo da Dascam'}
                onClick={() => history.push('/')}
              />
              <Container>
                <InputGroup>
                  <InputEmail
                    label='E-mail: '
                    maxWidth='300px'
                    value={email}
                    required
                    changeValue={(value) => setEmail(value)}
                  />
                </InputGroup>
              </Container>
              <Container>
                <InputGroup>
                  <InputText
                    label='Login: '
                    maxWidth='300px'
                    value={login}
                    required
                    changeValue={(value) => setLogin(value)}
                  />
                </InputGroup>
              </Container>
              <Button
                variant={'outline'}
                color={'primary.400'}
                colorScheme={'primary'}
                w={'70%'}
                mb={4}
                onClick={() =>
                  email !== '' && login !== ''
                    ? handleForgotPassword()
                    : undefined
                }
              >
                Confirmar
              </Button>
              <Button
                variant={'outline'}
                color={'red.400'}
                colorScheme={'red'}
                w={'70%'}
                onClick={() => {
                  setForgotPassword(!forgotPassword)
                }}
              >
                Cancelar
              </Button>
            </CenterItems>
          </AlignRight>
        </StyledLogin>
      )}
    </Section>
  )
}

export default LoginPage
