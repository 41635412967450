import { AxiosError } from "axios";
import { ApiContext } from "../ApiContext";
import { isUnauthorized } from "helpers/errors";
import { axiosAuth, axiosLogin } from "helpers/axiosInstances";

export class ApiProcess extends ApiContext {

  public async show(process_number: string | number, id_syscambio: string | number) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    return this.client.http
      .post<ApiProcess.OperationProps>(`/syscambio/process`, {
        process_number: Number(process_number),
        id_syscambio: Number(id_syscambio)
      }, config)
      .then((res) => res.data);
  }

}

export namespace ApiProcess {
  export interface CheckBalanceRequest {
    id_syscambio: string;
    document_number: string;
    document_type: string;
  }

  export interface CheckBalanceResponse {
    balance: number;
    flag: "newDoc" | string;
  }

  interface BeneficiaryInfo {
    beneficiary_identification: string;
    country_code: number;
    country_name: string;
    country_initials: string;
    beneficiary_address: string;
    beneficiary_city: string;
    bank_info: string;
    bank_country_code: number;
    bank_country: string;
    bank_city: string;
    bank_address: string;
    bank_swift: string;
    bank_aba: string;
    bank_chips: string;
    bank_account: string;
    bank_iban: string;
    bank_sortcode: string;
    bank_blz: string;
    bank_branchcode: string;
    bank_bsb: string;
    bank_transit: string;
    bank_cnaps: string;
  }

  export interface OperationProps {
    id: number;
    user_id: number;
    product_type: string;
    process_number: number;
    id_syscambio: number | string;
    client_name: string;
    client_document: string;
    beneficiary_code: number;
    beneficiary_name: string;
    beneficiary_info: {
      beneficiary: Partial<BeneficiaryInfo>;
      intermediary: Partial<BeneficiaryInfo>;
    };
    nature_fact: string;
    nature_client_type: string;
    nature_aval: string;
    nature_beneficiary: string;
    nature_group: string;
    currency_code: string;
    currency_iso: string;
    currency_name: string;
    process_value: string;
    process_expiration: string;
    documents: Array<{
      clearence_document: string;
      protocol: string;
      document_type: string;
      document_number: string;
      document_value: string;
      document_reference: string;
      document_key: string;
      document_issue: string;
      document_company: string;
      document_endorsement: string;
      document_additions: string;
      applied_value: string;
      balance_avaliable: string;
      total_value: string;
      departure_date: string;
      expiration_date: string;
      attached_file: string;
      attached_name: string;
      attached: string;
      set_number: string;
    }>;
    status: string;
    created_date: string;
    created_by_id: number;
    updated_date: string;
    updated_by_id: number;
    observation: ObservationProps;
  }

  export interface ProcessFilterProps {
    id: number;
    user_id: number;
    product_type: string;
    id_syscambio: number;
    client_name: string;
    currency_iso: string;
    currency_name: string;
    beneficiary_name: string;
    process_value: string;
    process_number: number;
    status: string[];
    date: [string, string];
    nature_fact: string;
    nature_client_type: string;
    nature_aval: string;
    nature_beneficiary: string;
    nature_group: string;
    limit: number;
    offset: number;
  }

  export interface OperationItem {
    id: string;
    user_id: string;
    product_type: string;
    id_syscambio: string;
    client_name: string;
    beneficiary_name: string;
    nature_fact: string;
    currency_code: string;
    currency_name: string;
    process_value: string;
    process_expiration: string;
    status: string;
    created_date: string;
  }

  export interface ObservationProps {
    process_id: number;
    text_content: string;
    created_date: string;
    created_by_id: number;
    creator_name: string;
    creator_sector?: string;
    status: string;
  }
}
