export interface ObservationOperationModel {
  id?: number;
  operation_id?: number;
  text_content?: string;
  status?: ObservationOperationStatusEnum;
  created_date?: string;
  created_by_id?: string;
  creator_name?: string;
  creator_sector?: string;
  updated_date?: string;
  updated_by_id?: string;
  updater_name?: string;
}

export enum ObservationOperationStatusEnum {
  SENT = "SENT", // Cliente = Enviado; Dascam = Em Analise
  PENDING = "PENDING", // Cliente = Em Rascunho
  CORRECTION = "CORRECTION", // Cliente = Para Correção
  APPROVAL = "APPROVAL", // Cliente + Dascam = Aguardando Aprovação do Cliente
  CANCELED = "CANCELED", // Cancelado/Desaprovado pelo Cliente
  RELEASE = "RELEASE", // Aprovado/Liberado pelo Cliente
}
