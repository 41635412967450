import {
  Button,
  HStack,
  IconButton,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { MdDelete } from 'react-icons/md'
import {
  DocumentGroupProps,
  DocumentItemProps
} from 'v2/components/forms/documents/contracts'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import TableRender from 'v2/components/shared/TableRender'
import {
  getDocumentsGroupAmmount,
  getNextDocumentsGroupAmmount
} from 'v2/services/factories/operation'
import { formatCurrency } from 'v2/services/helpers/formatCurrency'
import FileItem from '../../files/FileItem'
import DocumentSetModal, { Forms } from '../DocumentSetModal'
import { OperationInfosValue } from '../OperationInfos'
import DataTable, { TableColumn } from 'react-data-table-component'

interface OperationDocumentsProps {
  documents: DocumentItemProps[]
  onChange: (documents: DocumentItemProps[], indexDel?: number) => void
  formsEnabled: Forms[]
  idSyscambio: string
  readOnly?: boolean
  operation: OperationInfosValue
}

const OperationDocuments: React.FC<OperationDocumentsProps> = ({
  documents,
  onChange,
  formsEnabled,
  idSyscambio,
  readOnly,
  operation
}) => {
  const { toString } = formatCurrency()
  const showNewDocuments = useDisclosure()
  const [newDocumentsData, setNewDocumentsData] = useState<any>({})
  const [docsInfos, setDocsInfos] = useState<DocumentGroupProps>(
    getNextDocumentsGroupAmmount(documents, operation?.process!.value!)
  )

  const columns: TableColumn<any>[] = [
    {
      name: 'JOGO',
      cell: (row) => (
        <HStack m={1}>
          {readOnly && <Button isDisabled>{row.group_number}</Button>}
          {!readOnly && (
            <Button
              size={'xs'}
              onClick={() => {
                const docs = documents.filter(
                  (value) => value.group_number === row.group_number
                )
                setNewDocumentsData(() => ({ documents: docs }))
                setDocsInfos(() =>
                  getDocumentsGroupAmmount(
                    docs,
                    row.group_number,
                    operation?.process!.value!,
                    operation?.process!.type!
                  )
                )
                showNewDocuments.onOpen()
              }}
              rightIcon={<BiLinkExternal />}
            >
              {row.group_number}
            </Button>
          )}
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '80px'
    },
    {
      name: 'REFERÊNCIA',
      selector: (row) =>
        row.reference ?? row.reference !== '' ? row.reference : '---',
      sortable: true,
      width: '150px'
    },
    {
      name: 'DOCUMENTO',
      selector: (row) => row.document_type,
      sortable: true,
      width: '150px'
    },
    {
      name: 'NÚMERO',
      selector: (row) =>
        row.document_type === 'di'
          ? String(row.document_number)
              .replace(/\D/g, '')
              .replace(/(\d{2})(\d)/, '$1/$2')
              .replace(/(\d{7})(\d)/, '$1-$2')
              .replace(/(-\d{1})\d+?$/, '$1')
          : row.document_number,
      sortable: true,
      width: '130px'
    },
    {
      name: 'VALOR DA APLICAÇÃO',
      selector: (row) =>
        row.value_applied
          ? Number(row.value_applied).toLocaleString('pt-br', {
              minimumFractionDigits: 2
            })
          : '0,00',
      sortable: true,
      right: true,
      width: '220px'
    },
    {
      name: 'VALOR DO DOCUMENTO',
      selector: (row) =>
        Number(row.total_value ?? row.value_total ?? '0').toLocaleString(
          'pt-br',
          {
            minimumFractionDigits: 2
          }
        ),
      sortable: true,
      right: true,
      width: '220px'
    },
    {
      name: 'ANEXO',
      cell: (row) => (
        <HStack m={1}>
          {row.files && (
            <Col>
              {row?.files?.map((file: any, keyFile: number) => (
                <FileItem {...file} key={`fileItem${keyFile}`} length={24} />
              ))}
            </Col>
          )}
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '250px'
    },
    {
      name: '',
      cell: (row) => (
        <HStack m={1}>
          {!readOnly && (
            <IconButton
              aria-label='Excluir'
              onClick={() => {
                onChange(documents.filter((doc) => doc !== row))
              }}
            >
              <MdDelete />
            </IconButton>
          )}
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '80px'
    }
  ]

  return (
    <>
      <DocumentSetModal
        {...showNewDocuments}
        onSave={(value) => {
          // setDocuments((documents) => [...documents, ...value.documents]);

          console.log(value)

          value.documents.forEach(function (e) {
            if (e.old_doc) {
              documents = documents.filter(
                (doc) => doc.document_number !== e.old_doc
              )
            }
          })
          onChange(Array.from(new Set([...documents, ...value.documents])))
          setNewDocumentsData(() => ({}))
          setDocsInfos(() =>
            getNextDocumentsGroupAmmount(documents, operation?.process!.value!)
          )
          showNewDocuments.onClose()
        }}
        onChange={(value) => {
          if (value.indexDel !== null || value.indexDel !== undefined) {
            const checkIndex = documents.findIndex(
              (e) => e.document_number === String(value.document_number)
            )
            documents.splice(Number(checkIndex), 1)
          }
          setNewDocumentsData(value)
        }}
        onClose={() => {
          setNewDocumentsData(() => ({}))
          showNewDocuments.onClose()
        }}
        value={newDocumentsData}
        infos={docsInfos}
        formsEnabled={formsEnabled}
        idSyscambio={idSyscambio}
        operation={operation}
      />
      <Col>
        {!readOnly && (
          <Row mb={2}>
            <Button
              variant={'outline'}
              color={'terciary.400'}
              colorScheme={'terciary_hover'}
              onClick={() => {
                setDocsInfos(() =>
                  getNextDocumentsGroupAmmount(
                    documents,
                    operation?.process!.value!
                  )
                )
                showNewDocuments.onOpen()
              }}
            >
              Novo Jogo
            </Button>
          </Row>
        )}
        <Col>
          <Col
            rounded={'lg'}
            overflow={'hidden'}
            border={'2px solid transparent'}
            borderColor={'primary.500'}
          >
            <DataTable
              noDataComponent='Sem Documentos'
              columns={columns}
              data={documents ?? []}
              dense={true}
              fixedHeader={true}
              fixedHeaderScrollHeight={'350px'}
              striped={true}
            />
          </Col>
        </Col>
      </Col>
    </>
  )
}

export default OperationDocuments
