import GlobalLoading from 'components/generic/GlobalLoading'
import GlobalMessage from 'components/generic/GlobalMessage'
import PrivateRoute from 'components/generic/PrivateRoute'
import SuccessMessage from 'components/generic/SuccessMessage'
import Landing from 'pages/landing/Landing'
import PreRegistration from 'pages/pre-registration/PreRegistration'
import LoginPage from 'pages/restricted-area/login/Login'
import Loading from 'pages/Loading/Loading'
import Unauthorized from 'pages/Unauthorized/Unauthorized'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Users from 'pages/restricted-area/data/users/Users'
import UsersDascam from 'pages/restricted-area/dascam/users/UsersDascam'
import Management from 'pages/restricted-area/dascam/registration-management/Management'
import ForeignManagement from 'components/restricted-area/registration-data/dascam/registration-management/subComponents/foreignManagement/ForeignManagement'
import PhysicalManagement from 'components/restricted-area/registration-data/dascam/registration-management/subComponents/physicalManagement/PhysicalManagement'
import LegalManagement from 'components/restricted-area/registration-data/dascam/registration-management/subComponents/legalManagement/LegalManagement'
import Legal from 'components/restricted-area/registration-data/data/legal/Legal'
import Physical from 'components/restricted-area/registration-data/data/physical/Physical'
import Foreign from 'components/restricted-area/registration-data/data/foreign/Foreign'
import ForeignReading from 'components/restricted-area/registration-data/dascam/registration-management/subComponents/foreignReading/ForeignReading'
import PhysicalReading from 'components/restricted-area/registration-data/dascam/registration-management/subComponents/physicalReading/PhysicalReading'
import LegalReading from 'components/restricted-area/registration-data/dascam/registration-management/subComponents/legalReading/LegalReading'
import Home from 'pages/restricted-area/home/Home'
import PreCLosing from 'pages/restricted-area/data/pre-closing/PreClosing'
import NewLoading from 'components/generic/NewLoading'
import ContractReleased from 'pages/restricted-area/data/contract-released/ContractReleased'
import Configuration from 'pages/restricted-area/dascam/configuration/Configuration'
import RecoverPage from 'pages/restricted-area/recover-password/Recover'
import ProcessesClose from 'pages/restricted-area/data/processes-close/Processes'
import PostClosing from 'pages/restricted-area/data/post-closing/PostClosing'
import ExchangeSchedule from 'pages/restricted-area/data/exchange-schedule/ExchangeSchedule'
import CreateOperationPage from 'v2/pages/CreateOperationPage'
import ListOperationsPage from 'v2/pages/ListOperationsPage'
import ListReportsPage from 'v2/pages/ListReportsPage'
import MonitorPage from 'v2/pages/MonitorPage'
import ShowOperationPage from 'v2/pages/ShowOperationPage'
import EditOperationPage from 'v2/pages/EditOperationPage'
import SpreadsheetsOperationPage from 'v2/pages/SpreadsheetsOperationPage'
import ExchangeScheduleDetails from 'pages/restricted-area/data/exchange-schedule/ExchangeScheduleDetails'
import ProcessesCloseDetails from 'pages/restricted-area/data/processes-close/ProcessesDetails'
import FormBankInfo from 'v2/pages/FormBankInfo'
import FirstAccessPassword from 'pages/first-access/FirstAccessPassword'
import SupplierPortalPage from 'v2/pages/SupplierPortalPage'
import AFechar from 'pages/restricted-area/data/exchange-schedule/AFechar'
import ProcessToCloseInternal from 'pages/restricted-area/dascam/process-to-close/process-to-close-internal'
import Billing from 'pages/restricted-area/data/billing/Billing'
import BillingInternal from 'pages/restricted-area/dascam/billing/BillingInternal'
import ExchangeScheduleManagement from 'pages/restricted-area/dascam/billing/ExchangeScheduleManagement'
import SupplierPortalWorkFlowClient from 'v2/pages/SupplierPortalPage/workflow-client'
import SupplierPortalWorkFlowDascam from 'v2/pages/SupplierPortalPage/workflow-dascam'

function App () {
  return (
    <>
      <SuccessMessage />
      <GlobalMessage />
      <NewLoading />
      <GlobalLoading />
      <Router>
        <Switch>
          {/* Telas Dascam */}
          <PrivateRoute path='/dascam/review'>
            <ListOperationsPage />
          </PrivateRoute>
          <PrivateRoute path='/dascam/ap-ar-review'>
            <ProcessToCloseInternal />
          </PrivateRoute>
          <PrivateRoute path='/dascam/billing-review'>
            {<BillingInternal />}
          </PrivateRoute>
          <PrivateRoute path='/dascam/exchange-schedule-mg'>
            {<ExchangeScheduleManagement />}
          </PrivateRoute>
          <PrivateRoute path='/dascam/registration-management/reading/foreign/:email/:id'>
            <ForeignReading />
          </PrivateRoute>
          <PrivateRoute path='/dascam/registration-management/reading/individual/:email/:id'>
            <PhysicalReading />
          </PrivateRoute>
          <PrivateRoute path='/dascam/registration-management/reading/legal/:email/:id'>
            <LegalReading />
          </PrivateRoute>
          <PrivateRoute path='/dascam/registration-management/foreign/:email/:id'>
            <ForeignManagement />
          </PrivateRoute>
          <PrivateRoute path='/dascam/registration-management/individual/:email/:id'>
            <PhysicalManagement />
          </PrivateRoute>
          <PrivateRoute path='/dascam/registration-management/legal/:email/:id'>
            <LegalManagement />
          </PrivateRoute>
          <PrivateRoute path='/dascam/registration-management'>
            <Management />
          </PrivateRoute>
          <PrivateRoute path='/supplier-portal-workflow-management'>
            <SupplierPortalWorkFlowDascam />
          </PrivateRoute>
          <PrivateRoute path='/dascam/configuration'>
            <Configuration />
          </PrivateRoute>
          <PrivateRoute path='/dascam/users'>
            <UsersDascam />
          </PrivateRoute>
          <PrivateRoute path='/dascam/contract-released'>
            <ContractReleased />
          </PrivateRoute>

          {/* Telas Cliente */}
          <PrivateRoute path='/data/exchange-schedule'>
            {<ExchangeSchedule />}
          </PrivateRoute>
          <PrivateRoute path='/data/billing'>{<Billing />}</PrivateRoute>
          <PrivateRoute path='/data/ap-ar'>
            <AFechar />
          </PrivateRoute>
          <PrivateRoute path='/data/post-closing'>
            <PostClosing />
          </PrivateRoute>
          <PrivateRoute path='/data/pre-closing'>
            <PreCLosing />
          </PrivateRoute>
          <PrivateRoute path='/data/processes-close'>
            <ProcessesClose />
          </PrivateRoute>
          <PrivateRoute path='/data/users'>
            <Users />
          </PrivateRoute>
          <PrivateRoute path='/data/foreign'>
            <Foreign />
          </PrivateRoute>
          <PrivateRoute path='/data/individual'>
            <Physical />
          </PrivateRoute>
          <PrivateRoute path='/data/company'>
            <Legal />
          </PrivateRoute>
          <PrivateRoute path='/home'>
            <Home />
          </PrivateRoute>
          <Route path='/recover/:token'>
            <RecoverPage />
          </Route>
          <Route path='/login'>
            <LoginPage />
          </Route>
          <Route path='/unauthorized'>
            <Unauthorized />
          </Route>
          <Route path='/loading*'>
            <Loading />
          </Route>
          <PrivateRoute path={'/operations/create'}>
            <CreateOperationPage />
          </PrivateRoute>
          <PrivateRoute path={'/operations/show/:id'}>
            <ShowOperationPage />
          </PrivateRoute>
          <PrivateRoute path={'/operations/edit/:id'}>
            <EditOperationPage />
          </PrivateRoute>
          <PrivateRoute path={'/operations'}>
            <ListOperationsPage />
          </PrivateRoute>
          <PrivateRoute path='/supplierPortal'>
            <SupplierPortalPage />
          </PrivateRoute>
          <PrivateRoute path='/supplierPortalWorkflow'>
            <SupplierPortalWorkFlowClient />
          </PrivateRoute>
          <PrivateRoute path={'/spreadsheets/create'}>
            <SpreadsheetsOperationPage />
          </PrivateRoute>
          <PrivateRoute path={'/spreadsheets/show/:id'}>
            <SpreadsheetsOperationPage />
          </PrivateRoute>
          <PrivateRoute path={'/spreadsheets/edit/:id'}>
            <SpreadsheetsOperationPage />
          </PrivateRoute>
          <PrivateRoute path={'/reports'}>
            <ListReportsPage />
          </PrivateRoute>
          <PrivateRoute path={'/monitor'}>
            <MonitorPage />
          </PrivateRoute>
          <PrivateRoute
            path={'/operation/show/:id_operation/:id_syscambio/:type'}
          >
            <ProcessesCloseDetails />
          </PrivateRoute>
          <PrivateRoute path={'/schedules/show/:id_process/:id_syscambio'}>
            <ExchangeScheduleDetails />
          </PrivateRoute>
          {/* <Route path="/foreign">
            <PreRegistration type="foreign" />
          </Route>
          <Route path="/individual">
            <PreRegistration type="individual" />
          </Route>
          <Route path="/company">
            <PreRegistration type="company" />
          </Route> */}
          <Route path='/bankinfo'>
            <FormBankInfo />
          </Route>
          <Route path='/register'>
            <PreRegistration />
          </Route>
          <Route path='/firstaccess'>
            <FirstAccessPassword />
          </Route>
          <Route path='/'>
            <Landing />
          </Route>
        </Switch>
      </Router>
    </>
  )
}

export default App
