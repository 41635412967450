import styled, { useTheme } from "styled-components";
import GenericButton from "../GenericButton";
import { ReactComponent as ExitIcon } from "assets/leftArrowIcon.svg";
import { DOMAttributes } from "react";

export const Col = styled.div<{ grid?: number }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 1px;
  float: left;
  padding: 0.25rem;
  flex: ${props => (props.grid === undefined ? 1 : "auto")};

  @media only screen and (min-width: 768px) {
    width: ${props => (props.grid ? (props.grid / 12) * 100 : "8:33")}%;
  }
`;

export const SquareButton = styled(GenericButton)`
  width: 30px;
  height: 30px;
  padding: 0.3rem;
  background: ${props => props.theme.color.secondary_main};
  justify-content: center;
  align-items: center;
  transform: scale(0.95);
  margin: 0 0.1rem;

  &:hover {
    background: ${props => props.theme.color.secondary_light};
    transform: scale(1);
  }

  & svg {
    width: 100%;
    height: 100%;
    /* fill: ${props => props.theme.color.primary_main}; */
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TitlePage = styled.h1`
  font-size: 32px;
`;

export const Square = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background: ${props => props.theme.color.primary_main};
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

interface ContainerHeaderProps {
  title: string;
  rightContent?: React.ReactNode;
}

export const ContainerHeader: React.FC<ContainerHeaderProps> = ({
  title,
  rightContent,
}) => {
  const { color } = useTheme();

  return (
    <Row
      style={{
        minHeight: 50,
        padding: "10px 20px",
        borderBottom: "1px solid transparent",
        borderColor: color.gray4,
      }}
    >
      <TitlePage style={{ flex: 1 }}>{title}</TitlePage>
      {rightContent}
    </Row>
  );
};

export const ContainerBody: React.FC = ({ children }) => {
  return (
    <Col
      style={{
        width: "100%",
        flex: 1,
        position: "relative",
        padding: 0,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          overflow: "scroll",
          padding: 0,
        }}
      >
        {children}
      </div>
    </Col>
  );
};

export const ContainerTitle: React.FC<{ value: string }> = ({ value }) => {
  return (
    <Row style={{ padding: "0 0.75rem" }}>
      <Square />
      <Col style={{ flex: 1, paddingLeft: 20 }}>
        <h3>{value}</h3>
      </Col>
    </Row>
  );
};

export const CloseButton: React.FC<
  DOMAttributes<HTMLButtonElement>
> = props => (
  <SquareButton {...props}>
    <ExitIcon />
  </SquareButton>
);

export const ContainerFillable: React.FC<{ title: string }> = ({
  children,
  title,
}) => {
  return (
    <Col>
      <ContainerTitle value={title} />
      {children}
    </Col>
  );
};
