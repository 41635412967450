export function formatCurrency() {
  return {
    toNumber: (value: string) => {
      const floatValue = Number(value.replace(/[.,\s]/gm, "")) / 100;
      return floatValue;
    },
    toString: (value: number) => {
      const [leftValue, rightValue] = value.toFixed(2).split(".");

      let nextLeftValue: any = leftValue
        .split("")
        .reverse()
        .join("")
        .match(/.{1,3}/g)
        ?.map((item) => {
          return item.split("").reverse().join("");
        })
        .reverse();

      if (value >= 0) {
        nextLeftValue = nextLeftValue!.join(".");
      } else {
        nextLeftValue = nextLeftValue!.join("");
      }

      const nextValue = `${/*isNaN(Number(nextLeftValue)) ? "0" :*/ nextLeftValue
        },${rightValue || "00"}`;

      return nextValue;
    },
  };
}
