import { Redirect, Route, RouteProps } from "react-router";
import useAuth from "hooks/useAuth";
// import PreventUnload from "hooks/PreventUnload";
import Header from "components/restricted-area/Header/Header";
import { useHashData } from "v2/hooks/helpers/useHashData";
import { useEffect, useState } from "react";

export default function PrivateRoute({ children, ...props }: RouteProps) {
  let isAuthenticated = useAuth();
  const { hashData, exists } = useHashData();
  const [canHandle, setCanHandle] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCanHandle(() => true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (exists) {
      setCanHandle(() => true);
    }
  }, [exists]);

  if (!canHandle) return <></>;

  return (
    <>
      {/* <PreventUnload /> */}
      <Route
        {...props}
        render={({ location }) =>
          isAuthenticated ? (
            <Header>{children}</Header>
          ) : (
            <Redirect
              to={{
                pathname: `/loading`,
                state: { from: location },
              }}
            ></Redirect>
          )
        }
      />
    </>
  );
}
