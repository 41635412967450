import { SimpleGrid } from '@chakra-ui/react'
import { formatCNPJ, formatCPF } from 'helpers/formats'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { CurrencyModel } from 'interfaces/currency'
import { CustomerModel } from 'interfaces/customer'
import { PayerReceiverModel } from 'interfaces/payiesReceivies'
import React, { useState } from 'react'
import FormPickClient from 'v2/components/forms/FormPickClient'
import FormPickCurrency from 'v2/components/forms/FormPickCurrency'
import FormPickPayerReceiver from 'v2/components/forms/FormPickPayerReceiver'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { createCurrencySearchPicker } from 'v2/services/app/factories/forms/createCurrencySearchPicker'
import { createCustomerSearchPicker } from 'v2/services/app/factories/forms/createCustomerSearchPicker'
import { createPayerReceiverSearchPicker } from 'v2/services/app/factories/forms/createPayerReceiverSearchPicker'

export interface OperationInfosValue {
  beneficiatyBank: BankFormInfos
  intermediaryBank: BankFormInfos
  payerReceiver: PayerReceiverModel
  currency: CurrencyModel
  customer: CustomerModel
  process: {
    refCli?: string
    number: string
    value: number
    expiration: Date
    type: string
  }
}

interface OperationInfosProps {
  customers: CustomerModel[]
  currencies: CurrencyModel[]
  value: OperationInfosValue
  onChange: (value: OperationInfosValue) => void
  readOnly?: boolean
  isEdit?: boolean
}

interface BankFormInfos {
  bankInitials: string
  bankAccount: string
  bankName: string
  bankABA: string
  bankAddress: string
  bankCity: string
  bankCountry: string
  bankSWIFT: string
  bankIBAN: string
  bankSortCode: string
  bankTRANSIT: string
  bankCNAPS: string
  bankBLZ: string
  bankBSB: string
  bankBranchCode: string
  bankCHIPS: string
}

const OperationInfos: React.FC<OperationInfosProps> = ({
  customers,
  currencies,
  value,
  onChange,
  readOnly,
  isEdit
}) => {
  const { user } = useGlobals()
  // const [customer, setCustomer] = useState<CustomerModel>();
  // const [currency, setCurrency] = useState<CurrencyModel>();
  // const [payerReceiver, setPayerReceiver] = useState<PayerReceiverModel>();
  // const [formBeneficiaryData, setBeneficiaryData] = useState<BankFormInfos>(
  //   {} as BankFormInfos
  // );
  // const [formIntermediaryData, setIntermediaryData] = useState(
  //   {} as BankFormInfos
  // );

  const {
    payerReceiver,
    currency,
    beneficiatyBank,
    customer,
    intermediaryBank,
    process
  } = value

  return (
    <Col>
      <SimpleGrid gap={2}>
        <SimpleFormGenerator
          readOnly={readOnly}
          rows={[
            {
              columns: 4,
              fields: [
                {
                  label: 'Selecione um Produto*',
                  name: 'type',
                  type: 'select',
                  options: [
                    { label: () => 'Importação', value: 'IMPORTACAO' },
                    { label: () => 'Remessa', value: 'REMESSA' },
                    { label: () => 'Ingresso', value: 'INGRESSO' },
                    { label: () => 'Exportação', value: 'EXPORTACAO' }
                  ]
                },
                {
                  label: 'Nº do Processo (STALLOS)',
                  name: 'number',
                  type: 'text',
                  readOnly: true
                }
              ]
            }
          ]}
          value={process}
          onChange={(v) => {
            onChange({ ...value, process: v })
          }}
        />
        <SimpleFormGenerator
          readOnly={readOnly}
          rows={[
            {
              columns: 2,
              fields: [
                createCustomerSearchPicker(
                  user.company_id ?? user.id!,
                  user.cad_type === 'AUTORIZADO'
                    ? user.permission_group_company ?? []
                    : undefined,
                  {
                    label: 'Empresa*',
                    name: 'customer',
                    labelKey: 'fantasy_name'
                  }
                ),
                // {
                //   type: "text",
                //   name: "customer.corporate_name",
                //   label: "Nome",
                //   readOnly: true
                // },
                {
                  type: 'text',
                  name: 'customer.document',
                  label: 'CNPJ/CPF',
                  readOnly: true,
                  mask: (value) => {
                    return String(value).length === 11
                      ? formatCPF(value)
                      : formatCNPJ(value)
                  }
                }
              ]
            }
          ]}
          value={{ customer }}
          onChange={({ customer: nextCustomer }) => {
            console.log(nextCustomer)
            if (nextCustomer.document) {
              if (nextCustomer.document!.length > 11) {
                nextCustomer.document = nextCustomer.document
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d)/, '$1.$2')
                  .replace(/(\d{3})(\d)/, '$1.$2')
                  .replace(/(\d{3})(\d)/, '$1/$2')
                  .replace(/(\d{4})(\d{1,2})/, '$1-$2')
                  .replace(/(-\d{2})\d+?$/, '$1')
              } else {
                nextCustomer.document = nextCustomer.document
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d)/, '$1/$2')
                  .replace(/(\d{7})(\d)/, '$1-$2')
                  .replace(/(-\d{1})\d+?$/, '$1')
              }
            }
            if (customer?.id_syscambio !== nextCustomer?.id_syscambio) {
              onChange({
                ...value,
                customer: nextCustomer,
                payerReceiver: undefined as any
              })
            } else {
              onChange({
                ...value,
                customer: nextCustomer
              })
            }
          }}
        />
        <SimpleFormGenerator
          readOnly={readOnly}
          rows={[
            {
              columns: 4,
              fields: [
                {
                  type: 'text',
                  name: 'process.refCli',
                  label: 'Referência'
                },
                createCurrencySearchPicker(
                  {
                    label: 'Moeda*',
                    name: 'currency',
                    labelKey: 'Nome'
                  },
                  currencies
                ),
                // { type: "text", name: "currency.CodigoISO", label: "Cod. ISO", readOnly: true },
                // { type: "text", name: "currency.Codigo", label: "Cod. Moeda", readOnly: true },
                {
                  type: 'currency',
                  name: 'process.value',
                  label: 'Valor do processo*'
                },
                {
                  type: 'date',
                  name: 'process.expiration',
                  label: 'Previsão Fechamento*'
                }
              ]
            }
          ]}
          value={{ process, currency }}
          onChange={({ process, currency }) => {
            onChange({ ...value, process, currency })
          }}
        />
        <SimpleFormGenerator
          readOnly={readOnly}
          rows={[
            {
              columns: 2,
              fields: [
                createPayerReceiverSearchPicker(
                  customer?.id_syscambio
                    ? String(customer?.id_syscambio)
                    : undefined,
                  {
                    label: 'Pagador/Recebedor no Exterior*',
                    description: customer?.id_syscambio
                      ? `De ${customer.fantasy_name}`
                      : 'Selecione um Cliente...',
                    name: 'payerReceiver',
                    // labelKey: "Nome",
                    readOnly: !customer?.id_syscambio
                  }
                )
                // {
                //   type: "text",
                //   name: "payerReceiver.CodPagReg",
                //   label: "Identificação",
                // },
                // {
                //   type: "text",
                //   name: "payerReceiver.CodCli",
                //   label: "Cod. Cliente",
                // },
              ]
            }
          ]}
          value={{ payerReceiver }}
          onChange={(nextValue) => {
            const { payerReceiver: v } = nextValue

            onChange({
              ...value,
              payerReceiver: v,
              beneficiatyBank: {
                ...value.beneficiatyBank,
                bankAccount: v?.ContaBancoBeneficiario || '',
                bankName: v?.NomeBancoBeneficiario || '',
                bankABA: v?.AbaBancoBeneficiario || '',
                bankAddress: v?.EnderecoBancoBeneficiario || '',
                bankCity: v?.CidadeBancoBeneficiario || '',
                bankCountry: v?.PaisBancoBeneficiario || '',
                bankSWIFT: v?.SwiftBancoBeneficiario || '',
                bankIBAN: v?.IbanBancoBeneficiario || '',
                bankSortCode: v?.SortCodeInglaterra || '',
                bankTRANSIT: v?.TransitCanada || '',
                bankCNAPS: v?.CnapsBeneficiarioChina || '',
                bankBLZ: v?.BlzAlemanha || '',
                bankBSB: v?.BsbAustralia || '',
                bankBranchCode: v?.BranchCodeAfricadosul || '',
                bankCHIPS: v?.ChipsIntermediario || ''
              },
              intermediaryBank: {
                ...value.intermediaryBank,
                bankAccount: v?.ContaIntermediario || '',
                bankABA: v?.AbaIntermediario || '',
                bankAddress: v?.EndBancoIntermediario || '',
                bankCity: v?.CidadeBancoIntermediario || '',
                bankCountry: v?.PaisBancoIntermediario || '',
                bankCHIPS: v?.ChipsIntermediario || '',
                bankIBAN: v?.IbanIntermediario || '',
                bankName: v?.NomeIntermediario || '',
                bankSWIFT: v?.SwiftIntermediario || ''
              }
            })
          }}
        />
        {/* <FormPickPayerReceiver
          items={payersReceivers}
          onChange={(v) => {
            // console.log("payer-receiver", v);
            // setPayerReceiver(() => v);

            onChange({
              ...value,
              payerReceiver: v,
              beneficiatyBank: {
                ...value.beneficiatyBank,
                bankAccount: v.ContaBancoBeneficiario || "",
                bankName: v.NomeBancoBeneficiario || "",
                bankABA: v.AbaBancoBeneficiario || "",
                bankAddress: v.EnderecoBancoBeneficiario || "",
                bankCity: v.CidadeBancoBeneficiario || "",
                bankCountry: v.PaisBancoBeneficiario || "",
                bankSWIFT: v.SwiftBancoBeneficiario || "",
                bankIBAN: v.IbanBancoBeneficiario || "",
                bankSortCode: v.SortCodeInglaterra || "",
                bankTRANSIT: v.TransitCanada || "",
                bankCNAPS: v.CnapsBeneficiarioChina || "",
                bankBLZ: v.BlzAlemanha || "",
                bankBSB: v.BsbAustralia || "",
                bankBranchCode: v.BranchCodeAfricadosul || "",
                bankCHIPS: v.ChipsIntermediario || "",
              },
              intermediaryBank: {
                ...value.intermediaryBank,
                bankAccount: v.ContaIntermediario || "",
                bankABA: v.AbaIntermediario || "",
                bankAddress: v.EndBancoIntermediario || "",
                bankCity: v.CidadeBancoIntermediario || "",
                bankCountry: v.PaisBancoIntermediario || "",
                bankCHIPS: v.ChipsIntermediario || "",
                bankIBAN: v.IbanIntermediario || "",
                bankName: v.NomeIntermediario || "",
                bankSWIFT: v.SwiftIntermediario || "",
              },
            });
          }}
          value={payerReceiver?.CodPagReg}
        /> */}
      </SimpleGrid>
      {payerReceiver && (
        <SimpleFormGenerator
          readOnly
          value={beneficiatyBank}
          onChange={(v) => {
            // console.log("change", v);
          }}
          rows={[
            {
              columns: 5,
              fields: [
                { name: 'bankName', label: 'Banco', type: 'text' },
                {
                  name: 'bankInitials',
                  label: 'Sigla',
                  type: 'text'
                },
                {
                  name: 'bankCountry',
                  label: 'País',
                  type: 'text'
                },
                {
                  name: 'bankCity',
                  label: 'Cidade',
                  type: 'text'
                },
                {
                  name: 'bankAddress',
                  label: 'Endereço',
                  type: 'text'
                }
              ]
            },
            {
              columns: 5,
              fields: [
                { name: 'bankSWIFT', label: 'SWIFT', type: 'text' },
                {
                  name: 'bankABA',
                  label: 'ABA',
                  type: 'text'
                },
                {
                  name: 'bankCHIPS',
                  label: 'CHIPS',
                  type: 'text'
                },
                {
                  name: 'bankAccount',
                  label: 'Nº da Conta',
                  type: 'text'
                },
                {
                  name: 'bankIBAN',
                  label: 'IBAN',
                  type: 'text'
                }
              ]
            },
            {
              columns: 6,
              fields: [
                {
                  name: 'bankSortCode',
                  label: 'SORT CODE (Inglaterra)',
                  type: 'text'
                },
                {
                  name: 'bankBLZ',
                  label: 'BLZ (Alemanha)',
                  type: 'text'
                },
                {
                  name: 'bankBranchCode',
                  label: 'BRANCH CODE (Africa do Sul)',
                  type: 'text'
                },
                {
                  name: 'bankBSB',
                  label: 'BSB (Austrália)',
                  type: 'text'
                },
                {
                  name: 'bankTRANSIT',
                  label: 'TRANSIT (Canadá)',
                  type: 'text'
                },
                {
                  name: 'bankCNAPS',
                  label: 'CNAPS (China)',
                  type: 'text'
                }
              ]
            }
          ]}
        />
      )}
      {payerReceiver && (
        <SimpleFormGenerator
          readOnly
          value={intermediaryBank}
          onChange={(v) => {
            // console.log("change", v);
          }}
          rows={[
            {
              columns: 1,
              fields: [
                {
                  name: '2nd',
                  type: 'heading',
                  label: 'Intermediario',
                  as: 'h5',
                  size: 'sm'
                }
              ]
            },
            {
              columns: 5,
              fields: [
                { name: 'bankName', label: 'Banco', type: 'text' },
                {
                  name: 'bankInitials',
                  label: 'Sigla',
                  type: 'text'
                },
                {
                  name: 'bankCountry',
                  label: 'País',
                  type: 'text'
                },
                {
                  name: 'bankCity',
                  label: 'Cidade',
                  type: 'text'
                },
                {
                  name: 'bankAddress',
                  label: 'Endereço',
                  type: 'text'
                }
              ]
            },
            {
              columns: 5,
              fields: [
                { name: 'bankSWIFT', label: 'SWIFT', type: 'text' },
                {
                  name: 'bankABA',
                  label: 'ABA',
                  type: 'text'
                },
                {
                  name: 'bankCHIPS',
                  label: 'CHIPS',
                  type: 'text'
                },
                {
                  name: 'bankAccount',
                  label: 'Nº da Conta',
                  type: 'text'
                },
                {
                  name: 'bankIBAN',
                  label: 'IBAN',
                  type: 'text'
                }
              ]
            },
            {
              columns: 1,
              fields: [
                {
                  name: '2nd',
                  type: 'heading',
                  label: 'Documentos',
                  as: 'h5',
                  size: 'sm'
                }
              ]
            }
          ]}
        />
      )}
    </Col>
  )
}

export default OperationInfos
