import { Button, Checkbox, ModalBody } from '@chakra-ui/react'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { filterString } from 'v2/services/helpers/filters'
import AppModal, { AppModalProps } from '../AppModal'
import { Col } from '../Col'
import { Row } from '../Row'
import SearchFormGenerator, { SearchRow } from '../SearchFormGenerator'
import TableRender, { TableColumn } from '../TableRender'

export type AppModelPickerManagerProps<T> = {
  title?: string
  items: T[]
  value?: any
  onSelect?: (item: T) => void
} & AppModalProps

export interface AppModalPickerProps extends AppModalProps {
  items: any[]
  uuidField: string
  columns: TableColumn[]
  search: {
    rows: SearchRow[]
    initialValue?: any
    filters: {
      [key: string]: string[]
    }
  }
  onSelect?: (item: any) => void
  value?: any
}

const AppModalPicker: React.FC<AppModalPickerProps> = ({
  items,
  search,
  uuidField,
  columns,
  onSelect,
  value,
  ...rest
}) => {
  const filters = search.filters
  const [searchValue, setSearchValue] = useState<any>(search.initialValue || {})
  const [selected, setSelected] = useState<any>(value)
  const data = useMemo(() => {
    let result = [...items]
    for (const key in filters) {
      result = filterString(result, searchValue[key], filters[key])
    }
    return result
  }, [filters, items, searchValue])

  useEffect(() => {
    if (value) {
      setSelected(() => value)
    }
  }, [value])

  return (
    <AppModal
      {...rest}
      _rightHeader={
        <>
          {selected && (
            <Button
              colorScheme={'green'}
              size={'sm'}
              onClick={() => {
                onSelect?.(items.find((c) => selected === c[uuidField])!)
              }}
            >
              Continuar
            </Button>
          )}
          {rest._rightHeader}
        </>
      }
    >
      <ModalBody p={0}>
        <Row>
          <SearchFormGenerator
            rows={search.rows}
            value={searchValue}
            onSearch={setSearchValue}
          />
        </Row>
        <Col>
          <div
            style={{
              maxHeight: '300px',
              overflowY: 'scroll'
            }}
          >
            <TableRender
              config={{
                columns: [
                  {
                    title: 'Selecionar',
                    key: 'pick',
                    renderColumn: () => <></>,
                    render: (_, item) => (
                      <>
                        <Checkbox
                          bg={'white'}
                          isChecked={selected === item[uuidField]}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (selected === item[uuidField]) {
                              setSelected(() => undefined)
                            } else {
                              setSelected(() => item[uuidField])
                            }
                          }}
                        />
                      </>
                    )
                  },
                  ...columns
                ]
              }}
              data={data}
            />
          </div>
        </Col>
      </ModalBody>
    </AppModal>
  )
}

export default AppModalPicker
