import React from 'react'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { FormDocumentProps } from '../contracts'

const FormDocumentDRE: React.FC<FormDocumentProps> = ({
  onChange,
  value,
  onRequestDocumentNumberBalance
}) => {
  return (
    <>
      <SimpleFormGenerator
        onChange={onChange}
        value={value}
        rows={[
          {
            columns: 3,
            fields: [
              {
                name: 'files',
                label: 'Arquivo',
                type: 'files',
                extensions: ['pdf']
              },
              {
                name: 'document_number',
                label: 'Número',
                type: 'text',
                onBlur: onRequestDocumentNumberBalance
              },
              { name: 'reference', label: 'Referência', type: 'text' }
            ]
          },
          {
            columns: 3,
            fields: value.document_number
              ? [
                  {
                    name: 'value_applied',
                    label: 'Valor Aplicado',
                    type: 'currency'
                  },
                  {
                    name: 'value_total',
                    label: value?.last_balance
                      ? 'Valor Disponível para Uso'
                      : 'Valor Total',
                    type: 'currency',
                    readOnly: value?.last_balance
                  },
                  {
                    name: 'balance',
                    label: 'Saldo',
                    type: 'currency',
                    readOnly: true
                  }
                ]
              : []
          },
          {
            columns: 1,
            fields:
              value?.last_total && value.document_number
                ? [
                    {
                      type: 'heading',
                      as: 'h6',
                      label: `Valor Total Original: ${value?.last_total}`,
                      name: '',
                      size: 'xs'
                    }
                  ]
                : []
          }
        ]}
      />
    </>
  )
}

export default FormDocumentDRE
