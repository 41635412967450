import { Button, Divider, Progress, Text, useBoolean } from '@chakra-ui/react'
import { axiosWebsite } from 'helpers/axiosInstances'
import { usePdfExport } from 'hooks/helpers/usePdfExport'
import { OperationModel } from 'interfaces/operation'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { createOperationNumberConsumer } from 'services/pdf/PdfExportHelper'
import { DocumentItemProps } from 'v2/components/forms/documents/contracts'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import DataTable, { TableColumn } from 'react-data-table-component'
import { formatCNPJ, formatProduct, typeContract } from 'helpers/formats'

const ProcessesCloseDetails: React.FC = ({ children }) => {
  const { id_operation, id_syscambio, type } = useParams<any>()
  const { createOperationContract, createAnalyticsRelatory } = usePdfExport()

  const [operationData, setOperation] = useState<OperationModel>()
  const [documents, setDocuments] = useState<DocumentItemProps[]>([])
  const [isLoadingRefresh, loadRefresh] = useBoolean()
  const history = useHistory()

  const columns: TableColumn<any>[] = [
    {
      name: 'REFERÊNCIA',
      selector: (row) => row.reference ?? '---',
      sortable: true
    },
    {
      name: 'DOCUMENTO',
      selector: (row) => row.document_type,
      sortable: true
    },
    {
      name: 'NÚMERO',
      selector: (row) =>
        row.document_type === 'di'
          ? String(row.document_number)
              .replace(/\D/g, '')
              .replace(/(\d{2})(\d)/, '$1/$2')
              .replace(/(\d{7})(\d)/, '$1-$2')
              .replace(/(-\d{1})\d+?$/, '$1')
          : row.document_number,
      sortable: true
    },
    {
      name: 'VALOR DA APLICAÇÃO',
      selector: (row) =>
        Number(row.applied_value).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }),
      sortable: true,
      right: true
    }
  ]

  const onRefresh = useCallback(async () => {
    loadRefresh.on()
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    const data = await axiosWebsite.post(
      `/operation/${
        type === '0' ? '' : type === '1' ? 'closed/' : 'posfec/'
      }search`,
      {
        operation_number: String(id_operation),
        id_syscambio: Number(id_syscambio)
      },
      config
    )
    if (data.data?.length !== 0) {
      setOperation(data.data[0])
      // ALTERAÇÂO PARA LISTAR
      // setDocuments(Object.keys(documents).length !== 0 ? data.data[0].documents : [])
      setDocuments(data.data[0].documents ?? [])
    }

    loadRefresh.off()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_operation, id_syscambio, loadRefresh, type])

  useEffect(() => {
    onRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_operation, id_syscambio])

  if (isLoadingRefresh && !operationData?.operation_number)
    return (
      <CardPage title={'Dados do Contrato'}>
        <Progress isIndeterminate colorScheme={'secondary'} h={1} />
      </CardPage>
    )

  return (
    <CardPage
      title={`Dados do Contrato ${operationData?.flag ?? ''}`}
      _headerRight={
        <>
          <Button
            variant={'ghost'}
            color={'terciary.400'}
            colorScheme={'terciary_hover'}
            mr='20px'
            onClick={() => {
              history.push('/data/post-closing')
            }}
          >
            Voltar
          </Button>
          <Button
            disabled={
              !(
                operationData &&
                (operationData.bacen_number !== null ||
                  operationData.bacen_number !== undefined ||
                  operationData.bacen_number !== '')
              )
            }
            variant={'outline'}
            color={'primary.400'}
            colorScheme={'primary'}
            mr='20px'
            onClick={() => {
              createOperationContract(operationData!)
            }}
          >
            Contrato de Câmbio
          </Button>
          <Button
            variant={'outline'}
            color={'primary.400'}
            colorScheme={'primary'}
            mr='20px'
            onClick={() => {
              createAnalyticsRelatory(
                operationData!,
                createOperationNumberConsumer(`/operation/posfec/search`, {
                  id_syscambio: Number(operationData!.user_syscambio)
                })
              )
            }}
          >
            Relatório de Conferência
          </Button>
        </>
      }
    >
      <Col px={4}>
        <SimpleFormGenerator
          rows={[
            {
              columns: 4,
              fields: [
                {
                  type: 'text',
                  label: 'Contrato (N. Banco Central)',
                  name: 'bacen_number',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Tipo do Contrato',
                  name: 'product_type',
                  readOnly: true,
                  mask (data) {
                    return typeContract(data)
                  }
                },
                {
                  type: 'text',
                  label: 'Produto',
                  name: 'product_type',
                  readOnly: true,
                  mask (data) {
                    return formatProduct(data)
                  }
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
        <Divider m={2} />
        <Text fontSize='2xl'>Informações da Operação</Text>
        <SimpleFormGenerator
          rows={[
            {
              columns: 2,
              fields: [
                {
                  type: 'text',
                  label: 'Nome do Cliente',
                  name: 'client_name',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Documento do Cliente',
                  name: 'client_document',
                  readOnly: true,
                  mask (data) {
                    return formatCNPJ(data)
                  }
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
        <SimpleFormGenerator
          rows={[
            {
              columns: 2,
              fields: [
                {
                  type: 'text',
                  label: 'Nome do beneficiário',
                  name: 'beneficiary_name',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Vínculo',
                  name: 'vinculo',
                  readOnly: true
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
        <SimpleFormGenerator
          rows={[
            {
              columns: 2,
              fields: [
                {
                  type: 'text',
                  label: 'Instituição Finaceira',
                  name: 'instituicao',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Documento Instituição',
                  name: 'instituicao_doc',
                  readOnly: true,
                  mask (data) {
                    return formatCNPJ(data)
                  }
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
        <SimpleFormGenerator
          rows={[
            {
              columns: 4,
              fields: [
                {
                  type: 'text',
                  label: 'Moeda',
                  name: 'currency_iso',
                  readOnly: true
                },
                {
                  type: 'currency',
                  label: 'Valor ME',
                  name: 'operation_value',
                  readOnly: true
                },
                {
                  type: 'rate',
                  label: 'Taxa',
                  name: 'taxa',
                  readOnly: true
                },
                {
                  type: 'currency',
                  label: 'Valor Reais',
                  name: 'reais_value',
                  readOnly: true
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
        <SimpleFormGenerator
          rows={[
            {
              columns: 3,
              fields: [
                {
                  type: 'date',
                  label: 'Liquidação BACEN',
                  name: 'bacen_closure',
                  readOnly: true
                },
                {
                  type: 'date',
                  label: 'Liquidação Reais',
                  name: 'reais_closure',
                  readOnly: true
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
        <Divider m={2} />
        <Text fontSize='2xl'>Beneficiário</Text>
        <SimpleFormGenerator
          readOnly
          value={operationData}
          onChange={(v) => {}}
          rows={[
            {
              columns: 4,
              fields: [
                {
                  name: 'bank_info',
                  label: 'Banco',
                  type: 'text',
                  readOnly: true
                },
                {
                  name: 'bank_country_initials',
                  label: 'País',
                  type: 'text',
                  readOnly: true
                },
                {
                  name: 'bank_city',
                  label: 'Cidade',
                  type: 'text',
                  readOnly: true
                },
                {
                  name: 'bank_address',
                  label: 'Endereço',
                  type: 'text',
                  readOnly: true
                }
              ]
            },
            {
              columns: 4,
              fields: [
                {
                  name: 'bank_swift',
                  label: 'SWIFT',
                  type: 'text',
                  readOnly: true
                },
                {
                  name: 'bank_aba',
                  label: 'ABA',
                  type: 'text',
                  readOnly: true
                },
                //{ name: "bankCHIPS", label: "CHIPS", type: "text" },
                {
                  name: 'bank_account',
                  label: 'Nº da Conta',
                  type: 'text',
                  readOnly: true
                },
                {
                  name: 'bank_iban',
                  label: 'IBAN',
                  type: 'text',
                  readOnly: true
                }
              ]
            },
            {
              columns: 6,
              fields: [
                {
                  name: 'bank_sortcode',
                  label: 'SORT CODE (Inglaterra)',
                  type: 'text',
                  readOnly: true
                },
                {
                  name: 'bank_blz',
                  label: 'BLZ (Alemanha)',
                  type: 'text',
                  readOnly: true
                },
                {
                  name: 'bank_branchcode',
                  label: 'BRANCH CODE (Africa do Sul)',
                  type: 'text',
                  readOnly: true
                },
                {
                  name: 'bank_bsb',
                  label: 'BSB (Austrália)',
                  type: 'text',
                  readOnly: true
                },
                {
                  name: 'bank_transit',
                  label: 'TRANSIT (Canadá)',
                  type: 'text',
                  readOnly: true
                },
                {
                  name: 'bank_cnaps',
                  label: 'CNAPS (China)',
                  type: 'text',
                  readOnly: true
                }
              ]
            }
          ]}
        />

        <Divider m={2} />
        <Text fontSize='2xl'>Intermediário</Text>
        <SimpleFormGenerator
          readOnly
          rows={[
            {
              columns: 3,
              fields: [
                {
                  type: 'text',
                  label: 'País (intermediário)',
                  name: 'intermediary_country_initials',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Cidade (intermediário)',
                  name: 'intermediary_city',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Endereço (intermediário)',
                  name: 'intermediary_address',
                  readOnly: true
                }
              ]
            },
            {
              columns: 5,
              fields: [
                {
                  type: 'text',
                  label: 'SWIFT (intermediário)',
                  name: 'intermediary_swift',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'ABA (intermediário)',
                  name: 'intermediary_aba',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'Nº da Conta (intermediário)',
                  name: 'intermediary_account',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'IBAN (intermediário)',
                  name: 'intermediary_iban',
                  readOnly: true
                },
                {
                  type: 'text',
                  label: 'CHIPS (intermediário)',
                  name: 'intermediary_chips',
                  readOnly: true
                }
              ]
            },
            {
              columns: 1,
              fields: [
                {
                  type: 'textarea',
                  label: 'Informação (intermediário)',
                  name: 'intermediary_info',
                  readOnly: true
                }
              ]
            }
          ]}
          value={operationData}
          onChange={(v) => {}}
        />
      </Col>
      <Col px={4} my={2}>
        <Divider mt={6} />
        <Text fontSize='2xl'>Documentos</Text>
        {documents.length !== 0 && (
          <Col>
            <Col
              rounded={'lg'}
              overflow={'hidden'}
              border={'2px solid transparent'}
              borderColor={'primary.500'}
            >
              <DataTable
                noDataComponent='Sem Documentos'
                columns={columns}
                data={documents ?? []}
                dense={true}
                fixedHeader={true}
                fixedHeaderScrollHeight={'350px'}
                striped={true}
              />
            </Col>
          </Col>
        )}
      </Col>
    </CardPage>
  )
}

export default ProcessesCloseDetails
