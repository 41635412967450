import { axiosWebsite } from "helpers/axiosInstances";
import { UserResponsibleEnum } from "interfaces/user";

interface SendNotificationProps {
  title: string;
  message: string;
  areas?: UserResponsibleEnum[];
  users?: number[];
}

const sendNotification = ({
  message,
  title,
  areas,
  users,
}: SendNotificationProps) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  return axiosWebsite
    .post(`/notification`, {
      type: "NORMAL",
      title,
      message,
      area: areas,
      users,
    }, config)
    .then(res => res.data)
    .then(console.log);
};

export { sendNotification };
