import {
  InputCNPJCPF,
  InputDate,
  InputName,
  InputNumber,
  InputSelect
} from 'components/generic/GenericInputs'
import { OperationModel } from 'interfaces/operation'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import FindCustomer from './subComponents/FindCustomer'
import { ReactComponent as LupaIcon } from 'assets/lupaIcon.svg'
import { ReactComponent as EyeIcon } from 'assets/IconOpenEye.svg'
import { ReactComponent as EditIcon } from 'assets/editIcon.svg'
import {
  ButtonItem,
  ExcelOperationForm,
  OperationForm
} from './subComponents/ExcelOperationForm'
import GenericButton from 'components/generic/GenericButton'
import {
  ExcelStatusEnum,
  ExcelTypeEnum,
  OperationExcelModel
} from 'interfaces/operation_excel'
import { useSelector } from 'react-redux'
import { GlobalState } from 'redux/types'
import { axiosWebsite } from 'helpers/axiosInstances'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { UserTypeEnum } from 'interfaces/user'
import { onlyBase64File } from 'helpers/validFilds'
import { BiSearchAlt2 } from 'react-icons/bi'

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
`

const ButtonYellow = styled.button<{ marginBottom?: string }>`
  width: 40px;
  height: 40px;
  padding: 0.3rem;
  background-color: ${(props) => props.theme.color.secondary_main};
  border-radius: 6px;
  outline: none;
  border: none;
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  cursor: pointer;
  transform: scale(0.95);
  margin-bottom: ${(props) => props.marginBottom};

  & svg {
    max-width: 100%;
    width: auto;
    height: 100%;
    & path {
      fill: ${(props) => props.theme.color.primary_main};
    }
  }

  &:hover {
    transform: scale(1);
  }
`

const Container = styled.div``

const CardGrid = styled.div`
  width: 100%;
  position: relative;
  overflow: auto;
`

const TableGrid = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: auto minmax(250px, 300px) minmax(100px, 150px) minmax(
      100px,
      150px
    );
  grid-auto-rows: 35px;
  height: 100%;
  width: auto;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

const TextTitle = styled.h4`
  text-transform: uppercase;
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
`

interface Filter {
  userSyscambio?: string
  clientName?: string
  clientDocument?: string
  initialDate?: string
  finalDate?: string
  status?: string[]
}

const ColumnText = styled.div<{ background?: boolean }>`
  border-right: 1px solid ${(props) => props.theme.color.white5};
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid ${(props) => props.theme.color.white5};
  padding: 0 0.5rem;
  font-size: 12px;
  background: ${(props) =>
    props.background ? props.theme.color.white1 : props.theme.color.white2};
`

interface HomeSpreadsheetsPreClosingProps {
  title?: string
  canAdd?: boolean
  onSave?: () => void
  onCloseForm?: () => void
  onOpenForm?: () => void
  statusList?: Array<{
    value: ExcelStatusEnum
    label: string
  }>
}

export const HomeSpreadsheetsPreClosing: React.FC<
  HomeSpreadsheetsPreClosingProps
> = ({
  title = 'Pré-Fechamento de Operações',
  canAdd,
  onSave,
  onCloseForm,
  onOpenForm,
  statusList = [
    {
      value: ExcelStatusEnum.APPROVAL,
      label: 'APPROVAL'
    },
    {
      value: ExcelStatusEnum.PENDING,
      label: 'PENDING'
    },
    {
      value: ExcelStatusEnum.CANCELED,
      label: 'CANCELED'
    },
    {
      value: ExcelStatusEnum.RELEASE,
      label: 'RELEASE'
    },
    {
      value: ExcelStatusEnum.SENT,
      label: 'SENT'
    }
  ]
}) => {
  const user = useSelector((state: GlobalState) => state.user)
  const isDascamUser = user?.cad_type === UserTypeEnum.DASCAM
  const [items, setItems] = useState<OperationExcelModel[]>([])
  const [dataInput, setDataInput] = useState<Filter>({})
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
  const [showOperationForm, setShowOperationForm] = useState(false)
  const { startLoading, stopLoading, setMessage } = useGlobals()
  const [initialData, setInitialData] = useState<OperationExcelModel>()
  const [isFormDisabled, setFormDisabled] = useState(false)

  useEffect(() => {
    if (showOperationForm) {
      onOpenForm?.()
    } else {
      onCloseForm?.()
    }
  }, [showOperationForm])

  const defaultStatusFilter = useMemo(() => {
    return statusList.map((s) => s.value) as string[]
  }, [statusList])

  useEffect(() => {
    load()
  }, [])

  const load = useCallback(
    (overwrite?: Partial<Filter>) => {
      const nextDataInput = {
        ...dataInput,
        ...overwrite
      }

      setDataInput(() => nextDataInput)

      startLoading('Carregando Listagem de Planilhas')
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      axiosWebsite
        .post(
          '/operation/excel/list',
          {
            user_id: isDascamUser ? undefined : user?.id!,
            limit: 20,
            offset: 0,
            initial_date: nextDataInput.initialDate,
            end_date: nextDataInput.finalDate,
            status: nextDataInput.status || defaultStatusFilter
          },
          config
        )
        .then((res) => res.data)
        .then((res) => res.excel)
        .then(setItems)
        .finally(() => {
          stopLoading()
        })
    },
    [dataInput, isDascamUser, defaultStatusFilter]
  )

  const resetFilters = () => {
    load({
      clientDocument: '',
      clientName: '',
      finalDate: '',
      initialDate: '',
      userSyscambio: '',
      status: defaultStatusFilter
    })
  }

  const dataOperation = useMemo<OperationModel>(() => {
    return {
      user_syscambio: dataInput.userSyscambio,
      client_name: dataInput.clientName,
      client_document: dataInput.clientDocument
    }
  }, [dataInput])

  const register = <T extends keyof Filter>(name: T) => {
    return {
      value: dataInput[name],
      changeValue: (value: Filter[T]) => {
        return setDataInput((oldFilter) => {
          return {
            ...oldFilter,
            [name]: value
          }
        })
      }
    }
  }

  const send = (data: any) => {
    const fields: (keyof OperationExcelModel)[] = [
      'client_name',
      'client_doc',
      'client_name'
    ]

    for (const field of fields) {
      if (!data[field]) {
        setMessage({
          message: `O campo [${field}] é obrigatório!`,
          type: 'ERROR'
        })

        return
      }
    }

    startLoading(data.id ? 'Editando registro' : 'Criando novo registro')
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    axiosWebsite
      .post('/operation/excel', data, config)
      .then((res) => {
        console.log('results', res.data)
      })
      .then(() => {
        onSave?.()
        setShowOperationForm(() => false)
        load()
      })
      .catch(() => {
        setMessage({
          message: 'Não foi possível salvar.',
          type: 'ERROR'
        })
      })
      .finally(() => {
        stopLoading()
      })
  }

  const clientButtonsPending: ButtonItem[] = [
    {
      text: 'Voltar',
      isDeny: true,
      onClick: () => {
        setFormDisabled(() => false)
        setShowOperationForm(() => false)
      }
    },
    {
      text: 'Salvar Rascunho',
      isYellow: true,
      onClick: (data) => {
        const operationExcel = toOperationExcel(data, {
          status: ExcelStatusEnum.PENDING // draft
        })

        send(operationExcel)
      }
    },
    {
      text: 'Enviar Planilha',
      onClick: (data) => {
        const operationExcel = toOperationExcel(data, {
          status: ExcelStatusEnum.SENT // sent
        })

        send(operationExcel)
      }
    }
  ]

  const buttonsView: ButtonItem[] = [
    {
      text: 'Voltar',
      isDeny: true,
      onClick: () => {
        setFormDisabled(() => false)
        setShowOperationForm(() => false)
      }
    }
  ]

  const clientButtonsSent: ButtonItem[] = [
    {
      text: 'Voltar',
      isDeny: true,
      onClick: () => {
        setFormDisabled(() => false)
        setShowOperationForm(() => false)
      }
    }
  ]

  const dascamButtonsSent: ButtonItem[] = [
    {
      text: 'Voltar',
      isDeny: true,
      onClick: () => {
        setFormDisabled(() => false)
        setShowOperationForm(() => false)
      }
    },
    {
      text: 'Devolver para Revisão',
      isYellow: true,
      onClick: (data) => {
        const operationExcel = toOperationExcel(data, {
          status: ExcelStatusEnum.PENDING // draft
        })

        send(operationExcel)
      }
    },
    {
      text: 'Enviar para Liberação',
      onClick: (data) => {
        const operationExcel = toOperationExcel(data, {
          status: ExcelStatusEnum.APPROVAL // draft
        })

        send(operationExcel)
      }
    }
  ]

  const dascamButtonsApproval: ButtonItem[] = [
    {
      text: 'Voltar',
      isDeny: true,
      onClick: () => {
        setFormDisabled(() => false)
        setShowOperationForm(() => false)
      }
    }
  ]

  const clientButtonsApproval: ButtonItem[] = [
    {
      text: 'Voltar',
      isYellow: true,
      onClick: () => {
        setFormDisabled(() => false)
        setShowOperationForm(() => false)
      }
    },
    {
      text: 'Cancelar',
      isDeny: true,
      onClick: (data) => {
        const operationExcel = toOperationExcel(data, {
          status: ExcelStatusEnum.CANCELED // draft
        })

        send(operationExcel)
      }
    },
    {
      text: 'Enviar Liberação de Cotação e Fechamento',
      onClick: (data) => {
        const operationExcel = toOperationExcel(data, {
          status: ExcelStatusEnum.RELEASE // draft
        })

        send(operationExcel)
      }
    }
  ]

  const dascamButtonsPending: ButtonItem[] = [
    {
      text: 'Voltar',
      isDeny: true,
      onClick: () => {
        setFormDisabled(() => false)
        setShowOperationForm(() => false)
      }
    }
  ]

  const buttons = useMemo(() => {
    if (isFormDisabled) return buttonsView
    if (isDascamUser) {
      if (!initialData) return []
      if (initialData?.status === ExcelStatusEnum.SENT) {
        return dascamButtonsSent
      }
      if (initialData?.status === ExcelStatusEnum.PENDING) {
        return dascamButtonsPending
      }

      if (initialData?.status === ExcelStatusEnum.APPROVAL) {
        return dascamButtonsApproval
      }

      if (initialData?.status === ExcelStatusEnum.RELEASE) {
        return dascamButtonsApproval
      }
    } else {
      if (initialData) {
        if (initialData.status === ExcelStatusEnum.SENT) {
          return clientButtonsSent
        }

        if (initialData.status === ExcelStatusEnum.PENDING) {
          return clientButtonsPending
        }

        if (initialData.status === ExcelStatusEnum.APPROVAL) {
          return clientButtonsApproval
        }

        if (initialData.status === ExcelStatusEnum.RELEASE) {
          return clientButtonsSent
        }
      } else {
        return clientButtonsPending
      }
    }

    return []
  }, [isFormDisabled, isDascamUser, initialData])

  const toOperationExcel = (
    data: OperationForm,
    additionals?: Partial<OperationExcelModel>
  ) => {
    const clientExcel = onlyBase64File(data.clientExcel?.file)
    const dascamExcel = onlyBase64File(data.dascamExcel?.file)
    const attachmentFile = onlyBase64File(data.attachment?.file)

    const operationExcel: Partial<OperationExcelModel> = {
      id: initialData?.id,
      user_id: initialData?.user_id || user?.id!,
      attachment: attachmentFile,
      attachment_type: attachmentFile ? data.attachment?.type : undefined,
      attachment_name: attachmentFile ? data.attachment?.type : undefined,
      product_type: ExcelTypeEnum.IMPORTACAO,
      client_excel: clientExcel,
      client_excel_type: clientExcel ? data.clientExcel?.type : undefined,
      client_excel_name: clientExcel ? '' : undefined,
      client_doc: data.cnpjCpf,
      client_name: data.clientName,
      client_observation: data.clientObservation,
      created_by_id: initialData?.created_by_id || user?.id,
      dascam_excel: dascamExcel,
      dascam_excel_type: dascamExcel ? data.dascamExcel?.type : undefined,
      created_date: initialData?.created_date || new Date(),
      dascam_excel_name: '',
      dascam_observation: data.dascamObservation,
      status: ExcelStatusEnum.SENT,
      updated_by_id: user?.id,
      updated_date: new Date(),
      user_syscambio: Number(data.codClient)
    }

    const nextOperation = {
      ...operationExcel,
      ...additionals
    }

    return nextOperation
  }

  return (
    <>
      {showOperationForm && (
        <ExcelOperationForm
          initialData={initialData}
          isDisabled={isFormDisabled}
          buttons={buttons}
        />
      )}
      {!showOperationForm && (
        <Container>
          <Col style={{ padding: '0 40px' }}>
            <Row style={{ alignItems: 'center' }}>
              <TextTitle style={{ flex: 1 }}>{title}</TextTitle>
              {canAdd && (
                <GenericButton
                  onClick={() => {
                    setInitialData(() => undefined)
                    setShowOperationForm(() => true)
                  }}
                >
                  Novo Envio de Planilha
                </GenericButton>
              )}
            </Row>
            <ItemRow>
              <InputNumber
                label='Cód. do cliente'
                maxWidth='130px'
                maxLength={6}
                {...register('userSyscambio')}
              />
              <InputName
                label='Cliente'
                maxWidth='300px'
                {...register('clientName')}
              />
              <InputCNPJCPF
                label='CNPJ/CPF'
                maxWidth='200px'
                {...register('clientDocument')}
              />
              <ButtonYellow
                type='button'
                marginBottom='.3rem'
                onClick={() => setShowCustomerModal(!showCustomerModal)}
              >
                <BiSearchAlt2 />
              </ButtonYellow>
            </ItemRow>
            <ItemRow>
              <InputDate
                label='Período'
                maxWidth='200px'
                {...register('initialDate')}
              />
              <InputDate
                label='até'
                maxWidth='200px'
                {...register('finalDate')}
              />
              {statusList.length > 1 && (
                <InputSelect
                  label='Status'
                  maxWidth='200px'
                  value={dataInput.status as any}
                  changeValue={(value) => {
                    if (value === '') {
                      setDataInput((input) => ({
                        ...input,
                        status: defaultStatusFilter
                      }))
                    } else {
                      setDataInput({
                        ...dataInput,
                        status: [value]
                      })
                    }
                  }}
                >
                  <option value='' selected>
                    Todos
                  </option>
                  {statusList.map((status, keyStatus) => (
                    <option
                      key={`status${status.value}${keyStatus}`}
                      value={status.value}
                    >
                      {status.label}
                    </option>
                  ))}
                </InputSelect>
              )}
              <GenericButton style={{ marginRight: 20 }} onClick={resetFilters}>
                Limpar Filtro
              </GenericButton>
              <GenericButton
                onClick={() => {
                  load()
                }}
              >
                Buscar planilhas
              </GenericButton>
            </ItemRow>
          </Col>
          <CardGrid style={{ padding: '0 40px', marginTop: 40 }}>
            <TableGrid>
              <GridTitle>
                <Text fontWeight='bold'>Cliente</Text>
              </GridTitle>
              <GridTitle>
                <Text fontWeight='bold'>Data de Lançamento</Text>
              </GridTitle>
              <GridTitle>
                <Text fontWeight='bold'>Status</Text>
              </GridTitle>
              <GridTitle>
                <Text fontWeight='bold'>Ações</Text>
              </GridTitle>
              {items.map((excelOperation) => (
                <Fragment key={`excelOperation${excelOperation.id}`}>
                  <ColumnText>{excelOperation.client_name}</ColumnText>
                  <ColumnText>
                    {new Date(
                      excelOperation.created_date!
                    ).toLocaleDateString()}
                  </ColumnText>
                  <ColumnText>
                    <Text
                      fontWeight={
                        excelOperation.status === 'SENT'
                          ? 'bold'
                          : excelOperation.status === 'APPROVAL'
                          ? 'bold'
                          : excelOperation.status === 'CORRECTION'
                          ? 'bold'
                          : ''
                      }
                      Color={
                        excelOperation.status === 'PENDING'
                          ? '#7C7C7C'
                          : excelOperation.status === 'SENT'
                          ? '#0F4C86'
                          : excelOperation.status === 'APPROVAL'
                          ? '#161616'
                          : excelOperation.status === 'CANCELED'
                          ? '#FF0000'
                          : excelOperation.status === 'RELEASE'
                          ? '#039D00'
                          : excelOperation.status === 'CORRECTION'
                          ? '#FF8A00'
                          : ''
                      }
                      title={
                        excelOperation.status === 'PENDING'
                          ? 'Pendente'
                          : excelOperation.status === 'SENT'
                          ? 'Em Análise'
                          : excelOperation.status === 'APPROVAL'
                          ? 'Aguardando Aprovação'
                          : excelOperation.status === 'CANCELED'
                          ? 'Cancelada'
                          : excelOperation.status === 'RELEASE'
                          ? 'Liberado'
                          : excelOperation.status === 'CORRECTION'
                          ? 'Para Correção'
                          : ''
                      }
                    >
                      {excelOperation.status === 'PENDING'
                        ? 'Pendente'
                        : excelOperation.status === 'SENT'
                        ? 'Em Análise'
                        : excelOperation.status === 'APPROVAL'
                        ? 'Em Liberação'
                        : excelOperation.status === 'CANCELED'
                        ? 'Cancelada'
                        : excelOperation.status === 'RELEASE'
                        ? 'Liberado'
                        : excelOperation.status === 'CORRECTION'
                        ? 'Para Correção'
                        : ''}
                    </Text>
                  </ColumnText>
                  <ColumnText>
                    <ButtonYellow
                      style={{ width: 30, height: 30 }}
                      onClick={() => {
                        setFormDisabled(() => true)
                        setInitialData(() => excelOperation)

                        setShowOperationForm(() => true)
                      }}
                    >
                      <EyeIcon />
                    </ButtonYellow>
                    {(excelOperation.status === 'SENT' || !isDascamUser) &&
                      excelOperation.status !== 'RELEASE' && (
                        <ButtonYellow
                          style={{ width: 30, height: 30, marginLeft: 10 }}
                          onClick={() => {
                            setFormDisabled(() => false)
                            setInitialData(() => excelOperation)

                            setShowOperationForm(() => true)
                          }}
                        >
                          <EditIcon />
                        </ButtonYellow>
                      )}
                  </ColumnText>
                </Fragment>
              ))}
            </TableGrid>
          </CardGrid>
        </Container>
      )}
      <FindCustomer
        showModal={showCustomerModal}
        handleModal={() => setShowCustomerModal(!showCustomerModal)}
        data={dataOperation}
        changeData={(nextOperation) => {
          setDataInput(() => {
            return {
              clientDocument: nextOperation.client_document,
              userSyscambio: nextOperation.user_syscambio,
              clientName: nextOperation.client_name
            }
          })
        }}
      />
    </>
  )
}
