import {
  InputCelPhone,
  InputCPF,
  InputDate,
  InputEmail,
  InputImgBase64,
  InputLogin,
  InputPhone,
  InputText
} from 'components/generic/GenericInputs'
import { Button } from '@chakra-ui/button'
import Modal from 'components/generic/Modal'
import PermissionPicker from 'components/generic/PermissionPicker'
import { axiosAuth, axiosEmail } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { makeBaseUsersPermissionsGroups } from 'helpers/factories/permissions'
import { validateFild } from 'helpers/validFilds'
import { UserModel, UserStatusEnum, UserTypeEnum } from 'interfaces/user'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading
} from 'redux/actions'
import { GlobalState } from 'redux/types'
import styled from 'styled-components'
import { Avatar } from '@chakra-ui/react'

interface Props {
  showModal: boolean
  getUserData: () => void
  handleModal: () => void
  company_id?: number
}

const RegisterUser = ({
  showModal,
  getUserData,
  handleModal,
  company_id
}: Props): JSX.Element => {
  const user = useSelector((state: GlobalState) => state.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const [userData, setUserData] = useState<UserModel>({
    cad_type: 'AUTORIZADO' as UserTypeEnum,
    company_id: user?.id,
    permission_list: [],
    status: 'ACTIVE' as UserStatusEnum
  })

  const userRequired = ['user_name', 'email', 'login']

  const handleSubmit = async () => {
    if (validateFild(userData, userRequired)) {
      try {
        dispatch(
          showLoading({
            message: 'Carregando',
            isLoading: true
          })
        )

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }

        const { data } = await axiosAuth.post(`/users`, userData, config)

        const body_mail = {
          emails: [userData.email],
          user: userData.user_name,
          login: userData.login,
          password: data.password
        }

        dispatch(setSuccessMessage('Usuário registrado com sucesso!'))

        await axiosEmail.post(`/first-access-internal`, body_mail)

        close()
      } catch (error: any) {
        if (isUnauthorized(error)) {
          history.push('/unauthorized')
        } else {
          dispatch(
            setGlobalMessage({
              message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
              type: 'ERROR'
            })
          )
        }
      } finally {
        dispatch(clearLoading())
        getUserData()
      }
    } else {
      dispatch(
        setGlobalMessage({
          message: `Preencha todos os dados`,
          type: 'WARN'
        })
      )
    }
  }

  const close = () => {
    setUserData({
      cad_type: 'AUTORIZADO' as UserTypeEnum,
      company_id: user?.id,
      permission_list: [],
      status: 'ACTIVE' as UserStatusEnum
    })
    handleModal()
  }

  useEffect(() => {
    setUserData({ ...userData, company_id: company_id ?? user?.id! })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  return (
    <Modal title='Incluir novo usuário' showModal={showModal} backFocus>
      <ModalStyled>
        <LeftPane>
          <ItemRow>
            <InputText
              label='Nome*'
              value={userData.user_name!}
              changeValue={(value) =>
                setUserData({ ...userData, user_name: value })
              }
            />
          </ItemRow>
          <ItemRow>
            <InputEmail
              //maxWidth='200px'
              label='E-mail*'
              placeholder='exemplo@mail.com'
              value={userData.email!}
              changeValue={(value) =>
                setUserData({ ...userData, email: value })
              }
            />
            <InputCPF
              // maxWidth='200px'
              label='Documento'
              placeholder='___.___.___-__'
              value={userData.document!}
              changeValue={(value) =>
                setUserData({ ...userData, document: value })
              }
            />
            {/*<InputDate
              maxWidth="200px"
              label="Data de Nascimento"
              max={new Date().toISOString().split("T")[0]}
              value={userData.birthdate!}
              changeValue={value =>
                setUserData({ ...userData, birthdate: value })
              }
            />*/}
          </ItemRow>
          <ItemRow>
            <InputText
              // maxWidth='200px'
              label='Empresa'
              value={userData.contact_role!}
              changeValue={(value) =>
                setUserData({ ...userData, contact_role: value })
              }
            />
            <InputText
              //maxWidth='200px'
              label='Departamento'
              value={userData.contact_branch!}
              changeValue={(value) =>
                setUserData({ ...userData, contact_branch: value })
              }
            />
          </ItemRow>
          <ItemRow>
            <InputCelPhone
              //maxWidth='200px'
              label='Celular'
              placeholder='00000-0000'
              value={userData.contact_cellphone!}
              changeValue={(value) =>
                setUserData({ ...userData, contact_cellphone: value })
              }
            />
            <InputPhone
              //maxWidth='200px'
              label='Telefone'
              placeholder='0000-0000'
              value={userData.contact_phone!}
              changeValue={(value) =>
                setUserData({ ...userData, contact_phone: value })
              }
            />
          </ItemRow>
          <ItemRow>
            <InputLogin
              value={userData.login}
              label='Login de acesso *'
              maxWidth='200px'
              required
              changeValue={(value) =>
                setUserData({
                  ...userData,
                  login: value
                })
              }
            />
          </ItemRow>
        </LeftPane>
        <RightPane>
          <PermissionPicker
            onChange={(permissions) => {
              console.log({ permissions })
              setUserData((oldUser) => ({
                ...oldUser,
                permission_list: permissions
              }))
            }}
            groups={makeBaseUsersPermissionsGroups()}
          />
        </RightPane>
      </ModalStyled>
      <ButtonDiv>
        <Button
          m={2}
          variant='outline'
          color={'red.400'}
          colorScheme={'red'}
          onClick={() => close()}
        >
          Cancelar
        </Button>
        <Button
          m={2}
          variant='ghost'
          color={'green.400'}
          colorScheme={'green'}
          onClick={() => handleSubmit()}
        >
          Cadastrar
        </Button>
      </ButtonDiv>
    </Modal>
  )
}

const ModalStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
`

const LeftPane = styled.div`
  width: auto;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  & div {
    align-items: center;
  }
`

const RightPane = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`

const ButtonDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
`

export default RegisterUser
