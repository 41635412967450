import React from 'react'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as ExitIcon } from 'assets/leftArrowIcon.svg'
import { devices } from 'helpers/devices'
import { Button, IconButton } from '@chakra-ui/react'
import { AiOutlineLeft } from 'react-icons/ai'

const FadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    `

const AppearFromCenter = keyframes`
    from {
        opacity: 0;
        transform: scale(.3);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
    `

const Background = styled.div<{ backFocus?: boolean; animate: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15000;
  background: ${(props) =>
    props.backFocus ? props.theme.color.transparent_opaque : 'none'};
  animation: ${FadeIn} 200ms 1 ease-out both
    ${(props) => (props.animate ? 'running' : 'paused')};
`

const Container = styled.div<{
  padding: string
  backgroundColor: string
  maxWidth?: string
  maxHeight?: string
  width?: string
  height?: string
  animate: boolean
}>`
  padding: ${(props) => props.padding};
  box-shadow: 1px 2px 4px gray;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-height: ${(props) => props.maxHeight};
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  animation: ${AppearFromCenter} 200ms 1 ease-out both
    ${(props) => (props.animate ? 'running' : 'paused')};
`

const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  position: static;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
`

const Title = styled.p<{
  color: string
  fontSize: string
  textAlign?: string
  margin?: string
}>`
  color: ${(props) => props.color};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.5rem')};
  font-weight: bold;
  text-align: ${(props) => props.textAlign};
  margin: ${(props) => props.margin};
  text-transform: uppercase;

  @media ${devices.phoneOnly} {
    font-size: 1.2rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1.2rem;
  }

  @media ${devices.desktop} {
    font-size: 1.5rem;
  }
`

const SecondTitle = styled.p<{
  color: string
  fontSize: string
  textAlign?: string
}>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  font-weight: bold;
  text-align: ${(props) => props.textAlign};
  margin: 0 0.5rem;
`

const TieDiv = styled.div`
  display: flex;
`

const SubTitle = styled.p<{ fontSize: string }>`
  color: ${(props) => props.theme.color.text3};
  font-size: ${(props) => props.fontSize};
`

const CloseButton = styled.button`
  background: ${(props) => props.theme.color.secondary_main};
  border: none !important;
  width: 30px;
  height: 30px;
  outline: none;
  cursor: pointer;
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &:hover {
    background: ${(props) => props.theme.color.secondary_light};
  }

  & svg {
    height: 100%;
    width: auto;
  }
`

interface Props {
  //Modal
  title: string
  showModal: boolean
  children: React.ReactNode
  close?: () => void
  padding?: string
  backgroundColor?: string
  maxWidth?: string
  maxHeight?: string
  width?: string
  height?: string
  secondTitle?: string
  subtitle?: string
  backFocus?: boolean
  animate?: boolean
  noClose?: boolean

  //Title
  color?: string
  fontSize?: string
  textAlign?: string
  margin?: string
}

const Modal = ({
  showModal,
  close,
  children,
  padding,
  backgroundColor = '#fff',
  maxWidth,
  maxHeight,
  width,
  height,
  title,
  secondTitle,
  subtitle,
  backFocus,
  color = '#353535',
  fontSize = '0.5rem',
  textAlign = 'start',
  margin = '0',
  animate = true,
  noClose = false
}: Props): JSX.Element | null => {
  if (showModal) {
    return (
      <Background animate={animate} backFocus={backFocus}>
        <Container
          animate={animate}
          padding={padding ? padding : ''}
          backgroundColor={backgroundColor}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          width={width}
          height={height}
        >
          {title !== '' ? (
            <ContainerTitle>
              <TieDiv>
                <Title
                  color={color}
                  fontSize={fontSize}
                  textAlign={textAlign}
                  margin={margin}
                >
                  {title}
                </Title>
              </TieDiv>
              <TieDiv>
                <SecondTitle
                  color={color}
                  fontSize={fontSize}
                  textAlign={textAlign}
                >
                  {secondTitle}
                </SecondTitle>
                {noClose ? null : close ? (
                  <IconButton
                    size={'sm'}
                    aria-label='Fechar'
                    variant={'ghost'}
                    color={'terciary.400'}
                    colorScheme={'terciary_hover'}
                    onClick={close}
                  >
                    <AiOutlineLeft size={18} />
                  </IconButton>
                ) : null}
              </TieDiv>
            </ContainerTitle>
          ) : null}
          <SubTitle color={color} fontSize={fontSize}>
            {subtitle}
          </SubTitle>
          {children}
        </Container>
      </Background>
    )
  } else {
    return null
  }
}

export default Modal
