export const validateFild = (
  obj: any,
  list: string[],
  mandatory?: (value: any) => void
) => {
  let result = {};
  for (const data of list) {
    //loop through keys array
    if (obj[data]) {
      Object.assign(result, { [data]: true });
    } else {
      Object.assign(result, { [data]: false });
    }
  }
  if (mandatory) {
    mandatory(result);
  }
  return Object.values(result).every(item => item === true);
};

export const validateThatReturn = (obj: any, list: string[]) => {
  let result = {};
  for (const data of list) {
    //loop through keys array
    if (obj[data]) {
      Object.assign(result, { [data]: true });
    } else {
      Object.assign(result, { [data]: false });
    }
  }
  return Object.values(result).includes(true);
};

export const onlyBase64File = (source?: string) => {
  if (!source) return undefined;

  if (source?.startsWith("http")) {
    return undefined;
  }
  const indexPrefixo = source.lastIndexOf('base64,');
  if (indexPrefixo >= 0) {
    source = source.substring(indexPrefixo + 7);
  }

  return source;
};

export const splitFileData = (source?: string) => {
  if (!source) return undefined;

  if (source?.startsWith("http")) {
    return undefined;
  }
  const indexPrefixo = source.lastIndexOf(';base64,');
  if (indexPrefixo >= 0) {
    return source.split('base64,');
  }
  return source;
};
