import usePagination from 'hooks/usePagination'

import { ReactComponent as IconEye } from 'assets/IconOpenEye.svg'
import { MdOutlineMarkEmailUnread } from 'react-icons/md'
import { ReactComponent as EditDataIcon } from 'assets/editDataIcon.svg'
import { ReactComponent as HistoryIcon } from 'assets/historyIcon.svg'
import { useCallback, useEffect, useRef, useState } from 'react'
import { UserModel, UserStatusEnum } from 'interfaces/user'
import { axiosAuth } from 'helpers/axiosInstances'
import { useDispatch } from 'react-redux'
import { isUnauthorized } from 'helpers/errors'
import { Redirect, useHistory } from 'react-router-dom'
import {
  setGlobalMessage,
  clearLoading,
  showLoading,
  setSuccessMessage
} from 'redux/actions'

import { useGlobals } from 'hooks/helpers/useGlobals'
import { Permissions } from 'interfaces/web/permission'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import {
  formatCNPJ,
  formatCPF,
  formatDate,
  statusClient,
  typeClient
} from 'helpers/formats'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import ObservationHistory from 'components/generic/ObservationHistory'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import AppModal from 'v2/components/shared/AppModal'
import UsersClient from '../users/UsersClient'
import { ReactComponent as DascamNavIcon2 } from 'assets/dascamNavIcon2.svg'
import { ReactComponent as NavIcon10 } from 'assets/navIcon10.svg'

import DataTable, { TableColumn } from 'react-data-table-component'

const Management: React.FC = () => {
  const pageLimit = 6
  const { hashData, redirect } = useHashData({
    type: 'JURIDICO,FISICO,ESTRANGEIRO',
    status: null
  })
  const toast = useToast()
  const { hasSomePermissions, hasPermissions } = useGlobals()
  const [showObservation, setShowObservation] = useState<boolean>(false)
  const [showUsersClient, setShowUsersClient] = useState<boolean>(false)
  const [itemObservation, setItemObservation] = useState<any>()
  const [itemCompanyId, setItemCompanyId] = useState<any>()

  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)

  const [dataSelect, setDataSelect] = useState<any>()

  const dispatch = useDispatch()
  const history = useHistory()
  const [
    currentPage,
    pages,
    nextPage,
    prevPage,
    setTotal,
    goToPage,
    totalPages
  ] = usePagination(pageLimit)
  const [users, setUsers] = useState<UserModel[]>([])
  const paginationComponentOptions = {
    rowsPerPageText: 'Usuários por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
  }
  const columns: TableColumn<UserModel>[] = [
    {
      name: 'ID',
      selector: (row) => row.id ?? '',
      omit: true
    },
    {
      name: 'Cliente',
      selector: (row) => row.user_name ?? '',
      width: '280px'
    },
    {
      name: 'Tipo',
      selector: (row) => row.cad_type ?? '---',
      format: (row) => typeClient(row.cad_type ?? '') ?? '---',
      width: '100px'
    },
    {
      name: 'Data de Abertura',
      selector: (row) => row.created_date ?? '---',
      format: (row) => formatDate(row.created_date ?? '') ?? '---',
      width: '140px'
    },
    {
      name: 'Documento',
      selector: (row) => row.document ?? '---',
      format: (row) =>
        row.cad_type === 'JURIDICO'
          ? formatCNPJ(row.document!)
          : row.cad_type === 'FISICO'
          ? formatCPF(row.document!)
          : row.document,
      width: '160px'
    },
    {
      name: 'Login',
      selector: (row) => row.login ?? '---',
      width: '180px'
    },
    {
      name: 'Status',
      selector: (row) => statusClient(row.status ?? '') ?? '---',
      cell (row, rowIndex, column, id) {
        return (
          <Badge
            variant='subtle'
            colorScheme={row.status === UserStatusEnum.ACTIVE ? 'green' : 'red'}
          >
            {statusClient(row.status ?? '') ?? '---'}
          </Badge>
        )
      }
    },
    {
      name: 'Portal do Fornecedor',
      width: '160px',
      selector: (row) =>
        row.permission_list?.find(
          (e: number) => e === Permissions.PROVIDER_ACCESS
        )
          ? 'Habilitado'
          : 'Desabilitado',
      cell (row, rowIndex, column, id) {
        return (
          <Badge
            variant='subtle'
            colorScheme={
              row.permission_list?.find(
                (e: number) => e === Permissions.PROVIDER_ACCESS
              )
                ? 'green'
                : 'orange'
            }
          >
            {row.permission_list?.find(
              (e: number) => e === Permissions.PROVIDER_ACCESS
            )
              ? 'Habilitado'
              : 'Desabilitado'}
          </Badge>
        )
      }
    },
    {
      name: 'AÇÕES',
      cell: (item) => (
        <Flex m={1} gap='2'>
          <IconButton
            aria-label='Visualizar'
            size={'sm'}
            variant={'outline'}
            colorScheme={'primary'}
            onClick={() =>
              item.cad_type === 'JURIDICO'
                ? history.push(
                    `/dascam/registration-management/reading/legal/${item.email}/${item.id}`
                  )
                : item.cad_type === 'FISICO'
                ? history.push(
                    `/dascam/registration-management/reading/individual/${item.email}/${item.id}`
                  )
                : history.push(
                    `/dascam/registration-management/reading/foreign/${item.email}/${item.id}`
                  )
            }
          >
            <IconEye stroke='primary' />
          </IconButton>

          {hasPermissions([Permissions.CADASTRO_EDIT]) &&
          item.responsible_sector === 'CADASTRO' ? (
            <IconButton
              aria-label='Editar'
              size={'sm'}
              variant={'outline'}
              colorScheme={'primary'}
              onClick={() =>
                item.cad_type === 'JURIDICO'
                  ? history.push(
                      `/dascam/registration-management/legal/${item.email}/${item.id}`
                    )
                  : item.cad_type === 'FISICO'
                  ? history.push(
                      `/dascam/registration-management/individual/${item.email}/${item.id}`
                    )
                  : history.push(
                      `/dascam/registration-management/foreign/${item.email}/${item.id}`
                    )
              }
            >
              <EditDataIcon stroke='primary' />
            </IconButton>
          ) : null}
          {hasPermissions([Permissions.COMPLIANCE_EDIT]) &&
          item.responsible_sector === 'COMPLIANCE' ? (
            <IconButton
              aria-label='Editar'
              size={'sm'}
              variant={'outline'}
              colorScheme={'primary'}
              onClick={() =>
                item.cad_type === 'JURIDICO'
                  ? history.push(
                      `/dascam/registration-management/legal/${item.email}/${item.id}`
                    )
                  : item.cad_type === 'FISICO'
                  ? history.push(
                      `/dascam/registration-management/individual/${item.email}/${item.id}`
                    )
                  : history.push(
                      `/dascam/registration-management/foreign/${item.email}/${item.id}`
                    )
              }
            >
              <EditDataIcon stroke='primary' />
            </IconButton>
          ) : null}
          {hasPermissions([Permissions.DIRETORIA_EDIT]) &&
          item.responsible_sector === 'DIRETORIA' ? (
            <IconButton
              aria-label='Editar'
              size={'sm'}
              variant={'outline'}
              colorScheme={'primary'}
              onClick={() =>
                item.cad_type === 'JURIDICO'
                  ? history.push(
                      `/dascam/registration-management/legal/${item.email}/${item.id}`
                    )
                  : item.cad_type === 'FISICO'
                  ? history.push(
                      `/dascam/registration-management/individual/${item.email}/${item.id}`
                    )
                  : history.push(
                      `/dascam/registration-management/foreign/${item.email}/${item.id}`
                    )
              }
            >
              <EditDataIcon stroke='primary' />
            </IconButton>
          ) : null}
          {hasPermissions([Permissions.CADASTRO_EDIT]) && (
            <IconButton
              aria-label='Notificações'
              size={'sm'}
              variant={'outline'}
              colorScheme={'primary'}
              onClick={() => {
                setDataSelect({
                  user_id: item.id,
                  emails: item.notifiables
                })
                onOpen()
              }}
            >
              <MdOutlineMarkEmailUnread size={25} />
            </IconButton>
          )}
          {hasPermissions([Permissions.CADASTRO_EDIT]) && (
            <IconButton
              aria-label='Fornecedores'
              size={'sm'}
              variant={'outline'}
              colorScheme={'primary'}
              onClick={() => {
                setDataSelect({
                  user_id: item.id,
                  perm: item.permission_list
                })
                onOpenProvider()
              }}
            >
              <Icon
                as={NavIcon10}
                stroke={'#0f4c86'}
                fill={'#0f4c86'}
                w={6}
                h={6}
              />
            </IconButton>
          )}
          <IconButton
            aria-label='Historico'
            size={'sm'}
            variant={'outline'}
            colorScheme={'primary'}
            onClick={() => {
              setItemObservation(item)
              setShowObservation(!showObservation)
            }}
          >
            <HistoryIcon stroke='primary' />
          </IconButton>
          {item.status === 'ACTIVE' ? (
            <IconButton
              aria-label='Usuários'
              size={'sm'}
              variant={'outline'}
              colorScheme={'primary'}
              onClick={() => {
                setItemCompanyId(item.id)
                setShowUsersClient(true)
              }}
            >
              <Icon as={DascamNavIcon2} w={6} h={6} />
            </IconButton>
          ) : null}
        </Flex>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      width: '225px',
      button: true
    }
  ]

  const getUserData = useCallback(
    async (page?) => {
      try {
        dispatch(
          showLoading({
            message: 'Carregando',
            isLoading: true
          })
        )
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }

        const { data } = await axiosAuth.get(
          `/filter-users?limit=${perPage}&offset=${
            ((page ?? currentPage) - 1) * perPage
          }&cad_type=${
            hashData?.type ? hashData?.type : 'JURIDICO,FISICO,ESTRANGEIRO'
          }${hashData?.status ? `&status=${hashData?.status}` : ''}`,
          config
        )

        setTotalRows(data.page.total)

        setTotal(data.page.total)
        setUsers(data.users)
      } catch (error: any) {
        if (isUnauthorized(error)) {
          history.push('/unauthorized')
        } else {
          dispatch(
            setGlobalMessage({
              message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}.`,
              type: 'ERROR'
            })
          )
        }
      } finally {
        dispatch(clearLoading())
      }
    },
    [currentPage, dispatch, hashData, history, setTotal]
  )
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(
        `/filter-users?limit=${newPerPage}&offset=${
          ((page ?? currentPage) - 1) * newPerPage
        }&cad_type=${
          hashData?.type ? hashData?.type : 'JURIDICO,FISICO,ESTRANGEIRO'
        }${hashData?.status ? `&status=${hashData?.status}` : ''}`,
        config
      )

      setTotalRows(data.page.total)
      setPerPage(newPerPage)
      setTotal(data.page.total)
      setUsers(data.users)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}.`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  const handlePageChange = (page: any) => {
    getUserData(page)
  }

  useEffect(() => {
    getUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, hashData])

  async function sendData () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )
      await axiosAuth
        .put(`user/notifiable`, {
          ...dataSelect
        })
        .then((res) => {
          getUserData()
        })
        .catch((e) => {})
    } catch (error) {
    } finally {
      onClose()
      dispatch(clearLoading())
    }
  }

  function alert () {
    return toast({
      title: 'Campos Obrigatórios!!',
      position: 'bottom',
      description:
        'Por favor, verifique o campo "Liberado para o Dia" e tente novamente.',
      status: 'info',
      duration: 3000,
      isClosable: true
    })
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  function AlertDialogConfirmation () {
    const cancelRef = useRef<HTMLButtonElement>(null)
    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                E-mails para notificação
              </AlertDialogHeader>

              <AlertDialogBody>
                Adicione/Altere abaixo a lista de e-mails para notificações de
                novos processos. Para adicionar mais de um email, basta
                separa-los com virgula(,).
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 1,
                      fields: [
                        {
                          type: 'textarea',
                          label: 'E-Mails',
                          name: 'emails'
                        }
                      ]
                    }
                  ]}
                  value={dataSelect}
                  onChange={(v) => {
                    setDataSelect(v)
                  }}
                />
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  colorScheme='red'
                  onClick={() => (!dataSelect?.emails ? alert() : sendData())}
                  ml={3}
                >
                  Salvar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  const {
    isOpen: isOpenProvider,
    onOpen: onOpenProvider,
    onClose: onCloseProvider
  } = useDisclosure()
  function AlertDialogProvider () {
    const cancelRef = useRef<HTMLButtonElement>(null)
    console.log(dataSelect)
    return (
      <>
        <AlertDialog
          isOpen={isOpenProvider}
          leastDestructiveRef={cancelRef}
          onClose={onCloseProvider}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Portal do Forncedor
              </AlertDialogHeader>

              <AlertDialogBody>
                Habilite ou desabilite o acesso ao portal do forncedor.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseProvider}>
                  Cancelar
                </Button>
                <Button
                  colorScheme='red'
                  onClick={async () => {
                    dataSelect?.perm?.find(
                      (e: number) => e === Permissions.PROVIDER_ACCESS
                    )
                      ? await disableProvider()
                      : await enableProvider()
                  }}
                  ml={3}
                >
                  {dataSelect?.perm?.find(
                    (e: number) => e === Permissions.PROVIDER_ACCESS
                  )
                    ? 'Desabilitar'
                    : 'Habilitar'}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  async function enableProvider () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const body = {
        permission_list: [
          ...(dataSelect?.perm ?? []),
          Permissions.PROVIDER_ACCESS
        ]
      }

      await axiosAuth.put(`/users/${dataSelect.user_id}`, body, config)

      dispatch(setSuccessMessage('Acesso ativado com sucesso!'))

      onCloseProvider()
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
      getUserData()
    }
  }

  async function disableProvider () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const newList = dataSelect?.perm?.filter(
        (e: number) => e !== Permissions.PROVIDER_ACCESS
      )
      const body = {
        permission_list: newList ?? []
      }

      await axiosAuth.put(`/users/${dataSelect.user_id}`, body, config)

      dispatch(setSuccessMessage('Acesso desativado com sucesso!'))

      onCloseProvider()
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
      getUserData()
    }
  }

  return (
    <>
      {AlertDialogConfirmation()}
      {AlertDialogProvider()}
      {!hasSomePermissions([
        Permissions.CADASTRO_VIEW,
        Permissions.COMPLIANCE_VIEW,
        Permissions.DIRETORIA_VIEW
      ]) && <Redirect to='/unauthorized' />}
      <CardPage title='Gerenciamento de cadastro de clientes'>
        <Col px={2} w={'100%'}>
          <SimpleFormGenerator
            rows={[
              {
                columns: 4,
                fields: [
                  {
                    type: 'select',
                    label: 'Tipo de Cliente',
                    name: 'type',
                    options: [
                      {
                        label: () => 'Todos',
                        value: 'JURIDICO,FISICO,ESTRANGEIRO'
                      },
                      {
                        label: () => 'Jurídico',
                        value: 'JURIDICO'
                      },
                      {
                        label: () => 'Físico',
                        value: 'FISICO'
                      },
                      {
                        label: () => 'Estrangeiro',
                        value: 'ESTRANGEIRO'
                      }
                    ]
                  },
                  {
                    type: 'select',
                    label: 'Status',
                    name: 'status',
                    options: [
                      {
                        label: () => 'Todos',
                        value: ''
                      },
                      {
                        label: () => 'Em Análise',
                        value: 'EVALUATION'
                      },
                      {
                        label: () => 'Pendente',
                        value: 'PENDING'
                      },
                      {
                        label: () => 'Aprovado',
                        value: 'ACTIVE'
                      },
                      {
                        label: () => 'Reprovado',
                        value: 'DISAPPROVED'
                      },
                      {
                        label: () => 'Bloqueado',
                        value: 'BLOCKED'
                      }
                    ]
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              const nextParams: any = { ...v }
              redirect(nextParams)
            }}
          />
        </Col>
        <Col px={4}>
          <Col
            mt={6}
            rounded={'lg'}
            overflow={'hidden'}
            border={'1px solid transparent'}
            borderColor={'primary.600'}
          >
            <DataTable
              noDataComponent=''
              keyField='ID'
              columns={columns}
              data={users}
              dense={true}
              fixedHeader={true}
              fixedHeaderScrollHeight={'400px'}
              striped={true}
              pagination={true}
              paginationServer={true}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationComponentOptions={paginationComponentOptions}
            />
          </Col>
        </Col>
      </CardPage>
      <ObservationHistory
        showModal={showObservation}
        close={() => setShowObservation(!showObservation)}
        userData={itemObservation}
      />
      <AppModal
        _modal={{ size: '6xl', scrollBehavior: 'inside' }}
        isOpen={showUsersClient}
        title={'Gestão de Usuários do Cliente'}
        onClose={() => {
          setShowUsersClient(!showUsersClient)
          setItemCompanyId(null)
        }}
      >
        {<UsersClient company_id={itemCompanyId} />}
      </AppModal>
    </>
  )
}

export default Management
