import styled from 'styled-components'
import {
  InputCheckBox,
  InputCNPJCPF,
  InputPercentage,
  InputSelect,
  InputText
} from 'components/generic/GenericInputs'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading,
  clearGlobalMessage
} from 'redux/actions'
import { axiosAuth } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { useHistory } from 'react-router-dom'
import {
  BeneficiaryModel,
  BeneficiaryPersonEnum,
  BeneficiaryTypeEnum
} from 'interfaces/beneficiary'
import Beneficiary from './Beneficiary'
import GenericButton from 'components/generic/GenericButton'
import { UserModel, UserStatusEnum } from 'interfaces/user'
import { Col } from 'v2/components/shared/Col'
import DataTable, { TableColumn } from 'react-data-table-component'
import { HStack, IconButton } from '@chakra-ui/react'
import { ReactComponent as EditDataIcon } from 'assets/editDataIcon.svg'
import { ReactComponent as ActiveIcon } from 'assets/activeIcon.svg'
import { ReactComponent as InactiveIcon } from 'assets/inactiveIcon.svg'
import { ReactComponent as DeleteIcon } from 'assets/trashIcon.svg'
import { v4 as uuidv4 } from 'uuid'
import { formatCNPJ, formatCPF } from 'helpers/formats'

const TableGrid = styled.div<{ noAction?: boolean }>`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.noAction
      ? '180px 200px 150px 100px 100px 100px 80px'
      : '180px 200px 150px 100px 100px 100px 80px auto'};
  grid-auto-rows: 35px;
  height: auto;
  width: auto;
  max-height: 300px;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

const DivItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`

const TitleItems = styled.p`
  margin: 0.1rem 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`

// const ButtonUnderline = styled.button`
//   padding: 1rem;
//   font-weight: 500;
//   font-size: 1rem;
//   line-height: 20px;
//   text-decoration-line: underline;
//   color: ${props => props.theme.color.primary_main};
//   background-color: transparent;
//   outline: none;
//   border: none;
//   cursor: pointer;
// `;

const Button = styled(GenericButton)<{
  width?: string
  isDisabled?: boolean
  isDeny?: boolean
}>`
  margin: 1rem 0;
  margin-right: 0.5rem;
  padding: 1rem;
  width: ${(props) => (props.width ? props.width : '260px')};
  color: ${(props) =>
    props.isDisabled
      ? props.theme.color.white1
      : props.isDeny
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.color.gray3
      : props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${(props) =>
      props.isDisabled
        ? props.theme.color.gray4
        : props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`

const ButtonModal = styled(GenericButton)<{
  width?: string
  isDisabled?: boolean
  isDeny?: boolean
}>`
  margin: 0.3rem 0;
  padding: 1rem;
  width: 100%;
  color: ${(props) =>
    props.isDisabled
      ? props.theme.color.white1
      : props.isDeny
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.color.gray3
      : props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${(props) =>
      props.isDisabled
        ? props.theme.color.gray4
        : props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`

const TieCheckBox = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonStatus = styled(GenericButton)<{ isActive?: boolean }>`
  width: 60px;
  height: 25px;
  padding: 0.1rem;
  border: 1px solid ${(props) => props.theme.color.white1};
  border-radius: 10px;
  background: ${(props) => props.theme.color.primary_dark};
  align-items: center;
  justify-content: ${(props) => (props.isActive ? 'flex-start' : 'flex-end')};
  margin-left: 0.3rem;

  &:hover {
    background: ${(props) => props.theme.color.primary_main};
  }

  & svg {
    width: 30px;
    height: auto;
  }
`

interface Props {
  userId: number | undefined
  beneficiarys: BeneficiaryModel[]
  getBeneficiaryData: () => void
  getUserData: () => void
  userData: UserModel
  setBeneficiarys: (value: BeneficiaryModel[]) => void
}

const PartnerBeneficiaryLegal = ({
  beneficiarys,
  getBeneficiaryData,
  userData,
  setBeneficiarys,
  getUserData
}: Props): JSX.Element => {
  // const user = useSelector((state: GlobalState) => state.user);
  const [editUser, setEditUser] = useState<boolean>(false)
  const [beneficiaryData, setBeneficiaryData] = useState<BeneficiaryModel>({
    type: '' as BeneficiaryTypeEnum,
    beneficiary_name: '',
    document: '',
    participation_percentage: '0',
    final_beneficiary: false,
    attorney: false,
    legal_representative: false
  })
  const [maxPercentBeneficiary, setMaxPercentBeneficiary] = useState<number>(0)
  const [disableB, setDisableB] = useState<boolean>(false)
  const [activeB, setActiveB] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const columns: TableColumn<BeneficiaryModel>[] = [
    {
      name: 'Tipo',
      selector: (row) => row.type ?? '',
      sortable: true,
      width: '160px'
    },
    {
      name: 'Sócios',
      selector: (row) => row.beneficiary_name ?? '',
      sortable: true,
      width: '350px'
    },
    {
      name: 'CNPJ/ CPF',
      selector: (row) => row.document ?? '',
      format: (row) =>
        row.document
          ? row.document.length > 11
            ? formatCNPJ(String(row.document))
            : formatCPF(String(row.document))
          : '---',
      sortable: true,
      width: '170px'
    },
    {
      name: 'B. Final',
      selector: (row) => (row.final_beneficiary ? 'Sim' : 'Não'),
      sortable: true,
      width: '110px'
    },
    {
      name: 'Procurador',
      selector: (row) => (row.attorney ? 'Sim' : 'Não'),
      sortable: true,
      width: '110px'
    },
    {
      name: 'R. Legal',
      selector: (row) => (row.legal_representative ? 'Sim' : 'Não'),
      sortable: true,
      width: '110px'
    },
    {
      name: 'Particip.',
      selector: (row) =>
        row.participation_percentage
          ? `${row.participation_percentage}%`
          : '0%',
      sortable: true,
      width: '110px'
    },
    {
      name: 'AÇÕES',
      cell: (row) => action(row),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      width: '120px'
    }
  ]

  function action (data: any) {
    return userData.status !== 'EVALUATION' ? (
      <HStack m={1}>
        <IconButton
          onClick={() => {
            if (
              !editUser &&
              removeToEdit &&
              setEditUser &&
              setBeneficiaryData
            ) {
              data.uuid = uuidv4()
              removeToEdit(data.id, data.uuid)
              setEditUser(!editUser)
              setBeneficiaryData(data)
            } else {
              dispatch(
                setGlobalMessage({
                  message: 'Finalize a edição do registro atual!',
                  type: 'WARN'
                })
              )
            }
          }}
          aria-label='Editar'
          title='Editar'
          size={'sm'}
          colorScheme={'secondary_main'}
        >
          <EditDataIcon />
        </IconButton>
        {userData.status !== UserStatusEnum.PENDING && (
          <ButtonStatus
            isActive={data.status === 'ACTIVE' ? true : false}
            type='button'
            onClick={() =>
              data.status === 'ACTIVE'
                ? setDisableB(!disableB)
                : setActiveB(!activeB)
            }
          >
            {data.status === 'ACTIVE' ? <ActiveIcon /> : <InactiveIcon />}
          </ButtonStatus>
        )}
      </HStack>
    ) : null
  }

  const handleSubmitBeneficiary = async () => {
    try {
      dispatch(clearGlobalMessage())
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      beneficiaryData.participation_percentage =
        beneficiaryData.participation_percentage ?? '0'
      const body = {
        user_id: userData.id,
        type: beneficiaryData.type,
        beneficiary_name: beneficiaryData.beneficiary_name,
        document: beneficiaryData.document,
        participation_percentage: beneficiaryData.participation_percentage,
        final_beneficiary: beneficiaryData.final_beneficiary,
        attorney: beneficiaryData.attorney,
        legal_representative: beneficiaryData.legal_representative,
        person:
          (beneficiaryData.document?.length || 0) <= 11
            ? BeneficiaryPersonEnum.FISICO
            : BeneficiaryPersonEnum.JURIDICO
      }

      const bodyUser = {
        status: 'PENDING',
        responsible_sector: 'CLIENTE',
        old_sector: 'CLIENTE'
      }

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      if (editUser) {
        const res = await axiosAuth.put(
          `/beneficiary/${beneficiaryData.id}`,
          body,
          config
        )
        dispatch(setSuccessMessage('Cadeia societária alterado com sucesso!'))
        if (res) {
          await axiosAuth.put(`/users/${userData.id}`, bodyUser, config)

          if (userData.id_syscambio && res) {
            const resBeneficiary = await axiosAuth.get(
              `/beneficiary/${userData.id}`,
              config
            )
            const resUser = await axiosAuth.get(`/users/${userData.id}`, config)
            if (resBeneficiary && resUser) {
              const body = {
                user: resUser.data,
                bank: [],
                beneficiary: resBeneficiary.data
              }
              await axiosAuth.put(`/user/update`, body, config)
            }
          }
        }
      } else {
        const res = await axiosAuth.post(`/beneficiary`, body, config)
        dispatch(setSuccessMessage('Cadeia societária adicionado com sucesso!'))
        if (res) {
          await axiosAuth.put(`/users/${userData.id}`, bodyUser, config)

          if (userData.id_syscambio && res) {
            const resBeneficiary = await axiosAuth.get(
              `/beneficiary/${userData.id}`,
              config
            )
            const resUser = await axiosAuth.get(`/users/${userData.id}`, config)
            if (resBeneficiary && resUser) {
              const body = {
                user: resUser.data,
                bank: [],
                beneficiary: resBeneficiary.data
              }
              await axiosAuth.put(`/user/update`, body, config)
            }
          }
        }
      }
      clearBeneficiary()
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
      getBeneficiaryData()
    }
  }

  const calculationBeneficiary = (data: BeneficiaryModel[]) => {
    const newPrice = data.map((i) => Number(i.participation_percentage))

    // console.log(newPrice);
    let priceAll = 0
    for (let x = 0; x < newPrice.length; x++) {
      priceAll += newPrice[x]
    }
    // console.log(priceAll);
    // setTotalApplied(priceAll);
    return 100 - priceAll
  }

  const removeToEdit = (index: number, uuid?: string) => {
    let res = [...beneficiarys]
    index
      ? res.splice(
          res.findIndex((e) => e.id === index),
          1
        )
      : res.splice(
          res.findIndex((e) => e.uuid === uuid),
          1
        )
    setBeneficiarys(res)
    setMaxPercentBeneficiary(calculationBeneficiary(res))
  }

  const handleSubmit = () => {
    dispatch(
      setGlobalMessage({
        message: `Ao alterar os dados referentes a cadeia societária, será necessário enviar o cadastro para uma nova avaliação. 
        Após você revisar todas as informações do Cadastro de Cliente, clique no botão "Enviar para Análise", disponível na Aba Pré-Cadastro.`,
        type: 'MESSAGE',
        buttons: (
          <>
            <ButtonModal onClick={() => handleSubmitBeneficiary()}>
              Confirmar
            </ButtonModal>
            <ButtonModal isDeny onClick={() => dispatch(clearGlobalMessage())}>
              Cancelar
            </ButtonModal>
          </>
        )
      })
    )
  }

  const clearBeneficiary = () => {
    setBeneficiaryData({
      ...beneficiaryData,
      type: '' as BeneficiaryTypeEnum,
      beneficiary_name: '',
      document: '',
      participation_percentage: '0',
      final_beneficiary: false,
      attorney: false,
      legal_representative: false
    })
    setEditUser(false)
  }

  useEffect(() => {
    setMaxPercentBeneficiary(calculationBeneficiary(beneficiarys))
    // console.log(beneficiaryData.final_beneficiary);
  }, [beneficiarys])

  useEffect(() => {
    getBeneficiaryData()
    // console.log(beneficiaryData.final_beneficiary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DivItems>
        <TitleItems>Representante(s)</TitleItems>
      </DivItems>
      {userData.status !== 'EVALUATION' ? (
        <DivItems>
          <ItemRow>
            <InputSelect
              label='Tipo de Representante'
              maxWidth='250px'
              value={beneficiaryData.type}
              changeValue={(value) =>
                setBeneficiaryData({
                  ...beneficiaryData,
                  type: value as BeneficiaryTypeEnum
                })
              }
            >
              <option value='' selected disabled></option>
              <option value='CONTATO'>Contato</option>
              <option value='SOCIO / DIRETOR'>Socio / Diretor</option>
              {/*<option value='DIRETOR'>Diretor</option>*/}
              <option value='ADMINISTRADOR'>Administrador</option>
              <option value='PROCURADOR'>Procurador</option>
            </InputSelect>
            <InputText
              label='Razão social/nome'
              maxWidth='350px'
              value={beneficiaryData.beneficiary_name}
              changeValue={(value) =>
                setBeneficiaryData({
                  ...beneficiaryData,
                  beneficiary_name: value
                })
              }
            />
            <InputCNPJCPF
              label='CNPJ / CPF (sócio)'
              maxWidth='250px'
              value={beneficiaryData.document}
              changeValue={(value) =>
                setBeneficiaryData({
                  ...beneficiaryData,
                  document: value
                })
              }
            />
          </ItemRow>
          <ItemRow>
            <TieCheckBox>
              <InputCheckBox
                id='final_beneficiary'
                name='partners'
                label='Beneficiário final'
                valueBoolean={beneficiaryData.final_beneficiary}
                changeValueBoolean={(value) =>
                  setBeneficiaryData({
                    ...beneficiaryData,
                    final_beneficiary: value
                  })
                }
              />
              <InputCheckBox
                id='attorney'
                name='partners'
                label='Procurador'
                valueBoolean={beneficiaryData.attorney}
                changeValueBoolean={(value) =>
                  setBeneficiaryData({
                    ...beneficiaryData,
                    attorney: value
                  })
                }
              />
              <InputCheckBox
                id='legal_representative'
                name='partners'
                label='Representante Legal'
                valueBoolean={beneficiaryData.legal_representative}
                changeValueBoolean={(value) =>
                  setBeneficiaryData({
                    ...beneficiaryData,
                    legal_representative: value
                  })
                }
              />
            </TieCheckBox>
            <InputPercentage
              label='Porcentagem de Participação'
              maxWidth='100px'
              maxPercent={maxPercentBeneficiary}
              value={String(beneficiaryData.participation_percentage)}
              changeValue={(value) =>
                setBeneficiaryData({
                  ...beneficiaryData,
                  participation_percentage: value
                })
              }
            />
          </ItemRow>
          <ItemRow>
            {editUser ? (
              <Button
                isDeny
                type='button'
                width='180px'
                onClick={() => {
                  getBeneficiaryData()
                  clearBeneficiary()
                  setEditUser(!editUser)
                }}
              >
                Cancelar
              </Button>
            ) : null}
            <Button
              isDisabled={
                beneficiaryData.beneficiary_name !== '' &&
                beneficiaryData.type !== ('' as BeneficiaryTypeEnum) &&
                beneficiaryData.document !== '' /*&&
                beneficiaryData.participation_percentage !== ''*/
                  ? false
                  : true
              }
              type='button'
              width='220px'
              onClick={() =>
                beneficiaryData.beneficiary_name !== '' &&
                beneficiaryData.type !== ('' as BeneficiaryTypeEnum) &&
                beneficiaryData.document !== '' /*&&
                beneficiaryData.participation_percentage !== ''*/
                  ? userData.status === 'ACTIVE'
                    ? handleSubmit()
                    : handleSubmitBeneficiary()
                  : null
              }
            >
              {editUser ? 'Alterar' : '+ Adicionar Representante'}
            </Button>
          </ItemRow>
        </DivItems>
      ) : null}
      <DivItems>
        <Col
          rounded={'lg'}
          overflow={'auto'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
        >
          <DataTable
            noDataComponent=''
            columns={columns}
            data={beneficiarys}
            dense={true}
            fixedHeader={true}
            fixedHeaderScrollHeight={'350px'}
            striped={true}
          />
        </Col>
      </DivItems>
    </>
  )
}

export default PartnerBeneficiaryLegal
