import styled from "styled-components";
import GenericButton from "components/generic/GenericButton";
import { Fragment, useState } from "react";
import { ReactComponent as FileIcon } from "assets/fileIcon.svg";
import { ReactComponent as EditDataIcon } from "assets/editDataIcon.svg";
import { ReactComponent as TrashIcon } from "assets/trashIcon.svg";
import { ReactComponent as PreviewIcon } from "assets/IconOpenEye.svg";
import { valueConvert, valueReconvert } from "helpers/moedas";
import { OperationDocumentModel } from "interfaces/operation_document";
import { formatGame } from "helpers/formats";
import { useDispatch } from "react-redux";
import { setGlobalMessage } from "redux/actions";
import { SetModel } from "interfaces/set_model";
import ReadOperation from "./ReadOperation";

const ColumnText = styled.div<{ background?: boolean }>`
  border-right: 1px solid ${props => props.theme.color.white5};
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.white5};
  padding: 0 0.5rem;
  background: ${props =>
    props.background ? props.theme.color.white1 : props.theme.color.white2};
`;

const Text = styled.p<{ Color?: string; fontWeight?: string }>`
  font-size: ${props => props.theme.font.small};
  color: ${props => (props.Color ? props.Color : props.theme.color.text3)};
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;
`;

const DivButton = styled.div<{ borderRadius?: boolean; background?: boolean }>`
  border-right: 1px solid ${props => props.theme.color.white5};
  background: ${props =>
    props.background
      ? props.theme.color.primary_main
      : props.theme.color.primary_light};
  border-bottom: 1px solid ${props => props.theme.color.white5};
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: ${props => (props.borderRadius ? "0 0 10px 0" : "")};
`;

const ButtonEdit = styled(GenericButton)`
  width: 30px;
  height: 30px;
  padding: 0.3rem;
  background: ${props => props.theme.color.secondary_main};
  justify-content: center;
  align-items: center;
  transform: scale(0.95);
  margin: 0 0.1rem;

  &:hover {
    background: ${props => props.theme.color.secondary_light};
    transform: scale(1);
  }

  & svg {
    width: 100%;
    height: 100%;
    /* fill: ${props => props.theme.color.primary_main}; */
  }
`;

type Tabs = "Game" | "Money-Order" | "Invoice" | "Knowledge";
interface Props {
  last: boolean;
  index: number;
  document: OperationDocumentModel;
  handleUnItem: (
    uuid: string,
    set_number: number,
    set_id: number,
    id?: number,
    fn?: () => void
  ) => void;
  gameSetList: SetModel[];
  setGameSetList: (value: SetModel[]) => void;
  typeOperation: string;
  lastGame: number;
  currentTab: Tabs;
  setCurrentTab: (value: Tabs) => void;
  operationList: OperationDocumentModel[];
  isReadOperation: boolean;
  getUserData: () => void;
  totalValue: number;
}

const Document = ({
  last,
  index,
  document,
  handleUnItem,
  gameSetList,
  setGameSetList,
  typeOperation,
  lastGame,
  currentTab,
  setCurrentTab,
  operationList,
  isReadOperation,
  getUserData,
  totalValue,
}: Props): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const dispatch = useDispatch();

  const [showOperationModal, setShowOperationModal] = useState<boolean>(false);
  const [showOperationRead, setShowOperationRead] = useState<boolean>(false);

  const base64toBlob = (base64Data: string, type: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data.replace(`data:${type};base64,`, ""));
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {
      type: type,
    });
  };

  const openFile = (data: string, type: string) => {
    let blob = base64toBlob(data, type);
    if (
      (window.navigator as any) &&
      (window.navigator as any).msSaveOrOpenBlob
    ) {
      (window.navigator as any).msSaveOrOpenBlob(blob, `${Date.now()}`);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  };

  return (
    <>
      <Fragment key={document.id}>
        <ColumnText background={isEven(index)}>
          <Text title={formatGame(Number(document.set_number))}>
            {formatGame(Number(document.set_number))}
          </Text>
        </ColumnText>
        <ColumnText background={isEven(index)}>
          <Text title={document.set_reference}>{document.set_reference}</Text>
        </ColumnText>
        <ColumnText background={isEven(index)}>
          <Text
            title={
              document.set_value !== undefined
                ? valueConvert(
                    Number(valueReconvert(String(document.set_value), "ANY")),
                    "ANY"
                  )
                : "0,00"
            }
          >
            {document.set_value !== undefined
              ? valueConvert(
                  Number(valueReconvert(String(document.set_value), "ANY")),
                  "ANY"
                )
              : "0,00"}
          </Text>
        </ColumnText>
        <ColumnText background={isEven(index)}>
          <Text title={document.document_type}>{document.document_type}</Text>
        </ColumnText>
        <ColumnText background={isEven(index)}>
          <Text title={document.document_number}>
            {document.document_number}
          </Text>
        </ColumnText>
        <ColumnText background={isEven(index)}>
          <Text
            title={
              document.applied_value !== undefined
                ? valueConvert(
                    Number(
                      valueReconvert(String(document.applied_value), "ANY")
                    ),
                    "ANY"
                  )
                : "0,00"
            }
          >
            {document.applied_value !== undefined
              ? valueConvert(
                  Number(valueReconvert(String(document.applied_value), "ANY")),
                  "ANY"
                )
              : "0,00"}
          </Text>
        </ColumnText>
        <DivButton background={isEven(index)}>
          <ButtonEdit
            type="button"
            onClick={() => {
              if (
                document.attached_file?.includes(`https`) &&
                document.attached_file !== undefined &&
                document.attached_file !== null &&
                document.attached_file !== ""
              ) {
                window.open(String(document.attached_file), "_blank")!.focus();
              } else if (
                document.attached_file !== undefined &&
                document.attached_file !== null &&
                document.attached_file !== ""
              ) {
                openFile(
                  String(document.attached_file),
                  String(document.attached)
                );
              } else {
                dispatch(
                  setGlobalMessage({
                    message: "Não há anexo!",
                    type: "WARN",
                  })
                );
              }
            }}
          >
            <FileIcon />
          </ButtonEdit>
        </DivButton>
        <DivButton borderRadius={last} background={isEven(index)}>
          <ButtonEdit
            type="button"
            onClick={() => setShowOperationRead(!showOperationRead)}
          >
            <PreviewIcon />
          </ButtonEdit>
          {isReadOperation !== true ? (
            <>
              <ButtonEdit
                type="button"
                onClick={() => {
                  if (document.document_type === "FATURA") {
                    setCurrentTab("Invoice");
                  } else if (document.document_type === "CONHECIMENTO") {
                    setCurrentTab("Knowledge");
                  } else if (document.document_type === "ORDEM DE PAGAMENTO") {
                    setCurrentTab("Money-Order");
                  } else {
                    setCurrentTab("Game");
                  }
                  setShowOperationModal(!showOperationModal);
                }}
              >
                <EditDataIcon />
              </ButtonEdit>
              <ButtonEdit
                type="button"
                onClick={() => {
                  if (document.id !== undefined) {
                    handleUnItem(
                      String(document.uu_id),
                      Number(document.set_number),
                      Number(document.set_id),
                      Number(document.id)
                    );
                  } else {
                    handleUnItem(
                      String(document.uu_id),
                      Number(document.set_number),
                      Number(document.set_id)
                    );
                  }
                }}
              >
                <TrashIcon />
              </ButtonEdit>
            </>
          ) : null}
        </DivButton>
      </Fragment>
      <ReadOperation
        showModal={showOperationRead}
        handleModal={() => setShowOperationRead(!showOperationRead)}
        gameSetList={gameSetList}
        typeOperation={typeOperation}
        lastGame={lastGame}
        setCurrentTab={setCurrentTab}
        readValue={document}
      />
    </>
  );
};

export default Document;
