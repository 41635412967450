export interface FormsModel {
  id?: string;
  attached?: string;
  attached_file?: string;
  attached_name?: string;
  form_type?: FormsTypeEnum;
  created_date?: string;
  created_by_id?: string;
  updated_date?: string;
  updated_by_id?: string;
}

export enum FormsTypeEnum {
  FISICO = "FISICO",
  JURIDICO = "JURIDICO",
  ESTRANGEIRO = "ESTRANGEIRO",
}
