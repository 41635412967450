import { devices } from "helpers/devices";
import styled from "styled-components";
import building from "assets/buildingImg.png";

const WhoWeAreStyled = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: 100vh;
  min-height: 600px;
  max-height: 600px;
  width: 100vw;
  max-width: 100%;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media ${devices.phoneOnly} {
    padding: 0.3rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${props => props.theme.margins.from_sides};
  }
`;

const DivImg = styled.div`
  width: 400px;
  min-width: 400px;
  height: 100%;
  position: relative;
`;

const DivText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
`;

const Title = styled.p`
  padding: 0.5rem;
  width: 100%;
  font-family: "Montserrat";
  font-size: 2.2rem;
  line-height: 44px;
  text-transform: uppercase;
  color: ${props => props.theme.color.gray2};
  font-weight: bold;
`;

const Text = styled.p`
  padding: 0.5rem;
  width: 100%;
  /* font-family: "Work Sans"; */
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.02em;
  color: ${props => props.theme.color.gray1};
`;

const ImgContainer = styled.div`
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
`;

const Img = styled.img`
  width: auto;
  height: 100%;
  object-fit: fill;
`;

const WhoWeAre = (): JSX.Element => {
  return (
    <WhoWeAreStyled id="4">
      <Container>
        <DivText>
          <Title>Quem somos</Title>
          <Text>
            Investimento no potencial humano, com valorização do diferencial da sua equipe de profissionais,
            além de visão de longo prazo e foco na qualidade dos serviços prestados, são alguns dos principais
            fatores que fizeram com que a DASCAM conquistasse uma posição privilegiada no seu mercado de atuação,
            sempre trazendo soluções inovadoras para seus clientes. A DASCAM tem uma longa tradição na assessoria e
            consultoria no Mercado de Câmbio, com destaque para as soluções de projetos de alta complexidade que
            requerem conhecimento técnico e experiência, conduzindo essas soluções com absoluto respeito às normas
            vigentes e buscando eficiência e segurança aos nossos clientes.
            <br /><br />
            Para o acompanhamento contínuo da regulamentação que rege o Mercado de Câmbio, Capitais Internacionais,
            Comércio Exterior e da frenética diversificação do mercado financeiro, a DASCAM mantém uma equipe de
            profissionais competentes e atualizados a fim de proporcionar aos nossos clientes um atendimento
            diferenciado e de alto padrão.
            <br /><br />
            As equipes de profissionais da DASCAM são formadas por colaboradores que detêm conhecimento técnico e
            estratégico para oferecer aos nossos clientes a forma mais segura e eficiente de realizar suas operações com o exterior.
            <br /><br />
            E para assegurar a qualidade da prestação dos serviços, a DASCAM investe constantemente em sua infraestrutura
            tecnológica e de comunicação, utilizando softwares de última geração, disponíveis ao acesso de todos os seus colaboradores.
          </Text>
        </DivText>
        <DivImg>
          <ImgContainer>
            <Img src={building} alt="Imagem de Edificio" />
          </ImgContainer>
        </DivImg>
      </Container>
    </WhoWeAreStyled>
  );
};

export default WhoWeAre;
