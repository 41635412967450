import React from "react";
import SimpleFormGenerator from "v2/components/shared/SimpleFormGenerator";
import { FormDocumentProps } from "../contracts";

const FormDocumentAPOLICE: React.FC<FormDocumentProps> = ({
  onChange,
  value,
  onRequestDocumentNumberBalance,
}) => {
  return (
    <>
      <SimpleFormGenerator
        onChange={onChange}
        value={value}
        rows={[
          {
            columns: 4,
            fields: [
              {
                name: "files",
                label: "Arquivo",
                type: "files",
                extensions: ["pdf"],
              },
              {
                name: "document_number",
                label: "Número",
                type: "text",
                onBlur: onRequestDocumentNumberBalance,
              },
              { name: "reference", label: "Referência", type: "text" },
              { name: "emitted_at", label: "Emissão", type: "text" },
            ],
          },
          {
            columns: 2,
            fields: [
              { name: "company", label: "Companhia", type: "text" },
              { name: "endosso", label: "Endosso", type: "text" },
            ],
          },
        ]}
      />
    </>
  );
};

export default FormDocumentAPOLICE;
