import { SimpleGrid } from '@chakra-ui/react'
import React, { FormEvent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { AppModalPickerProps } from '../AppModalPicker'
import { Col } from '../Col'
import { SimpleMenuItem } from '../SimpleMenu'
import SimpleField from './src/SimpleField'
import get from 'lodash.get'

export type RowProps = {
  columns?: number
  fields: ColumnFieldProps[]
}

export type FieldProps<
  Name extends ColumnFieldProps['type'],
  Value = any,
  OnChangeValue = any
> = {
  value?: Value
  onChange?: (value: OnChangeValue) => void
  mask?: (value: any) => any
  isReadonly?: boolean
  infos: ColumnFieldByName[Name]
  maxLength?: number
}

export type ColumnFieldProps =
  | FormTextFieldProps
  | FormSelectFieldProps
  | FormNumberFieldProps
  | FormCurrencyFieldProps
  | FormRateFieldProps
  | FormFilesFieldProps
  | FormDateFieldProps
  | FormSearchPickerFieldProps
  | FormTextAreaFieldProps
  | FormHeadingFieldProps

export type ColumnFieldByName = {
  select: FormSelectFieldProps
  text: FormTextFieldProps
  number: FormNumberFieldProps
  currency: FormCurrencyFieldProps
  rate: FormRateFieldProps
  files: FormFilesFieldProps
  date: FormDateFieldProps
  'search-picker': FormSearchPickerFieldProps
  textarea: FormTextAreaFieldProps
  heading: FormHeadingFieldProps
}

export interface FormFieldProps {
  name: string
  label: string
  readOnly?: boolean
  description?: string
  placeholder?: string
  validation?: (value: any) => boolean | undefined
}
export interface FormSelectFieldProps extends FormFieldProps {
  type: 'select'
  options?: SimpleMenuItem[]
}
export interface FormTextFieldProps extends FormFieldProps {
  type: 'text'
  onBlur?: () => void
  mask?: (data: any) => any
  maxLength?: number
}
export interface FormDateFieldProps extends FormFieldProps {
  type: 'date'
}
export interface FormNumberFieldProps extends FormFieldProps {
  type: 'number'
}
export interface FormCurrencyFieldProps extends FormFieldProps {
  type: 'currency'
}
export interface FormRateFieldProps extends FormFieldProps {
  type: 'rate'
}
export interface FormSearchPickerFieldProps extends FormFieldProps {
  type: 'search-picker'
  onSearch: () => Promise<Partial<AppModalPickerProps>>
  labelKey: string
}
export interface FormFilesFieldProps extends FormFieldProps {
  type: 'files'
  maxFiles?: number
  types?: string[]
  extensions?: string[]
  viewMode?: boolean
}
export interface FormTextAreaFieldProps extends FormFieldProps {
  type: 'textarea'
  mask?: (data: any) => any
}
export interface FormHeadingFieldProps extends FormFieldProps {
  type: 'heading'
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
}

interface SimpleFormGeneratorProps {
  rows: RowProps[]
  onChange?: (data: any) => void
  onSubmit?: (data: any) => void
  value: any
  readOnly?: boolean
  maxLength?: number
}

const SimpleFormGenerator: React.NextFC<SimpleFormGeneratorProps> = ({
  rows,
  value,
  onChange,
  onSubmit,
  readOnly,
  children,
  maxLength
}) => {
  const form = useForm()
  const formData = form.watch()?.form || {}

  const isReadonly = (field: ColumnFieldProps) => {
    if (readOnly !== undefined) return readOnly
    return field.readOnly
  }

  const getValue = (field: ColumnFieldProps) => {
    let nextValue = get(formData, field.name)
    if (nextValue === undefined || nextValue === 'undefined') {
      return undefined
    }

    if (nextValue === null || nextValue === 'null') {
      return null
    }

    if ((field as FormTextFieldProps).mask !== undefined && field.readOnly) {
      nextValue = (field as FormTextFieldProps).mask?.(nextValue)
    }

    return nextValue
  }

  const onSubmitForm = () => {
    const data = form.getValues()?.form || {}
    return onSubmit?.(data)
  }

  const setFieldValue = (field: ColumnFieldProps, value: any) => {
    form.setValue(`form.${field.name}`, value)
    onChange?.(form.getValues()?.form || {})
  }

  useEffect(() => {
    if (value) {
      const nextValue = { ...value }

      form.setValue('form', nextValue)
    }
  }, [value, form])

  return (
    <form
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmitForm()
      }}
    >
      <Col>
        {rows.map((row, keyRow) => (
          <SimpleGrid
            mb={5}
            key={`rowForm${keyRow}`}
            gap={2}
            columns={row.columns}
          >
            {row.fields.map((field, keyField) => {
              return (
                <React.Fragment key={`fieldForm${keyRow}${keyField}`}>
                  <SimpleField
                    field={field}
                    value={getValue(field)}
                    isReadonly={isReadonly(field)}
                    onChange={(value) => {
                      setFieldValue(field, value)
                    }}
                  />
                </React.Fragment>
              )
            })}
          </SimpleGrid>
        ))}
      </Col>
      {children}
    </form>
  )
}

export default SimpleFormGenerator
