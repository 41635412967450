import { FormControl, FormLabel, Input, SimpleGrid } from "@chakra-ui/react";
import { Col } from "components/generic/helpers";
import React, { ChangeEvent, useEffect, useState } from "react";
import useDebounce from "v2/hooks/helpers/useDebounce";

type TextField = {
  type: "text";
  label: string;
  key: string;
  placeholder?: string;
};

type SelectField = {
  type: "select";
  label: string;
  key: string;
  options: Array<{
    label: string;
    value: string;
  }>;
};

export type SearchRow = {
  fields: Array<TextField | SelectField>;
  columns?: number;
};

interface SearchFormGeneratorProps {
  rows: Array<SearchRow>;
  value: any;
  onChange?: (value: any) => void;
  onSearch?: (value: any) => void;
}

const SearchFormGenerator: React.FC<SearchFormGeneratorProps> = ({
  rows,
  value,
  onChange,
  onSearch,
}) => {
  const [formValue, setFormValue] = useState(value);
  const searchValue = useDebounce(formValue, 500);

  const getFieldValue = (key: string) => {
    return formValue[key];
  };

  const setFieldValue = (key: string, value: any) => {
    const nextValue = {
      ...formValue,
      [key]: value,
    };

    setFormValue(() => nextValue);
    onChange?.(nextValue);
  };

  useEffect(() => {
    onSearch?.(searchValue);
  }, [searchValue]);

  return (
    <Col>
      {rows.map((row, keyRow) => (
        <SimpleGrid
          gap={row.columns}
          columns={row.columns}
          key={`row${keyRow}`}
        >
          {row.fields.map((field, keyField) => (
            <Col key={`field${keyRow}${keyField}`}>
              <FormControl>
                <FormLabel>{field.label}</FormLabel>
                {field.type === "text" && (
                  <Input
                    rounded={"sm"}
                    placeholder={field.placeholder}
                    value={getFieldValue(field.key)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(field.key, e.target.value);
                    }}
                    onBlur={() => {
                      onSearch?.(formValue);
                    }}
                  />
                )}
              </FormControl>
            </Col>
          ))}
        </SimpleGrid>
      ))}
    </Col>
  );
};

export default SearchFormGenerator;
