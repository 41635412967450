/* const numberToReal = (value: number) => {
    let number = value.toFixed(2).split(".");
    number[0] = "R$ " + number[0].split(/(?=(?:...)*$)/).join(".");
    return number.join(",");
};

const numberToDolar = (value: number) => {
    let number = value.toFixed(2).split(".");
    number[0] = "US$ " + number[0].split(/(?=(?:...)*$)/).join(",");
    return number.join(".");
};

const numberToEuro = (value: number) => {
    let number = value.toFixed(2).split(".");
    number[0] = "€ " + number[0].split(/(?=(?:...)*$)/).join(".");
    return number.join(",");
}; */

type CurrencyType = "BRL" | "USD" | "EUR" | "ANY";

export const valueBothConvert = (value: string, currency: CurrencyType) => {
  switch (currency) {
    case "BRL":
      return valueConvert(Number(valueReconvert(value, "BRL")), "BRL");
    case "USD":
      return valueConvert(Number(valueReconvert(value, "USD")), "USD");
    case "EUR":
      return valueConvert(Number(valueReconvert(value, "EUR")), "EUR");
    case "ANY":
      return valueConvert(Number(valueReconvert(value, "ANY")), "ANY");
    default:
      throw Error(`Tipo de moeda: ${currency} não encontrado no sistema.`);
  }
};

export const valueConvert = (value: number, currency: CurrencyType) => {
  let number = value ? value.toFixed(2).split(".") : ["0", "00"];

  switch (currency) {
    case "BRL":
      number[0] = "R$ " + number[0].split(/(?=(?:...)*$)/).join(".");
      return number.join(",");
    case "USD":
      number[0] = "US$ " + number[0].split(/(?=(?:...)*$)/).join(",");
      return number.join(".");
    case "EUR":
      number[0] = "€ " + number[0].split(/(?=(?:...)*$)/).join(".");
      return number.join(",");
    case "ANY":
      number[0] = number[0].split(/(?=(?:...)*$)/).join(".");
      return number.join(",");
    default:
      throw Error(`Tipo de moeda: ${currency} não encontrado no sistema.`);
  }
};

export const valueReconvert = (value: string, currency: CurrencyType) => {
  switch (currency) {
    case "BRL":
      return value.replace(/[^0-9,]/g, "").replace(",", ".");
    case "USD":
      return value.replace(/[^0-9.]/g, "").replace(".", ",");
    case "EUR":
      return value.replace(/[^0-9,]/g, "").replace(",", ".");
    case "ANY":
      return value.replace(/[^0-9,]/g, "").replace(",", ".");
    default:
      throw Error(`Tipo de moeda: ${currency} não encontrado no sistema.`);
  }
};

export const toCurrency = (value: string | number | undefined) => {
  if (!value) return `0,00`;

  return valueConvert(Number(valueReconvert(String(value), "ANY")), "ANY");
};

export const getMoeda = (cod: Number) => {
  const list = [
    {
      "Codigo": 5,
      "Nome": "AFEGANE AFEGANIST",
      "CodigoISO": "AFN",
      "Categoria": "A"
    },
    {
      "Codigo": 9,
      "Nome": "BIRR/ETIOPIA ",
      "CodigoISO": "ETB",
      "Categoria": "A"
    },
    {
      "Codigo": 10,
      "Nome": "AUSTRAL      ",
      "CodigoISO": "ARG",
      "Categoria": "A"
    },
    {
      "Codigo": 15,
      "Nome": "BATH/TAILANDIA   ",
      "CodigoISO": "THB",
      "Categoria": "A"
    },
    {
      "Codigo": 20,
      "Nome": "BALBOA/PANAMA",
      "CodigoISO": "PAB",
      "Categoria": "A"
    },
    {
      "Codigo": 25,
      "Nome": "BOLIVAR/VENZUELA ",
      "CodigoISO": "VEB",
      "Categoria": "B"
    },
    {
      "Codigo": 26,
      "Nome": "BOLIVAR VEN",
      "CodigoISO": "VEF",
      "Categoria": "B"
    },
    {
      "Codigo": 30,
      "Nome": "BOLIVIANO/BOLIVIA",
      "CodigoISO": "BOB",
      "Categoria": "A"
    },
    {
      "Codigo": 35,
      "Nome": "CEDI GANA",
      "CodigoISO": "GHS",
      "Categoria": "A"
    },
    {
      "Codigo": 40,
      "Nome": "COLON/COSTA RICA ",
      "CodigoISO": "CRC",
      "Categoria": "A"
    },
    {
      "Codigo": 45,
      "Nome": "COLON/EL SALVADOR",
      "CodigoISO": "SVC",
      "Categoria": "A"
    },
    {
      "Codigo": 51,
      "Nome": "CORDOBA OURO ",
      "CodigoISO": "NIO",
      "Categoria": "A"
    },
    {
      "Codigo": 55,
      "Nome": "COROA DINAMARQUESA",
      "CodigoISO": "DKK",
      "Categoria": "A"
    },
    {
      "Codigo": 57,
      "Nome": "COROA ESTONIA",
      "CodigoISO": "EEK",
      "Categoria": "A"
    },
    {
      "Codigo": 58,
      "Nome": "COROA ESLOVACA   ",
      "CodigoISO": "SKK",
      "Categoria": "A"
    },
    {
      "Codigo": 60,
      "Nome": "COROA ISLND/ISLAN",
      "CodigoISO": "ISK",
      "Categoria": "A"
    },
    {
      "Codigo": 65,
      "Nome": "COROA NORUEGUESA",
      "CodigoISO": "NOK",
      "Categoria": "A"
    },
    {
      "Codigo": 70,
      "Nome": "COROA SUECA",
      "CodigoISO": "SEK",
      "Categoria": "A"
    },
    {
      "Codigo": 75,
      "Nome": "COROA TCHECA ",
      "CodigoISO": "CZK",
      "Categoria": "B"
    },
    {
      "Codigo": 79,
      "Nome": "CRUZADO      ",
      "CodigoISO": "CZ$",
      "Categoria": "A"
    },
    {
      "Codigo": 80,
      "Nome": "CRUZEIRO     ",
      "CodigoISO": "CR$",
      "Categoria": "A"
    },
    {
      "Codigo": 83,
      "Nome": "CRUZEIRO     ",
      "CodigoISO": "CR$",
      "Categoria": "A"
    },
    {
      "Codigo": 85,
      "Nome": "CRUZEIRO REAL",
      "CodigoISO": "CR$",
      "Categoria": "A"
    },
    {
      "Codigo": 90,
      "Nome": "DALASI/GAMBIA",
      "CodigoISO": "GMD",
      "Categoria": "A"
    },
    {
      "Codigo": 95,
      "Nome": "DINAR ARGELINO   ",
      "CodigoISO": "DZD",
      "Categoria": "A"
    },
    {
      "Codigo": 100,
      "Nome": "DINAR/KWAIT  ",
      "CodigoISO": "KWD",
      "Categoria": "A"
    },
    {
      "Codigo": 105,
      "Nome": "DINAR/BAHREIN",
      "CodigoISO": "BHD",
      "Categoria": "A"
    },
    {
      "Codigo": 115,
      "Nome": "DINAR/IRAQUE ",
      "CodigoISO": "IQD",
      "Categoria": "A"
    },
    {
      "Codigo": 120,
      "Nome": "DINAR IUGOSLAVO",
      "CodigoISO": "DIN",
      "Categoria": "B"
    },
    {
      "Codigo": 125,
      "Nome": "DINAR/JORDANIA   ",
      "CodigoISO": "JOD",
      "Categoria": "A"
    },
    {
      "Codigo": 130,
      "Nome": "DINAR/LIBIA  ",
      "CodigoISO": "LYD",
      "Categoria": "A"
    },
    {
      "Codigo": 132,
      "Nome": "DINAR/MACEDONIA  ",
      "CodigoISO": "MKD",
      "Categoria": "A"
    },
    {
      "Codigo": 133,
      "Nome": "DINAR SERVIO SERVIA",
      "CodigoISO": "RSD",
      "Categoria": "A"
    },
    {
      "Codigo": 134,
      "Nome": "NOVA LIBRA SUDANESA",
      "CodigoISO": "SDG",
      "Categoria": "B"
    },
    {
      "Codigo": 135,
      "Nome": "DINAR/TUNISIA",
      "CodigoISO": "TND",
      "Categoria": "B"
    },
    {
      "Codigo": 136,
      "Nome": "LIBRA SUL SUDANESA",
      "CodigoISO": "SSP",
      "Categoria": "B"
    },
    {
      "Codigo": 139,
      "Nome": "DIRHAM/MARROCOS  ",
      "CodigoISO": "MAD",
      "Categoria": "A"
    },
    {
      "Codigo": 145,
      "Nome": "DIRHAM/EMIR.ARABE",
      "CodigoISO": "AED",
      "Categoria": "A"
    },
    {
      "Codigo": 148,
      "Nome": "DOBRA S TOME PRIN",
      "CodigoISO": "STD",
      "Categoria": "A"
    },
    {
      "Codigo": 150,
      "Nome": "DOLAR AUSTRALIANO",
      "CodigoISO": "AUD",
      "Categoria": "B"
    },
    {
      "Codigo": 155,
      "Nome": "DOLAR/BAHAMAS",
      "CodigoISO": "BSD",
      "Categoria": "A"
    },
    {
      "Codigo": 160,
      "Nome": "DOLAR/BERMUDAS   ",
      "CodigoISO": "BMD",
      "Categoria": "A"
    },
    {
      "Codigo": 165,
      "Nome": "DOLAR CANADENSE",
      "CodigoISO": "CAD",
      "Categoria": "A"
    },
    {
      "Codigo": 170,
      "Nome": "DOLAR DA GUIANA  ",
      "CodigoISO": "GYD",
      "Categoria": "A"
    },
    {
      "Codigo": 173,
      "Nome": "DOLAR DA NAMIBIA",
      "CodigoISO": "NAD",
      "Categoria": "A"
    },
    {
      "Codigo": 175,
      "Nome": "DOLAR/BARBADOS   ",
      "CodigoISO": "BBD",
      "Categoria": "A"
    },
    {
      "Codigo": 180,
      "Nome": "DOLAR/BELIZE ",
      "CodigoISO": "BZD",
      "Categoria": "B"
    },
    {
      "Codigo": 185,
      "Nome": "DOLAR BRUNEI",
      "CodigoISO": "BND",
      "Categoria": "A"
    },
    {
      "Codigo": 190,
      "Nome": "DOLAR CAYMAN",
      "CodigoISO": "KYD",
      "Categoria": "A"
    },
    {
      "Codigo": 195,
      "Nome": "DOLAR DE CINGAPURA",
      "CodigoISO": "SGD",
      "Categoria": "A"
    },
    {
      "Codigo": 197,
      "Nome": "UNIDADE DE FOMENTO DO CHILE",
      "CodigoISO": "CLF",
      "Categoria": "A"
    },
    {
      "Codigo": 200,
      "Nome": "DOLAR FIJI",
      "CodigoISO": "FJD",
      "Categoria": "A"
    },
    {
      "Codigo": 205,
      "Nome": "DOLAR DE HONG KONG",
      "CodigoISO": "HKD",
      "Categoria": "A"
    },
    {
      "Codigo": 210,
      "Nome": "DOLAR/TRIN. TOBAG",
      "CodigoISO": "TTD",
      "Categoria": "B"
    },
    {
      "Codigo": 215,
      "Nome": "DOLAR CARIBE ORIENTAL",
      "CodigoISO": "XCD",
      "Categoria": "A"
    },
    {
      "Codigo": 217,
      "Nome": "DOLAR ZIMBABUE",
      "CodigoISO": "ZWL",
      "Categoria": "B"
    },
    {
      "Codigo": 220,
      "Nome": "DOLAR DOS ESTADOS UNIDOS",
      "CodigoISO": "USD",
      "Categoria": "A"
    },
    {
      "Codigo": 230,
      "Nome": "DOLAR/JAMAICA",
      "CodigoISO": "JMD",
      "Categoria": "A"
    },
    {
      "Codigo": 235,
      "Nome": "DOLAR LIBERIA",
      "CodigoISO": "LRD",
      "Categoria": "A"
    },
    {
      "Codigo": 240,
      "Nome": "DOLAR MALAIO ",
      "CodigoISO": "M$ ",
      "Categoria": "A"
    },
    {
      "Codigo": 245,
      "Nome": "DOLAR NOVA ZELANDIA",
      "CodigoISO": "NZD",
      "Categoria": "B"
    },
    {
      "Codigo": 250,
      "Nome": "DOLAR IL SALOMAO",
      "CodigoISO": "SBD",
      "Categoria": "A"
    },
    {
      "Codigo": 255,
      "Nome": "DOLAR/SURINAME   ",
      "CodigoISO": "SRD",
      "Categoria": "B"
    },
    {
      "Codigo": 260,
      "Nome": "DONGUE/VIETNAN   ",
      "CodigoISO": "VND",
      "Categoria": "B"
    },
    {
      "Codigo": 270,
      "Nome": "DRACMA/GRECIA",
      "CodigoISO": "GRD",
      "Categoria": "A"
    },
    {
      "Codigo": 275,
      "Nome": "DRAM ARMENIA REP",
      "CodigoISO": "AMD",
      "Categoria": "A"
    },
    {
      "Codigo": 295,
      "Nome": "ESCUDO CABO VERDE",
      "CodigoISO": "CVE",
      "Categoria": "A"
    },
    {
      "Codigo": 315,
      "Nome": "ESCUDO PORTUGUES",
      "CodigoISO": "PTE",
      "Categoria": "A"
    },
    {
      "Codigo": 320,
      "Nome": "ESCUDO TIMOR LESTE",
      "CodigoISO": "TPE",
      "Categoria": "B"
    },
    {
      "Codigo": 325,
      "Nome": "GUILDER ANTILHAS HOLANDESAS",
      "CodigoISO": "ANG",
      "Categoria": "A"
    },
    {
      "Codigo": 328,
      "Nome": "FLORIM/ARUBA ",
      "CodigoISO": "AWG",
      "Categoria": "A"
    },
    {
      "Codigo": 330,
      "Nome": "FLORIM/SURINAME  ",
      "CodigoISO": "SRG",
      "Categoria": "A"
    },
    {
      "Codigo": 333,
      "Nome": "DOLAR/SURINAME   ",
      "CodigoISO": "SRD",
      "Categoria": "B"
    },
    {
      "Codigo": 335,
      "Nome": "FLORIM HOLANDES  ",
      "CodigoISO": "NLG",
      "Categoria": "A"
    },
    {
      "Codigo": 345,
      "Nome": "FORINT/HUNGRIA   ",
      "CodigoISO": "HUF",
      "Categoria": "A"
    },
    {
      "Codigo": 360,
      "Nome": "FRANCO BELGA/BELG",
      "CodigoISO": "BEF",
      "Categoria": "A"
    },
    {
      "Codigo": 363,
      "Nome": "FRANCO CONGOLES",
      "CodigoISO": "CDF",
      "Categoria": "A"
    },
    {
      "Codigo": 365,
      "Nome": "FRANCO/BURUNDI   ",
      "CodigoISO": "BIF",
      "Categoria": "A"
    },
    {
      "Codigo": 368,
      "Nome": "FRANCO/COMORES   ",
      "CodigoISO": "KMF",
      "Categoria": "A"
    },
    {
      "Codigo": 370,
      "Nome": "FRANCO CFA BEAC",
      "CodigoISO": "XAF",
      "Categoria": "A"
    },
    {
      "Codigo": 372,
      "Nome": "FRANCO CFA BCEAO",
      "CodigoISO": "XOF",
      "Categoria": "A"
    },
    {
      "Codigo": 380,
      "Nome": "FRANCO CFP",
      "CodigoISO": "XPF",
      "Categoria": "A"
    },
    {
      "Codigo": 390,
      "Nome": "FRANCO/DJIBUTI   ",
      "CodigoISO": "DJF",
      "Categoria": "A"
    },
    {
      "Codigo": 395,
      "Nome": "FRANCO FRANCES   ",
      "CodigoISO": "FRF",
      "Categoria": "A"
    },
    {
      "Codigo": 398,
      "Nome": "FRANCO/GUINE ",
      "CodigoISO": "GNF",
      "Categoria": "A"
    },
    {
      "Codigo": 400,
      "Nome": "FRANCO LUXEMBURGO",
      "CodigoISO": "LUF",
      "Categoria": "A"
    },
    {
      "Codigo": 405,
      "Nome": "FRANCO MALGAXE MADAGA",
      "CodigoISO": "MGF",
      "Categoria": "A"
    },
    {
      "Codigo": 406,
      "Nome": "ARIARY MADAGASCAR",
      "CodigoISO": "MGA",
      "Categoria": "A"
    },
    {
      "Codigo": 420,
      "Nome": "FRANCO/RUANDA",
      "CodigoISO": "RWF",
      "Categoria": "B"
    },
    {
      "Codigo": 425,
      "Nome": "FRANCO SUICO",
      "CodigoISO": "CHF",
      "Categoria": "A"
    },
    {
      "Codigo": 440,
      "Nome": "GOURDE/HAITI ",
      "CodigoISO": "HTG",
      "Categoria": "A"
    },
    {
      "Codigo": 450,
      "Nome": "GUARANI/PARAGUAI",
      "CodigoISO": "PYG",
      "Categoria": "A"
    },
    {
      "Codigo": 460,
      "Nome": "HRYVNIA UCRANIA",
      "CodigoISO": "UAH",
      "Categoria": "B"
    },
    {
      "Codigo": 470,
      "Nome": "IENE",
      "CodigoISO": "JPY",
      "Categoria": "A"
    },
    {
      "Codigo": 480,
      "Nome": "INTI PERUANO ",
      "CodigoISO": "I  ",
      "Categoria": "A"
    },
    {
      "Codigo": 482,
      "Nome": "LARI GEORGIA",
      "CodigoISO": "GEL",
      "Categoria": "A"
    },
    {
      "Codigo": 485,
      "Nome": "LAT LETONIA REP",
      "CodigoISO": "LVL",
      "Categoria": "A"
    },
    {
      "Codigo": 490,
      "Nome": "LEK ALBANIA REP",
      "CodigoISO": "ALL",
      "Categoria": "A"
    },
    {
      "Codigo": 495,
      "Nome": "LEMPIRA/HONDURAS ",
      "CodigoISO": "HNL",
      "Categoria": "A"
    },
    {
      "Codigo": 500,
      "Nome": "LEONE/SERRA LEOA ",
      "CodigoISO": "SLL",
      "Categoria": "A"
    },
    {
      "Codigo": 503,
      "Nome": "LEU/MOLDAVIA, REP",
      "CodigoISO": "MDL",
      "Categoria": "A"
    },
    {
      "Codigo": 505,
      "Nome": "LEU/ROMENIA  ",
      "CodigoISO": "ROL",
      "Categoria": "A"
    },
    {
      "Codigo": 506,
      "Nome": "NOVO LEU/ROMENIA ",
      "CodigoISO": "RON",
      "Categoria": "A"
    },
    {
      "Codigo": 510,
      "Nome": "LEV/BULGARIA, REP",
      "CodigoISO": "BGN",
      "Categoria": "A"
    },
    {
      "Codigo": 520,
      "Nome": "LIBRA CIP/CHIPRE ",
      "CodigoISO": "CYP",
      "Categoria": "B"
    },
    {
      "Codigo": 530,
      "Nome": "LIBRA/GIBRALTAR  ",
      "CodigoISO": "GIP",
      "Categoria": "A"
    },
    {
      "Codigo": 535,
      "Nome": "LIBRA/EGITO  ",
      "CodigoISO": "EGP",
      "Categoria": "A"
    },
    {
      "Codigo": 540,
      "Nome": "LIBRA ESTERLINA",
      "CodigoISO": "GBP",
      "Categoria": "B"
    },
    {
      "Codigo": 545,
      "Nome": "LIBRA/FALKLAND   ",
      "CodigoISO": "FKP",
      "Categoria": "A"
    },
    {
      "Codigo": 550,
      "Nome": "LIBRA/IRLANDA",
      "CodigoISO": "IEP",
      "Categoria": "A"
    },
    {
      "Codigo": 560,
      "Nome": "LIBRA/LIBANO ",
      "CodigoISO": "LBP",
      "Categoria": "A"
    },
    {
      "Codigo": 565,
      "Nome": "LIRA/MALTA   ",
      "CodigoISO": "MTL",
      "Categoria": "B"
    },
    {
      "Codigo": 570,
      "Nome": "LIBRA/STA HELENA ",
      "CodigoISO": "SHP",
      "Categoria": "A"
    },
    {
      "Codigo": 575,
      "Nome": "LIBRA/SIRIA, REP ",
      "CodigoISO": "SYP",
      "Categoria": "A"
    },
    {
      "Codigo": 580,
      "Nome": "LIBRA SUDANESA   ",
      "CodigoISO": "LSD",
      "Categoria": "A"
    },
    {
      "Codigo": 585,
      "Nome": "LILANGENI/SUAZIL ",
      "CodigoISO": "SZL",
      "Categoria": "B"
    },
    {
      "Codigo": 595,
      "Nome": "LIRA ITALIANA",
      "CodigoISO": "ITL",
      "Categoria": "A"
    },
    {
      "Codigo": 600,
      "Nome": "LIRA TURQUIA",
      "CodigoISO": "TRL",
      "Categoria": "B"
    },
    {
      "Codigo": 601,
      "Nome": "LITA LITUANIA",
      "CodigoISO": "LTL",
      "Categoria": "A"
    },
    {
      "Codigo": 603,
      "Nome": "LOTI/LESOTO  ",
      "CodigoISO": "LSL",
      "Categoria": "B"
    },
    {
      "Codigo": 607,
      "Nome": "MANAT ARZEBAIJAO",
      "CodigoISO": "AZN",
      "Categoria": "A"
    },
    {
      "Codigo": 608,
      "Nome": "NOVO MANAT TURCOM",
      "CodigoISO": "TMT",
      "Categoria": "B"
    },
    {
      "Codigo": 610,
      "Nome": "MARCO ALEMAO ",
      "CodigoISO": "DEM",
      "Categoria": "A"
    },
    {
      "Codigo": 612,
      "Nome": "MARCO CONV BOSNIA",
      "CodigoISO": "BAM",
      "Categoria": "A"
    },
    {
      "Codigo": 615,
      "Nome": "MARCO FINLANDES",
      "CodigoISO": "FIM",
      "Categoria": "B"
    },
    {
      "Codigo": 620,
      "Nome": "METICAL MOCAMBIQ",
      "CodigoISO": "MZM",
      "Categoria": "A"
    },
    {
      "Codigo": 622,
      "Nome": "NOVA METICAL/MOCA",
      "CodigoISO": "MZN",
      "Categoria": "B"
    },
    {
      "Codigo": 625,
      "Nome": "NAKFA ERITREIA",
      "CodigoISO": "ERN",
      "Categoria": "B"
    },
    {
      "Codigo": 630,
      "Nome": "NAIRA/NIGERIA",
      "CodigoISO": "NGN",
      "Categoria": "A"
    },
    {
      "Codigo": 635,
      "Nome": "KWANZA/ANGOLA",
      "CodigoISO": "AOA",
      "Categoria": "A"
    },
    {
      "Codigo": 637,
      "Nome": "NOVO DINAR IUGOSLAVO",
      "CodigoISO": "YUM",
      "Categoria": "A"
    },
    {
      "Codigo": 640,
      "Nome": "NOVO DOLAR/TAIWAN",
      "CodigoISO": "TWD",
      "Categoria": "A"
    },
    {
      "Codigo": 642,
      "Nome": "LIRA TURCA",
      "CodigoISO": "TRY",
      "Categoria": "B"
    },
    {
      "Codigo": 660,
      "Nome": "NOVOSOL/PERU",
      "CodigoISO": "PEN",
      "Categoria": "A"
    },
    {
      "Codigo": 665,
      "Nome": "NGULTRUM/BUTAO   ",
      "CodigoISO": "BTN",
      "Categoria": "B"
    },
    {
      "Codigo": 670,
      "Nome": "UGUIA MAURITANIA",
      "CodigoISO": "MRO",
      "Categoria": "A"
    },
    {
      "Codigo": 680,
      "Nome": "PAANGA/TONGA ",
      "CodigoISO": "TOP",
      "Categoria": "B"
    },
    {
      "Codigo": 685,
      "Nome": "PATACA/MACAU ",
      "CodigoISO": "MOP",
      "Categoria": "A"
    },
    {
      "Codigo": 690,
      "Nome": "PESETA/ANDORA",
      "CodigoISO": "ADP",
      "Categoria": "A"
    },
    {
      "Codigo": 700,
      "Nome": "PESETA ESPANHOLA ",
      "CodigoISO": "ESP",
      "Categoria": "A"
    },
    {
      "Codigo": 706,
      "Nome": "PESO ARGENTINO",
      "CodigoISO": "ARS",
      "Categoria": "A"
    },
    {
      "Codigo": 710,
      "Nome": "PESO BOLIVIANO   ",
      "CodigoISO": "$B ",
      "Categoria": "A"
    },
    {
      "Codigo": 715,
      "Nome": "PESO CHILE",
      "CodigoISO": "CLP",
      "Categoria": "A"
    },
    {
      "Codigo": 720,
      "Nome": "PESO/COLOMBIA",
      "CodigoISO": "COP",
      "Categoria": "A"
    },
    {
      "Codigo": 725,
      "Nome": "PESO/CUBA    ",
      "CodigoISO": "CUP",
      "Categoria": "B"
    },
    {
      "Codigo": 730,
      "Nome": "PESO/REP. DOMINIC",
      "CodigoISO": "DOP",
      "Categoria": "A"
    },
    {
      "Codigo": 735,
      "Nome": "PESO/FILIPINAS   ",
      "CodigoISO": "PHP",
      "Categoria": "A"
    },
    {
      "Codigo": 738,
      "Nome": "PESO/GUINE BISSAU",
      "CodigoISO": "GWP",
      "Categoria": "A"
    },
    {
      "Codigo": 741,
      "Nome": "PESO MEXICO",
      "CodigoISO": "MXN",
      "Categoria": "A"
    },
    {
      "Codigo": 745,
      "Nome": "PESO/URUGUAIO",
      "CodigoISO": "UYU",
      "Categoria": "A"
    },
    {
      "Codigo": 755,
      "Nome": "PULA/BOTSWANA",
      "CodigoISO": "BWP",
      "Categoria": "A"
    },
    {
      "Codigo": 760,
      "Nome": "QUACHA/MALAVI",
      "CodigoISO": "MWK",
      "Categoria": "B"
    },
    {
      "Codigo": 765,
      "Nome": "QUACHA ZAMBIA",
      "CodigoISO": "ZMK",
      "Categoria": "B"
    },
    {
      "Codigo": 766,
      "Nome": "QUACHA ZAMBIA",
      "CodigoISO": "ZMW",
      "Categoria": "B"
    },
    {
      "Codigo": 770,
      "Nome": "QUETZAL/GUATEMALA",
      "CodigoISO": "GTQ",
      "Categoria": "A"
    },
    {
      "Codigo": 775,
      "Nome": "QUIATE/BIRMANIA  ",
      "CodigoISO": "MMK",
      "Categoria": "A"
    },
    {
      "Codigo": 778,
      "Nome": "KINA/PAPUA N GUIN",
      "CodigoISO": "PGK",
      "Categoria": "A"
    },
    {
      "Codigo": 779,
      "Nome": "KUNA/CROACIA ",
      "CodigoISO": "HRK",
      "Categoria": "B"
    },
    {
      "Codigo": 780,
      "Nome": "QUIPE/LAOS, REP  ",
      "CodigoISO": "LAK",
      "Categoria": "A"
    },
    {
      "Codigo": 785,
      "Nome": "RANDE/AFRICASUL",
      "CodigoISO": "ZAR",
      "Categoria": "A"
    },
    {
      "Codigo": 790,
      "Nome": "REAL",
      "CodigoISO": "BRL",
      "Categoria": "A"
    },
    {
      "Codigo": 795,
      "Nome": "IUAN CHINA",
      "CodigoISO": "CNY",
      "Categoria": "A"
    },
    {
      "Codigo": 796,
      "Nome": "RENMINBI HONG KONG",
      "CodigoISO": "CNH",
      "Categoria": "B"
    },
    {
      "Codigo": 800,
      "Nome": "RIAL/CATAR   ",
      "CodigoISO": "QAR",
      "Categoria": "A"
    },
    {
      "Codigo": 805,
      "Nome": "RIAL/OMA     ",
      "CodigoISO": "OMR",
      "Categoria": "A"
    },
    {
      "Codigo": 810,
      "Nome": "RIAL/IEMEN   ",
      "CodigoISO": "YER",
      "Categoria": "A"
    },
    {
      "Codigo": 815,
      "Nome": "RIAL/IRAN, REP   ",
      "CodigoISO": "IRR",
      "Categoria": "A"
    },
    {
      "Codigo": 820,
      "Nome": "RIAL/ARAB SAUDITA",
      "CodigoISO": "SAR",
      "Categoria": "A"
    },
    {
      "Codigo": 825,
      "Nome": "RIEL/CAMBOJA ",
      "CodigoISO": "KHR",
      "Categoria": "A"
    },
    {
      "Codigo": 828,
      "Nome": "RINGGIT/MALASIA",
      "CodigoISO": "MYR",
      "Categoria": "A"
    },
    {
      "Codigo": 829,
      "Nome": "RUBLO BELARUS",
      "CodigoISO": "BYR",
      "Categoria": "A"
    },
    {
      "Codigo": 830,
      "Nome": "RUBLO/RUSSIA ",
      "CodigoISO": "RUB",
      "Categoria": "A"
    },
    {
      "Codigo": 831,
      "Nome": "RUBLO BELARUS",
      "CodigoISO": "BYN",
      "Categoria": "A"
    },
    {
      "Codigo": 835,
      "Nome": "SOMONI TADJIQUISTAO",
      "CodigoISO": "TJS",
      "Categoria": "A"
    },
    {
      "Codigo": 840,
      "Nome": "RUPIA/MAURICIO   ",
      "CodigoISO": "MUR",
      "Categoria": "A"
    },
    {
      "Codigo": 845,
      "Nome": "RUPIA/NEPAL  ",
      "CodigoISO": "NPR",
      "Categoria": "A"
    },
    {
      "Codigo": 850,
      "Nome": "RUPIA/SEYCHELES  ",
      "CodigoISO": "SCR",
      "Categoria": "A"
    },
    {
      "Codigo": 855,
      "Nome": "RUPIA/SRI LANKA  ",
      "CodigoISO": "LKR",
      "Categoria": "A"
    },
    {
      "Codigo": 860,
      "Nome": "RUPIA/INDIA  ",
      "CodigoISO": "INR",
      "Categoria": "A"
    },
    {
      "Codigo": 865,
      "Nome": "RUPIA/INDONESIA  ",
      "CodigoISO": "IDR",
      "Categoria": "A"
    },
    {
      "Codigo": 870,
      "Nome": "RUFIA/MALDIVAS   ",
      "CodigoISO": "MVR",
      "Categoria": "A"
    },
    {
      "Codigo": 875,
      "Nome": "RUPIA/PAQUISTAO  ",
      "CodigoISO": "PKR",
      "Categoria": "A"
    },
    {
      "Codigo": 880,
      "Nome": "SHEKEL/ISRAEL",
      "CodigoISO": "ILS",
      "Categoria": "A"
    },
    {
      "Codigo": 890,
      "Nome": "SOL PERUANO  ",
      "CodigoISO": "S/.",
      "Categoria": "A"
    },
    {
      "Codigo": 892,
      "Nome": "SOM QUIRGUISTAO",
      "CodigoISO": "KGS",
      "Categoria": "A"
    },
    {
      "Codigo": 893,
      "Nome": "SOM UZBEQUISTAO",
      "CodigoISO": "UZS",
      "Categoria": "B"
    },
    {
      "Codigo": 895,
      "Nome": "SUCRE EQUADOR",
      "CodigoISO": "ECS",
      "Categoria": "A"
    },
    {
      "Codigo": 905,
      "Nome": "TACA/BANGLADESH  ",
      "CodigoISO": "BDT",
      "Categoria": "A"
    },
    {
      "Codigo": 910,
      "Nome": "TALA         ",
      "CodigoISO": "WS$",
      "Categoria": "A"
    },
    {
      "Codigo": 911,
      "Nome": "TALA SAMOA OC",
      "CodigoISO": "WST",
      "Categoria": "A"
    },
    {
      "Codigo": 912,
      "Nome": "TALA SAMOA",
      "CodigoISO": "WST",
      "Categoria": "A"
    },
    {
      "Codigo": 913,
      "Nome": "TENGE CAZAQUISTAO",
      "CodigoISO": "KZT",
      "Categoria": "A"
    },
    {
      "Codigo": 914,
      "Nome": "TOLAR/ESLOVENIA  ",
      "CodigoISO": "SIT",
      "Categoria": "A"
    },
    {
      "Codigo": 915,
      "Nome": "TUGRIK/MONGOLIA  ",
      "CodigoISO": "MNT",
      "Categoria": "A"
    },
    {
      "Codigo": 916,
      "Nome": "UNID FOMENTO CHIL",
      "CodigoISO": "CLF",
      "Categoria": "A"
    },
    {
      "Codigo": 920,
      "Nome": "VATU VANUATU",
      "CodigoISO": "VUV",
      "Categoria": "B"
    },
    {
      "Codigo": 925,
      "Nome": "WON/COREIA NORTE ",
      "CodigoISO": "KPW",
      "Categoria": "A"
    },
    {
      "Codigo": 930,
      "Nome": "WON COREIA SUL",
      "CodigoISO": "KRW",
      "Categoria": "A"
    },
    {
      "Codigo": 940,
      "Nome": "XELIM AUSTRIACO  ",
      "CodigoISO": "ATS",
      "Categoria": "A"
    },
    {
      "Codigo": 946,
      "Nome": "XELIM/TANZANIA   ",
      "CodigoISO": "TZS",
      "Categoria": "B"
    },
    {
      "Codigo": 950,
      "Nome": "XELIM/QUENIA ",
      "CodigoISO": "KES",
      "Categoria": "A"
    },
    {
      "Codigo": 955,
      "Nome": "XELIM/UGANDA ",
      "CodigoISO": "UGX",
      "Categoria": "B"
    },
    {
      "Codigo": 960,
      "Nome": "XELIM/SOMALIA",
      "CodigoISO": "SOS",
      "Categoria": "B"
    },
    {
      "Codigo": 970,
      "Nome": "NOVO ZAIRE  ZAIRE",
      "CodigoISO": "ZRN",
      "Categoria": "A"
    },
    {
      "Codigo": 971,
      "Nome": "NOVO ZAIRE/ZAIRE ",
      "CodigoISO": "ZRN",
      "Categoria": "A"
    },
    {
      "Codigo": 975,
      "Nome": "ZLOTY/POLONIA",
      "CodigoISO": "PLN",
      "Categoria": "A"
    },
    {
      "Codigo": 978,
      "Nome": "EURO",
      "CodigoISO": "EUR",
      "Categoria": "B"
    },
    {
      "Codigo": 995,
      "Nome": "BUA          ",
      "CodigoISO": "BUA",
      "Categoria": "A"
    },
    {
      "Codigo": 996,
      "Nome": "FUA          ",
      "CodigoISO": "FUA",
      "Categoria": "A"
    }
  ]
  return list.filter(e => e.Codigo === cod)[0]?.CodigoISO ?? ''

};

export const getMoedaCompleto = (cod: Number) => {
  const list = [
    {
      "Codigo": 5,
      "Nome": "AFEGANE AFEGANIST",
      "CodigoISO": "AFN",
      "Categoria": "A"
    },
    {
      "Codigo": 9,
      "Nome": "BIRR/ETIOPIA ",
      "CodigoISO": "ETB",
      "Categoria": "A"
    },
    {
      "Codigo": 10,
      "Nome": "AUSTRAL      ",
      "CodigoISO": "ARG",
      "Categoria": "A"
    },
    {
      "Codigo": 15,
      "Nome": "BATH/TAILANDIA   ",
      "CodigoISO": "THB",
      "Categoria": "A"
    },
    {
      "Codigo": 20,
      "Nome": "BALBOA/PANAMA",
      "CodigoISO": "PAB",
      "Categoria": "A"
    },
    {
      "Codigo": 25,
      "Nome": "BOLIVAR/VENZUELA ",
      "CodigoISO": "VEB",
      "Categoria": "B"
    },
    {
      "Codigo": 26,
      "Nome": "BOLIVAR VEN",
      "CodigoISO": "VEF",
      "Categoria": "B"
    },
    {
      "Codigo": 30,
      "Nome": "BOLIVIANO/BOLIVIA",
      "CodigoISO": "BOB",
      "Categoria": "A"
    },
    {
      "Codigo": 35,
      "Nome": "CEDI GANA",
      "CodigoISO": "GHS",
      "Categoria": "A"
    },
    {
      "Codigo": 40,
      "Nome": "COLON/COSTA RICA ",
      "CodigoISO": "CRC",
      "Categoria": "A"
    },
    {
      "Codigo": 45,
      "Nome": "COLON/EL SALVADOR",
      "CodigoISO": "SVC",
      "Categoria": "A"
    },
    {
      "Codigo": 51,
      "Nome": "CORDOBA OURO ",
      "CodigoISO": "NIO",
      "Categoria": "A"
    },
    {
      "Codigo": 55,
      "Nome": "COROA DINAMARQUESA",
      "CodigoISO": "DKK",
      "Categoria": "A"
    },
    {
      "Codigo": 57,
      "Nome": "COROA ESTONIA",
      "CodigoISO": "EEK",
      "Categoria": "A"
    },
    {
      "Codigo": 58,
      "Nome": "COROA ESLOVACA   ",
      "CodigoISO": "SKK",
      "Categoria": "A"
    },
    {
      "Codigo": 60,
      "Nome": "COROA ISLND/ISLAN",
      "CodigoISO": "ISK",
      "Categoria": "A"
    },
    {
      "Codigo": 65,
      "Nome": "COROA NORUEGUESA",
      "CodigoISO": "NOK",
      "Categoria": "A"
    },
    {
      "Codigo": 70,
      "Nome": "COROA SUECA",
      "CodigoISO": "SEK",
      "Categoria": "A"
    },
    {
      "Codigo": 75,
      "Nome": "COROA TCHECA ",
      "CodigoISO": "CZK",
      "Categoria": "B"
    },
    {
      "Codigo": 79,
      "Nome": "CRUZADO      ",
      "CodigoISO": "CZ$",
      "Categoria": "A"
    },
    {
      "Codigo": 80,
      "Nome": "CRUZEIRO     ",
      "CodigoISO": "CR$",
      "Categoria": "A"
    },
    {
      "Codigo": 83,
      "Nome": "CRUZEIRO     ",
      "CodigoISO": "CR$",
      "Categoria": "A"
    },
    {
      "Codigo": 85,
      "Nome": "CRUZEIRO REAL",
      "CodigoISO": "CR$",
      "Categoria": "A"
    },
    {
      "Codigo": 90,
      "Nome": "DALASI/GAMBIA",
      "CodigoISO": "GMD",
      "Categoria": "A"
    },
    {
      "Codigo": 95,
      "Nome": "DINAR ARGELINO   ",
      "CodigoISO": "DZD",
      "Categoria": "A"
    },
    {
      "Codigo": 100,
      "Nome": "DINAR/KWAIT  ",
      "CodigoISO": "KWD",
      "Categoria": "A"
    },
    {
      "Codigo": 105,
      "Nome": "DINAR/BAHREIN",
      "CodigoISO": "BHD",
      "Categoria": "A"
    },
    {
      "Codigo": 115,
      "Nome": "DINAR/IRAQUE ",
      "CodigoISO": "IQD",
      "Categoria": "A"
    },
    {
      "Codigo": 120,
      "Nome": "DINAR IUGOSLAVO",
      "CodigoISO": "DIN",
      "Categoria": "B"
    },
    {
      "Codigo": 125,
      "Nome": "DINAR/JORDANIA   ",
      "CodigoISO": "JOD",
      "Categoria": "A"
    },
    {
      "Codigo": 130,
      "Nome": "DINAR/LIBIA  ",
      "CodigoISO": "LYD",
      "Categoria": "A"
    },
    {
      "Codigo": 132,
      "Nome": "DINAR/MACEDONIA  ",
      "CodigoISO": "MKD",
      "Categoria": "A"
    },
    {
      "Codigo": 133,
      "Nome": "DINAR SERVIO SERVIA",
      "CodigoISO": "RSD",
      "Categoria": "A"
    },
    {
      "Codigo": 134,
      "Nome": "NOVA LIBRA SUDANESA",
      "CodigoISO": "SDG",
      "Categoria": "B"
    },
    {
      "Codigo": 135,
      "Nome": "DINAR/TUNISIA",
      "CodigoISO": "TND",
      "Categoria": "B"
    },
    {
      "Codigo": 136,
      "Nome": "LIBRA SUL SUDANESA",
      "CodigoISO": "SSP",
      "Categoria": "B"
    },
    {
      "Codigo": 139,
      "Nome": "DIRHAM/MARROCOS  ",
      "CodigoISO": "MAD",
      "Categoria": "A"
    },
    {
      "Codigo": 145,
      "Nome": "DIRHAM/EMIR.ARABE",
      "CodigoISO": "AED",
      "Categoria": "A"
    },
    {
      "Codigo": 148,
      "Nome": "DOBRA S TOME PRIN",
      "CodigoISO": "STD",
      "Categoria": "A"
    },
    {
      "Codigo": 150,
      "Nome": "DOLAR AUSTRALIANO",
      "CodigoISO": "AUD",
      "Categoria": "B"
    },
    {
      "Codigo": 155,
      "Nome": "DOLAR/BAHAMAS",
      "CodigoISO": "BSD",
      "Categoria": "A"
    },
    {
      "Codigo": 160,
      "Nome": "DOLAR/BERMUDAS   ",
      "CodigoISO": "BMD",
      "Categoria": "A"
    },
    {
      "Codigo": 165,
      "Nome": "DOLAR CANADENSE",
      "CodigoISO": "CAD",
      "Categoria": "A"
    },
    {
      "Codigo": 170,
      "Nome": "DOLAR DA GUIANA  ",
      "CodigoISO": "GYD",
      "Categoria": "A"
    },
    {
      "Codigo": 173,
      "Nome": "DOLAR DA NAMIBIA",
      "CodigoISO": "NAD",
      "Categoria": "A"
    },
    {
      "Codigo": 175,
      "Nome": "DOLAR/BARBADOS   ",
      "CodigoISO": "BBD",
      "Categoria": "A"
    },
    {
      "Codigo": 180,
      "Nome": "DOLAR/BELIZE ",
      "CodigoISO": "BZD",
      "Categoria": "B"
    },
    {
      "Codigo": 185,
      "Nome": "DOLAR BRUNEI",
      "CodigoISO": "BND",
      "Categoria": "A"
    },
    {
      "Codigo": 190,
      "Nome": "DOLAR CAYMAN",
      "CodigoISO": "KYD",
      "Categoria": "A"
    },
    {
      "Codigo": 195,
      "Nome": "DOLAR DE CINGAPURA",
      "CodigoISO": "SGD",
      "Categoria": "A"
    },
    {
      "Codigo": 197,
      "Nome": "UNIDADE DE FOMENTO DO CHILE",
      "CodigoISO": "CLF",
      "Categoria": "A"
    },
    {
      "Codigo": 200,
      "Nome": "DOLAR FIJI",
      "CodigoISO": "FJD",
      "Categoria": "A"
    },
    {
      "Codigo": 205,
      "Nome": "DOLAR DE HONG KONG",
      "CodigoISO": "HKD",
      "Categoria": "A"
    },
    {
      "Codigo": 210,
      "Nome": "DOLAR/TRIN. TOBAG",
      "CodigoISO": "TTD",
      "Categoria": "B"
    },
    {
      "Codigo": 215,
      "Nome": "DOLAR CARIBE ORIENTAL",
      "CodigoISO": "XCD",
      "Categoria": "A"
    },
    {
      "Codigo": 217,
      "Nome": "DOLAR ZIMBABUE",
      "CodigoISO": "ZWL",
      "Categoria": "B"
    },
    {
      "Codigo": 220,
      "Nome": "DOLAR DOS ESTADOS UNIDOS",
      "CodigoISO": "USD",
      "Categoria": "A"
    },
    {
      "Codigo": 230,
      "Nome": "DOLAR/JAMAICA",
      "CodigoISO": "JMD",
      "Categoria": "A"
    },
    {
      "Codigo": 235,
      "Nome": "DOLAR LIBERIA",
      "CodigoISO": "LRD",
      "Categoria": "A"
    },
    {
      "Codigo": 240,
      "Nome": "DOLAR MALAIO ",
      "CodigoISO": "M$ ",
      "Categoria": "A"
    },
    {
      "Codigo": 245,
      "Nome": "DOLAR NOVA ZELANDIA",
      "CodigoISO": "NZD",
      "Categoria": "B"
    },
    {
      "Codigo": 250,
      "Nome": "DOLAR IL SALOMAO",
      "CodigoISO": "SBD",
      "Categoria": "A"
    },
    {
      "Codigo": 255,
      "Nome": "DOLAR/SURINAME   ",
      "CodigoISO": "SRD",
      "Categoria": "B"
    },
    {
      "Codigo": 260,
      "Nome": "DONGUE/VIETNAN   ",
      "CodigoISO": "VND",
      "Categoria": "B"
    },
    {
      "Codigo": 270,
      "Nome": "DRACMA/GRECIA",
      "CodigoISO": "GRD",
      "Categoria": "A"
    },
    {
      "Codigo": 275,
      "Nome": "DRAM ARMENIA REP",
      "CodigoISO": "AMD",
      "Categoria": "A"
    },
    {
      "Codigo": 295,
      "Nome": "ESCUDO CABO VERDE",
      "CodigoISO": "CVE",
      "Categoria": "A"
    },
    {
      "Codigo": 315,
      "Nome": "ESCUDO PORTUGUES",
      "CodigoISO": "PTE",
      "Categoria": "A"
    },
    {
      "Codigo": 320,
      "Nome": "ESCUDO TIMOR LESTE",
      "CodigoISO": "TPE",
      "Categoria": "B"
    },
    {
      "Codigo": 325,
      "Nome": "GUILDER ANTILHAS HOLANDESAS",
      "CodigoISO": "ANG",
      "Categoria": "A"
    },
    {
      "Codigo": 328,
      "Nome": "FLORIM/ARUBA ",
      "CodigoISO": "AWG",
      "Categoria": "A"
    },
    {
      "Codigo": 330,
      "Nome": "FLORIM/SURINAME  ",
      "CodigoISO": "SRG",
      "Categoria": "A"
    },
    {
      "Codigo": 333,
      "Nome": "DOLAR/SURINAME   ",
      "CodigoISO": "SRD",
      "Categoria": "B"
    },
    {
      "Codigo": 335,
      "Nome": "FLORIM HOLANDES  ",
      "CodigoISO": "NLG",
      "Categoria": "A"
    },
    {
      "Codigo": 345,
      "Nome": "FORINT/HUNGRIA   ",
      "CodigoISO": "HUF",
      "Categoria": "A"
    },
    {
      "Codigo": 360,
      "Nome": "FRANCO BELGA/BELG",
      "CodigoISO": "BEF",
      "Categoria": "A"
    },
    {
      "Codigo": 363,
      "Nome": "FRANCO CONGOLES",
      "CodigoISO": "CDF",
      "Categoria": "A"
    },
    {
      "Codigo": 365,
      "Nome": "FRANCO/BURUNDI   ",
      "CodigoISO": "BIF",
      "Categoria": "A"
    },
    {
      "Codigo": 368,
      "Nome": "FRANCO/COMORES   ",
      "CodigoISO": "KMF",
      "Categoria": "A"
    },
    {
      "Codigo": 370,
      "Nome": "FRANCO CFA BEAC",
      "CodigoISO": "XAF",
      "Categoria": "A"
    },
    {
      "Codigo": 372,
      "Nome": "FRANCO CFA BCEAO",
      "CodigoISO": "XOF",
      "Categoria": "A"
    },
    {
      "Codigo": 380,
      "Nome": "FRANCO CFP",
      "CodigoISO": "XPF",
      "Categoria": "A"
    },
    {
      "Codigo": 390,
      "Nome": "FRANCO/DJIBUTI   ",
      "CodigoISO": "DJF",
      "Categoria": "A"
    },
    {
      "Codigo": 395,
      "Nome": "FRANCO FRANCES   ",
      "CodigoISO": "FRF",
      "Categoria": "A"
    },
    {
      "Codigo": 398,
      "Nome": "FRANCO/GUINE ",
      "CodigoISO": "GNF",
      "Categoria": "A"
    },
    {
      "Codigo": 400,
      "Nome": "FRANCO LUXEMBURGO",
      "CodigoISO": "LUF",
      "Categoria": "A"
    },
    {
      "Codigo": 405,
      "Nome": "FRANCO MALGAXE MADAGA",
      "CodigoISO": "MGF",
      "Categoria": "A"
    },
    {
      "Codigo": 406,
      "Nome": "ARIARY MADAGASCAR",
      "CodigoISO": "MGA",
      "Categoria": "A"
    },
    {
      "Codigo": 420,
      "Nome": "FRANCO/RUANDA",
      "CodigoISO": "RWF",
      "Categoria": "B"
    },
    {
      "Codigo": 425,
      "Nome": "FRANCO SUICO",
      "CodigoISO": "CHF",
      "Categoria": "A"
    },
    {
      "Codigo": 440,
      "Nome": "GOURDE/HAITI ",
      "CodigoISO": "HTG",
      "Categoria": "A"
    },
    {
      "Codigo": 450,
      "Nome": "GUARANI/PARAGUAI",
      "CodigoISO": "PYG",
      "Categoria": "A"
    },
    {
      "Codigo": 460,
      "Nome": "HRYVNIA UCRANIA",
      "CodigoISO": "UAH",
      "Categoria": "B"
    },
    {
      "Codigo": 470,
      "Nome": "IENE",
      "CodigoISO": "JPY",
      "Categoria": "A"
    },
    {
      "Codigo": 480,
      "Nome": "INTI PERUANO ",
      "CodigoISO": "I  ",
      "Categoria": "A"
    },
    {
      "Codigo": 482,
      "Nome": "LARI GEORGIA",
      "CodigoISO": "GEL",
      "Categoria": "A"
    },
    {
      "Codigo": 485,
      "Nome": "LAT LETONIA REP",
      "CodigoISO": "LVL",
      "Categoria": "A"
    },
    {
      "Codigo": 490,
      "Nome": "LEK ALBANIA REP",
      "CodigoISO": "ALL",
      "Categoria": "A"
    },
    {
      "Codigo": 495,
      "Nome": "LEMPIRA/HONDURAS ",
      "CodigoISO": "HNL",
      "Categoria": "A"
    },
    {
      "Codigo": 500,
      "Nome": "LEONE/SERRA LEOA ",
      "CodigoISO": "SLL",
      "Categoria": "A"
    },
    {
      "Codigo": 503,
      "Nome": "LEU/MOLDAVIA, REP",
      "CodigoISO": "MDL",
      "Categoria": "A"
    },
    {
      "Codigo": 505,
      "Nome": "LEU/ROMENIA  ",
      "CodigoISO": "ROL",
      "Categoria": "A"
    },
    {
      "Codigo": 506,
      "Nome": "NOVO LEU/ROMENIA ",
      "CodigoISO": "RON",
      "Categoria": "A"
    },
    {
      "Codigo": 510,
      "Nome": "LEV/BULGARIA, REP",
      "CodigoISO": "BGN",
      "Categoria": "A"
    },
    {
      "Codigo": 520,
      "Nome": "LIBRA CIP/CHIPRE ",
      "CodigoISO": "CYP",
      "Categoria": "B"
    },
    {
      "Codigo": 530,
      "Nome": "LIBRA/GIBRALTAR  ",
      "CodigoISO": "GIP",
      "Categoria": "A"
    },
    {
      "Codigo": 535,
      "Nome": "LIBRA/EGITO  ",
      "CodigoISO": "EGP",
      "Categoria": "A"
    },
    {
      "Codigo": 540,
      "Nome": "LIBRA ESTERLINA",
      "CodigoISO": "GBP",
      "Categoria": "B"
    },
    {
      "Codigo": 545,
      "Nome": "LIBRA/FALKLAND   ",
      "CodigoISO": "FKP",
      "Categoria": "A"
    },
    {
      "Codigo": 550,
      "Nome": "LIBRA/IRLANDA",
      "CodigoISO": "IEP",
      "Categoria": "A"
    },
    {
      "Codigo": 560,
      "Nome": "LIBRA/LIBANO ",
      "CodigoISO": "LBP",
      "Categoria": "A"
    },
    {
      "Codigo": 565,
      "Nome": "LIRA/MALTA   ",
      "CodigoISO": "MTL",
      "Categoria": "B"
    },
    {
      "Codigo": 570,
      "Nome": "LIBRA/STA HELENA ",
      "CodigoISO": "SHP",
      "Categoria": "A"
    },
    {
      "Codigo": 575,
      "Nome": "LIBRA/SIRIA, REP ",
      "CodigoISO": "SYP",
      "Categoria": "A"
    },
    {
      "Codigo": 580,
      "Nome": "LIBRA SUDANESA   ",
      "CodigoISO": "LSD",
      "Categoria": "A"
    },
    {
      "Codigo": 585,
      "Nome": "LILANGENI/SUAZIL ",
      "CodigoISO": "SZL",
      "Categoria": "B"
    },
    {
      "Codigo": 595,
      "Nome": "LIRA ITALIANA",
      "CodigoISO": "ITL",
      "Categoria": "A"
    },
    {
      "Codigo": 600,
      "Nome": "LIRA TURQUIA",
      "CodigoISO": "TRL",
      "Categoria": "B"
    },
    {
      "Codigo": 601,
      "Nome": "LITA LITUANIA",
      "CodigoISO": "LTL",
      "Categoria": "A"
    },
    {
      "Codigo": 603,
      "Nome": "LOTI/LESOTO  ",
      "CodigoISO": "LSL",
      "Categoria": "B"
    },
    {
      "Codigo": 607,
      "Nome": "MANAT ARZEBAIJAO",
      "CodigoISO": "AZN",
      "Categoria": "A"
    },
    {
      "Codigo": 608,
      "Nome": "NOVO MANAT TURCOM",
      "CodigoISO": "TMT",
      "Categoria": "B"
    },
    {
      "Codigo": 610,
      "Nome": "MARCO ALEMAO ",
      "CodigoISO": "DEM",
      "Categoria": "A"
    },
    {
      "Codigo": 612,
      "Nome": "MARCO CONV BOSNIA",
      "CodigoISO": "BAM",
      "Categoria": "A"
    },
    {
      "Codigo": 615,
      "Nome": "MARCO FINLANDES",
      "CodigoISO": "FIM",
      "Categoria": "B"
    },
    {
      "Codigo": 620,
      "Nome": "METICAL MOCAMBIQ",
      "CodigoISO": "MZM",
      "Categoria": "A"
    },
    {
      "Codigo": 622,
      "Nome": "NOVA METICAL/MOCA",
      "CodigoISO": "MZN",
      "Categoria": "B"
    },
    {
      "Codigo": 625,
      "Nome": "NAKFA ERITREIA",
      "CodigoISO": "ERN",
      "Categoria": "B"
    },
    {
      "Codigo": 630,
      "Nome": "NAIRA/NIGERIA",
      "CodigoISO": "NGN",
      "Categoria": "A"
    },
    {
      "Codigo": 635,
      "Nome": "KWANZA/ANGOLA",
      "CodigoISO": "AOA",
      "Categoria": "A"
    },
    {
      "Codigo": 637,
      "Nome": "NOVO DINAR IUGOSLAVO",
      "CodigoISO": "YUM",
      "Categoria": "A"
    },
    {
      "Codigo": 640,
      "Nome": "NOVO DOLAR/TAIWAN",
      "CodigoISO": "TWD",
      "Categoria": "A"
    },
    {
      "Codigo": 642,
      "Nome": "LIRA TURCA",
      "CodigoISO": "TRY",
      "Categoria": "B"
    },
    {
      "Codigo": 660,
      "Nome": "NOVOSOL/PERU",
      "CodigoISO": "PEN",
      "Categoria": "A"
    },
    {
      "Codigo": 665,
      "Nome": "NGULTRUM/BUTAO   ",
      "CodigoISO": "BTN",
      "Categoria": "B"
    },
    {
      "Codigo": 670,
      "Nome": "UGUIA MAURITANIA",
      "CodigoISO": "MRO",
      "Categoria": "A"
    },
    {
      "Codigo": 680,
      "Nome": "PAANGA/TONGA ",
      "CodigoISO": "TOP",
      "Categoria": "B"
    },
    {
      "Codigo": 685,
      "Nome": "PATACA/MACAU ",
      "CodigoISO": "MOP",
      "Categoria": "A"
    },
    {
      "Codigo": 690,
      "Nome": "PESETA/ANDORA",
      "CodigoISO": "ADP",
      "Categoria": "A"
    },
    {
      "Codigo": 700,
      "Nome": "PESETA ESPANHOLA ",
      "CodigoISO": "ESP",
      "Categoria": "A"
    },
    {
      "Codigo": 706,
      "Nome": "PESO ARGENTINO",
      "CodigoISO": "ARS",
      "Categoria": "A"
    },
    {
      "Codigo": 710,
      "Nome": "PESO BOLIVIANO   ",
      "CodigoISO": "$B ",
      "Categoria": "A"
    },
    {
      "Codigo": 715,
      "Nome": "PESO CHILE",
      "CodigoISO": "CLP",
      "Categoria": "A"
    },
    {
      "Codigo": 720,
      "Nome": "PESO/COLOMBIA",
      "CodigoISO": "COP",
      "Categoria": "A"
    },
    {
      "Codigo": 725,
      "Nome": "PESO/CUBA    ",
      "CodigoISO": "CUP",
      "Categoria": "B"
    },
    {
      "Codigo": 730,
      "Nome": "PESO/REP. DOMINIC",
      "CodigoISO": "DOP",
      "Categoria": "A"
    },
    {
      "Codigo": 735,
      "Nome": "PESO/FILIPINAS   ",
      "CodigoISO": "PHP",
      "Categoria": "A"
    },
    {
      "Codigo": 738,
      "Nome": "PESO/GUINE BISSAU",
      "CodigoISO": "GWP",
      "Categoria": "A"
    },
    {
      "Codigo": 741,
      "Nome": "PESO MEXICO",
      "CodigoISO": "MXN",
      "Categoria": "A"
    },
    {
      "Codigo": 745,
      "Nome": "PESO/URUGUAIO",
      "CodigoISO": "UYU",
      "Categoria": "A"
    },
    {
      "Codigo": 755,
      "Nome": "PULA/BOTSWANA",
      "CodigoISO": "BWP",
      "Categoria": "A"
    },
    {
      "Codigo": 760,
      "Nome": "QUACHA/MALAVI",
      "CodigoISO": "MWK",
      "Categoria": "B"
    },
    {
      "Codigo": 765,
      "Nome": "QUACHA ZAMBIA",
      "CodigoISO": "ZMK",
      "Categoria": "B"
    },
    {
      "Codigo": 766,
      "Nome": "QUACHA ZAMBIA",
      "CodigoISO": "ZMW",
      "Categoria": "B"
    },
    {
      "Codigo": 770,
      "Nome": "QUETZAL/GUATEMALA",
      "CodigoISO": "GTQ",
      "Categoria": "A"
    },
    {
      "Codigo": 775,
      "Nome": "QUIATE/BIRMANIA  ",
      "CodigoISO": "MMK",
      "Categoria": "A"
    },
    {
      "Codigo": 778,
      "Nome": "KINA/PAPUA N GUIN",
      "CodigoISO": "PGK",
      "Categoria": "A"
    },
    {
      "Codigo": 779,
      "Nome": "KUNA/CROACIA ",
      "CodigoISO": "HRK",
      "Categoria": "B"
    },
    {
      "Codigo": 780,
      "Nome": "QUIPE/LAOS, REP  ",
      "CodigoISO": "LAK",
      "Categoria": "A"
    },
    {
      "Codigo": 785,
      "Nome": "RANDE/AFRICASUL",
      "CodigoISO": "ZAR",
      "Categoria": "A"
    },
    {
      "Codigo": 790,
      "Nome": "REAL",
      "CodigoISO": "BRL",
      "Categoria": "A"
    },
    {
      "Codigo": 795,
      "Nome": "IUAN CHINA",
      "CodigoISO": "CNY",
      "Categoria": "A"
    },
    {
      "Codigo": 796,
      "Nome": "RENMINBI HONG KONG",
      "CodigoISO": "CNH",
      "Categoria": "B"
    },
    {
      "Codigo": 800,
      "Nome": "RIAL/CATAR   ",
      "CodigoISO": "QAR",
      "Categoria": "A"
    },
    {
      "Codigo": 805,
      "Nome": "RIAL/OMA     ",
      "CodigoISO": "OMR",
      "Categoria": "A"
    },
    {
      "Codigo": 810,
      "Nome": "RIAL/IEMEN   ",
      "CodigoISO": "YER",
      "Categoria": "A"
    },
    {
      "Codigo": 815,
      "Nome": "RIAL/IRAN, REP   ",
      "CodigoISO": "IRR",
      "Categoria": "A"
    },
    {
      "Codigo": 820,
      "Nome": "RIAL/ARAB SAUDITA",
      "CodigoISO": "SAR",
      "Categoria": "A"
    },
    {
      "Codigo": 825,
      "Nome": "RIEL/CAMBOJA ",
      "CodigoISO": "KHR",
      "Categoria": "A"
    },
    {
      "Codigo": 828,
      "Nome": "RINGGIT/MALASIA",
      "CodigoISO": "MYR",
      "Categoria": "A"
    },
    {
      "Codigo": 829,
      "Nome": "RUBLO BELARUS",
      "CodigoISO": "BYR",
      "Categoria": "A"
    },
    {
      "Codigo": 830,
      "Nome": "RUBLO/RUSSIA ",
      "CodigoISO": "RUB",
      "Categoria": "A"
    },
    {
      "Codigo": 831,
      "Nome": "RUBLO BELARUS",
      "CodigoISO": "BYN",
      "Categoria": "A"
    },
    {
      "Codigo": 835,
      "Nome": "SOMONI TADJIQUISTAO",
      "CodigoISO": "TJS",
      "Categoria": "A"
    },
    {
      "Codigo": 840,
      "Nome": "RUPIA/MAURICIO   ",
      "CodigoISO": "MUR",
      "Categoria": "A"
    },
    {
      "Codigo": 845,
      "Nome": "RUPIA/NEPAL  ",
      "CodigoISO": "NPR",
      "Categoria": "A"
    },
    {
      "Codigo": 850,
      "Nome": "RUPIA/SEYCHELES  ",
      "CodigoISO": "SCR",
      "Categoria": "A"
    },
    {
      "Codigo": 855,
      "Nome": "RUPIA/SRI LANKA  ",
      "CodigoISO": "LKR",
      "Categoria": "A"
    },
    {
      "Codigo": 860,
      "Nome": "RUPIA/INDIA  ",
      "CodigoISO": "INR",
      "Categoria": "A"
    },
    {
      "Codigo": 865,
      "Nome": "RUPIA/INDONESIA  ",
      "CodigoISO": "IDR",
      "Categoria": "A"
    },
    {
      "Codigo": 870,
      "Nome": "RUFIA/MALDIVAS   ",
      "CodigoISO": "MVR",
      "Categoria": "A"
    },
    {
      "Codigo": 875,
      "Nome": "RUPIA/PAQUISTAO  ",
      "CodigoISO": "PKR",
      "Categoria": "A"
    },
    {
      "Codigo": 880,
      "Nome": "SHEKEL/ISRAEL",
      "CodigoISO": "ILS",
      "Categoria": "A"
    },
    {
      "Codigo": 890,
      "Nome": "SOL PERUANO  ",
      "CodigoISO": "S/.",
      "Categoria": "A"
    },
    {
      "Codigo": 892,
      "Nome": "SOM QUIRGUISTAO",
      "CodigoISO": "KGS",
      "Categoria": "A"
    },
    {
      "Codigo": 893,
      "Nome": "SOM UZBEQUISTAO",
      "CodigoISO": "UZS",
      "Categoria": "B"
    },
    {
      "Codigo": 895,
      "Nome": "SUCRE EQUADOR",
      "CodigoISO": "ECS",
      "Categoria": "A"
    },
    {
      "Codigo": 905,
      "Nome": "TACA/BANGLADESH  ",
      "CodigoISO": "BDT",
      "Categoria": "A"
    },
    {
      "Codigo": 910,
      "Nome": "TALA         ",
      "CodigoISO": "WS$",
      "Categoria": "A"
    },
    {
      "Codigo": 911,
      "Nome": "TALA SAMOA OC",
      "CodigoISO": "WST",
      "Categoria": "A"
    },
    {
      "Codigo": 912,
      "Nome": "TALA SAMOA",
      "CodigoISO": "WST",
      "Categoria": "A"
    },
    {
      "Codigo": 913,
      "Nome": "TENGE CAZAQUISTAO",
      "CodigoISO": "KZT",
      "Categoria": "A"
    },
    {
      "Codigo": 914,
      "Nome": "TOLAR/ESLOVENIA  ",
      "CodigoISO": "SIT",
      "Categoria": "A"
    },
    {
      "Codigo": 915,
      "Nome": "TUGRIK/MONGOLIA  ",
      "CodigoISO": "MNT",
      "Categoria": "A"
    },
    {
      "Codigo": 916,
      "Nome": "UNID FOMENTO CHIL",
      "CodigoISO": "CLF",
      "Categoria": "A"
    },
    {
      "Codigo": 920,
      "Nome": "VATU VANUATU",
      "CodigoISO": "VUV",
      "Categoria": "B"
    },
    {
      "Codigo": 925,
      "Nome": "WON/COREIA NORTE ",
      "CodigoISO": "KPW",
      "Categoria": "A"
    },
    {
      "Codigo": 930,
      "Nome": "WON COREIA SUL",
      "CodigoISO": "KRW",
      "Categoria": "A"
    },
    {
      "Codigo": 940,
      "Nome": "XELIM AUSTRIACO  ",
      "CodigoISO": "ATS",
      "Categoria": "A"
    },
    {
      "Codigo": 946,
      "Nome": "XELIM/TANZANIA   ",
      "CodigoISO": "TZS",
      "Categoria": "B"
    },
    {
      "Codigo": 950,
      "Nome": "XELIM/QUENIA ",
      "CodigoISO": "KES",
      "Categoria": "A"
    },
    {
      "Codigo": 955,
      "Nome": "XELIM/UGANDA ",
      "CodigoISO": "UGX",
      "Categoria": "B"
    },
    {
      "Codigo": 960,
      "Nome": "XELIM/SOMALIA",
      "CodigoISO": "SOS",
      "Categoria": "B"
    },
    {
      "Codigo": 970,
      "Nome": "NOVO ZAIRE  ZAIRE",
      "CodigoISO": "ZRN",
      "Categoria": "A"
    },
    {
      "Codigo": 971,
      "Nome": "NOVO ZAIRE/ZAIRE ",
      "CodigoISO": "ZRN",
      "Categoria": "A"
    },
    {
      "Codigo": 975,
      "Nome": "ZLOTY/POLONIA",
      "CodigoISO": "PLN",
      "Categoria": "A"
    },
    {
      "Codigo": 978,
      "Nome": "EURO",
      "CodigoISO": "EUR",
      "Categoria": "B"
    },
    {
      "Codigo": 995,
      "Nome": "BUA          ",
      "CodigoISO": "BUA",
      "Categoria": "A"
    },
    {
      "Codigo": 996,
      "Nome": "FUA          ",
      "CodigoISO": "FUA",
      "Categoria": "A"
    }
  ]
  return list.filter(e => e.Codigo === cod)[0]?.Nome ?? ''

};