import React from 'react'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { FormDocumentProps } from '../contracts'

const FormDocumentConhecimento: React.FC<FormDocumentProps> = ({
  onChange,
  value
}) => {
  return (
    <>
      <SimpleFormGenerator
        onChange={onChange}
        value={value}
        rows={[
          {
            columns: 2,

            fields: [
              {
                name: 'files',
                label: 'Arquivo',
                type: 'files',
                extensions: ['pdf']
              },
              {
                name: 'document_number',
                label: 'Nº Conhecimento',
                type: 'text'
              },
              { name: 'expiration_date', label: 'Data Embarque', type: 'date' }
            ]
          }
        ]}
      />
    </>
  )
}

export default FormDocumentConhecimento
