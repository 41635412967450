import { useEffect, useState } from "react";
import styled from "styled-components";
import CheckBoxPermission from "components/generic/CheckBoxPermission";
import GenericButton from "./GenericButton";
import { UserModel } from "interfaces/user";

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  margin: 0.1rem 0;
`;

const TextPermission = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 24px;
  text-transform: uppercase;
  color: ${props => props.theme.color.gray1};
  margin-right: 1rem;
`;

const BlueCard = styled.div`
  width: auto;
  height: 190px;
  padding: 0.5rem;
  border-radius: 9px;
  background-color: ${props => props.theme.color.primary_main};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const TabsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  max-height: 150px;
  margin-right: 0.5rem;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  border-radius: 9px;
  background-color: ${props => props.theme.color.white1};
  height: auto;
  width: 150px;
`;

const ButtonTabs = styled(GenericButton)<{
  isDeny?: boolean;
  isYellow?: boolean;
  isBlue?: boolean;
}>`
  width: 150px;
  height: 25px;
  margin: 0.1rem;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  color: ${props => props.theme.color.white1};
  font-size: 0.9rem;
  background-color: ${props =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isYellow
      ? props.theme.color.secondary_main
      : props.isBlue
      ? "#296ba9"
      : props.theme.color.button_normal} !important;

  &:hover {
    background-color: ${props =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isYellow
        ? props.theme.color.secondary_light
        : props.isBlue
        ? "#4b87be"
        : props.theme.color.button_normal_hover} !important;
  }
`;

type Tabs =
  | "CADASTRO"
  | "COMPLIANCE"
  | "DIRETORIA"
  | "USERSDASCAM"
  | "DOCUMENTACAO"
  | "OPERACOES";

interface Props {
  userData: UserModel;
  changeUserData: (userData: UserModel) => void;
  readOnly?: boolean;
}

const PermissionList = ({
  userData,
  changeUserData,
  readOnly,
}: Props): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<Tabs>("CADASTRO");
  //Cadastro
  const [cadastroCreate, setCadastroCreate] = useState<boolean>(false);
  const [cadastroView, setCadastroView] = useState<boolean>(false);
  const [cadastroEdit, setCadastroEdit] = useState<boolean>(false);
  const [cadastroStatus, setCadastroStatus] = useState<boolean>(false);
  const [cadastroAll, setCadastroAll] = useState<boolean>(false);
  //Compliance
  const [complianceCreate, setComplianceCreate] = useState<boolean>(false);
  const [complianceView, setComplianceView] = useState<boolean>(false);
  const [complianceEdit, setComplianceEdit] = useState<boolean>(false);
  const [complianceStatus, setComplianceStatus] = useState<boolean>(false);
  const [complianceAll, setComplianceAll] = useState<boolean>(false);
  //Diretoria
  const [diretoriaCreate, setDiretoriaCreate] = useState<boolean>(false);
  const [diretoriaView, setDiretoriaView] = useState<boolean>(false);
  const [diretoriaEdit, setDiretoriaEdit] = useState<boolean>(false);
  const [diretoriaStatus, setDiretoriaStatus] = useState<boolean>(false);
  const [diretoriaAll, setDiretoriaAll] = useState<boolean>(false);
  //DascamUser
  const [usersCreate, setUsersCreate] = useState<boolean>(false);
  const [usersView, setUsersView] = useState<boolean>(false);
  const [usersEdit, setUsersEdit] = useState<boolean>(false);
  const [usersStatus, setUsersStatus] = useState<boolean>(false);
  const [usersAll, setUsersAll] = useState<boolean>(false);
  //Documentação
  const [documentCreate, setDocumentCreate] = useState<boolean>(false);
  const [documentView, setDocumentView] = useState<boolean>(false);
  const [documentEdit, setDocumentEdit] = useState<boolean>(false);
  const [documentStatus, setDocumentStatus] = useState<boolean>(false);
  const [documentAll, setDocumentAll] = useState<boolean>(false);
  //Operações
  const [operationView, setOperationView] = useState<boolean>(false);
  const [operationEdit, setOperationEdit] = useState<boolean>(false);
  const [operationAll, setOperationAll] = useState<boolean>(false);

  const handlePermission = (value: number) => {
    if (userData.permission_list) {
      return userData.permission_list.find(
        permission => permission === value
      ) !== undefined
        ? true
        : false;
    } else {
      return false;
    }
  };

  useEffect(() => {
    changeUserData({
      ...userData,
      permission_list: [
        cadastroCreate === true ? 1 : 0,
        cadastroView === true ? 2 : 0,
        cadastroEdit === true ? 3 : 0,
        cadastroStatus === true ? 4 : 0,
        complianceCreate === true ? 5 : 0,
        complianceView === true ? 6 : 0,
        complianceEdit === true ? 7 : 0,
        complianceStatus === true ? 8 : 0,
        diretoriaCreate === true ? 9 : 0,
        diretoriaView === true ? 10 : 0,
        diretoriaEdit === true ? 11 : 0,
        diretoriaStatus === true ? 12 : 0,
        usersCreate === true ? 13 : 0,
        usersView === true ? 14 : 0,
        usersEdit === true ? 15 : 0,
        usersStatus === true ? 16 : 0,
        documentCreate === true ? 17 : 0,
        documentView === true ? 18 : 0,
        documentEdit === true ? 19 : 0,
        documentStatus === true ? 20 : 0,
        operationView === true ? 21 : 0,
        operationEdit === true ? 22 : 0,
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cadastroCreate,
    cadastroView,
    cadastroEdit,
    cadastroStatus,
    complianceCreate,
    complianceView,
    complianceEdit,
    complianceStatus,
    diretoriaCreate,
    diretoriaView,
    diretoriaEdit,
    diretoriaStatus,
    usersCreate,
    usersView,
    usersEdit,
    usersStatus,
    documentCreate,
    documentView,
    documentEdit,
    documentStatus,
    operationView,
    operationEdit,
  ]);

  useEffect(() => {
    setCadastroCreate(handlePermission(1));
    setCadastroView(handlePermission(2));
    setCadastroEdit(handlePermission(3));
    setCadastroStatus(handlePermission(4));
    setComplianceCreate(handlePermission(5));
    setComplianceView(handlePermission(6));
    setComplianceEdit(handlePermission(7));
    setComplianceStatus(handlePermission(8));
    setDiretoriaCreate(handlePermission(9));
    setDiretoriaView(handlePermission(10));
    setDiretoriaEdit(handlePermission(11));
    setDiretoriaStatus(handlePermission(12));
    setUsersCreate(handlePermission(13));
    setUsersView(handlePermission(14));
    setUsersEdit(handlePermission(15));
    setUsersStatus(handlePermission(16));
    setDocumentCreate(handlePermission(17));
    setDocumentView(handlePermission(18));
    setDocumentEdit(handlePermission(19));
    setDocumentStatus(handlePermission(20));
    setOperationView(handlePermission(21));
    setOperationEdit(handlePermission(22));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      cadastroView === true &&
      cadastroEdit === true &&
      cadastroStatus === true
    ) {
      setCadastroAll(true);
    } else {
      setCadastroAll(false);
    }
    if (
      complianceView === true &&
      complianceEdit === true &&
      complianceStatus === true
    ) {
      setComplianceAll(true);
    } else {
      setComplianceAll(false);
    }
    if (
      diretoriaView === true &&
      diretoriaEdit === true &&
      diretoriaStatus === true
    ) {
      setDiretoriaAll(true);
    } else {
      setDiretoriaAll(false);
    }
    if (
      usersCreate === true &&
      usersView === true &&
      usersEdit === true &&
      usersStatus === true
    ) {
      setUsersAll(true);
    } else {
      setUsersAll(false);
    }
    if (documentView === true && documentEdit === true) {
      setDocumentAll(true);
    } else {
      setDocumentAll(false);
    }
    if (operationView === true && operationEdit === true) {
      setOperationAll(true);
    } else {
      setOperationAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <ItemRow>
      <TextPermission>Permissões:</TextPermission>
      <BlueCard>
        <TabsList>
          <ButtonTabs
            isBlue={currentTab !== "CADASTRO"}
            isYellow={currentTab === "CADASTRO"}
            type="button"
            onClick={() => setCurrentTab("CADASTRO")}
          >
            Setor Cadastro
          </ButtonTabs>
          <ButtonTabs
            isBlue={currentTab !== "COMPLIANCE"}
            isYellow={currentTab === "COMPLIANCE"}
            type="button"
            onClick={() => setCurrentTab("COMPLIANCE")}
          >
            Setor Compliance
          </ButtonTabs>
          <ButtonTabs
            isBlue={currentTab !== "DIRETORIA"}
            isYellow={currentTab === "DIRETORIA"}
            type="button"
            onClick={() => setCurrentTab("DIRETORIA")}
          >
            Setor Diretoria
          </ButtonTabs>
          <ButtonTabs
            isBlue={currentTab !== "USERSDASCAM"}
            isYellow={currentTab === "USERSDASCAM"}
            type="button"
            onClick={() => setCurrentTab("USERSDASCAM")}
          >
            Usuário Dascam
          </ButtonTabs>
          <ButtonTabs
            isBlue={currentTab !== "DOCUMENTACAO"}
            isYellow={currentTab === "DOCUMENTACAO"}
            type="button"
            onClick={() => setCurrentTab("DOCUMENTACAO")}
          >
            Documentação
          </ButtonTabs>
          <ButtonTabs
            isBlue={currentTab !== "OPERACOES"}
            isYellow={currentTab === "OPERACOES"}
            type="button"
            onClick={() => setCurrentTab("OPERACOES")}
          >
            Operações
          </ButtonTabs>
        </TabsList>
        {currentTab === "CADASTRO" ? (
          <List>
            {/* <CheckBoxPermission
              title="Criar"
              selected={cadastroCreate}
              changeSelected={value => setCadastroCreate(value)}
              readOnly={readOnly ? readOnly : false}
            /> */}
            <CheckBoxPermission
              title="Visualizar"
              selected={cadastroView}
              changeSelected={value => setCadastroView(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Alterar"
              selected={cadastroEdit}
              changeSelected={value => setCadastroEdit(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Alterar Status"
              selected={cadastroStatus}
              changeSelected={value => setCadastroStatus(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Todas"
              selected={cadastroAll}
              changeSelected={value => {
                setCadastroAll(value);
                setCadastroCreate(value);
                setCadastroView(value);
                setCadastroEdit(value);
                setCadastroStatus(value);
              }}
              readOnly={readOnly ? readOnly : false}
            />
          </List>
        ) : null}
        {currentTab === "COMPLIANCE" ? (
          <List>
            {/* <CheckBoxPermission
              title="Criar"
              selected={complianceCreate}
              changeSelected={value => setComplianceCreate(value)}
              readOnly={readOnly ? readOnly : false}
            /> */}
            <CheckBoxPermission
              title="Visualizar"
              selected={complianceView}
              changeSelected={value => setComplianceView(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Alterar"
              selected={complianceEdit}
              changeSelected={value => setComplianceEdit(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Alterar Status"
              selected={complianceStatus}
              changeSelected={value => setComplianceStatus(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Todas"
              selected={complianceAll}
              changeSelected={value => {
                setComplianceAll(value);
                setComplianceCreate(value);
                setComplianceView(value);
                setComplianceEdit(value);
                setComplianceStatus(value);
              }}
              readOnly={readOnly ? readOnly : false}
            />
          </List>
        ) : null}

        {currentTab === "DIRETORIA" ? (
          <List>
            {/* <CheckBoxPermission
              title="Criar"
              selected={diretoriaCreate}
              changeSelected={value => setDiretoriaCreate(value)}
              readOnly={readOnly ? readOnly : false}
            /> */}
            <CheckBoxPermission
              title="Visualizar"
              selected={diretoriaView}
              changeSelected={value => setDiretoriaView(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Alterar"
              selected={diretoriaEdit}
              changeSelected={value => setDiretoriaEdit(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Alterar Status"
              selected={diretoriaStatus}
              changeSelected={value => setDiretoriaStatus(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Todas"
              selected={diretoriaAll}
              changeSelected={value => {
                setDiretoriaAll(value);
                setDiretoriaCreate(value);
                setDiretoriaView(value);
                setDiretoriaEdit(value);
                setDiretoriaStatus(value);
              }}
              readOnly={readOnly ? readOnly : false}
            />
          </List>
        ) : null}
        {currentTab === "USERSDASCAM" ? (
          <List>
            <CheckBoxPermission
              title="Criar"
              selected={usersCreate}
              changeSelected={value => setUsersCreate(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Visualizar"
              selected={usersView}
              changeSelected={value => setUsersView(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Alterar"
              selected={usersEdit}
              changeSelected={value => setUsersEdit(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Ativar/Desativar"
              selected={usersStatus}
              changeSelected={value => setUsersStatus(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Todas"
              selected={usersAll}
              changeSelected={value => {
                setUsersAll(value);
                setUsersCreate(value);
                setUsersView(value);
                setUsersEdit(value);
                setUsersStatus(value);
              }}
              readOnly={readOnly ? readOnly : false}
            />
          </List>
        ) : null}
        {currentTab === "DOCUMENTACAO" ? (
          <List>
            {/* <CheckBoxPermission
              title="Criar"
              selected={documentCreate}
              changeSelected={value => setDocumentCreate(value)}
              readOnly={readOnly ? readOnly : false}
            /> */}
            <CheckBoxPermission
              title="Visualizar"
              selected={documentView}
              changeSelected={value => setDocumentView(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Efetuar Upload"
              selected={documentEdit}
              changeSelected={value => setDocumentEdit(value)}
              readOnly={readOnly ? readOnly : false}
            />
            {/* <CheckBoxPermission
              title="Ativar/Desativar"
              selected={documentStatus}
              changeSelected={value => setDocumentStatus(value)}
              readOnly={readOnly ? readOnly : false}
            /> */}
            <CheckBoxPermission
              title="Todas"
              selected={documentAll}
              changeSelected={value => {
                setDocumentAll(value);
                setDocumentCreate(value);
                setDocumentView(value);
                setDocumentEdit(value);
                setDocumentStatus(value);
              }}
              readOnly={readOnly ? readOnly : false}
            />
          </List>
        ) : null}
        {currentTab === "OPERACOES" ? (
          <List>
            <CheckBoxPermission
              title="Visualizar"
              selected={operationView}
              changeSelected={value => setOperationView(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Aprovar/Reprovar"
              selected={operationEdit}
              changeSelected={value => setOperationEdit(value)}
              readOnly={readOnly ? readOnly : false}
            />
            <CheckBoxPermission
              title="Todas"
              selected={operationAll}
              changeSelected={value => {
                setOperationAll(value);
                setOperationEdit(value);
                setOperationView(value);
              }}
              readOnly={readOnly ? readOnly : false}
            />
          </List>
        ) : null}
      </BlueCard>
    </ItemRow>
  );
};

export default PermissionList;
