import { axiosAuth, axiosEmail } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { BankModel } from 'interfaces/bank'
import { BeneficiaryModel } from 'interfaces/beneficiary'
import { UserModel, UserResponsibleEnum, UserStatusEnum } from 'interfaces/user'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading
} from 'redux/actions'
import { GlobalState } from 'redux/types'
import styled from 'styled-components'
import GenericButton from './GenericButton'
import { InputTextArea } from './GenericInputs'
import Modal from './Modal'
import { formatResposible, formatUserStatus } from 'helpers/formats'

const StyledObservation = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
`

const Text = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray1};
  max-width: 450px;
  text-align: center;
`

const DivButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0.3rem 0;
`

const SpanColor = styled.span`
  color: ${(props) => props.theme.color.third_main};
`

const Button = styled(GenericButton)<{ isDeny?: boolean }>`
  width: 150px;
  padding: 0.5rem;
  margin: 0 0.3rem;
  color: ${(props) => props.theme.color.white1};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.button_normal};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.button_normal_hover};
  }
`

interface Props {
  showModal: boolean
  userData: UserModel
  accountBanks: BankModel[]
  beneficiarys?: BeneficiaryModel[]
  handleModal: () => void
}

interface PathNav {
  id: string
  email: string
}

const ApprovalUser = ({
  showModal,
  userData,
  accountBanks,
  beneficiarys,
  handleModal
}: Props): JSX.Element => {
  const user = useSelector((state: GlobalState) => state.user)
  const history = useHistory()
  const dispatch = useDispatch()
  const pathNav = useParams<PathNav>()
  const [observationValue, setObservationValue] = useState<string>('')

  const handleApprovalStep = async (status: string, sector: string) => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const body = {
        status: status,
        responsible_sector: sector,
        user_name: userData.user_name,
        responsible_sector_notf: sector
      }

      await axiosAuth.put(`/users/${pathNav.id}`, body, config)

      const bodyObserv = {
        user_id: userData?.id,
        text_content: observationValue,
        creator_name: user?.user_name,
        creator_sector: user?.dascam_type,
        created_by_id: user?.id
      }

      await axiosAuth.post(`/observation`, bodyObserv, config)

      const { data } = await axiosAuth.get(`/users/${pathNav.id}`, config)

      const bodyMail = {
        emails: [data.email],
        name: data.user_name,
        status: formatUserStatus(data.status),
        sector: formatResposible(data.responsible_sector),
        text: 'Cadastro Válido'
      }

      await axiosEmail.post(`/update-status`, bodyMail)

      dispatch(
        setSuccessMessage(
          'Cadastro Enviado para Análise, prazo mínimo para análise 24 horas.'
        )
      )
      history.push('/dascam/registration-management')
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleActiveUser = async (status: string, sector: string) => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      userData.status = status as UserStatusEnum
      userData.responsible_sector = sector as UserResponsibleEnum

      const body = {
        user: userData,
        bank: accountBanks,
        beneficiary: beneficiarys
      }

      await axiosAuth.post(`/user/approved`, body, config)

      const bodyObserv = {
        user_id: userData?.id,
        text_content: observationValue,
        creator_name: user?.user_name,
        creator_sector: user?.dascam_type,
        created_by_id: user?.id
      }

      await axiosAuth.post(`/observation`, bodyObserv, config)

      const { data } = await axiosAuth.get(`/users/${pathNav.id}`, config)

      const bodyMail = {
        emails: [data.email],
        name: data.user_name,
        status: data.status,
        sector: data.responsible_sector,
        text: 'Cadastro Válido'
      }

      await axiosEmail.post(`/update-status`, bodyMail)

      dispatch(setSuccessMessage('Envio feito com sucesso!'))
      history.push('/dascam/registration-management')
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleActiveExistUser = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      userData.status = 'ACTIVE' as UserStatusEnum
      userData.responsible_sector = 'CLIENTE' as UserResponsibleEnum

      const body = {
        user: userData,
        bank: accountBanks,
        beneficiary: beneficiarys
      }

      const resUser = await axiosAuth.put(`/user/update`, body, config)

      if (resUser) {
        const bodyObserv = {
          user_id: userData?.id,
          text_content: observationValue,
          creator_name: user?.user_name,
          creator_sector: user?.contact_branch,
          created_by_id: user?.id
        }

        const observation = await axiosAuth.post(
          `/observation`,
          bodyObserv,
          config
        )

        if (observation) {
          //  const { data } = await axiosAuth.get(`/users/${pathNav.id}`, config);

          const bodyMail = {
            emails: [userData.email],
            name: userData.user_name,
            status: userData.status,
            sector: userData.responsible_sector,
            text: 'Cadastro Válido'
          }

          await axiosEmail.post(`/update-status`, bodyMail)
          dispatch(setSuccessMessage('Envio feito com sucesso!'))
        }
      }
      history.push('/dascam/registration-management')
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const close = () => {
    handleModal()
  }

  return (
    <Modal showModal={showModal} title='' backFocus>
      <StyledObservation>
        {userData.old_sector !== 'DIRETORIA' ? (
          <Text>
            <SpanColor>
              Você deseja realmente enviar este cadastro para :{' '}
            </SpanColor>
            {userData.responsible_sector === 'CADASTRO'
              ? 'Compliance'
              : userData.responsible_sector === 'COMPLIANCE'
              ? 'Diretoria'
              : 'Ativo'}
            <br />
            <br />
            Deixe uma mensagem para o usuário:
          </Text>
        ) : (
          <Text>
            <SpanColor>Você deseja realmente aprovar:</SpanColor>{' '}
            {userData.user_name}
          </Text>
        )}
        <InputTextArea
          value={observationValue}
          changeValue={(value) => setObservationValue(value)}
        />

        <DivButtons>
          <Button type='button' isDeny onClick={() => close()}>
            Voltar
          </Button>
          {userData.responsible_sector === 'CADASTRO' &&
          userData.old_sector !== 'DIRETORIA' &&
          !userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() => handleApprovalStep('EVALUATION', 'COMPLIANCE')}
              >
                Confirmar
              </Button>
            </>
          ) : null}
          {userData.responsible_sector === 'COMPLIANCE' &&
          userData.old_sector !== 'DIRETORIA' &&
          !userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() => handleApprovalStep('EVALUATION', 'DIRETORIA')}
              >
                Confirmar
              </Button>
            </>
          ) : null}
          {userData.responsible_sector === 'DIRETORIA' &&
          userData.old_sector !== 'DIRETORIA' &&
          !userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() => handleActiveUser('ACTIVE', 'CLIENTE')}
              >
                Confirmar
              </Button>
            </>
          ) : null}
          {userData.responsible_sector === 'CADASTRO' &&
          userData.old_sector === 'DIRETORIA' &&
          !userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() => handleActiveUser('ACTIVE', 'CLIENTE')}
              >
                Confirmar
              </Button>
            </>
          ) : null}
          {userData.responsible_sector === 'COMPLIANCE' &&
          userData.id_syscambio ? (
            <>
              <Button
                type='button'
                onClick={() => handleApprovalStep('EVALUATION', 'DIRETORIA')}
              >
                Confirmar
              </Button>
            </>
          ) : null}
          {userData.responsible_sector === 'DIRETORIA' &&
          userData.id_syscambio ? (
            <>
              <Button type='button' onClick={() => handleActiveExistUser()}>
                Confirmar
              </Button>
            </>
          ) : null}
        </DivButtons>
      </StyledObservation>
    </Modal>
  )
}

export default ApprovalUser
