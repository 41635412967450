import GenericButton from 'components/generic/GenericButton'
import {
  InputName,
  InputNumber,
  InputText
} from 'components/generic/GenericInputs'
import Modal from 'components/generic/Modal'
import { axiosWebsite } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { CurrencyModel } from 'interfaces/currency'
import { OperationModel } from 'interfaces/operation'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import styled from 'styled-components'
import { Currency } from './LinesModals'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const Button = styled(GenericButton)<{ isDeny?: boolean }>`
  margin: 0.3rem 0;
  width: 150px;
  color: ${(props) => props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
`

const CardGrid = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.1rem;
`
const TableGrid = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: minmax(100px, 150px) minmax(100px, 150px) minmax(
      300px,
      380px
    );
  grid-auto-rows: 35px;
  height: 100%;
  max-height: 600px;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

interface Props {
  showModal: boolean
  handleModal: () => void
  data: OperationModel
  changeData: (value: OperationModel) => void
}

const FindCurrency = ({
  showModal,
  handleModal,
  data,
  changeData
}: Props): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currencys, setCurrencys] = useState<CurrencyModel[]>([])
  const [values, setValues] = useState<CurrencyModel>({
    Codigo: '',
    CodigoISO: '',
    Nome: ''
  })
  const [valuesFilter, setValuesFilter] = useState<CurrencyModel>({
    Codigo: '',
    CodigoISO: '',
    Nome: ''
  })

  const handleGetCurrency = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosWebsite.get(`/moeda`, config)

      setCurrencys(data)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleFilter = () => {
    setValuesFilter({
      Codigo: values.Codigo,
      CodigoISO: values.CodigoISO,
      Nome: values.Nome
    })
  }

  const close = () => {
    handleModal()
    setValuesFilter({
      Codigo: '',
      CodigoISO: '',
      Nome: ''
    })
    setValues({
      Codigo: '',
      CodigoISO: '',
      Nome: ''
    })
  }

  useEffect(() => {
    if (showModal) {
      handleGetCurrency()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])
  return (
    <Modal
      title='Buscar tipo moeda'
      showModal={showModal}
      close={() => handleModal()}
      backFocus
      width='100%'
      maxWidth='700px'
      height='100%'
      maxHeight='85vh'
    >
      <Container>
        <ItemRowTop>
          <InputNumber
            label='Cód. Moeda'
            maxWidth='150px'
            maxLength={3}
            value={values.Codigo}
            changeValue={(value) =>
              setValues({
                ...values,
                Codigo: value
              })
            }
            readOnly={
              String(values.CodigoISO) !== '' || String(values.Nome) !== ''
            }
          />
          <InputName
            label='Código ISO'
            maxWidth='150px'
            maxLength={3}
            value={values.CodigoISO}
            changeValue={(value) =>
              setValues({
                ...values,
                CodigoISO: value
              })
            }
            readOnly={
              String(values.Codigo) !== '' || String(values.Nome) !== ''
            }
          />
          <InputText
            label='Nome da Moeda'
            maxWidth='200px'
            value={values.Nome}
            changeValue={(value) =>
              setValues({
                ...values,
                Nome: value
              })
            }
            readOnly={
              String(values.CodigoISO) !== '' || String(values.Codigo) !== ''
            }
          />
          <Button type='button' onClick={() => handleFilter()}>
            Buscar
          </Button>
        </ItemRowTop>
        <CardGrid>
          <TableGrid>
            <GridTitle>
              <Text fontWeight='bold'>Cód. Moeda</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Código ISO</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Nome da Moeda</Text>
            </GridTitle>
            {/*Mudar depois*/}
            {currencys
              .filter(
                (e) =>
                  String(e.Codigo)
                    ?.toLowerCase()
                    .indexOf(String(valuesFilter.Codigo).toLowerCase()) > -1 &&
                  String(e.CodigoISO)
                    ?.toLowerCase()
                    .indexOf(String(valuesFilter.CodigoISO).toLowerCase()) >
                    -1 &&
                  String(e.Nome)
                    ?.toLowerCase()
                    .indexOf(String(valuesFilter.Nome).toLowerCase()) > -1
              )
              .map((currency, index) => {
                let last = false
                if (index === currencys.length - 1) {
                  last = true
                }
                return (
                  <Currency
                    last={last}
                    index={index}
                    currency={currency}
                    handleModal={close}
                    data={data}
                    changeData={changeData}
                  />
                )
              })}
          </TableGrid>
        </CardGrid>
      </Container>
    </Modal>
  )
}

export default FindCurrency
