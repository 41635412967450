import React from "react";
import GenericButton from "./GenericButton";

interface ButtonsTabProps {
  buttons: Array<{ value: string; label: string }>;
  onChange: (value: string) => void;
  align: "right" | "left";
  value: string;
}

const ButtonsTab: React.FC<ButtonsTabProps> = ({
  buttons,
  onChange,
  align,
  value,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: 10,
      }}
    >
      {align === "right" && <div style={{ flex: 1 }} />}

      {buttons.map((button, keyButton) => (
        <GenericButton
          key={`button${keyButton}${button.value}`}
          onClick={() => onChange(button.value)}
          style={{
            marginRight: align === "right" ? 0 : 10,
            marginLeft: align === "left" ? 0 : 10,
            opacity: value === button.value ? 1 : 0.5,
          }}
        >
          {button.label}
        </GenericButton>
      ))}

      {align === "left" && <div style={{ flex: 1 }} />}
    </div>
  );
};

export default ButtonsTab;
