import { axiosWebsite } from 'helpers/axiosInstances'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { BankAgencyModel } from 'interfaces/bankAgency'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import GenericButton from '../GenericButton'
import { InputText } from '../GenericInputs'
import Modal from '../Modal'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const Button = styled(GenericButton)<{ isDeny?: boolean }>`
  margin: 0.3rem 0;
  width: 150px;
  color: ${(props) => props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`

const ItemRowTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
`

const CardGrid = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.1rem;
`

const TableGrid = styled.div`
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 15px;
  display: grid;
  grid-template-columns: minmax(100px, 150px) minmax(300px, 1fr) minmax(
      100px,
      150px
    );
  grid-auto-rows: 35px;
  height: 100%;
  max-height: 600px;
  overflow: auto;
`

const GridTitle = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 4px gray;
  background: ${(props) => props.theme.color.primary_light};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.borderRadius};
  position: sticky;
  top: 0;
  z-index: 10;
`

const Text = styled.p<{
  Color?: string
  fontSize?: string
  fontWeight?: string
  textAlign?: boolean
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.font.small};
  color: ${(props) => (props.Color ? props.Color : props.theme.color.white1)};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? 'center' : 'none')};
`

const ColumnText = styled.div<{ isActive?: boolean }>`
  border-right: 1px solid ${(props) => props.theme.color.white5};
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid ${(props) => props.theme.color.white5};
  padding: 0 0.5rem;
  background: ${(props) =>
    props.isActive ? props.theme.color.white4 : props.theme.color.white1};

  &:hover {
    cursor: pointer;
  }
`

const TextColumn = styled(Text)`
  color: black;
  user-select: none;
`

interface ModalPickAgencyProps {
  isOpen?: boolean
  onClose?: () => void
  onChange?: (bank: BankAgencyModel) => void
  value?: BankAgencyModel
  bankCode: number | string
  state: string
  city: string
}

const ModalPickAgency: React.FC<ModalPickAgencyProps> = ({
  onChange,
  value,
  isOpen = false,
  onClose,
  bankCode,
  city,
  state
}) => {
  const [agencies, setAgencys] = useState<BankAgencyModel[]>([])
  const [bankSelected, setAgencySelected] = useState<string>()
  const [bankActive, setAgencyActive] = useState<string>()
  const currentAgencies = useMemo(() => {
    return agencies
  }, [agencies])
  const { startLoading, stopLoading } = useGlobals()

  const load = async () => {
    setAgencys(() => [])
    startLoading('Carregando Agências')
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    axiosWebsite
      .post(
        '/bancos/agencias',
        {
          bank_code: bankCode,
          city,
          state
        },
        config
      )
      .then((res) => res.data)
      .then(setAgencys)
      .finally(stopLoading)
  }

  const columnEvents = (bank: BankAgencyModel) => {
    const key = `${bank.Cnpj}${bank.CodAgencia}${bank.CodAgenciaSyscambio}`
    const isActive =
      [bankSelected, bankActive].includes(key) ||
      value?.CodAgenciaSyscambio === bank.CodAgenciaSyscambio

    return {
      onClick: () => {
        setAgencySelected(() => key)
      },
      onDoubleClick: () => {
        setAgencyActive(() => key)
        onChange?.(bank)
      },
      isActive
    }
  }

  useEffect(() => {
    if (isOpen) {
      if (city && city !== '--') {
        load()
      }
    }
  }, [isOpen])

  return (
    <Modal
      showModal={isOpen}
      title='Selecione uma Agência'
      backFocus
      width='50%'
      close={onClose}
    >
      <Container>
        <CardGrid>
          <TableGrid>
            <GridTitle>
              <Text fontWeight='bold'>Cod. Agencia</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>Nome</Text>
            </GridTitle>
            <GridTitle>
              <Text fontWeight='bold'>CNPJ</Text>
            </GridTitle>
            {currentAgencies.map((bank) => (
              <>
                <ColumnText {...columnEvents(bank)}>
                  <TextColumn Color={'black'}>{bank.CodAgencia}</TextColumn>
                </ColumnText>
                <ColumnText {...columnEvents(bank)}>
                  <TextColumn Color={'black'}>{bank.Nome}</TextColumn>
                </ColumnText>
                <ColumnText {...columnEvents(bank)}>
                  <TextColumn Color={'black'}>{bank.Cnpj}</TextColumn>
                </ColumnText>
              </>
            ))}
          </TableGrid>
        </CardGrid>
      </Container>
    </Modal>
  )
}

export default ModalPickAgency
