import { Avatar, Button } from '@chakra-ui/react'
import {
  InputCelPhone,
  InputCPF,
  InputDate,
  InputEmail,
  InputImgBase64,
  InputPhone,
  InputText
} from 'components/generic/GenericInputs'
import Modal from 'components/generic/Modal'
import PermissionPicker from 'components/generic/PermissionPicker'
import { axiosAuth } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { makeBaseUsersPermissionsGroups } from 'helpers/factories/permissions'
import { enableButtonUser, validBody } from 'helpers/validBody'
import { validateFild } from 'helpers/validFilds'
import { UserModel } from 'interfaces/user'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading
} from 'redux/actions'
import styled from 'styled-components'

interface Props {
  showModal: boolean
  getUserData: () => void
  handleModal: () => void
  id: string
}

const EditUser = ({
  showModal,
  getUserData,
  handleModal,
  id
}: Props): JSX.Element => {
  const [showModalData, setShowModalData] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const [userData, setUserData] = useState<UserModel>({})
  const [userFixedData, setUserFixedData] = useState<UserModel>({})
  const [body, setBody] = useState<UserModel>({})

  const getUser = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(`/users/${id}`, config)

      setUserFixedData(data)
      setUserData(data)
      setShowModalData(true)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const userRequired = ['user_name', 'email']

  const handleSubmit = async () => {
    if (validateFild(userData, userRequired)) {
      try {
        dispatch(
          showLoading({
            message: 'Carregando',
            isLoading: true
          })
        )

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }

        await axiosAuth.put(`/users/${userData.id}`, body, config)

        dispatch(setSuccessMessage('Usuário alterado com sucesso!'))

        close()
      } catch (error: any) {
        if (isUnauthorized(error)) {
          history.push('/unauthorized')
        } else {
          dispatch(
            setGlobalMessage({
              message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
              type: 'ERROR'
            })
          )
        }
      } finally {
        dispatch(clearLoading())
        getUserData()
      }
    } else {
      dispatch(
        setGlobalMessage({
          message: `Preencha todos os dados`,
          type: 'WARN'
        })
      )
    }
  }

  const close = () => {
    setUserData({})
    setUserFixedData({})
    setBody({})
    handleModal()
    setShowModalData(false)
  }

  useEffect(() => {
    setBody(validBody(userFixedData, userData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  useEffect(() => {
    if (showModal === true) {
      getUser()
    }
    // setUserData({ ...userData, company_id: user?.id! });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  return (
    <Modal title='Alterar usuário' showModal={showModalData} backFocus>
      <ModalStyled>
        <LeftPane>
          {' '}
          <ItemRow>
            <InputText
              label='Nome*'
              value={userData.user_name!}
              changeValue={(value) =>
                setUserData({ ...userData, user_name: value })
              }
            />
          </ItemRow>
          <ItemRow>
            <InputCPF
              //maxWidth='200px'
              label='CPF'
              placeholder='___.___.___-__'
              value={userData.document!}
              changeValue={(value) =>
                setUserData({ ...userData, document: value })
              }
            />
            <InputEmail
              //maxWidth='200px'
              label='E-mail*'
              placeholder='exemplo@mail.com'
              value={userData.email!}
              changeValue={(value) =>
                setUserData({ ...userData, email: value })
              }
            />
          </ItemRow>
          <ItemRow>
            <InputText
              //maxWidth='200px'
              label='Empresa'
              value={userData.contact_role!}
              changeValue={(value) =>
                setUserData({ ...userData, contact_role: value })
              }
            />
            <InputText
              //maxWidth='200px'
              label='Departamento'
              value={userData.contact_branch!}
              changeValue={(value) =>
                setUserData({ ...userData, contact_branch: value })
              }
            />
          </ItemRow>
          <ItemRow>
            <InputPhone
              //maxWidth='200px'
              label='Telefone'
              placeholder='0000-0000'
              value={userData.contact_phone!}
              changeValue={(value) =>
                setUserData({ ...userData, contact_phone: value })
              }
            />
            <InputCelPhone
              //maxWidth='200px'
              label='Celular'
              placeholder='00000-0000'
              value={userData.contact_cellphone!}
              changeValue={(value) =>
                setUserData({ ...userData, contact_cellphone: value })
              }
            />
          </ItemRow>
        </LeftPane>
        <RightPane>
          <PermissionPicker
            initialValue={userData.permission_list}
            onChange={(permissions) => {
              console.log({ permissions })
              setUserData((oldUser) => ({
                ...oldUser,
                permission_list: permissions
              }))
            }}
            groups={makeBaseUsersPermissionsGroups()}
          />
        </RightPane>
      </ModalStyled>
      <ButtonDiv>
        <Button
          m={2}
          variant='ghost'
          color={'red.400'}
          colorScheme={'red'}
          onClick={() => close()}
        >
          Cancelar
        </Button>
        <Button
          isActive={!enableButtonUser(body)}
          m={2}
          variant='outline'
          color={'green.400'}
          colorScheme={'green'}
          onClick={() => {
            if (enableButtonUser(body)) {
              handleSubmit()
            }
          }}
        >
          Confirmar Alterações
        </Button>
      </ButtonDiv>
    </Modal>
  )
}

const ModalStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
`

const LeftPane = styled.div`
  width: auto;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  & div {
    align-items: center;
  }
`

const RightPane = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`

const ButtonDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
`

export default EditUser
