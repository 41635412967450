export function addDays(date: string, days: number): string {
  const result = new Date(date);
  result.setDate(result.getDate() + 1 + days);
  return result.toLocaleDateString("pt-BR").split("/").reverse().join("-");
}

export function todayString() {
  const current = new Date()
  const day =
    current.getFullYear().toString() +
    '-' +
    (current.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    current.getDate().toString().padStart(2, '0')
  return day
}