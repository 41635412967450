import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { axiosSupplier } from 'helpers/axiosInstances'
import { formatStatus } from 'helpers/formats'
import { useDisclosure } from 'hooks/helpers/useDisclosure'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { useDispatch } from 'react-redux'
import { clearLoading, showLoading } from 'redux/actions'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

interface Props {
  data: any
}

const SupplierPortalWorkFlowDascam: React.FC = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const [toggledClearRows, setToggleClearRows] = useState<boolean>(false)
  const [isApprove, setIsApprove] = useState<boolean>(false)
  const [selected, setSelected] = useState<any[]>([])
  const [accounts, setAccounts] = useState<any[]>([])
  const [msg, setMsg] = useState<string>('')

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows)
  }
  const handleRowSelected = React.useCallback((state) => {
    setSelected(state.selectedRows)
  }, [])
  const rowDisabledCriteria = (row: any) => row.status === 'TO_INTEGRATION'
  const ExpandedComponent: React.FC<Props> = ({ data }) => {
    return (
      <pre>
        {
          <>
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='b' flex='1' textAlign='left'>
                      Dados Base
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <p>NIF: {data.nif ?? '---'} </p>
                  <p>Cidade: {data.city ?? '---'} </p>
                  <p>Estado: {data.state ?? '---'} </p>
                  <p>País {data.country ?? '---'} </p>
                  <p>Moeda: {data.coin ?? '---'} </p>
                  <Button
                    mt={4}
                    variant={'outline'}
                    color={'green.400'}
                    onClick={() => {
                      const link = document.createElement('a')
                      const filePath = data.file
                      link.href = filePath
                      link.target = '_blank'
                      link.download = `download-${moment().format(
                        'YYYYMMDDHHmmss'
                      )}`
                      link.click()
                    }}
                    disabled={!data.file}
                  >
                    Documento(s)
                  </Button>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='b' flex='1' textAlign='left'>
                      Conta
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <p>Banco: {data.account_data.bank_name ?? '---'} </p>
                  <p>Cidade: {data.account_data.bank_city ?? '---'} </p>
                  <p>Estado: {data.account_data.bank_state ?? '---'} </p>
                  <p>End.: {data.account_data.bank_address ?? '---'} </p>
                  <p>País {data.account_data.bank_country ?? '---'} </p>
                  <p>BLZ: {data.account_data.blz ?? '---'} </p>
                  <p>BSB: {data.account_data.bsb ?? '---'} </p>
                  <p>IBAN: {data.account_data.iban ?? '---'} </p>
                  <p>CHIPS: {data.account_data.chips ?? '---'} </p>
                  <p>CNAPS: {data.account_data.cnaps ?? '---'} </p>
                  <p>TRASIT: {data.account_data.trasit ?? '---'} </p>
                  <p>SORT CODE: {data.account_data.sort_code ?? '---'} </p>
                  <p>BRANCH CODE: {data.account_data.branch_code ?? '---'} </p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='b' flex='1' textAlign='left'>
                      Conta Intermediário
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <p>Banco: {data.intermediary.bank_name ?? '---'} </p>
                  <p>Cidade: {data.intermediary.bank_city ?? '---'} </p>
                  <p>Estado: {data.intermediary.bank_state ?? '---'} </p>
                  <p>End.: {data.intermediary.bank_address ?? '---'} </p>
                  <p>País {data.intermediary.bank_country ?? '---'} </p>
                  <p>Conta: {data.intermediary.account ?? '---'} </p>
                  <p>ABA: {data.intermediary.aba ?? '---'} </p>
                  <p>IBAN: {data.intermediary.iban ?? '---'} </p>
                  <p>CHIPS: {data.intermediary.chips ?? '---'} </p>
                  <p>SWIFT: {data.intermediary.swift ?? '---'} </p>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        }
      </pre>
    )
  }

  const listaContas: TableColumn<any>[] = [
    {
      name: 'id',
      selector: (row) => row.id ?? '---',
      width: '100px',
      omit: true
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      width: '200px',
      cell (row, rowIndex, column, id) {
        return (
          <Badge
            variant='subtle'
            colorScheme={row.status === 'TO_INTEGRATION' ? 'primary' : 'red'}
          >
            {formatStatus(row.status)}
          </Badge>
        )
      }
    },
    {
      name: 'Empresa',
      selector: (row) => row.user.company_name ?? '---',
      width: '260px'
    },
    {
      name: 'Fornecedor',
      selector: (row) => row.user.provider_name ?? '---',
      width: '260px'
    },
    {
      name: 'Conta',
      selector: (row) => row.account_data.account ?? '---'
    },
    {
      name: 'SWIFT',
      selector: (row) => row.account_data.swift ?? '---'
    },
    {
      name: 'ABA',
      selector: (row) => row.account_data.aba ?? '---'
    }
  ]

  async function getItens () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando Solicitações',
          isLoading: true,
          subMessage: ''
        })
      )

      handleClearRows()
      setSelected([])
      setAccounts([])

      const config = {
        headers: {
          directtoken: `${localStorage.getItem('access_token')}`
        }
      }
      const res = await axiosSupplier.get(`/accounts-to-approve`, config)
      if (res.status === 200) {
        if (res.data.length > 0) {
          setAccounts(res.data)
        }
      }
    } catch (error) {
      return toast({
        title: 'Erro!',
        position: 'bottom',
        description: `Erro ao processar ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      dispatch(clearLoading())
    }
  }

  async function action () {
    try {
      dispatch(
        showLoading({
          message: 'Salvando Solicitações',
          isLoading: true,
          subMessage: ''
        })
      )
      if (isApprove) {
        const list = selected.map((e) => {
          return { id: e.id, status: 'TO_INTEGRATION' }
        })
        const rest = await axiosSupplier.put('/accounts/update-status', {
          list: list
        })
        if (rest.status !== 201) {
          return toast({
            title: 'Erro!',
            position: 'bottom',
            description: 'Erro ao processar!',
            status: 'error',
            duration: 3000,
            isClosable: true
          })
        } else {
          return toast({
            title: 'Processado!',
            position: 'bottom',
            description: 'Salvo com sucesso!',
            status: 'success',
            duration: 3000,
            isClosable: true
          })
        }
      } else {
        const list = selected.map((e) => {
          return { id: e.id, status: 'DENY', msg: msg }
        })
        const rest = await axiosSupplier.put('/accounts/update-status', {
          list: list
        })
        if (rest.status !== 201) {
          return toast({
            title: 'Erro!',
            position: 'bottom',
            description: 'Erro ao processar!',
            status: 'error',
            duration: 3000,
            isClosable: true
          })
        } else {
          return toast({
            title: 'Processado!',
            position: 'bottom',
            description: 'Salvo com sucesso!',
            status: 'success',
            duration: 3000,
            isClosable: true
          })
        }
      }
    } catch (error) {
    } finally {
      dispatch(clearLoading())
      onClose()
      getItens()
    }
  }

  useEffect(() => {
    getItens()
  }, [])

  const { isOpen, onOpen, onClose } = useDisclosure()
  function AlertDialogConfirmation () {
    const cancelRef = useRef<HTMLButtonElement>(null)

    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          onCloseComplete={() => {
            setMsg('')
          }}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Ação
              </AlertDialogHeader>

              <AlertDialogBody>
                Você deseja fazer essa ação?
                {!isApprove && (
                  <SimpleFormGenerator
                    rows={[
                      {
                        columns: 1,
                        fields: [
                          {
                            type: 'textarea',
                            label: 'Motivo',
                            name: 'msg'
                          }
                        ]
                      }
                    ]}
                    value={{ msg: msg }}
                    onChange={(v) => {
                      setMsg(v.msg)
                    }}
                  />
                )}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Não
                </Button>
                <Button
                  colorScheme='red'
                  onClick={() => action()}
                  ml={3}
                  disabled={!isApprove ? msg.length <= 0 : false}
                >
                  Sim
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  return (
    <>
      {AlertDialogConfirmation()}
      <CardPage
        title={'Portal do Fornecedor - Aprovações de contas'}
        _headerBottom={
          <Col px={2} w={'100%'} mt={6}>
            <VStack spacing={4} align='end'>
              <Box h='35px' mt={1}>
                <Button
                  width={200}
                  marginBottom={5}
                  marginEnd={3}
                  variant={'outline'}
                  color={'green.400'}
                  colorScheme={'green'}
                  isDisabled={selected.length === 0}
                  onClick={() => {
                    setIsApprove(true)
                    onOpen()
                  }}
                >
                  Aprovar
                </Button>
                <Button
                  width={200}
                  marginBottom={5}
                  marginEnd={3}
                  variant={'outline'}
                  color={'red.400'}
                  colorScheme={'red'}
                  isDisabled={selected.length !== 1}
                  onClick={() => {
                    setIsApprove(false)
                    onOpen()
                  }}
                >
                  Reprovar
                </Button>
                <Button
                  width={200}
                  marginBottom={5}
                  variant={'outline'}
                  color={'primary.400'}
                  colorScheme={'primary'}
                  onClick={() => {
                    getItens()
                  }}
                >
                  Buscar Itens
                </Button>
              </Box>
            </VStack>
          </Col>
        }
      >
        {
          <Col marginTop={3} id='cont' minH={'80%'} px={2}>
            <Col
              mt={1}
              rounded={'lg'}
              overflow={'auto'}
              border={'1px solid transparent'}
              borderColor={'primary.600'}
            >
              <Text m={3}>
                Para aprovar/reprovar um item, basta verificar na lista abaixo e
                selecionar os itens e clicar na ação desejada.
              </Text>
              <DataTable
                keyField='id'
                noDataComponent=''
                columns={listaContas}
                data={accounts}
                dense={true}
                fixedHeader={true}
                fixedHeaderScrollHeight={`${
                  (document.getElementById('cont')?.clientHeight ?? 0) - 25
                }px`}
                striped={true}
                expandableRows={true}
                expandableRowsComponent={ExpandedComponent}
                selectableRowDisabled={rowDisabledCriteria}
                selectableRows={true}
                onSelectedRowsChange={handleRowSelected}
                selectableRowsHighlight={true}
                clearSelectedRows={toggledClearRows}
              />
            </Col>
          </Col>
        }
      </CardPage>
    </>
  )
}

export default SupplierPortalWorkFlowDascam
