import React, { useMemo } from "react";
import styled from "styled-components";
import chunkText from "chunk-text";
import chunk from "lodash.chunk";
import { getOS, OS } from "helpers/devices";
import { useEffect } from "react";

const os = getOS();
const isMac = os === OS.mac;
const isWindows = os === OS.win;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Th = styled.div`
  flex: 1;
  background: #eee;
  font-weight: bold;
  height: 100%;
`;

export const Td = styled.div`
  flex: 1;
`;

export const Title = styled.h1`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const PdfHorizontal = styled.div`
  width: 100%;
  height: 1415px;
  font-size: ${() => (isMac ? 12 : 13)}px;
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  padding: ${() => (isMac ? 5 : 10)}px;
  position: relative;
`;

export const Spacing = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const BoxProp = (props: {
  label: string;
  value?: string;
  isBordered?: boolean;
  isFlex?: boolean;
}) => {
  return (
    <>
      <Col
        style={{
          flex: props.isFlex ? 1 : undefined,
          border: props.isBordered ? "0.001rem solid #000" : undefined,
          padding: props.isBordered ? "0 4px" : undefined,
          height: "100%",
          minHeight: 50,
          minWidth: 100,
        }}
      >
        <span style={{ fontWeight: "bold", flex: 1 }}>{props.label}</span>
        <span style={{ flex: 1 }}>{props.value || "Não definido"}</span>
      </Col>
    </>
  );
};

export const PdfPageCount: React.FC<{
  page: number;
  total: number;
}> = ({ page, total }) => {
  return (
    <Col
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        left: 0,
        padding: 5,
        boxSizing: "border-box",
      }}
    >
      <Spacing />
      <Row>
        <Row style={{ flex: 1 }} />
        <span style={{ fontWeight: "bold" }}>
          Página {page}/{total}
        </span>
      </Row>
    </Col>
  );
};

export const PdfMaxLines: React.FC<{
  _header?: (index: number, totalPages: number) => React.ReactNode;
  _footer?: (index: number, totalPages: number) => React.ReactNode;
  onCountPages?: (totalPages: number) => void;
  maxLineChars: number;
  maxLinesPage: number;
  initialPage: number;
  texts: string[];
  additionalPagesCount: number;
}> = ({
  texts,
  _header,
  _footer,
  maxLineChars,
  maxLinesPage,
  onCountPages,
  initialPage,
  additionalPagesCount,
}) => {
  const pagesText = useMemo(() => {
    const paragraphs = texts.map(text => {
      return chunkText(text, maxLineChars);
    });

    const lines = paragraphs.reduce((lines, p) => {
      return [...lines, "\n", ...p];
    }, [] as string[]);

    return chunk(lines, maxLinesPage);

    // return chunk(next, maxLinesPage);
  }, [texts, maxLineChars]);

  useEffect(() => {
    onCountPages?.(pagesText.length);
    console.log("pagesText", pagesText.length);
  }, [pagesText]);

  // console.log({ pagesText });

  return (
    <>
      {pagesText.map((lines, keyPage) => (
        <PdfHorizontal key={`page${keyPage}`}>
          {_header?.(keyPage, pagesText.length)}
          {lines.map((line, keyLine) => (
            <React.Fragment key={`line${keyLine}${keyPage}`}>
              {line !== "\n" && (
                <Row style={{ width: "100%", textAlign: "justify" }}>
                  {line}
                </Row>
              )}
              {line === "\n" && <Spacing />}
            </React.Fragment>
          ))}
          {/* {_footer?.(keyPage, pagesText.length)} */}
          <PdfPageCount
            page={initialPage + keyPage}
            total={pagesText.length + additionalPagesCount}
          />
        </PdfHorizontal>
      ))}
    </>
  );
};
