import { Button } from "@chakra-ui/react";
import GenericButton from "components/generic/GenericButton";
import {
  InputCelPhone,
  InputCPF,
  InputEmail,
  InputImgBase64,
  InputPhone,
  InputText,
  InputLogin
} from "components/generic/GenericInputs";
import Modal from "components/generic/Modal";
import PermissionList from "components/generic/PermissionList";
import { axiosAuth } from "helpers/axiosInstances";
import { isUnauthorized } from "helpers/errors";
import { UserModel } from "interfaces/user";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setGlobalMessage, clearLoading, showLoading } from "redux/actions";
import styled from "styled-components";

const ModalStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
`;

const LeftPane = styled.div`
  width: auto;
  min-width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  & div {
    align-items: center;
  }
`;

const RightPane = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`;

const ButtonDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
`;

// const DivAddClient = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   height: auto;
//   width: auto;

//   & p {
//     margin: 0.3rem;
//     font-family: Montserrat;
//     font-weight: 500;
//     font-size: 1rem;
//     line-height: 20px;
//   }
// `;

interface Props {
  showModal: boolean;
  handleModal: () => void;
  id: string;
}

const VisualUser = ({ showModal, handleModal, id }: Props): JSX.Element => {
  const [showModalData, setShowModalData] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState<UserModel>({});

  const getUser = async () => {
    try {
      dispatch(
        showLoading({
          message: "Carregando",
          isLoading: true,
        })
      );

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const { data } = await axiosAuth.get(`/users/${id}`, config);

      setUserData(data);
      setShowModalData(true);
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push("/unauthorized");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(clearLoading());
    }
  };

  const close = () => {
    setUserData({});
    handleModal();
    setShowModalData(false);
  };

  useEffect(() => {
    if (showModal === true) {
      getUser();
    }
    // setUserData({ ...userData, company_id: user?.id! });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <Modal title="Visualizar usuário" showModal={showModalData} backFocus>
      <ModalStyled>
        <LeftPane>
          <InputImgBase64
            name="photo"
            avatar={userData.photo!}
            changeValue={value => setUserData({ ...userData, photo: value })}
            readOnly
          />
        </LeftPane>
        <RightPane>
          <ItemRow>
            <InputText
              maxWidth="400px"
              label="Nome"
              value={userData.user_name!}
              changeValue={value =>
                setUserData({ ...userData, user_name: value })
              }
              readOnly
            />
          </ItemRow>
          <ItemRow>
            <InputCPF
              maxWidth="200px"
              label="CPF"
              placeholder="___.___.___-__"
              value={userData.document!}
              changeValue={value =>
                setUserData({ ...userData, document: value })
              }
              readOnly
            />
            <InputEmail
              maxWidth="200px"
              label="E-mail"
              placeholder="exemplo@mail.com"
              value={userData.email!}
              changeValue={value => setUserData({ ...userData, email: value })}
              readOnly
            />
          </ItemRow>
          <ItemRow>
            <InputText
              maxWidth="200px"
              label="Departamento"
              value={userData.contact_branch!}
              changeValue={value =>
                setUserData({ ...userData, contact_branch: value })
              }
              readOnly
            />
            <InputLogin
              value={userData.login}
              label="Login de acesso *"
              maxWidth="200px"
              readOnly
              changeValue={value =>
                setUserData({
                  ...userData,
                  login: value,
                })
              }
            />
          </ItemRow>
          {/* <ItemRow>
            <DivAddClient>
              <p>Clientes Dascam:</p>
              <Button type="button">Ver clientes</Button>
            </DivAddClient>
          </ItemRow> */}
          <PermissionList
            userData={userData}
            changeUserData={value => setUserData(value)}
            readOnly
          />
        </RightPane>
      </ModalStyled>
      <ButtonDiv>
        <Button
          variant={"ghost"}
          color={"terciary.400"}
          colorScheme={"terciary_hover"}
          onClick={() => close()}>
          Voltar
        </Button>
      </ButtonDiv>
    </Modal>
  );
};

export default VisualUser;
