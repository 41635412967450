import { FormControl, FormLabel, Heading } from '@chakra-ui/react'
import React from 'react'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import { FieldProps } from '../..'

interface HeadingFieldProps extends FieldProps<'heading', string, string> {}

const HeadingField: React.FC<HeadingFieldProps> = ({ infos }) => {
  return (
    <FormControl
      minH={'100%'}
      display={'flex'}
      flexDir={'column'}
      fontFamily={'heading'}
      mt={2}
      pb={2}
    >
      <Row pos={'relative'} alignItems={'center'} role='group' mt={'auto'}>
        <Heading as={infos.as} size={infos.size} color='#296ba9'>
          {infos.label}
        </Heading>
      </Row>
    </FormControl>
  )
}

export default HeadingField
