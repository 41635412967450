import styled, { keyframes } from 'styled-components'
import { ReactComponent as CheckIcon } from 'assets/checkSvg.svg'
import { ReactComponent as ExitIcon } from 'assets/leftArrowIcon.svg'
import { OperationModel } from 'interfaces/operation'
import { useEffect, useState } from 'react'
import { formatDate } from 'helpers/formats'
import {
  ObservationOperationModel,
  ObservationOperationStatusEnum
} from 'interfaces/observation-operation'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import { isUnauthorized } from 'helpers/errors'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { axiosWebsite } from 'helpers/axiosInstances'
import moment from 'moment'

const FadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    `

const AppearFromCenter = keyframes`
    from {
        opacity: 0;
        transform: scale(.3);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
    `

const Background = styled.div<{ backFocus?: boolean; animate: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 15000;
  background: ${(props) =>
    props.backFocus ? props.theme.color.transparent_opaque : 'none'};
  animation: ${FadeIn} 200ms 1 ease-out both
    ${(props) => (props.animate ? 'running' : 'paused')};
`

// const ContainerOne = styled.div<{
//   animate: boolean;
// }>`
//   padding: 1rem;
//   margin: 0.5rem;
//   box-shadow: 1px 2px 4px gray;
//   background-color: ${props => props.theme.color.white1};
//   border-radius: 10px;
//   display: flex;
//   flex-direction: column;
//   align-items: start;
//   height: 600px;
//   width: 300px;
//   animation: ${AppearFromCenter} 200ms 1 ease-out both
//     ${props => (props.animate ? "running" : "paused")};
// `;

const ContainerTwo = styled.div<{
  animate: boolean
}>`
  padding: 1rem;
  margin: 0.5rem;
  box-shadow: 1px 2px 4px gray;
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 600px;
  width: 700px;
  animation: ${AppearFromCenter} 200ms 1 ease-out both
    ${(props) => (props.animate ? 'running' : 'paused')};
`

const Title = styled.p`
  margin: 0.3rem 0;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.third_main};
`

// const StatusText = styled.p`
//   margin: 0.3rem 0;
//   font-weight: bold;
//   font-size: 1rem;
//   line-height: 21px;
//   text-transform: uppercase;
//   color: ${props => props.theme.color.gray1};
// `;

// const SpanBrown = styled.span`
//   color: ${props => props.theme.color.third_main};
// `;

// const StatusFlowDiv = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   padding: 1rem 0;
// `;

// const FlowSteps = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: flex-start;
// `;

const CheckStatus = styled.div<{ isChecked?: boolean; isDeny?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0.3rem;
  margin: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isChecked
      ? props.theme.color.primary_main
      : props.isDeny
      ? props.theme.color.deny
      : props.theme.color.gray4};

  & svg {
    width: 100%;
    height: 100%;
  }
`

const CloseButton = styled.button`
  background: ${(props) => props.theme.color.secondary_main};
  border: none !important;
  width: 30px;
  height: 30px;
  outline: none;
  cursor: pointer;
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &:hover {
    background: ${(props) => props.theme.color.secondary_light};
  }

  & svg {
    height: 100%;
    width: auto;
  }
`

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`

const DivObservation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.1rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

const NewObservation = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${(props) => props.theme.color.white1};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid ${(props) => props.theme.color.white4};
  border-radius: 19px;
  margin: 0.5rem 0;
  /* 
  & h4 {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 23px;
    color: ${(props) => props.theme.color.gray2};
    margin: 0.5rem;
  }

  & p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: ${(props) => props.theme.color.gray2};
    margin: 0.5rem;
    white-space: pre-line;
  } */
`

// const ColumnStatus = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   height: 100%;
//   width: 100%;
//   overflow-y: auto;
// `;

const TieStatusText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`

const TieBarIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
`

const TitleStatus = styled.p`
  font-weight: bold;
  margin: 0;
  font-size: 1.2rem;
  line-height: 26px;
  margin-bottom: 0.3rem;
  color: ${(props) => props.theme.color.primary_main};
`

const H4Status = styled.h4`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 23px;
  color: ${(props) => props.theme.color.primary_light};
  margin: 0.3rem 0;
`

const PStatus = styled.p`
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: ${(props) => props.theme.color.gray2};
  margin: 0.3rem 0;
  white-space: pre-line;
`

const DateStatus = styled.p`
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 19px;
  margin: 0.3rem 0;
  color: ${(props) => props.theme.color.gray3};
`

const DivStatus = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

interface Props {
  showModal: boolean
  handleModal: () => void
  operation: OperationModel
}

const Historic = ({
  showModal,
  handleModal,
  operation
}: Props): JSX.Element | null => {
  const [observationsData, setObservationsData] = useState<
    ObservationOperationModel[]
  >([])

  const dispatch = useDispatch()
  const history = useHistory()

  const getObservations = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosWebsite.get(
        `/obsoperation/${operation.id}`,
        config
      )

      setObservationsData(data)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar as observações do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  useEffect(() => {
    if (showModal) {
      getObservations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])
  if (showModal) {
    return (
      <Background animate={true} backFocus={true}>
        {/* <ContainerOne animate={true}>
          <Title>Processo de aprovação</Title>
          <StatusText>
            <SpanBrown>Operação: </SpanBrown>
            {operation.id} -{" "}
            {operation.product_type === ("REMESSA" as ProductTypeEnum)
              ? "Remessa"
              : operation.product_type === ("INGRESSO" as ProductTypeEnum)
              ? "Ingresso"
              : operation.product_type === ("EXPORTACAO" as ProductTypeEnum)
              ? "Exportação"
              : operation.product_type === ("IMPORTACAO" as ProductTypeEnum)
              ? "Importação"
              : ""}
          </StatusText>
          <ColumnStatus>
            <StatusFlowDiv>
              <FlowSteps>
                <TieStatusText>
                  <TieBarIcon>
                    <CheckStatus isChecked>
                      <CheckIcon />
                    </CheckStatus>
                  </TieBarIcon>
                  <DivStatus>
                    <TitleStatus>Abertura de Pré- cadastro</TitleStatus>
                    <DateStatus>01/10/1995</DateStatus>
                  </DivStatus>
                </TieStatusText>
              </FlowSteps>
            </StatusFlowDiv>
          </ColumnStatus>
        </ContainerOne> */}
        <ContainerTwo animate={true}>
          <TitleBar>
            <Title>histórico</Title>
            <CloseButton onClick={() => handleModal()}>
              <ExitIcon />
            </CloseButton>
          </TitleBar>
          <DivObservation>
            {observationsData.map((observation, index) => {
              return (
                <>
                  <NewObservation>
                    <TieStatusText>
                      <TieBarIcon>
                        <CheckStatus isChecked>
                          <CheckIcon />
                        </CheckStatus>
                      </TieBarIcon>
                      <DivStatus>
                        <TitleStatus>
                          {observation.status ===
                          ('CORRECTION' as ObservationOperationStatusEnum)
                            ? 'Operação enviada para correção'
                            : observation.status ===
                              ('SENT' as ObservationOperationStatusEnum)
                            ? 'Enviado para análise'
                            : observation.status ===
                              ('APPROVAL' as ObservationOperationStatusEnum)
                            ? 'Enviado para aprovação do cliente'
                            : observation.status ===
                              ('CANCELED' as ObservationOperationStatusEnum)
                            ? 'Operação para cotação e fechamento cancelada'
                            : observation.status ===
                              ('RELEASE' as ObservationOperationStatusEnum)
                            ? 'Liberação para Fechamento e Cotação'
                            : ''}
                        </TitleStatus>
                        <DateStatus>
                          Data de atualização:{' '}
                          {moment(observation.created_date).format(
                            'DD/MM/YYYY'
                          )}
                        </DateStatus>
                        <H4Status>
                          {`${observation.creator_name}${
                            observation.creator_sector
                              ? ' - ' + observation.creator_sector
                              : ''
                          }`}
                        </H4Status>
                        {observation.status ===
                        ('CORRECTION' as ObservationOperationStatusEnum) ? (
                          <PStatus>
                            Enviou a operação para correção de dados
                          </PStatus>
                        ) : observation.status ===
                          ('SENT' as ObservationOperationStatusEnum) ? (
                          <PStatus>Enviou a operação para análise</PStatus>
                        ) : observation.status ===
                          ('APPROVAL' as ObservationOperationStatusEnum) ? (
                          <PStatus>Enviou a operação para aprovação</PStatus>
                        ) : null}
                        {observation.text_content ? (
                          <PStatus>Motivo: {observation.text_content}</PStatus>
                        ) : null}
                      </DivStatus>
                    </TieStatusText>
                  </NewObservation>
                </>
              )
            })}
          </DivObservation>
        </ContainerTwo>
      </Background>
    )
  } else {
    return null
  }
}

export default Historic
