import styled from "styled-components";
import { Fragment, useState } from "react";
import { OperationModel } from "interfaces/operation";
import { formatCNPJ, formatCPF } from "helpers/formats";
import { FindFinancialInstitutionType } from "./helpers/FindFinancialInstitution";
import { CustomerModel } from "interfaces/customer";
import { CurrencyModel } from "interfaces/currency";
import { CountryModel } from "interfaces/country";
import { PayerReceiverModel } from "interfaces/payiesReceivies";

const ColumnText = styled.div<{ background?: boolean; isHover?: boolean }>`
  border-right: 1px solid ${props => props.theme.color.white5};
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.white5};
  padding: 0 0.5rem;
  background: ${props =>
    props.isHover
      ? "#e8f2fc"
      : props.background
      ? props.theme.color.white1
      : props.theme.color.white2};

  transform: ${props => (props.isHover ? "scale(1, 1.1)" : "scale(1)")};

  &:hover {
    cursor: pointer;
  }
`;

const Text = styled.p<{ Color?: string; fontWeight?: string }>`
  font-size: ${props => props.theme.font.small};
  color: ${props => (props.Color ? props.Color : props.theme.color.text3)};
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

interface PropsCustomer {
  last: boolean;
  index: number;
  customer: CustomerModel;
  handleModal: () => void;
  data: OperationModel;
  changeData: (value: OperationModel) => void;
}

const Customer = ({
  last,
  index,
  customer,
  handleModal,
  data,
  changeData,
}: PropsCustomer): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const [hover, setHover] = useState<boolean>(false);

  const handleSelected = () => {
    changeData({
      ...data,
      user_syscambio: customer.id_syscambio,
      client_name: customer.corporate_name,
      client_document: customer.document,
    });
    handleModal();
  };

  return (
    <Fragment key={customer.id_syscambio}>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={customer.id_syscambio}>{customer.id_syscambio}</Text>
      </ColumnText>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={customer.corporate_name}>{customer.corporate_name}</Text>
      </ColumnText>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={customer.fantasy_name}>{customer.fantasy_name}</Text>
      </ColumnText>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={customer.document}>
          {String(customer.document).length > 14
            ? formatCNPJ(String(customer.document))
            : formatCPF(String(customer.document))}
        </Text>
      </ColumnText>
    </Fragment>
  );
};

interface PropsFinancial {
  last: boolean;
  index: number;
  financialInstitution: FindFinancialInstitutionType;
  handleModal: () => void;
  data: OperationModel;
  changeData: (value: OperationModel) => void;
}

const FinancialInstitution = ({
  last,
  index,
  financialInstitution,
  handleModal,
  data,
  changeData,
}: PropsFinancial): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const [hover, setHover] = useState<boolean>(false);

  const handleSelected = () => {
    changeData({
      ...data,
      financial_institution_code:
        financialInstitution.financial_institution_code,
      financial_institution: financialInstitution.financial_institution,
      financial_institution_doc: financialInstitution.financial_institution_doc,
    });
    handleModal();
  };
  return (
    <Fragment key={financialInstitution.id}>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={financialInstitution.financial_institution_code}>
          {financialInstitution.financial_institution_code}
        </Text>
      </ColumnText>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={financialInstitution.financial_institution}>
          {financialInstitution.financial_institution}
        </Text>
      </ColumnText>
    </Fragment>
  );
};

interface PropsCurrency {
  last: boolean;
  index: number;
  currency: CurrencyModel;
  handleModal: () => void;
  data: OperationModel;
  changeData: (value: OperationModel) => void;
}

const Currency = ({
  last,
  index,
  currency,
  handleModal,
  data,
  changeData,
}: PropsCurrency): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const [hover, setHover] = useState<boolean>(false);

  const handleSelected = () => {
    changeData({
      ...data,
      currency_code: currency.Codigo,
      currency_iso: currency.CodigoISO,
      currency_name: currency.Nome,
    });
    handleModal();
  };

  return (
    <Fragment key={1}>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={currency.Codigo}>{currency.Codigo}</Text>
      </ColumnText>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={currency.CodigoISO}>{currency.CodigoISO}</Text>
      </ColumnText>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={currency.Nome}>{currency.Nome}</Text>
      </ColumnText>
    </Fragment>
  );
};

interface PropsPayerReceiver {
  last: boolean;
  index: number;
  payerReceiver: PayerReceiverModel;
  handleModal: () => void;
  data: OperationModel;
  changeData: (value: OperationModel) => void;
  setReadOnly?: (value: boolean) => void;
  setBlockEdit?: (value: boolean) => void;
}

const PayerReceiver = ({
  last,
  index,
  payerReceiver,
  handleModal,
  data,
  changeData,
  setReadOnly,
  setBlockEdit,
}: PropsPayerReceiver): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };

  const [hover, setHover] = useState<boolean>(false);

  const handleSelected = () => {
    changeData({
      ...data,
      beneficiary_code: payerReceiver.CodPagReg,
      beneficiary_name: payerReceiver.NomeBeneficiario,
      beneficiary_identification: payerReceiver.CodCli,
      country_initials: payerReceiver.CodPaisBeneficiario,
      country_name: payerReceiver.PaisBeneficiario,
    });
    handleModal();
    if (setReadOnly && setBlockEdit) {
      setReadOnly(true);
      setBlockEdit(true);
    }
  };

  return (
    <Fragment key={1}>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={payerReceiver.CodCli}>{payerReceiver.CodCli}</Text>
      </ColumnText>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={payerReceiver.NomeBeneficiario}>
          {payerReceiver.NomeBeneficiario}
        </Text>
      </ColumnText>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={payerReceiver.PaisBeneficiario}>
          {payerReceiver.PaisBeneficiario}
        </Text>
      </ColumnText>
      {/* <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={payerReceiver.CodPagReg}>
          {payerReceiver.CodPagReg}
        </Text>
      </ColumnText> */}
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() => handleSelected()}
      >
        <Text title={payerReceiver.ContaBancoBeneficiario}>
          {payerReceiver.ContaBancoBeneficiario}
        </Text>
      </ColumnText>
    </Fragment>
  );
};

interface PropsCountry {
  last: boolean;
  index: number;
  country: CountryModel;
  handleModal: () => void;
  data: OperationModel;
  changeData: (value: OperationModel) => void;
  isPayer: boolean;
  isIntermediary: boolean;
}

const Country = ({
  last,
  index,
  country,
  handleModal,
  data,
  changeData,
  isPayer,
  isIntermediary,
}: PropsCountry): JSX.Element => {
  const isEven = (value: number) => {
    return value % 2 === 0;
  };
  const [hover, setHover] = useState<boolean>(false);

  const handleSelectedPayer = () => {
    changeData({
      ...data,
      country_initials: country.CodPaisIso3166,
      country_name: country.Nome,
    });
    handleModal();
  };

  const handleSelectedBank = () => {
    changeData({
      ...data,
      bank_country_initials: country.CodPaisIso3166,
      bank_country: country.Nome,
    });
    handleModal();
  };

  const handleSelectedBankIntermediary = () => {
    changeData({
      ...data,
      intermediary_country_initials: country.CodPaisIso3166,
      intermediary_country: country.Nome,
    });
    handleModal();
  };

  return (
    <Fragment key={1}>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() =>
          isPayer
            ? handleSelectedPayer()
            : isIntermediary
            ? handleSelectedBankIntermediary()
            : handleSelectedBank()
        }
      >
        <Text title={country.CodPaisIso3166}>{country.CodPaisIso3166}</Text>
      </ColumnText>
      <ColumnText
        background={isEven(index)}
        isHover={hover}
        onMouseOver={() => setHover(!hover)}
        onMouseOut={() => setHover(!hover)}
        onDoubleClick={() =>
          isPayer
            ? handleSelectedPayer()
            : isIntermediary
            ? handleSelectedBankIntermediary()
            : handleSelectedBank()
        }
      >
        <Text title={country.Nome}>{country.Nome}</Text>
      </ColumnText>
    </Fragment>
  );
};

export { Customer, FinancialInstitution, Currency, PayerReceiver, Country };
