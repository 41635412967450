import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {
  createGlobalStyle,
  ThemeProvider,
  DefaultTheme
} from 'styled-components'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { globalReducer } from 'redux/globalReducer'
import ErrorBoundary from 'components/generic/ErrorBoundary'
import V2Provider from 'v2/components/providers/V2Provider'

const globalStore = createStore(
  globalReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
)

const GlobalStyle = createGlobalStyle`

    body {
        font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
            'Ubuntu', 'Montserrat', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
`

const theme: DefaultTheme = {
  color: {
    primary_main: '#0F4C86',
    primary_light: '#296ba9',
    primary_dark: '#042D51',
    primary_dark2: '#011E38',
    secondary_main: '#FFC000',
    secondary_light: '#ffcb31',
    secondary_dark: '#d8a200',
    third_main: '#AA6443',
    button_normal: '#6fa778',
    button_deny: '#ff6e6e',
    button_normal_hover: '#81b88a',
    button_deny_hover: '#f88484',
    text1: '#181917',
    text2: '#FFF',
    text3: '#747774',
    approval: '#87c443',
    warn: '#ffdc2a',
    deny: '#d12121',
    white1: '#FFF',
    white2: '#FAFAFA',
    white3: '#eee',
    white4: '#dce3ed',
    white5: '#d9e3d7',
    gray1: '#111',
    gray2: '#181917',
    gray3: '#979797',
    gray4: '#B1C3D0',
    transparent_opaque: 'rgba(24, 24, 24, 0.8)',
    transparent_clear: 'rgba(24, 24, 24, 0.5)'
  },
  font: {
    huge: '4rem',
    large: '3rem',
    big: '2.2rem',
    medium: '1.8rem',
    normal: '1rem',
    small: '.8rem',
    very_small: '.4rem'
  },
  margins: {
    from_sides: '3',
    from_sides_mobile: '3'
  }
}

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <Provider store={globalStore}>
        <ErrorBoundary>
          <V2Provider>
            <App />
          </V2Provider>
        </ErrorBoundary>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
