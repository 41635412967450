import { axiosWebsite } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { ObservationOperationStatusEnum } from 'interfaces/observation-operation'
import { OperationModel } from 'interfaces/operation'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setGlobalMessage, clearLoading, showLoading } from 'redux/actions'
import { GlobalState } from 'redux/types'
import { ReactComponent as AcceptIcon } from 'assets/acceptIcon.svg'
import styled from 'styled-components'
import GenericButton from 'components/generic/GenericButton'
import Modal from 'components/generic/Modal'

const StyledObservation = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
`

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;

  & div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  & svg {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
`

const Title = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  color: ${(props) => props.theme.color.gray1};
  font-size: 0.1.2rem;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
`

const Text = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  color: ${(props) => props.theme.color.gray1};
  font-size: 0.1.2rem;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 25ch;

  & span {
    color: ${(props) => props.theme.color.primary_light};
  }
`

const DivButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.3rem 0;
`

const Button = styled(GenericButton)<{ isDeny?: boolean }>`
  width: 150px;
  padding: 0.5rem;
  margin: 0 0.3rem;
  color: ${(props) => props.theme.color.white1};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.button_normal};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.button_normal_hover};
  }
`

interface Props {
  showModal: boolean
  operation: OperationModel
  handleModal: () => void
  changeRender: (value: string) => void
  getOperationData?: () => void
}

const ApprovalOperation = ({
  showModal,
  operation,
  handleModal,
  changeRender,
  getOperationData
}: Props): JSX.Element => {
  const user = useSelector((state: GlobalState) => state.user)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleApproval = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const body = {
        id: operation.id,
        updated_by_id: user?.id,
        updated_date: new Date().toDateString(),
        status: 'RELEASE'
      }

      await axiosWebsite.post(`/operation/create`, body, config)

      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Incluindo dados da operação'
        })
      )

      const bodyOperation = {
        creator_name: user?.user_name,
        creator_sector: user?.contact_branch,
        created_by_id: user?.id,
        operation_id: operation.id,
        status: ObservationOperationStatusEnum.RELEASE
      }

      await axiosWebsite.post(`/obsoperation`, bodyOperation, config)

      changeRender('Home')
      close()
      if (getOperationData) {
        getOperationData()
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const close = () => {
    handleModal()
  }

  return (
    <Modal showModal={showModal} title='' maxWidth='400px' backFocus>
      <StyledObservation>
        <TextContainer>
          <div>
            <Title>
              Deseja realmente liberar esta operação para cotação e fechamento
              de contrato:
            </Title>
            <br />
            <Text>
              Cliente:{' '}
              <span>
                {operation.beneficiary_name} sankddnsaklnlkadslkndsaknldsnkla
              </span>
            </Text>
            <Text>
              Tipo de contrato: <span>{operation.product_type}</span>
            </Text>
            <Text>
              Tipo de operação: <span>{operation.operation_type}</span>
            </Text>
            <Text>
              Valor Total: <span>{operation.operation_value}</span>
            </Text>
          </div>
          <AcceptIcon />
        </TextContainer>
        <DivButtons>
          <Button type='button' isDeny onClick={() => close()}>
            Cancelar
          </Button>
          <Button type='button' onClick={() => handleApproval()}>
            Confirmar
          </Button>
        </DivButtons>
      </StyledObservation>
    </Modal>
  )
}

export default ApprovalOperation
