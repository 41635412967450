import { devices } from "helpers/devices";
import styled from "styled-components";
import Person from "assets/person.png";
import Enterprise from "assets/enterprise.png";
import Foreign from "assets/foreign.png";
import GenericButton from "components/generic/GenericButton";
import { ReactComponent as PersonIcon } from "assets/person.svg";
import { ReactComponent as EnterpriseIcon } from "assets/enterprise.svg";
import { ReactComponent as ForeignIcon } from "assets/foreign.svg";
import Background from "assets/bgPersons.png";
import { useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { Row } from "v2/components/shared/Row";

const ForWhomStyled = styled.section`
  background-color: ${props => props.theme.color.white1};
  background-image: url("${Background}");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 1000px;
  max-height: 1000px;
  width: 100vw;
  max-width: 100%;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media ${devices.phoneOnly} {
    padding: 0.3rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${props => props.theme.margins.from_sides};
  }
`;

const DivTitle = styled.div`
  width: 100%;
  height: auto;
  padding: 2rem 1rem;
`;

const Title = styled.p`
  font-size: 2.7rem;
  color: ${props => props.theme.color.gray2};
  font-weight: bold;
  text-transform: uppercase;
`;

const SpanBrown = styled.span`
  color: #aa6443;
`;

const DivCards = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 650px;
  background: ${props => props.theme.color.white2};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.62);
  display: flex;
  flex-direction: row;
  justify-content: flex-center;
  border-radius: 15px;
`;

const DivItems = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const TitleCard = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.color.gray3};
`;

const TextCard = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  /* font-family: "Work Sans"; */
  line-height: 1.5;
  letter-spacing: -0.02em;
  color: ${props => props.theme.color.gray1};
`;

const ImgContainer = styled.div`
  width: 50%;
  height: auto;
`;

const Img = styled.img`
  width: 70%;
  height: auto;
  object-fit: fill;
`;

// const Button = styled(GenericButton)`
//   text-align: start;
//   justify-content: flex-start;
//   padding: 0.5rem;
//   position: relative;
//   width: 280px;

//   & svg {
//     width: 40px;
//     height: 40px;
//     position: absolute;
//     bottom: -3px;
//     right: -5px;
//   }
// `;

const ForWhom = (): JSX.Element => {
  const history = useHistory();
  return (
    <ForWhomStyled id="3">
      <Container>
        <DivTitle>
          <Title>
            Para quem é <SpanBrown>DASCAM Direct?</SpanBrown>
          </Title>
        </DivTitle>
        <DivCards>
          <Card>
            <DivItems>
              <Row alignContent={"center"} width={"100%"}>
                <ImgContainer>
                  <Img src={Person} alt="Imagem pessoa física" />
                </ImgContainer>
                <ImgContainer>
                  <Img src={Enterprise} alt="Imagem pessoa Juridica" />
                </ImgContainer>
                <ImgContainer>
                  <Img src={Foreign} alt="Imagem pessoa Estrangeiro" />
                </ImgContainer>
              </Row>
              <TitleCard>DASCAM DIRECT OTIMIZA SEUS PROCESSOS DE CÂMBIO</TitleCard>
              <TextCard>
                Desenvolvido sob medida para quem busca maior agilidade e transparência nas suas operações,
                o DASCAM DIRECT usa um sistema que permite acompanhar todas as etapas do processo de câmbio,
                desde a inserção de sua operação em nossa base de dados até a sua liquidação, evitando com isso,
                a exaustiva troca de e-mails.
                <br /><br />
                Ao utilizar o DASCAM DIRECT, além de contar com todo acompanhamento da equipe da DASCAM,
                você tem acesso a inúmeros relatórios analíticos e fiscais gerados pelo nosso time de analistas,
                o que vai permitir maior agilidade e gerenciamento dos seus processos de câmbio, com toda agilidade e segurança.
                Venha nos conhecer!
              </TextCard>
              <Button
                variant={"outline"}
                color={"primary.400"}
                colorScheme={"primary"}
                onClick={() => history.push("/register")}>
                Realizar Cadastro no DASCAM Direct
              </Button>
            </DivItems>
          </Card>
        </DivCards>
      </Container>
    </ForWhomStyled>
  );
};

export default ForWhom;
